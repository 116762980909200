import { FormBuilder, Validators } from '@angular/forms';

/**
 * TODO: https://tsdoc.org/
 */
export function initFormDocumentGeneration() {
    const objFormBuilder = new FormBuilder();

    return objFormBuilder.group({
        only_most_recent: [
            true
        ]
    });
}