import { Component,OnInit, Input, HostListener } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PrimengComponentsModule } from '@nashville-primeng';

@Component({
  selector: 'nashville-page-title',
  standalone: true,
  imports: [CommonModule, PrimengComponentsModule],
  templateUrl: './page-title.component.html',
  styleUrl: './page-title.component.scss',
})
export class PageTitleComponent implements OnInit {
  //#region "|--- INPUTS ---|"
  @Input() pageTitle!: string;
  @Input() leftIcon?: string;
  @Input() noLabelWidth?: string;
  //#endregion

  //#region "|--- PROPERTIES ---|"
  innerWidth?: any;
  //#endregion

  ngOnInit(): void {
    this._initVariables();
  }

  private _initVariables() {
    this.innerWidth = window.innerWidth;
  }

  /**
   * (v1) - Executada toda vez que ocorre REDIMENSIONAMENTO da Tela.
   * https://www.w3schools.com/jsref/dom_obj_event.asp
   * @param event
   */
  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    this.innerWidth = window.innerWidth;
  }
}
