<article class="cls-component-version-info">
    <p-panel [toggleable]="true" class="cls-component-version-info-panel">
        <ng-template pTemplate="header">
            <div class="cls-component-version-info-panel-header">
                <i class="fa-solid fa-paperclip"></i> 0.0.0 - 10/06/2024
            </div>            
        </ng-template>
        <ng-template pTemplate="content">
            <div class="cls-component-version-info-list">MÓDULO AERONAVES</div>
            <div class="cls-component-version-info-list">

            </div>
            <div class="cls-component-version-info-item-list ">MÓDULO CCO</div>
            <div>

            </div>

            <ul>
                <li>
                    
                    <div class="">FRONTEND - PARTE VISUAL</div>
                    <ul>
                        <li>
                            <i class="fa-regular fa-star"></i> Formulário Inserir/Alterar Registro Aeronáutico;
                            <div class="ml-3"><i class="fa-regular fa-star"></i> <strong>Registro
                                    Aeronáutico:</strong>
                            </div>
                            <ul>
                                <li><i class="fa-regular fa-star"></i> País de Registro</li>
                                <li><i class="fa-regular fa-star"></i> Tipo Aeronave</li>
                                <li><i class="fa-regular fa-star"></i> Registro Aeronave</li>
                                <li><i class="fa-regular fa-star"></i> Icao Code</li>
                                <li><i class="fa-regular fa-star"></i> Ano de Fabricação</li>
                                <li><i class="fa-regular fa-star"></i> Fabricante</li>
                                <li><i class="fa-regular fa-star"></i> Modelo</li>
                                <li><i class="fa-regular fa-star"></i> Número Serial</li>
                                <li><i class="fa-regular fa-star"></i> Regra de Voo</li>
                                <li><i class="fa-regular fa-star"></i> Número Fin</li>
                                <li><i class="fa-regular fa-star"></i> Nome Aeronave</li>
                                <li><i class="fa-regular fa-star"></i> Obervações</li>
                            </ul>
                            <div class="ml-3"><i class="fa-regular fa-star"></i> <strong>Aeronave no
                                    Sistema:</strong></div>
                            <ul>
                                <li><i class="fa-regular fa-star"></i> Upload de Imagem da Aeronave</li>
                                <li><i class="fa-regular fa-star"></i> Mostrar Imagem da Aeronave </li>
                            </ul>
                            <div class="ml-3"><i class="fa-regular fa-star"></i> <strong>Lista de
                                    Proprietários:</strong>
                            </div>
                            <ul>
                                <li><i class="fa-regular fa-star"></i> Tipo Usuário (CPF/CNPJ)</li>
                                <li><i class="fa-regular fa-star"></i> CNPJ - Imagem do Logo da Empresa</li>
                                <li><i class="fa-regular fa-star"></i> CNP - NI</li>
                                <li><i class="fa-regular fa-star"></i> CNPJ - Nome</li>
                                <li><i class="fa-regular fa-star"></i> CNPJ - Nome Fantasia</li>
                                <li><i class="fa-regular fa-star"></i> CNPJ - Nome Responsável</li>
                                <li><i class="fa-regular fa-star"></i> CNPJ - Celular/WhatsApp Responsável</li>

                            </ul>
                        </li>
                        <li>Inserir/Listar Proprietários;</li>
                        <li>Inserir/Listar Operadores;</li>
                    </ul>
                    <div><strong>BACKEND - FUNCIONALIDADES</strong></div>
                </li>
                <li>
                    <div class="cls-component-version-info-item-list">CCO - (Centro de Controle de Operações)</div>
                    <ul>
                        <li>
                            Aeronave e Comando;
                        </li>
                        <li>
                            Dados de Voo;
                        </li>
                        <li>
                            Filtro Top Risk;
                        </li>
                        <li>
                            Filtro Meteorologia;
                        </li>
                    </ul>
                </li>
            </ul>
        </ng-template>
        <ng-template pTemplate="footer">

        </ng-template>
    </p-panel>
</article>