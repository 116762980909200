import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'nashville-aircraft-logbook-form',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './aircraft-logbook-form.component.html',
  styleUrl: './aircraft-logbook-form.component.scss',
})
export class AircraftLogbookFormComponent {}
