import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'nashville-ftd-web-client-portal-angular-profile-form',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './profile-form.component.html',
  styleUrl: './profile-form.component.scss',
})
export class ProfileFormComponent {}
