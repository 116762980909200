<div class="cls-ui-primeng-field">
    <label class="cls-label" [ngClass]="{'cls-label-bold': this.getShowLabelBold() }" for="{{ this.idField }}">{{label}}</label>
    <div class="cls-ui-primeng-field-switch">
        <ng-container *ngIf="this.showLeftIcon()">                
          <i [class]="this.getLeftIconName()" [ngClass]="{'cls-field-wrong': this.showErrors() }" class="mr-2"></i>
        </ng-container>
        <div class="cls-ui-primeng-field-switch-item-left">{{itemLeft}}</div>
        <p-inputSwitch
          [formControl]="this.control"
          name="{{ this.name }}"
          inputId="{{ this.idField }}">
        </p-inputSwitch>
        <div class="cls-ui-primeng-field-switch-item-right">{{itemRight}}</div>
        <ng-container *ngIf="this.showRightIcon()">                
          <i [class]="this.getRightIconName()" [ngClass]="{'cls-field-wrong': this.showErrors() }" class="ml-2"></i>
        </ng-container>
      </div>
</div>
