import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'frame-vertical-basic-02',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule
  ],
  templateUrl: './vertical-basic-02.component.html',
  styleUrl: './vertical-basic-02.component.scss',
})
export class VerticalBasic02Component {}
