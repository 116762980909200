//#region "|--- IMPORT ANGULAR ---|"
import { FormBuilder, FormArray, Validators } from '@angular/forms';
import { LocalMethodsHandlerClass } from '@nashville-local-methods';
//#endregion

/**
 * TODO: https://tsdoc.org/
 */
export function initAircraftDataForm() {
    const objFormBuilder: FormBuilder = new FormBuilder();

    return objFormBuilder.group({
        aircraft_data_id: [null],        
        avatar_image: ["no_image_available.jpg"],
        avatar_color: [LocalMethodsHandlerClass.generateHexColor()],        
        aero_registration: objFormBuilder.group({
            country_registration: [
                "BRA",
                [
                    Validators.required
                ]
            ],
            type_aircraft: [
                null,
                [
                    Validators.required
                ]
            ],
            mark: [
                null,
                [
                    Validators.required,
                    Validators.minLength(3),
                    Validators.maxLength(7),
                    Validators.pattern('^[a-zA-Z0-9]+$')
                ]
            ],
            manufacturer: [
                null,
                [
                    Validators.required
                ]
            ],
            model: [
                null,
                [
                    Validators.required
                ]
            ],            
            icao_code: [
                null,
                [
                    Validators.required
                ]
            ],
            year_manufactured: [
                null,
                [
                    Validators.required
                ]
            ],            
            serial_number: [
                null,
                [
                    Validators.required
                ]
            ],            
            flight_rules: [
                null,
                [
                    Validators.required
                ]
            ],
            fin_number: [
                null
            ],
            aircraft_name: [
                null
            ],
            obs_registration_data: [
                null
            ]
        }),
        owner_operator_data: [
            []
        ]
    });
}


/*
objFormBuilder.group({
                owner_operator_id: [null],
                register_type: [null],
                avatar_image: [null],
                avatar_color: [null],
                is_company: [null],
                ni: [null],
                fantasy_name: [null],
                obs_owner_operator: [null],
                address: objFormBuilder.group({
                    cep: [null],
                    street_name: [null],
                    number: [null],
                    district: [null],
                    complement: [null],
                    country: [null],
                    city: [null],
                    state: [null],
                    obs_address: [null]
                }),
                contacts: objFormBuilder.group({
                    mobile: [null],
                    phone: [null],
                    instagram: [null],
                    facebook: [null],
                    email: [null],
                    obs_contacts: [null]
                })
            }),
*/