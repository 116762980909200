//#region "|--- INTERFACE ---|"
import { IDropDownBasicOptions } from '../../interfaces/IDropDownBasicOptions';
//#endregion

/**
 * TODO: https://tsdoc.org/
 */
export const typeAerodromeOptions: IDropDownBasicOptions[] = [
    { label: 'AERODORME', value: 'AD' },
    { label: 'HELIPORTO', value: 'HL' },
    { label: 'PLATAFORMA', value: 'PF' }
]