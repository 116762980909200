//#region "|--- INTERFACE ---|"
import { IDropDownBasicOptions } from '../../interfaces/IDropDownBasicOptions';
//#endregion

/**
 * TODO: https://tsdoc.org/
 */
export const riskAttentionOptions:IDropDownBasicOptions[] =  [
    { label: 'VOO SEM COMPLEXIDADE', value: 'not_complex' },
    { label: 'ATENÇÃO AO VOO', value: 'exercise_caution'},
    { label: 'EXTREMA ATENÇÃO', value: 'area_concern' },           
]