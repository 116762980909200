//#region "|--- IMPORT MODULES/PACKAGES ---|"
// ***** ANGULAR *****
import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { CommonModule } from '@angular/common';

// ***** ANGULAR *****
import HTTP_STATUS from 'http-status-codes';

// ***** PRIMENG *****
import { Message } from 'primeng/api';

// ***** NASHVILLE *****
import { PrimengComponentsModule } from '@nashville-primeng';
import { LocalMethodsHandlerClass } from '@nashville-local-methods';
//#endregion

//#region "|--- INTERFACE ---|"
import { IDropDownBasicOptions } from '../../../../../../interfaces/IDropDownBasicOptions';
import { IDropDownTemplateCountryOptions } from '../../../../../../interfaces/IDropDownTemplateCountryOptions';
import { IToastParameters } from '../../../../../../interfaces/IToastParameters';
//#endregion

//#region "|--- DROPDOWN OPTIONS ---|"
import { countryOptions } from '../../../../../../helpers/dropdown-static-options/countryOptions';
import { typeAircraftOptions } from '../../../../../../helpers/dropdown-static-options/typeAircraftOptions';
import { flightRulesOptions } from '../../../../../../helpers/dropdown-static-options/flightRulesOptions';
//#endregion

//#region "|--- ENUNS ---|"
import { BUTTON_ACTION } from '../../../../../../helpers/enum/ButtonActions';
//#endregion

//#region "|--- SERVICE ---|"
import { AircraftDataApisService } from 'src/app/features/aircraft-data/services/aircraft-data-apis.service';
//#endregion

@Component({
  selector: 'nashville-panel-aero-registration',
  standalone: true,
  imports: [
    CommonModule,
    PrimengComponentsModule
  ],
  templateUrl: './panel-aero-registration.component.html',
  styleUrl: './panel-aero-registration.component.scss',
})
export class PanelAeroRegistrationComponent implements OnInit {
  //#region "|--- INPUTS ---|"
  @Input() formComponent!: any;
  @Input() pageInnerWidth!: any;
  //#endregion

  //#region "|--- OUTPUTS ---|"
  @Output() buttonsOwnerOperatorAction = new EventEmitter<any>();
  @Output() showToastMessage = new EventEmitter<IToastParameters>();
  //#endregion

  //region "|--- PROPERTIES ---|"
  optionsCountryRegistration: IDropDownTemplateCountryOptions[];
  optionsTypeAircraft: IDropDownBasicOptions[];
  optionsFlightRules: IDropDownBasicOptions[];

  registerAircraftIsDisabled!: boolean;

  messagesNewAdvices: Message[] | undefined;
  messagesEditAdvices: Message[] | undefined

  labelActionsButtons: any;
  //#endregion

  constructor(
    private aircraftDataApisService: AircraftDataApisService
  ) { }

  ngOnInit(): void {
    this._initVariables();
  }

  //#region "|--- PRIVATE METHODS ---|"  


  /**
   * TODO: https://tsdoc.org/
   */
  private _initVariables(): void {
    this.optionsCountryRegistration = countryOptions;
    this.optionsTypeAircraft = typeAircraftOptions;
    this.optionsFlightRules = flightRulesOptions

    this.labelActionsButtons = BUTTON_ACTION;

    this.messagesNewAdvices = [
      { severity: 'info', summary: '* Aeronaves registradas no Brasil, preencha primeiro o "Registro Aeronave", serão consultadas junto a ANAC e, se o sistema estiver on-line e existir o cadastro, os dados serão recuperados e carregados no formulário. Por favor, faça a conferência antes de salva o registro.', key: "advice-message-new" }
    ];

    this.messagesEditAdvices = [
      { severity: 'warn', summary: '* O campo "Registro Aeronave" não pode ser alterado.', key: "advice-message-edit" }
    ];
  }
  //#endregion

  //#region "|--- PUBLIC METHODS ---|" 
  /**
   * TODO: https://tsdoc.org/
   */
  callbackShowOwnerDialog(xAction: any) {
    this.buttonsOwnerOperatorAction.emit({
      action: xAction,
      type: "owner",
      index: -1
    });
  }

  /**
   * TODO: https://tsdoc.org/
   */
  callbackShowOperatorDialog(xAction: any) {
    this.buttonsOwnerOperatorAction.emit({
      action: xAction,
      type: "operator",
      index: -1
    });
  }

  /**
   * TODO: https://tsdoc.org/
   */
  setOwnerOperatorToBothType(xRegisterIndex: string) {
    this.formComponent.controls.owner_operator_data.value[xRegisterIndex].type_owner_operator = "both";
  }

  /**
   * TODO: https://tsdoc.org/
   */
  setOwnerOperatorEdit(xRegisterIndex: string, xTypeOwnerOperator: string) {
    this.buttonsOwnerOperatorAction.emit({
      action: this.labelActionsButtons.SHOW_DIALOG,
      type: xTypeOwnerOperator,
      index: xRegisterIndex
    });
  }

  /**
   * TODO: https://tsdoc.org/
   */
  setOwnerOperatorDelete(xRegisterIndex: string, xTypeOwnerOperator: string) {
    this.buttonsOwnerOperatorAction.emit({
      action: this.labelActionsButtons.DELETE,
      type: xTypeOwnerOperator,
      index: xRegisterIndex
    });
  }
  //#endregion
}
