//#region "|--- IMPORT MODULES/PACKAGES ---|"
// ***** ANGULAR *****
import { Route } from '@angular/router';

// ***** SHARED *****
import { LoginPageComponent } from './shared/login/login-page/login-page.component';
import { RegisterPageComponent } from './shared/register/register-page/register-page.component';
import { SpaPageComponent } from './shared/single-page-application/spa-page/spa-page.component';

// ***** COMPONENT *****
import { AccountDataFormComponent } from './features/aim-data/pages/account-data-form/account-data-form.component';
import { AccountDataListComponent } from './features/aim-data/pages/account-data-list/account-data-list.component';
import { AircraftDataFormComponent } from './features/aircraft-data/pages/aircraft-data-form/aircraft-data-form.component';
import { AircraftDataListComponent } from './features/aircraft-data/pages/aircraft-data-list/aircraft-data-list.component';
import { AircraftLogbookFormComponent } from './features/aircraft-logbook/pages/aircraft-logbook-form/aircraft-logbook-form.component';
import { AircraftLogbookListComponent } from './features/aircraft-logbook/pages/aircraft-logbook-list/aircraft-logbook-list.component';
import { ChangePasswordFormComponent } from './features/aim-data/pages/change-password-form/change-password-form.component';
import { ChecklistListComponent } from './features/flight-plan/pages/checklist-list/checklist-list.component';
import { FlightPlanRequestFormComponent } from './features/flight-plan/pages/flight-plan-request-form/flight-plan-request-form.component';
import { FlightPlanRequestListComponent } from './features/flight-plan/pages/flight-plan-request-list/flight-plan-request-list.component';
import { LoginFormComponent } from './features/aim-data/pages/login-form/login-form.component';
import { LogoutFormComponent } from './features/aim-data/pages/logout-form/logout-form.component';
import { ProfileFormComponent } from './features/aim-data/pages/profile-form/profile-form.component';
import { SocketioComponent } from './temp/socketio/socketio.component';
import { RequestPasswordFormComponent } from './features/aim-data/pages/request-password-form/request-password-form.component';
import { VersionDevComponent } from './features/about/pages/version-dev/version-dev.component';
import { UserDataFormComponent } from './features/aim-data/pages/user-data-form/user-data-form.component';
import { UserDataListComponent } from './features/aim-data/pages/user-data-list/user-data-list.component';
//#endregion

/**
 * @type ROUTES.
 * @version 1.0.0 (GASPAR - 08/07/2024).

 * 
 * @description
 * - Definição das rotas do Angular.
 */
export const appRoutes: Route[] = [
    {
        path: 'login',
        component: LoginPageComponent,
        children: [
            { path: '', component: LoginFormComponent }
        ]
    },
    {
        path: 'record',
        component: RegisterPageComponent
    },
    {
        path: '',
        component: SpaPageComponent,
        children: [
            { path: 'account/form', component: AccountDataFormComponent },
            { path: 'account/form-ls/:id', component: AccountDataFormComponent },
            { path: 'account/list', component: AccountDataListComponent },
            { path: 'aircraft/form', component: AircraftDataFormComponent },
            { path: 'aircraft/form-ls/:id', component: AircraftDataFormComponent },
            { path: 'aircraft/list', component: AircraftDataListComponent },
            { path: 'user/change/password', component: ChangePasswordFormComponent },
            { path: 'user/profile', component: ProfileFormComponent },
            { path: 'user/form', component: UserDataFormComponent},
            { path: 'user/form-ls/:id', component: UserDataFormComponent},
            { path: 'user/list', component: UserDataListComponent},
            { path: 'logbook/form', component: AircraftLogbookFormComponent },
            { path: 'logbook/form-ls/:id', component: AircraftLogbookFormComponent },
            { path: 'logbook/list', component: AircraftLogbookListComponent },
            { path: 'flight-request/form', component: FlightPlanRequestFormComponent },
            { path: 'flight-request/form-ls/:id', component: FlightPlanRequestFormComponent }, //Indica que a chamada para o formulário vem da ls (Listagem de Planos de Voo)
            { path: 'flight-request/list', component: FlightPlanRequestListComponent },
            { path: 'logout', component: LogoutFormComponent},
            { path: 'flight-request/checklist', component: ChecklistListComponent },
            { path: 'version/dev', component: VersionDevComponent },
            { path: 'request/password', component: RequestPasswordFormComponent},
            { path: 'socketio', component: SocketioComponent },
        ]
    }
];
