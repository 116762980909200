<div class="cls-ui-primeng-field mb-2">
    <ng-container *ngIf="this.showLabel">
        <label class="cls-field-label" [ngClass]="{'cls-label-bold': this.getShowLabelBold() }"
            for="{{ this.idField }}">
            {{ this.label }}
            <ng-container *ngIf="this.showToolTipIcon">
                <i [pTooltip]="tooltipContent" [showDelay]="500" [hideDelay]="300"
                    class="fa-solid fa-comment-dots ml-1"></i>
            </ng-container>
        </label>
        <ng-template #tooltipContent>
            <div class="flex align-items-center">
                <img src="https://primefaces.org/cdn/primeng/images/primeng.svg" height="20" class="mr-2" />
                <span> {{ this.getTooTipContent() }} </span>
            </div>
        </ng-template>
    </ng-container>
</div>