//#region "|--- IMPORT ANGULAR ---|"
import { FormBuilder, Validators } from '@angular/forms'
//#endregion

/**
 * TODO: https://tsdoc.org/
 */
export function initAircraftOwnersOperatorsForm() {
    const objFormBuilder = new FormBuilder();

    return objFormBuilder.group({
        owner_operator_id: [null],
        type_owner_operator: [
            null,
            [
                Validators.required
            ]
        ], //"owner", "operator", "both"
        avatar_image: [null],
        avatar_color: [null],
        is_company: [true],
        country: ["BRA"],
        ni: [null],
        name: [
            null,
            [
                Validators.required
            ]
        ],        
        responsible: objFormBuilder.group({
            name: [null],
            contact: objFormBuilder.group({
                ddi: [null],
                ddd: [null],
                number: [null],
                normalized_text: [null]
            })
        }),        
        address: [[]],
        contacts: [[]],
        obs_owner_operator: [null]
    });
}