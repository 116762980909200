<div class="cls-ui-primeng-field">
    <div [ngClass]="{'cls-field-wrong': this.showErrors() }">
        <ng-container *ngIf="this.showLabel">        
            <label class="cls-field-label" [ngClass]="{'cls-label-bold': this.getShowLabelBold(), 'cls-field-wrong': this.showErrors() }" for="{{ this.idField }}">
                {{ this.label }} 
                <ng-container *ngIf="this.showToolTipIcon">
                    <i [pTooltip]="tooltipContent" [showDelay]="500" [hideDelay]="300" class="fa-solid fa-comment-dots ml-1"></i>
                </ng-container> 
            </label>
            <ng-template #tooltipContent>
                <div class="flex align-items-center">
                    <img src="https://primefaces.org/cdn/primeng/images/primeng.svg" height="20" class="mr-2" />
                    <span> {{ this.getTooTipContent() }} </span>
                </div>
            </ng-template>
        </ng-container>
        <div class="cls-ui-primeng-field-color-picker">
            <div class="cls-ui-primeng-field-color-picker-elements">
                <p-colorPicker 
                    [formControl]="this.control"
                    [ngClass]="{ 'is-danger': this.showErrors() }"                    
                    inputId="{{ this.idField }}"
                    format=hex              
                    >            
                </p-colorPicker> 
                <input            
                    [formControl]="this.inputTextControl"
                    maxlength="7"
                    pInputText/> 
            </div>            
        </div>        
    </div>
    <ui-primeng-error-message-controls class="cls-pmg-field-error-message" [control]="this.control" typeControl="input"></ui-primeng-error-message-controls>
</div>
