//#region "|--- IMPORT MODULES/PACKAGES ---|"
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';

import { settingConfig } from '@nashville-config';

import { PrimengComponentsModule } from '@nashville-primeng';
//#endregion

//#region "|--- ENUNS ---|"
import { BUTTON_ACTION } from '../../../../../../helpers/enum/ButtonActions';
//#endregion

@Component({
  selector: 'nashville-dialog-many-aerodrome-basic-data',
  standalone: true,
  imports: [CommonModule, PrimengComponentsModule],
  templateUrl: './dialog-many-aerodrome-basic-data.component.html',
  styleUrl: './dialog-many-aerodrome-basic-data.component.scss',
})
export class DialogManyAerodromeBasicDataComponent implements OnInit {
  //#region "|--- INPUTS ---|"
  @Input() showDialog!: boolean;
  //#endregion

   //#region "|--- OUTPUTS ---|"
   @Output() dialogButtonsAction = new EventEmitter<string>();
   //#endregion

   //#region "|--- PROPERTIES---|"
   settingConfig!: any;
 
   labelActionsButtons: any;
   //#endregion

  /**
   * TODO: https://tsdoc.org/
   */
  ngOnInit(): void {
    this._initVariables();
  }

  /**
   * TODO: https://tsdoc.org/
   */
  callbackButton(xValue: string) {
    this.dialogButtonsAction.emit(xValue);
  }


  //#region "|--- PRIVATE METHODS---|" 
  /**
   * TODO: https://tsdoc.org/
   */
  private _initVariables(): void {
    this.labelActionsButtons = BUTTON_ACTION;

    this.settingConfig = settingConfig;
  }
  //#endregion
}
