//#region "|--- IMPORT MODULES/PACKAGES ---|"
// ***** ANGULAR *****
import { Component, OnInit, Input, Output, EventEmitter, HostListener } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, FormGroup, UntypedFormControl } from '@angular/forms';

// ***** PRIMENG *****
import { Message } from 'primeng/api';

// ***** NASHVILLE *****
import { settingConfig } from '@nashville-config';
import { PrimengComponentsModule } from '@nashville-primeng';
//#endregion

//#region "|--- IMPORT INTERFACES ---|"
import { IDropDownBasicOptions } from '../../../../interfaces/IDropDownBasicOptions';
import { IDropDownTemplateCountryOptions } from '../../../../interfaces/IDropDownTemplateCountryOptions';
//#endregion

//#region "|--- DROPDOWN OPTIONS ---|"
import { countryOptions } from '../../../../helpers/dropdown-static-options/countryOptions';
//#endregion

//#region "|--- IMPORT SERVICES ---|"
import { ConfirmationService } from 'primeng/api';
import { ZipCodeApisService } from '../../../../services/zip-code/zip-code-apis.service';
//#endregion

@Component({
  selector: 'nashville-address-handler',
  standalone: true,
  imports: [
    CommonModule,
    PrimengComponentsModule
  ],
  templateUrl: './address_handler.component.html',
  styleUrl: './address_handler.component.scss',
})
export class AddressHandlerComponent implements OnInit {
  //#region "|--- INPUTS ---|"
  @Input() pageInnerWidth!: any;
  @Input() arrayAddressData!: any;
  //#endregion

  //#region "|--- OUTPUTS ---|"
  @Output() callBackAddressAction = new EventEmitter<any>();
  //#endregion

  //#region "|--- PROPERTIES---|"
  // ***** CONFIG *****
  innerWidth?: any;
  settingConfig!: any;

  // ***** FORMS *****
  formAddress!: any;

  // ***** OPTIONS DROPDOWN *****
  optionsAddressType!: IDropDownBasicOptions[];
  optionsCountryAddress!: IDropDownTemplateCountryOptions[];

  // ***** SHOW DIALOG *****
  showDialogFormAddress?: boolean;

  // ***** MESSAGEGE *****
  messagesAdvices: Message[] | undefined;

  // ***** OTHER *****
  currentIndexUpdate: string | null;
  //#endregion

  constructor(
    private confirmationService: ConfirmationService,
    private zipCodeService: ZipCodeApisService
  ) { }

  ngOnInit(): void {
    this._initVariables();

    this._initAddressTypeOptions();
    this._initFormAddress();

    setTimeout(() => {
      this._initEventsAddressForm();
    }, 10);
  }

  //#region "|--- HOST LISTENER ---|"
  /**
   * @type HOST LISTENER
   * @version 1.0.0 (GASPAR - 11/06/2024)
   * - Versão inicial.
   * 
   * @description 
   * - Executada toda vez que ocorre REDIMENSIONAMENTO da Tela.
   * - https://www.w3schools.com/jsref/dom_obj_event.asp
   * 
   * @param event 
   */
  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    this.innerWidth = window.innerWidth;
  }
  //#endregion

  //#region "|--- INIT FORM METHODS ---|"
  /**
     * @status OK
     * @type METHOD
     * @version 1.0.0 (GASPAR - 11/06/2024)
     * - Versão inicial.
     * 
     * @description 
     * - Inicializa o FORM de Endereço.
     */
  private _initFormAddress() {
    this.formAddress = new FormGroup({
      type: new UntypedFormControl(null),
      zip_code: new UntypedFormControl(null),
      street: new UntypedFormControl(null),
      number: new UntypedFormControl(null),
      district: new UntypedFormControl(null),
      complement: new UntypedFormControl(null),
      country: new FormControl<string>('BRA'),
      state: new UntypedFormControl(null),
      city: new UntypedFormControl(null),
      obs_address: new UntypedFormControl(null)
    });
  }
  //#endregion

  //#region "|--- PRIVATE METHODS---|"
  private _initEventsAddressForm() {
    this.formAddress.get('zip_code').valueChanges.subscribe(async (xValue: any) => {
      if (xValue && xValue != null && xValue.length > 0) {
        const tempZipCodeNoMask = xValue.replaceAll("-", "");
        
        if (tempZipCodeNoMask.length == 8) {
          await this.zipCodeService.getBrasilCep(tempZipCodeNoMask).subscribe({
            next: (xResponseService: any) => {
              this.formAddress.controls.city.setValue(xResponseService.localidade.toUpperCase());
              this.formAddress.controls.state.setValue(xResponseService.uf.toUpperCase());
              this.formAddress.controls.street.setValue(xResponseService.logradouro.toUpperCase());
              this.formAddress.controls.district.setValue(xResponseService.bairro.toUpperCase());
            },
            complete: () => { },
            error: (xErrorService: any) => {
             
            }
          })
        }
      }
    });
  }

  private _initVariables(): void {
    this.showDialogFormAddress = false;
    this.settingConfig = settingConfig;
    this.optionsCountryAddress = countryOptions;
    this.currentIndexUpdate = null;

    this.messagesAdvices = [
      { severity: 'info', summary: '* Endereços no Brasil, preencha primeiro o "CEP", será realizada uma consulta e, se o sistema estiver on-line e existir o CEP, os dados serão carregados no formulário. Por favor, faça a conferência antes de salva o registro.', key: "advice-message-new" }
    ];
  }
  

  private _initAddressTypeOptions() {
    this.optionsAddressType = [
      {
        label: "Residencial",
        value: "residencial"
      },
      {
        label: "Comercial",
        value: "commercial"
      }
    ];
  }

  private _clearAddress() {
    this.formAddress.controls.type.setValue(null);
    this.formAddress.controls.zip_code.setValue();
    this.formAddress.controls.street.setValue(null);
    this.formAddress.controls.number.setValue(null);
    this.formAddress.controls.district.setValue(null);
    this.formAddress.controls.complement.setValue(null);
    this.formAddress.controls.country.setValue("BRA");
    this.formAddress.controls.state.setValue(null);
    this.formAddress.controls.city.setValue(null);
    this.formAddress.controls.obs_address.setValue(null);
  }
  //#endregion

  //#region "|--- PUBLIC METHODS ---|"
  callbackShowDialog() {
    this.showDialogFormAddress = true;
  }

  saveDataAddress() {
    this.arrayAddressData.push(this.formAddress.getRawValue())
    this.showDialogFormAddress = false;
    this.callBackAddressAction.emit(this.arrayAddressData);
    this._clearAddress();
  }

  saveEditAddress() {
    this.callBackAddressAction.emit(this.arrayAddressData);
  }

  editAddress(xIndexToUpdate: number) {

  }

  closeDialogAddress() {
    this.showDialogFormAddress = false;
    this._clearAddress();
  }

  deleteAddress(xIndexToDelete: number) {
    this.confirmationService.confirm({
      target: event.target as EventTarget,
      message: `Você deseja apagar endereço.`,
      header: 'Confirmação para apagar dados.',
      icon: 'pi pi-exclamation-triangle',
      acceptIcon: "none",
      rejectIcon: "none",
      acceptLabel: "CONFIRMAR",
      rejectLabel: "CANCELAR",
      acceptButtonStyleClass: "cls-component-button-pattern-default ml-2",
      rejectButtonStyleClass: "cls-component-button-pattern-default",
      accept: () => {
        // Quando for do tipo BOTH, não pode apagar o item do array, mas apenas definir qual tipo vai ser. As Tabelas serão atualizadas automaticamente.
        this.arrayAddressData.splice(xIndexToDelete, 1);
        this.callBackAddressAction.emit(this.arrayAddressData);
      },
      reject: () => {

      }
    });
  }

  getTypeName(xType: string) {
    if (xType == "residencial") {
      return "RESIDENCIAL";
    } else if (xType == "commercial") {
      return "COMERCIAL";
    } else {
      return "";
    }
  }
  //#endregion
}
