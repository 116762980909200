<section class="cls-component-data-panel">
    <p-panel header="Dados do Voo" class="cls-component-panel-formgrid">
        <div class="cls-component-form">
            <div class="formgrid grid">
                <div class="col-3">
                    <ui-primeng-calendar 
                        label="Data EOBT (Z) #BOLD#" 
                        idField="fid-date-eobt"
                        [control]="this.formComponent.controls.date_time_eobt.controls.date_zulu"
                        class="cls-component-calendar-request" 
                        sizeInput="small" 
                        isDisabled="false"
                        noLabelWidth="{{this.settingConfig.WIDTH_NO_LABEL}}" 
                        isUTC="true" 
                        leftIcon="true">
                    </ui-primeng-calendar>
                </div>
                <div class="col-3">
                    <ui-primeng-input-text label="Hora EOBT (Z) #BOLD#" idField="fid-time-eobt" placeHolder="00:00"
                        [control]="this.formComponent.controls.date_time_eobt.controls.time_zulu" maskInput="Hh:m0"
                        sizeInput="small" letterCase="upper" maxCharacters="5" isDisabled="false"
                        noLabelWidth="{{this.settingConfig.WIDTH_NO_LABEL}}">
                    </ui-primeng-input-text>
                </div>
                <div class="col-3">
                    <ui-primeng-input-text 
                        label="Total EET #BOLD#" 
                        idField="fid-eet" 
                        placeHolder="00:00"
                        [control]="this.formComponent.controls.eet" 
                        maskInput="Hh:m0" 
                        sizeInput="small"
                        letterCase="upper" 
                        maxCharacters="5" 
                        isDisabled="false" 
                        isUTC="true"
                        noLabelWidth="{{this.settingConfig.WIDTH_NO_LABEL}}">
                    </ui-primeng-input-text>
                </div>
                <div class="col-3">
                    <ui-primeng-dropdown label="Regra de Voo #BOLD#" idField="fid-flight-rule" placeHolder=""
                        sizeInput="small" [control]="this.formComponent.controls.flight_rule" placeHolder="..."
                        [itemsArray]="this.optionsFlightPlanFlightRules" withFilter="false"
                        noLabelWidth="{{this.settingConfig.WIDTH_NO_LABEL}}">
                    </ui-primeng-dropdown>
                </div>

            </div>
            <div class="formgrid grid">
                <div class="field col-3">
                    <ui-primeng-calendar 
                        label="Data Est. Pouso (Z) #BOLD#" 
                        idField="fid-date-landing"
                        [control]="this.formComponent.controls.date_time_landing.controls.date_zulu"
                        class="cls-component-calendar-request" 
                        sizeInput="small" 
                        isDisabled="false"
                        noLabelWidth="{{this.settingConfig.WIDTH_NO_LABEL}}" 
                        isUTC="true" 
                        leftIcon="true">
                    </ui-primeng-calendar>
                </div>
                <div class="field col-3">
                    <ui-primeng-input-text label="Hora Est. Pouso (Z) #BOLD#" idField="fid-time-landing"
                        [control]="this.formComponent.controls.date_time_landing.controls.time_zulu" maskInput="Hh:m0"
                        placeHolder="00:00" sizeInput="small" letterCase="upper" maxCharacters="5" isDisabled="false"
                        noLabelWidth="{{this.settingConfig.WIDTH_NO_LABEL}}">
                    </ui-primeng-input-text>
                </div>
                <div class="field col-3">
                    <ui-primeng-input-text label="Nível de Voo #BOLD#" idField="fid-flight-level"
                        [control]="this.formComponent.controls.flight_level" sizeInput="small" letterCase="upper"
                        maxCharacters="4" isDisabled="false" noLabelWidth="{{this.settingConfig.WIDTH_NO_LABEL}}">
                    </ui-primeng-input-text>
                </div>
                <div class="field col-3">
                    <ui-primeng-input-text label="Velocidade Cruzeiro #BOLD#" idField="fid-cruise-speed"
                        [control]="this.formComponent.controls.cruise_speed" sizeInput="small" letterCase="upper"
                        maxCharacters="5" isDisabled="false" noLabelWidth="{{this.settingConfig.WIDTH_NO_LABEL}}">
                    </ui-primeng-input-text>
                </div>
            </div>
            <div class="formgrid grid">
                <ng-container
                    *ngFor="let itemMainInputField of this.arrayMainInputFieldAerodrome; let currentElementIndex=index">
                    <div class="field col-3">
                        <div class="cls-component-field-aerodrome-composed">
                            <p-fieldset legend="{{itemMainInputField.label}}">
                                <div class="cls-component-field-aerodrome-composed-aerodrome-legend">
                                    <img src="{{this.getAerodromeIcon(itemMainInputField.target)}}" alt="">
                                </div>
                                <div class="cls-component-field-aerodrome-composed-panel">
                                    <ui-primeng-input-text label="ICAO / COORD. #BOLD#"
                                        idField="fid-aerodrome-{{itemMainInputField.target}}"
                                        [control]="this.formAerodrome.controls[itemMainInputField.control]"
                                        sizeInput="small" letterCase="upper" isDisabled="false"
                                        noLabelWidth="{{this.settingConfig.WIDTH_NO_LABEL}}"
                                        class="cls-component-field-aerodrome-composed-input">
                                    </ui-primeng-input-text>
                                    <div class="cls-component-field-aerodrome-composed-button-frame">
                                        <!-- VOLTAR -->
                                        <div>
                                            <ui-primeng-label>&nbsp;</ui-primeng-label>
                                            <p-button icon="fa-solid fa-magnifying-glass" severity="secondary"
                                                class="cls-component-field-aerodrome-composed-button"
                                                [ngClass]="{'cls-component-field-aerodrome-composed-button-label': this._innerWidth >= this.settingConfig.WIDTH_NO_LABEL }"
                                                (click)="searchAerodromeByIcao(itemMainInputField.target)">
                                            </p-button>
                                        </div>
                                        <div>
                                            <ui-primeng-label>&nbsp;</ui-primeng-label>
                                            <p-button icon="fa-solid fa-map-location-dot" severity="secondary"
                                                class="cls-component-field-aerodrome-composed-button"
                                                [ngClass]="{'cls-component-field-aerodrome-composed-button-label': this._innerWidth >= this.settingConfig.WIDTH_NO_LABEL }"
                                                (click)="searchAerodromeByCoord(itemMainInputField.target)">
                                            </p-button>
                                        </div>
                                    </div>
                                </div>
                                <ng-container
                                    *ngIf="this.formComponent.controls.aerodromes.controls[itemMainInputField.target].controls.data.controls.name.value !== null">
                                    <div class="cls-component-field-aerodrome-composed-name-label" [ngClass]="{'cls-component-field-aerodrome-composed-name-label-green': this.objAerodromeComplete[itemMainInputField.target] === 'OK'}">
                                        {{ 
                                            this.formComponent.controls.aerodromes.controls[itemMainInputField.target].controls.data.controls.name.getRawValue()
                                        }}
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="this.objAerodromesProgressBar[itemMainInputField.target]">
                                    <div class="cls-component-field-aerodrome-composed-progress-bar">
                                        <p-progressBar mode="indeterminate" [style]="{ height: '6px' }"></p-progressBar>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="this.objAerodromesError[itemMainInputField.target] !== undefined">
                                    <ng-container
                                        *ngIf="this.objAerodromesError[itemMainInputField.target]['basicData'] !== undefined">
                                        <div class="cls-component-field-aerodrome-composed-aerodrome-error">
                                            {{
                                                this.objAerodromesError[itemMainInputField.target]['basicData']
                                            }}
                                        </div>
                                    </ng-container>
                                </ng-container>
                            </p-fieldset>
                        </div>
                    </div>
                </ng-container>
            </div>
            <div class="formgrid grid">
                <div class="field col-12">
                    <div class="cls-component-field-route">
                        <div class="cls-component-field-route-text">
                            <ui-primeng-text-area label="ROTA #BOLD#" idField="fid-route" [rowsSize]="3"
                                [control]="this.formComponent.controls.route.controls.normalized_text" sizeInput="small"
                                letterCase="upper" isDisabled="false"
                                noLabelWidth="{{this.settingConfig.WIDTH_NO_LABEL}}"
                                class="cls-component-field-aerodrome-composed-input">
                            </ui-primeng-text-area>
                        </div>
                        <div class="cls-component-field-route-buttons">
                            <!-- VOLTAR -->
                            <div class="cls-component-field-route-buttons-item">
                                <p-button icon="fa-solid fa-magnifying-glass" severity="secondary"
                                    class="cls-component-field-aerodrome-composed-button"
                                    [ngClass]="{'cls-component-field-aerodrome-composed-button-label': this._innerWidth >= this.settingConfig.WIDTH_NO_LABEL }"
                                    (click)="searchRouteData()">
                                </p-button>
                            </div>
                            <div>
                                <p-button icon="fa-solid fa-floppy-disk" severity="secondary"
                                    class="cls-component-field-aerodrome-composed-button"
                                    [ngClass]="{'cls-component-field-aerodrome-composed-button-label': this._innerWidth >= this.settingConfig.WIDTH_NO_LABEL }"
                                    (click)="saveRouteData()">
                                </p-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mb-2"></div>
            <div class="formgrid grid">
                <div class="field col-12">
                    <p-panel class="p-element cls-component-panel-form-item">
                        <ng-template pTemplate="header">
                            AERÓDROMOS ALTERNATIVOS EXTRAS
                        </ng-template>
                        <ng-template pTemplate="icons">
                            <button class="p-panel-header-icon p-link mr-2" (click)="newExtraAerodrome()">
                                <span class="fa-solid fa-circle-plus"></span>
                            </button>
                        </ng-template>
                        <ng-template pTemplate="content">
                            <div class="grid">
                                <ng-container
                                    *ngFor="let itemControl of this.formAerodrome.controls.alt_extras.controls; let currentElementIndex=index">
                                    <div class="field col-3">
                                        <div class="cls-component-field-aerodrome-composed">
                                            <div class="cls-component-field-aerodrome-composed-button-close">
                                                <p-button icon="fa-solid fa-xmark" [rounded]="true" severity="secondary"
                                                    (click)="removeExtraAerodrome(currentElementIndex)">
                                                </p-button>
                                            </div>
                                            <div class="cls-component-field-aerodrome-composed-extra-frame">
                                                <p-fieldset legend="AD Alt. {{currentElementIndex + 3}}">
                                                    <div
                                                        class="cls-component-field-aerodrome-composed-aerodrome-legend">
                                                        <img src="{{this.getAerodromeIcon('alt_extra')}}" alt="">
                                                    </div>
                                                    <div class="cls-component-field-aerodrome-composed-panel">
                                                        <ui-primeng-input-text label="ICAO / COORD. #BOLD#"
                                                            idField="fid-aerodrome-alt-extra-alt-{{currentElementIndex + 3}}"
                                                            [control]="itemControl" sizeInput="small" letterCase="upper"
                                                            isDisabled="false"
                                                            noLabelWidth="{{this.settingConfig.WIDTH_NO_LABEL}}"
                                                            class="cls-component-field-aerodrome-composed-input">
                                                        </ui-primeng-input-text>
                                                        <div
                                                            class="cls-component-field-aerodrome-composed-button-frame">
                                                            <div>
                                                                <ui-primeng-label>&nbsp;</ui-primeng-label>
                                                                <p-button icon="fa-solid fa-magnifying-glass"
                                                                    severity="secondary"
                                                                    class="cls-component-field-aerodrome-composed-button"
                                                                    [ngClass]="{'cls-component-field-aerodrome-composed-button-label': this._innerWidth >= this.settingConfig.WIDTH_NO_LABEL }"
                                                                    (click)="searchAerodromeByIcao('alt_' + (currentElementIndex + 3))">
                                                                </p-button>
                                                            </div>
                                                            <div>
                                                                <ui-primeng-label>&nbsp;</ui-primeng-label>
                                                                <p-button icon="fa-solid fa-map-location-dot"
                                                                    severity="secondary"
                                                                    class="cls-component-field-aerodrome-composed-button"
                                                                    [ngClass]="{'cls-component-field-aerodrome-composed-button-label': this._innerWidth >= this.settingConfig.WIDTH_NO_LABEL }"
                                                                    (click)="searchAerodromeByCoord('alt_' + (currentElementIndex + 3))">
                                                                </p-button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <ng-container
                                                        *ngIf=" this.formComponent.controls.aerodromes.controls.alt_extras.controls[currentElementIndex].controls.data.controls.name.value !== null">
                                                        <div class="cls-component-field-aerodrome-composed-name-label" [ngClass]="{'cls-component-field-aerodrome-composed-name-label-green': this.objAerodromeComplete['alt_' + (currentElementIndex + 3)]}">
                                                            {{ 
                                                                this.formComponent.controls.aerodromes.controls.alt_extras.controls[currentElementIndex].controls.data.controls.name.value
                                                            }}
                                                        </div>
                                                    </ng-container>
                                                    <ng-container
                                                        *ngIf="this.objAerodromesProgressBar['alt_' + (currentElementIndex + 3)]">
                                                        <div
                                                            class="cls-component-field-aerodrome-composed-progress-bar">
                                                            <p-progressBar mode="indeterminate"
                                                                [style]="{ height: '6px' }"></p-progressBar>
                                                        </div>
                                                    </ng-container>
                                                    <ng-container
                                                        *ngIf="this.objAerodromesError['alt_' + (currentElementIndex + 3)] !== undefined">
                                                        <ng-container
                                                            *ngIf="this.objAerodromesError['alt_' + (currentElementIndex + 3)]['basicData'] !== undefined">
                                                            <div
                                                                class="cls-component-field-aerodrome-composed-aerodrome-error">
                                                                {{
                                                                    this.objAerodromesError['alt_' + (currentElementIndex + 3)]['basicData']
                                                                }}
                                                            </div>
                                                        </ng-container>
                                                    </ng-container>
                                                </p-fieldset>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                        </ng-template>
                    </p-panel>
                </div>
            </div>
            <div class="mb-3"></div>
        </div>
    </p-panel>
</section>