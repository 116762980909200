//#region "|--- IMPORT MODULES/PACKAGES ---|"
// ***** ANGULAR *****
import { CommonModule } from '@angular/common';
import { Component, OnInit, Input, HostListener } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';

// ***** PRIMENG *****
import { CalendarModule } from 'primeng/calendar';
import { KeyFilterModule } from 'primeng/keyfilter';
import { TooltipModule } from 'primeng/tooltip';

// ***** CLASSES *****
import { hasControlErrors } from '../common/controls-methods';
import { ErrorMessageControlsComponent } from '../common/error-message-controls/error-message-controls.component';
//#endregion

//$sm	Breakpoint of screens such as phones.					           576px
//$md	Breakpoint of screens such as tablets.					         768px
//$lg	Breakpoint of screens such as notebook monitors.		     992px
//$xl	Breakpoint of smaller screens such as desktop monitors.	1200px

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'ui-primeng-calendar',
  standalone: true,
  imports: [
    ErrorMessageControlsComponent,
    KeyFilterModule,
    ReactiveFormsModule,
    CommonModule,
    CalendarModule,
    TooltipModule
  ],
  templateUrl: './calendar.component.html',
  styleUrl: './calendar.component.scss',
})
export class CalendarComponent implements OnInit {
  //#region "|--- INPUTS ---|"
  @Input() control!: FormControl;
  @Input() label!: string;
  @Input() idField!: string;
  @Input() sizeInput?: string;
  @Input() placeHolder?: string;
  @Input() noLabelWidth?: string;
  @Input() isDisabled?: string;
  @Input() toolTip?: string;
  @Input() leftIcon?: string;
  @Input() formatDate: string;
  @Input() minDate?: string;
  @Input() maxDate?: string;
  @Input() isUTC?: string;


  // É feito o set do @Input para que seja capturado qualquer modificação, em tempo real, 
  // do parâmetro que está sendo enviado pelo PARENT e, principalmente, que seja feito o 
  // processamento necessário com base no novo valor.
  @Input() set valueControl(xValue: any) {
    this.valueToShow = xValue;
  };
  //#endregion

  //#region "|--- PROPERTIES---|"
  innerWidth?: any;
  placeHolderContent?: string;

  showLeftIcon = false;
  showLabel = true;
  showLabelBold = false;
  showToolTipIcon = false;
  valueToShow: any;

  valueSelected: any;

  isToClear = false;

  formValue?: any | null;

  valMinData: Date | null;
  valMaxDate: Date | null;
  //#endregion

  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
    this.placeHolderContent = this.placeHolder;

    this.valMinData = null;
    this.valMaxDate = null;
    this.formValue = null;
    this.valueSelected = null;

    
    this._hasToShowLabelBold();
    this._getShowLabel();
    this._getIsDisabled();
    this._hasToShowToolTipIcon();
    this._initEventsControlForm();
  }

  //#region "|--- HOST LISTENER ---|"
  /**
   * @type LISTENER
   * @version 1.0.0 (GASPAR - 12/06/2024)
   * - Versão inicial.
   * 
   * @description 
   * - Executada toda vez que ocorre REDIMENSIONAMENTO da Tela.
   * - https://www.w3schools.com/jsref/dom_obj_event.asp
   * 
   * @param event 
   */
  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    this.innerWidth = window.innerWidth;

    this._getShowLabel();
  }
  //#endregion

  //#region "|--- LISTENERS: VALUE CHANGES ---|"
  /**
   * @status OK
   * @type METHOD
   * @version 1.0.0 (GASPAR - 13/06/2024)
   * - Versão inicial.
   * 
   * @description 
   * - Faz a inicialização dos LISTENERS que capturam as mudanças de valores dos Controle do Calendário. 
   */
  private _initEventsControlForm() {
    this.control.valueChanges.subscribe((xValue: any) => {
      const today = new Date();

      this.formValue = xValue;

      if(this.isUTC == "true"){
        this.valMinData = new Date(today.getUTCFullYear(), today.getUTCMonth(), today.getUTCDate(), 0, 0);
      } else {
        this.valMinData =  new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0);
      }
    })
  }
  //#endregion

  //#region "|--- CALLBACK ---|"
  callbackClearItem(): any {
    
  }

  callbackSelectedItem(xValue: any): any {    
    
  }
  //#endregion

  //#region "|--- PRIVATE METHODS ---|"
  /**
  * @status OK
  * @type METHOD
  * @version 1.0.0 (GASPAR - 13/06/2024)
  * - Versão inicial.
  * 
  * @description 
  * - Determina se o conteúdo do Calendário pode ser alterado ou não, via digitação de conteúdo no INPUT.
  */
  private _getIsDisabled(): void {
    if (this.isDisabled != undefined && this.isDisabled != "") {
      if (this.isDisabled == "true") {
        this.control.disable();
      } else {
        this.control.enable();
      }
    } else {
      this.control.enable();
    }
  }

  /**
   * @status OK
   * @type METHOD
   * @version 1.0.0 (GASPAR - 13/06/2024)
   * - Versão inicial.
   * 
   * @description 
   * - Verifica se é preciso mostrar o Conteúdo do LABEL em negrito.
   */
  private _hasToShowLabelBold(): void {
    if (this.label != undefined) {
      if (this.label.indexOf("#BOLD#") > 0) {
        this.showLabelBold = true;
        this.label = this.label.replace("#BOLD#", "").trim();
      }
    }
  }

  /**
   * @status OK
   * @type METHOD
   * @version 1.0.0 (GASPAR - 13/06/2024)
   * - Versão inicial.
   * 
   * @description 
   * - Verifica se é para mostrar o LABEL ou se é para mostra o placeHolder.
   * - Serve para quando se stá trabalhando com tela pequenas.
   */
  private _getShowLabel(): void {
    if (this.noLabelWidth != undefined && this.noLabelWidth != "") {
      if (this.innerWidth < Number(this.noLabelWidth)) {
        this.showLabel = false;
        this.showToolTipIcon = false;
        this.placeHolderContent = this.label;
      } else {
        this.showLabel = true;
        this._hasToShowToolTipIcon();
        this.placeHolderContent = "";
      }
    }
  }

  /**
   * @status OK
   * @type METHOD
   * @version 1.0.0 (GASPAR - 13/06/2024)
   * - Versão inicial.
   * 
   * @description 
   * - Verifica se é para mostrar o botão do Tooltip.
   */
  _hasToShowToolTipIcon(): void {
    if (this.toolTip != undefined && this.toolTip != "") {
      this.showToolTipIcon = true;
    }
  }
  //#endregion

  //#region "|--- PUBLIC METHODS ---|"  
  /**
   * @status OK
   * @type METHOD
   * @version 1.0.0 (GASPAR - 13/06/2024)
   * - Versão inicial.
   * 
   * @description 
   * - Recupera o formato de apresentação da data no Calendário. 
   * - Formato padrão: dd/mm/yy
   */
  getFormatDate() {
    if (this.formatDate) {
      return this.formatDate;
    } else {
      return "dd/mm/yy";
    }
  }

  /**
   * @status OK
   * @type METHOD
   * @version 1.0.0 (GASPAR - 13/06/2024)
   * - Versão inicial.
   * 
   * @description 
   * - Retorna qual o conteúdo a ser mostrado no Place Holder.. 
   */
  getPlaceHolder() {
    if (this.placeHolder) {
      return this.placeHolder;
    } else {
      return "dd/mm/aaaa";
    }
  }

  /**
  * @status OK
  * @type METHOD
  * @version 1.0.0 (GASPAR - 13/06/2024)
  * - Versão inicial.
  * 
  * @description 
  * - Retorna se o conteúdo do LABEL vai ser mostrado em negrito, ou seja, se deve aplicar a classe que torna o conteúdo em negrito. 
  */
  getShowLabelBold(): boolean {
    return this.showLabelBold;
  }

  /**
   * @status OK
   * @type METHOD
   * @version 1.0.0 (GASPAR - 13/06/2024)
   * - Versão inicial.
   * 
   * @description 
   * - Retorna se o conteúdo do TOOLTIP, caso exista. 
   */
  getTooTipContent(): string {
    if (this.toolTip != undefined && this.toolTip != "") {
      return this.toolTip;
    } else {
      return "";
    }
  }

  /**
   * @status OK
   * @type METHOD
   * @version 1.0.0 (GASPAR - 13/06/2024)
   * - Versão inicial.
   * 
   * @description 
   * - Retorna o nome do componente com base no Id que foi passado.
   */
  getFieldName() {
    return this.idField.replace("fid-", "");
  }

  /**
   * @status OK
   * @type METHOD
   * @version 1.0.0 (GASPAR - 13/06/2024)
   * - Versão inicial.
   * 
   * @description 
   * - Verifica se é para mostrar o Icone no Input do Calendário
   */
  getShowIcon(): boolean {
    if (this.leftIcon) {
      return (this.leftIcon.toLowerCase() === "true");
    } else {
      return this.showLeftIcon;
    }
  }

  /**
   * @status OK
   * @type METHOD
   * @version 1.0.0 (GASPAR - 13/06/2024)
   * - Versão inicial.
   * 
   * @description 
   * - Verifica se existe mensagens de erros a serem mostradas.. 
   */
  showErrors() {
    return hasControlErrors(this.control, "input");
  }
  //#endregion 
}
