<section class="cls-component-data-panel">
    <p-panel header="Aeronave e Comando" class="cls-component-panel-formgrid">
        <div class="cls-component-form">
            <div class="formgrid grid">
                <div class="col-6 md:col-6 xl:col-3">
                    <!--<ui-primeng-input-text label="ACFT Registro #BOLD#" idField="fid-aircraft-mark"
                        [control]="this.formComponent.controls.aircraft_mark"
                        sizeInput="small" letterCase="upper" maxCharacters="8" isDisabled="false"
                        noLabelWidth="{{this.pageInnerWidth}}">
                    </ui-primeng-input-text>-->
                    <ui-primeng-dropdown-template-icon idField="fid-aircraft-mark" label="ACFT Registro #BOLD#" placeHolder="..."
                        sizeInput="small" [control]="this.formComponent.controls.aircraft_mark"
                        [itemsArray]="this.optionsAircraft" withFilter="true" noLabelWidth="{{this.pageInnerWidth}}">
                    </ui-primeng-dropdown-template-icon>
                </div>
                <div class="col-6 md:col-6 xl:col-3">
                    <!--<ui-primeng-input-text label="Operador #BOLD#" idField="fid-operator"
                        [control]="this.formComponent.controls.operator"
                        sizeInput="small" letterCase="upper" maxCharacters="150" isDisabled="false"
                        noLabelWidth="{{this.pageInnerWidth}}">
                    </ui-primeng-input-text>-->
                    <ui-primeng-dropdown label="Operador #BOLD#" idField="fid-operator" placeHolder="" sizeInput="small"
                        [control]="this.formComponent.controls.operator" [itemsArray]="this.optionsOperators"
                        withFilter="false" noLabelWidth="{{this.pageInnerWidth}}">
                    </ui-primeng-dropdown>
                </div>

                <div class="col-6 md:col-6 xl:col-3">
                    <ui-primeng-input-text label="Piloto em Comando #BOLD#" idField="fid-first-command"
                        [control]="this.formComponent.controls.crew.controls.first_in_command.controls.normalized_text"
                        sizeInput="small" letterCase="upper" maxCharacters="150" isDisabled="false"
                        noLabelWidth="{{this.pageInnerWidth}}">
                    </ui-primeng-input-text>
                    <!--<ui-primeng-dropdown label="Piloto em Comando #BOLD#" idField="fid-first-command"
                        placeHolder="..." sizeInput="small"
                        [control]="this.formComponent.controls.crew.controls.first_in_command.controls.fc_normalized_text"
                        [itemsArray]="this.optionsPilots" withFilter="true" noLabelWidth="{{this.pageInnerWidth}}">
                    </ui-primeng-dropdown>-->
                </div>
                <div class="col-6 md:col-6 xl:col-3">
                    <ui-primeng-input-text label="Segundo em Comando #BOLD#" idField="fid-second-command"
                        [control]="this.formComponent.controls.crew.controls.second_in_command.controls.normalized_text"
                        sizeInput="small" letterCase="upper" maxCharacters="150" isDisabled="false"
                        noLabelWidth="{{this.pageInnerWidth}}">
                    </ui-primeng-input-text>
                    <!--<ui-primeng-dropdown label="Segundo em Comando" idField="fid-second-in-pilot" placeHolder=""
                        sizeInput="small"
                        [control]="this.formComponent.controls.crew.controls.second_in_command.controls.pilot_id"
                        [itemsArray]="this.optionsPilots" withFilter="true" noLabelWidth="{{this.pageInnerWidth}}">
                    </ui-primeng-dropdown>-->
                </div>
                
            </div>
        </div>
    </p-panel>
</section>