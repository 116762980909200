<article id="id-flight-plan-list" class="cls-page-frame">
  <!-- TOAST-->
  <nashville-page-toast [objToastMessage]="this.objToastMessage"></nashville-page-toast>
  <!-- TITLE -->
  <nashville-page-title pageTitle="{{this.currentPageTitle}}"></nashville-page-title>
  <!-- CONTENT -->
  <div class="cls-page-frame-content">
    <!-- TOOLBAR -->
    <nashville-toolbar-table-flight-request-list (toolbarAction)="this.callBackToolbarFormList($event)">
    </nashville-toolbar-table-flight-request-list>
    <div class="cls-component-table-page-list">
      <!-- FILTER -->
      <p-accordion>
        <p-accordionTab iconPos="end">
          <ng-template pTemplate="header">
            <div class="cls-component-table-page-list-filter-title">
              <span><i class="fa-solid fa-filter"></i> FILTRO DE PESQUISA</span>
            </div>
          </ng-template>
          <ng-template pTemplate="content">
            <p>
              Lorem ipsum dolor sit amet...
            </p>
          </ng-template>

        </p-accordionTab>
      </p-accordion>
      <!-- TABLE -->
      <p-table #dt1 [value]="this.arrayAllFlightRequests" [lazy]="true" [loading]="loading"
        [tableStyle]="{ 'min-width': '50rem' }" [breakpoint]="'{{this.settingConfig.TABLE_STACK}}'" [rows]="10"
        [rowsPerPageOptions]="[10, 15, 20]" [paginator]="true" [customSort]="true"
        [globalFilterFields]="['flight_request_code.normalized_text', 'aircraft_mark', 'aerodromes.departure.data.icao_code', 'flightRequest.aerodromes.destination.data.icao_code', 'date_time_eobt.date_zulu', 'date_time_eobt.time_zulu']"
        [totalRecords]="totalRecords" [scrollable]="true" [resizableColumns]="true"
        (onLazyLoad)="loadFlightRequest($event)" scrollHeight="500px" sortMode="multiple"
        styleClass="p-datatable-sm p-datatable-striped p-datatable-gridlines">
        <ng-template pTemplate="caption">
          <div class="flex align-items-center justify-content-between">
            <div class="cls-component-table-page-list-table-title">
              <!--<span><i class="fa-solid fa-table"></i> REQUISIÇÕES DE VOO </span>-->
            </div>
            <div class="flex">
              <p-iconField iconPosition="left" class="ml-auto">
                <p-inputIcon>
                  <i class="fa-solid fa-magnifying-glass"></i>
                </p-inputIcon>
                <input pInputText type="text" (input)="applyFilterGlobal($event, 'contains')"
                  placeholder="Procurar conteúdo" />
              </p-iconField>
            </div>
          </div>
        </ng-template>
        <ng-template pTemplate="header">
          <tr>
            <th style="width:11%; text-align:center">CÓDIGO REQUISIÇÃO</th>
            <th style="width:8%; text-align:center">
              MATRÍCULA
              <p-sortIcon field="aircraft_mark" />
            </th>
            <th style="width:25%; text-align:left">
              AD. PARTIDA
              <p-sortIcon field="aerodromes.departure.data.icao_code" />
            </th>
            <th style="width:25%; text-align:left">
              AD. DESTINO
              <p-sortIcon field="aerodromes.destination.data.icao_code" />
            </th>
            <th style="width:8%; text-align:center">
              DATA EOBT (Z)
              <p-sortIcon field="date_time_eobt.date_zulu" />
            </th>
            <th style="width:8%; text-align:center">
              HORA EOBT (Z)
              <p-sortIcon field="date_time_eobt.time_zulu" />
            </th>
            <th style="width:15%; text-align:right"></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-flightRequest>
          <tr>
            <td style="text-align:center">
              <!-- FLIGHT REQUEST CODE NORMALIZED TEXT -->
              {{ flightRequest.flight_request_code.normalized_text }}
            </td>
            <td style="text-align:center">
              <!-- AIRCRAFT MARK -->
              {{ this.localMethodsHandlerClass.aircraftMarkPutMask(flightRequest.aircraft_mark) }}
            </td>
            <td style="text-align:left">
              <!-- ICAO CODE PARTIDA -->
              <div class="">
                <span class="">
                  {{ flightRequest.aerodromes.departure.data.icao_code }}
                </span>
              </div>
              <!-- NOME DO AERÓDROMO PARTIDA -->
              <ng-container *ngIf="flightRequest.aerodromes.departure.data.name !== ''">
                <div>
                  <span class="">
                    {{ flightRequest.aerodromes.departure.data.name }}
                  </span>
                </div>
              </ng-container>
              <!-- COORDENADA AERÓDROMO PARTIDA -->
              <ng-container *ngIf="flightRequest.aerodromes.departure.data.name === ''">
                <div>
                  <span class="">
                    ({{
                      flightRequest.aerodromes.departure.data.coordinate_dd[0].toFixed(2)
                    }},
                    {{
                      flightRequest.aerodromes.departure.data.coordinate_dd[1].toFixed(2)
                    }})
                  </span>
                </div>
              </ng-container>
            </td>
            <td style="text-align:left">
              <!-- ICAO CODE DESTINO -->
              <div class="">
                <span class="">
                  {{ flightRequest.aerodromes.destination.data.icao_code }}
                </span>
              </div>
              <!-- NOME DO AERÓDROMO DESTINO -->
              <ng-container *ngIf="flightRequest.aerodromes.destination.data.name !== ''">
                <div>
                  <span class="">
                    {{ flightRequest.aerodromes.destination.data.name }}
                  </span>
                </div>
              </ng-container>
              <!-- COORDENADA AERÓDROMO DESTINO -->
              <ng-container *ngIf="flightRequest.aerodromes.destination.data.name === ''">
                <div>
                  <span class="">
                    ({{
                      flightRequest.aerodromes.destination.data.coordinate_dd[0].toFixed(2)
                    }},
                    {{
                      flightRequest.aerodromes.destination.data.coordinate_dd[1].toFixed(2)
                    }})
                  </span>
                </div>
              </ng-container>
            </td>
            <td style="text-align:center">
              <!-- DATE ZULU -->
              {{ flightRequest.date_time_eobt.date_zulu }}
            </td>
            <td style="text-align:center">
              <!-- TIME ZULU -->
              {{ flightRequest.date_time_eobt.time_zulu }}
            </td>
            <td style="text-align:right" class="cls-component-table-page-list-td-action">
              <p-button icon="fa-solid fa-pen-to-square" [rounded]="true" severity="secondary" (click)="
                    this.updateFlightPlanning(flightRequest.flight_request_id)
                  "></p-button>
              <p-button icon="fa-solid fa-trash-can" [rounded]="true" severity="danger"></p-button>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="summary">
          <div class="flex align-items-center justify-content-between">
            Total de Requisições de Voo recuperadas:
            {{this.arrayAllFlightRequests ? this.arrayAllFlightRequests.length : 0 }} products.
          </div>
        </ng-template>
      </p-table>
    </div>









    <!--<section id="id-toolbar-flight-request-list" class="cls-component-toolbar-control-list">
      <p-toolbar class="cls-component-toolbar-control-list-pattern">
        <div class="p-toolbar-group-start"></div>
        <div class="p-toolbar-group-center"></div>
        <div class="p-toolbar-group-end">
          <div class="cls-page-box-right">
            <p-button label="NOVO PLANO" icon="fa-solid fa-plus" [raised]="true" severity="success"
              class="cls-component-button-pattern-default"></p-button>
            <p-button label="ATUALIZAR" icon="fa-solid fa-rotate" [raised]="true" severity="warning"
              class="cls-component-button-pattern-default"></p-button>
          </div>
        </div>
      </p-toolbar>
    </section>
    <div class="cls-content-inside-box">
      <p-fieldset [toggleable]="true" [collapsed]="true" class="cls-component-fieldset-filter">
        <ng-template pTemplate="header">
          <div class="flex align-items-center gap-2 px-2">
            <span class="font-bold">Filtros Tabela</span>
          </div>
        </ng-template>
        <div class="">
          <div class="cls-component-form">
            <div class="formgrid grid">
              <!--<div class="field col-4">
                                <ui-primeng-input-text label="Cód. ICAO" idField="fid-aircraft-mark"
                                    [control]="this.formFilterTableSearchAerodrome.controls.icao_code"
                                    sizeInput="small" letterCase="upper" maxCharacters="4"
                                    isDisabled="false" noLabelWidth="1410">
                                </ui-primeng-input-text>
                            </div>
                            <div class="field col-4">
                                <ui-primeng-input-text label="Tipo" idField="fid-aircraft-mark"
                                    [control]="this.formFilterTableSearchAerodrome.controls.aerodrome_type"
                                    sizeInput="small" letterCase="upper" maxCharacters="5"
                                    isDisabled="false" noLabelWidth="1410">
                                </ui-primeng-input-text>
                            </div>
                            <div class="field col-4">
                                <ui-primeng-input-text label="Nome" idField="fid-aircraft-mark"
                                    [control]="this.formFilterTableSearchAerodrome.controls.name"
                                    sizeInput="small" letterCase="upper" maxCharacters="5"
                                    isDisabled="false" noLabelWidth="1410">
                                </ui-primeng-input-text>
                            </div>
                            <div class="field col-4">
                                <ui-primeng-input-text label="País" idField="fid-aircraft-mark"
                                    [control]="this.formFilterTableSearchAerodrome.controls.country"
                                    sizeInput="small" letterCase="upper" maxCharacters="25"
                                    isDisabled="false" noLabelWidth="1410">
                                </ui-primeng-input-text>
                            </div>
                            <div class="field col-4">
                                <ui-primeng-input-text label="Estado" idField="fid-aircraft-mark"
                                    [control]="this.formFilterTableSearchAerodrome.controls.state"
                                    sizeInput="small" letterCase="upper" maxCharacters="15"
                                    isDisabled="false" noLabelWidth="1410">
                                </ui-primeng-input-text>
                            </div>
                            <div class="field col-4">
                                <ui-primeng-input-text label="Cidade" idField="fid-aircraft-mark"
                                    [control]="this.formFilterTableSearchAerodrome.controls.city"
                                    sizeInput="small" letterCase="upper" maxCharacters="5"
                                    isDisabled="false" noLabelWidth="1410">
                                </ui-primeng-input-text>
                            </div>
            </div>
          </div>
        </div>
      </p-fieldset>
      <p-table #dt [value]="this.arrayAllFlightPlans"
        class="cls-component-table-list cls-component-layout-table-flight-plan">
        <ng-template pTemplate="header">
          <tr>
            <th>Código</th>
            <th>Aeronave</th>
            <th>AD. Partida</th>
            <th>AD. Destino</th>
            <th>Data EOBT (Z)</th>
            <th>HORA EOBT (Z)</th>
            <th></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-flight_plan>
          <tr>
            <td>
              <div class="cls-component-layout-table-flight-plan-item-code">
                {{ flight_plan.flight_request_code.normalized_text }}
              </div>
            </td>
            <td>
              <div class="cls-component-layout-table-flight-plan-item-aircraft-mark">
                {{ flight_plan.aircraft_mark }}
              </div>
            </td>
            <td>
              <div class="cls-component-layout-table-flight-plan-item-aerodrome">
                <span class="cls-component-layout-table-flight-plan-item-aerodrome-icao">
                  {{ flight_plan.aerodromes.departure.data.icao_code }}
                </span>
                <span class="cls-component-layout-table-flight-plan-item-aerodrome-coordinate">
                  ({{
                    flight_plan.aerodromes.departure.data.coordinate_dd[0].toFixed(
                      2
                    )
                  }},
                  {{
                    flight_plan.aerodromes.departure.data.coordinate_dd[1].toFixed(
                      2
                    )
                  }})
                </span>
              </div>
              <div>
                <span class="cls-component-layout-table-flight-plan-item-aerodrome-name">
                  {{ flight_plan.aerodromes.departure.data.name }}
                </span>
              </div>
            </td>
            <td>
              <div class="cls-component-layout-table-flight-plan-item-aerodrome">
                <span class="cls-component-layout-table-flight-plan-item-aerodrome-icao">
                  {{ flight_plan.aerodromes.destination.data.icao_code }}
                </span>
              </div>

              <ng-container *ngIf="flight_plan.aerodromes.destination.data.name !== ''">
                <div>
                  <span class="cls-component-layout-table-flight-plan-item-aerodrome-name">
                    {{ flight_plan.aerodromes.destination.data.name }}
                  </span>
                </div>
              </ng-container>

              <ng-container *ngIf="flight_plan.aerodromes.destination.data.name === ''">
                <div>
                  <span class="cls-component-layout-table-flight-plan-item-aerodrome-coordinate">
                    ({{
                      flight_plan.aerodromes.destination.data.coordinate_dd[0].toFixed(
                        2
                      )
                    }},
                    {{
                      flight_plan.aerodromes.destination.data.coordinate_dd[1].toFixed(
                        2
                      )
                    }})
                  </span>
                </div>
              </ng-container>
            </td>
            <td>
              <span class="cls-component-layout-table-flight-plan-item-date-zulu">
                {{ flight_plan.date_time_eobt.date_zulu }}
              </span>
            </td>
            <td>
              <span class="cls-component-layout-table-flight-plan-item-time-zulu">
                {{ flight_plan.date_time_eobt.time_zulu }}
              </span>
            </td>
            <td>
              <div class="cls-component-layout-table-flight-plan-item-button">
                <p-button icon="pi pi-pencil" [rounded]="true" severity="secondary" (click)="
                    this.updateFlightPlanning(flight_plan.flight_request_id)
                  "></p-button>
              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>-->
  </div>
</article>