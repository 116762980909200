import { Component, OnInit, Input, HostListener } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, ReactiveFormsModule } from '@angular/forms';

import { AutoCompleteCompleteEvent, AutoCompleteModule } from 'primeng/autocomplete';
import { KeyFilterModule } from 'primeng/keyfilter';
import { TooltipModule } from 'primeng/tooltip';

import { ErrorMessageControlsComponent } from '../common/error-message-controls/error-message-controls.component';
import { hasControlErrors } from '../common/controls-methods';
import { ParseError } from '@angular/compiler';

//$sm	Breakpoint of screens such as phones.					           576px
//$md	Breakpoint of screens such as tablets.					         768px
//$lg	Breakpoint of screens such as notebook monitors.		     992px
//$xl	Breakpoint of smaller screens such as desktop monitors.	1200px

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'ui-primeng-auto-complement-input-text-button',
  standalone: true,
  imports: [
    AutoCompleteModule,
    ErrorMessageControlsComponent,
    KeyFilterModule,
    ReactiveFormsModule,
    CommonModule,
    TooltipModule
  ],
  templateUrl: './auto-complement-input-text-button.component.html',
  styleUrl: './auto-complement-input-text-button.component.scss',
})
export class AutoComplementInputTextButtonComponent implements OnInit {
  //#region "|--- INPUTS ---|"
  @Input() control!: FormControl;
  @Input() label!: string;
  @Input() idField!: string;
  @Input() sizeInput?: string;
  @Input() typeInput?: string;
  @Input() arraySuggestions!: any[];
  @Input() placeHolder?: string;
  @Input() noLabelWidth?: string;
  @Input() maxCharacters?: number;
  @Input() letterCase?: string;
  @Input() toolTip?: string;
  //#endregion

  //#region "|--- PROPERTIES---|"
  innerWidth?: any;
  placeHolderContent?: string;

  showLabel = true;
  showLabelBold = false;
  showToolTipIcon = false;
  //#endregion

  ngOnInit(): void {
    if (this.letterCase != undefined && this.letterCase != "" && this.letterCase != null) {
      if (this.letterCase.toLocaleLowerCase() == "upper") {

      } else {
        this.letterCase = "lower";
      }
    } else {
      this.letterCase = "lower";
    }

    this.innerWidth = window.innerWidth;
    this.placeHolderContent = this.placeHolder;

    this._hasToShowLabelBold();
    this._getShowLabel();
  }

  /**
   * (v1) - Executada toda vez que ocorre REDIMENSIONAMENTO da Tela.
   * https://www.w3schools.com/jsref/dom_obj_event.asp
   * @param event
   */
  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    this.innerWidth = window.innerWidth;

    this._getShowLabel();
  }

  /**
   * TODO: https://tsdoc.org/
   */
  getInputType(): string {
    if (this.typeInput) {
      return this.typeInput; // Retorna o que foi enviado durante a criação do componente
    } else {
      return "text"; // Retorna o Valor Padrão
    }
  }

  /**
   * TODO: https://tsdoc.org/
   */
  getMaxCharacters(): number {
    if (this.maxCharacters) {
      return this.maxCharacters; // Retorna o que foi enviado durante a criação do componente
    } else {
      return 10000; // Retorna o padrão texto
    }
  }

  filterContent(event: AutoCompleteCompleteEvent) {
    let filtered: any[] = [];
    let query = event.query;

    for (let i = 0; i < this.arraySuggestions.length; i++) {
      let tmpElement = this.arraySuggestions[i];

      // Serve para acrescentar o Elemento na ultima posição, pois assim evita a ampulheta fique rodando igual uma louca
      if (tmpElement.toUpperCase().indexOf(query.toUpperCase()) == 0) {
        filtered.unshift(tmpElement); // Coloca na Primeira Posição
      }
    }

    this.arraySuggestions = filtered;
  }

  /**
   * TODO: https://tsdoc.org/
   */
  getShowLabelBold(): boolean {
    return this.showLabelBold;
  }

  /**
   * TODO: https://tsdoc.org/
   */
  getInputClass(): string {
    //let tmpReturn = "text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full";
    let tmpReturn = "w-full";

    // Faz a verificação da Caixa da Letra.

    if (this.letterCase != undefined && this.letterCase != "") {
      if (this.letterCase.toLowerCase() == "upper") {
        tmpReturn = `${tmpReturn} cls-text-uppercase`;
      } else if (this.letterCase.toLowerCase() == "lower") {
        tmpReturn = `${tmpReturn} cls-text-lowercase`;
      }
    }

    // Faz a Verificação do tamanho do Input.

    if (this.sizeInput != undefined && this.sizeInput != "") {
      if (this.sizeInput.toLowerCase() == "small") {
        tmpReturn = `${tmpReturn} p-inputtext-sm`;
      } else if (this.sizeInput.toLowerCase() == "large") {
        tmpReturn = `${tmpReturn} p-inputtext-lg`;
      } else {
        tmpReturn = `${tmpReturn}`;
      }
    }

    return tmpReturn
  }

  /**
   * TODO: https://tsdoc.org/
   */
  showErrors() {
    return hasControlErrors(this.control, "input");
  }

  /**
  * TODO: https://tsdoc.org/
  */
  getTooTipContent(): string {
    if (this.toolTip != undefined && this.toolTip != "") {
      return this.toolTip;
    } else {
      return "";
    }
  }

  /**
 * TODO: https://tsdoc.org/
 */
  private _hasToShowLabelBold(): void {
    if (this.label != undefined) {
      if (this.label.indexOf("#BOLD#") > 0) {
        this.showLabelBold = true;
        this.label = this.label.replace("#BOLD#", "").trim();
      }
    }
  }

  /**
   * TODO: https://tsdoc.org/
   */
  private _getShowLabel(): void {
    if (this.noLabelWidth != undefined && this.noLabelWidth != "") {
      if (this.innerWidth < Number(this.noLabelWidth)) {
        this.showLabel = false;
        this.showToolTipIcon = false;
        this.placeHolderContent = this.label;
      } else {
        this.showLabel = true;
        this._hasToShowToolTipIcon();
        this.placeHolderContent = "";
      }
    }
  }

  /**
  * TODO: https://tsdoc.org/
  */
  _hasToShowToolTipIcon(): void {
    if (this.toolTip != undefined && this.toolTip != "") {
      this.showToolTipIcon = true;
    }
  }
}
