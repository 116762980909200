//#region "|--- IMPORT MODULES/PACKAGES ---|"
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';

import { settingConfig } from '@nashville-config';

import { PrimengComponentsModule } from '@nashville-primeng';
//#endregion

//#region "|--- ENUNS ---|"
import { BUTTON_ACTION } from '../../../../../../helpers/enum/ButtonActions';
import { FormGroup, UntypedFormControl } from '@angular/forms';
import { FlightPlanApisService } from 'src/app/features/flight-plan/services/flight-plan-data-apis.service';
import { MessageService } from 'primeng/api';
import { IPatternResponseFromAPI } from 'src/app/interfaces/IPatternCrudResponseFromAPI';
import { IDropDownBasicOptions } from 'src/app/interfaces/IDropDownBasicOptions';
import { typeAerodromeOptions } from 'src/app/helpers/dropdown-static-options/typeAerodromeOptions';
//#endregion

@Component({
  selector:
    'nashville-dialog-search-aerodrome-icao',
  standalone: true,
  imports: [CommonModule, PrimengComponentsModule],
  templateUrl: './dialog-search-aerodrome-icao.component.html',
  styleUrl: './dialog-search-aerodrome-icao.component.scss',
})
export class DialogSearchAerodromeIcaoComponent implements OnInit {
  //#region "|--- INPUTS ---|"
  @Input() showDialog!: boolean;
  //#endregion

  //#region "|--- OUTPUTS ---|"
  @Output() dialogButtonsAction = new EventEmitter<string>();
  //#endregion

  //#region "|--- PROPERTIES---|"
  settingConfig!: any;

  labelActionsButtons: any;

  formFilterSearchRecordedAerodrome!: any;

  arrayAerodromeSearch: any[] = [];

  optionsTypeAerodrome!: IDropDownBasicOptions[];

  filteredByIcaoCode!: any[];
  filteredByName!: any[];
  filteredByCity!: any[];
  //#endregion

  constructor(
    private flightPlanApisService: FlightPlanApisService,
    private messageService: MessageService,
  ) { }

  /**
   * TODO: https://tsdoc.org/
   */
  ngOnInit(): void {
    this._initVariables();

    this._initFilterSearchRecordedAerodromeForm();
    this._initFilterSearchRecordedAerodromeEvents();
  }

  /**
   * TODO: https://tsdoc.org/
   */
  callbackButton(xValue: string) {
    this.dialogButtonsAction.emit(xValue);
  }

  //#region "|--- PRIVATE METHODS---|" 
  /**
   * TODO: https://tsdoc.org/
   */
  private _initVariables(): void {
    this.labelActionsButtons = BUTTON_ACTION;

    this.settingConfig = settingConfig;

    this.optionsTypeAerodrome = typeAerodromeOptions;

    this.arrayAerodromeSearch = [];
  }

  private _initFilterSearchRecordedAerodromeForm() {
    this.formFilterSearchRecordedAerodrome = new FormGroup({
      icao_code: new UntypedFormControl(null),
      aerodrome_type: new UntypedFormControl(null),
      name: new UntypedFormControl(null),
      state: new UntypedFormControl(null),
      city: new UntypedFormControl(null),
      coordinate: new UntypedFormControl(null)
    });
  }

  /**
   * TODO: https://tsdoc.org/
   */
  private _initFilterSearchRecordedAerodromeEvents() {
    this.formFilterSearchRecordedAerodrome
      .get('icao_code')
      .valueChanges.subscribe((xValue: any) => {
        // this.control.patchValue(this.control.value.toLowerCase());
        if (xValue) {
          this.formFilterSearchRecordedAerodrome.controls.name.setValue(null, {emitEvent: false});
          this.formFilterSearchRecordedAerodrome.controls.aerodrome_type.setValue(null, {emitEvent: false});
          this.formFilterSearchRecordedAerodrome.controls.state.setValue(null, {emitEvent: false});
          this.formFilterSearchRecordedAerodrome.controls.city.setValue(null, {emitEvent: false});
          this.formFilterSearchRecordedAerodrome.controls.coordinate.setValue(null, {emitEvent: false});

          // Verifica se existe valor.
          if (xValue.length > 1) {
            // Vai buscar auto complemente a partir de 2 letras.
            this.filteredByIcaoCode = []; // Tem que zerar antes, para preencher corretamente.
            this.arrayAerodromeSearch = []; // Tem que zerar qualquer aeródromo que tenha sido mostrado na Tabela

            // Construindo a pesquisa
            const fieldToSearch = {
              icao_code: xValue.toUpperCase()
            };

            this.flightPlanApisService
              .getAerodromeBasicDataSuggestionStart(fieldToSearch)
              .subscribe({
                next: (xResponseService: IPatternResponseFromAPI) => {

                  if (xResponseService.status_code == 200) {
                    const dataInfo = xResponseService.data_info.data;

                    // Refaz o Array, forçando alteração
                    this.filteredByIcaoCode = dataInfo.map(
                      (xElement: any) => xElement.icao_code,
                    );
                    this.arrayAerodromeSearch = dataInfo;

                    //TODO: TRANSLATION
                    //TODO: MESSENGER
                    //TODO: TOAST
                    this.messageService.add({
                      severity: 'success',
                      summary: 'Recuperar Aeródromo',
                      detail: `Aeródromo ${xValue} Recuperado com Sucesso.`,
                    });
                  } else {
                    this.filteredByIcaoCode = [];
                    this.arrayAerodromeSearch = [];

                    //TODO: TRANSLATION
                    //TODO: MESSENGER
                    //TODO: TOAST
                    this.messageService.add({
                      severity: 'error',
                      summary: 'Recuperar Aeródromo',
                      detail: `Erro ao Recuperar Dados do Aeródromo ${xValue}.`,
                    });
                  }
                },
                complete: () => { },
                error: (xErrorService: any) => {
                  this.filteredByIcaoCode = [];
                  this.arrayAerodromeSearch = []; // Tem que zerar qualquer aeródromo que tenha sido mostrado na Tabela

                  //TODO: TRANSLATION
                  //TODO: MESSENGER
                  //TODO: TOAST
                  // USAR SOMENTE PARA DESENVOLVIMENTO
                  this.messageService.add({
                    severity: 'error',
                    summary: 'Recuperar Aeródromo',
                    detail: 'TEM QUE ARRUMAR ESTE ERRO. NA FUNCAO QUE SUGERE',
                  });
                },
              });
          } else {
            this.filteredByIcaoCode = []; // Nenhum Conteúdo, tem que zerar a sugestão.
            this.arrayAerodromeSearch = []; // Tem que zerar qualquer aeródromo que tenha sido mostrado na Tabela
          }
        } else {
          this.filteredByIcaoCode = []; // Nenhum Conteúdo, tem que zerar a sugestão.
          this.arrayAerodromeSearch = []; // Tem que zerar qualquer aeródromo que tenha sido mostrado na Tabela
        }
      });

    this.formFilterSearchRecordedAerodrome
      .get('name')
      .valueChanges.subscribe((xValue: any) => {
        // this.control.patchValue(this.control.value.toLowerCase());
        if (xValue) {
          this.formFilterSearchRecordedAerodrome.controls.icao_code.setValue(null, {emitEvent: false});
          this.formFilterSearchRecordedAerodrome.controls.aerodrome_type.setValue(null, {emitEvent: false});
          this.formFilterSearchRecordedAerodrome.controls.state.setValue(null, {emitEvent: false});
          this.formFilterSearchRecordedAerodrome.controls.city.setValue(null, {emitEvent: false});
          this.formFilterSearchRecordedAerodrome.controls.coordinate.setValue(null, {emitEvent: false});

          // Verifica se existe valor.
          if (xValue.length > 1) {
            // Vai buscar auto complemente a partir de 2 letras.
            this.filteredByName = []; // Tem que zerar antes, para preencher corretamente.
            this.arrayAerodromeSearch = []; // Tem que zerar qualquer aeródromo que tenha sido mostrado na Tabela

            // Construindo a pesquisa
            const fieldToSearch = {
              name: xValue.toLowerCase()
            };

            this.flightPlanApisService
              .getAerodromeBasicSuggestionContains(fieldToSearch)
              .subscribe({
                next: (xResponseService: IPatternResponseFromAPI) => {
                  if (xResponseService.status_code == 200) {
                    const dataInfo = xResponseService.data_info.data;

                    // Refaz o Array, forçando alteração
                    this.filteredByName = dataInfo.map(
                      (xElement: any) => xElement.icao_code,
                    );
                    this.arrayAerodromeSearch = dataInfo;

                    //TODO: TRANSLATION
                    //TODO: MESSENGER
                    //TODO: TOAST
                    this.messageService.add({
                      severity: 'success',
                      summary: 'Recuperar Aeródromo',
                      detail: `Aeródromo ${xValue} Recuperado com Sucesso.`,
                    });
                  } else {
                    this.filteredByName = []; // Nenhum Conteúdo, tem que zerar a sugestão.
                    this.arrayAerodromeSearch = []; // Tem que zerar qualquer aeródromo que tenha sido mostrado na Tabela

                    //TODO: TRANSLATION
                    //TODO: MESSENGER
                    //TODO: TOAST
                    this.messageService.add({
                      severity: 'error',
                      summary: 'Recuperar Aeródromo',
                      detail: `Erro ao Recuperar Dados do Aeródromo ${xValue}.`,
                    });
                  }

                  // Lembre-se: está sendo executado um OBSERVER, ou seja, ele é assíncrono e é difícil esperar o seu final para executar outro método.
                  // Para que os aeródromos sejam plotados no MAPA é preciso "forçar" uma atualização do conteúdo para que seja pego pelo componente de mapa, via "INPUT PROPERTY SETTER"
                  // Forçar no final da execução do OBSERVER
                  /////////////////////this._forceReloadArrayAerodromesToMarkMapValue();
                },
                complete: () => { },
                error: (xErrorService: any) => {
                  this.filteredByName = [];
                  this.arrayAerodromeSearch = []; // Tem que zerar qualquer aeródromo que tenha sido mostrado na Tabela

                  //TODO: TRANSLATION
                  //TODO: MESSENGER
                  //TODO: TOAST
                  // USAR SOMENTE PARA DESENVOLVIMENTO
                  this.messageService.add({
                    severity: 'error',
                    summary: 'Recuperar Aeródromo',
                    detail: 'TEM QUE ARRUMAR ESTE ERRO. NA FUNCAO QUE SUGERE',
                  });
                },
              });
          } else {
            this.filteredByName = []; // Nenhum Conteúdo, tem que zerar a sugestão.
            this.arrayAerodromeSearch = []; // Tem que zerar qualquer aeródromo que tenha sido mostrado na Tabela
          }
        } else {
          this.filteredByName = []; // Nenhum Conteúdo, tem que zerar a sugestão.
          this.arrayAerodromeSearch = []; // Tem que zerar qualquer aeródromo que tenha sido mostrado na Tabela
        }
      });

    this.formFilterSearchRecordedAerodrome
      .get('city')
      .valueChanges.subscribe((xValue: any) => {
        // this.control.patchValue(this.control.value.toLowerCase());
        if (xValue) {
          this.formFilterSearchRecordedAerodrome.controls.name.setValue(null, {emitEvent: false});
          this.formFilterSearchRecordedAerodrome.controls.icao_code.setValue(null, {emitEvent: false});
          this.formFilterSearchRecordedAerodrome.controls.aerodrome_type.setValue(null, {emitEvent: false});
          this.formFilterSearchRecordedAerodrome.controls.state.setValue(null, {emitEvent: false});
          this.formFilterSearchRecordedAerodrome.controls.coordinate.setValue(null, {emitEvent: false});

          // Verifica se existe valor.
          if (xValue.length > 1) {
            // Vai buscar auto complemente a partir de 2 letras.
            this.filteredByCity = []; // Tem que zerar antes, para preencher corretamente.
            this.arrayAerodromeSearch = []; // Tem que zerar qualquer aeródromo que tenha sido mostrado na Tabela

            // Construindo a pesquisa
            const fieldToSearch = {
              'place.city': xValue.toLowerCase()
            };

            this.flightPlanApisService
              .getAerodromeBasicSuggestionContains(fieldToSearch)
              .subscribe({
                next: (xResponseService: IPatternResponseFromAPI) => {
                  if (xResponseService.status_code == 200) {
                    const dataInfo = xResponseService.data_info.data;

                    // Refaz o Array, forçando alteração
                    this.filteredByCity = dataInfo.map(
                      (xElement: any) => xElement.icao_code,
                    );
                    this.filteredByCity.push(xValue);

                    this.arrayAerodromeSearch = dataInfo;

                    //TODO: TRANSLATION
                    //TODO: MESSENGER
                    //TODO: TOAST
                    this.messageService.add({
                      severity: 'success',
                      summary: 'Recuperar Aeródromo',
                      detail: `Aeródromo ${xValue} Recuperado com Sucesso.`,
                    });
                  } else {
                    this.filteredByCity = []; // Tem que zerar antes, para preencher corretamente.
                    this.arrayAerodromeSearch = []; // Tem que zerar qualquer aeródromo que tenha sido mostrado na Tabela

                    //TODO: TRANSLATION
                    //TODO: MESSENGER
                    //TODO: TOAST
                    this.messageService.add({
                      severity: 'error',
                      summary: 'Recuperar Aeródromo',
                      detail: `Erro ao Recuperar Dados do Aeródromo ${xValue}.`,
                    });
                  }

                  // Lembre-se: está sendo executado um OBSERVER, ou seja, ele é assíncrono e é difícil esperar o seu final para executar outro método.
                  // Para que os aeródromos sejam plotados no MAPA é preciso "forçar" uma atualização do conteúdo para que seja pego pelo componente de mapa, via "INPUT PROPERTY SETTER"
                  // Forçar no final da execução do OBSERVER
                  /////////////////////////////this._forceReloadArrayAerodromesToMarkMapValue();
                },
                complete: () => { },
                error: (xErrorService: any) => {
                  this.filteredByCity = [];
                  this.arrayAerodromeSearch = []; // Tem que zerar qualquer aeródromo que tenha sido mostrado na Tabela

                  //TODO: TRANSLATION
                  //TODO: MESSENGER
                  //TODO: TOAST
                  // USAR SOMENTE PARA DESENVOLVIMENTO
                  this.messageService.add({
                    severity: 'error',
                    summary: 'Recuperar Aeródromo',
                    detail: 'TEM QUE ARRUMAR ESTE ERRO. NA FUNCAO QUE SUGERE',
                  });
                },
              });
          } else {
            this.filteredByCity = []; // Nenhum Conteúdo, tem que zerar a sugestão.
            this.arrayAerodromeSearch = []; // Tem que zerar qualquer aeródromo que tenha sido mostrado na Tabela
          }
        } else {
          this.filteredByCity = []; // Nenhum Conteúdo, tem que zerar a sugestão.
          this.arrayAerodromeSearch = []; // Tem que zerar qualquer aeródromo que tenha sido mostrado na Tabela
        }
      });

    this.formFilterSearchRecordedAerodrome
      .get('state')
      .valueChanges.subscribe((xValue: any) => {
        // this.control.patchValue(this.control.value.toLowerCase());
        if (xValue) {
          this.formFilterSearchRecordedAerodrome.controls.name.setValue(null, {emitEvent: false});
          this.formFilterSearchRecordedAerodrome.controls.icao_code.setValue(null, {emitEvent: false});
          this.formFilterSearchRecordedAerodrome.controls.aerodrome_type.setValue(null, {emitEvent: false});
          this.formFilterSearchRecordedAerodrome.controls.city.setValue(null, {emitEvent: false});
          this.formFilterSearchRecordedAerodrome.controls.coordinate.setValue(null, {emitEvent: false});
          
          // Verifica se existe valor.
          if (xValue.length > 1) {
            // Vai buscar auto complemente a partir de 2 letras.
            this.filteredByCity = []; // Tem que zerar antes, para preencher corretamente.
            this.arrayAerodromeSearch = []; // Tem que zerar qualquer aeródromo que tenha sido mostrado na Tabela

            // Construindo a pesquisa
            const fieldToSearch = {
              'place.state': xValue.toLowerCase()
            };

            this.flightPlanApisService
              .getAerodromeBasicSuggestionContains(fieldToSearch)
              .subscribe({
                next: (xResponseService: IPatternResponseFromAPI) => {
                  if (xResponseService.status_code == 200) {
                    const dataInfo = xResponseService.data_info.data;

                    // Refaz o Array, forçando alteração
                    this.filteredByCity = dataInfo.map(
                      (xElement: any) => xElement.icao_code,
                    );
                    this.filteredByCity.push(xValue);

                    this.arrayAerodromeSearch = dataInfo;

                    //TODO: TRANSLATION
                    //TODO: MESSENGER
                    //TODO: TOAST
                    this.messageService.add({
                      severity: 'success',
                      summary: 'Recuperar Aeródromo',
                      detail: `Aeródromo ${xValue} Recuperado com Sucesso.`,
                    });
                  } else {
                    this.filteredByCity = []; // Tem que zerar antes, para preencher corretamente.
                    this.arrayAerodromeSearch = []; // Tem que zerar qualquer aeródromo que tenha sido mostrado na Tabela

                    //TODO: TRANSLATION
                    //TODO: MESSENGER
                    //TODO: TOAST
                    this.messageService.add({
                      severity: 'error',
                      summary: 'Recuperar Aeródromo',
                      detail: `Erro ao Recuperar Dados do Aeródromo ${xValue}.`,
                    });
                  }

                  // Lembre-se: está sendo executado um OBSERVER, ou seja, ele é assíncrono e é difícil esperar o seu final para executar outro método.
                  // Para que os aeródromos sejam plotados no MAPA é preciso "forçar" uma atualização do conteúdo para que seja pego pelo componente de mapa, via "INPUT PROPERTY SETTER"
                  // Forçar no final da execução do OBSERVER
                  /////////////////////////////this._forceReloadArrayAerodromesToMarkMapValue();
                },
                complete: () => { },
                error: (xErrorService: any) => {
                  this.filteredByCity = [];
                  this.arrayAerodromeSearch = []; // Tem que zerar qualquer aeródromo que tenha sido mostrado na Tabela

                  //TODO: TRANSLATION
                  //TODO: MESSENGER
                  //TODO: TOAST
                  // USAR SOMENTE PARA DESENVOLVIMENTO
                  this.messageService.add({
                    severity: 'error',
                    summary: 'Recuperar Aeródromo',
                    detail: 'TEM QUE ARRUMAR ESTE ERRO. NA FUNCAO QUE SUGERE',
                  });
                },
              });
          } else {
            this.filteredByCity = []; // Nenhum Conteúdo, tem que zerar a sugestão.
            this.arrayAerodromeSearch = []; // Tem que zerar qualquer aeródromo que tenha sido mostrado na Tabela
          }
        } else {
          this.filteredByCity = []; // Nenhum Conteúdo, tem que zerar a sugestão.
          this.arrayAerodromeSearch = []; // Tem que zerar qualquer aeródromo que tenha sido mostrado na Tabela
        }
      });
    
      this.formFilterSearchRecordedAerodrome
      .get('coordinate')
      .valueChanges.subscribe((xValue: any) => {
        // this.control.patchValue(this.control.value.toLowerCase());
        if (xValue) {
          this.formFilterSearchRecordedAerodrome.controls.name.setValue(null, {emitEvent: false});
          this.formFilterSearchRecordedAerodrome.controls.icao_code.setValue(null, {emitEvent: false});
          this.formFilterSearchRecordedAerodrome.controls.aerodrome_type.setValue(null, {emitEvent: false});
          this.formFilterSearchRecordedAerodrome.controls.state.setValue(null, {emitEvent: false});
          this.formFilterSearchRecordedAerodrome.controls.city.setValue(null, {emitEvent: false});
          
          // Verifica se existe valor.
          if (xValue.length > 1) {
            // Vai buscar auto complemente a partir de 2 letras.
            this.filteredByCity = []; // Tem que zerar antes, para preencher corretamente.
            this.arrayAerodromeSearch = []; // Tem que zerar qualquer aeródromo que tenha sido mostrado na Tabela

            // Construindo a pesquisa
            const fieldToSearch = {
              'coordinate': xValue.toLowerCase()
            };

            this.flightPlanApisService
              .getAerodromeBasicSuggestionNear(fieldToSearch)
              .subscribe({
                next: (xResponseService: IPatternResponseFromAPI) => {
                  if (xResponseService.status_code == 200) {
                    const dataInfo = xResponseService.data_info.data;

                    // Refaz o Array, forçando alteração
                    this.filteredByCity = dataInfo.map(
                      (xElement: any) => xElement.icao_code,
                    );
                    this.filteredByCity.push(xValue);

                    this.arrayAerodromeSearch = dataInfo;

                    //TODO: TRANSLATION
                    //TODO: MESSENGER
                    //TODO: TOAST
                    this.messageService.add({
                      severity: 'success',
                      summary: 'Recuperar Aeródromo',
                      detail: `Aeródromo ${xValue} Recuperado com Sucesso.`,
                    });
                  } else {
                    this.filteredByCity = []; // Tem que zerar antes, para preencher corretamente.
                    this.arrayAerodromeSearch = []; // Tem que zerar qualquer aeródromo que tenha sido mostrado na Tabela

                    //TODO: TRANSLATION
                    //TODO: MESSENGER
                    //TODO: TOAST
                    this.messageService.add({
                      severity: 'error',
                      summary: 'Recuperar Aeródromo',
                      detail: `Erro ao Recuperar Dados do Aeródromo ${xValue}.`,
                    });
                  }

                  // Lembre-se: está sendo executado um OBSERVER, ou seja, ele é assíncrono e é difícil esperar o seu final para executar outro método.
                  // Para que os aeródromos sejam plotados no MAPA é preciso "forçar" uma atualização do conteúdo para que seja pego pelo componente de mapa, via "INPUT PROPERTY SETTER"
                  // Forçar no final da execução do OBSERVER
                  /////////////////////////////this._forceReloadArrayAerodromesToMarkMapValue();
                },
                complete: () => { },
                error: (xErrorService: any) => {
                  this.filteredByCity = [];
                  this.arrayAerodromeSearch = []; // Tem que zerar qualquer aeródromo que tenha sido mostrado na Tabela

                  //TODO: TRANSLATION
                  //TODO: MESSENGER
                  //TODO: TOAST
                  // USAR SOMENTE PARA DESENVOLVIMENTO
                  this.messageService.add({
                    severity: 'error',
                    summary: 'Recuperar Aeródromo',
                    detail: 'TEM QUE ARRUMAR ESTE ERRO. NA FUNCAO QUE SUGERE',
                  });
                },
              });
          } else {
            this.filteredByCity = []; // Nenhum Conteúdo, tem que zerar a sugestão.
            this.arrayAerodromeSearch = []; // Tem que zerar qualquer aeródromo que tenha sido mostrado na Tabela
          }
        } else {
          this.filteredByCity = []; // Nenhum Conteúdo, tem que zerar a sugestão.
          this.arrayAerodromeSearch = []; // Tem que zerar qualquer aeródromo que tenha sido mostrado na Tabela
        }
      });
  }
  //#endregion
}
