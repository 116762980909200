import { Component } from '@angular/core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'common-components-top-horizontal-menu-02',
  standalone: true,
  imports: [],
  templateUrl: './top-horizontal-menu-02.component.html',
  styleUrl: './top-horizontal-menu-02.component.css'
})
export class TopHorizontalMenu02Component {

}
