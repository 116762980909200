<div class="cls-ui-primeng-field">
    <div class="field" [ngClass]="{'cls-field-wrong': this.showErrors() }">
        <ng-container *ngIf="this.showLabel">
            <label class="cls-field-label"
                [ngClass]="{'cls-label-bold': this.getShowLabelBold(), 'cls-field-wrong': this.showErrors() }"
                for="{{ this.idField }}">
                {{ this.label }}
                <ng-container *ngIf="this.showToolTipIcon">
                    <i [pTooltip]="tooltipContent" [showDelay]="500" [hideDelay]="300"
                        class="fa-solid fa-comment-dots ml-1"></i>
                </ng-container>
            </label>
            <ng-template #tooltipContent>
                <div class="flex align-items-center">
                    <img src="https://primefaces.org/cdn/primeng/images/primeng.svg" height="20" class="mr-2" />
                    <span> {{ this.getTooTipContent() }} </span>
                </div>
            </ng-template>
        </ng-container>
        <div class="cls-ui-primeng-field-slider"> 
            <div class="cls-ui-primeng-field-slider-component">
                <p-slider 
                    [formControl]="this.control" 
                    [min]=getMinxValue() 
                    [max]=this.getMaxValue()
                    [step]=getStepValue()                    
                    id="{{ this.idField }}"                    
                    gDefaultControl>
                </p-slider>
            </div>
            <div class="cls-ui-primeng-field-slider-frame">
                <div>{{getLabelValue()}}</div>
                <div class="cls-ui-primeng-field-slider-value">
                    {{this.control.value}}
                </div>
                <div>{{getPosLabelValue()}}</div>
            </div>           
        </div>
    </div>
</div>