/**
 * TODO: https://tsdoc.org/
 */
export const weatherCategoriesOptions = [
    { 
        label: 'VFR', 
        value: 'VFR'
    },
    { 
        label: 'MVFR', 
        value: 'MVFR'
    },
    { 
        label: 'IFR', 
        value: 'IFR'
    },
    { 
        label: 'LIFR', 
        value: 'LIFR'
    }
]