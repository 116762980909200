//#region "|--- IMPORT MODULES/PACKAGES ---|"
// ***** ANGULAR *****
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';

// ***** NPM *****
import { MenuItem } from 'primeng/api';

// ***** NASHVILLE *****
import { PrimengComponentsModule } from '@nashville-primeng';
//#endregion

//#region "|--- IMPORT ENUMS ---|"
import { BUTTON_ACTION } from '../../../../../../helpers/enum/ButtonActions';
import { TYPE_DOCUMENT } from '../../../../../../helpers/enum/TypeDocuments';
//#endregion

@Component({
  selector: 'nashville-toolbar-panel-generate-pdf',
  standalone: true,
  imports: [
    CommonModule,
    PrimengComponentsModule
  ],
  templateUrl: './toolbar-panel-generate-pdf.component.html',
  styleUrl: './toolbar-panel-generate-pdf.component.scss',
})
export class ToolbarPanelGeneratePdfComponent implements OnInit {
  //#region "|--- INPUTS ---|"
  @Input() pageInnerWidth!: any;
  //#endregion

  //#region "|--- OUTPUTS ---|"
  @Output() generatePdfButtonAction = new EventEmitter<any>();
  //#endregion

  //#region "|--- PROPERTIES---|"
  itemsSubMenuNew!: MenuItem[];

  labelActionsButtons: any;
  //#endregion

  /**
   * TODO: https://tsdoc.org/
   */
  ngOnInit(): void {
    this._initVariables();
  }

  //#region "|--- PRIVATE METHODS---|" 
  /**
   * TODO: https://tsdoc.org/
   */
  private _initVariables() {
    this.labelActionsButtons = BUTTON_ACTION;

    this.itemsSubMenuNew = [
      {
        label: 'Compl. Executiva',
        icon: 'fa-regular fa-file-pdf',
        command: () => {
          this.generatePdfButtonAction.emit(TYPE_DOCUMENT.COMPLETE_EXECUTIVE);
        }
      }
    ];
  }
  //#endregion
}
