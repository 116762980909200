//#region "|--- INTERFACE ---|"
import { IDropDownTemplateCountryOptions } from "src/app/interfaces/IDropDownTemplateCountryOptions";
//#endregion

/**
 * TODO: https://tsdoc.org/
 */
export const countryOptions: IDropDownTemplateCountryOptions[] = [
    { label: '', value: null, flag: '', ddi: '' },
    { label: 'ARGENTINA', value: 'ARG', flag: 'AR', ddi: '+54' },
    { label: 'AUSTRÁLIA', value: 'AUS', flag: 'AU', ddi: '+61' },
    { label: 'BRASIL', value: 'BRA', flag: 'BR', ddi: '+55' },
    { label: 'BOLÍVIA', value: 'BOL', flag: 'BO', ddi: '+591' },
    { label: 'CANADA', value: 'CAN', flag: 'CA', ddi: '+1' },
    { label: 'CHILE', value: 'CHL', flag: 'CL', ddi: '+56' },
    { label: 'COLÔMBIA', value: 'COL', flag: 'CO', ddi: '+57' },
    { label: 'EQUADOR', value: 'ECU', flag: 'EC', ddi: '+593' },
    { label: 'ESTADOS UNIDOS', value: 'USA', flag: 'US', ddi: '+1' },
    { label: 'GEÓRGIA DO SUL', value: 'GEO', flag: 'GE', ddi: '+500' },
    { label: 'GUIANA', value: 'GUY', flag: 'GY', ddi: '+592' },
    { label: 'GUIANA FRANCESA', value: 'GUF', flag: 'GF', ddi: '+594' },
    { label: 'MÉXICO', value: 'MEX', flag: 'MX', ddi: '+52' },
    { label: 'PARAGUAI', value: 'PRY', flag: 'PY', ddi: '+595' },
    { label: 'PERU', value: 'PER', flag: 'PE', ddi: '+51' },
    { label: 'SURINAME', value: 'SUR', flag: 'SR', ddi: '+597' },
    { label: 'URUGUAI', value: 'URY', flag: 'UY', ddi: '+598' },
    { label: 'VENEZUELA', value: 'VEN', flag: 'VE', ddi: '+58' },
    { label: 'NEW ZEALAND', value: 'NZL', flag: 'NZ', ddi: '+64' }
]