/**
 * TODO: https://tsdoc.org/
 */
export const ICON_AERODROME = {
    DEPARTURE: "airport-location-blue.png",
    DESTINATION: "airport-location-red.png",
    ALT_1: "airport-location-roxo.png",
    ALT_2: "airport-location-cyan.png",
    ALT_EXTRA: "airport-location-maroon.png"
}
