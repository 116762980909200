import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MessageService } from 'primeng/api';

import { FlightPlanApisService } from '../../services/flight-plan-data-apis.service';
import { IPatternResponseFromAPI } from 'src/app/interfaces/IPatternCrudResponseFromAPI';

@Component({
  selector: 'nashville-bottom-utc-datetime',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './bottom-utc-datetime.component.html',
  styleUrl: './bottom-utc-datetime.component.scss',
})
export class BottomUtcDatetimeComponent implements OnInit {
  //#region "|--- PROPERTIES---|"
  isServerDateTime!: boolean;

  currentDateTimeLocal!: Date;

  showWatch!: boolean;

  monthDateUTC!: any;
  dayDateUTC!: any;
  yearDateUTC!: any;
  hourDateUTC!: any;
  minuteDateUTC!: any;
  secondsDateUTC!: any;
  //#endregion

  constructor(
    private flightPlanApisService: FlightPlanApisService,
    private messageService: MessageService
  ) { }

  ngOnInit(): void {
    this._initVariables();
    this._initClock();
  }

  /**
   * TODO: https://tsdoc.org/
   */
  private _initVariables() {
    this.showWatch = false;
  }

  /**
   * TODO: https://tsdoc.org/
   */
  private _initClock() {
    this._getInitialDateTime();

    setInterval(() => {
      this._updateClock();
    }, 1000);
  }

  /**
   * TODO: https://tsdoc.org/
   */
  private async _getInitialDateTime() {
    try {
      // https://stackoverflow.com/questions/15141762/how-to-initialize-a-javascript-date-to-a-particular-time-zone
      // 1) Inicializar sempre com a Hora do Computador local.
      this.currentDateTimeLocal = new Date();
      this.isServerDateTime = false;

      await this.flightPlanApisService.getServerDataTimeUTC().subscribe({
        next: (xResponseService: IPatternResponseFromAPI) => {
          if (xResponseService.status_code == 200) {
            const tmpObjData = xResponseService.data_info[0];

            this.currentDateTimeLocal = new Date(tmpObjData.milliseconds_19700101_utc + 10); // Coloca-se + 10 segundos para compensar a chamada ao servidor ida e volta, em média.

            this.isServerDateTime = true;
          } 
        },
        complete: () => {
          
        },
        error: (xErrorService: any) => {
          this.messageService.add({ severity: 'error', summary: 'Data Hora UTC', detail: "Houve um erro na hora de recuperar a Data e Hora do Servidor." });
        }
      });

    } catch (xError: any) {
      console.log("ERROR CLOCK", xError);
    }
  }

  /**
   * TODO: https://tsdoc.org/
   */
  private _updateClock() {
    this.monthDateUTC = (this.currentDateTimeLocal.getUTCMonth() + 1).toString().padStart(2, "0");
    this.dayDateUTC = this.currentDateTimeLocal.getUTCDate().toString().padStart(2, "0");
    this.yearDateUTC = this.currentDateTimeLocal.getUTCFullYear().toString().padStart(2, "0");
    this.hourDateUTC = this.currentDateTimeLocal.getUTCHours().toString().padStart(2, "0");
    this.minuteDateUTC = this.currentDateTimeLocal.getUTCMinutes().toString().padStart(2, "0");
    this.secondsDateUTC = this.currentDateTimeLocal.getUTCSeconds().toString().padStart(2, "0");

    this.showWatch = true;
    
    this.currentDateTimeLocal.setSeconds(this.currentDateTimeLocal.getSeconds() + 1);
  }
}
