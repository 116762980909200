import { ApplicationConfig } from '@angular/core';
import { provideRouter } from '@angular/router';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideHttpClient, withFetch, withJsonpSupport, withNoXsrfProtection } from "@angular/common/http";
import { MessageService } from 'primeng/api';
import { ConfirmationService } from 'primeng/api';
import { appRoutes } from './app.routes';
import { provideEnvironmentNgxMask } from 'ngx-mask';
import { IConfig } from 'ngx-mask'
// https://blog.ninja-squad.com/2022/11/09/angular-http-in-standalone-applications/
// https://dev.to/this-is-angular/how-to-fetch-data-using-the-providehttpclient-in-angular-5h47

const maskConfigFunction: () => Partial<IConfig> = () => {
  return {
    validation: false,
  };
};

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(appRoutes), 
    provideAnimations(),
    provideEnvironmentNgxMask(maskConfigFunction),
    provideHttpClient(withJsonpSupport(), withNoXsrfProtection()),
    MessageService,
    ConfirmationService
  ]
};

//provideHttpClient(withJsonpSupport(), withNoXsrfProtection(), withFetch()),
