import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { TopHeader01Component } from '../../common-structure/top-header-01/top-header-01.component';
import { TopHeader02Component } from '../../common-structure/top-header-02/top-header-02.component';
import { TopHorizontalMenu01Component } from '../../common-structure/top-horizontal-menu-01/top-horizontal-menu-01.component';
import { TopHorizontalMenu02Component } from '../../common-structure/top-horizontal-menu-02/top-horizontal-menu-02.component';

import { PrimengComponentsModule } from 'src/layouts/primeng-components/primeng-components.module';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'frame-horizontal-basic-01',
  standalone: true,
  imports: [
    PrimengComponentsModule,
    TopHeader01Component,
    TopHeader02Component,
    TopHorizontalMenu01Component,
    TopHorizontalMenu02Component,
    CommonModule,
    RouterModule
  ],
  templateUrl: './horizontal-basic-01.component.html',
  styleUrl: './horizontal-basic-01.component.scss',
})
export class HorizontalBasic01Component implements OnInit {
  topHeader!: string | null;
  topHorizontalMenu!: string | null;

  ngOnInit(): void {
    this.topHeader = document.body.getAttribute('data-top_header');
    this.topHorizontalMenu = document.body.getAttribute('data-top_horizontal_menu')
  }

  isTopHeader01() {
    if (this.topHeader == "top-header-01") {
      return true;
    } else {
      return false;
    }
  }

  isTopHeader02() {
    if (this.topHeader == "top-header-02") {
      return true;
    } else {
      return false;
    }
  }

  isTopHorizontalMenu01() {
    if (this.topHorizontalMenu == "top-horizontal-menu-01") {
      return true;
    } else {
      return false;
    }
  }

  isTopHorizontalMenu02() {
    if (this.topHorizontalMenu == "top-horizontal-menu-02") {
      return true;
    } else {
      return false;
    }
  }
}
