//#region "|--- IMPORT MODULES/PACKAGES ---|"
import { Component, OnInit, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MenuItem, MessageService } from 'primeng/api';

import { settingConfig } from 'src/config/settingConfig';

import clipboard from 'clipboardy';

import { statusFlightPlanOptions } from '../../../../../../helpers/dropdown-static-options/statusFlightPlanOptions';
import { statusTreatmentOptions } from '../../../../../../helpers/dropdown-static-options/statusTreatmentOptions';
import { checklistOptions } from 'src/app/helpers/dropdown-static-options/checklistOptions';

import { PrimengComponentsModule } from '@nashville-primeng';
//#endregion

//#region "|--- INTERFACE ---|"
import { IDropDownTemplateColorOptions } from '../../../../../../interfaces/IDropDownTemplateColorOptions';
import { IDropDownBasicOptions } from 'src/app/interfaces/IDropDownBasicOptions';
//#endregion

@Component({
  selector: 'nashville-panel-request-data',
  standalone: true,
  imports: [
    CommonModule,
    PrimengComponentsModule
  ],
  templateUrl: './panel-request-data.component.html',
  styleUrl: './panel-request-data.component.scss',
})
export class PanelRequestDataComponent implements OnInit {
  //#region "|--- INPUTS ---|"
  @Input() formComponent!: any;

  // É feito o set do @Input para que seja capturado qualquer modificação, em tempo real, 
  // do parâmetro que está sendo enviado pelo PARENT e, principalmente, que seja feito o 
  // processamento necessário com base no novo valor.
  @Input() set pageInnerWidth(xValue: any) {
    this._innerWidth = xValue;
  };
  //#endregion

  //#region "|--- PROPERTIES---|" 
  _innerWidth!: any;

  optionsStatusFlightPlan!: IDropDownTemplateColorOptions[];
  optionsStatusTreatment!: IDropDownTemplateColorOptions[];
  optionsCheckListRequest!: IDropDownBasicOptions[];

  itensPlusMinutes: MenuItem[];
  itemsMinusMinutes: MenuItem[];

  settingConfig!: any;
  //#endregion

  constructor(
    private messageService: MessageService
  ) { }

  ngOnInit(): void {
    this._initVariables();
  }

  //#region "|--- PUBLIC METHODS---|"
  plusMinutesEobt(xMinutes: string) {
    const tempDate = new Date(this.formComponent.controls.date_time_eobt.controls.date_time_zulu.value);
    const tempDatePlusMinutes = new Date(tempDate.setMinutes(tempDate.getMinutes() + parseInt(xMinutes)));

    const tempNewDate = tempDatePlusMinutes.getUTCDate();
    const tempNewMonth = (tempDatePlusMinutes.getUTCMonth() + 1);
    const tempNewYear = tempDatePlusMinutes.getUTCFullYear();
    const tempNewHour = tempDatePlusMinutes.getUTCHours();
    const tempNewMinute = tempDatePlusMinutes.getUTCMinutes();

    const finalNewDate = tempNewDate < 10 ? `0${tempNewDate}` : `${tempNewDate}`;
    const finalNewMonth = tempNewMonth < 10 ? `0${tempNewMonth}` : `${tempNewMonth}`;
    const finalNewYear = tempNewYear < 10 ? `0${tempNewYear}` : `${tempNewYear}`;
    const finalNewHour = tempNewHour < 10 ? `0${tempNewHour}` : `${tempNewHour}`;
    const finalNewMinute = tempNewMinute < 10 ? `0${tempNewMinute}` : `${tempNewMinute}`;

    this.formComponent.controls.date_time_eobt.controls.date_zulu.setValue(`${finalNewDate}/${finalNewMonth}/${finalNewYear}`);
    this.formComponent.controls.date_time_eobt.controls.time_zulu.setValue(`${finalNewHour}:${finalNewMinute}`);
    this.formComponent.controls.date_time_eobt.controls.date_time_zulu.setValue(tempDatePlusMinutes.toISOString());  
  }

  minusMinutesEobt(xMinutes: string) {
    const tempDate = new Date(this.formComponent.controls.date_time_eobt.controls.date_time_zulu.value);
    const tempDatePlusMinutes = new Date(tempDate.setMinutes(tempDate.getMinutes() - parseInt(xMinutes)));

    const tempNewDate = tempDatePlusMinutes.getUTCDate();
    const tempNewMonth = (tempDatePlusMinutes.getUTCMonth() + 1);
    const tempNewYear = tempDatePlusMinutes.getUTCFullYear();
    const tempNewHour = tempDatePlusMinutes.getUTCHours();
    const tempNewMinute = tempDatePlusMinutes.getUTCMinutes();

    const finalNewDate = tempNewDate < 10 ? `0${tempNewDate}` : `${tempNewDate}`;
    const finalNewMonth = tempNewMonth < 10 ? `0${tempNewMonth}` : `${tempNewMonth}`;
    const finalNewYear = tempNewYear < 10 ? `0${tempNewYear}` : `${tempNewYear}`;
    const finalNewHour = tempNewHour < 10 ? `0${tempNewHour}` : `${tempNewHour}`;
    const finalNewMinute = tempNewMinute < 10 ? `0${tempNewMinute}` : `${tempNewMinute}`;
   
    this.formComponent.controls.date_time_eobt.controls.date_zulu.setValue(`${finalNewDate}/${finalNewMonth}/${finalNewYear}`);
    this.formComponent.controls.date_time_eobt.controls.time_zulu.setValue(`${finalNewHour}:${finalNewMinute}`);
    this.formComponent.controls.date_time_eobt.controls.date_time_zulu.setValue(tempDatePlusMinutes.toISOString());  
  }

  /**
   * TODO: https://tsdoc.org/
   */
  copyFlightRequestIdToClipboard() {
    const tempValue = this.formComponent.controls.flight_request_id.value;

    if (tempValue && tempValue.length > 0) {
      //TODO: TRANSLATION
      //TODO: MESSENGER
      //TODO: TOAST 
      clipboard.write(tempValue).
        then(xReturn => {
          this.messageService.add({
            severity: 'success',
            summary: `Identificador da Requisição`,
            detail: `o ID da Requisição foi Copiado com Sucesso para o Clipboard.`
          });
        });
    } else {
      //TODO: TRANSLATION
      //TODO: MESSENGER
      //TODO: TOAST 
      this.messageService.add({
        severity: 'info',
        summary: `Copia da ID.REQUISIÇÃO`,
        detail: `O Conteúdo está vazio.`
      });
    }
  }

  /**
   * TODO: https://tsdoc.org/
   */
  copyFlightPlanOfficialConfirmationToClipboard() {
    const tempValue = this.formComponent.controls.flight_plan_official_confirmation.value;

    if (tempValue && tempValue.length > 0) {
      //TODO: TRANSLATION
      //TODO: MESSENGER
      //TODO: TOAST 
      clipboard.write(tempValue).
        then(xReturn => {
          this.messageService.add({
            severity: 'success',
            summary: `Copia do Código do Plano Aprovado`,
            detail: `O Conteúdo foi copiado com Sucesso.`
          });
        });
    } else {
      //TODO: TRANSLATION
      //TODO: MESSENGER
      //TODO: TOAST 
      this.messageService.add({
        severity: 'info',
        summary: `Copia do Código do Plano Aprovado`,
        detail: `O Conteúdo está vazio.`
      });
    }

  }

  /**
   * TODO: https://tsdoc.org/
   */
  async pasteFlightPlanOfficialConfirmationToClipboard() {
    this.formComponent.controls.flight_plan_official_confirmation.setValue(await navigator.clipboard.readText());

    this.messageService.add({
      severity: 'success',
      summary: `Colar do Código do Plano Aprovado`,
      detail: `O Conteúdo foi colado com Sucesso.`
    });
  }
  //#endregion

  //#region "|--- PRIVATE METHODS---|"
  private _initVariables(): void {
    this.settingConfig = settingConfig;

    this.optionsStatusFlightPlan = statusFlightPlanOptions;
    this.optionsStatusTreatment = statusTreatmentOptions;
    this.optionsCheckListRequest = checklistOptions;

    this.itensPlusMinutes = [
      {
        label: '30 minutos',
        icon: 'pi pi-plus',
        command: () => {
          this.plusMinutesEobt("30");
        }
      },  
      {
        label: '60 minutos',
        icon: 'pi pi-plus',
        command: () => {
          this.plusMinutesEobt("60");
        }
      },
      {
        label: '90 minutos',
        icon: 'pi pi-plus',
        command: () => {
          this.plusMinutesEobt("90");
        }
      },
      {
        label: '1 dia',
        icon: 'pi pi-plus',
        command: () => {
          this.plusMinutesEobt("1440");
        }
      }
    ];

    this.itemsMinusMinutes = [
      {
        label: '30 minutos',
        icon: 'pi pi-minus',
        command: () => {
          this.minusMinutesEobt("30");
        }
      },  
      {
        label: '60 minutos',
        icon: 'pi pi-minus',
        command: () => {
          this.minusMinutesEobt("60");
        }
      },
      {
        label: '90 minutos',
        icon: 'pi pi-minus',
        command: () => {
          this.minusMinutesEobt("90");
        }
      },
      {
        label: '1 dia',
        icon: 'pi pi-plus',
        command: () => {
          this.plusMinutesEobt("1440");
        }
      }
    ];
  }
  //#endregion
}
