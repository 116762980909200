<section class="cls-component-data-panel">
    <p-panel header="VISUALIZAR E EDITAR CONTEÚDO DOCUMENTO" class="cls-component-panel-formgrid" [collapsed]>
        <nashville-toolbar-panel-content-pdf 
            (callbackFromToolbarButton)="this.callbackToolbarPanelButton($event)"
            [flightPlanContent]="this.flightPlanContentData">
        </nashville-toolbar-panel-content-pdf>
        <div class="cls-component-show-content-pdf-expand-retract">
            <div>TODAS AS ABAS: </div>
            <p-button icon="fa-solid fa-angles-up" [rounded]="true" severity="secondary" [text]="true"
                (click)="this.collapseExpandAllPanels('collapse')" />
            <p-button icon="fa-solid fa-angles-down" [rounded]="true" severity="secondary" [text]="true"
                (click)="this.collapseExpandAllPanels('expand')" />
        </div>
        <nashville-show-flight-data-pdf 
            [iptSfdFormRequest]="this.iptPcpFormRequest"
            [iptSfdFlightPlanContent]="this.flightPlanContentData"
            [iptSfdCollapsePanel]="this.collapseAllPanels"                         
            (ottSfdActionExtraFlightData)="callbackExtraFlightData($event)">
        </nashville-show-flight-data-pdf>
        <nashville-show-top-risk-data-pdf 
            [iptStdFormRequest]="this.iptPcpFormRequest" 
            [iptStdFlightPlanContent]="this.flightPlanContentData"
            [iptStdCollapsePanel]="this.collapseAllPanels"                    
            (ottStdActionExtraTopRiskData)="callbackExtraFlightData($event)">
        </nashville-show-top-risk-data-pdf>
        <nashville-show-aerodrome-data-pdf 
            [iptSadFormRequest]="this.iptPcpFormRequest"
            [iptSadFlightPlanContent]="this.flightPlanContentData" 
            [iptSadCollapsePanel]="this.collapseAllPanels"                        
            (ottSadActionExtraAerodromesData)="callbackExtraFlightData($event)"
            (generatePdfButtonAction)="this.callbackToolbarPanelButton($event)">
        </nashville-show-aerodrome-data-pdf>
        <nashville-show-meteorology-data-pdf 
            [iptSmdFormRequest]="this.iptPcpFormRequest"                       
            [iptSmdFlightPlanContent]="this.flightPlanContentData"
            [iptSmdCollapsePanel]="this.collapseAllPanels"  
            (ottSmdActionExtraMeteorologyData)="callbackExtraFlightData($event)">
        </nashville-show-meteorology-data-pdf>
    </p-panel>
</section>