//#region "|--- IMPORT MODULES/PACKAGES ---|"
import { Component, OnInit, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

import { settingConfig } from '@nashville-config';

import { PrimengComponentsModule } from '@nashville-primeng';
//#endregion

@Component({
  selector: 'nashville-panel-notam-data',
  standalone: true,
  imports: [
    CommonModule,
    PrimengComponentsModule
  ],
  templateUrl: './panel-notam-data.component.html',
  styleUrl: './panel-notam-data.component.scss',
})
export class PanelNotamDataComponent implements OnInit {
  //#region "|--- INPUTS ---|"
  @Input() formComponent!: any;  // Recebe o Formulário: REQUEST-FORM

  // É feito o set do @Input para que seja capturado qualquer modificação, em tempo real, 
  // do parâmetro que está sendo enviado pelo PARENT e, principalmente, que seja feito o 
  // processamento necessário com base no novo valor.
  @Input() set pageInnerWidth(xValue: any) {
    this._innerWidth = xValue;
  };
  //#endregion

  //#region "|--- PROPERTIES---|" 
  _innerWidth!: any;

  settingConfig!: any;
  //#endregion

  ngOnInit(): void {
    this._initVariables();
  }

  /**
   * TODO: https://tsdoc.org/
   */
  private _initVariables(): void {
    this.settingConfig = settingConfig;    
  }
}
