// $$$$$$$$$$ REVISED $$$$$$$$$$

//#region "|--- IMPORT MODULES/PACKAGES ---|"
// ***** ANGULAR *****
import { Component, EventEmitter, HostListener, Input, OnInit, Output, AfterViewChecked, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ElementRef } from '@angular/core';

// ***** NASHVILLE *****
import { settingConfig } from '@nashville-config';
import { PrimengComponentsModule } from '@nashville-primeng';
//#endregion

//#region "|--- IMPORT COMPONENTS ---|"
import { ExtraContentComponent } from '../../../../components/extra-content/extra-content.component';
//#endregion

@Component({
  selector: 'nashville-show-meteorology-data-pdf',
  standalone: true,
  imports: [
    CommonModule,
    PrimengComponentsModule,
    ExtraContentComponent
  ],
  templateUrl: './show-meteorology-data-pdf.component.html',
  styleUrl: './show-meteorology-data-pdf.component.scss',
})
export class ShowMeteorologyDataPdfComponent implements OnInit, AfterViewChecked {
  //#region "|--- VIEW CHILD ---|"
  @ViewChild('panelContentMeteorology', { static: false }) divPanel: ElementRef;
  //#endregion

  //#region "|--- INPUTS ---|"
  // (IPT) Input
  // (SMD) ShowMeteorologyDataPdf
  @Input() iptSmdFormRequest!: any;

  // É feito o set do @Input para que seja capturado qualquer modificação, em tempo real, 
  // do parâmetro que está sendo enviado pelo PARENT e, principalmente, que seja feito o 
  // processamento necessário com base no novo valor.
  @Input() set iptSmdFlightPlanContent(xValue: any) {
    if (xValue && xValue != null) {
      this.flightPlanContentData = xValue;
      this.meteorologyContentData = xValue.meteorology;
    }
  };
  @Input() set iptSmdCollapsePanel(xValue: any) {
    this.collapsePanel = xValue;
  };
  //#endregion

  //#region "|--- OUTPUTS ---|"
  // (OTT) Output
  // (SMD) ShowMeteorologyDataPdf
  @Output() ottSmdActionExtraMeteorologyData = new EventEmitter<string>();
  //#endregion

  //#region "|--- PROPERTIES---|" 
  // ***** PRIVATE *****
  _innerWidth!: any;

  // ***** CONFIG *****  
  collapsePanel: boolean;
  settingConfig!: any;

  // ***** ARRAY *****  
  arrayLegendSigmet: any;

  // ***** OTHERS *****
  meteorologyContentData: any;
  flightPlanContentData: any;
  dataInfoUploadImage!: any;
  serverUrl: any;
  //#endregion

  ngOnInit(): void {
    this._initVariables();
  }

  ngAfterViewChecked() {
    // PARA MEXER NO LAYOUT, TEM QUE ESPERAR TODOS O COMPONENTES HTML SEREM RENDERIZADOS.
    this._setLayout();
  }

  //#region "|--- HOST LISTENER ---|"
  /**
   * @type HOST LISTENER
   * @version 1.0.0 (GASPAR - 11/06/2024)
   * - Versão inicial.
   * 
   * @description 
   * - Executada toda vez que ocorre REDIMENSIONAMENTO da Tela.
   * - https://www.w3schools.com/jsref/dom_obj_event.asp
   * 
   * @param event 
   */
  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    this._innerWidth = window.innerWidth;
  }
  //#endregion

  //#region "|--- CALLBACK METHODS---|"
  /**
   * @type CALLBACK
   * @version 1.0.0 (GASPAR - 13/06/2024)
   * - Versão inicial.
   * 
   * @description 
   * - Faz o tratamento dos eventos relacionados aos botões do Toolbar.
   * 
   * @param xValue 
   */
  callbackActionExtraMeteorologyData(xAction: any) {
    this.ottSmdActionExtraMeteorologyData.emit(xAction);
  }
  //#endregion

  //#region "|--- PRIVATE METHODS---|"
  /**
   * @status OK
   * @type METHOD
   * @version 1.0.0 (GASPAR - 13/06/2024)
   * - Versão inicial.
   * 
   * @description 
   * - Inicializa as variáveis do processo.
   */
  private _initVariables() {
    if (settingConfig.API_FLIGHT_PLAN_PORT == "80") {
      this.serverUrl = `${settingConfig.API_PROTOCOL}://${settingConfig.API_HOST}/${settingConfig.API_PATH}`
    } else {
      this.serverUrl = `${settingConfig.API_PROTOCOL}://${settingConfig.API_HOST}:${settingConfig.API_FLIGHT_PLAN_PORT}/${settingConfig.API_PATH}`
    }

    this.settingConfig = settingConfig;

    this.dataInfoUploadImage = {
      flight_request_id: this.flightPlanContentData.flight_request_id,
      type_file: "meteorology_data_extra"
    }

    this.arrayLegendSigmet = [
      {
        fen: "VA CLD",
        desc: "Cinzas Vulcânicas",
        color: "rgb(255, 127, 80)"
      },
      {
        fen: "RDOACT CLD",
        desc: "Nuvem Radioativa",
        color: "rgb(206, 236, 245)"
      },
      {
        fen: "ICE",
        desc: "Gelo",
        color: "rgb(0, 191, 255)"
      },
      {
        fen: "TC",
        desc: "Ciclone",
        color: "rgb(0, 0, 0)"
      },
      {
        fen: "TS",
        desc: "Trovoada",
        color: "rgb(255, 0, 0)"
      },
      {
        fen: "TURB",
        desc: "Turbulência",
        color: "rgb(255, 255, 0)"
      },
      {
        fen: "VA",
        desc: "Vulcão",
        color: "rgb(92, 46, 0)"
      }
    ]
  }

  private _setLayout() {
    this.divPanel.nativeElement.querySelector('.p-panel .p-panel-header').style.backgroundColor = settingConfig.LAYOUT.background_color.meteorology;
    this.divPanel.nativeElement.querySelector('.p-panel .p-panel-header').style.color = "#FFF";
    this.divPanel.nativeElement.querySelector('.p-panel .p-panel-header .p-panel-header-icon').setAttribute('style', 'color: #FFF !important');
    this.divPanel.nativeElement.querySelector('.p-panel .p-panel-header .p-icon-wrapper').setAttribute('style', 'color: #FFF !important');
  }
  //#endregion

  //#region "|--- PUBLIC METHODS ---|"
  setDateFormat(xDate: string) {
    return `${xDate.substring(8,10)}/${xDate.substring(5,7)}/${xDate.substring(0,4)} - ${xDate.substring(11,19)} Z`
  }
  //#endregion
}
