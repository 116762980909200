//#region "|--- IMPORT MODULES/PACKAGES ---|"
// ***** ANGULAR *****
import { Component, HostListener, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';

// ***** NPM *****
import HTTP_STATUS from 'http-status-codes';

// ***** NASHVILLE *****
import { settingConfig } from '@nashville-config';
import { PrimengComponentsModule } from '@nashville-primeng-components';
import { LocalMethodsHandlerClass } from '@nashville-local-methods';
//#endregion

//#region "|--- IMPORT FORM ---|"
import { initFormLogin } from '../../form-init/login-form';
//#endregion

//#region "|--- IMPORT SERVICE ---|"
import { AimDataApisService } from '../../services/aim-data-apis.service';
//#endregion

//#region "|--- IMPORT COMPONENTS ---|"
import { PageToastComponent } from '../../../../shared/components/page-toast/page-toast.component';
//#endregion

//#region "|--- IMPORT INTERFACES ---|"
import { IToastParameters } from '../../../../interfaces/IToastParameters';
import { IPatternResponseFromAPI } from '../../../../interfaces/IPatternCrudResponseFromAPI';
//#endregion

@Component({
  selector: 'nashville-login-form',
  standalone: true,
  imports: [
    CommonModule,
    PrimengComponentsModule,
    PageToastComponent
  ],
  templateUrl: './login-form.component.html',
  styleUrl: './login-form.component.scss',
})
export class LoginFormComponent implements OnInit {
  //#region "|--- PROPERTIES---|"
  innerWidth?: any;

  formLogin!: any;

  settingConfig!: any;

  showDialogError!: any;

  errorMessage!: string;

  objToastMessage: IToastParameters;
  //#endregion

  constructor(
    private aimDataApisService: AimDataApisService,
  ) { }

  ngOnInit(): void {
    this._initVariables();
    this._initFormRequest();
  }

  //#region "|--- PAGE EVENTS ---|"
  /**
   * (v1) - Executada toda vez que ocorre REDIMENSIONAMENTO da Tela.
   * https://www.w3schools.com/jsref/dom_obj_event.asp
   * @param event
   */
  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    this.innerWidth = window.innerWidth;
  }
  //#endregion

  //#region "|--- PUBLIC METHODS ---|"
  closeDialog() {
    this.showDialogError = false;
  }

  public async loginUser() {
    // VERIFICA SE O FORM FOI PREENCHIDO CORRETAMENTE.
    if (this.formLogin.invalid || this.formLogin.invalid) {
      // Recupera todos os Erros do Form, com base na Validação durante a criação do FORM.
      const errosFormRequest = LocalMethodsHandlerClass.getFormFieldsErrors(this.formLogin);
      const fieldsErros: any = [];

      let tmpData = '- campos não identificados.';

      // NOMEANDO OS CAMPOS
      errosFormRequest.forEach((xItem: any) => {
        fieldsErros.push(this._getFieldName(xItem.controlName));
      });

      // FORMATANDO OS NOMES DOS CAMPOS A SEREM MOSTRADOS.
      if (Array.isArray(fieldsErros)) {
        tmpData = fieldsErros.join('\n - ');
      }

      this._showToast({
        severity: 'error',
        summary: 'Login do Usuário',
        detail: `Os campos não foram validados: \n\n- ${tmpData}`
      });
    } else {
      await this.aimDataApisService.loginUser(this.formLogin.getRawValue()).subscribe({
        next: (xResponse: IPatternResponseFromAPI) => {
          if (xResponse.status_code == HTTP_STATUS.OK) {

          } else {

          }
        },
        complete: () => {
        },
        error: (xException: any) => {
          this.showDialogError = true;

          if (this.settingConfig.SHOW_LOG_EXCEPTION) {
            console.log("Exceção - Ao fazer o LOGIN: ", xException);
          }

          if (xException.name == "HttpErrorResponse") {
            if (xException.status == HTTP_STATUS.BAD_REQUEST) {
              if (xException.error.data_info && xException.error.data_info.length > 0) {
                this.errorMessage = "";

                xException.error.data_info.forEach((xElement: any, xIndex: number) => {
                  if (xIndex == 0) {
                    this.errorMessage = `- ${xElement.message}`;
                  } else {
                    this.errorMessage = `${this.errorMessage} <br> - ${xElement.message}`;
                  }
                });

                this.errorMessage = `Houve um erro de validação de dados de entada. <br><br> ${this.errorMessage} <br><br> Tente novamente e caso persista o problema, entre em contato com o suporte do sistema.`;
              } else {
                this.errorMessage = `Houve um erro de validação de dados de entada. <br><br> Tente novamente e caso persista o problema, entre em contato com o suporte do sistema.`;
              }
            } else if (xException.status == 0) {
              this.errorMessage = `Houve um erro de conexão com o Sistema. <br><br> Tente novamente e caso persista o problema, entre em contato com o suporte do sistema.`;
            } else {
              this.errorMessage = "Houve uma exceção ao realizar o Login. <br><br> Tente novamente e caso persista o problema, entre em contato com o suporte do sistema.";
            }
          }
        }
      })
    }
  }
  //#endregion

  //#region "|--- PRIVATE METHODS---|"
  private _initVariables(): void {
    this.showDialogError = false;
    this.settingConfig = settingConfig;
  }

  /**
   * @type METHOD.
   * @version 1.0.0 (GASPAR - 09/07/2024).
  .
   * 
   * @description
   * - Inicialização do form do Login.
   */
  private _initFormRequest(): void {
    this.formLogin = initFormLogin();
  }

  /**
   * @type METHOD.
   * @version 1.0.0 (GASPAR - 27/06/2024).
  .
   * 
   * @description 
   * - Método que mostra a mensagem no Toast.
   * 
   * @param xObjMessage IToastParameters
   * - Objeto contendo os dados necessários para mostrar os dados em um Toast. 
   */
  private async _showToast(xObjMessage: IToastParameters) {
    this.objToastMessage = null; // Tem que forçar a mudança para que o componente registre a alteração do valor da propriedade.
    this.objToastMessage = xObjMessage;
  }

  private _getFieldName(xFiledId: string) {
    switch (xFiledId) {
      case 'id':
        return 'ID Usuário';
      case 'password':
        return 'Senha';      
      default:
        return xFiledId;
    }
  }
  //#endregion
}
