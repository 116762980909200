//#region "|--- IMPORT MODULES/PACKAGES ---|"
// ***** ANGULAR *****
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';

// ***** NASHVILLE *****
import { settingConfig } from '@nashville-config';
import { LocalMethodsHandlerClass } from '@nashville-local-methods';
import { PrimengComponentsModule } from '@nashville-primeng';
import { initFormDocumentGeneration } from 'src/app/features/flight-plan/form-init/document-generation-form';
//#endregion

//#region "|--- IMPORT COMPONENTS ---|"
import { ToolbarPanelGeneratePdfComponent } from '../toolbar-panel-generate-pdf/toolbar-panel-generate-pdf.component';
//#endregion

//#region "|--- IMPORT ENUMS ---|"
import { NAME_TYPE_DOCUMENT } from 'src/app/helpers/enum/NameTypeDocuments';
//#endregion

@Component({
  selector:
    'nashville-panel-generate-pdf',
  standalone: true,
  imports: [
    CommonModule,
    PrimengComponentsModule,
    ToolbarPanelGeneratePdfComponent
  ],
  templateUrl: './panel-generate-pdf.component.html',
  styleUrl: './panel-generate-pdf.component.scss',
})
export class PanelGeneratePdfComponent implements OnInit {
  //#region "|--- INPUTS ---|"
  @Input() formComponent!: any;

  // É feito o set do @Input para que seja capturado qualquer modificação, em tempo real, 
  // do parâmetro que está sendo enviado pelo PARENT e, principalmente, que seja feito o 
  // processamento necessário com base no novo valor.
  @Input() set pageInnerWidth(xValue: any) {
    this._innerWidth = xValue;
  };
  //#endregion

  //#region "|--- OUTPUTS ---|"
  @Output() generatePdfButtonAction = new EventEmitter<string>();
  //#endregion

  //#region "|--- PROPERTIES---|" 
  _innerWidth!: any;

  formDocumentGeneration!: any;
  serverUrl: any;
  arrayTypesDocumentToShow: any;

  objNameTypeDocument: any;
  //#endregion

  constructor(
    private http: HttpClient
  ) { }


  ngOnInit(): void {
    this._initVariables();
    this._initDocumentGenerationForm();
  }

  //#region "|--- PRIVATE METHODS---|"
  private _initVariables(): void {
    if (settingConfig.API_FLIGHT_PLAN_PORT == "80") {
      this.serverUrl = `${settingConfig.API_PROTOCOL}://${settingConfig.API_HOST}/${settingConfig.API_PATH}`
    } else {
      this.serverUrl = `${settingConfig.API_PROTOCOL}://${settingConfig.API_HOST}:${settingConfig.API_FLIGHT_PLAN_PORT}/${settingConfig.API_PATH}`
    }

    this.objNameTypeDocument = NAME_TYPE_DOCUMENT;
    this.arrayTypesDocumentToShow = [];
  }

  private _initDocumentGenerationForm() {
    this.formDocumentGeneration = initFormDocumentGeneration();
  }
  //#endregion

  //#region "|--- PUBLIC METHODS---|"
  showDocumentValueInTable(xIndex: number, xDocumentType: string) {
    // Lembrando que sempre os primeiros são os mais recentes....
    if (xIndex == 0) { // Sempre que for o primeiro elemento, zera o array de controle...
      this.arrayTypesDocumentToShow = [];
    }

    // Caso Falso, é para mostrar todos...
    if (this.formDocumentGeneration.controls.only_most_recent.value == false) {
      return true;
    } else {
      if (this.arrayTypesDocumentToShow.includes(xDocumentType) == false) {
        this.arrayTypesDocumentToShow.push(xDocumentType);

        return true; // Retorna que pode mostrar
      } else { // indica que um Documento do tipo já foi mostrado.
        return false;
      }
    }
  }

  /**
   * type METHOD.
   * @version 1.0.0 (GASPAR - 27/06/2024).
  .
   * 
   * @description
   * - Faz a comparação entre um TimeStamp que foi passado e a Hora Atual, a fim de saber qual é a maior.
   * - Neste caso o TimeStamp çe acrescido de 30 minutos
   * 
   * @param xTimeStamp 
   * - TimeStamp que se deseja comparar com a hora atual
   * @returns 
   */
  checkDateTime(xTimeStamp: any) {
    // Este método é chamado direto, por isto tem que somar 30 minutos ao tempo do PDF.
    const pdfDateTime30More = new Date(xTimeStamp);
    const currentDateTime = new Date();

    pdfDateTime30More.setMinutes(pdfDateTime30More.getMinutes() + 30);

    if (currentDateTime < pdfDateTime30More) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * type METHOD.
   * @version 1.0.0 (GASPAR - 27/06/2024).
  .
   * 
   * @description
   * - Faz a Conversão do Formato de Timestamp, para "dd/mm/aaaa - hh:mm"
   * - Como esta parte é feita no html, é preciso criar este método intermediário, já a classe LocalMethodsHandlerClass não é reconhecida.
   * @param xDate 
   * @returns 
   */
  setVersionDateFormat(xDate: number) {
    return `${LocalMethodsHandlerClass.processTimeStampToDateTimeFormat(new Date(xDate))}`;
  }

  downloadPdfFromServer(xFileName: string) {
    const tempURL = `${this.serverUrl}/mod_fp/documentation/download/pdf?file_name=${xFileName}&aircraft_mark=${this.formComponent.controls.aircraft_mark.value}&flight_request_code=${this.formComponent.controls.flight_request_code.controls.normalized_text.value}`

    // https://stackoverflow.com/questions/57497402/how-to-download-a-pdf-file-in-angular-which-is-generated-on-my-serverspringboot
    // https://gmadhumitha98.medium.com/how-to-download-the-pdf-in-angular-e93f8f82d98
    // https://blog.aakashgoplani.in/how-to-download-pdf-files-in-angular
    // https://ej2.syncfusion.com/angular/documentation/pdfviewer/download
    // https://medium.com/@danilodev.silva/download-de-pdf-com-angular-13-d2e2286ea966

    this.http.get(tempURL, {
      responseType: "blob"
    }).subscribe((response: Blob) => {
      var file = new Blob([response], { type: 'application/pdf' })
      var fileURL = URL.createObjectURL(file);

      //window.open(fileURL);
      var a = document.createElement('a');
      a.href = fileURL;
      a.target = '_blank';
      a.download = xFileName;
      document.body.appendChild(a);
      a.click();
    });
  }

  viewPdfFromServer(xFileName: string) {
    const tempURL = `${this.serverUrl}/mod_fp/documentation/download/pdf?file_name=${xFileName}&aircraft_mark=${this.formComponent.controls.aircraft_mark.value}&flight_request_code=${this.formComponent.controls.flight_request_code.controls.normalized_text.value}`

    // https://stackoverflow.com/questions/57497402/how-to-download-a-pdf-file-in-angular-which-is-generated-on-my-serverspringboot
    // https://gmadhumitha98.medium.com/how-to-download-the-pdf-in-angular-e93f8f82d98
    // https://blog.aakashgoplani.in/how-to-download-pdf-files-in-angular
    // https://ej2.syncfusion.com/angular/documentation/pdfviewer/download
    // https://medium.com/@danilodev.silva/download-de-pdf-com-angular-13-d2e2286ea966

    this.http.get(tempURL, {
      responseType: "blob"
    }).subscribe((response: Blob) => {
      var file = new Blob([response], { type: 'application/pdf' })
      var fileURL = URL.createObjectURL(file);

      window.open(fileURL);
      /*var a = document.createElement('a');
      a.href = fileURL;
      a.target = '_blank';
      document.body.appendChild(a);
      a.click();*/
    });
  }
  //#endregion

  //#region "|--- CALLBACK METHODS---|"
  callbackFromToolbarButton(xValue: any) {
    this.generatePdfButtonAction.emit(xValue);
  }
  //#endregion
}
