<section class="cls-component-address-handler">
    <article>
        <div class="cls-component-table-frame-01">
            <p-table [value]="this.arrayAddressData" [showCurrentPageReport]="true" [scrollable]="true"
                scrollHeight="100px" rowExpandMode="multiple" responsiveLayout="stack"
                class="cls-component-table-form-list" styleClass="p-datatable-striped">
                <ng-template pTemplate="caption">
                    <div class="flex align-items-center justify-content-between cls-component-table-list-caption">
                        <span>
                            <i class="fa-solid fa-location-dot"></i> LISTA DE ENDEREÇOS
                        </span>
                        <p-button icon="fa-solid fa-plus" (click)="this.callbackShowDialog()" size="small"
                            severity="secondary" class="cls-component-table-list-caption-button"></p-button>
                    </div>
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <th style="width:10%">TIPO</th>
                        <th style="width:10%">CEP</th>
                        <th style="width:65%">ENDEREÇO COMPLETO</th>
                        <th style="width:15%;"></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-address let-rowIndex="rowIndex">
                    <tr>
                        <td>{{ this.getTypeName(address.type) }}</td>
                        <td>{{ address.zip_code }}</td>
                        <td>
                            {{ address.street }}, {{ address.number }} - {{ address.complement }}. {{ address.zip_code }} ({{ address.city }}/{{ address.state }} - {{ address.country }})


                        </td>
                        <td style="text-align:right" class="cls-component-table-form-list-item-actions">
                            <p-button icon="fa-solid fa-pen" [rounded]="true" [text]="true" severity="info"
                                (click)="this.editAddress(rowIndex)">
                            </p-button>
                            <p-button icon="fa-solid fa-trash-can" [rounded]="true" [text]="true" severity="danger"
                                (click)="this.deleteAddress(rowIndex)">
                            </p-button>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </article>
    <article>
        <p-dialog header="Edit Profile" [modal]="true" [closable]="false" [(visible)]="this.showDialogFormAddress"
            [style]="{ width: '45vw', height: '50vh' }">
            <ng-template pTemplate="header">
                <h2 class="cls-component-dialog-modal-title">Dados do Endereço</h2>
            </ng-template>
            <p-messages [(value)]="this.messagesAdvices" [closable]="false" class="cls-component-message-frame"
                key="advice-message" />
            <ng-template pTemplate="content">
                <div class="cls-component-form">
                    <div class="formgrid grid">
                        <div class="col-12 md:col-12 lg:col-12">
                            <ui-primeng-dropdown label="Tipo de Endereço #BOLD#" idField="fid-flight-rule"
                                placeHolder="" sizeInput="small" [control]="this.formAddress.controls.type"
                                placeHolder="..." [itemsArray]="this.optionsAddressType" withFilter="false"
                                noLabelWidth="{{this.settingConfig.WIDTH_NO_LABEL}}">
                            </ui-primeng-dropdown>
                        </div>
                    </div>
                    <div class="formgrid grid">
                        <div class="col-12 md:col-12 lg:col-3">
                            <ui-primeng-dropdown-template-flag label="País #BOLD#"
                                idField="id-aircraft-owner-operator-address-country"
                                [control]="this.formAddress.controls.country" [itemsArray]="this.optionsCountryAddress"
                                withFilter="false" placeHolder="..." sizeInput="small"
                                noLabelWidth="{{this.pageInnerWidth}}">
                            </ui-primeng-dropdown-template-flag>
                        </div>
                        <div class="col-12 md:col-12 lg:col-2">
                            <ui-primeng-input-text idField="fid-aircraft-owner-operator-address-zip:code"
                                [control]="this.formAddress.controls.zip_code" letterCase="upper" sizeInput="small"
                                maxCharacters="10" label="CEP #BOLD#" noLabelWidth="{{this.pageInnerWidth}}"
                                maskInput="00000-000" showMask="true">
                            </ui-primeng-input-text>
                        </div>
                        <div class="col-12 md:col-12 lg:col-5">
                            <ui-primeng-input-text idField="fid-aircraft-owner-operator-address-street-name"
                                [control]="this.formAddress.controls.street" letterCase="upper" sizeInput="small"
                                maxCharacters="15" label="Logradouro #BOLD#" noLabelWidth="{{this.pageInnerWidth}}">
                            </ui-primeng-input-text>
                        </div>
                        <div class="col-12 md:col-12 lg:col-2">
                            <ui-primeng-input-text idField="fid-aircraft-owner-operator-address-number"
                                [control]="this.formAddress.controls.number" letterCase="upper" sizeInput="small"
                                maxCharacters="15" label="Número #BOLD#" noLabelWidth="{{this.pageInnerWidth}}">
                            </ui-primeng-input-text>
                        </div>
                    </div>
                    <div class="formgrid grid">
                        <div class="col-12 md:col-12 lg:col-3">
                            <ui-primeng-input-text idField="fid-aircraft-owner-operator-address-district"
                                [control]="this.formAddress.controls.district" letterCase="upper" sizeInput="small"
                                maxCharacters="15" label="Bairro #BOLD#" noLabelWidth="{{this.pageInnerWidth}}">
                            </ui-primeng-input-text>
                        </div>
                        <div class="col-12 md:col-12 lg:col-2">
                            <ui-primeng-input-text idField="fid-aircraft-owner-operator-address-complement"
                                [control]="this.formAddress.controls.complement" letterCase="upper" sizeInput="small"
                                maxCharacters="15" label="Complemento" noLabelWidth="{{this.pageInnerWidth}}">
                            </ui-primeng-input-text>
                        </div>
                        <div class="col-12 md:col-12 lg:col-5">
                            <ui-primeng-input-text idField="fid-aircraft-owner-operator-address-city"
                                [control]="this.formAddress.controls.city" letterCase="upper" sizeInput="small"
                                maxCharacters="15" label="Cidade #BOLD#" noLabelWidth="{{this.pageInnerWidth}}">
                            </ui-primeng-input-text>
                        </div>
                        <div class="col-12 md:col-12 lg:col-2">
                            <ui-primeng-input-text idField="fid-aircraft-owner-operator-address-state"
                                [control]="this.formAddress.controls.state" letterCase="upper" sizeInput="small"
                                maxCharacters="15" label="Estado #BOLD#" noLabelWidth="{{this.pageInnerWidth}}">
                            </ui-primeng-input-text>
                        </div>
                    </div>
                    <div class="formgrid grid">
                        <div class="col-12 md:col-12 lg:col-12">
                            <ui-primeng-input-text idField="fid-aircraft-owner-operator-address-observation-data"
                                [control]="this.formAddress.controls.obs_address" maxCharacters="500" sizeInput="small"
                                label="Observação Endereço" noLabelWidth="{{this.pageInnerWidth}}">
                            </ui-primeng-input-text>
                        </div>
                    </div>
                </div>
            </ng-template>
            <ng-template pTemplate="footer">
                <p-toolbar class="cls-component-toolbar-control-dialog-pattern">
                    <div class="p-toolbar-group-start"></div>
                    <div class="p-toolbar-group-center"></div>
                    <div class="p-toolbar-group-end">
                        <div class="cls-page-box-left">
                            <ng-container *ngIf="this.currentIndexUpdate === null">
                                <p-button label="SALVAR" icon="fa-regular fa-floppy-disk" [raised]="true"
                                    (click)="this.saveDataAddress()" severity="secondary"
                                    class="cls-component-button-pattern-default">
                                </p-button>
                            </ng-container>
                            <ng-container *ngIf="this.currentIndexUpdate !== null">
                                <p-button label="ATUALIZAR" icon="fa-solid fa-rotate" [raised]="true"
                                    severity="secondary" (click)="this.saveEditAddress()"
                                    class="cls-component-button-pattern-default">
                                </p-button>
                            </ng-container>
                            <p-button label="FECHAR" icon="fa-solid fa-arrow-right-from-bracket"
                                (click)="this.closeDialogAddress()" [raised]="true" severity="secondary"
                                class="cls-component-button-pattern-default">
                            </p-button>
                        </div>
                    </div>
                </p-toolbar>
            </ng-template>
        </p-dialog>
    </article>
</section>