export const environment = {
    production: false,
    angular_env: "development",
    web_service_name: "dev-nashville-ftd-web-client-portal-angular",
    project_name: "NASHVILLE PROJECT",
    front_end_version_build: "0.0.0",
    backs_end_version_build: "0.0.0",
    api_protocol: "https",
    api_host: "api-web.aircraft.app.br",
    api_aircraft_data_port: "443",
    api_flight_plan_port: "443",
    api_aim_port: "443",
    api_path: "api/v1",
    width_no_label: "576",
    toast_position: "top-right",
    scheduled_id_length: "15",
    request_axios_timeout: "5000",
    number_attempts: "1",
    interval_attempts: "2000",
    max_file_size_upload: "2100000",
    table_tack:"960px",
    show_log_exception: true
}; 
