//#region "|--- INTERFACE ---|"
import { IDropDownBasicOptions } from '../../interfaces/IDropDownBasicOptions';
//#endregion

/**
 * TODO: https://tsdoc.org/
 */
export const checklistOptions: IDropDownBasicOptions[] = [
    { label: 'AEROMÉDICO', value: 'aeromedic' },
    { label: 'FRETAMENTO', value: 'charter' }
]