//#region "|--- IMPORT MODULES/PACKAGES ---|"
import { Component, OnInit, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PrimengComponentsModule } from '@nashville-primeng';
//#endregion

//#region "|--- INTERFACE ---|"
import { IDropDownBasicOptions } from '../../../../../../interfaces/IDropDownBasicOptions';
//#endregion

@Component({
  selector: 'nashville-process-monitoring-data',
  standalone: true,
  imports: [
    CommonModule,
    PrimengComponentsModule
  ],
  templateUrl: './process-monitoring-data.component.html',
  styleUrl: './process-monitoring-data.component.scss',
})
export class ProcessMonitoringDataComponent implements OnInit{
   //#region "|--- INPUTS ---|"
   @Input() pageInnerWidth!: any;
   @Input() formComponent!: any;
   @Input() arrayChecklist!: any;
   //#endregion

  //#region "|--- PROPERTIES ---|"  
  optionsAircraft!: IDropDownBasicOptions[];  
  //#endregion

  ngOnInit(): void {
    this._initVariables();
  }

  //#region "|--- PRIVATE METHODS ---|"  
  /**
   * TODO: https://tsdoc.org/
   */
  private _initVariables(): void {
    this.optionsAircraft = this.arrayChecklist;
    

    
  }
  //#endregion

}
