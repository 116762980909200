<div class="cls-ui-primeng-field">
    <div class="field" [ngClass]="{'cls-field-wrong': this.showErrors() }">
        <ng-container *ngIf="this.showLabel">        
            <label class="cls-field-label" [ngClass]="{'cls-label-bold': this.getShowLabelBold(), 'cls-field-wrong': this.showErrors() }" for="{{ this.idField }}">
                {{ this.label }} 
                <ng-container *ngIf="this.showToolTipIcon">
                    <i [pTooltip]="tooltipContent" [showDelay]="500" [hideDelay]="300" class="fa-solid fa-comment-dots ml-1"></i>
                </ng-container> 
            </label>
            <ng-template #tooltipContent>
                <div class="flex align-items-center">
                    <img src="https://primefaces.org/cdn/primeng/images/primeng.svg" height="20" class="mr-2" />
                    <span> {{ this.getTooTipContent() }} </span>
                </div>
            </ng-template>
        </ng-container>
        <p-dropdown
            [formControl]="this.control" 
            [options]="this.itemsArray" 
            [optionLabel]="getOptionLabel()"
            [optionValue]="getOptionValue()"
            [class]="this.getInputClass()"
            [ngClass]="{ 'is-danger': this.showErrors() }"            
            [autoDisplayFirst]="true" 
            [showClear]="true"
            [filter]="getWithFilter()"
            [editable]="getIsEditable()" 
            [appendTo]="getAppendTo()"
            placeholder="{{this.placeHolderContent}}"
            (onClear)="callbackClear"
            (onChange)="callbackOnChange"
            id="{{ this.idField }}"
            inputId="{{ this.tmpInputId }}"
            name="{{ this.getFieldName() }}" 
            styleClass="w-full"
        ></p-dropdown>
    </div>
    <ui-primeng-error-message-controls class="cls-pmg-field-error-message" [control]="this.control" typeControl="dropdown"></ui-primeng-error-message-controls>
</div>
