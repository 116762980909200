// $$$$$$$$$$ REVISED $$$$$$$$$$

//#region "|--- IMPORT MODULES/PACKAGES ---|"
// ***** ANGULAR *****
import { Component, EventEmitter, HostListener, Input, OnInit, AfterViewChecked, Output, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ElementRef } from '@angular/core';

// ***** NASHVILLE *****
import { settingConfig } from '@nashville-config';
import { PrimengComponentsModule } from '@nashville-primeng';
//#endregion

//#region "|--- IMPORT COMPONENTS ---|"
import { ExtraContentComponent } from '../../../../components/extra-content/extra-content.component';
import { TYPE_DOCUMENT } from 'src/app/helpers/enum/TypeDocuments';
//#endregion

@Component({
  selector: 'nashville-show-aerodrome-data-pdf',
  standalone: true,
  imports: [
    CommonModule,
    PrimengComponentsModule,
    ExtraContentComponent
  ],
  templateUrl: './show-aerodrome-data-pdf.component.html',
  styleUrl: './show-aerodrome-data-pdf.component.scss',
})
export class ShowAerodromeDataPdfComponent implements OnInit, AfterViewChecked {
  //#region "|--- VIEW CHILD ---|"
  @ViewChild('panelContentAerodromes', { static: false }) divPanel: ElementRef;
  //#endregion

  //#region "|--- INPUTS ---|"
  // (IPT) Input
  // (SAD) ShowAerodromeDataPdf
  @Input() iptSadFormRequest!: any;

  // É feito o set do @Input para que seja capturado qualquer modificação, em tempo real, 
  // do parâmetro que está sendo enviado pelo PARENT e, principalmente, que seja feito o 
  // processamento necessário com base no novo valor.
  @Input() set iptSadFlightPlanContent(xValue: any) {
    if (xValue && xValue != null) {
      this.flightPlanContentData = xValue;
      this.aerodromesContentData = xValue.aerodromes.data_complete.data[0];
    }
  };
  @Input() set iptSadCollapsePanel(xValue: any) {
    this.collapsePanel = xValue;
  };
  //#endregion

  //#region "|--- OUTPUTS ---|"
  // (OTT) Output
  // (SAD) ShowAerodromeDataPdf
  @Output() ottSadActionExtraAerodromesData = new EventEmitter<string>();
  @Output() generatePdfButtonAction = new EventEmitter<string>();
  //#endregion

  //#region "|--- PROPERTIES---|" 
  // ***** PRIVATE *****
  _innerWidth!: any;

  // ***** CONFIG *****  
  collapsePanel: boolean;
  settingConfig!: any;

  // ***** ARRAY *****  
  arrayAerodromeGoal = ["departure", "destination", "alt_1", "alt_2"];

  // ***** OTHERS *****
  dataInfoUploadImage!: any;
  flightPlanContentData: any;
  aerodromesContentData: any;
  serverUrl: any;
  //#endregion

  ngOnInit(): void {
    this._initVariables();
  }

  ngAfterViewChecked() {
    // PARA MEXER NO LAYOUT, TEM QUE ESPERAR TODOS O COMPONENTES HTML SEREM RENDERIZADOS.
    this._setLayout();
  }

  //#region "|--- HOST LISTENER ---|"
  /**
   * @type HOST LISTENER
   * @version 1.0.0 (GASPAR - 11/06/2024)
   * - Versão inicial.
   * 
   * @description 
   * - Executada toda vez que ocorre REDIMENSIONAMENTO da Tela.
   * - https://www.w3schools.com/jsref/dom_obj_event.asp
   * 
   * @param event 
   */
  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    this._innerWidth = window.innerWidth;
  }
  //#endregion

  //#region "|--- CALLBACK METHODS---|"
  /**
   * @type CALLBACK
   * @version 1.0.0 (GASPAR - 13/06/2024)
   * - Versão inicial.
   * 
   * @description 
   * - Faz o tratamento dos eventos relacionados aos botões do Toolbar.
   * 
   * @param xValue 
   */
  callbackActionExtraAerodromesData(xAction: any) {
    this.ottSadActionExtraAerodromesData.emit(xAction);
  }
  //#endregion

  //#region "|--- PRIVATE METHODS---|"
  /**
   * @status OK
   * @type METHOD
   * @version 1.0.0 (GASPAR - 13/06/2024)
   * - Versão inicial.
   * 
   * @description 
   * - Inicializa as variáveis do processo.
   */
  private _initVariables() {
    if (settingConfig.API_FLIGHT_PLAN_PORT == "80") {
      this.serverUrl = `${settingConfig.API_PROTOCOL}://${settingConfig.API_HOST}/${settingConfig.API_PATH}`
    } else {
      this.serverUrl = `${settingConfig.API_PROTOCOL}://${settingConfig.API_HOST}:${settingConfig.API_FLIGHT_PLAN_PORT}/${settingConfig.API_PATH}`
    }

    this.settingConfig = settingConfig;

    this.dataInfoUploadImage = {
      flight_request_id: this.flightPlanContentData.flight_request_id,
      type_file: "aerodrome_data_extra"
    }
  }

  private _setLayout() {
    this.divPanel.nativeElement.querySelector('.p-panel .p-panel-header').style.backgroundColor = settingConfig.LAYOUT.background_color.aerodrome;
    this.divPanel.nativeElement.querySelector('.p-panel .p-panel-header').style.color = "#FFF";
    this.divPanel.nativeElement.querySelector('.p-panel .p-panel-header .p-panel-header-icon').setAttribute('style', 'color: #FFF !important');
    this.divPanel.nativeElement.querySelector('.p-panel .p-panel-header .p-icon-wrapper').setAttribute('style', 'color: #FFF !important');
  }
  //#endregion

  //#region "|--- PUBLIC METHODS ---|"
  createAerodromeCard() {
    this.generatePdfButtonAction.emit(TYPE_DOCUMENT.CARD_AERODROME);
  }
  //#endregion

  getSunset(xAerodromeId: string) {
    const tempSunsetSunrise = this.flightPlanContentData.sunset_sunrise.data;

    let returnDate = "";

    for (const objIndex in tempSunsetSunrise) {
      if (tempSunsetSunrise[objIndex].aerodrome_basic_data_id == xAerodromeId) {
        const tempDate = tempSunsetSunrise[objIndex].data.date;

        returnDate = `${tempDate.substring(8, 10)}/${tempDate.substring(5, 7)}/${tempDate.substring(0, 4)} <br> ${tempSunsetSunrise[objIndex].data.sunset} Z`
      }
    }

    return returnDate;
  }

  getSunrise(xAerodromeId: string) {
    const tempSunsetSunrise = this.flightPlanContentData.sunset_sunrise.data;

    let returnDate = "";

    for (const objIndex in tempSunsetSunrise) {
      if (tempSunsetSunrise[objIndex].aerodrome_basic_data_id == xAerodromeId) {
        const tempDate = tempSunsetSunrise[objIndex].data.date;

        returnDate = `${tempDate.substring(8, 10)}/${tempDate.substring(5, 7)}/${tempDate.substring(0, 4)} <br> ${tempSunsetSunrise[objIndex].data.sunrise} Z`
      }
    }

    return returnDate;
  }

  getIconName(xGoal: string): string {
    if (this.arrayAerodromeGoal.includes(xGoal)) {
      return xGoal;
    } else {
      return "alt_extras";
    }
  }

  getRunwayType(xType: string) {
    if (xType == "HELPN" || xType == "HP") {
      return "HELIPONTO";
    } else if (xType == "HD") {
      return "HELIDECK"
    } else {
      return "PISTA DE POUSO E DECOLAGEM";
    }
  }

  getResistanceLegend(xRunwayData: any) {
    const arrayTempResistance = xRunwayData["surface_resistance_type"].split("/");

    let returnData = "";

    if (arrayTempResistance.length > 0) {
      returnData = `<ul>`;

      if (xRunwayData["legend"]["pcn"] != "") {
        returnData = `${returnData}<li>PCN - ${xRunwayData["legend"]["pcn"]}</li>`;
      }

      if (xRunwayData["legend"]["flooring"] != "") {
        returnData = `${returnData}<li>${xRunwayData["legend"]["flooring"]}</li>`;
      }

      if (xRunwayData["legend"]["subgrade"] != "") {
        returnData = `${returnData}<li>${xRunwayData["legend"]["subgrade"]}</li>`;
      }

      if (xRunwayData["legend"]["pressure"] != "") {
        returnData = `${returnData}<li>${xRunwayData["legend"]["pressure"]}</li>`;
      }

      if (xRunwayData["legend"]["method"] != "") {
        returnData = `${returnData}<li>${xRunwayData["legend"]["method"]}</li>`;
      }

      returnData = `${returnData}</ul>`;
    }

    return returnData;
  }

  getRunwayLight(xRunwayLight: any) {
    let returnData = "";

    if (xRunwayLight.length > 0) {
      returnData = `<ul>`;

      for (let i = 0; i < xRunwayLight.length; i++) {
        returnData = `${returnData}<li>${xRunwayLight[i]["light_code"]} - ${xRunwayLight[i]["description"]}</li>`;
      }

      returnData = `${returnData}</ul>`;
    }

    return returnData;
  }

  getAerodromeData(xGoal: string, xField: string): any {
    let tempDataAerodrome;
    let returnData: any;

    if (this.arrayAerodromeGoal.includes(xGoal)) {
      tempDataAerodrome = this.aerodromesContentData[xGoal].data[0];
    } else {
      const tempIndex = parseInt(xGoal.replace("alt_", "")) - 3;

      tempDataAerodrome = this.aerodromesContentData["alt_extras"][tempIndex].data[0];
    }

    if (xField == "icao_code") {
      returnData = tempDataAerodrome["icao_code"];
    } else if (xField == "name") {
      returnData = tempDataAerodrome["name"];
    } else if (xField == "location") {
      returnData = `${tempDataAerodrome["place"]["city"]}/${tempDataAerodrome["place"]["state"]} - ${tempDataAerodrome["place"]["country_code"]}`;
    } else if (xField == "aerodrome_type") {
      const tempType = `${tempDataAerodrome["details"]["aerodrome_type"]}`;

      if (tempType.toUpperCase() == "HELPN" || tempType.toUpperCase() == "HP") {
        returnData = `(HP) Heliponto`;
      } else if (tempType.toUpperCase() == "HD") {
        returnData = `(HD) Helideck`;
      } else {
        returnData = `(AD) Aeródromo`;
      }
    } else if (xField == "coordinate") {
      returnData = `(${tempDataAerodrome["coordinate_dd"][0].toFixed(4)}, ${tempDataAerodrome["coordinate_dd"][1].toFixed(4)}) <br> (${tempDataAerodrome["coordinate_gms"][0]}, ${tempDataAerodrome["coordinate_gms"][1]}) <br> ${tempDataAerodrome["coordinate_plan"]}`;
    } else if (xField == "altitude") {
      returnData = `${tempDataAerodrome["elevation_meter"]} metros - ${tempDataAerodrome["elevation_feet"]} feet`;
    } else if (xField == "goal") {
      if (xGoal == "departure") {
        returnData = "PARTIDA";
      } else if (xGoal == "destination") {
        returnData = "DESTINO";
      } else if (xGoal == "alt_1") {
        returnData = "ALTERNATIVO 01";
      } else if (xGoal == "alt_2") {
        returnData = "ALTERNATIVO 02";
      } else {
        returnData = "ALTERNATIVO EXTRA";
      }
    } else if (xField == "details_raw") {
      return `${tempDataAerodrome["details"]["details_raw"]}`;
    } else if (xField == "category") {
      const tempCategory = `${tempDataAerodrome["details"]["category"]}`;

      if (tempCategory != "") {
        returnData = tempCategory;
      } else {
        returnData = "Nacional";
      }
    } else if (xField == "utilization_type") {
      returnData = `${tempDataAerodrome["details"]["utilization"]}`;
    } else if (xField == "operator") {
      returnData = `${tempDataAerodrome["details"]["operator"]["name"]}`;
    } else if (xField == "downtown") {
      returnData = `${tempDataAerodrome["details"]["downtown_distance"]}`;
    } else if (xField == "utc") {
      returnData = `UTC${tempDataAerodrome["details"]["time_zone"]}`;
    } else if (xField == "operation_type") {
      returnData = `${tempDataAerodrome["details"]["operation"]}`;
    } else if (xField == "lights") {
      const tempLights = tempDataAerodrome["lights_data"]["data"];

      returnData = `<ul>`

      for (let i = 0; i < tempLights.length; i++) {
        if (i == 0) {
          returnData = `<li>${tempLights[i]["light_code"]} - ${tempLights[i]["description"]}</li>`;
        } else {
          returnData = `${returnData} <li>${tempLights[i]["light_code"]} - ${tempLights[i]["description"]}</li>`;
        }
      }

      returnData = `${returnData}</ul>`
    } else if (xField == "fir") {
      returnData = `${tempDataAerodrome["details"]["fir"]}`;
    } else if (xField == "authority") {
      returnData = `${tempDataAerodrome["details"]["authority"]}`;
    } else if (xField == "runway_length") {
      returnData = `${tempDataAerodrome["runways_data"]["count"]}`;
    } else if (xField == "runway") {
      returnData = tempDataAerodrome["runways_data"]["data"];
    }
    else {
      returnData = xField;
    }

    return returnData;
  }
  //#endregion
}