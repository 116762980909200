<section class="cls-component-data-panel">
    <p-panel class="cls-component-panel-formgrid">
        <ng-template pTemplate="header">
            <div class="flex align-items-center gap-2">
                <div>
                    <span
                        class="font-bold">{{this.formComponent.controls.flight_request_code.controls.normalized_text.value}}</span>
                </div>
                <p-button icon="fa-solid fa-copy" severity="secondary" (click)="copyFlightRequestIdToClipboard()"
                    class="cls-component-panel-formgrid-header-button"
                    [ngClass]="{'cls-component-field-input-with-button-label': this._innerWidth >= this.settingConfig.WIDTH_NO_LABEL }">
                </p-button>
            </div>
        </ng-template>
        <div class="cls-component-form">
            <div class="grid">
                <div class="lg:col-4 md:col-12">
                    <div class="cls-component-minutes-eobt">
                        <div class="cls-component-minutes-eobt-clock">
                            <nashville-countdown-clock-flip
                                [targetDateTime]="this.formComponent.controls.date_time_eobt.controls.date_time_zulu.value">
                            </nashville-countdown-clock-flip>
                        </div>
                    </div>
                </div>
                <div class="lg:col-8 md:col-12">
                    <div class="cls-component-flight-request-control">
                        <div class="grid">
                            <div class="col-4 cls-component-flight-request-control-frame-button">
                                <p-splitButton label="45 MIN." icon="fa-solid fa-plus" (onClick)="plusMinutesEobt('45')"
                                    [model]="this.itensPlusMinutes" severity="secondary" size="small"
                                    class="cls-component-button-pattern-default-split cls-component-flight-request-control-button">
                                </p-splitButton>
                            </div>
                            <div class="col-4 cls-component-flight-request-control-frame-button">
                                <p-splitButton label="45 MIN." icon="fa-solid fa-minus"
                                    (onClick)="minusMinutesEobt('45')" [model]="this.itemsMinusMinutes"
                                    severity="secondary" size="small"
                                    class="cls-component-button-pattern-default-split cls-component-flight-request-control-button">
                                </p-splitButton>
                            </div>
                            <!-- VOLTAR -->
                            <!--<div class="col-4 cls-component-flight-request-control-frame-button">
                                <p-button label="CANCELAR VOO" icon="fa-solid fa-plane-slash" [raised]="true"
                                    severity="danger"
                                    class="cls-component-button-pattern-default cls-component-flight-request-control-button"
                                    [routerLink]="'/flight-request/list'">
                                </p-button>
                            </div>-->
                        </div>
                    </div>
                    <div class="cls-component-flight-request-control mt-2">
                        <!-- VOLTAR -->                        
                        <!--<div class="grid">
                            <div class="col-4 cls-component-flight-request-control-frame-button">
                                <p-button label="AERONAVE DECOLADA" icon="fa-solid fa-plane-departure" [raised]="true"
                                    severity="secondary"
                                    class="cls-component-button-pattern-default cls-component-flight-request-control-button"
                                    [routerLink]="'/flight-request/list'">
                                </p-button>
                            </div>
                            <div class="col-4 cls-component-flight-request-control-frame-button">
                                <p-button label="AERONAVE POUSADA" icon="fa-solid fa-plane-arrival" [raised]="true"
                                    severity="secondary"
                                    class="cls-component-button-pattern-default cls-component-flight-request-control-button"
                                    [routerLink]="'/flight-request/list'">
                                </p-button>
                            </div>
                            <div class="col-4 cls-component-flight-request-control-frame-button">
                                <p-button label="VOO FINALIZADO" icon="fa-solid fa-check-double" [raised]="true"
                                    severity="success"
                                    class="cls-component-button-pattern-default cls-component-flight-request-control-button"
                                    [routerLink]="'/flight-request/list'">
                                </p-button>
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
            <div class="grid">
                <div class="lg:col-12">
                    <div class="formgrid grid">
                        <div class="field col-3">
                            <div class="cls-component-field-input-with-button">
                                <ui-primeng-input-text label="CÓD. PLANO APROVADO #BOLD#"
                                    idField="fid-flight-plan-official-confirmation"
                                    [control]="this.formComponent.controls.flight_plan_official_confirmation"
                                    sizeInput="small" letterCase="upper"
                                    noLabelWidth="{{this.settingConfig.WIDTH_NO_LABEL}}">
                                </ui-primeng-input-text>
                                <p-button icon="fa-solid fa-paste" severity="secondary"
                                    (click)="pasteFlightPlanOfficialConfirmationToClipboard()"
                                    class="cls-component-field-input-with-button-button"
                                    [ngClass]="{'cls-component-field-input-with-button-label': this._innerWidth >= this.settingConfig.WIDTH_NO_LABEL }">
                                </p-button>
                                <p-button icon="fa-solid fa-copy" severity="secondary"
                                    (click)="copyFlightPlanOfficialConfirmationToClipboard()"
                                    class="cls-component-field-input-with-button-button"
                                    [ngClass]="{'cls-component-field-input-with-button-label': this._innerWidth >= this.settingConfig.WIDTH_NO_LABEL }">
                                </p-button>
                            </div>
                        </div>
                        <!-- VOLTAR -->
                        <!--<div class="field col-3">                            
                            <ui-primeng-dropdown label="CHECK LIST #BOLD#" idField="fid-status-flight-plan"
                                [itemsArray]="this.optionsCheckListRequest"
                                [control]="this.formComponent.controls.status_flight_plan" sizeInput="small"
                                letterCase="upper" noLabelWidth="{{this.settingConfig.WIDTH_NO_LABEL}}">
                            </ui-primeng-dropdown>
                        </div>
                        <div class="field col-3">
                            <ui-primeng-dropdown-template-color label="STATUS PLANO DE VOO #BOLD#"
                                idField="fid-status-flight-plan" [itemsArray]="this.optionsStatusFlightPlan"
                                [control]="this.formComponent.controls.status_flight_plan" sizeInput="small"
                                letterCase="upper" noLabelWidth="{{this.settingConfig.WIDTH_NO_LABEL}}">
                            </ui-primeng-dropdown-template-color>
                        </div>
                        <div class="field col-3">
                            <ui-primeng-dropdown-template-color label="STATUS ATENDIMENTO #BOLD#"
                                idField="fid-status-flight-treatment" [itemsArray]="this.optionsStatusTreatment"
                                [control]="this.formComponent.controls.status_flight_treatment" sizeInput="small"
                                letterCase="upper" noLabelWidth="{{this.settingConfig.WIDTH_NO_LABEL}}">
                            </ui-primeng-dropdown-template-color>
                        </div>-->
                    </div>

                </div>
            </div>
        </div>
    </p-panel>
</section>