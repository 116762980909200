<section class="cls-component-data-panel">    
    <p-panel header="Registro Aeronáutico" class="cls-component-panel-formgrid">
        <ng-container *ngIf="this.formComponent.controls.aircraft_data_id.value === null">
            <p-messages [(value)]="this.messagesNewAdvices" [closable]="false" class="cls-component-message-frame" key="advice-message-new" />
        </ng-container>
        <ng-container *ngIf="this.formComponent.controls.aircraft_data_id.value !== null">
            <p-messages [(value)]="this.messagesEditAdvices" [closable]="false" class="cls-component-message-frame" key="advice-message-edit" />
        </ng-container>        
        <div class="cls-component-form">
            <div class="formgrid grid">
                <div class="col-3">
                    <ui-primeng-dropdown-template-icon label="Tipo Aeronave #BOLD#" idField="fid-type-aircraft"
                        [control]="this.formComponent.controls.aero_registration.controls.type_aircraft"
                        [itemsArray]="this.optionsTypeAircraft" withFilter="false" placeHolder="..." sizeInput="small"
                        noLabelWidth="{{this.pageInnerWidth}}">
                    </ui-primeng-dropdown-template-icon>
                </div>
                <div class="col-3">
                    <ui-primeng-dropdown-template-flag label="País de Registro #BOLD#"
                        idField="fid-country-registration"
                        [control]="this.formComponent.controls.aero_registration.controls.country_registration"
                        [itemsArray]="this.optionsCountryRegistration" withFilter="false" placeHolder="..."
                        sizeInput="small" noLabelWidth="{{this.pageInnerWidth}}">
                    </ui-primeng-dropdown-template-flag>
                </div>
                <div class=" field col-3">
                    <ng-container *ngIf="this.formComponent.controls.aircraft_data_id.value !== null">
                        <ui-primeng-label label="* Registro Aeronave #BOLD#"></ui-primeng-label>
                        <div>
                            {{this.formComponent.controls.aero_registration.controls.mark.value}}
                        </div>
                    </ng-container>
                    <ng-container *ngIf="this.formComponent.controls.aircraft_data_id.value === null">
                        <ui-primeng-input-text label="* Registro Aeronave #BOLD#" idField="fid-mark"
                            [control]="this.formComponent.controls.aero_registration.controls.mark" sizeInput="small"
                            letterCase="upper" maxCharacters="7" noLabelWidth="{{this.pageInnerWidth}}">
                        </ui-primeng-input-text>
                    </ng-container>
                </div>
                <div class="field col-3">
                    <ui-primeng-input-text label="ICAO Code #BOLD#" idField="fid-icao-code"
                        [control]="this.formComponent.controls.aero_registration.controls.icao_code" sizeInput="small"
                        letterCase="upper" maxCharacters="15" noLabelWidth="{{this.pageInnerWidth}}">
                    </ui-primeng-input-text>
                </div>
            </div>
            <div class="formgrid grid">
                <div class="field col-3">
                    <ui-primeng-input-text label="Ano Fabricação #BOLD#" idField="fid-year-manufactured"
                        [control]="this.formComponent.controls.aero_registration.controls.year_manufactured"
                        sizeInput="small" maxCharacters="4" noLabelWidth="{{this.pageInnerWidth}}">
                    </ui-primeng-input-text>
                </div>
                <div class="field col-3">
                    <ui-primeng-input-text label="Fabricante #BOLD#" idField="fid-manufacturer"
                        [control]="this.formComponent.controls.aero_registration.controls.manufacturer"
                        sizeInput="small" letterCase="upper" noLabelWidth="{{this.pageInnerWidth}}">
                    </ui-primeng-input-text>
                </div>
                <div class="field col-3">
                    <ui-primeng-input-text label="Modelo #BOLD#" idField="fid-model"
                        [control]="this.formComponent.controls.aero_registration.controls.model" sizeInput="small"
                        letterCase="upper" noLabelWidth="{{this.pageInnerWidth}}">
                    </ui-primeng-input-text>
                </div>
                <div class="field col-3">
                    <ui-primeng-input-text label="Número Serial #BOLD#" idField="fid-serial-number"
                        [control]="this.formComponent.controls.aero_registration.controls.serial_number"
                        sizeInput="small" letterCase="upper" noLabelWidth="{{this.pageInnerWidth}}">
                    </ui-primeng-input-text>
                </div>
            </div>
            <div class="formgrid grid">
                <div class="field col-3">
                    <ui-primeng-dropdown label="Regra de Voo #BOLD#" idField="fid-flight-rules"
                        [control]="this.formComponent.controls.aero_registration.controls.flight_rules"
                        [itemsArray]="this.optionsFlightRules" withFilter="false" sizeInput="small" placeHolder="..."
                        noLabelWidth="{{this.pageInnerWidth}}">
                    </ui-primeng-dropdown>
                </div>
                <div class="field col-3">
                    <ui-primeng-input-text label="Número FIN" idField="fid-fin-number"
                        [control]="this.formComponent.controls.aero_registration.controls.fin_number" sizeInput="small"
                        letterCase="upper" noLabelWidth="{{this.pageInnerWidth}}">
                    </ui-primeng-input-text>
                </div>
                <div class="field col-3">
                    <ui-primeng-input-text label="Nome Aeronave" idField="fid-aircraft-name"
                        [control]="this.formComponent.controls.aero_registration.controls.aircraft_name"
                        sizeInput="small" letterCase="upper" noLabelWidth="{{this.pageInnerWidth}}">
                    </ui-primeng-input-text>
                </div>
                <div class="field col-3">
                    <ui-primeng-input-text label="Observação" idField="fid-obs-registration-data"
                        [control]="this.formComponent.controls.aero_registration.controls.obs_registration_data"
                        sizeInput="small" letterCase="upper" noLabelWidth="{{this.pageInnerWidth}}">
                    </ui-primeng-input-text>
                </div>
            </div>
            <div class="formgrid grid">
                <div class="field col-6 mt-2">
                    <div class="cls-component-table-frame-02">
                        <p-table [value]="this.formComponent.controls.owner_operator_data.value" [scrollable]="true"
                            scrollHeight="180px" [showCurrentPageReport]="true" rowExpandMode="multiple"
                            responsiveLayout="stack" class="cls-component-table-form-list"
                            styleClass="p-datatable-striped">
                            <ng-template pTemplate="caption">
                                <div
                                    class="flex align-items-center justify-content-between cls-component-table-form-list-caption">
                                    <span><i class="fa-solid fa-hands-holding-circle"></i> LISTA DE
                                        PROPRIETÁRIOS</span>
                                    <p-button icon="fa-solid fa-plus" size="small" severity="secondary"
                                        (click)="callbackShowOwnerDialog(this.labelActionsButtons.SHOW_DIALOG)"
                                        class="cls-component-table-form-list-caption-button"></p-button>
                                </div>
                            </ng-template>
                            <ng-template pTemplate="header">
                                <tr>
                                    <th style="width:24%; text-align: center">CPF/CNPJ</th>
                                    <th style="width:58%">NOME</th>
                                    <th style="width:18%; text-align: center"></th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-owner let-rowIndex="rowIndex">
                                <ng-container
                                    *ngIf="owner.type_owner_operator === 'owner' || owner.type_owner_operator === 'both'">
                                    <tr>
                                        <td style="text-align: center">{{ owner.ni }}</td>
                                        <td>{{ owner.name }}</td>
                                        <td style="text-align: center"
                                            class="cls-component-table-form-list-item-actions">
                                            <ng-container *ngIf="owner.type_owner_operator === 'owner'">
                                                <p-button icon="fa-regular fa-handshake" [rounded]="true" [text]="true"
                                                    severity="help" [outlined]="true"
                                                    (click)="this.setOwnerOperatorToBothType(rowIndex)"></p-button>
                                            </ng-container>
                                            <p-button icon="fa-solid fa-pen" [rounded]="true" [text]="true"
                                                severity="info" (click)="this.setOwnerOperatorEdit(rowIndex, 'owner')">
                                            </p-button>
                                            <p-button icon="fa-solid fa-trash-can" [rounded]="true" [text]="true"
                                                severity="danger"
                                                (click)="this.setOwnerOperatorDelete(rowIndex, 'owner')">
                                            </p-button>
                                        </td>
                                    </tr>
                                </ng-container>
                            </ng-template>
                        </p-table>
                    </div>
                </div>
                <div class="field col-6 mt-2">
                    <div class="cls-component-table-frame-02">
                        <p-table [value]="this.formComponent.controls.owner_operator_data.value" [scrollable]="true"
                            scrollHeight="180px" [showCurrentPageReport]="true" rowExpandMode="multiple"
                            responsiveLayout="stack" class="cls-component-table-form-list"
                            styleClass="p-datatable-striped">
                            <ng-template pTemplate="caption">
                                <div
                                    class="flex align-items-center justify-content-between cls-component-table-form-list-caption">
                                    <span><i class="fa-regular fa-handshake"></i> LISTA DE
                                        OPERADORES</span>
                                    <p-button icon="fa-solid fa-plus" size="small" severity="secondary"
                                        (click)="callbackShowOperatorDialog(this.labelActionsButtons.SHOW_DIALOG)"
                                        class="cls-component-table-form-list-caption-button">
                                    </p-button>
                                </div>
                            </ng-template>
                            <ng-template pTemplate="header">
                                <tr>
                                    <th style="width:24%; text-align: center">CPF/CNPJ</th>
                                    <th style="width:58%">NOME</th>
                                    <th style="width:18%; text-align: center"></th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-operator let-rowIndex="rowIndex">
                                <ng-container
                                    *ngIf="operator.type_owner_operator === 'operator' || operator.type_owner_operator === 'both'">
                                    <tr>
                                        <td>{{ operator.ni }}</td>
                                        <td>{{ operator.name }}</td>
                                        <td style="text-align: center"
                                            class="cls-component-table-form-list-item-actions">
                                            <ng-container *ngIf="operator.type_owner_operator === 'operator'">
                                                <p-button icon="fa-solid fa-hands-holding-circle" [rounded]="true"
                                                    [text]="true" severity="help" [outlined]="true"
                                                    (click)="this.setOwnerOperatorToBothType(rowIndex)"></p-button>
                                            </ng-container>
                                            <p-button icon="fa-solid fa-pen" [rounded]="true" [text]="true"
                                                severity="info"
                                                (click)="this.setOwnerOperatorEdit(rowIndex, 'operator')">
                                            </p-button>
                                            <p-button icon="fa-solid fa-trash-can" [rounded]="true" [text]="true"
                                                severity="danger"
                                                (click)="this.setOwnerOperatorDelete(rowIndex, 'operator')">
                                            </p-button>
                                        </td>
                                    </tr>
                                </ng-container>
                            </ng-template>
                        </p-table>
                    </div>
                </div>
            </div>
        </div>
    </p-panel>
</section>
