//#region "|--- INTERFACE ---|"
import { IDropDownTemplateColorOptions } from "src/app/interfaces/IDropDownTemplateColorOptions"; 
//#endregion

/**
 * TODO: https://tsdoc.org/
 */
export const riskDegreeOptions:IDropDownTemplateColorOptions[] =  [
    { label: 'BAIXA', value: 'low', color: "#000000" },
    { label: 'MÉDIO', value: 'medium', color: "#008000" },
    { label: 'SÉRIO', value: 'serious', color: "#FFD700" },
    { label: 'ALTO', value: 'high', color: "#8B0000" }            
]