<section id="id-dialog-search-aerodrome" class="cls-component-dialog-control">
  <p-dialog 
      [(visible)]="this.showDialog" 
      [modal]="true" 
      [showHeader]="false" 
      [closable]="false"
      [style]="{ width: '80rem' }" 
      class="cls-component-dialog-modal">
    <ng-template pTemplate="header"></ng-template>
    <ng-template pTemplate="content">
      <div class="grid">
        <div class="field col-12">
          <p-fieldset class="cls-component-fieldset-filter">
            <ng-template pTemplate="header">
              <span class="font-bold">Procurar Aeródromo / Heliporto / Heliponto / ZZZZ</span>
            </ng-template>
            <div class="">
              <div class="cls-component-form">
                <div class="formgrid grid">
                  <div class="field col-6 md:col-6 md:col-6 lg:col-6 xl:col-2">
                    <ui-primeng-input-text label="CÓD. ICAO" idField="fid-aerodrome-filter-table-icao-code"
                      [control]="this.formFilterSearchRecordedAerodrome.controls.icao_code" sizeInput="small"
                      letterCase="upper" isDisabled="false" noLabelWidth="{{this.settingConfig.WIDTH_NO_LABEL}}"
                      class="cls-component-field-aerodrome-composed-input" maxCharacters="4">
                    </ui-primeng-input-text>
                  </div>
                  <div class="field col-6 md:col-6 md:col-6 lg:col-6 xl:col-2">
                    <ui-primeng-dropdown label="Tipo" idField="fid-aerodrome-filter-aerodrome-type"
                      [control]="this.formFilterSearchRecordedAerodrome.controls.aerodrome_type"
                      [itemsArray]="this.optionsTypeAerodrome" withFilter="false" sizeInput="small" placeHolder="..."
                      noLabelWidth="{{this.settingConfig.WIDTH_NO_LABEL}}">
                    </ui-primeng-dropdown>
                  </div>
                  <div class="field col-6 md:col-6 md:col-6 lg:col-6 xl:col-2">
                    <ui-primeng-input-text label="Nome Aeródomo" idField="fid-aerodrome-filter-table-name"
                      [control]="this.formFilterSearchRecordedAerodrome.controls.name" sizeInput="small"
                      letterCase="upper" isDisabled="false" noLabelWidth="{{this.settingConfig.WIDTH_NO_LABEL}}"
                      class="cls-component-field-aerodrome-composed-input">
                    </ui-primeng-input-text>
                  </div>
                  <div class="field col-6 md:col-6 md:col-6 lg:col-6 xl:col-2">
                    <ui-primeng-input-text label="Cidade" idField="fid-aerodrome-filter-table-city"
                      [control]="this.formFilterSearchRecordedAerodrome.controls.city" sizeInput="small"
                      letterCase="upper" isDisabled="false" noLabelWidth="{{this.settingConfig.WIDTH_NO_LABEL}}"
                      class="cls-component-field-aerodrome-composed-input">
                    </ui-primeng-input-text>
                  </div>
                  <div class="field col-6 md:col-6 md:col-6 lg:col-6 xl:col-2">
                    <ui-primeng-input-text label="Estado" idField="fid-aerodrome-filter-table-state"
                      [control]="this.formFilterSearchRecordedAerodrome.controls.state" sizeInput="small"
                      letterCase="upper" isDisabled="false" noLabelWidth="{{this.settingConfig.WIDTH_NO_LABEL}}"
                      class="cls-component-field-aerodrome-composed-input">
                    </ui-primeng-input-text>
                  </div>

                  <div class="field col-6 md:col-6 md:col-6 lg:col-6 xl:col-2">
                    <ui-primeng-input-text label="Coord. (Raio 5 nm)" idField="fid-aerodrome-filter-coordinate"
                      [control]="this.formFilterSearchRecordedAerodrome.controls.coordinate" sizeInput="small"
                      letterCase="upper" isDisabled="false" noLabelWidth="{{this.settingConfig.WIDTH_NO_LABEL}}"
                      class="cls-component-field-aerodrome-composed-input">
                    </ui-primeng-input-text>
                  </div>
                </div>
              </div>
            </div>
          </p-fieldset>
          <div class="cls-component-dialog-search-aerodrome-table">
            <p-table [value]="this.arrayAerodromeSearch" responsiveLayout="stack"
              styleClass="p-datatable-gridlines p-datatable-striped" scrollHeight="23.5rem"
              [tableStyle]="{'height': '23.5rem'}">
              <ng-template pTemplate="header">
                <tr>
                  <th style="width:3%;"></th>
                  <th pSortableColumn="icao_code" class="text-center" style="width:10%;">ICAO
                    CODE
                    <p-sortIcon field="ica_code"></p-sortIcon>
                  </th>
                  <th class="text-center" style="width:7%;">TIPO</th>
                  <th pSortableColumn="name" style="width:30%;">NOME <p-sortIcon field="name">
                    </p-sortIcon>
                  </th>
                  <th class="text-center">PAÍS</th>
                  <th pSortableColumn="place.state" class="text-center">ESTADO
                    <p-sortIcon field="place.state"></p-sortIcon>
                  </th>
                  <th pSortableColumn="place.city" style="width:25%;">CIDADE
                    <p-sortIcon field="place.city"></p-sortIcon>
                  </th>
                  <th class="text-center">COORDENADAS</th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-aerodromes>
                <tr>
                  <td class="text-center">
                    <!--<div class="cls-component-dialog-search-aerodrome-table-action-button">
                    <p-button [rounded]="true" severity="secondary" [outlined]="true"
                      (click)="selectAerodrome(aerodromes.aerodrome_basic_data_id)">
                      <img src="{{this.getAerodromeIcon(null)}}" alt="">
                    </p-button>
                  </div>-->
                  </td>
                  <td class="text-center">{{ aerodromes.icao_code }}</td>
                  <td class="text-center">{{ aerodromes.aerodrome_type }}</td>
                  <td>{{ aerodromes.name }}</td>
                  <td class="text-center">{{ aerodromes.place.country_code }}</td>
                  <td class="text-center">{{ aerodromes.place.state }}</td>
                  <td>{{ aerodromes.place.city }}</td>
                  <td class="text-center">
                    <div>
                      ({{ aerodromes.coordinate_dd[0].toFixed(5) }},
                      {{ aerodromes.coordinate_dd[1].toFixed(5) }})
                    </div>
                  </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="summary">
                <div class="flex align-items-center justify-content-between">
                  Total de Aeródromos recuperados:
                  {{this.arrayAerodromeSearch ? this.arrayAerodromeSearch.length : 0 }}.
                </div>
              </ng-template>
            </p-table>
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template pTemplate="footer">
      <section class="cls-component-toolbar-control-dialog">
        <p-toolbar class="cls-component-toolbar-control-dialog-pattern">
          <div class="p-toolbar-group-start"></div>
          <div class="p-toolbar-group-center"></div>
          <div class="p-toolbar-group-end">
            <div class="cls-page-box-left">
              <p-button label="SALVAR" icon="fa-solid fa-rotate" [raised]="true" severity="success"
                class="cls-component-button-pattern-default" (click)="callbackButton(this.labelActionsButtons.OK)">
              </p-button>
              <p-button label="CANCELAR" icon="fa-regular fa-file-pdf" [raised]="true" severity="danger"
                class="cls-component-button-pattern-default"
                (click)="callbackButton(this.labelActionsButtons.CLOSE_DIALOG)">
              </p-button>
            </div>
          </div>
        </p-toolbar>
      </section>
    </ng-template>
  </p-dialog>
</section>