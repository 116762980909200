<section class="cls-component-data-panel">
    <ng-container *ngIf="this.formComponent.controls.flight_request_id.value !== null">
        <div class="grid">
            <div class="col-12">
                <div class="cls-component-quick-message">
                    <p-toolbar>
                        <div class="p-toolbar-group-start">
                            <span class="cls-component-quick-message-label">MENSAGEIRIA</span>
                        </div>
                        <div class="p-toolbar-group-center"></div>
                        <div class="p-toolbar-group-end">
                            <button class="cls-component-quick-message-button" severity="secondary" pButton
                                icon="fa-solid fa-rectangle-list"></button>
                        </div>
                    </p-toolbar>
                    <p-panel [toggleable]="true" [collapsed]="true" class="cls-component-quick-message-panel">
                        <ng-template pTemplate="icons">
                            <button class="p-panel-header-icon p-link" (click)="this.refreshMetarTafToClipboard()">
                                <span class="fa-solid fa-rotate"></span>
                            </button>
                            <button class="p-panel-header-icon p-link" (click)="this.copyMetarTafToClipboard()">
                                <span class="fa-solid fa-copy"></span>
                            </button>
                        </ng-template>
                        <ng-template pTemplate="header">
                            <div class="flex align-items-center gap-2">
                                <span class="cls-component-quick-message-item-label">METAR/TAF</span>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="content">
                            <ng-container *ngIf="this.metarTafMessageHtml === null">
                                Para Gerar uma Nova Consulta de METAR e TAF clique no botão <span
                                    class="fa-solid fa-rotate"></span> acima.
                            </ng-container>
                            <ng-container *ngIf="this.metarTafMessageHtml !== null">
                                <div [innerHTML]="this.metarTafMessageHtml"></div>
                            </ng-container>
                        </ng-template>
                        <ng-template pTemplate="footer">
                            <ng-container *ngIf="this.executionTimeMetarTaf === null">
                                Atualizado: ---
                            </ng-container>
                            <ng-container *ngIf="this.executionTimeMetarTaf !== null">
                                Atualizado: {{this.executionTimeMetarTaf}}
                            </ng-container>
                        </ng-template>
                    </p-panel>
                    <p-panel [toggleable]="true" [collapsed]="true" class="cls-component-quick-message-panel">
                        <ng-template pTemplate="icons">
                            <button class="p-panel-header-icon p-link" (click)="this.copyFlightIntentionToClipboard()">
                                <span class="fa-solid fa-copy"></span>
                            </button>
                        </ng-template>
                        <ng-template pTemplate="header">
                            <div class="flex align-items-center gap-2">
                                <span class="cls-component-quick-message-item-label">CONFIRMAR REQUISIÇÃO</span>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="content">
                            <div [innerHTML]="this.flightIntentionMessageHtml"></div>
                        </ng-template>
                        <ng-template pTemplate="footer">
                            <ng-container *ngIf="this.executionTimeFlightIntention === null">
                                Atualizado: ---
                            </ng-container>
                            <ng-container *ngIf="this.executionTimeFlightIntention !== null">
                                Atualizado: {{this.executionTimeFlightIntention}}
                            </ng-container>
                        </ng-template>
                    </p-panel>
                    <!-- VOLTAR -->
                    <!--
                    <p-panel [toggleable]="true" [collapsed]="true" class="cls-component-quick-message-panel">
                        <ng-template pTemplate="icons">
                            <button class="p-panel-header-icon p-link">
                                <span class="fa-solid fa-copy"></span>
                            </button>
                        </ng-template>
                        <ng-template pTemplate="header">
                            <div class="flex align-items-center gap-2">
                                <span class="cls-component-quick-message-item-label">PLANO ENVIADO AISWEB</span>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="content">

                        </ng-template>
                        <ng-template pTemplate="footer">
                            Atualizado: 23/05/2024 - 22:00
                        </ng-template>
                    </p-panel>
                    <p-panel [toggleable]="true" [collapsed]="true" class="cls-component-quick-message-panel">
                        <ng-template pTemplate="icons">
                            <button class="p-panel-header-icon p-link">
                                <span class="fa-solid fa-copy"></span>
                            </button>
                        </ng-template>
                        <ng-template pTemplate="header">
                            <div class="flex align-items-center gap-2">
                                <span class="cls-component-quick-message-item-label">PLANO APROVADO AISWEB</span>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="content">

                        </ng-template>
                        <ng-template pTemplate="footer">
                            Atualizado: 23/05/2024 - 22:00
                        </ng-template>
                    </p-panel>
                    <p-panel [toggleable]="true" [collapsed]="true" class="cls-component-quick-message-panel">
                        <ng-template pTemplate="icons">
                            <button class="p-panel-header-icon p-link">
                                <span class="fa-solid fa-copy"></span>
                            </button>
                        </ng-template>
                        <ng-template pTemplate="header">
                            <div class="flex align-items-center gap-2">
                                <span class="cls-component-quick-message-item-label">ENVIO DLA AISWEB</span>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="content">

                        </ng-template>
                        <ng-template pTemplate="footer">
                            Atualizado: 23/05/2024 - 22:00
                        </ng-template>
                    </p-panel>
                    <p-panel [toggleable]="true" [collapsed]="true" class="cls-component-quick-message-panel">
                        <ng-template pTemplate="icons">
                            <button class="p-panel-header-icon p-link">
                                <span class="fa-solid fa-copy"></span>
                            </button>
                        </ng-template>
                        <ng-template pTemplate="header">
                            <div class="flex align-items-center gap-2">
                                <span class="cls-component-quick-message-item-label">ENVIO CANCELAMENTO AISWEB</span>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="content">

                        </ng-template>
                        <ng-template pTemplate="footer">
                            Atualizado: 23/05/2024 - 22:00
                        </ng-template>
                    </p-panel>-->
                </div>
            </div>
        </div>
    </ng-container>


</section>
<!--<section>
    <div>

    </div>
    <div>
        <p-card>
            <ng-template pTemplate="header">
                CHECK LIST
                <p-progressBar [value]="50"></p-progressBar>
            </ng-template>
            <ng-template pTemplate="footer">
                <p-checkbox name="groupname" value="val1" label="ITEM 01"></p-checkbox>
                <p-checkbox name="groupname" value="val2" label="ITEM 02"></p-checkbox>
                <p-checkbox name="groupname" value="val1" label="ITEM 03"></p-checkbox>
                <p-checkbox name="groupname" value="val2" label="ITEM 04"></p-checkbox>
                <p-checkbox name="groupname" value="val1" label="ITEM 05"></p-checkbox>
                <p-checkbox name="groupname" value="val2" label="ITEM 06"></p-checkbox>
            </ng-template>
            <ng-template pTemplate="footer">

            </ng-template>
        </p-card>
    </div>

</section>
-->