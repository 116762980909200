<div class="cls-ui-primeng-field">
    <div class="cls-component-upload-dropzone"
        [ngClass]="{'cls-component-upload-dropzone-animation': this.fileOver === true }">
        <ng-container *ngIf="this.isMultiFiles === false">
            <input  
            #fileDropRef 
            type="file" 
            id="{{ this.idField }}"             
            accept="{{this.acceptExtension}}"
            (change)="fileBrowserHandler($event)" />
        </ng-container>
        <ng-container *ngIf="this.isMultiFiles === true">
            <input  
            #fileDropRef 
            type="file" 
            id="{{ this.idField }}" 
            multiple            
            accept="{{this.acceptExtension}}"
            (change)="fileBrowserHandler($event)" />
        </ng-container>       
        <div class="cls-component-upload-dropzone-box-upload" >                       
            <div class="cls-component-upload-dropzone-box-upload-image">
                <img src="assets/imgs/file_upload_image.png" alt="">
            </div>
            <div class="cls-component-upload-dropzone-box-upload-label">
                Arraste e Solte Aqui
            </div>
            <div class="cls-component-upload-dropzone-box-upload-label">
                Cole Aqui (Ctrl + V)
            </div>
            <div class="cls-component-upload-dropzone-box-upload-advice">
                Arquivo deve ter no máximo {{this.fileSizeLabel}}.
            </div>            
        </div>
    
        <div class="mb-3"></div>
        <label for="{{ this.idField }}">{{this.buttonLabelGetFile}}</label>
        <div></div>
    </div>
    <!--<div *ngIf="this.file">
        File details:
        <ul>
            <li>Name: {{file.name}}</li>
            <li>type: {{file.type}}</li>
            <li>Size: {{file.size}} bytes</li>
        </ul>
        <section [ngSwitch]="status">
            <p *ngSwitchCase="'uploading'">⏳ Uploading...</p>
            <p *ngSwitchCase="'success'">✅ Done!</p>
            <p *ngSwitchCase="'fail'">❌ Error!</p>
            <p *ngSwitchDefault>😶 Waiting to upload...</p>
        </section>
    </div>-->
</div>