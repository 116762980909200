<section class="cls-component-data-panel">
    <div #panelContentTopRisk class="cls-component-show-content-pdf">
        <p-panel [toggleable]="true" [collapsed]="this.collapsePanel" class="cls-component-show-content-pdf-panel">
            <ng-template pTemplate="icons">
                <button class="p-panel-header-icon p-link">
                    <span class="fa-solid fa-file-pdf"></span>
                </button>
            </ng-template>
            <ng-template pTemplate="header">
                <div class="flex align-items-center gap-2">
                    <span class="cls-component-show-content-pdf-title">TOP RISK</span>
                </div>
            </ng-template>
            <ng-template pTemplate="content">
                <p-accordion>
                    <p-accordionTab header="AVISOS">
                        <p>
                            Lorem ipsum dolor sit amet...
                        </p>
                    </p-accordionTab>
                    <p-accordionTab header="OBSTÁCULOS">
                        <p>
                            Lorem ipsum dolor sit amet...
                        </p>
                    </p-accordionTab>
                    <p-accordionTab header="METEOROLOGIA">
                        <p>
                            Lorem ipsum dolor sit amet...
                        </p>
                    </p-accordionTab>
                    <p-accordionTab header="ROTAER">
                        <p>
                            Lorem ipsum dolor sit amet...
                        </p>
                    </p-accordionTab>
                    <p-accordionTab>
                        <ng-template pTemplate="header">
                            <span class="flex align-items-center gap-2 w-full">
                                <span class="font-bold white-space-nowrap">
                                   INFORMAÇÕES ANEXAS - TOP RISK
                                </span>
                                <p-badge value="{{this.flightPlanContentData.top_risk.extra_data.data.length}}" class="ml-auto mr-2" />
                            </span>
                        </ng-template>
                        <ng-template pTemplate="content">
                            <nashville-extra-content
                                [dataInfo]="this.dataInfoUploadImage"
                                [formRequest]="this.iptStdFormRequest"
                                [arrayExtraData]="this.flightPlanContentData.top_risk.extra_data.data"
                                (resultUploadImage)="callbackActionExtraTopRiskData($event)">                                
                            </nashville-extra-content>
                        </ng-template>                        
                    </p-accordionTab>
                </p-accordion>
            </ng-template>
            <ng-template pTemplate="footer">

            </ng-template>
        </p-panel>
    </div>
</section>