<section class="cls-component-data-panel">
    <div #panelContentMeteorology class="cls-component-show-content-pdf">
        <p-panel [toggleable]="true" [collapsed]="this.collapsePanel" class="cls-component-show-content-pdf-panel">
            <ng-template pTemplate="icons">
                <button class="p-panel-header-icon p-link">
                    <span class="fa-solid fa-file-pdf"></span>
                </button>
            </ng-template>
            <ng-template pTemplate="header">
                <div class="flex align-items-center gap-2">
                    <span class="cls-component-show-content-pdf-title">METEOROLOGIA</span>
                </div>
            </ng-template>
            <ng-template pTemplate="content">
                <p-accordion [activeIndex]="0">
                    <p-accordionTab header="METAR">
                        <p>
                            Lorem ipsum dolor sit amet...
                        </p>
                    </p-accordionTab>
                    <p-accordionTab header="TAF">
                        <p>
                            Lorem ipsum dolor sit amet...
                        </p>
                    </p-accordionTab>
                    <p-accordionTab header="SIGWX">
                        <p>
                            Lorem ipsum dolor sit amet...
                        </p>
                    </p-accordionTab>
                    <p-accordionTab header="SATÉLITE">
                        <p>
                            Lorem ipsum dolor sit amet...
                        </p>
                    </p-accordionTab>
                    <p-accordionTab>
                        <ng-template pTemplate="header">
                            <span class="flex align-items-center gap-2 w-full">
                                <span class="font-bold white-space-nowrap">
                                    SIGMET
                                </span>
                            </span>
                        </ng-template>
                        <ng-template pTemplate="content">
                            <div class="grid">
                                <div class="col-7 cls-component-show-content-pdf-pseudo-table-grid-col">
                                    <div class="cls-component-show-content-pdf-sigmet-legend">
                                        <p-table [value]="this.arrayLegendSigmet"
                                            class="cls-component-table-form-list p-datatable-sm">
                                            <ng-template pTemplate="header">
                                                <tr>
                                                    <th>FENÔMENO</th>
                                                    <th>DESCRIÇÃO</th>
                                                    <th>COR</th>
                                                </tr>
                                            </ng-template>
                                            <ng-template pTemplate="body" let-legend let-rowIndex="rowIndex">
                                                <tr>
                                                    <td>{{ legend.fen }}</td>
                                                    <td>{{ legend.desc }}</td>
                                                    <td>
                                                        <div class="cls-component-show-content-pdf-sigmet-legend-color"
                                                            style="background-color: {{legend.color}};">
                                                        </div>
                                                    </td>
                                                </tr>
                                            </ng-template>
                                        </p-table>
                                    </div>
                                </div>
                                <div class="col-5 cls-component-show-content-pdf-pseudo-table-grid-col">
                                    <div class="cls-component-show-content-pdf-pseudo-table-img-aerodrome">
                                        <p-image
                                            src="{{this.serverUrl}}/mod_fp/flight/plan/data/sigmet/image/{{this.iptSmdFormRequest.controls.flight_request_id.value}}?url={{this.meteorologyContentData.sigmet.data[0].image}}"
                                            alt="Image" [preview]="true" />
                                    </div>
                                </div>
                            </div>
                            <div class="grid">
                                <div class="col-12 cls-component-show-content-pdf-pseudo-table-grid-col">
                                    <ng-container
                                        *ngFor="let itemSigmet of this.meteorologyContentData.sigmet.data[0].items; let elementIndex=index">
                                        <div class="cls-component-show-content-pdf-sigmet-data">
                                            <div class="cls-component-show-content-pdf-sigmet-data-title">                                                
                                                <div style="background-color: {{itemSigmet.fenomeno_transparencia}}">
                                                    {{itemSigmet.fenomeno_comp}} - FIR: {{itemSigmet.id_fir}}
                                                </div>                                                
                                            </div>                    
                                            <div class="cls-component-show-content-pdf-sigmet-data-mens">
                                                {{itemSigmet.mens}}
                                            </div>
                                            <div class="cls-component-show-content-pdf-sigmet-data-date">
                                               VALIDADE INICIO: {{this.setDateFormat(itemSigmet.validade_inicial)}} --- VALIDADE FIM: {{setDateFormat(itemSigmet.validade_final)}}
                                            </div>                                            
                                        </div>                                        
                                    </ng-container>
                                </div>
                            </div>
                        </ng-template>
                    </p-accordionTab>
                    <p-accordionTab>
                        <ng-template pTemplate="header">
                            <span class="flex align-items-center gap-2 w-full">
                                <span class="font-bold white-space-nowrap">
                                    INFORMAÇÕES ANEXAS - DADOS DE METEOROLOGIA
                                </span>
                                <p-badge value="{{this.meteorologyContentData.extra_data.data.length}}"
                                    class="ml-auto mr-2" />
                            </span>
                        </ng-template>
                        <ng-template pTemplate="content">
                            <nashville-extra-content [dataInfo]="this.dataInfoUploadImage"
                                [formRequest]="this.iptSmdFormRequest"
                                [arrayExtraData]="this.meteorologyContentData.extra_data.data"
                                (resultUploadImage)="callbackActionExtraMeteorologyData($event)">
                            </nashville-extra-content>
                        </ng-template>
                    </p-accordionTab>
                </p-accordion>

            </ng-template>
            <ng-template pTemplate="footer">

            </ng-template>
        </p-panel>
    </div>
</section>