//#region "|--- IMPORT MODULES/PACKAGES ---|"
import { Component, OnInit, Input, HostListener } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ToastPositionType } from 'primeng/toast'; // ToastPositionType = 'top-left' | 'top-center' | 'top-right' | 'bottom-left' | 'bottom-center' | 'bottom-right' | 'center';
import { MessageService } from 'primeng/api';

import { PrimengComponentsModule } from '@nashville-primeng';
//#endregion

//#region "|--- INTERFACE ---|"
import { IToastParameters } from 'src/app/interfaces/IToastParameters';
//#endregion

@Component({
  selector: 'nashville-page-toast',
  standalone: true,
  imports: [
    CommonModule, 
    PrimengComponentsModule
  ],
  templateUrl: './page-toast.component.html',
  styleUrl: './page-toast.component.scss',
})
export class PageToastComponent implements OnInit {
  //#region "|--- INPUTS ---|"
  @Input() noLabelWidth?: string;
  @Input() showPosition?: ToastPositionType;

  // É feito o set do @Input para que seja capturado qualquer modificação, em tempo real, 
  // do parâmetro que está sendo enviado pelo PARENT e, principalmente, que seja feito o 
  // processamento necessário com base no novo valor.
  @Input() set objToastMessage(xValue: IToastParameters) {
    

    if(xValue != undefined && xValue != null) {
      this._toastMessageShow = xValue;
      this.showToast();
    }    
  };
  //#endregion

  //#region "|--- PROPERTIES ---|"
  innerWidth?: any;

  _toastMessageShow?: IToastParameters;
  //#endregion 

  constructor(
    private messageService: MessageService,
  ) { }

  ngOnInit(): void {
    this._initVariables();
  }

  private _initVariables() {
    this.innerWidth = window.innerWidth;
  }

  //#region "|--- PAGE EVENTS ---|"
  /**
   * (v1) - Executada toda vez que ocorre REDIMENSIONAMENTO da Tela.
   * https://www.w3schools.com/jsref/dom_obj_event.asp
   * @param event
   */
  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    this.innerWidth = window.innerWidth;
  }
  //#endregion

  //#region "|--- PRIVATE METHODS---|" 
  getShowPosition(): ToastPositionType {
    if (this.showPosition) {
      return this.showPosition;
    } else {
      return "bottom-right";
    }
  }

  showToast() {
    this.messageService.add(this._toastMessageShow);    
  }
}
