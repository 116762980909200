import HTTP_STATUS from 'http-status-codes';
import { Component, AfterViewInit, Input, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';

import * as L from 'leaflet';

import { FlightPlanApisService } from '../../services/flight-plan-data-apis.service';
import { MenuItem, MessageService } from 'primeng/api';

import { PrimengComponentsModule } from '../../../../../layouts/primeng-components/primeng-components.module';

import { settingConfig } from '../../../../../config/settingConfig';
//#region "|--- INTERFACES ---|"
import { IAerodromeMarkerOnTheMap } from 'src/app/interfaces/IAerodromeMarkerOnTheMap';
import { IPatternResponseFromAPI } from 'src/app/interfaces/IPatternCrudResponseFromAPI';

//#endregion

//#region "|--- CONSTANTS ---|
const INITIAL_LAT = -14.654012;
const INITIAL_LNG = -57.962814;
const INITIAL_ZOOM = 4;
//#endregion

@Component({
  selector: 'nashville-navigation-map',
  standalone: true,
  imports: [
    PrimengComponentsModule,
    CommonModule
  ],
  templateUrl: './navigation-map.component.html',
  styleUrl: './navigation-map.component.scss',
})
export class NavigationMapComponent implements AfterViewInit, OnDestroy {
  //#region "|--- PRIVATE VARIABLE USED TO HELP "INPUT PROPERTY SETTER" ---|"
  private _aerodromesMarkers!: IAerodromeMarkerOnTheMap[];
  //#endregion

  //#region "|--- INPUTS ---|"
  // É feito o set do @Input para que seja capturado qualquer modificação, em tempo real, 
  // do parâmetro que está sendo enviado pelo PARENT e, principalmente, que seja feito o 
  // processamento necessário com base no novo valor.
  @Input() set objectToDraw(xValue: IAerodromeMarkerOnTheMap[]) {
    if (xValue) {
      this._objectToDrawOnMap = xValue;

      this._drawObjectsMap();
    }
  };
  //#endregion

  //#region "|--- PROPERTIES---|"
  private _mapViewDisplay!: L.Map;
  private _mapStateMachine!: any
  private _objectToDrawOnMap!: any;

  objMarkerOnMap: any = {};   // Armazena todos os Marcadores referentes aos AERÓDROMOS que foram plotados no MAPA.
  objDrawOnMap: any = {};     // Armazena todos os Desenhos referentes as ROTAS que foram plotados no MAPA.

  objAerodromesIconsToMarker: any = {};   // Lista dos Ícones para os Marcadores dos Aeródromos.

  urlImageSatelliteRealcada!: string;
  urlImageSatelliteDateTime!: string;
  //#endregion

  constructor(
    private flightPlanApisService: FlightPlanApisService,
    private messageService: MessageService,
  ) { }

  ngAfterViewInit(): void {
    const tmpContext = this;

    this._initStateMachineMap();

    setTimeout(() => {
      //tmpContext._initVariables();
      tmpContext._initMap();
      tmpContext._initIconsToMarkers();
      //tmpContext._initSatelliteImage();   
      this._executeMachineSate();
    }, 500); // Adjust the value (in ms) 
  }

  ngOnDestroy() {
    console.log("ON DESRTOYYYYYY")
    //this._map.removeControl(this.custom);
    //this._map.off('click', this.onClick);
  }

  /**
   * TODO: https://tsdoc.org/
   */
  private _initMap(): void {
    this._mapViewDisplay = L.map('map-navigation', {
      center: [INITIAL_LAT, INITIAL_LNG],
      zoom: INITIAL_ZOOM
    });
  }

  private _initStateMachineMap() {
    // INICIA A MÁQUINA DE ESTADOS QUANDO ELA VEM NULL
    this._mapStateMachine = {
      type_layout: "default",
      satellite_image: "realcada",
      show_aerodrome_status: true,
      show_sigmet: true,
    }
  }

  private _executeMachineSate() {
    this._processMapLayer(this._mapStateMachine.type_layout);
    this._processImageSatellite(this._mapStateMachine.satellite_image);
    this._processShowAerodromesStatus(this._mapStateMachine.show_aerodrome_status);
    this._processShowSigmet(this._mapStateMachine.show_sigmet);
  }

  private _processMapLayer(xTypeLayout: string) {
    if (xTypeLayout == "01") {
      L.tileLayer.wms('http://ows.mundialis.de/services/service?', {
        layers: 'SRTM30-Colored-Hillshade'
      }).addTo(this._mapViewDisplay)
    } else
      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        maxZoom: 18,
        minZoom: 3,
        attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
      }).addTo(this._mapViewDisplay);
  }

  private async _processImageSatellite(xImageType: string) {
    try {

      await this.flightPlanApisService.getUrlOnlineImageSatellite(xImageType).subscribe({
        next: async (xResponseServiceOnline: IPatternResponseFromAPI) => {
          let dataUrlSatelliteImage: any;

          if (xResponseServiceOnline.status_code == HTTP_STATUS.OK) {
            dataUrlSatelliteImage = xResponseServiceOnline.data_info[0];

            L.imageOverlay(dataUrlSatelliteImage.url, dataUrlSatelliteImage.lat_lng_bounds, { opacity: 0.6 }).addTo(this._mapViewDisplay);
          } else {
            await this.flightPlanApisService.getUrlServerImageSatellite(xImageType).subscribe({
              next: async (xResponseServiceServer: IPatternResponseFromAPI) => {
                if (xResponseServiceServer.status_code == HTTP_STATUS.OK) {
                  dataUrlSatelliteImage = xResponseServiceOnline.data_info[0];

                  const tempURL = `${settingConfig.API_PROTOCOL}://${settingConfig.API_HOST}:${settingConfig.API_FLIGHT_PLAN_PORT}/${settingConfig.API_PATH}/meteorology/data/image/satellite/${dataUrlSatelliteImage.file_name}`

                  L.imageOverlay(tempURL, dataUrlSatelliteImage.lat_lng_bounds, { opacity: 0.6 }).addTo(this._mapViewDisplay);
                }
              },
              complete: () => {

              },
              error: (xError: any) => {
                this.messageService.add({ severity: 'error', summary: 'Imagem de Satélite', detail: `Erro ao Recuperar a Imagem de Satélite.` });
              }
            })
          }
        },
        complete: () => {

        },
        error: (xError: any) => {
          if (xError.status == HTTP_STATUS.NOT_FOUND) {
            this.messageService.add({ severity: 'error', summary: 'Imagem de Satélite', detail: `O REDEMET está com problema de fornecer a Imagem de Satélite.` });
          } else {
            this.messageService.add({ severity: 'error', summary: 'Imagem de Satélite', detail: `Erro ao Recuperar a Imagem de Satélite.` });
          }
        }
      });

    } catch (xError) {
      this.messageService.add({ severity: 'error', summary: 'Imagem de Satélite', detail: `Exceção ao Recuperar a Imagem de Satélite.` });
    }
  }

  private async _processShowSigmet(xShow: boolean) {
    if (xShow) {
      await this.flightPlanApisService.getCurrentSigmetData().subscribe({
        next: async (xResponseServiceOnline: IPatternResponseFromAPI) => {
          let dataCurrentSigmetData: any;

          if (xResponseServiceOnline.status_code == HTTP_STATUS.OK) {
            dataCurrentSigmetData = xResponseServiceOnline.data_info[0].data;
          } else {

          }
        },
        complete: () => {

        },
        error: (xError: any) => {
          this.messageService.add({ severity: 'error', summary: 'Imagem de Satélite', detail: `Erro ao Recuperar a Imagem de Satélite.` });
        }
      });
    }
  }

  private async _processShowAerodromesStatus(xShow: boolean) {
    if (xShow) {
      await this.flightPlanApisService.getCurrentAerodromeStatusData().subscribe({
        next: async (xResponseServiceOnline: IPatternResponseFromAPI) => {
          let dataCurrentShowAerodromes: any;

          if (xResponseServiceOnline.status_code == HTTP_STATUS.OK) {
            dataCurrentShowAerodromes = xResponseServiceOnline.data_info[0];

          } else {

          }
        },
        complete: () => {

        },
        error: (xError: any) => {
          this.messageService.add({ severity: 'error', summary: 'Imagem de Satélite', detail: `Erro ao Recuperar a Imagem de Satélite.` });
        }
      });
    }
  }

  private async _drawObjectsMap() {
    // APAGA TODOS OS MARKERS.
    this._clearAllMarkersOnMap();
    this._clearAllMarMapOnMap();

    // DESENHA TODOS OS MARKERS
    this._objectToDrawOnMap.marker.forEach((xMarker: any) => {
      this.objMarkerOnMap[`${xMarker.id}`] = L.marker([xMarker.coord_dd![0], xMarker.coord_dd![1]], { icon: this.objAerodromesIconsToMarker[this._aerodromeTargetIcon(xMarker.target)] }).addTo(this._mapViewDisplay);
    });

    // DESENHA TODOS OS DRAW
    this._objectToDrawOnMap.draw.forEach((xDraw: any) => {
      this.objMarkerOnMap[`${xDraw.id}`] = L.polyline([xDraw.from, xDraw.to], {
        color: 'black',
        weight: 3,
        opacity: 0.9,
        smoothFactor: 1
      }).addTo(this._mapViewDisplay);
    });

    this._centerMapByMarkers();
  }

  private _centerMapByMarkers() {
    // SEMPRE QUE FOR CHAMADA, DEVE-SE INICIALIZAR O ARRAY DE PONTOS PARA CALCULAR AS BORDAS DA CENTRALIZAÇÃO.
    const arrayLocationLatLong: any = [];

    if (this._objectToDrawOnMap.marker) {
      this._objectToDrawOnMap.marker.forEach((xMarker: any) => {
        arrayLocationLatLong.push(L.latLng(xMarker.coord_dd![0], xMarker.coord_dd![1]));
      });
    }

    // CALCULO DAS BORDAS DO LIMITE QUE SERá MOSTRADO NO MAPA.
    //const bounds = L.latLngBounds(toBoundLocationMarkers.map(toBoundLocationMarkers => toBoundLocationMarkers.getLatLng()));
    const originalBounds = L.latLngBounds(arrayLocationLatLong);
    const bounds = this._extraBoardToFit(originalBounds);

    if (this._mapViewDisplay) { // VERIFICAR SE O MAPA FOI INICIALIZADO.
      if (arrayLocationLatLong) {
        if (arrayLocationLatLong.length < 1) { // Nenhum ponto, deve-se centralizar na forma inicial
          this._mapViewDisplay.setView(new L.LatLng(INITIAL_LAT, INITIAL_LNG), INITIAL_ZOOM); // Posição da Inicialização...
        } else if (arrayLocationLatLong.length > 1) { // Quando tiver mais de 1 ponto, deve-se usar a opção de mostrar o mapa com base nas bordas...
          this._mapViewDisplay.fitBounds(bounds);
        } else if (arrayLocationLatLong.length == 1) {
          // Quando for uma coordenada só, basta recentrar o mapa
          // É preciso colocar um timer, pois tem momento que ele esta em transição - removendo ou colocando markers, por exemplo - e pode não aplicar o zoom... Coloca o Marker, mas sem zoom.                              
          setTimeout(() => {
            this._mapViewDisplay.setView(new L.LatLng(this._objectToDrawOnMap.marker[0].coord_dd![0], this._objectToDrawOnMap.marker[0].coord_dd![1]), 8);
          }, 1)
        }
      }
    }
  }

  private _extraBoardToFit(xBounds: any) {
    if (Object.keys(xBounds).length > 0) {
      const originalNorthEast = xBounds._northEast;
      const originalSouthWest = xBounds._southWest;
      const diffLat = Math.abs(Math.abs(originalNorthEast.lat) - Math.abs(originalSouthWest.lat));
      const diffLng = Math.abs(Math.abs(originalNorthEast.lng) - Math.abs(originalSouthWest.lng));

      let returnNewBounds = {};
      let northEastLat = originalNorthEast.lat;
      let northEastLng = originalNorthEast.lng;
      let southWestLat = originalSouthWest.lat;
      let southWestLng = originalSouthWest.lng;
      let deltaLat = 0.15;
      let deltaLng = 0.15;

      if (northEastLat < 0) {
        northEastLat = northEastLat + deltaLat;
      } else {
        northEastLat = northEastLat + deltaLat;
      }

      if (northEastLng < 0) {
        northEastLng = northEastLng - deltaLng;
      } else {
        northEastLng = northEastLng - deltaLng;
      }

      if (southWestLat < 0) {
        southWestLat = southWestLat - deltaLat;
      } else {
        southWestLat = southWestLat - deltaLat;
      }

      if (southWestLng < 0) {
        southWestLng = southWestLng + deltaLng;
      } else {
        southWestLng = southWestLng + deltaLng;
      }

      returnNewBounds = L.latLngBounds(L.latLng(northEastLat, northEastLng), L.latLng(southWestLat, southWestLng));

      return returnNewBounds;
    } else {
      return xBounds;
    }
  }

  private _clearAllMarkersOnMap() {
    // IMPORTANTE: Não precisa criar um método para apagar uma posição especifica, pois toda vez que o 
    // array com o dados a serem plotados forem enviados para o Mapa, eles já vem com o resultado final.                
    if (this.objMarkerOnMap) {
      for (var keyObject in this.objMarkerOnMap) {
        if (Object.prototype.hasOwnProperty.call(this.objMarkerOnMap, keyObject)) {
          this._mapViewDisplay.removeLayer(this.objMarkerOnMap[keyObject]);
        }
      }
    }

    this.objMarkerOnMap = {}; // TEm que reiniciar o Objeto, agora que todos os MArcadores foram removidos...
  }

  private _clearAllMarMapOnMap() {
    // IMPORTANTE: Não precisa criar um método para apagar uma posição especifica, pois toda vez que o 
    // array com o dados a serem plotados forem enviados para o Mapa, eles já vem com o resultado final.                
    if (this.objDrawOnMap) {
      for (var keyObject in this.objDrawOnMap) {
        if (Object.prototype.hasOwnProperty.call(this.objDrawOnMap, keyObject)) {
          this._mapViewDisplay.removeLayer(this.objDrawOnMap[keyObject]);
        }
      }
    }

    this.objDrawOnMap = {}; // TEm que reiniciar o Objeto, agora que todos os MArcadores foram removidos...
  }

  private _aerodromeTargetIcon(xTarget: string): string {
    let returnIcon = "";

    if (xTarget == "departure") {
      returnIcon = "blueIcon";
    } else if (xTarget == "destination") {
      returnIcon = "redIcon";
    } else if (xTarget == "alt_1") {
      returnIcon = "roxoIcon";
    } else if (xTarget == "alt_2") {
      returnIcon = "cyanIcon";
    } else {
      returnIcon = "maroonIcon";
    }

    return returnIcon;
  }

  /**
   * TODO: https://tsdoc.org/
   */
  private _initIconsToMarkers() {
    /***************|--- COLORS AND LINKS TO ICONS ---|***************
     * SOBRE CORES: https://html-color.codes/black
     * ICONES CUSTOMIZÁVEIS AERODROMOS: https://www.iconpacks.net/free-icon/airport-location-4358.html
     * - airport-location-blue.png: BODY:#214fc6 - BACK:#ffffff - BORDER:#214fc6
     * - airport-location-red.png: BODY:#d40000 - BACK:#ffffff - BORDER:#d40000
     * - airport-location-roxo.png: BODY:#9f00c5 - BACK:#ffffff - BORDER:#9f00c5
     * - airport-location-orange.png: BODY:#ff4f00 - BACK:#ffffff - BORDER:#ff4f00
     * - airport-location-black.png: BODY:#000000 - BACK:#ffffff - BORDER:#ff4f00
     * - airport-location-green.png: BODY:#006b3c - BACK:#ffffff - BORDER:#006b3c
     * - airport-location-cyan.png: BODY:#00cccc - BACK:#ffffff - BORDER:#00cccc
     * - airport-location-pink.png: BODY:#fe4eda - BACK:#ffffff - BORDER:#fe4eda
     * - airport-location-yellow.png: BODY:#ffd800 - BACK:#ffffff - BORDER:#ffd800
     * - airport-location-gray.png: BODY:#808080  - BACK:#ffffff - BORDER:#808080
     * - airport-location-maroon.png: BODY:#800000  - BACK:#ffffff - BORDER:#8b0000
     * ICONES CUSTOMIZÁVEIS CIRCLE: https://www.iconpacks.net/free-icon/japan-flag-circular-17764.html
     * - circle-red.png: INSIDE:#d40000 - OUTSIDE:#d40000
     * - circle-green.png: INSIDE:#006b3c - OUTSIDE:#006b3c
     * - circle-yellow.png: INSIDE:#ffd800 - OUTSIDE:#ffd800
     * - circle-gray.png: INSIDE:#808080  - OUTSIDE:#808080
     * - circle-green-white.png: INSIDE:ffffff - OUTSIDE:#006b3c
     ****************************************************************/

    //#region "|--- AERODROMES ---|"    
    this.objAerodromesIconsToMarker["blueIcon"] = L.icon({ // OK
      iconUrl: '../../../../../assets/imgs/l_icon/airport-location-blue.png',
      iconSize: [40, 40], // size of the icon
      iconAnchor: [20, 40], // point of the icon which will correspond to marker's location
      popupAnchor: [-3, -76] // point from which the popup should open relative to the iconAnchor
    });

    this.objAerodromesIconsToMarker["greenIcon"] = L.icon({ // OK
      iconUrl: '../../../../../assets/imgs/l_icon/airport-location-green.png',
      iconSize: [40, 40], // size of the icon
      iconAnchor: [20, 40], // point of the icon which will correspond to marker's location
      popupAnchor: [-3, -76] // point from which the popup should open relative to the iconAnchor
    });

    this.objAerodromesIconsToMarker["redIcon"] = L.icon({ // OK
      iconUrl: '../../../../../assets/imgs/l_icon/airport-location-red.png',
      iconSize: [40, 40], // size of the icon
      iconAnchor: [20, 40], // point of the icon which will correspond to marker's location
      popupAnchor: [-3, -76] // point from which the popup should open relative to the iconAnchor
    });

    this.objAerodromesIconsToMarker["blackIcon"] = L.icon({ // OK
      iconUrl: '../../../../../assets/imgs/l_icon/airport-location-black.png',
      iconSize: [40, 40], // size of the icon
      iconAnchor: [20, 40], // point of the icon which will correspond to marker's location
      popupAnchor: [-3, -76] // point from which the popup should open relative to the iconAnchor
    });

    this.objAerodromesIconsToMarker["roxoIcon"] = L.icon({ // OK
      iconUrl: '../../../../../assets/imgs/l_icon/airport-location-roxo.png',
      iconSize: [40, 40], // size of the icon
      iconAnchor: [20, 40], // point of the icon which will correspond to marker's location
      popupAnchor: [-3, -76] // point from which the popup should open relative to the iconAnchor
    });

    this.objAerodromesIconsToMarker["orangeIcon"] = L.icon({ //OK
      iconUrl: '../../../../../assets/imgs/l_icon/airport-location-orange.png',
      iconSize: [40, 40], // size of the icon
      iconAnchor: [20, 40], // point of the icon which will correspond to marker's location
      popupAnchor: [-3, -76] // point from which the popup should open relative to the iconAnchor
    });

    this.objAerodromesIconsToMarker["cyanIcon"] = L.icon({ //OK
      iconUrl: '../../../../../assets/imgs/l_icon/airport-location-cyan.png',
      iconSize: [40, 40], // size of the icon
      iconAnchor: [20, 40], // point of the icon which will correspond to marker's location
      popupAnchor: [-3, -76] // point from which the popup should open relative to the iconAnchor
    });

    this.objAerodromesIconsToMarker["yellowIcon"] = L.icon({ // OK
      iconUrl: '../../../../../assets/imgs/l_icon/airport-location-yellow.png',
      iconSize: [40, 40], // size of the icon
      iconAnchor: [20, 40], // point of the icon which will correspond to marker's location
      popupAnchor: [-3, -76] // point from which the popup should open relative to the iconAnchor
    });

    this.objAerodromesIconsToMarker["maroonIcon"] = L.icon({ // OK
      iconUrl: '../../../../../assets/imgs/l_icon/airport-location-maroon.png',
      iconSize: [40, 40], // size of the icon
      iconAnchor: [20, 40], // point of the icon which will correspond to marker's location
      popupAnchor: [-3, -76] // point from which the popup should open relative to the iconAnchor
    });
    //#endregion
  }


  /*private _initAerodromeStatus() {

  }*/

  /**
   * TODO: https://tsdoc.org/
   */
  /*private _initExamples() {
    //#region "|--- CRIAR UM MARCADOR ---|"
    // SIMPLES
    let marker_simple = L.marker([-25.404034, -49.232340]).addTo(this.map_navigation);

    // COM POPUP
    let marker_pop_ip = L.marker([-14.654012, -56.162814]).addTo(this.map_navigation)
      .bindPopup('A pretty CSS popup.<br> Easily customizable.')
      .openPopup();
    //#endregion

    //#region "|--- CRIAR FIGURAS GEOMÉTRICAS ---|"
    // CIRCULO - SIMPLES - RAIO EM METGROS
    let circle = L.circle([-23.476597, -52.017960], {
      color: 'red',
      fillColor: '#f03',
      fillOpacity: 0.5,
      radius: 500
    }).addTo(this.map_navigation);

    // POLIGONO
    let polygon = L.polygon([
      [-23.952567, -52.019699],
      [-24.363556, -52.429213],
      [-23.869709, -52.646338]
    ]).addTo(this.map_navigation);
    //#endregion

    //#region "|--- INCLUIR POP-UP ---|"
    // EM OBJETOS JA CRIADOS
    marker_simple.bindPopup("<b>Hello world!</b><br>I am a popup.").openPopup();
    circle.bindPopup("I am a circle.");
    polygon.bindPopup("I am a polygon.");

    // POP-UP COMO LAYER
    var popup = L.popup()
      .setLatLng([-3.300937, -59.583036])
      .setContent("I am a standalone popup.")
      .openOn(this.map_navigation);
    //#endregion

    //#region "|--- INCLUIR EVENTOS ---|"   
    this.map_navigation.on('click', this._onMapClick, this);
    //#endregion    
  }*/

  /**
   * TODO: https://tsdoc.org/
   */
  /*private _onMapClick(e: any) {
    var popup = L.popup()
      .setLatLng(e.latlng)
      .setContent("You clicked the map at " + e.latlng.toString())
      .openOn(this.map_navigation);
  }*/



  /**
   * TODO: https://tsdoc.org/
   */
  /*private _settingAerodromesMarker() {
    // Se tiver MARCADORES para Plotar, deve-se apagar todos os marcadores anteriores...
    // Se não tiver nenhum MARCADOR para Plotar, deve-se apagar todos os marcadores anteriores...
    this._clearAllAerodromesMarkerOnMap();
    this._clearAllRoutesMarkerOnMap();

    if (this._aerodromesMarkers) {
      this._aerodromesMarkers.forEach((xElementAerodrome: IAerodromeMarkerOnTheMap) => {
        let tmpIconClass = "";

        if (xElementAerodrome.icon_class == "departure") {
          tmpIconClass = "blueIcon";
        } else if (xElementAerodrome.icon_class == "destination") {
          tmpIconClass = "redIcon";
        } else if (xElementAerodrome.icon_class == "alt_1") {
          tmpIconClass = "roxoIcon";
        } else if (xElementAerodrome.icon_class == "alt_2") {
          tmpIconClass = "orangeIcon";
        } else {
          tmpIconClass = "blackIcon";
        }

        this.objAerodromesMarkersOnMap[`${xElementAerodrome.id_marker}`] = L.marker([xElementAerodrome.coordinates_dd![0], xElementAerodrome.coordinates_dd![1]], { icon: this.objAerodromesIconsToMarker[tmpIconClass] }).addTo(this.map_navigation);
      });
    }

    this._centerMapByMarkers();
    this._drawRoutes();
  }*/

  /**
   * TODO: https://tsdoc.org/
   */
  /**/

  /**
   * TODO: https://tsdoc.org/
   */
  /*private _clearAllRoutesMarkerOnMap() {
    // IMPORTANTE: Não precisa criar um método para apagar uma posição especifica, pois toda vez que o 
    // array com o dados a serem plotados forem enviados para o Mapa, eles já vem com o resultado final.                
    if (this.objRoutesOnMap) {
      for (var keyObject in this.objRoutesOnMap) {
        this.map_navigation.removeLayer(this.objRoutesOnMap[keyObject]);
      }
    }

    this.objRoutesOnMap = {}
  }*/

  /**
  * TODO: https://tsdoc.org/
  */
  /*private _centerMapByMarkers() {
    // SEMPRE QUE FOR CHAMADA, DEVE-SE INICIALIZAR O ARRAY DE PONTOS PARA CALCULAR AS BORDAS DA CENTRALIZAÇÃO.
    const arrayLocationMarkers: L.Marker[] = [];

    // Esta alteração não influencia no posicionamento e nem nos cálculos de distância ente as coordenadas dos aerodromos, pois somente influencia no calculo dos limites da imagem que via centralizada.
    const toBoundLocationMarkers: L.Marker[] = []; // Cria-se este Array apenas para limitar a imagem que vai ser mostrada no DIV. Acontece que os Icones tem tamanho de 40, e quando usa-se o fitBound, ele ficam no limite. 

    if (this._aerodromesMarkers) {
      this._aerodromesMarkers.forEach((xElementAerodrome: IAerodromeMarkerOnTheMap) => {
        arrayLocationMarkers.push(L.marker([xElementAerodrome.coordinates_dd![0], xElementAerodrome.coordinates_dd![1]]));
        toBoundLocationMarkers.push(L.marker([xElementAerodrome.coordinates_dd![0] + 0.2, xElementAerodrome.coordinates_dd![1]])); // somando 0.2, eu garanto que a imagem fica bem centralizada...
      });
    }

    // CALCULO DAS BORDAS DO LIMITE QUE SERá MOSTRADO NO MAPA.
    const bounds = L.latLngBounds(toBoundLocationMarkers.map(toBoundLocationMarkers => toBoundLocationMarkers.getLatLng()));

    if (this.map_navigation) { // VERIFICAR SE O MAPA FOI INICIALIZADO.
      if (arrayLocationMarkers) {
        if (arrayLocationMarkers.length < 1) { // Nenhum ponto, deve-se centralizar na forma inicial
          this.map_navigation.setView(new L.LatLng(INITIAL_LAT, INITIAL_LNG), INITIAL_ZOOM); // Posição da Inicialização...
        } else if (arrayLocationMarkers.length > 1) { // Quando tiver mais de 1 ponto, deve-se usar a opção de mostrar o mapa com base nas bordas...
          this.map_navigation.fitBounds(bounds);
        } else if (arrayLocationMarkers.length == 1) {
          if (this._aerodromesMarkers) {
            if (Object.keys(this._aerodromesMarkers).length == 1) {
              // Quando for uma coordenada só, basta recentrar o mapa
              // É preciso colocar um timer, pois tem momento que ele esta em transição - removendo ou colocando markers, por exemplo - e pode não aplicar o zoom... Coloca o Marker, mas sem zoom.
              setTimeout(() => {
                this.map_navigation.setView(new L.LatLng(this._aerodromesMarkers[0].coordinates_dd![0], this._aerodromesMarkers[0].coordinates_dd![1]), 8);
              }, 1100)
            }
          }
        }
      }
    }
  }*/

  /**
   * TODO: https://tsdoc.org/
   */
  /*private _drawRoutes(): void {
    let tmpHasDeparture: boolean = false;
    let tmpHasDestination: boolean = false;
    let tmpHasAlt1: boolean = false;
    let tmpHasAlt2: boolean = false;
    let pointDeparture: any;
    let pointDestination: any;
    let pointAlt1: any;
    let pointAlt2: any;

    if (this._aerodromesMarkers) {
      this._aerodromesMarkers.forEach(xElement => {
        if (xElement.aerodrome_target == "departure") {
          pointDeparture = xElement.coordinates_dd;
          tmpHasDeparture = true;
        } else if (xElement.aerodrome_target == "destination") {
          pointDestination = xElement.coordinates_dd;
          tmpHasDestination = true;
        } else if (xElement.aerodrome_target == "alt_1") {
          pointAlt1 = xElement.coordinates_dd;
          tmpHasAlt1 = true;
        } else if (xElement.aerodrome_target == "alt_2") {
          pointAlt2 = xElement.coordinates_dd;
          tmpHasAlt2 = true;
        }
      });
    }

    if (tmpHasDeparture && tmpHasDestination) {
      this.objRoutesOnMap["departure_destination"] = new L.Polyline([pointDeparture, pointDestination], {
        color: 'black',
        weight: 3,
        opacity: 0.9,
        smoothFactor: 1
      }).addTo(this.map_navigation);
    }

    if (tmpHasDestination && tmpHasAlt1) {
      this.objRoutesOnMap["destination_alt1"] = new L.Polyline([pointDestination, pointAlt1], {
        color: 'red',
        weight: 3,
        opacity: 0.9,
        smoothFactor: 1
      }).addTo(this.map_navigation);
    }

    if (tmpHasAlt1 && tmpHasAlt2) {
      this.objRoutesOnMap["alt1_alt2"] = new L.Polyline([pointAlt1, pointAlt2], {
        color: 'blue',
        weight: 3,
        opacity: 0.9,
        smoothFactor: 1
      }).addTo(this.map_navigation);
    }
  }*/

  /**
   * TODO: https://tsdoc.org/
   */
  /*private _initVariables(): void {

  }*/
}
