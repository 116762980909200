const pageCode = "JRANPHFR";

//#region "|--- IMPORT MODULES ---|"
import { environment } from "src/environment/environment";
//#endregion

// SOBRE CONFIGURAÇÃO DE AMBIENTE.
//https://dev.to/nikosanif/using-shared-settings-between-multiple-environments-in-angular-1d0e

class SettingConfig {
    public IS_PRODUCTION_ENV: boolean | undefined;
    public ANGULAR_ENV: string | undefined;
    public WEB_SERVICE_NAME: string | undefined;
    public PROJECT_NAME: string | undefined;
    public FRONT_END_VERSION_BUILD: string | undefined;
    public BACK_END_VERSION_BUILD: string | undefined;
    public API_PROTOCOL: string | undefined;    
    public API_HOST: string | undefined;
    public API_AIRCRAFT_DATA_PORT: string | undefined;
    public API_FLIGHT_PLAN_PORT: string | undefined;
    public API_AIM_PORT: string | undefined;
    public API_PATH: string | undefined;
    public WIDTH_NO_LABEL: string | undefined;
    public TOAST_POSITION: string | undefined;   
    public SCHEDULED_ID_LENGTH: number | undefined;  
    public REQUEST_AXIOS_TIMEOUT: number | undefined;
    public NUMBER_ATTEMPTS: number | undefined;
    public INTERVAL_ATTEMPTS: number | undefined;
    public MAX_FILE_SIZE_UPLOAD: number | undefined;
    public TABLE_STACK: string | undefined;
    public SHOW_LOG_EXCEPTION: boolean | undefined; 
    public LAYOUT: any;
            
    constructor() {
        this.IS_PRODUCTION_ENV = environment.production;
        this.ANGULAR_ENV = environment.angular_env || "development";
        this.WEB_SERVICE_NAME = environment.web_service_name || "";
        this.PROJECT_NAME = environment.project_name || "";
        this.FRONT_END_VERSION_BUILD = environment.front_end_version_build || "";
        this.BACK_END_VERSION_BUILD = environment.backs_end_version_build || "";
        this.API_PROTOCOL = environment.api_protocol || "http";
        this.API_HOST = environment.api_host || "web-api.flysolutions.com.br"; 
        this.API_AIRCRAFT_DATA_PORT = environment.api_aircraft_data_port || "443";
        this.API_FLIGHT_PLAN_PORT = environment.api_flight_plan_port || "443";
        this.API_AIM_PORT = environment.api_aim_port || "443";
        this.API_PATH = environment.api_path || ""; 
        this.WIDTH_NO_LABEL = environment.width_no_label || "576";    
        this.TOAST_POSITION = environment.toast_position || "bottom-left"   
        this.SCHEDULED_ID_LENGTH = parseInt(environment.scheduled_id_length) || 15;        
        this.REQUEST_AXIOS_TIMEOUT = parseInt(environment.scheduled_id_length) || 8000; // Default vai ser 8 segundos
        this.NUMBER_ATTEMPTS = parseInt(environment.number_attempts) || 5;
        this.INTERVAL_ATTEMPTS = parseInt(environment.interval_attempts) || 2000;
        this.MAX_FILE_SIZE_UPLOAD = parseInt(environment.max_file_size_upload) || 2100000;
        this.TABLE_STACK = environment.table_tack || "960px";
        this.SHOW_LOG_EXCEPTION =  environment.show_log_exception || false;

        this.LAYOUT = {
            background_color: {
                menu_pdf: "3a9a9a9",
                flight_data: "#006400",
                top_risk: "#E2062C",
                aerodrome: "#000080",
                meteorology: "#FF4500"                        
            },
            sub_background_color: {
                menu_pdf: "#d3d3d3",
                flight_data: "#addfad",
                top_risk: "#ff9999",
                aerodrome: "#77b5fe",                        
                meteorology: "#FFCC99"
            },
            icons: {
                departure: "airport-location-blue",
                destination: "airport-location-red",
                alt_1: "airport-location-roxo",
                alt_2: "airport-location-cyan",
                alt_extras: "airport-location-maroon",
                topRisk: "top-risk-red",
                flight_data: "file-gray",
                menu_pdf: "menu-gray",
                meteorology: "meteorology-gray"
            }
        }
    }

    public validateConfig(): void {
        // Busca uma Key ou Value em uym determinado objeto.
        for (const [key, value] of Object.entries(this)) {
            if (value === undefined) {
                throw new Error(`Configuration ${key} is undefined`);
            }
        }
    }
}

export const settingConfig: SettingConfig = new SettingConfig();
