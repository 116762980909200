//#region "|--- INTERFACE ---|"
import { IDropDownTemplateColorOptions } from "src/app/interfaces/IDropDownTemplateColorOptions"; 
//#endregion

/**
 * TODO: https://tsdoc.org/
 */
export const statusTreatmentOptions:IDropDownTemplateColorOptions[] =  [
    { label: 'NOVO', value: 'new', color: "#FF0000" },
    { label: 'INCIO', value: 'begin', color: "#00FF00" },
    { label: 'FIM', value: 'end', color: "#0000FF" }
]