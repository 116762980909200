//#region "|--- INTERFACE ---|"
import { IDropDownBasicOptions } from '../../interfaces/IDropDownBasicOptions';
//#endregion

/**
 * TODO: https://tsdoc.org/
 */
export const sortResultObstaclesOptions: IDropDownBasicOptions[] = [
    { label: 'Mais Próximos', value: 'radius' },
    { label: 'Altura Decrescente', value: 'heig' },
    { label: 'Altitude Decrescente', value: 'alti' },
    { label: 'Altura Decrescente - Altitude Decrescente', value: 'heig_alti' },
    { label: 'Altitude Decrescente - Altura Decrescente', value: 'alti_heig' },
]