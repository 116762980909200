<section id="id-toolbar-dialog-aircraft-data" class="cls-component-toolbar-control-dialog">
    <p-toolbar class="cls-component-toolbar-control-dialog-pattern">
        <div class="p-toolbar-group-start"></div>
        <div class="p-toolbar-group-center"></div>
        <div class="p-toolbar-group-end">
            <div class="cls-page-box-left">               
                <ng-container *ngIf="this.formComponent.controls.owner_operator_id.value === null">
                    <p-button label="SALVAR {{this.labelButtonType}}" icon="fa-regular fa-floppy-disk" [raised]="true"
                        (click)="callbackDialogButton(this.labelActionsButtons.SAVE)" severity="secondary"
                        class="cls-component-button-pattern-default">
                    </p-button>
                </ng-container>
                <ng-container *ngIf="this.formComponent.controls.owner_operator_id.value !== null">
                    <p-button label="ATUALIZAR {{this.labelButtonType}}" icon="fa-solid fa-rotate" [raised]="true"
                        severity="secondary" (click)="callbackDialogButton(this.labelActionsButtons.UPDATE)"
                        class="cls-component-button-pattern-default">
                    </p-button>
                </ng-container>
                <p-button label="FECHAR" icon="fa-solid fa-arrow-right-from-bracket"
                    (click)="callbackDialogButton(this.labelActionsButtons.CANCEL)" [raised]="true"
                    severity="secondary" class="cls-component-button-pattern-default">
                </p-button>
            </div>
        </div>
    </p-toolbar>
</section>