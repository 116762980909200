//#region "|--- IMPORT MODULES/PACKAGES ---|"
import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';

import { Table } from 'primeng/table';
import { TableLazyLoadEvent } from 'primeng/table';
import { MessageService } from 'primeng/api';

import { settingConfig } from '@nashville-config';
import { PrimengComponentsModule } from '@nashville-primeng-components';
import { LocalMethodsHandlerClass } from '@nashville-local-methods';
//#endregion

//#region "|--- IMPORT COMPONENTS ---|"
import { PageTitleComponent } from '../../../../shared/components/page-title/page-title.component';
import { PageToastComponent } from '../../../../shared/components/page-toast/page-toast.component';
import { ToolbarTableFlightRequestListComponent } from './components/toolbar-table-flight-request-list/toolbar-table-flight-request-list.component';
//#endregion

//#region "|--- INTERFACE ---|"
import { IToastParameters } from '../../../../interfaces/IToastParameters';
import { IPatternResponseFromAPI } from '../../../../interfaces/IPatternCrudResponseFromAPI';
//#endregion

//#region "|--- SERVICE ---|"
import { FlightPlanApisService } from '../../services/flight-plan-data-apis.service';
//#endregion

//#region "|--- ENUMS ---|"
import { BUTTON_ACTION } from '../../../../helpers/enum/ButtonActions';
//#endregion

//#region "|--- CONSTANTS ---|"
const TITLE_LIST = "REQUISIÇÕES DE PLANOS DE VOO";
//#endregion

@Component({
  selector: 'nashville-flight-plan-request-list',
  standalone: true,
  imports: [
    CommonModule,
    PageTitleComponent,
    PageToastComponent,
    PrimengComponentsModule,
    ToolbarTableFlightRequestListComponent
  ],
  templateUrl: './flight-plan-request-list.component.html',
  styleUrl: './flight-plan-request-list.component.scss',
})
export class FlightPlanRequestListComponent implements OnInit {
  @ViewChild('dt1') dt1: Table | undefined;

  //#region "|--- PROPERTIES---|"
  settingConfig!: any;

  arrayAllFlightRequests: any;

  loading: boolean = false;

  totalRecords!: number;

  objToastMessage: IToastParameters;

  currentPageTitle: string;

  localMethodsHandlerClass: any;
  //#endregion

  constructor(
    private flightPlanApisService: FlightPlanApisService,
    private messageService: MessageService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this._initVariables();
  }

  //#region "|--- PUBLIC METHODS---|"
  applyFilterGlobal($event: any, stringVal: any) {
    this.dt1.filterGlobal(($event.target as HTMLInputElement).value, stringVal);
  }
  //#endregion

  //#region "|--- PRIVATE METHODS---|"
  /**
   * TODO: https://tsdoc.org/
   */
  private _initVariables(): void {
    this.settingConfig = settingConfig;
    this.currentPageTitle = TITLE_LIST;

    this.localMethodsHandlerClass = LocalMethodsHandlerClass;
  }

  /**
   * TODO: https://tsdoc.org/
   */
  updateFlightPlanning(xFlightPlanningId: any) {
    this.router.navigateByUrl(`/flight-request/form-ls/${xFlightPlanningId}`);
  }

  /**
   * TODO: https://tsdoc.org/
   */
  loadFlightRequest(event: TableLazyLoadEvent) {
    this.loading = true;

    setTimeout(() => {
      const lazyEvent = JSON.stringify(event);
      const firstElement = event.first;
      const sizeRows = event.rows;

      this._loadAllFlightPlans();
      this.loading = false;
    }, 1000);
  }

  /**
   * TODO: https://tsdoc.org/
   */
  private async _loadAllFlightPlans() {
    await this.flightPlanApisService.getAllFlightRequest().subscribe({
      next: (xResponseService: IPatternResponseFromAPI) => {

        if (xResponseService.status_code == 200) {
          this.arrayAllFlightRequests = xResponseService.data_info.data;

          this._showToast({
            severity: 'success',
            summary: 'Listar Requisições de Planos de Voo',
            detail: 'Lista de requisições de planos de voo recuperada com sucesso.',
          });
        } else {
          if (this.settingConfig.SHOW_LOG_EXCEPTION) {
            console.log("Erro - Listar Requisições de Planos de Voo: ", xResponseService);
          }

          this._showToast({
            severity: 'error',
            summary: 'Listar Requisições de Planos de Voo',
            detail: 'Erro - Recuperar requisições de planos de voo.',
          });
        }
      },
      complete: () => {

      },
      error: (xErrorService: any) => {
        let tempMessage = "";
        let tempSeverity = "error";

        if (this.settingConfig.SHOW_LOG_EXCEPTION) {
          console.log("Exceção - Listar Requisições de Planos de Voo: ", xErrorService);
        }

        if (xErrorService.status == 0) {
          tempMessage = 'Exceção - Não foi possível conectar com o servidor da aplicação.';
        } else if (xErrorService.status == 404) {
          tempMessage = 'Exceção - Não foi possível conectar com o servidor da aplicação.'

          if (xErrorService.error) {
            if (xErrorService.error.data_info) {
              if (xErrorService.error.data_info.qtd == 0) {
                tempSeverity = "warn";
                tempMessage = "Exceção - Nenhuma requisição de plano de voo registrado no sistema";
              }
            }
          }
        } else {
          tempMessage = 'Exceção - Recuperar requisições de planos de voo.';
        }

        this._showToast({
          severity: tempSeverity,
          summary: 'Listar Requisições de Planos de Voo',
          detail: tempMessage,
        });
      }
    });
  }

  /**
   * TODO: https://tsdoc.org/
   */
  private async _showToast(xObjMessage: IToastParameters) {
    this.objToastMessage = null; // Tem que forçar a mudança para que o componente registre a alteração do valor da propriedade.
    this.objToastMessage = xObjMessage;
  }
  //#endregion


  //#region "|--- CALLBACK METHODS---|"
  /**
   * TODO: https://tsdoc.org/
   */
  callBackToolbarFormList(xAction: string) {
    if (xAction == BUTTON_ACTION.RELOAD) {
      this.arrayAllFlightRequests = [];

      this._loadAllFlightPlans();
    }
  }
  //#endregion

}
