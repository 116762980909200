import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LoginBasic01Component } from '../../../../layouts/frames-layout/login-frames/login-basic-01/login-basic-01.component';
import { LoginCover01Component } from '../../../../layouts/frames-layout/login-frames/login-cover-01/login-cover-01.component';

@Component({
  selector: 'nashville-login-page',
  standalone: true,
  imports: [
    CommonModule,
    LoginBasic01Component,
    LoginCover01Component
  ],
  templateUrl: './login-page.component.html',
  styleUrl: './login-page.component.scss',
})
export class LoginPageComponent implements OnInit {
  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnInit(): void {}

  isLoginBasic01LayoutRequested(): boolean {
    document.body.setAttribute('data-layout_frame', "login-basic-01");

    return true;
  }

  isLoginCover01LayoutRequested(): boolean {
    return false;
  }
}
