//#region "|--- IMPORT MODULES/PACKAGES ---|"
// ***** ANGULAR *****
import { Component, OnInit, HostListener } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { FormBuilder, FormControl, FormGroup, UntypedFormControl } from '@angular/forms';
import { Router } from '@angular/router';

// ***** NPM *****
import clipboard from 'clipboardy';
import domtoimage from 'dom-to-image';
import HTTP_STATUS from 'http-status-codes';
import { MessageService, MenuItem, Message } from 'primeng/api';
import { copyImageToClipboard } from 'copy-image-clipboard';

// ***** NASHVILLE *****
import { settingConfig } from '@nashville-config';
import { LocalMethodsHandlerClass } from '@nashville-local-methods';
import { PrimengComponentsModule } from '@nashville-primeng-components';
//#endregion

//#region "|--- IMPORT COMPONENTS ---|"
import { PanelRequestDataComponent } from './components/panel-request-data/panel-request-data.component';
import { PanelAircraftDataComponent } from './components/panel-aircraft-data/panel-aircraft-data.component';
import { ProcessMonitoringDataComponent } from './components/process-monitoring-data/process-monitoring-data.component';
import { QuickAccessDataComponent } from './components/quick-access-data/quick-access-data.component';
import { PanelContentPdfComponent } from './components/panel-content-pdf/panel-content-pdf.component';
import { PanelFlightDataComponent } from './components/panel-flight-data/panel-flight-data.component';
import { PanelGeneratePdfComponent } from './components/panel-generate-pdf/panel-generate-pdf.component';
import { PanelNotamDataComponent } from './components/panel-notam-data/panel-notam-data.component';
import { PanelMeteorologyDataComponent } from './components/panel-meteorology-data/panel-meteorology-data.component';
import { PanelTopRiskDataComponent } from './components/panel-top-risk-data/panel-top-risk-data.component';
import { DialogManyAerodromeBasicDataComponent } from './components/dialog-many-aerodrome-basic-data/dialog-many-aerodrome-basic-data.component';
import { DialogSearchAerodromeCoordComponent } from './components/dialog-search-aerodrome-coord/dialog-search-aerodrome-coord.component';
import { DialogSearchAerodromeIcaoComponent } from './components/dialog-search-aerodrome-icao/dialog-search-aerodrome-icao.component';
import { DialogRouteSaveComponent } from './components/dialog-route-save/dialog-route-save.component';
import { DialogRouteListComponent } from './components/dialog-route-list/dialog-route-list.component';
import { DialogWaitingProcessComponent } from './components/dialog-waiting-process/dialog-waiting-process.component';
import { NavigationMapComponent } from '../../components/navigation-map/navigation-map.component';
import { SearchLocationMapComponent } from '../../components/search-location-map/search-location-map.component';
import { BottomUtcDatetimeComponent } from '../../components/bottom-utc-datetime/bottom-utc-datetime.component';
import { PageTitleComponent } from '../../../../shared/components/page-title/page-title.component';
import { PageToastComponent } from '../../../../shared/components/page-toast/page-toast.component';
import { ToolbarPanelContentPdfComponent } from './components/toolbar-panel-content-pdf/toolbar-panel-content-pdf.component';
import { ToolbarPanelGeneratePdfComponent } from './components/toolbar-panel-generate-pdf/toolbar-panel-generate-pdf.component';
import { ToolbarFormFlightRequestComponent } from './components/toolbar-form-flight-request/toolbar-form-flight-request.component';
//#endregion

//#region "|--- IMPORT FORM ---|"
import { initFormRequest } from '../../form-init/request-form';
import { initFormChecklist } from '../../form-init/checklist-form';
import { initFormRiskList } from '../../form-init/risk-list-form';
//#endregion

//#region "|--- IMPORT INTERFACES ---|"
import { IPatternResponseFromAPI } from '../../../../interfaces/IPatternCrudResponseFromAPI';
import { IDropDownBasicOptions } from '../../../../interfaces/IDropDownBasicOptions';
import { IAerodromeMarkerOnTheMap } from '../../../../interfaces/IAerodromeMarkerOnTheMap';
import { ICityMarkerOnTheMap } from '../../../../interfaces/ICityMarkerOnTheMap';
import { IToastParameters } from '../../../../interfaces/IToastParameters';
//#endregion

//#region "|--- IMPORT SERVICE ---|"
import { FlightPlanApisService } from '../../services/flight-plan-data-apis.service';
//#endregion

//#region "|--- IMPORT ENUMS ---|"
import { BUTTON_ACTION } from '../../../../helpers/enum/ButtonActions';
import { ICON_AERODROME } from '../../../../helpers/enum/IconAerodrome';
import { TYPE_DOCUMENT } from '../../../../helpers/enum/TypeDocuments';
//#endregion

//#region "|--- IMPORT STATIC OPTIONS ---|"
import { braStatesOptions } from '../../../../helpers/dropdown-static-options/braStatesOptions';
import { countryOptions } from '../../../../helpers/dropdown-static-options/countryOptions';
import { flightPlanFlightRulesOptions } from '../../../../helpers/dropdown-static-options/flightPlanFlightRulesOptions';
//#endregion

//#region "|--- CONSTANTS ---|"
const TITLE_NEW = "CADASTRAR NOVA REQUISIÇÃO DE VOO";
const TITLE_UPDATE = "ATUALIZAR DADOS DA REQUISIÇÃO DE VOO";
//#endregion

@Component({
  selector: 'nashville-flight-plan-request-form',
  standalone: true,
  imports: [
    CommonModule,
    PrimengComponentsModule,
    BottomUtcDatetimeComponent,
    DialogManyAerodromeBasicDataComponent,
    DialogRouteListComponent,
    DialogRouteSaveComponent,
    DialogSearchAerodromeCoordComponent,
    DialogSearchAerodromeIcaoComponent,
    DialogWaitingProcessComponent,
    NavigationMapComponent,
    PageTitleComponent,
    PageToastComponent,
    PanelAircraftDataComponent,
    PanelContentPdfComponent,
    PanelFlightDataComponent,
    PanelGeneratePdfComponent,
    PanelNotamDataComponent,
    PanelMeteorologyDataComponent,
    PanelRequestDataComponent,
    PanelTopRiskDataComponent,
    ProcessMonitoringDataComponent,
    QuickAccessDataComponent,
    SearchLocationMapComponent,
    ToolbarPanelContentPdfComponent,
    ToolbarPanelGeneratePdfComponent,
    ToolbarFormFlightRequestComponent,
  ],
  templateUrl: './flight-plan-request-form.component.html',
  styleUrl: './flight-plan-request-form.component.scss',
})
export class FlightPlanRequestFormComponent implements OnInit {
  //#region "|--- PROPERTIES---|"
  // ***** CONFIG *****
  innerWidth?: any;
  settingConfig!: any;
  currentPageTitle: string;

  // ***** FORMS *****
  formRequest!: any;

  // ***** OPTIONS DROPDOWN *****
  optionsPilots!: IDropDownBasicOptions[];
  optionsOperators!: IDropDownBasicOptions[];
  optionsAircraft!: IDropDownBasicOptions[];
  optionsFlightPlanFlightRules!: IDropDownBasicOptions[];
  optionsMapStates!: IDropDownBasicOptions[];

  // ***** SHOW DIALOG *****
  showDialogWaitingSaveFlightRequest: boolean;
  showDialogWaitingUpdateFlightRequest: boolean;
  showDialogWaitingLoadingData: boolean;
  showDialogWaitingGettingData: boolean;
  showDialogWaitingUpdatingFlightPlanData: boolean;
  showDialogWaitingPdfGenerating: boolean;
  showDialogWaitingGeneratingDocumentCard: boolean;
  showDialogWaitingProcess: boolean;
  showDialogManyAerodromeBasicData: boolean;
  showDialogSearchAerodromeCoord: boolean;
  showDialogSearchAerodromeIcao: boolean;
  showDialogRouteSave: boolean;
  showDialogRouteList: boolean;

  // ***** SHOW SIDEBAR *****
  showSidebarMessages: boolean;
  showSidebarCheckList: boolean;

  // ***** ARRAYS *****
  arrayChecklist!: any[];
  arrayMessages1: Message[] | undefined;
  arrayMessages2: Message[] | undefined;
  arrayGoalAerodrome!: any[];



  // ***** ???????? *****


  objToastMessage: IToastParameters;

  formTempAerodrome!: any;
  formTopRisk!: any;

  currentFlightPlanData: any;

  objectToDrawOnMaps: any;

  formFlightPlanRequest!: any;

  formChecklist!: any;

  arrayRecoverAircraft: any;
  arrayPilots: any;

  optionsCheckList!: IDropDownBasicOptions[];

  formFilterSearchRecordedAerodrome!: any;
  formFilterSearchLocation!: any;

  formIntentionMessage!: FormGroup;

  formCopyToClipboard!: FormGroup; // Este Form é utilizado para controle de botão, não precisa inicializar por fora.

  isMapCopied!: boolean;
  isIntentionMessageCopied!: boolean;
  isMetarTafCopied!: boolean;

  itemsNewFlightPlan!: MenuItem[];

  optionsMapCities!: IDropDownBasicOptions[];

  optionsMapCountry!: IDropDownBasicOptions[];

  arrayAerodromesToMarkMap: IAerodromeMarkerOnTheMap[] = [];

  arrayCitiesToMarkMap: ICityMarkerOnTheMap[] = [];

  showDialogSearchLocation!: boolean;

  objRoutesOnMap: any = {}; // Armazena todos os Marcadores referentes as ROTAS que foram plotados no MAPA.

  objAerodromeIcones: any = {};

  currentTagAerodromeTarget!: string;

  currentAction!: string
  //#endregion

  constructor(
    private flightPlanApisService: FlightPlanApisService,
    private messageService: MessageService,
    private route: ActivatedRoute,
    private location: Location,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this._initVariables();

    this._initFormRequest();
    this._initFormFilterSearchLocation();

    this._recoverAircraft();
    this._recoverPilots();

    // Tem que esperar um pouco, pois a inicialização do FORM pode não ter terminado.
    setTimeout(() => {
      this._initEventsFlightRequestForm();
    }, 10);





    this._initFormTopRisk();
    this._initFormChecklist();
    this._initFormFilterSearchLocationEvents();







    this._initFilterSearchRecordedAerodromeForm();

    this._initCopyToClipboardForm();


    this._initIntentionMessageForm();

    // TEM QUE SER O ULTIMO ELEMENTO DA INICIALIZAÇÃO, POIS TEM QUE CARREGAR OS COMBOS PRIMEIROS.
    this._checkEditMode(); // Verifica se o Formulário vai ser usado para Salvar ou Editar dados.
  }

  //#region "|--- HOST LISTENER ---|"
  /**
   * @type HOST LISTENER
   * @version 1.0.0 (GASPAR - 11/06/2024)
   * - Versão inicial.
   * 
   * @description 
   * - Executada toda vez que ocorre REDIMENSIONAMENTO da Tela.
   * - https://www.w3schools.com/jsref/dom_obj_event.asp
   * 
   * @param event 
   */
  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    this.innerWidth = window.innerWidth;
  }
  //#endregion

  //#region "|--- LISTENERS: VALUE CHANGES ---|"
  /**
   * @status OK
   * @type METHOD
   * @version 1.0.0 (GASPAR - 11/06/2024)
   * - Versão inicial.
   * 
   * @description 
   * - Faz a inicialização dos LISTENERS que capturam as mudanças de valores dos controles do FORM FLIGHT REQUEST. 
   */
  private _initEventsFlightRequestForm() {
    this.formRequest.get('crew.first_in_command.normalized_text').valueChanges.subscribe((xValue: any) => {
      if (xValue && xValue != null && xValue.length > 0) {
        const arrayItems = xValue.split('-');

        if (arrayItems.length == 2) {
          this.formRequest.controls.crew.controls.first_in_command.controls.pilot_id.setValue(arrayItems[0].trim());
          this.formRequest.controls.crew.controls.first_in_command.controls.full_name.setValue(arrayItems[1].trim());
        } else {
          this.formRequest.controls.crew.controls.first_in_command.controls.pilot_id.setValue(null);
          this.formRequest.controls.crew.controls.first_in_command.controls.full_name.setValue(null);
        }
      } else {
        this.formRequest.controls.crew.controls.first_in_command.controls.pilot_id.setValue(null);
        this.formRequest.controls.crew.controls.first_in_command.controls.full_name.setValue(null);
      }
    });

    this.formRequest.get('crew.second_in_command.normalized_text').valueChanges.subscribe((xValue: any) => {
      if (xValue && xValue != null && xValue.length > 0) {
        // Verifica se existe valor.
        if (xValue.length > 0) {
          const arrayItems = xValue.split('-');

          if (arrayItems.length == 2) {
            this.formRequest.controls.crew.controls.second_in_command.controls.pilot_id.setValue(arrayItems[0].trim());
            this.formRequest.controls.crew.controls.second_in_command.controls.full_name.setValue(arrayItems[1].trim());
          } else {
            this.formRequest.controls.crew.controls.second_in_command.controls.pilot_id.setValue(null);
            this.formRequest.controls.crew.controls.second_in_command.controls.full_name.setValue(null);
          }
        }
      } else {
        this.formRequest.controls.crew.controls.second_in_command.controls.pilot_id.setValue(null);
        this.formRequest.controls.crew.controls.second_in_command.controls.full_name.setValue(null);
      }
    });

    this.formRequest.get('date_time_eobt.date_zulu').valueChanges.subscribe((xValue: any) => {
      if (xValue && xValue != null && xValue instanceof Date) {
        const tempDay = `${xValue.getUTCDate()}`.padStart(2, "0");
        const tempMonth = `${xValue.getUTCMonth() + 1}`.padStart(2, "0");
        const tempYear = `${xValue.getUTCFullYear()}`;
        const tempDate = `${tempDay}/${tempMonth}/${tempYear}`;

        this.formRequest.controls.date_time_eobt.controls.date_zulu.setValue(tempDate.trim(), { emitEvent: false });
        this.formRequest.controls.filters.controls.meteorology.controls.initial_date_zulu.setValue(tempDate.trim(), { emitEvent: false });
      } else if (xValue == null) {
        this.formRequest.controls.date_time_eobt.controls.date_zulu.setValue(null, { emitEvent: false });
        this.formRequest.controls.filters.controls.meteorology.controls.initial_date_zulu.setValue(null, { emitEvent: false });
      } else if (xValue.length == 10) {
        this.formRequest.controls.date_time_eobt.controls.date_zulu.setValue(xValue, { emitEvent: false, });
        this.formRequest.controls.filters.controls.meteorology.controls.initial_date_zulu.setValue(xValue, { emitEvent: false });
      }

      this._mergeDataTimeEobtUtc();
      this._calculatingLandingTime();
    });

    this.formRequest.get('date_time_eobt.time_zulu').valueChanges.subscribe((xValue: any) => {
      if (xValue && xValue.length == 5) {
        this.formRequest.controls.filters.controls.meteorology.controls.initial_time_zulu.setValue(xValue);
      }

      this._mergeDataTimeEobtUtc();
      this._calculatingLandingTime();
    });

    this.formRequest.get('date_time_landing.date_zulu').valueChanges.subscribe((xValue: any) => {
      if (xValue && xValue != null && xValue instanceof Date) {
        const tempDay = `${xValue.getUTCDate()}`.padStart(2, "0");
        const tempMonth = `${xValue.getUTCMonth() + 1}`.padStart(2, "0");
        const tempYear = `${xValue.getUTCFullYear()}`;
        const tempDate = `${tempDay}/${tempMonth}/${tempYear}`;

        // Como já gerou o erro, é preciso zerar o valor.
        this.formRequest.controls.date_time_landing.controls.date_zulu.setValue(tempDate.trim(), { emitEvent: false });
        this.formRequest.controls.filters.controls.meteorology.controls.end_date_zulu.setValue(tempDate.trim(), { emitEvent: false });
      } else if (xValue == null) {
        this.formRequest.controls.date_time_landing.controls.date_zulu.setValue(null, { emitEvent: false });
        this.formRequest.controls.filters.controls.meteorology.controls.end_date_zulu.setValue(null, { emitEvent: false });
      } else if (xValue.length == 10) {
        this.formRequest.controls.date_time_landing.controls.date_zulu.setValue(xValue, { emitEvent: false, });
        this.formRequest.controls.filters.controls.meteorology.controls.end_date_zulu.setValue(xValue, { emitEvent: false });
      }

      this._mergeDataTimeLandingUtc();
    });

    this.formRequest.get('date_time_landing.time_zulu').valueChanges.subscribe((xValue: any) => {
      if (xValue && xValue.length == 5) {
        this.formRequest.controls.filters.controls.meteorology.controls.end_time_zulu.setValue(xValue);
      }

      this._mergeDataTimeLandingUtc();
    });

    this.formRequest.get('eet').valueChanges.subscribe((xValue: any) => {
      if (xValue && xValue != null) {
        this._calculatingLandingTime();
      }
    });
  }
  //#endregion

  //#region "|--- INIT FORM METHODS ---|"
  /**
   * @status OK
   * @type METHOD
   * @version 1.0.0 (GASPAR - 11/06/2024)
   * - Versão inicial.
   * 
   * @description 
   * - Inicializa o FORM que trata a Requisição de Voo.
   */
  private _initFormRequest(): void {
    this.formRequest = initFormRequest();
  }

  /**
   * @status OK
   * @type METHOD
   * @version 1.0.0 (GASPAR - 11/06/2024)
   * - Versão inicial.
   * 
   * @description 
   * - Inicializa o FORM de Pesquisa para Cadastro de Aeródromos ZZZZ.
   */
  private _initFormFilterSearchLocation() {
    this.formFilterSearchLocation = new FormGroup({
      country_acronym: new FormControl<string>('BRA'),
      state_acronym: new UntypedFormControl(null),
      city_name: new UntypedFormControl(null),
      coordinate: new UntypedFormControl(null),
    });
  }
  //#endregion

  //#region "|--- CALLBACK METHODS ---|"
  //#endregion

  //#region "|--- PRIVATE METHODS ---|"
  /**
   * @status OK
   * @type METHOD
   * @version 1.0.0 (GASPAR - 11/06/2024)
   * - Versão inicial.
   * 
   * @description 
   * - Verifica se a chamada a página FORM é para gerar um NOVO Documento ou para EDITAR um Documento já existente. 
   */
  private async _checkEditMode(): Promise<void> {
    try {
      // Verifica se na URL, mapeada em rotas, possui um parâmetro que neste caso: ID da Requisição do Plano de Voo.
      await this.route.params.subscribe(async (xParams) => {
        const tmpCurrentID = xParams['id'];

        if (tmpCurrentID) { // Se existe um ID, por exemplo: flight-request/form-ls/:id, indica que é para editar algum registro. Deve-se recuperar os dados...                  
          this.showDialogWaitingLoadingData = true;
          this.currentPageTitle = TITLE_UPDATE;

          Promise.all([(
            await this._recoverFlightRequestForm(tmpCurrentID)
          )])

          // É melhor dar um tempo entre recuperar a Requisição de Plano de Voo e os Dados do Plano de Voo.
          setTimeout(async () => {
            if (this.formRequest.controls.fk_flight_plan_data_id.value != null && this.formRequest.controls.fk_flight_plan_data_id.value != "") {
              await this._recoverFlightPlanData(this.formRequest.controls.fk_flight_plan_data_id.value);
            }

            this.showDialogWaitingLoadingData = false;

            this._showToast({
              severity: 'success',
              summary: 'Formulário Requisição de Voo',
              detail: `A Requisição de Voo foi recuperada com sucesso.`
            });
          }, 5000);
        } else {
          this.formRequest.controls.flight_request_id.setValue(null);
          this.currentPageTitle = TITLE_NEW;
        }
      });
    } catch (xException) {
      if (this.settingConfig.SHOW_LOG_EXCEPTION) {
        console.log("Exceção - Ao verificar a chamada ao formulário da Requisição do Voo: ", xException);
      }

      this.showDialogWaitingLoadingData = false;

      this._showToast({
        severity: 'error',
        summary: 'Formulário Requisição de Voo',
        detail: `Houve uma exceção na verificação da chamada ao formulário da Requisição do Voo.`
      });
    }
  }

  /**
   * @status OK
   * @type METHOD
   * @version 1.0.0 (GASPAR - 11/06/2024)
   * - Versão inicial.
   * 
   * @description
   * - Salva a Requisição de Voo no ssitema.
   */
  private async _saveRequest() {
    //#region "|--- PRÉ-PROCESSAMENTO ---|"
    // A FIM DE EVITAR ERRO, FAÇO O MERGE DAS DATAS PARA OS CAMPOS OCULTOS ANTES DE SALVAR A REQUISIÇÃO.
    this._mergeDataTimeEobtUtc();
    this._mergeDataTimeLandingUtc();
    this._calculatingLandingTime();

    // ATUALIZAR A LISTA DE AERÓDROMOS.
    this._settingAerodromeList();
    //#endregion

    // VERIFICA SE O FORM FOI PREENCHIDO CORRETAMENTE.
    if (this.formRequest.invalid || this.formTempAerodrome.invalid) {
      // Recupera todos os Erros do Form, com base na Validação durante a criação do FORM.
      const errosFormRequest = LocalMethodsHandlerClass.getFormFieldsErrors(this.formRequest);
      const errosFormTempAerodrome = LocalMethodsHandlerClass.getFormFieldsErrors(this.formTempAerodrome);
      const fieldsErros: any = [];

      let tmpData = '- campos não identificados.';

      // NOMEANDO OS CAMPOS
      errosFormRequest.forEach((xItem: any) => {
        fieldsErros.push(this._getFieldName(xItem.controlName));
      });

      errosFormTempAerodrome.forEach((xItem: any) => {
        fieldsErros.push(this._getFieldName(xItem.controlName));
      });

      // FORMATANDO OS NOMES DOS CAMPOS A SEREM MOSTRADOS.
      if (Array.isArray(fieldsErros)) {
        tmpData = fieldsErros.join('\n - ');
      }

      this._showToast({
        severity: 'error',
        summary: 'Salvar Requisição de Voo',
        detail: `Os campos não foram validados: \n\n- ${tmpData}`
      });
    } else {
      this.formRequest.controls.filters.controls.notam.setValue(this.formRequest.controls.aerodromes_list.setValue.value);

      this.showDialogWaitingSaveFlightRequest = true;

      // TOMADA ESTA AÇÃO PARA INDICAR AO TRIGGER DO COMPONENTE QUE HOUVE UM MUDANÇA DE CONTEÚDO.
      this.currentAction = null;
      this.currentAction = "SAVE";

      // Tem que usar".getRawValue()", pois como tem controle desabilitado pode ser que os dados não sejam capturados.
      await this.flightPlanApisService.saveFlightRequest(this.formRequest.getRawValue()).subscribe({
        next: (xResponse: IPatternResponseFromAPI) => {
          if (xResponse.status_code == HTTP_STATUS.OK || xResponse.status_code == HTTP_STATUS.CREATED) {
            const tempData = xResponse.data_info[0];

            this.formRequest.controls.flight_request_id.setValue(tempData.flight_request_id);
            this.formRequest.controls.flight_request_code.patchValue(tempData.flight_request_code);

            // STATUS DA REQUISIÇÃO DE VOO.
            this.formRequest.controls.current_status.controls.request_flight.patchValue(tempData.current_status.request_flight);
            // STATUS DA REQUISIÇÃO DA DOCUMENTAÇÃO.
            this.formRequest.controls.current_status.controls.document.patchValue(tempData.current_status.document);
            // STATUS DO PLANO DE VOO JUNTO AO ÓRGÃO DE CONTROLE.
            this.formRequest.controls.current_status.controls.official.patchValue(tempData.current_status.official);
            // HISTÓRICO DE AÇÕES.
            this.formRequest.controls.request_historic.value = tempData.request_historic;

            setTimeout(() => {
              this.showDialogWaitingSaveFlightRequest = false;

              this._showToast({
                severity: 'success',
                summary: 'Salvar Requisição de Voo',
                detail: `A Requisição de Voo foi criada com sucesso.`
              });
            }, 3000)
          } else {
            this.showDialogWaitingSaveFlightRequest = false;

            this._showToast({
              severity: 'error',
              summary: 'Salvar Requisição de Voo',
              detail: `Houve uma Erro ao Salvar a Requisição do Voo.`
            });
          }
        },
        error: (xException: any) => {
          if (this.settingConfig.SHOW_LOG_EXCEPTION) {
            console.log("Exceção - Salvar Requisição: ", xException);
          }

          this.showDialogWaitingSaveFlightRequest = false;

          this._showToast({
            severity: 'error',
            summary: 'Salvar Requisição de Voo',
            detail: `Houve uma Exceção ao Salvar a Requisição do Voo.`
          });
        },
      });
    }
  }

  /**
   * @status OK
   * @type METHOD
   * @version 1.0.0 (GASPAR - 11/06/2024)
   * - Versão inicial.
   * 
   * @description
   * - Iniciando o Atendimento do Serviço.
   */
  private async _startRequestService() {
    //#region "|--- PRE-PROCESSAMENTO ---|"
    // A FIM DE EVITAR ERRO, FAÇO O MERGE DAS DATAS PARA OS CAMPOS OCULTOS ANTES DE INICIAR O ATENDIMENTO.
    this._mergeDataTimeEobtUtc();
    this._mergeDataTimeLandingUtc();
    this._calculatingLandingTime();

    // ATUALIZAR A LISTA DE AERÓDROMOS.
    this._settingAerodromeList();
    //#endregion

    // VERIFICA SE O FORM FOI PREENCHIDO CORRETAMENTE.
    if (this.formRequest.invalid || this.formTempAerodrome.invalid) {
      // Recupera todos os Erros do Form, com base na Validação durante a criação do FORM.
      const errosFormRequest = LocalMethodsHandlerClass.getFormFieldsErrors(this.formRequest);
      const errosFormTempAerodrome = LocalMethodsHandlerClass.getFormFieldsErrors(this.formTempAerodrome);
      const fieldsErros: any = [];

      let tmpData = '- campos não identificados.';

      // NOMEANDO OS CAMPOS
      errosFormRequest.forEach((xItem: any) => {
        fieldsErros.push(this._getFieldName(xItem.controlName));
      });

      errosFormTempAerodrome.forEach((xItem: any) => {
        fieldsErros.push(this._getFieldName(xItem.controlName));
      });

      // FORMATANDO OS NOMES DOS CAMPOS A SEREM MOSTRADOS.
      if (Array.isArray(fieldsErros)) {
        tmpData = fieldsErros.join('\n - ');
      }

      this._showToast({
        severity: 'error',
        summary: 'Iniciar Atendimento',
        detail: `O(s) campo(s) não foram validados: \n\n- ${tmpData}`
      });
    } else {
      this.showDialogWaitingGettingData = true;

      // Tem que usar".getRawValue()", pois como tem controle desabilitado pode ser que os dados não sejam capturados.
      const objToStarService = {
        request_flight: this.formRequest.getRawValue(),
        top_risk_form: this.formTopRisk.getRawValue()
      }

      await this.flightPlanApisService.startRequestService(objToStarService).subscribe({
        next: async (xResponse: IPatternResponseFromAPI) => {
          if (xResponse.status_code == HTTP_STATUS.OK || xResponse.status_code == HTTP_STATUS.CREATED) {
            const tempData = xResponse.data_info[0];

            this.formRequest.controls.fk_flight_plan_data_id.setValue(tempData.fk_flight_plan_data_id);

            // STATUS DA REQUISIÇÃO DE VOO.
            this.formRequest.controls.current_status.controls.request_flight.patchValue(tempData.current_status.request_flight);
            // STATUS DA REQUISIÇÃO DA DOCUMENTAÇÃO.
            this.formRequest.controls.current_status.controls.document.patchValue(tempData.current_status.document);
            // STATUS DO PLANO DE VOO JUNTO AO ÓRGÃO DE CONTROLE.
            this.formRequest.controls.current_status.controls.official.patchValue(tempData.current_status.official);
            // HISTÓRICO DE AÇÕES.
            this.formRequest.controls.request_historic.value = tempData.request_historic;

            // É Preciso Recuperar os Dados do plano de Voo para mostrar no View
            await this._recoverFlightPlanData(tempData.fk_flight_plan_data_id);

            // ! TEM QUE GERAR O PDF

            setTimeout(() => {
              this._showToast({
                severity: 'success',
                summary: 'Iniciar Atendimento',
                detail: `O Inicio do Atendimento da Requisição foi iniciado com sucesso.`
              });

              this.showDialogWaitingGettingData = false;
            }, 10000);
          } else {
            this.showDialogWaitingGettingData = false;

            this._showToast({
              severity: 'error',
              summary: 'Iniciar Atendimento',
              detail: `Houve um Erro ao inicio do Atendimento da Requisição.`
            });
          }
        },
        error: (xException: any) => {
          if (this.settingConfig.SHOW_LOG_EXCEPTION) {
            console.log("Exceção - Inicio do Atendimento da Requisição: ", xException);
          }

          this.showDialogWaitingGettingData = false;

          this._showToast({
            severity: 'error',
            summary: 'Iniciar Atendimento',
            detail: `Houve uma Exceção ao inicio do Atendimento da Requisição.`
          });
        }
      });
    }
  }

  /**
   * @status OK
   * @type METHOD
   * @version 1.0.0 (GASPAR - 13/06/2024)
   * - Versão inicial.
   * 
   * @description
   * - Atualiza as informações da Requisição de voo.
   */
  private async _updateRequest() {
    //#region "|--- PRE-PROCESSAMENTO ---|"
    // A FIM DE EVITAR ERRO, FAÇO O MERGE DAS DATAS PARA OS CAMPOS OCULTOS ANTES DE ATUALIZAR A REQUISIÇÃO.
    this._mergeDataTimeEobtUtc();
    this._mergeDataTimeLandingUtc();
    this._calculatingLandingTime();

    // ATUALIZAR A LISTA DE AERÓDROMOS.
    this._settingAerodromeList();
    //#endregion

    if (this.formRequest.invalid || this.formTempAerodrome.invalid) {
      // Recupera todos os Erros do Form, com base na Validação durante a criação do FORM.
      const errosFormRequest = LocalMethodsHandlerClass.getFormFieldsErrors(this.formRequest);
      const errosFormTempAerodrome = LocalMethodsHandlerClass.getFormFieldsErrors(this.formTempAerodrome);
      const fieldsErros: any = [];

      let tmpData = '- campos não identificados.';

      // NOMEANDO OS CAMPOS
      errosFormRequest.forEach((xItem: any) => {
        fieldsErros.push(this._getFieldName(xItem.controlName));
      });

      errosFormTempAerodrome.forEach((xItem: any) => {
        fieldsErros.push(this._getFieldName(xItem.controlName));
      });

      // FORMATANDO OS NOMES DOS CAMPOS A SEREM MOSTRADOS.
      if (Array.isArray(fieldsErros)) {
        tmpData = fieldsErros.join('\n - ');
      }

      this._showToast({
        severity: 'success',
        summary: 'Atualização da Requisição de Voo',
        detail: `O(s) campo(s) não foram validados: \n\n- ${tmpData}`
      });
    } else {
      this.showDialogWaitingUpdateFlightRequest = true;

      // Tem que usar".getRawValue()", pois como tem controle desabilitado pode ser que os dados não sejam capturados.
      await this.flightPlanApisService.updateFlightRequest(this.formRequest.getRawValue(), this.formRequest.controls.flight_request_id.value).subscribe({
        next: (xResponse: IPatternResponseFromAPI) => {
          if (xResponse.status_code == HTTP_STATUS.OK || xResponse.status_code == HTTP_STATUS.CREATED) {
            const tempData = xResponse.data_info[0];
            this.currentAction = null;
            this.currentAction = "UPDATE";

            // STATUS DA REQUISIÇÃO DE VOO.
            //this.formRequest.controls.current_status.controls.request_flight.patchValue(tempData.current_status.request_flight);
            // STATUS DA REQUISIÇÃO DA DOCUMENTAÇÃO.
            //this.formRequest.controls.current_status.controls.document.patchValue(tempData.current_status.document);
            // STATUS DO PLANO DE VOO JUNTO AO ÓRGÃO DE CONTROLE.
            //this.formRequest.controls.current_status.controls.official.patchValue(tempData.current_status.official);
            // HISTÓRICO DE AÇÕES.
            //this.formRequest.controls.request_historic.value = tempData.request_historic;

            // ! TEM QUE ATUALIZAR OS DADOS DO PLANO DE VOO

            // ! TEM QUE RECUPERAR OS DADOS DO PLANO DE VOO

            // ! TEM QUE GERAR O PDF

            setTimeout(() => {
              this._showToast({
                severity: 'success',
                summary: 'Atualização da Requisição de Voo',
                detail: 'A Requisição de Voo foi atualizada com Sucesso.',
              });

              this.showDialogWaitingUpdateFlightRequest = false;
            }, 10000)
          } else {

            this.showDialogWaitingUpdateFlightRequest = false;

            this._showToast({
              severity: 'error',
              summary: 'Atualização da Requisição de Voo',
              detail: `Houve um Erro ao fazer a atualização da Requisição de Voo.`
            });
          }
        },
        error: (xException: any) => {
          if (this.settingConfig.SHOW_LOG_EXCEPTION) {
            console.log("Exceção - Atualizar Atendimento da Requisição: ", xException);
          }

          this.showDialogWaitingUpdateFlightRequest = false;

          this._showToast({
            severity: 'error',
            summary: 'Atualização da Requisição de Voo',
            detail: `Houve uma Exceção ao fazer a atualização da Requisição de Voo.`
          });
        }
      });
    }
  }

  /**
   * @status OK
   * @type METHOD
   * @version 1.0.0 (GASPAR - 11/06/2024)
   * - Versão inicial.
   * 
   * @description 
   * - Recupera os dado da REquisição de Voo e atribui os valores ao Formulário.
   * - Insere os Valores direto no FORM FLIGHT REQUEST.
   * 
   * @param xFlightPlanRequestID 
   * - ID do da Requisição do Plano de Voo que se deseja recuperar.
   * @returns 
   * - Retorna 200 quando sucesso;
   * - Retorna null quando dá um erro;
   */
  private async _recoverFlightRequestForm(xFlightPlanRequestID: string): Promise<any> {
    return new Promise(async (resolve, reject) => {
      this.flightPlanApisService.getFlightRequestById(xFlightPlanRequestID).subscribe({
        next: (xResponse: IPatternResponseFromAPI) => {
          if (xResponse.status_code == HTTP_STATUS.OK) {
            const dataInfo = xResponse.data_info.data[0];

            // ! TEM QUE RETIRAR O ENVIO DESTES PARÂMETROS NO BACKGROUND...
            delete xResponse.data_info.data[0]['id'];
            delete xResponse.data_info.data[0]['_id'];

            // INSERÇÃO DO DADOS DOS AERÓDROMO DEPARTURE, DESTINATION, ALT_1 e ALT_2
            this.arrayGoalAerodrome.forEach((xGoal: any) => {
              this.formTempAerodrome.controls[`${xGoal}`].setValue(dataInfo.aerodromes[`${xGoal}`].data.icao_code, { emitEvent: false },);
              this.formRequest.controls.aerodromes.controls[`${xGoal}`].setValue(dataInfo.aerodromes[`${xGoal}`], { emitEvent: false, });
            });

            if (dataInfo.aerodromes.alt_extras.length >= 0) {
              for (let i = 0; i < dataInfo.aerodromes.alt_extras.length; i++) {
                const objFormBuilder = new FormBuilder();

                this.formRequest.controls.aerodromes.controls.alt_extras.controls.push(
                  objFormBuilder.group({
                    data: objFormBuilder.group({
                      aerodrome_basic_data_id: dataInfo.aerodromes.alt_extras[i].data.aerodrome_basic_data_id,
                      fk_aerodrome_complete_data_id: dataInfo.aerodromes.alt_extras[i].data.fk_aerodrome_complete_data_id,
                      iata_code: dataInfo.aerodromes.alt_extras[i].data.iata_code,
                      icao_code: dataInfo.aerodromes.alt_extras[i].data.icao_code,
                      name: dataInfo.aerodromes.alt_extras[i].data.name,
                      aerodrome_type: dataInfo.aerodromes.alt_extras[i].data.aerodrome_type,
                      coordinate_label: [dataInfo.aerodromes.alt_extras[i].data.coordinate_label,],
                      coordinate_dd: [dataInfo.aerodromes.alt_extras[i].data.coordinate_dd,],
                      coordinate_gms: [dataInfo.aerodromes.alt_extras[i].data.coordinate_gms,],
                      coordinate_plan: dataInfo.aerodromes.alt_extras[i].data.coordinate_plan,
                      coordinate_geo: dataInfo.aerodromes.alt_extras[i].data.coordinate_geo,
                      place: dataInfo.aerodromes.alt_extras[i].data.play_circle_outline,
                    }),
                    timestamp: dataInfo.aerodromes.alt_extras[i].font,
                    font: dataInfo.aerodromes.alt_extras[i].timestamp,
                  })
                );

                this.formTempAerodrome.controls.alt_extras.controls.push(objFormBuilder.control(dataInfo.aerodromes.alt_extras[i].data.icao_code));

                // ESTA LINHA SERVE APENAS PARA SINALIZAR "FORÇAR" UMA MUDANÇA DE CONTEÚDO DESTE PARÂMETRO. ISTO VAI ATIVAR O TRIGGER DE MUDANÇA DO COMPONENTE QUE RECEBE ESTE FORM.
                this.formTempAerodrome = this.formTempAerodrome;
              }
            }

            // PODE SER QUE O MAPA AINDA NÃO MONTOU... É PRECISO ESPERAR UM POUCO.
            setTimeout(() => {
              this.objectToDrawOnMaps = {
                marker: dataInfo.map.marker,
                draw: dataInfo.map.draw,
              };

              this._drawRouteToMap();
            }, 1000);

            // OUTROS DADOS
            this.formRequest.controls.flight_request_id.setValue(dataInfo.flight_request_id);
            this.formRequest.controls.fk_flight_plan_data_id.setValue(dataInfo.fk_flight_plan_data_id);
            this.formRequest.controls.flight_request_code.setValue(dataInfo.flight_request_code,);
            this.formRequest.controls.flight_plan_official_confirmation.setValue(dataInfo.flight_plan_official_confirmation);
            this.formRequest.controls.aerodromes_list.setValue(dataInfo.aerodromes_list, { emitEvent: false });
            this.formRequest.controls.aircraft_mark.setValue(dataInfo.aircraft_mark, { emitEvent: false });
            this.formRequest.controls.crew.setValue(dataInfo.crew);
            this.formRequest.controls.cruise_speed.setValue(dataInfo.cruise_speed);
            this.formRequest.controls.date_time_eobt.setValue(dataInfo.date_time_eobt);
            this.formRequest.controls.date_time_landing.setValue(dataInfo.date_time_landing);
            this.formRequest.controls.eet.setValue(dataInfo.eet);
            this.formRequest.controls.flight_level.setValue(dataInfo.flight_level);
            this.formRequest.controls.operator.setValue(dataInfo.operator);
            this.formRequest.controls.flight_rule.setValue(dataInfo.flight_rule);
            this.formRequest.controls.route.patchValue(dataInfo.route); // TEM ARRAY, ENTÃO USAR PATCH_VALUE
            this.formRequest.controls.map.controls.marker.value = dataInfo.map.marker;
            this.formRequest.controls.map.controls.draw.value = dataInfo.map.draw;
            this.formRequest.controls.filters.controls.meteorology.patchValue(dataInfo.filters.meteorology); // TEM ARRAY, ENTÃO USAR PATCH_VALUE
            this.formRequest.controls.filters.controls.obstacles.patchValue(dataInfo.filters.obstacles); // TEM ARRAY, ENTÃO USAR PATCH_VALUE                                                                              
            this.formRequest.controls.documents_files.value = dataInfo.documents_files;
            this.formRequest.controls.current_status.controls.request_flight.patchValue(dataInfo.current_status.request_flight);
            this.formRequest.controls.current_status.controls.document.patchValue(dataInfo.current_status.document);
            this.formRequest.controls.current_status.controls.official.patchValue(dataInfo.current_status.official);
            this.formRequest.controls.request_historic.value = dataInfo.request_historic;

            // TOMADA ESTA AÇÃO PARA INDICAR AO TRIGGER DO COMPONENTE QUE HOUVE UM MUDANÇA DE CONTEÚDO.
            this.currentAction = null;
            this.currentAction = "RELOAD";

            this.messageService.add({
              severity: 'success',
              summary: 'Recuperar Requisição de Voo',
              detail: `Recuperar Requisição de Voo recuperado com Sucesso.`,
            });

            resolve(HTTP_STATUS.OK)
          } else {
            this.messageService.add({
              severity: 'error',
              summary: 'Recuperar Requisição de Voo',
              detail: `Erro ao recuperar Requisição de Voo.`,
            });

            reject(null);
          }

          // Lembre-se: está sendo executado um OBSERVER, ou seja, ele é assíncrono e é difícil esperar o seu final para executar outro método.
          // Para que os aeródromos sejam plotados no MAPA é preciso "forçar" uma atualização do conteúdo para que seja pego pelo componente de mapa, via "INPUT PROPERTY SETTER"
          // Forçar no final da execução do OBSERVER
          //this._forceReloadArrayAerodromesToMarkMapValue();
        },
        error: (xException: any) => {
          let messageToReturn = "";

          if (this.settingConfig.SHOW_LOG_EXCEPTION) {
            console.log("Exceção - Recuperar Requisição de Voo: ", xException);
          }

          if (xException.status == HTTP_STATUS.NOT_FOUND && xException.error && xException.error.status_code == HTTP_STATUS.NOT_FOUND) {
            messageToReturn = 'A Requisição de Voo não foi encontrada.';
          } else {
            messageToReturn = 'Exceção - Recuperar Requisição de Voo.';
          }

          this._showToast({
            severity: 'error',
            summary: 'Recuperar Requisição de Voo',
            detail: messageToReturn
          });

          reject(null);
        }
      });
    });
  }

  /**
   * @status OK
   * @type METHOD
   * @version 1.0.0 (GASPAR - 11/06/2024)
   * - Versão inicial.
   * 
   * @description 
   * - Recupera os dado da REquisição de Voo e atribui os valores ao Formulário.
   * 
   * @param xFlightPlanRequestID 
   * - ID do da Requisição do Plano de Voo que se deseja recuperar.
   * - Insere o valor retornado na Variável que armazena o valores do Plano de Voo Atual.
   * 
   * @returns 
   * - Retorna 200 quando sucesso;
   * - Retorna null quando dá um erro;
   */
  private async _recoverFlightPlanData(xFlightPlanDataID: string): Promise<any> {
    return new Promise(async (resolve, reject) => {
      await this.flightPlanApisService.getFlightPlanDataById(xFlightPlanDataID).subscribe({
        next: (xResponse: IPatternResponseFromAPI) => {
          if (xResponse.status_code == HTTP_STATUS.OK) {
            this.currentFlightPlanData = null; // TEM QUE MUDAR, PARA QUE O GATILHO PEGUE ESTA MUDANÇA E EDITE A DATA E HORA DE GERAÇÃO DOS DADOS.
            this.currentFlightPlanData = xResponse.data_info.data[0];

            this._showToast({
              severity: 'success',
              summary: 'Recuperar Dados do Plano de Voo',
              detail: `Sucesso ao recuperar os Dados do Plano de Voo.`
            });

            resolve(HTTP_STATUS.OK);
          } else {
            this._showToast({
              severity: 'error',
              summary: 'Recuperar Dados do Plano de Voo',
              detail: `Houve um erro ao recuperar os Dados do Plano de Voo.`
            });

            reject(null);
          }
        },
        error: (Exception: any) => {
          let messageToReturn = "";

          if (this.settingConfig.SHOW_LOG_EXCEPTION) {
            console.log("Exceção - Ao Recuperar os Dados do Plano de Voo: ", Exception);
          }

          if (Exception.status == HTTP_STATUS.NOT_FOUND && Exception.error && Exception.error.status_code == HTTP_STATUS.NOT_FOUND) {
            messageToReturn = `Não foi encontrado os Dados do Plano de Voo solicitado.`;
          } else {
            messageToReturn = `Houve uma Exceção ao Recuperar os Dados do Plano de Voo.`;
          }

          this._showToast({
            severity: 'error',
            summary: 'Atendimento da Requisição',
            detail: messageToReturn
          });

          reject(null);
        }
      });
    });
  }

  /**
   * @status OK
   * @type METHOD
   * @version 1.0.0 (GASPAR - 11/06/2024)
   * - Versão inicial.
   * 
   * @description 
   * - Responsável por mostra a mensagem em um TOAST.
   * 
   * @param xObjMessage 
   * - Dados para mostra o TOAST na tela.
   */
  private async _showToast(xObjMessage: IToastParameters) {
    this.objToastMessage = null; // Tem que forçar a mudança para que o componente registre a alteração do valor da propriedade.
    this.objToastMessage = xObjMessage;
  }

  /**
   * @status OK
   * @type METHOD
   * @version 1.0.0 (GASPAR - 11/06/2024).
   * - Versão inicial.
   * 
   * @description 
   * - Método que faz o merge da Data do EOBT e Hora do EOBT em um campo normalizado.
   * - O valor é posto no FORM FLIGHT REQUEST.
   * - Lembre-se que a Data e o Horário devem ser referentes ao horário UTC.
   */
  private _mergeDataTimeEobtUtc() {
    const dateZuluRegEx = new RegExp('^(3[01]|[12][0-9]|0[1-9])/(1[0-2]|0[1-9])/[0-9]{4}$');
    const timeZuluRegEx = new RegExp('^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$');
    const valueDateZulu = this.formRequest.controls.date_time_eobt.controls.date_zulu.value;
    const valueTimeZulu = this.formRequest.controls.date_time_eobt.controls.time_zulu.value;

    // Verifica se a Data e o Horário tem estrutura válida.
    if (dateZuluRegEx.test(valueDateZulu) && timeZuluRegEx.test(valueTimeZulu)) {
      const tempDate = valueDateZulu.substring(0, 2).padStart(2, "0");
      const tempMonth = valueDateZulu.substring(3, 5).padStart(2, "0");
      const tempYear = valueDateZulu.substring(6, 10);
      const tempHour = valueTimeZulu.substring(0, 2).padStart(2, "0");
      const tempMinute = valueTimeZulu.substring(3, 5).padStart(2, "0");
      const mergeDateTime = `${tempYear}-${tempMonth}-${tempDate}T${tempHour}:${tempMinute}:00.000Z`;

      this.formRequest.controls.date_time_eobt.controls.date_time_zulu.setValue(mergeDateTime);
    } else {
      this.formRequest.controls.date_time_eobt.controls.date_time_zulu.setValue(null);
    }
  }

  /**
   * @status OK
   * @type METHOD
   * @version 1.0.0 (GASPAR - 11/06/2024).
   * - Versão inicial.
   * 
   * @description 
   * - Método que faz o merge da Data do Landing e Hora do Landing em um campo normalizado.
   * - O valor é posto no FORM do Request.
   * - Lembre-se que a Data e o Horário devem ser referentes ao horário UTC.
   */
  private _mergeDataTimeLandingUtc() {
    const dateZuluRegEx = new RegExp('^(3[01]|[12][0-9]|0[1-9])/(1[0-2]|0[1-9])/[0-9]{4}$');
    const timeZuluRegEx = new RegExp('^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$');
    const valueDateZulu = this.formRequest.controls.date_time_landing.controls.date_zulu.value;
    const valueTimeZulu = this.formRequest.controls.date_time_landing.controls.time_zulu.value;

    if (dateZuluRegEx.test(valueDateZulu) && timeZuluRegEx.test(valueTimeZulu)) {
      const tempDate = valueDateZulu.substring(0, 2).padStart(2, "0");
      const tempMonth = valueDateZulu.substring(3, 5).padStart(2, "0");
      const tempYear = valueDateZulu.substring(6, 10);
      const tempHour = valueTimeZulu.substring(0, 2).padStart(2, "0");
      const tempMinute = valueTimeZulu.substring(3, 5).padStart(2, "0");
      const mergeDateTime = `${tempYear}-${tempMonth}-${tempDate}T${tempHour}:${tempMinute}:00.000Z`;

      this.formRequest.controls.date_time_landing.controls.date_time_zulu.setValue(mergeDateTime);
    } else {
      this.formRequest.controls.date_time_landing.controls.date_time_zulu.setValue(null);
    }
  }

  /**
   * @status OK
   * @type METHOD
   * @version 1.0.0 (GASPAR - 11/06/2024).
   * - Versão inicial.
   * 
   * @description 
   * - Monta uma lista dos Aeródromos que foram solicitados par ao Plano de Voo.
   */
  private _settingAerodromeList() {
    const tempAerodromesList: any = [];
    const tempAerodromesValue = this.formRequest.controls.aerodromes.getRawValue(); // PEGAR OS DADOS CRUS, CASO CONTRÁRIO NÃO RECUPERA OS DADOS DOS AERÓDROMOS EXTRAS....

    if (tempAerodromesValue.departure.data.icao_code && tempAerodromesValue.departure.data.icao_code != "") {
      tempAerodromesList.push({
        "aerodrome_basic_data_id": tempAerodromesValue.departure.data.aerodrome_basic_data_id,
        "icao_code": tempAerodromesValue.departure.data.icao_code,
        "name": tempAerodromesValue.departure.data.name,
        "coordinate_dd": tempAerodromesValue.departure.data.coordinate_dd,
        "aerodrome_goal": "departure",
        "country": tempAerodromesValue.departure.data.place.country_code,
        "label": tempAerodromesValue.departure.data.icao_code
      });
    }

    if (tempAerodromesValue.destination.data.icao_code && tempAerodromesValue.destination.data.icao_code != "") {
      tempAerodromesList.push({
        "aerodrome_basic_data_id": tempAerodromesValue.destination.data.aerodrome_basic_data_id,
        "icao_code": tempAerodromesValue.destination.data.icao_code,
        "name": tempAerodromesValue.destination.data.name,
        "coordinate_dd": tempAerodromesValue.destination.data.coordinate_dd,
        "aerodrome_goal": "destination",
        "country": tempAerodromesValue.destination.data.place.country_code,
        "label": tempAerodromesValue.destination.data.icao_code
      });
    }

    if (tempAerodromesValue.alt_1.data.icao_code && tempAerodromesValue.alt_1.data.icao_code != "") {
      tempAerodromesList.push({
        "aerodrome_basic_data_id": tempAerodromesValue.alt_1.data.aerodrome_basic_data_id,
        "icao_code": tempAerodromesValue.alt_1.data.icao_code,
        "name": tempAerodromesValue.alt_1.data.name,
        "coordinate_dd": tempAerodromesValue.alt_1.data.coordinate_dd,
        "aerodrome_goal": "alt_1",
        "country": tempAerodromesValue.alt_1.data.place.country_code,
        "label": tempAerodromesValue.alt_1.data.icao_code
      });
    }

    if (tempAerodromesValue.alt_2.data.icao_code && tempAerodromesValue.alt_2.data.icao_code != "") {
      tempAerodromesList.push({
        "aerodrome_basic_data_id": tempAerodromesValue.alt_2.data.aerodrome_basic_data_id,
        "icao_code": tempAerodromesValue.alt_2.data.icao_code,
        "name": tempAerodromesValue.alt_2.data.name,
        "coordinate_dd": tempAerodromesValue.alt_2.data.coordinate_dd,
        "aerodrome_goal": "alt_2",
        "country": tempAerodromesValue.alt_2.data.place.country_code,
        "label": tempAerodromesValue.alt_2.data.icao_code
      });
    }

    if (tempAerodromesValue.alt_extras.length > 0) {
      tempAerodromesValue.alt_extras.forEach((xItemAltExt: any, xItem: number) => {
        tempAerodromesList.push({
          "aerodrome_basic_data_id": xItemAltExt.data.aerodrome_basic_data_id,
          "icao_code": xItemAltExt.data.icao_code,
          "name": xItemAltExt.data.name,
          "coordinate_dd": xItemAltExt.data.coordinate_dd,
          "aerodrome_goal": `alt_${xItem + 3}`,
          "country": xItemAltExt.data.place.country_code,
          "label": xItemAltExt.data.icao_code
        });
      });
    }

    this.formRequest.controls.aerodromes_list.setValue(tempAerodromesList);
  }

  /**
   * @status OK
   * @type METHOD
   * @version 1.0.0 (GASPAR - 11/06/2024).  
   * - Versão inicial.
   * 
   * @description 
   * - Faz o Calculo do Estimado de pouso com base na Data e Hora EOBT mais o tempo de voo EET.
   */
  private _calculatingLandingTime() {
    const dateZuluRegEx = new RegExp('^(3[01]|[12][0-9]|0[1-9])/(1[0-2]|0[1-9])/[0-9]{4}$');
    const timeZuluRegEx = new RegExp('^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$');
    const valueEobtDateZulu = this.formRequest.controls.date_time_eobt.controls.date_zulu.value;
    const valueEobtTimeZulu = this.formRequest.controls.date_time_eobt.controls.time_zulu.value;
    const valueEet = this.formRequest.controls.eet.value;

    // Faz as validações dos campos, para ter certeza que os dados estão íntegros para fazer o calculo.
    if (dateZuluRegEx.test(valueEobtDateZulu) && timeZuluRegEx.test(valueEobtTimeZulu) && timeZuluRegEx.test(valueEet)) {
      const tempDate = valueEobtDateZulu.substring(0, 2).padStart(2, "0");
      const tempMonth = valueEobtDateZulu.substring(3, 5).padStart(2, "0");
      const tempYear = valueEobtDateZulu.substring(6, 10);
      const tempHour = valueEobtTimeZulu.substring(0, 2).padStart(2, "0");
      const tempMinute = valueEobtTimeZulu.substring(3, 5).padStart(2, "0");
      const newDateTime = new Date(tempYear, tempMonth, tempDate, tempHour, tempMinute);
      const eetTimeParts = valueEet.split(':');
      const eetMinutes = Number(eetTimeParts[0]) * 60 + Number(eetTimeParts[1]); // Transforma a hora e os minutos, tudo em minutos..

      // ADICIONANDO OS MINUTOS.
      newDateTime.setMinutes(newDateTime.getMinutes() + eetMinutes);

      // NÃO PRECISA RECUPERAR OS DADOS NO FORMATO UTC,
      // POIS ESTOU SOMANDO HORAS - PODE UTILIZAR O HORÁRIO LOCAL - UMA VEZ
      // QUE EU PRECISO APENAS DOS VALORES ABSOLUTOS
      const tempNewDate = `${newDateTime.getDate()}`.padStart(2, "0");
      const tempNewMonth = `${newDateTime.getMonth()}`.padStart(2, "0");
      const tempNewYear = `${newDateTime.getFullYear()}`;
      const tempNewHour = `${newDateTime.getHours()}`.padStart(2, "0");
      const tempNewMinute = `${newDateTime.getMinutes()}`.padStart(2, "0");

      this.formRequest.controls.date_time_landing.controls.date_zulu.setValue(`${tempNewDate}/${tempNewMonth}/${tempNewYear}`);
      this.formRequest.controls.date_time_landing.controls.time_zulu.setValue(`${tempNewHour}:${tempNewMinute}`);
    }
  }

  /**
   * @status OK
   * @type METHOD
   * @version 1.0.0 (GASPAR - 13/06/2024)
   * - Versão inicial.
   * 
   * @description 
   * - Inicializa as variáveis do processo.
   */
  private _initVariables(): void {
    this.innerWidth = window.innerWidth;

    this.settingConfig = settingConfig;

    this.showDialogWaitingSaveFlightRequest = false;
    this.showDialogWaitingUpdateFlightRequest = false;
    this.showDialogWaitingLoadingData = false;
    this.showDialogWaitingGettingData = false;
    this.showDialogWaitingUpdatingFlightPlanData = false;
    this.showDialogWaitingPdfGenerating = false;
    this.showDialogWaitingGeneratingDocumentCard = false;
    this.showDialogWaitingProcess = false;
    this.showDialogManyAerodromeBasicData = false;
    this.showDialogSearchAerodromeCoord = false;
    this.showDialogSearchAerodromeIcao = false;
    this.showDialogRouteSave = false;
    this.showDialogRouteList = false;

    // ***** ARRAYS *****
    this.arrayChecklist = [];
    this.arrayMessages1 = [];
    this.arrayMessages2 = [];
    this.arrayGoalAerodrome = ['departure', 'destination', 'alt_1', 'alt_2'];

    // ***** OPTIONS DROPDOWN *****
    this.optionsMapStates = braStatesOptions;
    this.optionsFlightPlanFlightRules = flightPlanFlightRulesOptions;
    this.optionsAircraft = [];
    this.optionsPilots = [];
    this.optionsOperators = [];

    // ***** ???? *****
    this.objectToDrawOnMaps = {
      marker: [],
      draw: [],
    };

    this.currentTagAerodromeTarget = '';

    this.objAerodromeIcones = ICON_AERODROME;
    this.optionsMapCountry = countryOptions;
    this.isMapCopied = false;
    this.isIntentionMessageCopied = false;
    this.isMetarTafCopied = false;

    this.itemsNewFlightPlan = [
      {
        label: 'Voo de Retorno',
        icon: 'fa-solid fa-right-left',
        command: () => {
          //this.update();
        },
      },
      {
        label: 'Clonar Plano de Voo',
        icon: 'fa-solid fa-clone',
        command: () => {
          //this.delete();
        },
      }
    ];
  }

  private _recoverAircraft() { }

  private _recoverPilots() { }

  /**
   * @status OK
   * @type METHOD
   * @version 1.0.0 (GASPAR - 11/06/2024).  
   * - Versão inicial.
   * 
   * @description
   * - Retorna o nome do campo de um formulário, com base no id.
   * 
   * @param xFiledId 
   * - Id do Campo que determina o nome do campo.
   * 
   * @returns 
   * - Nome do campo desejado.
   */
  private _getFieldName(xFiledId: string) {
    switch (xFiledId) {
      case 'aircraft_mark':
        return 'ACFT Registro';
      case 'crew.first_in_command.normalized_text':
        return 'Piloto em Comando';
      case 'crew.second_in_command.normalized_text':
        return 'Segundo em Comando';
      case 'date_time_eobt.date_zulu':
        return 'Data EOBT (Z)';
      case 'date_time_eobt.time_zulu':
        return 'Hora EOBT (Z)';
      case 'date_time_eobt.date_time_zulu':
        return 'Data/Hora EOBT (Z) - INTERNO';
      case 'date_time_landing.date_zulu':
        return 'Data Est. Pouso (Z)';
      case 'date_time_landing.time_zulu':
        return 'Hora Est. Pouso';
      case 'date_time_landing.date_time_zulu':
        return 'Data/Hora Est. Pouso (Z) - INTERNO';
      case 'eet':
        return 'Total EET';
      default:
        return xFiledId;
    }
  }
  //#endregion

  //#region "|--- PUBLIC METHODS ---|"
  //#endregion


























  //#region "|--- PRIVATE METHODS ---|"

  // ! TEm que validar
  private async _renewFlightRequestData() {
    const tempFlightRequestId = this.formRequest.controls.flight_request_id.value;
    const tempFlightPlanDataId = this.formRequest.controls.fk_flight_plan_data_id.value;

    if (tempFlightPlanDataId != null && tempFlightPlanDataId != "") {
      const renewParameter = {
        flight_request_id: tempFlightRequestId,
        flight_plan_data_id: tempFlightPlanDataId,
        top_risk_form: this.formTopRisk.getRawValue()
      }

      this.showDialogWaitingUpdatingFlightPlanData = true;

      await this.flightPlanApisService.renewFlightPlanDataContent(renewParameter).subscribe({
        next: async (xResponseService: IPatternResponseFromAPI) => {

          this.formRequest.controls.fk_flight_plan_data_id.setValue(xResponseService.data_info[0].fk_flight_plan_data_id);
          this.formRequest.controls.current_status.controls.request_flight.patchValue(xResponseService.data_info[0].current_status.request_flight);
          this.formRequest.controls.current_status.controls.document.patchValue(xResponseService.data_info[0].current_status.document);
          this.formRequest.controls.current_status.controls.official.patchValue(xResponseService.data_info[0].current_status.official);
          this.formRequest.controls.request_historic.value = xResponseService.data_info[0].request_historic;

          // É Preciso Recuperar os Dados do plano de Voo para mostrar no View
          await this._recoverFlightPlanData(xResponseService.data_info[0].fk_flight_plan_data_id);

          this._showToast({
            severity: 'success',
            summary: 'Atualização de Dados de Plano de Voo',
            detail: `A Atualização dos Dados do Plano de voo foi um sucesso.`
          });
        },
        complete: () => {
          setTimeout(() => {
            this.showDialogWaitingUpdatingFlightPlanData = false;
          }, 10000);
        },
        error: (xErrorService: any) => {
          if (this.settingConfig.SHOW_LOG_EXCEPTION) {
            console.log("Exceção - Iniciar Atendimento da Requisição: ", xErrorService);
          }

          setTimeout(() => {
            this.showDialogWaitingUpdatingFlightPlanData = false;
          }, 10000);

          this._showToast({
            severity: 'error',
            summary: 'Atualização de Dados de Plano de Voo',
            detail: `Houve uma Exceção ao Fazer a Atualização dos Dados do Plano de Voo.`
          });
        }
      });
    } else {
      this._showToast({
        severity: 'error',
        summary: 'Atualização de Dados de Plano de Voo',
        detail: `Não há nenhum Plano de Voo ligado a requisição..`
      });
    }


    /*
    // TRATAMENTO DA DATA E HORA EOBT E ESTIMADO DE POUSO.
    this._mergeDataTimeEobtUtc();
    this._mergeDataTimeLandingUtc();
    this._calculatingLandingTime();
 
    // VERIFICA SE O FORM FOI PREENCHIDO CORRETAMENTE.
    if (this.formRequest.invalid || this.formTempAerodrome.invalid) {
      // Recupera todos os Erros do Form, com base na Validação durante a criação do FORM.
      const errosFormRequest = LocalMethodsHandlerClass.getFormFieldsErrors(this.formRequest);
      const errosFormTempAerodrome = LocalMethodsHandlerClass.getFormFieldsErrors(this.formTempAerodrome);
      const fieldsErros: any = [];
      let tmpData = '- campos não identificados.';
 
      // NOMEANDO OS CAMPOS
      errosFormRequest.forEach((xItem: any) => {
        fieldsErros.push(this._getFieldName(xItem.controlName));
      });
 
      errosFormTempAerodrome.forEach((xItem: any) => {
        fieldsErros.push(this._getFieldName(xItem.controlName));
      });
 
      // FORMATANDO OS NOMES DOS CAMPOS A SEREM MOSTRADOS.
      if (Array.isArray(fieldsErros)) {
        tmpData = fieldsErros.join('\n - ');
      }
 
      this._showToast({
        severity: 'error',
        summary: 'Atendimento da Requisição',
        detail: `O(s) campo(s) não foram validados: \n\n- ${tmpData}`
      });
    } else {
      this.showDialogWaitingGettingData = true;
 
      this.formRequest.getRawValue()
 
      // Tem que usar".getRawValue()", pois como tem controle desabilitado pode ser que os dados não sejam capturados.
      await this.flightPlanApisService.startRequestService(this.formRequest.getRawValue()).subscribe({
        next: async (xResponseService: IPatternResponseFromAPI) => {
          this.formRequest.controls.fk_flight_plan_data_id.setValue(xResponseService.data_info[0].fk_flight_plan_data_id);
          this.formRequest.controls.current_status.controls.request_flight.patchValue(xResponseService.data_info[0].current_status.request_flight);
          this.formRequest.controls.current_status.controls.document.patchValue(xResponseService.data_info[0].current_status.document);
          this.formRequest.controls.current_status.controls.official.patchValue(xResponseService.data_info[0].current_status.official);
          this.formRequest.controls.request_historic.value = xResponseService.data_info[0].request_historic;
 
          // É Preciso Recuperar os Dados do plano de Voo para mostrar no View
          this._recoverFlightPlanData();
 
          this._showToast({
            severity: 'success',
            summary: 'Atendimento da Requisição',
            detail: `O Atendimento dois iniciado com sucesso.`
          });
        },
        complete: () => {
          setTimeout(() => {
            this.showDialogWaitingGettingData = false;
          }, 3000);
        },
        error: (xErrorService: any) => {
          if (this.settingConfig.SHOW_LOG_EXCEPTION) {
            console.log("Exceção - Iniciar Atendimento da Requisição: ", xErrorService);
          }
 
          setTimeout(() => {
            this.showDialogWaitingGettingData = false;
          }, 3000);
 
          this._showToast({
            severity: 'error',
            summary: 'Atendimento da Requisição',
            detail: `Houve uma Exceção ao Salvar a Requisição do Voo.`
          });
        }
      });
    }*/
  }

  // ! TEM QUE ARRUMAR O RETORNO, POIS NÃO EStÁ ATRIBUINDO OS STATUS E O HISTORIC

  /**
   * @type METHOD.
   * @version 1.0.0 (GASPAR - 28/06/2024).
  .
   * 
   * @description 
   * - Gera o Documento do tipo completo.
   * 
   * @param xPdfGenerateParameters 
   * - Dados para a geração do Documento Completo:
   *   - flight_request_id: ID da requisição de Voo que será base para geração da documentação;
   *   - type_document: tipo de documento a ser gerado.
   */
  private async _generateCompleteDocumentationPdf(xPdfGenerateParameters: any) {
    this.showDialogWaitingPdfGenerating = true;

    await this.flightPlanApisService.generateCompleteDocumentationPdfService(xPdfGenerateParameters).subscribe({
      next: (xResponseService: IPatternResponseFromAPI) => {
        if (xResponseService.status_code == 200) {
          this.formRequest.controls.documents_files.value = xResponseService.data_info[0].document_files;
          this.formRequest.controls.current_status.controls.request_flight.patchValue(xResponseService.data_info[0].current_status.request_flight);
          this.formRequest.controls.current_status.controls.document.patchValue(xResponseService.data_info[0].current_status.document);
          this.formRequest.controls.current_status.controls.official.patchValue(xResponseService.data_info[0].current_status.official);
          this.formRequest.controls.request_historic.value = xResponseService.data_info[0].request_historic;

          this._showToast({
            severity: 'success',
            summary: 'Geração do PDF',
            detail: `O documento foi gerado com sucesso.`
          });
        } else {
          this._showToast({
            severity: 'error',
            summary: 'Geração do PDF',
            detail: `Houve um erro na geração do documento.`
          });
        }
      },
      complete: () => {
        setTimeout(() => {
          this.showDialogWaitingPdfGenerating = false;
        }, 10000);
      },
      error: (xErrorService: any) => {
        if (this.settingConfig.SHOW_LOG_EXCEPTION) {
          console.log("Exceção - Gerar o PDF: ", xErrorService);
        }

        setTimeout(() => {
          this.showDialogWaitingPdfGenerating = false;
        }, 10000);

        this._showToast({
          severity: 'error',
          summary: 'Geração do PDF',
          detail: `Houve uma exceção na geração do documento.`
        });
      },
    });
  }

  private async _generateCardPdf(xPdfGenerateParameters: any) {
    this.showDialogWaitingPdfGenerating = true;

    await this.flightPlanApisService.generateCardPdfService(xPdfGenerateParameters).subscribe({
      next: (xResponseService: IPatternResponseFromAPI) => {
        if (xResponseService.status_code == 200) {
          this.formRequest.controls.documents_files.value = xResponseService.data_info[0].document_files;
          this.formRequest.controls.current_status.controls.request_flight.patchValue(xResponseService.data_info[0].current_status.request_flight);
          this.formRequest.controls.current_status.controls.document.patchValue(xResponseService.data_info[0].current_status.document);
          this.formRequest.controls.current_status.controls.official.patchValue(xResponseService.data_info[0].current_status.official);
          this.formRequest.controls.request_historic.value = xResponseService.data_info[0].request_historic;

          this._showToast({
            severity: 'success',
            summary: 'Geração do PDF',
            detail: `O documento foi gerado com sucesso.`
          });
        } else {
          this._showToast({
            severity: 'error',
            summary: 'Geração do PDF',
            detail: `Houve um erro na geração do documento.`
          });
        }
      },
      complete: () => {
        setTimeout(() => {
          this.showDialogWaitingPdfGenerating = false;
        }, 10000);
      },
      error: (xErrorService: any) => {
        if (this.settingConfig.SHOW_LOG_EXCEPTION) {
          console.log("Exceção - Gerar o PDF: ", xErrorService);
        }

        setTimeout(() => {
          this.showDialogWaitingPdfGenerating = false;
        }, 10000);

        this._showToast({
          severity: 'error',
          summary: 'Geração do PDF',
          detail: `Houve uma exceção na geração do documento.`
        });
      },
    });
  }


  //#endregion

  //#region "|--- CALLBACK EVENTS ---|"
  /**
   * @type METHOD
   * @version 1.0.0 (GASPAR - 27/06/2024).
  
   * 
   * @description
   * - FAz o tratamento da Ação do Botão relacionado a página sobre Requisição.
   * 
   * @param xAction 
   */
  callbackToolbarRequestButtons(xAction: string) {
    if (xAction == BUTTON_ACTION.SAVE) {
      this._saveRequest();
    } else if (xAction == BUTTON_ACTION.START) {
      this._startRequestService();
    } else if (xAction == BUTTON_ACTION.UPDATE) {
      this._updateRequest();
    } else if (xAction == BUTTON_ACTION.SHOW_MESSAGING) {
      this.showSidebarMessages = true;
    } else if (xAction == BUTTON_ACTION.SHOW_CHECK_LIST) {
      this.showSidebarCheckList = true;
    }


    /*if (xAction == BUTTON_ACTION.NEW_CLONE) {
      console.log(BUTTON_ACTION.NEW_CLONE);
    } else if (xAction == BUTTON_ACTION.NEW_RETURN) {
      console.log(BUTTON_ACTION.NEW_RETURN);
    }   else if (xAction == BUTTON_ACTION.CREATE_PDF) {
      //this._generatePdf();
    } else if (xAction == BUTTON_ACTION.CANCEL) {
      console.log(BUTTON_ACTION.CANCEL);
    }*/
  }

  /**
   * @type METHOD
   * @version 1.0.0 (GASPAR - 28/06/2024).
  
   * 
   * @description
   * - FAz o tratamento da Ação do Botão relacionado a geração do PDF.
   * 
   * @param xAction 
   */
  callbackGeneratePdfButtons(xAction: string) {
    console.log("~~~~~~", xAction)
    
    if (xAction == TYPE_DOCUMENT.COMPLETE_EXECUTIVE) {
      const pdfGenerateParameters = {
        flight_request_id: this.formRequest.controls.flight_request_id.value,
        type_document: TYPE_DOCUMENT.COMPLETE_EXECUTIVE
      }

      this._generateCompleteDocumentationPdf(pdfGenerateParameters);
    } 
  }

  async callbackActionExtraFlightData(xAction: any) {
    await this.flightPlanApisService.updateFlightPlanDataContent(this.currentFlightPlanData).subscribe({
      next: async (xResponseService: IPatternResponseFromAPI) => {

        // É Preciso Recuperar os Dados do plano de Voo para mostrar no View
        // !EU TENHO QE FAZER...
        //////////////////////this._recoverFlightPlanData();

        this._showToast({
          severity: 'success',
          summary: 'Atualização de Dados de Plano de Voo',
          detail: `A Atualização dos Dados do Plano de voo foi um sucesso.`
        });
      },
      complete: () => {
        setTimeout(() => {
          this.showDialogWaitingUpdatingFlightPlanData = false;
        }, 10000);
      },
      error: (xErrorService: any) => {
        if (this.settingConfig.SHOW_LOG_EXCEPTION) {
          console.log("Exceção - Iniciar Atendimento da Requisição: ", xErrorService);
        }

        setTimeout(() => {
          this.showDialogWaitingUpdatingFlightPlanData = false;
        }, 10000);

        this._showToast({
          severity: 'error',
          summary: 'Atualização de Dados de Plano de Voo',
          detail: `Houve uma Exceção ao Fazer a Atualização dos Dados do Plano de Voo.`
        });
      }
    });


  }

  /**
   * @type METHOD
   * @version 1.0.0 (GASPAR - 28/06/2024).
  
   * 
   * @description
   * - FAz o tratamento da Ação do Botão ao Conteúdo do PDF.
   * 
   * @param xAction 
   */
  callbackContentPdfButtons(xAction: string) {
    if (xAction == BUTTON_ACTION.RENEW) {
      this._renewFlightRequestData();
    } else if (xAction == TYPE_DOCUMENT.CARD_AERODROME) {
      const pdfGenerateParameters = {
        flight_request_id: this.formRequest.controls.flight_request_id.value,
        type_document: TYPE_DOCUMENT.CARD_AERODROME
      }

      
      this._generateCardPdf(pdfGenerateParameters);
    } else if (xAction == TYPE_DOCUMENT.CARD_METEOROLOGY) {
      const pdfGenerateParameters = {
        flight_request_id: this.formRequest.controls.flight_request_id.value,
        type_document: TYPE_DOCUMENT.CARD_METEOROLOGY
      }

      this._generateCardPdf(pdfGenerateParameters);
    }
  }
  //#endregion





  //#region "|--- FORMS METHODS---|"


  /**
   * TODO: https://tsdoc.org/
   */

  //#endregion

  //#region "|--- CALL BACKS METHODS---|"
  returnCallbackSearchAerodromeByIcao(xDataAction: any) {
    if (xDataAction.action == BUTTON_ACTION.SHOW_DIALOG) {
      this.showDialogSearchAerodromeIcao = true;
    }
  }

  returnCallbackSearchAerodromeByCoord(xDataAction: any) {
    if (xDataAction.action == BUTTON_ACTION.SHOW_DIALOG) {
      this.showDialogSearchAerodromeCoord = true;
    }
  }

  expandNavigationMap(){

  }

  refreshNavigationMap(){

  }

  centerNavigationMap(){

  }

  /**
   * TODO: https://tsdoc.org/
   */
  returnCallBackFormTempAerodrome(xFormReturn: FormGroup) {
    this.formTempAerodrome = xFormReturn;
  }

  /**
   * TODO: https://tsdoc.org/
   */
  returnCallBackAerodromeOnMap(xAerodromeTarget: string) {
    // TEM QUE POR UM TIMER, POIS ELE DEMORA UM POUCO PARA ATUALIZAR O FORM.
    setTimeout(() => {
      const arrayMarkerMap = this.objectToDrawOnMaps.marker;
      const arrayDrawMap = this.objectToDrawOnMaps.draw;
      const _tempArrayMarkerMap: any = [];
      const _tempArrayDrawMap: any = [];

      this.objectToDrawOnMaps.marker = [];
      this.objectToDrawOnMaps.draw = [];

      let tempAerodromeIcao: any;
      let tempCoordinate: any;

      if (this.arrayGoalAerodrome.includes(xAerodromeTarget)) {
        tempAerodromeIcao = this.formRequest.controls.aerodromes.controls[`${xAerodromeTarget}`].controls.data.controls.icao_code.value;
        tempCoordinate = this.formRequest.controls.aerodromes.controls[`${xAerodromeTarget}`].controls.data.controls.coordinate_dd.value;
      } else if (xAerodromeTarget.indexOf('alt_') >= 0) {
        const tempIndexAlt = parseInt(xAerodromeTarget.replaceAll('alt_', '')) - 3;

        if (this.formRequest.controls.aerodromes.controls.alt_extras.controls[tempIndexAlt]) {
          tempAerodromeIcao = this.formRequest.controls.aerodromes.controls.alt_extras.controls[tempIndexAlt].controls.data.controls.icao_code.value;
          tempCoordinate = this.formRequest.controls.aerodromes.controls.alt_extras.controls[tempIndexAlt].controls.data.controls.coordinate_dd.value;
        }
      }

      // PODE APAGAR O MARKER SEMPRE, INDEPENDENTE SE EXISTE OU NÃO, POIS ELE FOI RETIRADO O ESTÁ ENTRANDO UM NOVO...     
      arrayMarkerMap.forEach((xObjMarker: any, xIndex: number) => {
        // Verifica se existe um MARKER COM O TARGET DESEJADO.
        if (xObjMarker.target == xAerodromeTarget) {
          delete arrayMarkerMap[xIndex];
        }
      });

      // PODE APAGAR O DRAW SEMPRE, INDEPENDENTE SE EXISTE OU NÃO, POIS ELE FOI RETIRADO O ESTÁ ENTRANDO UM NOVO...     
      arrayDrawMap.forEach((xObjDraw: any, xIndex: number) => {
        // Verifica se existe um MARKER COM O TARGET DESEJADO.
        if (xObjDraw.target.includes(xAerodromeTarget) >= 0) {
          delete arrayDrawMap[xIndex];
        }
      });

      if (tempAerodromeIcao != null && tempAerodromeIcao.length == 4) {
        arrayMarkerMap.push({
          id: LocalMethodsHandlerClass.generateUuid(),
          name: tempAerodromeIcao,
          target: xAerodromeTarget,
          coord_dd: tempCoordinate,
        });
      }

      // ESTOU FAZENDO ISTO PARA REORDENAR OS INDEX DO ARRAY, POIS FORAM DELETADOS ALGUNS E FEZ BAGUNÇA
      arrayMarkerMap.forEach((xElement: any) => {
        _tempArrayMarkerMap.push(xElement);
      });

      _tempArrayMarkerMap.forEach((xItemMarkerFrom: any, xIndexFrom: number) => {
        if (xItemMarkerFrom.target == "departure") {
          _tempArrayMarkerMap.forEach((xItemMarkerTo: any, xIndexTo: number) => {
            if (xItemMarkerTo.target == "destination") {
              _tempArrayDrawMap.push({
                id: LocalMethodsHandlerClass.generateUuid(),
                type: "line",
                color: "#F0000",
                target: ["departure", "destination"],
                from: xItemMarkerFrom.coord_dd,
                to: xItemMarkerTo.coord_dd
              })
            }
          });
        } else if (xItemMarkerFrom.target == "destination") {
          _tempArrayMarkerMap.forEach((xItemMarkerTo: any, xIndexTo: number) => {
            if (xItemMarkerTo.target == "alt_1") {
              _tempArrayDrawMap.push({
                id: LocalMethodsHandlerClass.generateUuid(),
                type: "line",
                color: "#F0000",
                target: ["destination", "alt_1"],
                from: xItemMarkerFrom.coord_dd,
                to: xItemMarkerTo.coord_dd
              })
            }
          });
        } else if (xItemMarkerFrom.target == "alt_1") {
          _tempArrayMarkerMap.forEach((xItemMarkerTo: any, xIndexTo: number) => {
            if (xItemMarkerTo.target == "alt_2") {
              _tempArrayDrawMap.push({
                id: LocalMethodsHandlerClass.generateUuid(),
                type: "line",
                color: "#F0000",
                target: ["alt_1", "alt_2"],
                from: xItemMarkerFrom.coord_dd,
                to: xItemMarkerTo.coord_dd
              })
            }
          });
        }
      })

      // ESTOU FAZENDO ISTO PARA REORDENAR OS INDEX DO ARRAY, POIS FORAM DELETADOS ALGUNS E FEZ BAGUNÇA
      arrayDrawMap.forEach((xElement: any) => {
        _tempArrayDrawMap.push(xElement);
      });

      this.formRequest.controls.map.controls.marker.value = _tempArrayMarkerMap;
      this.formRequest.controls.map.controls.draw.value = _tempArrayDrawMap;

      this.objectToDrawOnMaps = {
        marker: _tempArrayMarkerMap,
        draw: _tempArrayDrawMap,
      };
    }, 1000);
  }

  /**
   * TODO: https://tsdoc.org/
   */
  returnCallBackRouteButtons(xAction: string) {
    if (xAction == BUTTON_ACTION.SAVE) {
      this.showDialogRouteSave = true;
      console.log(BUTTON_ACTION.SAVE);
    } else if (xAction == BUTTON_ACTION.SEARCH) {
      this.showDialogRouteList = true;
      console.log(BUTTON_ACTION.SEARCH);
    }
  }



  returnCallbackDialogManyAerodromeBasicData(xAction: string) {
    if (xAction == BUTTON_ACTION.CLOSE_DIALOG) {
      this.showDialogManyAerodromeBasicData = false;
    }
  }

  returnCallbackDialogRouteSave(xAction: string) {
    if (xAction == BUTTON_ACTION.CLOSE_DIALOG) {
      this.showDialogRouteSave = false;
    }
  }

  returnCallbackDialogRouteList(xAction: string) {
    if (xAction == BUTTON_ACTION.CLOSE_DIALOG) {
      this.showDialogRouteList = false;
    }
  }

  returnCallbackDialogSearchAerodromeByIcao(xAction: string) {
    if (xAction == BUTTON_ACTION.CLOSE_DIALOG) {
      this.showDialogSearchAerodromeIcao = false;
    } else if (xAction == BUTTON_ACTION.OK) {
    }
  }

  returnCallbackDialogSearchAerodromeByCoord(xAction: string) {
    if (xAction == BUTTON_ACTION.CLOSE_DIALOG) {
      this.showDialogSearchAerodromeCoord = false;
    } else if (xAction == BUTTON_ACTION.OK) {
    }
  }

  returnCallbackDialogWaitingProcess(xAction: string) {
    if (xAction == BUTTON_ACTION.CLOSE_DIALOG) {
      this.showDialogWaitingProcess = false;
    }
  }


  //#endregion

  //#region "|--- PRIVATE METHODS---|"

  /**
   * TODO: https://tsdoc.org/
   */


















  private _drawRouteToMap() {
    /*const tempDraw = [];

    let departureCoord = null;
    let destinationCoord = null;
    let alt1Coord = null;
    let alt2Coord = null;
  
    console.log("********************************", this.objectToDrawOnMaps);

    if (this.objectToDrawOnMaps.marker != null) {
      this.objectToDrawOnMaps.marker.forEach((xItem: any) => {
        if (xItem.target == "departure") {
          departureCoord = xItem.coord_dd;
        } else if (xItem.target == "destination") {
          destinationCoord = xItem.coord_dd;
        } else if (xItem.target == "alt_1") {
          alt1Coord = xItem.coord_dd;
        } else if (xItem.target == "alt_2") {
          alt2Coord = xItem.coord_dd;
        }
      });

      // DEPARTURE - DESTINATION
      if (departureCoord != null && destinationCoord != null) {
        tempDraw.push({
          id: LocalMethodsHandlerClass.generateUuid(),
          type: "line",
          color: "#F0000",
          target: ["departure", "destination"],
          from: departureCoord,
          to: destinationCoord
        });
      }

      // DESTINATION - ALT_1
      if (destinationCoord != null && alt1Coord != null) {
        tempDraw.push({
          id: LocalMethodsHandlerClass.generateUuid(),
          type: "line",
          color: "#F0000",
          target: ["destination", "alt_1"],
          from: destinationCoord,
          to: alt1Coord
        });
      }

      // ALT_1 - ALT_2
      if (alt1Coord != null && alt2Coord != null) {
        tempDraw.push({
          id: LocalMethodsHandlerClass.generateUuid(),
          type: "line",
          color: "#F0000",
          target: ["alt_1", "alt_2"],
          from: alt1Coord,
          to: alt2Coord
        });
      }

      this.objectToDrawOnMaps = {
        marker: this.objectToDrawOnMaps.tempDataAerodromesToDrawMap,
        draw: tempDraw
      }
    }*/
  }
  //#endregion


  /**
   * TODO: https://tsdoc.org/
   */
  private _initFormChecklist(): void {
    this.formChecklist = initFormChecklist();
  }

  private _initFormTopRisk(): void {
    this.formTopRisk = initFormRiskList();
  }


  //#endregion

  //#region "|--- PPPPPPRIVATE METHODS---|"







  //#endregion

  /**
   * TODO: https://tsdoc.org/
   */
  async saveFlightPlan() {
    this.currentAction = undefined;

    if (this.formFlightPlanRequest.invalid) {
      const invalid = [];
      const controls = this.formFlightPlanRequest.controls;
      for (const name in controls) {
        if (controls[name].invalid) {
          invalid.push(name);
        }
      }
    } else {
      // Tem que usar".getRawValue()", pois como tem controle desabilitado pode ser que os dados não sejam capturados.
      await this.flightPlanApisService
        .saveFlightRequest(this.formFlightPlanRequest.getRawValue())
        .subscribe({
          next: (xResponseService: IPatternResponseFromAPI) => {
            if (xResponseService.status_code == 201) {
              const dataReturned = xResponseService.data_info;

              // Acrescentar o ID da Aeronave Inserida.
              this.formFlightPlanRequest.controls.flight_plan_id.setValue(
                xResponseService.data_info.data[0].id,
              );
              this.formFlightPlanRequest.controls.flight_request_code.controls.setValue(
                dataReturned.data[0]['flight_request_code'],
              );

              //TODO: TRANSLATION
              //TODO: MESSENGER
              //TODO: TOAST
              this.messageService.add({
                key: 'toast2',
                severity: 'success',
                summary: 'Requisição de Voo',
                detail: 'A Reequisição de Voo foi criada  com Sucesso.',
              });
            } else {
            }
          },
          complete: () => { },
          error: (xErrorService: any) => {
            console.log('BBBBBBBBBBBBB', xErrorService);
            this.messageService.add({
              key: 'toast2',
              severity: 'error',
              summary: 'Plano de Voo',
              detail: 'ERROR',
            });
          },
        });
    }
  }

  /**
   * TODO: https://tsdoc.org/
   */
  showSearchRecordedAerodrome(xAerodromeTarget: string) {
    this.currentTagAerodromeTarget = xAerodromeTarget;
  }

  /**
   * TODO: https://tsdoc.org/
   */
  showSearchLocation(xAerodromeTarget: string) {
    this.showDialogSearchLocation = true;
  }

  /**
   * TODO: https://tsdoc.org/
   */
  closeSearchRecordedAerodrome() {
    this.currentTagAerodromeTarget = '';
    ///////////////////////////////this.arrayAerodromeSearch = [];
    this.formFilterSearchRecordedAerodrome.controls.icao_code.setValue(null);
  }

  //////////////////////////////////////**
  ///////////////////////////////////// * TODO: https://tsdoc.org/
  ///////////////////////////////////// */
  /////////////////////////////////////selectAerodrome(xAerodromeId: string) {
  /////////////////////////////////////  let tmpAerodromeData: any;
  /////////////////////////////////////
  /////////////////////////////////////  for (const indexArray in this.arrayAerodromeSearch) {
  /////////////////////////////////////    if (
  /////////////////////////////////////      this.arrayAerodromeSearch[indexArray]['aerodrome_basic_data_id'] ==
  /////////////////////////////////////      xAerodromeId
  /////////////////////////////////////    ) {
  /////////////////////////////////////      tmpAerodromeData = this.arrayAerodromeSearch[indexArray];
  /////////////////////////////////////    }
  /////////////////////////////////////  }
  /////////////////////////////////////
  /////////////////////////////////////  // Por segurança, vou optar por chamar o método para limpar o conteúdo que pde existir do aerodromo em questão.
  /////////////////////////////////////  this._removeAerodromeData(this.currentTagAerodromeTarget.toLowerCase());
  /////////////////////////////////////
  /////////////////////////////////////  // Só é preciso atualizar o ICAO CODE, pois isto vai disparar o Evento que pega alteração no conteúdo do controle "controls.icao_code"
  /////////////////////////////////////  // Dentro do Evento disparado ele vai fazer toda o tratamento necessário, como ver o tamanho do valor e limpar dados antigos e colocar os dados novos.
  /////////////////////////////////////  // *** IMPORTANTE *** No tratamento do Evento do Campo ICAO_CODE do Form formFlightPlanRequest ele vai buscar novamente os dados para preencher o que for necessário, ou seja, OS DADOS RECUPERADOS AQUI N4AO SÃO UTILIZADOS PARA PREENCHER O FORM.
  /////////////////////////////////////  if (this.currentTagAerodromeTarget.toLowerCase() == 'departure') {
  /////////////////////////////////////    this.formFlightPlanRequest.controls.aerodrome_departure.controls.icao_code.setValue(
  /////////////////////////////////////      tmpAerodromeData.icao_code,
  /////////////////////////////////////    );
  /////////////////////////////////////  } else if (this.currentTagAerodromeTarget.toLowerCase() == 'destination') {
  /////////////////////////////////////    this.formFlightPlanRequest.controls.aerodrome_destination.controls.icao_code.setValue(
  /////////////////////////////////////      tmpAerodromeData.icao_code,
  /////////////////////////////////////    );
  /////////////////////////////////////  } else if (this.currentTagAerodromeTarget.toLowerCase() == 'alt_1') {
  /////////////////////////////////////    this.formFlightPlanRequest.controls.aerodrome_alt_1.controls.icao_code.setValue(
  /////////////////////////////////////      tmpAerodromeData.icao_code,
  /////////////////////////////////////    );
  /////////////////////////////////////  } else if (this.currentTagAerodromeTarget.toLowerCase() == 'alt_2') {
  /////////////////////////////////////    this.formFlightPlanRequest.controls.aerodrome_alt_2.controls.icao_code.setValue(
  /////////////////////////////////////      tmpAerodromeData.icao_code,
  /////////////////////////////////////    );
  /////////////////////////////////////  }
  /////////////////////////////////////
  /////////////////////////////////////  this.currentTagAerodromeTarget = '';
  /////////////////////////////////////  this.arrayAerodromeSearch = [];
  /////////////////////////////////////  this.formFilterSearchRecordedAerodrome.controls.icao_code.setValue(null);
  /////////////////////////////////////}

  /**
   * TODO: https://tsdoc.org/
   */
  closeSearchLocation() {
    this.showDialogSearchLocation = false;
  }

  /**
   * TODO: https://tsdoc.org/
   */
  copyMapClipboard() {
    const imageToScreen = document.getElementById('map-navigation');
    const tmpContext = this;

    if (imageToScreen) {
      domtoimage
        .toPng(imageToScreen, {
          cacheBust: true,
          bgcolor: '#fff',
          height: imageToScreen.clientHeight + 10,
          width: imageToScreen.clientWidth + 10,
        })
        .then(function (xDataUrl: any) {
          copyImageToClipboard(xDataUrl)
            .then(() => {
              tmpContext.isMapCopied = true;

              tmpContext.messageService.add({
                severity: 'success',
                summary: `Copiar Imagem para WhatsApp`,
                detail: `Imagem copiado com Sucesso.`,
              });

              setTimeout(() => {
                tmpContext.isMapCopied = false;
              }, 5000);
            })
            .catch((e) => {
              console.log('Error: ', e.message);
            });
        })
        .catch(function (xError: any) {
          console.error('oops, something went wrong!', xError);
        });
    }
  }

  /**
   * TODO: https://tsdoc.org/
   */
  newFlightPlan() { }

  /**
   * TODO: https://tsdoc.org/
   */
  getAerodromeIcon(xAerodromeTarget: any) {
    if (xAerodromeTarget) {
      return `/assets/imgs/l_icon/${this.objAerodromeIcones[xAerodromeTarget.toUpperCase()]}`;
    } else {
      return `/assets/imgs/l_icon/${this.objAerodromeIcones[this.currentTagAerodromeTarget.toUpperCase()]}`;
    }
  }

  /**
   * TODO: https://tsdoc.org/
   */
  async copyFlightIntentionClipboard() {
    let intentionMessage = '';
    let tmpDateEobt =
      this.formFlightPlanRequest.controls.eobt.controls.date_zulu.value;

    const tmpDeparture =
      this.formFlightPlanRequest.controls.aerodrome_departure.controls.icao_code
        .value;
    const tmpDestination =
      this.formFlightPlanRequest.controls.aerodrome_destination.controls
        .icao_code.value;
    const tmpAircraftMark =
      this.formFlightPlanRequest.controls.aircraft_mark.value;
    const tmpPilotId =
      this.formFlightPlanRequest.controls.pilot_in_command.controls
        .normalized_text.value;
    const tmpTimeEobt =
      this.formFlightPlanRequest.controls.eobt.controls.time_zulu.value;
    const tmpFlightRule = this.formFlightPlanRequest.controls.flight_rule.value;
    const tmpFlightLevel =
      this.formFlightPlanRequest.controls.flight_level.value;

    if (typeof tmpDateEobt == 'object') {
      const tmpDate = new Date(tmpDateEobt);

      tmpDateEobt = `${tmpDate.getUTCDate().toString().padStart(2, '0')}/${(tmpDate.getUTCMonth() + 1).toString().padStart(2, '0')}/${tmpDate.getUTCFullYear()}`;
    }

    intentionMessage = `✅ *Solicitação de Voo recebida!*\n`;

    intentionMessage = `${intentionMessage} *${tmpAircraftMark}* CANAC: ${tmpPilotId}`;
    intentionMessage = `${intentionMessage} \n ${tmpDateEobt} ${tmpTimeEobt}z`;
    intentionMessage = `${intentionMessage} \n *${tmpDeparture} - ${tmpDestination}* ${tmpFlightRule} ${tmpFlightLevel}`;

    //#region "|--- REFAZER POIS ELE É CHAMADO EM 2 LUGARES DA MESMA MANERIA ---|"
    const dateTimeSearch = new Date();
    let retornoMetarTaf;

    Promise.all([(retornoMetarTaf = await this._getMetarTafToMessage())]);

    intentionMessage = ` ${intentionMessage} \n\n ☁️ *CONSULTA METAR E TAF REALIZADA: ${dateTimeSearch.getUTCDate().toString().padStart(2, '0')}/${(dateTimeSearch.getUTCMonth() + 1).toString().padStart(2, '0')}/${dateTimeSearch.getUTCFullYear()} ${dateTimeSearch.getUTCHours().toString().padStart(2, '0')}:${dateTimeSearch.getUTCMinutes().toString().padStart(2, '0')}z*: ☁️\n`;

    if (retornoMetarTaf) {
      // O Retorno do Metar TAF está na ordem correta de Apresentação, basta mostrar
      for (let itemMetarTaf in retornoMetarTaf) {
        let tmpMetar: string = '';
        let tmpTaf: string = '';

        intentionMessage = `${intentionMessage} \n  📍 *${itemMetarTaf.toUpperCase()}*`;

        if (retornoMetarTaf[itemMetarTaf]['metar']) {
          tmpMetar = retornoMetarTaf[itemMetarTaf]['metar'];

          if (tmpMetar.indexOf('METAR') >= 0) {
            tmpMetar = tmpMetar.replace(`METAR`, `*METAR*`);
          }

          if (tmpMetar.indexOf('SPECI') >= 0) {
            tmpMetar = tmpMetar.replace(`SPECI`, `*SPECI*`);
          }
        }

        if (retornoMetarTaf[itemMetarTaf]['taf']) {
          tmpTaf = retornoMetarTaf[itemMetarTaf]['taf'];

          if (tmpTaf.indexOf('TAF') >= 0) {
            tmpTaf = tmpTaf.replace(`TAF`, `*TAF*`);
          }
        }

        if (tmpMetar != '') {
          intentionMessage = `${intentionMessage} \n ${tmpMetar}`;
        }

        if (tmpTaf != '') {
          intentionMessage = `${intentionMessage} \n ${tmpTaf} `;
        }
      }
    }
    //#endregion

    clipboard.write(intentionMessage).then((xReturn) => {
      this.messageService.add({
        severity: 'success',
        summary: `Copiar Mensagem de Intenção de Voo`,
        detail: `Mensagem copiado com Sucesso.`,
      });

      this.isIntentionMessageCopied = true;

      setTimeout(() => {
        this.isIntentionMessageCopied = false;
      }, 5000);
    });
  }

  /**
   * TODO: https://tsdoc.org/
   */
  async copyMetarTafClipboard() {
    const dateTimeSearch = new Date();
    let retornoMetarTaf;
    let metarTafMessage = '';

    Promise.all([(retornoMetarTaf = await this._getMetarTafToMessage())]);

    metarTafMessage = `☁️ *CONSULTA METAR E TAF REALIZADA: ${dateTimeSearch.getUTCDate().toString().padStart(2, '0')}/${(dateTimeSearch.getUTCMonth() + 1).toString().padStart(2, '0')}/${dateTimeSearch.getUTCFullYear()} ${dateTimeSearch.getUTCHours().toString().padStart(2, '0')}:${dateTimeSearch.getUTCMinutes().toString().padStart(2, '0')}z*: ☁️\n`;

    if (retornoMetarTaf) {
      // O Retorno do Metar TAF está na ordem correta de Apresentação, basta mostrar
      for (let itemMetarTaf in retornoMetarTaf) {
        let tmpMetar: string = '';
        let tmpTaf: string = '';

        metarTafMessage = `${metarTafMessage} \n  📍 *${itemMetarTaf.toUpperCase()}*`;

        if (retornoMetarTaf[itemMetarTaf]['metar']) {
          tmpMetar = retornoMetarTaf[itemMetarTaf]['metar'];

          if (tmpMetar.indexOf('METAR') >= 0) {
            tmpMetar = tmpMetar.replace(`METAR`, `*METAR*`);
          }

          if (tmpMetar.indexOf('SPECI') >= 0) {
            tmpMetar = tmpMetar.replace(`SPECI`, `*SPECI*`);
          }
        }

        if (retornoMetarTaf[itemMetarTaf]['taf']) {
          tmpTaf = retornoMetarTaf[itemMetarTaf]['taf'];

          if (tmpTaf.indexOf('TAF') >= 0) {
            tmpTaf = tmpTaf.replace(`TAF`, `*TAF*`);
          }
        }

        if (tmpMetar != '') {
          metarTafMessage = `${metarTafMessage} \n ${tmpMetar}`;
        }

        if (tmpTaf != '') {
          metarTafMessage = `${metarTafMessage} \n ${tmpTaf} `;
        }
      }
    }

    clipboard.write(metarTafMessage).then((xReturn) => {
      this.messageService.add({
        severity: 'success',
        summary: `Copiar Mensagem de Intenção de Voo`,
        detail: `Mensagem copiado com Sucesso.`,
      });

      this.isMetarTafCopied = true;

      setTimeout(() => {
        this.isMetarTafCopied = false;
      }, 5000);
    });
  }

  /**
   * TODO: https://tsdoc.org/
   */
  private _getFlightRuleWhatsapp(xValue: string) {
    if (xValue) {
      // Verifica se existe valor.
      if (xValue.toUpperCase() == 'I') {
        return 'IFR';
      } else if (xValue.toUpperCase() == 'Y') {
        return 'YANKEE';
      } else if (xValue.toUpperCase() == 'V') {
        return 'VFR';
      } else if (xValue.toUpperCase() == 'Z') {
        return 'ZULU';
      } else {
        return '';
      }
    }

    return '';
  }

  /**
   * TODO: https://tsdoc.org/
   */
  private _forceReloadArrayAerodromesToMarkMapValue() {
    const tmpArrayAerodrome: IAerodromeMarkerOnTheMap[] = [];

    // Tem que forçar uma alteração no Variável, pois só assim o componente Child, par aonde ele é passado, vai pegar a alteração no "INPUT PROPERTY SETTER".
    this.arrayAerodromesToMarkMap = tmpArrayAerodrome.concat(
      this.arrayAerodromesToMarkMap,
    );
  }

  /**
   * TODO: https://tsdoc.org/
   */
  private async _getAerodromeData(xValue: string, xAerodromeTarget: string) {
    try {
      if (xValue.length == 4) {
        const fieldToSearch = {
          icao_code: {
            value: xValue,
            condition: '=',
          },
        };

        this.flightPlanApisService
          .getAerodromeBasicDataByFields(fieldToSearch)
          .subscribe({
            next: (xResponseService: IPatternResponseFromAPI) => {
              if (xResponseService.status_code == 200) {
                const dataInfo = xResponseService.data_info.data[0];

                this.formFlightPlanRequest.controls[
                  `aerodrome_${xAerodromeTarget}`
                ].controls.icao_code.setValue(dataInfo.icao_code, {
                  emitEvent: false,
                }); // É preciso colocar o EmitEvento como falso para evitar de entrar em um looping infinito de mudanças realizadas no controle do formulário.
                this.formFlightPlanRequest.controls[
                  `aerodrome_${xAerodromeTarget}`
                ].controls.name.setValue(dataInfo.name);
                this.formFlightPlanRequest.controls[
                  `aerodrome_${xAerodromeTarget}`
                ].controls.coordinate_label.setValue(dataInfo.coordinate_label);
                this.formFlightPlanRequest.controls[
                  `aerodrome_${xAerodromeTarget}`
                ].controls.coordinate_dd.setValue(dataInfo.coordinate_dd);
                this.formFlightPlanRequest.controls[
                  `aerodrome_${xAerodromeTarget}`
                ].controls.coordinate_dms.setValue(dataInfo.coordinate_dms);
                this.formFlightPlanRequest.controls[
                  `aerodrome_${xAerodromeTarget}`
                ].controls.coordinate_plan.setValue(dataInfo.coordinate_plan);
                this.formFlightPlanRequest.controls[
                  `aerodrome_${xAerodromeTarget}`
                ].controls.coordinate_geo.setValue(dataInfo.coordinate_geo);

                this.arrayAerodromesToMarkMap.push({
                  id_marker: dataInfo.aerodrome_basic_data_id,
                  icao_code: dataInfo.icao_code,
                  aerodrome_name: dataInfo.name,
                  aerodrome_target: xAerodromeTarget,
                  coordinates_dd: dataInfo.coordinate_dd,
                  icon_class: xAerodromeTarget,
                  popup_legend: 'TESTE01',
                });

                //TODO: TRANSLATION
                //TODO: MESSENGER
                //TODO: TOAST
                this.messageService.add({
                  severity: 'success',
                  summary: 'Recuperar Aeródromo',
                  detail: `Aeródromo ${xValue} Recuperado com Sucesso.`,
                });
              } else if (xResponseService.status_code == 404) {
                //TODO: TRANSLATION
                //TODO: MESSENGER
                //TODO: TOAST
                this.messageService.add({
                  severity: 'error',
                  summary: 'Recuperar Aeródromo',
                  detail: `Aeródromo ${xValue} NÃO existente.`,
                });
              } else {
                //TODO: TRANSLATION
                //TODO: MESSENGER
                //TODO: TOAST
                this.messageService.add({
                  severity: 'error',
                  summary: 'Recuperar Aeródromo',
                  detail: `Erro ao Recuperar Dados do Aeródromo ${xValue}.`,
                });
              }

              // Lembre-se: está sendo executado um OBSERVER, ou seja, ele é assíncrono e é difícil esperar o seu final para executar outro método.
              // Para que os aeródromos sejam plotados no MAPA é preciso "forçar" uma atualização do conteúdo para que seja pego pelo componente de mapa, via "INPUT PROPERTY SETTER"
              // Forçar no final da execução do OBSERVER
              this._forceReloadArrayAerodromesToMarkMapValue();
            },
            complete: () => { },
            error: (xErrorService: any) => {
              this.messageService.add({
                severity: 'error',
                summary: 'Recuperar Aeródromo',
                detail: 'ERROR',
              });
            },
          });
      } else {
        if (
          this.arrayAerodromesToMarkMap.find(
            (xObjectItem) => xObjectItem.icon_class === xAerodromeTarget,
          )
        ) {
          // Remove o Elemento do Array que possui o Tipo de Aerodrome que não possui o Indicador ICAO com 4 letras....
          this.arrayAerodromesToMarkMap = this.arrayAerodromesToMarkMap.filter(
            (xObjectItem) => xObjectItem.icon_class != xAerodromeTarget,
          );
        }
      }
    } catch (error) {
      //TODO: TRANSLATION
      //TODO: MESSENGER
      //TODO: ERROR - LOG
      //TODO: SEVERITY
      this.messageService.add({
        severity: 'error',
        summary: 'Plano de Voo',
        detail: 'Exceção ao Criar o Plano de Voo',
      });
    }
  }

  /**
   * TODO: https://tsdoc.org/
   */
  private async _removeAerodromeData(xAerodromeTarget: string) {
    this.formFlightPlanRequest.controls[
      `aerodrome_${xAerodromeTarget}`
    ].controls.icao_code.setValue(null, { emitEvent: false }); // É preciso colocar o EmitEvento como falso para evitar de entrar em um looping infinito de mudanças realizadas no controle do formulário.
    this.formFlightPlanRequest.controls[
      `aerodrome_${xAerodromeTarget}`
    ].controls.name.setValue(null);
    this.formFlightPlanRequest.controls[
      `aerodrome_${xAerodromeTarget}`
    ].controls.coordinate_label.setValue(null);
    this.formFlightPlanRequest.controls[
      `aerodrome_${xAerodromeTarget}`
    ].controls.coordinate_dd.setValue(null);
    this.formFlightPlanRequest.controls[
      `aerodrome_${xAerodromeTarget}`
    ].controls.coordinate_dms.setValue(null);
    this.formFlightPlanRequest.controls[
      `aerodrome_${xAerodromeTarget}`
    ].controls.coordinate_plan.setValue(null);
    this.formFlightPlanRequest.controls[
      `aerodrome_${xAerodromeTarget}`
    ].controls.coordinate_geo.controls.location.controls.type.setValue('Point');
    this.formFlightPlanRequest.controls[
      `aerodrome_${xAerodromeTarget}`
    ].controls.coordinate_geo.controls.location.controls.coordinates.setValue(
      null,
    );

    // Remove o Elemento do Array que possui o Tipo de Aerodrome que não possui o Indicador ICAO com 4 letras....
    this.arrayAerodromesToMarkMap = this.arrayAerodromesToMarkMap.filter(
      (xObjectItem) => xObjectItem.aerodrome_target != xAerodromeTarget,
    );

    // Lembre-se: está sendo executado um OBSERVER, ou seja, ele é assíncrono e é difícil esperar o seu final para executar outro método.
    // Para que os aeródromos sejam plotados no MAPA é preciso "forçar" uma atualização do conteúdo para que seja pego pelo componente de mapa, via "INPUT PROPERTY SETTER"
    // Forçar no final da execução do OBSERVER
    this._forceReloadArrayAerodromesToMarkMapValue();
  }

  /**
   * TODO: https://tsdoc.org/
   */
  private _initFilterSearchRecordedAerodromeForm() {
    this.formFilterSearchRecordedAerodrome = new FormGroup({
      icao_code: new UntypedFormControl(null),
      aerodrome_type: new UntypedFormControl(null),
      name: new UntypedFormControl(null),
      country: new UntypedFormControl(null),
      state: new UntypedFormControl(null),
      city: new UntypedFormControl(null),
    });
  }



  /**
   * TODO: https://tsdoc.org/
   */
  private _initCopyToClipboardForm() {
    this.formCopyToClipboard! = new FormGroup({
      copiedMap: new FormControl<boolean>(false),
      copiedConformFlight: new FormControl<boolean>(false),
    });
  }

  /**
   * TODO: https://tsdoc.org/
   */
  private _initIntentionMessageForm() {
    this.formIntentionMessage! = new FormGroup({
      aircraft_mark: new FormControl<string>(''),
      command_id: new FormControl<string>(''),
      date_eobt: new FormControl<string>(''),
      time_eobt: new FormControl<string>(''),
      departure: new FormControl<string>(''),
      destination: new FormControl<string>(''),
      flight_rule: new FormControl<string>(''),
      flight_level: new FormControl<string>(''),
      frequencies: new FormControl<string>(''),
      refuel: new FormControl<string>(''),
      metar: new UntypedFormControl(null),
      taf: new UntypedFormControl(null),
    });
  }


  /**
   * TODO: https://tsdoc.org/
   */
  private _initFormFilterSearchLocationEvents() {
    this.formFilterSearchLocation.get('country_acronym').valueChanges.subscribe((xValue: any) => {
      // this.control.patchValue(this.control.value.toLowerCase());
      if (xValue) {
        // Verifica se existe valor.
        //console.log("COUNTRY: ", xValue);
      }
    });

    this.formFilterSearchLocation.get('state_acronym').valueChanges.subscribe((xValue: any) => {
      if (xValue) {
        // Verifica se existe valor.
        console.log('STATES:', xValue);

        try {
          // 1) RECUPERAR TODAS AS CIDADES DO ESTADO
          const fieldToSearchAllCitiesOfState = {
            state_acronym: {
              value: xValue,
              condition: '=',
            },
          };

          this.flightPlanApisService
            .getLocationCitiesByFields(fieldToSearchAllCitiesOfState)
            .subscribe({
              next: (xResponseService: IPatternResponseFromAPI) => {
                if (xResponseService.status_code == 200) {
                  const dataInfo = xResponseService.data_info.data;

                  this.arrayCitiesToMarkMap = []; // SEMPRE ZERAR OS DADOS A SEREM MOSTRADOS NO MAPA...
                  this.optionsMapCities = []; // SEMPRE ZERAR OS DADOS A SEREM MOSTRADOS NO DROPDOWN...

                  dataInfo.forEach((xElementCity: any) => {
                    // 2) Criar as Opções do DropDown.
                    this.optionsMapCities.push({
                      label: xElementCity.city_name,
                      value: xElementCity.city_name,
                    });

                    // 3) Recuperar a Cidade que é a Capital do Estado para Incluir no Mapa.
                    if (xElementCity.is_state_capital == true) {
                      this.arrayCitiesToMarkMap.push({
                        city_name: xElementCity.city_name,
                        icon_class: 'city',
                        coordinates_dd: xElementCity.coordinate_dd,
                        popup_legend: 'TESTE 02',
                        isStateCapital: xElementCity.is_state_capital,
                        isCountryCapital: xElementCity.is_country_capital,
                        marker_as_capital: true,
                      });
                    }
                  });

                  console.log('State', this.arrayCitiesToMarkMap);

                  //TODO: TRANSLATION
                  //TODO: MESSENGER
                  //TODO: TOAST
                  this.messageService.add({
                    severity: 'success',
                    summary: 'Recuperar Cidades',
                    detail: `Cidades do Estado ${xValue} Recuperadas com Sucesso.`,
                  });
                } else if (xResponseService.status_code == 404) {
                  //TODO: TRANSLATION
                  //TODO: MESSENGER
                  //TODO: TOAST
                  this.messageService.add({
                    severity: 'error',
                    summary: 'Recuperar Cidades',
                    detail: `Cidades do Estado ${xValue} NÃO existem.`,
                  });
                } else {
                  //TODO: TRANSLATION
                  //TODO: MESSENGER
                  //TODO: TOAST
                  this.messageService.add({
                    severity: 'error',
                    summary: 'Recuperar Cidade',
                    detail: `Erro ao Recuperar Cidades do Estado ${xValue}.`,
                  });
                }

                // Lembre-se: está sendo executado um OBSERVER, ou seja, ele é assíncrono e é difícil esperar o seu final para executar outro método.
                // Para que os aeródromos sejam plotados no MAPA é preciso "forçar" uma atualização do conteúdo para que seja pego pelo componente de mapa, via "INPUT PROPERTY SETTER"
                // Forçar no final da execução do OBSERVER
                //this._forceReloadArrayAerodromesToMarkMapValue();
              },
              complete: () => { },
              error: (xErrorService: any) => {
                this.messageService.add({
                  severity: 'error',
                  summary: 'Recuperar Aeródromo',
                  detail: 'ERROR',
                });
              },
            });
        } catch (error) {
          //TODO: TRANSLATION
          //TODO: MESSENGER
          //TODO: ERROR - LOG
          //TODO: SEVERITY
          this.messageService.add({
            severity: 'error',
            summary: 'Plano de Voo',
            detail: 'Exceção ao Criar o Plano de Voo',
          });
        }
      } else {
        this.arrayCitiesToMarkMap = []; // SEMPRE ZERAR OS DADOS A SEREM MOSTRADOS NO MAPA...
        this.optionsMapCities = []; // SEMPRE ZERAR OS DADOS A SEREM MOSTRADOS NO DROPDOWN...
      }
    });

    this.formFilterSearchLocation
      .get('city_name')
      .valueChanges.subscribe((xValue: any) => {
        if (xValue) {
          // Verifica se existe valor.
          // console.log("CITY - VALUE", xValue);
        }
      });

    this.formFilterSearchLocation
      .get('coordinate')
      .valueChanges.subscribe((xValue: any) => {
        if (xValue) {
          // Verifica se existe valor.
          // console.log("COORDENADA", xValue);
        }
      });
  }

  /**
   * TODO: https://tsdoc.org/
   */
  private async _getMetarTafToMessage(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      let dataReturned: any = null;

      const aerodromeToSearch: any = {
        aerodromes: [],
      };

      const departureIcao =
        this.formFlightPlanRequest.controls.aerodrome_departure.controls
          .icao_code.value;
      const destinationIcao =
        this.formFlightPlanRequest.controls.aerodrome_destination.controls
          .icao_code.value;
      const alt1Icao =
        this.formFlightPlanRequest.controls.aerodrome_alt_1.controls.icao_code
          .value;
      const alt2Icao =
        this.formFlightPlanRequest.controls.aerodrome_alt_2.controls.icao_code
          .value;

      if (
        departureIcao &&
        departureIcao.length == 4 &&
        departureIcao.toUpperCase() != 'ZZZZ'
      ) {
        aerodromeToSearch.aerodromes.push(departureIcao);
      }

      if (
        destinationIcao &&
        destinationIcao.length == 4 &&
        destinationIcao.toUpperCase() != 'ZZZZ'
      ) {
        aerodromeToSearch.aerodromes.push(destinationIcao);
      }

      if (
        alt1Icao &&
        alt1Icao.length == 4 &&
        alt1Icao.toUpperCase() != 'ZZZZ'
      ) {
        aerodromeToSearch.aerodromes.push(alt1Icao);
      }

      if (
        alt2Icao &&
        alt2Icao.length == 4 &&
        alt2Icao.toUpperCase() != 'ZZZZ'
      ) {
        aerodromeToSearch.aerodromes.push(alt2Icao);
      }

      await this.flightPlanApisService
        .getMetarTafAisweb(aerodromeToSearch)
        .subscribe({
          next: (xResponseService: IPatternResponseFromAPI) => {
            if (xResponseService.status_code == 200) {
              dataReturned = xResponseService.data_info;
            } else {
              this.messageService.add({
                severity: 'error',
                summary: 'Plano de Voo',
                detail: 'Erro ao Recuperar a Imagem do Satellite',
              });
            }
          },
          complete: () => {
            resolve(dataReturned);
          },
          error: (xErrorService: any) => {
            this.messageService.add({
              severity: 'error',
              summary: 'Plano de Voo',
              detail: 'ERROR',
            });
          },
        });
    });
  }
}
