import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserMenuService {

  constructor() { }

  getUserMenu(): Observable<any> {
    const userMenu = [
      {
        label: 'Home',
        icon: 'fa-solid fa-book',
      },
      {        
        label: 'Aircraft',
        icon: 'fa-solid fa-plane',
        items: [
          {
            label: 'Nova Aeronave',
            icon: 'fa-solid fa-file-circle-plus',
            routerLink: ["/aircraft/form"]
          },
          {
            label: 'Listar Aeronaves',
            icon: 'fa-solid fa-table-list',
            routerLink: ["/aircraft/list"]
          },
          {
            separator: true
          },
          {
            label: 'Dashboard',
            icon: 'fa-solid fa-gauge',
            routerLink: ["/flight-plan/list"]
          }

        ]
      },
      {
        label: 'Logbook',
        icon: 'fa-solid fa-book',
        items: [
          {
            label: 'Listar',
            icon: 'pi pi-fw pi-align-left',
            routerLink: ["/logbook/list"]
          },
          {
            label: 'Right',
            icon: 'pi pi-fw pi-align-right'
            ,
            routerLink: ["/logbook/form"]
          }
        ]
      },
      {
        label: 'Plano de Voo',
        icon: 'fa-solid fa-route',
        items: [
          {
            label: 'Novo',
            icon: 'fa-solid fa-file-circle-plus',
            routerLink: ["/flight-plan/form"]
          },
          {
            label: 'Listar',
            icon: 'fa-solid fa-table-list',
            routerLink: ["/flight-plan/list"]
          },
          {
            separator: true
          },
          {
            label: 'Dashboard',
            icon: 'fa-solid fa-gauge',
            routerLink: ["/flight-plan/list"]
          },
          {
            label: 'Flight Board',
            icon: 'fa-solid fa-chalkboard-user',
            routerLink: ["/flight-plan/list"]
          },
        ]
      }
    ]

    return of(userMenu);
  }
}
