import { Component, OnInit, Input, HostListener } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, ReactiveFormsModule } from '@angular/forms';

import { SliderModule } from 'primeng/slider';
import { TooltipModule } from 'primeng/tooltip';

import { ErrorMessageControlsComponent } from '../common/error-message-controls/error-message-controls.component';
import { hasControlErrors } from '../common/controls-methods';

//$sm	Breakpoint of screens such as phones.					           576px
//$md	Breakpoint of screens such as tablets.					         768px
//$lg	Breakpoint of screens such as notebook monitors.		     992px
//$xl	Breakpoint of smaller screens such as desktop monitors.	1200px

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'ui-primeng-slider',
  standalone: true,
  imports: [
    CommonModule,
    ErrorMessageControlsComponent,
    ReactiveFormsModule,
    SliderModule,
    TooltipModule
  ],
  templateUrl: './slider.component.html',
  styleUrl: './slider.component.scss',
})
export class SliderComponent implements OnInit {
  //#region "|--- INPUTS ---|"
  @Input() control!: FormControl;
  @Input() label!: string;
  @Input() idField!: string;
  @Input() placeHolder?: string;
  @Input() noLabelWidth?: string;
  @Input() isDisabled?: string;
  @Input() toolTip?: string;
  @Input() maxValue?: string;
  @Input() minValue?: string;
  @Input() stepValue?: string;
  @Input() labelValue?: string;
  @Input() posLabelValue?: string;
  //#region "|--- INPUTS ---|"

  //#region "|--- PROPERTIES ---|"
  innerWidth?: any;
  placeHolderContent?: string;

  showLabel = true;
  showLabelBold = false;
  showToolTipIcon = false;
  //#endregion

  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
    this.placeHolderContent = this.placeHolder;

    this._hasToShowLabelBold();
    this._getShowLabel();
    this._getIsDisabled();
    this._hasToShowToolTipIcon();
  }

  /**
   * (v1) - Executada toda vez que ocorre REDIMENSIONAMENTO da Tela.
   * https://www.w3schools.com/jsref/dom_obj_event.asp
   * @param event
   */
  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    this.innerWidth = window.innerWidth;

    this._getShowLabel();
  }

  /**
   * TODO: https://tsdoc.org/
   */
  getLabelValue(){
    if(this.labelValue){
      return this.labelValue;
    } else {
      return ""
    }
  }

  /**
   * TODO: https://tsdoc.org/
   */
  getPosLabelValue(){
    if(this.posLabelValue){
      return this.posLabelValue;
    } else {
      return ""
    }
  }

  /**
   * TODO: https://tsdoc.org/
   */
  getMaxValue() {
    if (this.maxValue) {
      return parseFloat(this.maxValue);
    } else {
      return 100;
    }
  }

  /**
   * TODO: https://tsdoc.org/
   */
  getMinxValue() {
    if (this.minValue) {
      return parseFloat(this.minValue);
    } else {
      return 0;
    }
  }

  /**
   * TODO: https://tsdoc.org/
   */
  getStepValue() {
    if (this.stepValue) {
      return parseFloat(this.stepValue);
    } else {
      return 1;
    }
  }

  /**
   * TODO: https://tsdoc.org/
   */
  getFieldName() {
    return this.idField.replace("fid-", "");
  }

  /**
   * TODO: https://tsdoc.org/
   */
  getShowLabelBold(): boolean {
    return this.showLabelBold;
  }

  /**
   * TODO: https://tsdoc.org/
   */
  getInputClass(): string {
    //let tmpReturn = "text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full";
    let tmpReturn = "w-full";

    return tmpReturn
  }

  /**
  * TODO: https://tsdoc.org/
  */
  private _hasToShowLabelBold(): void {
    if (this.label != undefined) {
      if (this.label.indexOf("#BOLD#") > 0) {
        this.showLabelBold = true;
        this.label = this.label.replace("#BOLD#", "").trim();
      }
    }
  }

  /**
   * TODO: https://tsdoc.org/
   */
  private _getShowLabel(): void {
    if (this.noLabelWidth != undefined && this.noLabelWidth != "") {
      if (this.innerWidth < Number(this.noLabelWidth)) {
        this.showLabel = false;
        this.showToolTipIcon = false;
        this.placeHolderContent = this.label;
      } else {
        this.showLabel = true;
        this._hasToShowToolTipIcon();

        if (this.placeHolder) {
          this.placeHolderContent = this.placeHolder;
        } else {
          this.placeHolderContent = "";
        }
      }
    }
  }

  /**
   * TODO: https://tsdoc.org/
   */
  getTooTipContent(): string {
    if (this.toolTip != undefined && this.toolTip != "") {
      return this.toolTip;
    } else {
      return "";
    }
  }

  /**
   * TODO: https://tsdoc.org/
   */
  _hasToShowToolTipIcon(): void {
    if (this.toolTip != undefined && this.toolTip != "") {
      this.showToolTipIcon = true;
    }
  }

  /**
  * TODO: https://tsdoc.org/
  */
  private _getIsDisabled(): void {
    if (this.isDisabled != undefined && this.isDisabled != "") {
      if (this.isDisabled == "true") {
        this.control.disable();
      } else {
        this.control.enable();
      }
    } else {
      this.control.enable();
    }
  }

  /**
  * TODO: https://tsdoc.org/
  */
  showErrors() {
    return hasControlErrors(this.control, "input");
  }
}
