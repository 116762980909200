//#region "|--- INTERFACE ---|"
import { IDropDownBasicOptions } from '../../interfaces/IDropDownBasicOptions';
//#endregion

/**
 * TODO: https://tsdoc.org/
 */
export const flightRulesOptions:IDropDownBasicOptions[] =  [
    { label: 'VFR DIURNO', value: 'vfr_day' },
    { label: 'VFR NOTURNO', value: 'vfr_night' },
    { label: 'IFR', value: 'ifr_vfr' },
]