//#region "|--- IMPORT MODULES/PACKAGES ---|"
import { Component, OnInit, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

import { settingConfig } from '@nashville-config';

import { PrimengComponentsModule } from '@nashville-primeng';

import { weatherCategoriesOptions } from 'src/app/helpers/dropdown-static-options/weatherCategoriesOptions';
import { riskYesNotApplicableOptions } from 'src/app/helpers/dropdown-static-options/riskYesNotApplicableOptions';
//#endregion

//#region "|--- OPTIONS ---|"
import { sortResultObstaclesOptions } from 'src/app/helpers/dropdown-static-options/sortResultObstaclesOptions';
import { riskDegreeOptions } from 'src/app/helpers/dropdown-static-options/riskDegreeOptions';
import { riskAttentionOptions } from 'src/app/helpers/dropdown-static-options/riskAttentionOptions';
//#endregion

//#region "|--- INTERFACE ---|"
import { IDropDownBasicOptions } from '../../../../../../interfaces/IDropDownBasicOptions';
//#endregion

@Component({
  selector: 'nashville-panel-top-risk-data',
  standalone: true,
  imports: [
    CommonModule,
    PrimengComponentsModule
  ],
  templateUrl: './panel-top-risk-data.component.html',
  styleUrl: './panel-top-risk-data.component.scss',
})
export class PanelTopRiskDataComponent implements OnInit {
  //#region "|--- INPUTS ---|"
  @Input() formComponent!: any;  // Recebe o Formulário: REQUEST-FORM
  @Input() formTopRisk!: any;  // Recebe o Formulário: REQUEST-FORM

  // É feito o set do @Input para que seja capturado qualquer modificação, em tempo real, 
  // do parâmetro que está sendo enviado pelo PARENT e, principalmente, que seja feito o 
  // processamento necessário com base no novo valor.
  @Input() set pageInnerWidth(xValue: any) {
    this._innerWidth = xValue;
  };
  //#endregion

  //#region "|--- PROPERTIES---|" 
  _innerWidth!: any;

  optionsObstaclesSortResult!: IDropDownBasicOptions[];
  optionsWeatherCategories!: any;
  optionsRiskYesNotApplicable!: any
  optionsRiskDegree!: any
  optionsRiskAttention!: any

  settingConfig!: any;
  //#endregion

  ngOnInit(): void {
    this._initVariables();
  }

  /**
   * TODO: https://tsdoc.org/
   */
  private _initVariables(): void {
    this.settingConfig = settingConfig;

    this.optionsObstaclesSortResult = sortResultObstaclesOptions;
    this.optionsWeatherCategories = weatherCategoriesOptions;
    this.optionsRiskYesNotApplicable = riskYesNotApplicableOptions;
    this.optionsRiskDegree = riskDegreeOptions;
    this.optionsRiskAttention = riskAttentionOptions;

    this._initEventsFlightPlanForm();
  }

  private _initEventsFlightPlanForm() {
    this.formComponent.get('filters.obstacles.itens.all').valueChanges.subscribe((xValue: any) => {
      // this.control.patchValue(this.control.value.toLowerCase()); 
      if (xValue) { // Verifica se existe valor.             
        for (let tempProperty in this.formComponent.controls.filters.controls.obstacles.controls.itens.controls) {
          if (this.formComponent.controls.filters.controls.obstacles.controls.itens.controls.hasOwnProperty(tempProperty)) {
            if (tempProperty != "all") {
              this.formComponent.controls.filters.controls.obstacles.controls.itens.controls[tempProperty].setValue(true);
            }
          }
        }
      } else {
        for (let tempProperty in this.formComponent.controls.filters.controls.obstacles.controls.itens.controls) {
          if (tempProperty != "all") {
            this.formComponent.controls.filters.controls.obstacles.controls.itens.controls[tempProperty].setValue(false);
          }
        }
      }
    });
  }
}
