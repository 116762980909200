import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'frame-login-basic-01',
  standalone: true,
  imports: [
    CommonModule, 
    RouterModule
  ],
  templateUrl: './login-basic-01.component.html',
  styleUrl: './login-basic-01.component.scss',
})
export class LoginBasic01Component {}
