import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SocketIoHandlerService } from 'src/app/services/socket-io-handler/socket-io-handler.service';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'nashville-socketio',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './socketio.component.html',
  styleUrl: './socketio.component.scss',
})
export class SocketioComponent implements OnInit {
  messages: string[] = [];

  formChatContent!: any;

  constructor(private chatService: SocketIoHandlerService) { }

  ngOnInit(): void {
    this._initChatContentForm();

    this.chatService.getMessages().subscribe((user: string, message: string) => {
      const tempUser = user;
      this.messages.push(message);
    });
  }

  sendMessage() {
    this.chatService.sendMessage("TESTE");
  }

  private _initChatContentForm() {    
    this.formChatContent = new FormGroup({
      chat_message: new FormControl<string>("")
    });
  }
}
