/**
 * TODO: https://tsdoc.org/
 */
export const TYPE_DOCUMENT = {
    COMPLETE_EXECUTIVE: "complete_executive",
    COMPLETE_COMPANY: "complete_company",
    CARD_AERODROME: "card_aerodrome",
    CARD_TOP_RISK: "card_top_risk",
    CARD_FREQUENCY: "card_frequency",
    CARD_METEOROLOGY: "card_meteorology",
    CARD_NOTAM: "card_notam"
}
