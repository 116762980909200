import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'nashville-ftd-web-client-portal-angular-checklist-list',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './checklist-list.component.html',
  styleUrl: './checklist-list.component.scss',
})
export class ChecklistListComponent {}
