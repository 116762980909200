<section
  id="id-toolbar-document-pdf-form"
  class="cls-component-toolbar-control-form">
  <p-toolbar class="cls-component-toolbar-control-form-pattern">
    <div class="p-toolbar-group-start">
      <div class="ml-4">
        
      </div>      
    </div>
    <div class="p-toolbar-group-center"></div>
    <div class="p-toolbar-group-end">
    <div class="cls-page-box-right">
      <p-button
          label="GERAR PDF"
          icon="fa-solid fa-file-pdf"
          [raised]="true"
          severity="secondary"
          (click)="menuGerarPdf.toggle($event)"
          class="cls-component-button-pattern-default"
        >        
        </p-button>
        <p-tieredMenu #menuGerarPdf [model]="this.itemsSubMenuNew" [popup]="true" />
      </div>
    </div>
  </p-toolbar>
</section>

