import { Component, OnInit, Input, HostListener } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, ReactiveFormsModule } from '@angular/forms';

import { TooltipModule } from 'primeng/tooltip';
import { CheckboxModule } from 'primeng/checkbox';

import { ErrorMessageControlsComponent } from '../common/error-message-controls/error-message-controls.component';
import { hasControlErrors } from '../common/controls-methods';

//$sm	Breakpoint of screens such as phones.					           576px
//$md	Breakpoint of screens such as tablets.					         768px
//$lg	Breakpoint of screens such as notebook monitors.		     992px
//$xl	Breakpoint of smaller screens such as desktop monitors.	1200px

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'ui-primeng-checkbox',
  standalone: true,
  imports: [
    ErrorMessageControlsComponent,
    ReactiveFormsModule,
    CommonModule,
    TooltipModule,
    CheckboxModule
  ],
  templateUrl: './checkbox.component.html',
  styleUrl: './checkbox.component.scss',
})
export class CheckboxComponent implements OnInit {
  //#region "|--- INPUTS ---|"
  @Input() control!: FormControl;
  @Input() label!: string;
  @Input() idField!: string;
  @Input() initialValue!: string;
  //#region "|--- INPUTS ---|"

  //#region "|--- PROPERTIES ---|"
  innerWidth?: any;
  //#endregion

  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
  }  
}
