import { Component } from '@angular/core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'common-components-top-header-02',
  standalone: true,
  imports: [],
  templateUrl: './top-header-02.component.html',
  styleUrl: './top-header-02.component.scss'
})
export class TopHeader02Component {

}
