//#region "|--- IMPORT MODULES/PACKAGES ---|"
// ***** ANGULAR *****
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// ***** RXJS *****
import { Observable } from 'rxjs';

// ***** NASHVILLE *****
import { settingConfig } from '@nashville-config';
//#endregion

//#region "|--- IMPORT INTERFACES ---|"
import { IPatternResponseFromAPI } from "../../../interfaces/IPatternCrudResponseFromAPI";
//#endregion

@Injectable({
  providedIn: 'root'
})
export class FlightPlanApisService {
  //#region "|--- PROPERTIES---|"  
  urlFlightPlanService: any;
  //#endregion

  constructor(private http: HttpClient) {
    if (settingConfig.API_FLIGHT_PLAN_PORT == "80") {
      this.urlFlightPlanService = `${settingConfig.API_PROTOCOL}://${settingConfig.API_HOST}/${settingConfig.API_PATH}`;
    } else {
      this.urlFlightPlanService = `${settingConfig.API_PROTOCOL}://${settingConfig.API_HOST}:${settingConfig.API_FLIGHT_PLAN_PORT}/${settingConfig.API_PATH}`;
    }
  }

  //#region "|--- LIGHT REQUEST ---|" 
  /**
   * @type METHOD
   * @version 1.0.0 (GASPAR - 21/06/2024).
  
   * 
   * @description 
   * - Salva os dados da Requisição de voo. 
   * 
   * @param xFlightRequest 
   * - Dados de da Requisição de Voo
   * @returns 
   * 
   */
  saveFlightRequest(xFlightRequest: any): Observable<IPatternResponseFromAPI> {
    return this.http.post<any>(`${this.urlFlightPlanService}/mod_fp/flight/request`, xFlightRequest);
  }

  /**
   * @type METHOD
   * @version 1.0.0 (GASPAR - 27/06/2024).
  
   * 
   * @description 
   * - Inicia o Atendimento ligado a requisição de voo.
   * 
   * @param xFlightRequest 
   * - Dados de da Requisição de Voo
   * @returns 
   */
  startRequestService(xFlightRequest: any): Observable<IPatternResponseFromAPI> {
    return this.http.post<any>(`${this.urlFlightPlanService}/mod_fp/flight/request/start/service`, xFlightRequest);
  }

  /**
   * @type METHOD
   * @version 1.0.0 (GASPAR - 28/06/2024).
  
   * 
   * @description 
   * - Geração da documentação completa, sendo que o modelo que será gerado está no parâmetro de chamada.
   * 
   * @param xPdfGenerateParameters 
   * - Dados para a geração do Documento Completo:
   *   - flight_request_id: ID da requisição de Voo que será base para geração da documentação;
   *   - type_document: tipo de documento a ser gerado.
   * @returns 
   */
  generateCompleteDocumentationPdfService(xPdfGenerateParameters: any): Observable<IPatternResponseFromAPI> {
    return this.http.post<any>(`${this.urlFlightPlanService}/mod_fp/documentation/generate/complete/pdf`, xPdfGenerateParameters);
  }

  generateCardPdfService(xPdfGenerateParameters: any): Observable<IPatternResponseFromAPI> {
    return this.http.post<any>(`${this.urlFlightPlanService}/mod_fp/card/generate/pdf`, xPdfGenerateParameters);
  }


  /**
   * @type METHOD
   * @version 1.0.0 (GASPAR - 28/06/2024).
  
   * 
   * @description 
   * - Recupera os dados de um plano de voo com base no ID
   * 
   * @param xFlightPlanData 
   * - ID do Plano de Voo.
   *
   * @returns 
   */
  getFlightPlanDataById(xFlightPlanData: string) {
    return this.http.get<any>(`${this.urlFlightPlanService}/mod_fp/flight/plan/data/${xFlightPlanData}`);
  }

  /**
   * @type METHOD
   * @version 1.0.0 (GASPAR - 30/06/2024).
  
   * 
   * @description 
   * - Faz a Atualização do Conteúdo dos dados de do Plano de Voo, buscando na fonte.
   * 
   * @param xFlightPlanData 
   * - Objeto contendo dados para atualização dos Dados do Plano.
   *
   * @returns 
   */
  renewFlightPlanDataContent(xObjParameters: any) {
    return this.http.post<any>(`${this.urlFlightPlanService}/mod_fp/flight/plan/data/renew/content`, xObjParameters);
  }

/**
 * @type METHOD
 * @version 1.0.0 (GASPAR - 30/06/2024).

 * 
 * @description 
 * - Faz a Atualização do Conteúdo dos dados de do Plano de Voo, com base no que é passado para ele.
 * 
 * @param xFlightPlanData 
 * - Objeto contendo dados para atualização dos Dados do Plano.
 *
 * @returns 
 */
  updateFlightPlanDataContent(xFlightPlanDataParameters: any) {
    return this.http.put<any>(`${this.urlFlightPlanService}/mod_fp/flight/plan/data/update/content/${xFlightPlanDataParameters.flight_plan_data_id}`, xFlightPlanDataParameters);
  }

   /**
   * TODO: https://tsdoc.org/
   */
   getAllAircraftRequest(): Observable<any> {
    return this.http.get<any>(`${this.urlFlightPlanService}/mod_fp/flight/plan/aircraft`);
  } 

  //#endregion














  //#region "|--- DOCUMENT ---|"
  generateDocumentPdfService(xDocumentRequisition: any): Observable<IPatternResponseFromAPI> {
    return this.http.post<any>(`${this.urlFlightPlanService}/mod_fp/documentation/generate/pdf`, xDocumentRequisition);
  }




  downloadDocumentPdfService(xFileName: string, xAircraftMark: string, xFlightRequestCode: string): Observable<IPatternResponseFromAPI> {
    return this.http.get<any>(`${this.urlFlightPlanService}/mod_fp/documentation/generate/pdf?file_name=${xFileName}&aircraft_mark=${xAircraftMark}&flight_request_code=${xFlightRequestCode}`);
  }
  //#endregion

  //#region "|--- QUICK ACCESS ---|"
  /**
   * TODO: https://tsdoc.org/
   */
  getMetarTafAisweb(xAerodromesIcao: any) {
    return this.http.post<any>(`${this.urlFlightPlanService}/mod_fp/meteorology/data/metar/taf/aisweb`, xAerodromesIcao);
  }
  //#endregion

  //#region "|--- CONFIG ---|"
  /**
   * TODO: https://tsdoc.org/
   */
  getServerDataTimeUTC() {
    return this.http.get<any>(`${this.urlFlightPlanService}/mod_fp/flight/plan/data/time/utc`);
  }
  //#endregion

  //#region "|--- MAPS ---|"
  /**
   * TODO: https://tsdoc.org/
   */
  getUrlOnlineImageSatellite(xTypeImage: string) {
    return this.http.get<any>(`${this.urlFlightPlanService}/mod_fp/meteorology/data/online/url/satellite/${xTypeImage}`);
  }

  /** 
   * TODO: https://tsdoc.org/
   */
  getUrlServerImageSatellite(xTypeImage: string) {
    return this.http.get<any>(`${this.urlFlightPlanService}/mod_fp/meteorology/data/server/url/satellite/${xTypeImage}`);
  }
  //#endregion
















  // --------------------------------------------------------------------------------------------


  //#region "|--- KAFKA SERVICE ---|"
  /**
   * TODO: https://tsdoc.org/
   */
  getKafkaServiceDataById(xKafkaServiceId: string): Observable<any> {
    return this.http.get<any>(`${this.urlFlightPlanService}/mod_fp/kafka/process/${xKafkaServiceId}`);
  }
  //#endregion

  //#region "|--- AERODROME BASIC ---|"
  /**
   * TODO: https://tsdoc.org/
   */
  getAerodromeBasicDataByAerodromeIcao(xAerodromeIcao: string, xForceReturn: string = null): Observable<any> {
    return this.http.get<any>(`${this.urlFlightPlanService}/mod_fp/aux/aerodrome/basic/search/${xAerodromeIcao}`);
  }

  /**
   * TODO: https://tsdoc.org/
   */
  getAerodromeBasicDataSuggestionStart(xFieldToAerodromeSuggestion: any) {
    return this.http.post<any>(`${this.urlFlightPlanService}/mod_fp/aux/aerodrome/basic/start`, xFieldToAerodromeSuggestion);
  }

  /**
   * TODO: https://tsdoc.org/
   */
  getAerodromeBasicSuggestionContains(xFieldToAerodromeSuggestion: any) {
    return this.http.post<any>(`${this.urlFlightPlanService}/mod_fp/aux/aerodrome/basic/contains`, xFieldToAerodromeSuggestion);
  }

  /**
   * TODO: https://tsdoc.org/
   */
  getAerodromeBasicSuggestionNear(xFieldToAerodromeSuggestion: any) {
    return this.http.post<any>(`${this.urlFlightPlanService}/mod_fp/aux/aerodrome/basic/near`, xFieldToAerodromeSuggestion);
  }
  //#endregion



  //#region "|--- AERODROME COMPLETE ---|"
  /**
   * TODO: https://tsdoc.org/
   */
  getAerodromeCompleteDataByAerodromeIcao(xAerodromeIcao: string, xForceReturn: string = null): Observable<any> {
    return this.http.get<any>(`${this.urlFlightPlanService}/mod_fp/aux/aerodrome/complete/search/${xAerodromeIcao}`);
  }
  //#endregion

  //#region "|--- FFFFFFFLIGHT REQUEST ---|" 

  //#endregion








  /**
   * TODO: https://tsdoc.org/
   */
  updateFlightRequest(xFlightPlan: any, xFlightPlanId: string): Observable<IPatternResponseFromAPI> {
    return this.http.put<any>(`${this.urlFlightPlanService}/mod_fp/flight/request/${xFlightPlanId}`, xFlightPlan);
  }

  /**
   * TODO: https://tsdoc.org/
   */
  getAllFlightRequest(): Observable<any> {
    return this.http.get<any>(`${this.urlFlightPlanService}/mod_fp/flight/request`);
  }

  /**
   * TODO: https://tsdoc.org/
   */
  getFlightRequestById(xFlightPlanId: string): Observable<any> {
    return this.http.get<any>(`${this.urlFlightPlanService}/mod_fp/flight/request/${xFlightPlanId}`);
  }

  //#region "|--- FLIGHT REQUEST ---|" 


  //#region "|--- FLIGHT REQUEST ---|" 


  /**
   * TODO: https://tsdoc.org/
   */
  generateCompleteCardPdfService(xFlightRequisition: any): Observable<IPatternResponseFromAPI> {
    return this.http.post<any>(`${this.urlFlightPlanService}/mod_fp/documentation/generate/card/pdf`, xFlightRequisition);
  }
  //#endregion


  //#region "|--- METEOROLOGY ---|" 


  //#endregion











  //#region "|--- OBSTACLES ---|"
  /**
   * TODO: https://tsdoc.org/
   */
  getAerodromeObstaclesDataByAerodromeIcao(xAerodromeIcao: string, xForceReturn: string = null): Observable<any> {
    return this.http.get<any>(`${this.urlFlightPlanService}/mod_fp/aerodrome/obstacles/search/${xAerodromeIcao}`);
  }
  //#endregion

  //#region "|--- NOTAM ---|"
  /**
   * TODO: https://tsdoc.org/
   */
  getAerodromeNotamOnlineByAerodromeIcao(xAerodromeIcao: string, xForceReturn: string = null): Observable<any> {
    return this.http.get<any>(`${this.urlFlightPlanService}/mod_fp/aerodrome/notam/online/${xAerodromeIcao}`);
  }
  //#endregion

  //#region "|--- METEOROLOGY ---|"
  /**
   * TODO: https://tsdoc.org/
   */
  getAerodromeMeteorologyOnlineByAerodromeIcao(xAerodromeIcao: string, xForceReturn: string = null): Observable<any> {
    return this.http.get<any>(`${this.urlFlightPlanService}/mod_fp/aerodrome/meteorology/online/${xAerodromeIcao}`);
  }



  /**
   * TODO: https://tsdoc.org/
   */
  getCurrentSigmetData() {
    return this.http.get<any>(`${settingConfig.API_PROTOCOL}://${settingConfig.API_HOST}:${settingConfig.API_FLIGHT_PLAN_PORT}/${settingConfig.API_PATH}/mod_fp/meteorology/data/current/sigmet`);
  }

  /**
   * TODO: https://tsdoc.org/
   */
  getCurrentAerodromeStatusData() {
    return this.http.get<any>(`${settingConfig.API_PROTOCOL}://${settingConfig.API_HOST}:${settingConfig.API_FLIGHT_PLAN_PORT}/${settingConfig.API_PATH}/mod_fp/meteorology/data/current/aerodrome/status`);
  }
  //#endregion





















  /**
   * TODO: https://tsdoc.org/
   */
  getAllAerodromeBasicData(): Observable<any> {
    return this.http.get<any>(`${this.urlFlightPlanService}/aux/aerodrome/basic/search`);
  }

  /**
   * TODO: https://tsdoc.org/
   */
  getAerodromeBasicDataById(xAerodromeIcaoCode: string): Observable<any> {
    return this.http.get<any>(`${this.urlFlightPlanService}/aux/aerodrome/basic/search/${xAerodromeIcaoCode}`);
  }

  /**
   * TODO: https://tsdoc.org/
   */
  getAerodromeBasicDataByFields(xAerodromeFields: any): Observable<any> {
    return this.http.post<any>(`${this.urlFlightPlanService}/aux/aerodrome/basic/search`, xAerodromeFields);
  }









  //#endregion









  getAllAircraft() {

  }





  /**
   * TODO: https://tsdoc.org/
   */
  saveImageSatelliteRealcadaAisweb() {
    return this.http.get<any>(`${settingConfig.API_PROTOCOL}://${settingConfig.API_HOST}:${settingConfig.API_FLIGHT_PLAN_PORT}/${settingConfig.API_PATH}/mod_fp/meteorology/data/save/image/satellite/realcada/aisweb`);
  }



  /**
   * TODO: https://tsdoc.org/
   */
  getLocationCitiesByFields(xCityFields: any): Observable<any> {
    return this.http.post<any>(`${settingConfig.API_PROTOCOL}://${settingConfig.API_HOST}:${settingConfig.API_FLIGHT_PLAN_PORT}/${settingConfig.API_PATH}/aid/location/city/data/search`, xCityFields);
  }
}
