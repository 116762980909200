//#region "|--- IMPORT MODULES/PACKAGES ---|"
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PrimengComponentsModule } from '@nashville-primeng';

import { MenuItem } from 'primeng/api';
//#endregion

//#region "|--- ENUNS ---|"
import { BUTTON_ACTION } from '../../../../../../helpers/enum/ButtonActions';
//#endregion

@Component({
  selector: 'nashville-toolbar-form-flight-request',
  standalone: true,
  imports: [
    CommonModule,
    PrimengComponentsModule
  ],
  templateUrl: './toolbar-form-flight-request.component.html',
  styleUrl: './toolbar-form-flight-request.component.scss',
})
export class ToolbarFormFlightRequestComponent implements OnInit { 
  //#region "|--- INPUTS ---|"
  @Input() formComponent!: any;
  @Input() pageInnerWidth!: any;
  //#endregion

  //#region "|--- OUTPUTS ---|"
  @Output() toolbarButtonAction = new EventEmitter<string>();
  //#endregion

  //#region "|--- PROPERTIES---|"
  itemsSubMenuNew!: MenuItem[];

  labelActionsButtons: any;
  //#endregion

  /**
   * TODO: https://tsdoc.org/
   */
  ngOnInit(): void {
    this._initVariables();
  }

  //#region "|--- PUBLIC METHODS---|" 
  /**
   * TODO: https://tsdoc.org/
   */
  callbackButton(xValue: string) {
    this.toolbarButtonAction.emit(xValue);
  }
  //#endregion

  //#region "|--- PRIVATE METHODS---|" 
  /**
   * TODO: https://tsdoc.org/
   */
  private _initVariables() {
    this.labelActionsButtons = BUTTON_ACTION;

    this.itemsSubMenuNew = [
      {
        label: 'Voo de Volta',
        icon: 'fa-solid fa-arrow-right-arrow-left',
        command: () => {
          this.callbackButton(this.labelActionsButtons.NEW_RETURN);
        }
      },
      {
        label: 'Clonar',
        icon: 'fa-regular fa-clone',
        command: () => {
          this.callbackButton(this.labelActionsButtons.NEW_CLONE);
        }
      }
    ];
  }
  //#endregion
}
