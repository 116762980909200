/**
 * TODO: https://tsdoc.org/
 */
export const SCREEN_SIZES = {
    SM: 576,
    MD: 768,
    LG: 992,
    XL: 1200
}


//$sm	Breakpoint of screens such as phones.					 576px
//$md	Breakpoint of screens such as tablets.					 768px
//$lg	Breakpoint of screens such as notebook monitors.		 992px
//$xl	Breakpoint of smaller screens such as desktop monitors.	1200px