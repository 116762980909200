import { NgModule } from '@angular/core';

//#region "|--- IMPORT PRIMENG ---|"
import { AvatarModule } from 'primeng/avatar';
import { AvatarGroupModule } from 'primeng/avatargroup';
import { AccordionModule } from 'primeng/accordion';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { BadgeModule } from 'primeng/badge';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { CheckboxModule } from 'primeng/checkbox';
import { ChipsModule } from 'primeng/chips';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { FileUploadModule } from 'primeng/fileupload';
import { FieldsetModule } from 'primeng/fieldset';
import { InputSwitchModule } from 'primeng/inputswitch';
import { ImageModule } from 'primeng/image';
import { IconFieldModule } from 'primeng/iconfield';
import { InputIconModule } from 'primeng/inputicon';
import { MessagesModule } from 'primeng/messages';
import { PanelModule } from 'primeng/panel';
import { PasswordModule } from 'primeng/password';
import { ProgressBarModule } from 'primeng/progressbar';
import { RadioButtonModule } from 'primeng/radiobutton';
import { SliderModule } from 'primeng/slider';
import { SpeedDialModule } from 'primeng/speeddial';
import { SidebarModule } from 'primeng/sidebar';
import { SplitButtonModule } from 'primeng/splitbutton';
import { ScrollTopModule } from 'primeng/scrolltop';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { ToastModule } from 'primeng/toast';
import { ToolbarModule } from 'primeng/toolbar';
import { ToggleButtonModule } from 'primeng/togglebutton';
//#endregion

import { AutoComplementDropdownComponent } from './form/auto-complement-dropdown/auto-complement-dropdown.component';
import { AutoComplementInputTextButtonComponent } from './form/auto-complement-input-text-button/auto-complement-input-text-button.component';
import { CalendarComponent } from './form/calendar/calendar.component'; 
import { ColorPickerComponent } from './form/color-picker/color-picker.component';
import { CountdownClockFlipComponent } from './form/countdown-clock-flip/countdown-clock-flip.component';
import { CheckboxComponent } from './form/checkbox/checkbox.component';
import { ChipsComponent } from './form/chips/chips.component';
import { DropdownComponent } from './form/dropdown/dropdown.component';
import { DropdownTemplateColorComponent } from './form/dropdown-template-color/dropdown-template-color.component.component';
import { DropdownTemplateFlagComponent } from './form/dropdown-template-flag/dropdown-template-flag.component.component';
import { DropdownTemplateIconComponent } from './form/dropdown-template-icon/dropdown-template-icon.component.component';
import { DropdownTemplateDdiComponent } from './form/dropdown-template-ddi/dropdown-template-ddi.component.component';
import { InputSwitchComponent } from './form/input-switch/input-switch.component';
import { InputPasswordComponent } from './form/input-password/input-password.component';
import { InputTextComponent } from './form/input-text/input-text.component';
import { LabelComponent } from './form/label/label.component';
import { SliderComponent } from './form/slider/slider.component';
import { TextAreaComponent } from './form/text-area/text-area.component';
import { UploadFileComponent } from './form/upload-file/upload-file.component';

const UX_MODULE = [
  AutoCompleteModule,
  AvatarModule,
  AvatarGroupModule,
  AccordionModule,
  BadgeModule,
  ButtonModule,
  CardModule,
  CheckboxModule,
  ChipsModule,
  ConfirmDialogModule,
  FileUploadModule,
  FieldsetModule,
  InputSwitchModule,  
  IconFieldModule,
  InputIconModule,
  ImageModule,
  MessagesModule,
  DialogModule,
  PanelModule,
  PasswordModule,
  ProgressBarModule,
  RadioButtonModule,
  SliderModule,
  SpeedDialModule,
  SidebarModule,
  SplitButtonModule,
  ScrollTopModule,
  TableModule,
  TabViewModule,
  TieredMenuModule,
  ToastModule,
  ToolbarModule,
  ToggleButtonModule
];

@NgModule({
  declarations: [

  ],
  imports: [
    AutoComplementDropdownComponent,
    AutoComplementInputTextButtonComponent,
    CalendarComponent,
    ColorPickerComponent,
    CountdownClockFlipComponent,
    CheckboxComponent,
    ChipsComponent,
    DropdownComponent,
    DropdownTemplateColorComponent,
    DropdownTemplateFlagComponent,
    DropdownTemplateIconComponent,
    DropdownTemplateDdiComponent,
    InputSwitchComponent,
    InputTextComponent,
    InputPasswordComponent,
    LabelComponent,
    SliderComponent,
    TextAreaComponent,  
    UploadFileComponent,  
    ...UX_MODULE
  ],
  exports: [
    AutoComplementDropdownComponent,
    AutoComplementInputTextButtonComponent,
    CalendarComponent,
    ColorPickerComponent,
    CountdownClockFlipComponent,
    CheckboxComponent,
    ChipsComponent,
    DropdownComponent,
    DropdownTemplateColorComponent,
    DropdownTemplateFlagComponent,
    DropdownTemplateIconComponent,
    DropdownTemplateDdiComponent,
    InputSwitchComponent,
    InputTextComponent,
    InputPasswordComponent,
    LabelComponent,
    SliderComponent,
    TextAreaComponent,
    UploadFileComponent,
    ...UX_MODULE
  ]
})
export class PrimengComponentsModule { }
