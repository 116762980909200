<article id="id-aircraft-data-list" class="cls-page-frame">
    <!-- TOAST-->
    <nashville-page-toast [objToastMessage]="this.objToastMessage"></nashville-page-toast>
    <!-- TITLE -->
    <nashville-page-title pageTitle="{{this.currentPageTitle}}"></nashville-page-title>
    <!-- CONTENT -->
    <div class="cls-page-frame-content">
        <!-- TOOLBAR -->
        <nashville-toolbar-table-aircraft-data-list (toolbarAction)="callBackToolbarFormList($event)">
        </nashville-toolbar-table-aircraft-data-list>
        <div class="cls-component-table-page-list">
            <!-- FILTER -->
            <!--<p-accordion>
                <p-accordionTab iconPos="end">
                    <ng-template pTemplate="header">
                        <div class="cls-component-table-page-list-filter-title">
                            <span><i class="fa-solid fa-filter"></i> FILTRO DE PESQUISA</span>
                        </div>
                    </ng-template>
                    <ng-template pTemplate="content">
                        <p>
                            Lorem ipsum dolor sit amet...
                        </p>
                    </ng-template>
                </p-accordionTab>
            </p-accordion>-->
            <!-- TABLE -->
            <p-table 
                #dt1 
                [value]="this.tableAircraft" 
                [lazy]="true" 
                [loading]="loading"
                [tableStyle]="{ 'max-width': '100%' }" 
                [breakpoint]="'{{this.settingConfig.TABLE_STACK}}'" 
                [rows]="10"
                [rowsPerPageOptions]="[10, 15, 20]" 
                [paginator]="true" [customSort]="true"
                [globalFilterFields]="['aero_registration.mark']"
                [totalRecords]="totalRecords" 
                [scrollable]="false" 
                [resizableColumns]="false"
                (onLazyLoad)="loadAircraft($event)" 
                scrollHeight="500px" 
                styleClass="p-datatable-sm p-datatable-striped p-datatable-gridlines">
                <ng-template pTemplate="caption">
                    <div class="flex align-items-center justify-content-between">
                        <div class="cls-component-table-page-list-table-title">
                            <!--<span><i class="fa-solid fa-table"></i> AERONAVES CADASTRADAS </span>-->
                        </div>
                        <div class="flex">
                            <p-iconField iconPosition="left" class="ml-auto">
                                <!--<p-inputIcon>
                                    <i class="fa-solid fa-magnifying-glass"></i>
                                </p-inputIcon>
                                <input 
                                    [formControl]="this.formFilter.controls.keyword_filter"
                                    type="text" 
                                    (input)="dt1.filterGlobal(getValueFilter($event), 'contains')"
                                    placeholder="Procurar conteúdo" 
                                    pInputText />-->
                            </p-iconField>
                        </div>
                    </div>
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <th style="width:11%; text-align:center">FOTO</th>
                        <th style="width:8%; text-align:center">
                            TIPO
                        </th>
                        <th style="width:8%; text-align:center">
                            MATRÍCULA
                        </th>
                        <th style="width:8%; text-align:center">
                            MODELO ICAO
                        </th>
                        <th style="width:27%; text-align:left">
                            PROPRIETÁRIO
                        </th>
                        <th style="width:26%; text-align:left">
                            OPERADOR
                        </th>
                        <th style="width:10%; text-align:right"></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-aircraftData>
                    <tr>
                        <td style="width:11%; text-align:center" class="cls-component-table-page-list-td-thumb">
                            <!-- AIRCRAFT AVATAR IMAGE -->
                            <img src="{{this.serverUrl}}/mod_ac/aircraft/avatar/image?url={{aircraftData.avatar_image}}"
                                alt="">
                        </td>
                        <td style="width:8%; text-align:center">
                            <!-- TYPE AIRCRAFT-->
                            <ng-container *ngIf="aircraftData.aero_registration.type_aircraft === 'helicopter'">
                                <i class="fa-solid fa-helicopter"></i> HELICÓPTERO
                            </ng-container>
                            <ng-container *ngIf="aircraftData.aero_registration.type_aircraft === 'aircraft'">
                                <i class="fa-solid fa-plane"></i> AVIÃO
                            </ng-container>                            
                        </td>
                        <td style="width:8%; text-align:center">
                            <!-- AIRCRAFT MARK -->
                            {{ this.localMethodsHandlerClass.aircraftMarkPutMask(aircraftData.aero_registration.mark) }}
                        </td>
                        <td style="width:8%; text-align:center">
                            <!-- AIRCRAFT ICAO CODE -->
                            {{ aircraftData.aero_registration.icao_code }}
                        </td>
                        <td style="width:27%; text-align:left">
                            <ul>
                                <!-- OWNER -->
                                <ng-container
                                    *ngFor="let itemOwner of aircraftData.owner_operator_data; let currentElementIndex=index">
                                    <ng-container
                                        *ngIf="itemOwner.type_owner_operator === 'owner' || itemOwner.type_owner_operator === 'both'">
                                        <li>{{itemOwner.ni}} - {{itemOwner.name}}</li>
                                    </ng-container>
                                </ng-container>
                            </ul>
                        </td>
                        <td style="width:26%; text-align:left">
                            <ul>
                                <!-- OPERATOR -->
                                <ng-container
                                    *ngFor="let itemOperator of aircraftData.owner_operator_data; let currentElementIndex=index">
                                    <ng-container
                                        *ngIf="itemOperator.type_owner_operator === 'operator' || itemOperator.type_owner_operator === 'both'">
                                        <li>{{itemOperator.ni}} - {{itemOperator.name}}</li>
                                    </ng-container>
                                </ng-container>
                            </ul>
                        </td>
                        <td style="width:10%; text-align:right" class="cls-component-table-page-list-td-action">
                            <p-button icon="fa-solid fa-trash-can" [rounded]="true" severity="danger"></p-button>
                            <p-button icon="fa-solid fa-pen-to-square" [rounded]="true" severity="secondary" (click)="
                            this.updateAircraftData(aircraftData.aircraft_data_id)">
                            </p-button>
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="summary">
                    <div class="flex align-items-center justify-content-between">
                        Total de Aeronaves recuperadas:
                        {{this.arrayAllAircraftData ? this.arrayAllAircraftData.length : 0 }}
                    </div>
                </ng-template>
            </p-table>
        </div>
    </div>
</article>