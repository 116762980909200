//#region "|--- IMPORT MODULES/PACKAGES ---|"
import HTTP_STATUS from 'http-status-codes';

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';

import { settingConfig } from '@nashville-config';

import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { MessageService } from 'primeng/api';

import { PrimengComponentsModule } from '@nashville-primeng';

import { LocalMethodsHandlerClass } from '../../../../../../helpers/classes/LocalMethodsHandlerClass';

import { ICON_AERODROME } from '../../../../../../helpers/enum/IconAerodrome';
//#endregion

//#region "|--- SERVICE ---|"
import { FlightPlanApisService } from '../../../../services/flight-plan-data-apis.service';
//#endregion

//#region "|--- FORM ---|"
import { initFormAerodrome } from '../../../../form-init/aerodrome-form';
//#endregion

//#region "|--- INTERFACE ---|"
import { IDropDownBasicOptions } from '../../../../../../interfaces/IDropDownBasicOptions';
import { IAerodromeBasicData } from '../../../../../../interfaces/IAerodromeBasicData';
import { IPatternResponseFromAPI } from '../../../../../../interfaces/IPatternCrudResponseFromAPI';
//#endregion 

//#region "|--- IMPORT ENUM ---|"
import { BUTTON_ACTION } from 'src/app/helpers/enum/ButtonActions';
//#endregion 

//#region "|--- OPTIONS ---|"
import { flightPlanFlightRulesOptions } from '../../../../../../helpers/dropdown-static-options/flightPlanFlightRulesOptions';
//#endregion 

@Component({
  selector: 'nashville-flight-data',
  standalone: true,
  imports: [
    CommonModule,
    PrimengComponentsModule
  ],
  templateUrl: './panel-flight-data.component.html',
  styleUrl: './panel-flight-data.component.scss',
})
export class PanelFlightDataComponent implements OnInit {
  //#region "|--- INPUTS ---|"
  @Input() formComponent!: any;  // Recebe o Formulário: REQUEST-FORM

  // É feito o set do @Input para que seja capturado qualquer modificação, em tempo real, 
  // do parâmetro que está sendo enviado pelo PARENT e, principalmente, que seja feito o 
  // processamento necessário com base no novo valor.
  @Input() set pageInnerWidth(xValue: any) {
    this._innerWidth = xValue;
  };
  //#endregion

  //#region "|--- OUTPUTS ---|"
  @Output() outputFormTempAerodrome = new EventEmitter<FormGroup>();
  @Output() outputFormRoute = new EventEmitter<string>();
  @Output() outputFormFindAerodromeByIcao = new EventEmitter<any>();
  @Output() outputFormFindAerodromeByCoord = new EventEmitter<any>();
  @Output() handlingAerodromeOnMap = new EventEmitter<string>();
  //#endregion

  //#region "|--- PROPERTIES---|" 
  _innerWidth!: any;

  formAerodrome!: any;

  settingConfig!: any;

  objAerodromeIcones: any;
  objAerodromesProgressBar: any;
  objAerodromesError: any;
  objAerodromeComplete: any

  arrayGoalAerodrome: any;
  arrayMainInputFieldAerodrome: any;

  optionsFlightPlanFlightRules!: IDropDownBasicOptions[];
  //#endregion 

  constructor(
    private flightPlanApisService: FlightPlanApisService,
    private messageService: MessageService
  ) { }

  /**
   * TODO: https://tsdoc.org/
   */
  ngOnInit(): void {
    this._initVariables();
    this._initFormAerodrome();

    // Tem que esperar um pouco, pois a inicialização do FORM pode não ter terminado.
    setTimeout(() => {
      this._initEventsAerodromeForm();
    }, 700);

    this.outputFormTempAerodrome.emit(this.formAerodrome);
  }

  //#region "|--- PUBLIC METHODS---|"
  /**
   * TODO: https://tsdoc.org/
   */
  newExtraAerodrome() {
    const objFormBuilder = new FormBuilder();
    let elementIndex;

    // LEMBRE-SE, ESTAMOS TRATANDO DE UM ARRAY DE CONTROLES, NÃO HÁ COMO NOMEAR OS CONTROLES, VAMOS TER QUE TRABALHAR COM A RODEM DELES
    this.formAerodrome.controls.alt_extras.controls.push(objFormBuilder.control(null));
    elementIndex = (this.formAerodrome.controls.alt_extras.controls.length + 2);

    this.formComponent.controls.aerodromes.controls.alt_extras.controls.push(objFormBuilder.group({
      data: objFormBuilder.group({
        aerodrome_basic_data_id: [null],
        fk_aerodrome_complete_data_id: [null],
        iata_code: [null],
        icao_code: [null],
        name: [null],
        aerodrome_type: [null],
        coordinate_label: [null],
        coordinate_dd: [null],
        coordinate_gms: [null],
        coordinate_plan: [null],
        coordinate_geo: {
          location: {
            type: "Point",
            coordinates: [null]
          }
        },
        place: {
          country_code: [null],
          state: [null],
          city: [null]
        }
      }),
      timestamp: [null],
      font: [null]
    })
    );

    this.formAerodrome.controls.alt_extras.controls[(this.formAerodrome.controls.alt_extras.controls.length - 1)].valueChanges.subscribe((xValue: any) => {
      if (xValue) {
        // Aceita qualquer valor, pois vai ter que distinguir entre Coordenadas e Indicador ICAO.        
        this._handlingAerodromeInput(xValue, `alt_${elementIndex}`);
      } else {
        this._clearAerodromeInput(`alt_${elementIndex}`);
        console.log("9999999999999999-------");
      }

      this.handlingAerodromeOnMap.emit(`alt_${elementIndex}`);
    });

    /*this.formAerodrome.controls.alt_extras.get(`${(this.formAerodrome.controls.alt_extras.controls.length - 1)}`).valueChanges.subscribe((xValue: any) => {
      if (xValue) {
        // Aceita qualquer valor, pois vai ter que distinguir entre Coordenadas e Indicador ICAO.
        this._handlingAerodromeInput(xValue, `alt_${(this.formAerodrome.controls.alt_extras.controls.length + 2)}`);
      }
    });*/
  }

  /**
   * TODO: https://tsdoc.org/
   */
  removeExtraAerodrome(xIndex: number) {
    const arrayElementRemove = [];
    const tempValue = this.formComponent.controls.aerodromes.controls.alt_extras.controls[xIndex].controls.data.controls.icao_code.value;

    if (tempValue != null) {
      arrayElementRemove.push(`${tempValue}`);
    }

    this.formComponent.controls.aerodromes.controls.alt_extras.controls[xIndex].controls.data.reset();
    this.formComponent.controls.aerodromes.controls.alt_extras.controls[xIndex].controls.font.reset();
    this.formComponent.controls.aerodromes.controls.alt_extras.controls[xIndex].controls.timestamp.reset();

    delete this.objAerodromesError[`alt_${xIndex + 3}`];

    this.handlingRiskFields(arrayElementRemove);
    this.handlingMeteorologyFields(arrayElementRemove);

    this.formAerodrome.controls.alt_extras.controls.splice(xIndex, 1);

    // TEM QUE APAGAR OS DADOS DO AERODROMO E DO PLANO DE VOO DO AERODROMO    
    this.formComponent.controls.aerodromes.controls.alt_extras.controls.splice(xIndex, 1);

    console.log("---------------", xIndex);

    this.handlingAerodromeOnMap.emit(`alt_${xIndex + 3}`);
  }

  /**
   * TODO: https://tsdoc.org/
   */
  getAerodromeIcon(xAerodromeTarget: any) {
    //if (xAerodromeTarget) {
    return `/assets/imgs/l_icon/${this.objAerodromeIcones[xAerodromeTarget.toUpperCase()]}`;
    //} else {
    //return `/assets/imgs/l_icon/${this.objAerodromeIcones[this.currentTagAerodromeTarget.toUpperCase()]}`;
    //}
  }

  /**
   * TODO: https://tsdoc.org/
   */
  searchAerodromeByIcao(xTargetAerodrome: string) {
    this.outputFormFindAerodromeByIcao.emit({
      target: xTargetAerodrome,
      action: BUTTON_ACTION.SHOW_DIALOG
    });
  }

  /**
   * TODO: https://tsdoc.org/
   */
  searchAerodromeByCoord(xTargetAerodrome: string) {
    this.outputFormFindAerodromeByCoord.emit({
      target: xTargetAerodrome,
      action: BUTTON_ACTION.SHOW_DIALOG
    });
  }

  /**
   * TODO: https://tsdoc.org/
   */
  searchRouteData() {
    this.outputFormRoute.emit(BUTTON_ACTION.SEARCH);
  }

  saveRouteData() {
    this.outputFormRoute.emit(BUTTON_ACTION.SAVE);
  }
  //#endregion

  //#region "|--- FORM METHODS---|"
  /**
   * TODO: https://tsdoc.org/
   */
  private handlingRiskFields(xArrayRemove: any = null) {
    const tempAerodromesContent = this.formComponent.controls.filters.controls.obstacles.controls.aerodromes_icao.value;
    const tempCoordContent = this.formComponent.controls.filters.controls.obstacles.controls.coordinates.value;
    const aerodromesValues = this.formComponent.controls.aerodromes.getRawValue();

    let newAerodromesContent: any = [];
    let newCoordContent: any = [];

    // Remover os que Foram Passados Dos Dados que já existem 02.
    if (xArrayRemove) {
      xArrayRemove.forEach((xItem: string) => {
        const indexElementAerodromes = tempAerodromesContent.findIndex((x: any) => x.icao_code === xItem);
        const indexElementCoordinates = tempCoordContent.findIndex((x: any) => x.label === xItem);

        if (indexElementAerodromes >= 0) {
          tempAerodromesContent.splice(indexElementAerodromes, 1);
        }

        if (indexElementCoordinates >= 0) {
          tempCoordContent.splice(indexElementCoordinates, 1);
        }
      });
    }

    // RECUPERA OS AERODROMOS E COORDENADAS DOS DADOS RECUPERADOS
    this.arrayGoalAerodrome.forEach((xElement: any) => {
      if (aerodromesValues[`${xElement}`] && aerodromesValues[`${xElement}`].data.icao_code && aerodromesValues[`${xElement}`].data.icao_code.length == 4) {
        if (aerodromesValues[`${xElement}`].data.icao_code != null) {
          if (aerodromesValues[`${xElement}`].data.icao_code != "ZZZZ") {
            newAerodromesContent.push({
              aerodrome_basic_data_id: `${aerodromesValues[`${xElement}`].data.aerodrome_basic_data_id}`,
              icao_code: `${aerodromesValues[`${xElement}`].data.icao_code}`.toUpperCase(),
              name: `${aerodromesValues[`${xElement}`].data.name}`.toUpperCase(),
              coordinate_dd: aerodromesValues[`${xElement}`].data.coordinate_dd,
              label: `${aerodromesValues[`${xElement}`].data.icao_code}`.toUpperCase()
            });
          } else {
            newCoordContent.push({
              aerodrome_basic_data_id: `${aerodromesValues[`${xElement}`].data.aerodrome_basic_data_id}`,
              icao_code: `${aerodromesValues[`${xElement}`].data.icao_code}`.toUpperCase(),
              name: `${aerodromesValues[`${xElement}`].data.name}`.toUpperCase(),
              coordinate_dd: [aerodromesValues[`${xElement}`].data.coordinate_dd[0].toFixed(3), aerodromesValues[`${xElement}`].data.coordinate_dd[1].toFixed(3)],
              label: `${aerodromesValues[`${xElement}`].data.coordinate_dd[0].toFixed(3)}, ${aerodromesValues[`${xElement}`].data.coordinate_dd[1].toFixed(3)}`
            });
          }
        }
      }
    });

    // RECUPERA OS AERODROMOS E COORDENADAS DOS DADOS RECUPERADOS: Alt_extras
    if (aerodromesValues.alt_extras && aerodromesValues.alt_extras.length > 0) {
      aerodromesValues.alt_extras.forEach((xElement: any) => {
        if (xElement.data.icao_code != null) {
          if (xElement.data.icao_code != "ZZZZ") {
            newAerodromesContent.push({
              aerodrome_basic_data_id: `${xElement.data.aerodrome_basic_data_id}`,
              icao_code: `${xElement.data.icao_code}`.toUpperCase(),
              name: `${xElement.data.name}`.toUpperCase(),
              coordinate_dd: xElement.data.coordinate_dd,
              label: `${xElement.data.icao_code}`.toUpperCase()
            });
          } else {
            newCoordContent.push({
              aerodrome_basic_data_id: `${xElement.data.aerodrome_basic_data_id}`,
              icao_code: `${xElement.data.icao_code}`.toUpperCase(),
              name: `${xElement.data.name}`.toUpperCase(),
              coordinate_dd: [xElement.data.coordinate_dd[0].toFixed(3), xElement.data.coordinate_dd[1].toFixed(3)],
              label: `${xElement.data.coordinate_dd[0].toFixed(3)}, ${xElement.data.coordinate_dd[1].toFixed(3)}`
            });

            newCoordContent.push(`${xElement.data.coordinate_dd[0].toFixed(2)}, ${xElement.data.coordinate_dd[1].toFixed(2)}`);
          }
        }
      });
    }

    // Fazer o merge entre 2 Arrays
    if (tempAerodromesContent && tempAerodromesContent.length > 0) {
      newAerodromesContent = newAerodromesContent.concat(tempAerodromesContent);
    }

    if (tempCoordContent && tempCoordContent.length > 0) {
      newCoordContent = newCoordContent.concat(tempCoordContent);
    }

    // Remover os Duplicados 
    const finalAerodromesContent = [...new Map(newAerodromesContent.map((item:any) => [item["icao_code"], item])).values()];

    const finaCoordContent = [...new Map(newCoordContent.map((item:any) => [item["coordinate_dd"], item])).values()];

    this.formComponent.controls.filters.controls.obstacles.controls.aerodromes_icao.patchValue(finalAerodromesContent);
    this.formComponent.controls.filters.controls.obstacles.controls.coordinates.patchValue(finaCoordContent);
  }

  /**
   * TODO: https://tsdoc.org/
   */
  private handlingMeteorologyFields(xArrayRemove: any = null) {
    const tempAerodromesContent = this.formComponent.controls.filters.controls.meteorology.controls.aerodromes_icao.value;
    const tempCoordContent = this.formComponent.controls.filters.controls.meteorology.controls.coordinates.value;
    const aerodromesValues = this.formComponent.controls.aerodromes.getRawValue();

    let newAerodromesContent: any = [];
    let newCoordContent: any = [];

    // Remover os que Foram Passados Dos Dados que já existem 01.
    if (xArrayRemove) {
      xArrayRemove.forEach((xItem: string) => {
        const indexElementAerodromes = tempAerodromesContent.indexOf(xItem);
        const indexElementCoordinates = tempCoordContent.indexOf(xItem);

        if (indexElementAerodromes >= 0) {
          tempAerodromesContent.splice(indexElementAerodromes, 1);
        }

        if (indexElementCoordinates >= 0) {
          tempCoordContent.splice(indexElementCoordinates, 1);
        }
      });
    }

    // RECUPERA OS AERODROMOS E COORDENADAS DOS DADOS RECUPERADOS
    this.arrayGoalAerodrome.forEach((xElement: any) => {
      if (aerodromesValues[`${xElement}`] && aerodromesValues[`${xElement}`].data.icao_code && aerodromesValues[`${xElement}`].data.icao_code.length == 4) {
        if (aerodromesValues[`${xElement}`].data.icao_code != null) {
          if (aerodromesValues[`${xElement}`].data.icao_code != "ZZZZ") {
            newAerodromesContent.push(`${aerodromesValues[`${xElement}`].data.icao_code}`.toUpperCase());
          } else {
            newCoordContent.push(`${aerodromesValues[`${xElement}`].data.coordinate_dd[0].toFixed(2)}, ${aerodromesValues[`${xElement}`].data.coordinate_dd[1].toFixed(2)}`);
          }
        }
      }
    });

    // RECUPERA OS AERODROMOS E COORDENADAS DOS DADOS RECUPERADOS: Alt_extras    
    if (aerodromesValues.alt_extras && aerodromesValues.alt_extras.length > 0) {
      aerodromesValues.alt_extras.forEach((xElement: any) => {
        if (xElement.data.icao_code != null) {
          if (xElement.data.icao_code != "ZZZZ") {
            newAerodromesContent.push(`${xElement.data.icao_code}`.toUpperCase());
          } else {
            newCoordContent.push(`${xElement.data.coordinate_dd[0].toFixed(2)}, ${xElement.data.coordinate_dd[1].toFixed(2)}`);
          }
        }
      });
    }

    // Fazer o merge entre 2 Arrays
    if (tempAerodromesContent && tempAerodromesContent.length > 0) {
      newAerodromesContent = newAerodromesContent.concat(tempAerodromesContent);
    }

    if (tempCoordContent && tempCoordContent.length > 0) {
      newCoordContent = newCoordContent.concat(tempCoordContent);
    }

    // Remover os Duplicados 
    const finalAerodromesContent = newAerodromesContent.filter((xElement: any, xIndex: number) => {     
      return newAerodromesContent.indexOf(xElement) === xIndex;
    });

    const finaCoordContent = newCoordContent.filter((xElement: any, xIndex: number) => {
      return newCoordContent.indexOf(xElement) === xIndex;
    });

    this.formComponent.controls.filters.controls.meteorology.controls.aerodromes_icao.patchValue(finalAerodromesContent);
    this.formComponent.controls.filters.controls.meteorology.controls.coordinates.patchValue(finaCoordContent);
  }

  /**
   * TODO: https://tsdoc.org/
   */
  private _validateOnSubmit() {
    console.log("---------------------")
  }

  /**
   * TODO: https://tsdoc.org/
   */
  private _initFormAerodrome(): void {
    this.formAerodrome = initFormAerodrome();
  }

  /**
   * TODO: https://tsdoc.org/
   */
  private _initEventsAerodromeForm(): void {
    // *****--- DEPARTURE ---****
    this.formAerodrome.get(this.arrayGoalAerodrome[0]).valueChanges.subscribe((xValue: any) => {
      if (xValue && xValue != "ZZZ") { // Quando é ZZZ tenho que preservar, pois tem que tratar quando retirar um Z
        this._clearAerodromeInput(this.arrayGoalAerodrome[0]);
      }

      if (xValue) {
        // Aceita qualquer valor, pois vai ter que distinguir entre Coordenadas e Indicador ICAO.
        this._handlingAerodromeInput(xValue, this.arrayGoalAerodrome[0]);
      }

      this.outputFormTempAerodrome.emit(this.formAerodrome); // ESTE ITEM É OBRIGATÓRIO, POR ISTO QUE TEM QUE INFORMAR QUALQUER MUDANÇA NESTE CAMPO
    });

    // *****--- DESTINATION ---****
    this.formAerodrome.get(this.arrayGoalAerodrome[1]).valueChanges.subscribe((xValue: any) => {
      if (xValue && xValue != "ZZZ") { // Quando é ZZZ tenho que preservar, pois tem que tratar quando retirar um Z
        this._clearAerodromeInput(this.arrayGoalAerodrome[1]);
      }

      if (xValue) {
        // Aceita qualquer valor, pois vai ter que distinguir entre Coordenadas e Indicador ICAO.
        this._handlingAerodromeInput(xValue, this.arrayGoalAerodrome[1]);
      }

      this.outputFormTempAerodrome.emit(this.formAerodrome); // ESTE ITEM É OBRIGATÓRIO, POR ISTO QUE TEM QUE INFORMAR QUALQUER MUDANÇA NESTE CAMPO
    });

    // *****--- ALT_1 ---****
    this.formAerodrome.get(this.arrayGoalAerodrome[2]).valueChanges.subscribe((xValue: any) => {
      if (xValue && xValue != "ZZZ") {
        this._clearAerodromeInput(this.arrayGoalAerodrome[2]);
      }

      if (xValue) {
        // Aceita qualquer valor, pois vai ter que distinguir entre Coordenadas e Indicador ICAO.
        this._handlingAerodromeInput(xValue, this.arrayGoalAerodrome[2]);
      }

      this.outputFormTempAerodrome.emit(this.formAerodrome); // ESTE ITEM É OBRIGATÓRIO, POR ISTO QUE TEM QUE INFORMAR QUALQUER MUDANÇA NESTE CAMPO
    });

    // *****--- ALT_2 ---****
    this.formAerodrome.get(this.arrayGoalAerodrome[3]).valueChanges.subscribe((xValue: any) => {
      if (xValue && xValue != "ZZZ") {
        this._clearAerodromeInput(this.arrayGoalAerodrome[3]);
      }

      if (xValue) {
        // Aceita qualquer valor, pois vai ter que distinguir entre Coordenadas e Indicador ICAO.
        this._handlingAerodromeInput(xValue, this.arrayGoalAerodrome[3]);
      }

    });
  }
  //#endregion

  //#region "|--- PRIVATE METHODS---|"
  /**
   * TODO: https://tsdoc.org/
   */
  private _clearAerodromeInput(xTarget: string): void {
    let tempValue;
    let isExtraAlt = false;

    if (xTarget.indexOf("alt_") >= 0) { // Verifica se é um aeródromo alternativo
      const tempIndexAlt = parseInt(xTarget.replaceAll("alt_", ""));

      if (tempIndexAlt > 2) { // Verifica se é um aeródromo Extra, index maior que 2.
        isExtraAlt = true;
      }
    }

    if (isExtraAlt == false) {
      tempValue = this.formComponent.controls.aerodromes.controls[`${xTarget}`].controls.data.controls.icao_code.value;
    } else {
      const tempIndex = parseInt(xTarget.replaceAll("alt_", "")) - 3;

      tempValue = this.formComponent.controls.aerodromes.controls.alt_extras.controls[tempIndex].controls.data.controls.icao_code.value;
    }

    // TEM QUE APAGAR OS DADOS BÁSICOS E DADOS COMPLETOS   
    if (tempValue != null) {
      const arrayElementRemove = [];

      let tempCoordinate;

      if (isExtraAlt == false) {
        tempCoordinate = this.formComponent.controls.aerodromes.controls[`${xTarget}`].controls.data.controls.coordinate_dd.value;
      } else {
        const tempIndex = parseInt(xTarget.replaceAll("alt_", "")) - 3;
        const tempValueCoord = this.formComponent.controls.aerodromes.controls.alt_extras.controls[tempIndex].controls.data.controls.coordinate_dd.value;

        if (tempValueCoord != null) {
          tempCoordinate = tempValueCoord;
        }
      }

      if (tempValue != null) {
        arrayElementRemove.push(`${tempValue}`);
      }

      if (tempCoordinate && tempCoordinate.length == 2) {
        arrayElementRemove.push(`${tempCoordinate[0].toFixed(2)}, ${tempCoordinate[1].toFixed(2)}`);
      }

      if (isExtraAlt == false) {
        this.formComponent.controls.aerodromes.controls[`${xTarget}`].controls.data.reset();
        this.formComponent.controls.aerodromes.controls[`${xTarget}`].controls.font.reset();
        this.formComponent.controls.aerodromes.controls[`${xTarget}`].controls.timestamp.reset();
      } else {
        const tempIndex = parseInt(xTarget.replaceAll("alt_", "")) - 3;

        this.formComponent.controls.aerodromes.controls.alt_extras.controls[tempIndex].controls.data.reset();
        this.formComponent.controls.aerodromes.controls.alt_extras.controls[tempIndex].controls.font.reset();
        this.formComponent.controls.aerodromes.controls.alt_extras.controls[tempIndex].controls.timestamp.reset();
      }

      delete this.objAerodromesError[`${xTarget}`];

      this.handlingAerodromeOnMap.emit(xTarget); // VAI MOSTRAR O AERÓDROMO NO MAPA.

      this.handlingRiskFields(arrayElementRemove);
      this.handlingMeteorologyFields(arrayElementRemove);
    }
  }

  /**
   * TODO: https://tsdoc.org/
   */
  private async _handlingAerodromeInput(xAerodromeCoord: string, xTarget: string) {
    // TIPOS DE COORDENADAS:
    // LABEL:   "0952.06S,06753.53W" OU "0952.06S;06753.53W" OU "0952.06S/06753.53W" OU "0952,06S/06753,53W"
    // DECIMAL: "-9.868333333333,-67.89805555555" OU "-9,868333333333; -67,89805555555" OU "-9,868333333333/-67,89805555555"
    // GMS:     "09 52 06S,067 53 53W" OU "09 52 06S;067 53 53W" OU "09 52 06S/067 53 53W"
    // PLAN: 0952S06753W 

    const returnDataCoord: any = {}
    const tempAerodromeCoord = xAerodromeCoord.trim().toUpperCase();

    let tempTypeCoord = "";
    let tempLat: any;  // Pode ser String ou Numérico.
    let tempLng: any;  // Pode ser String ou Numérico.

    // Nesta variável será armazenado os dados referentes a um Aeródromo Básico quando for inserido uma coordenada. 
    let coordData: IAerodromeBasicData = LocalMethodsHandlerClass.initAerodromeBasicData();

    returnDataCoord["data_info"] = [];

    // VERIFICA SE É CÓDIGO ICAO COM 4 DÍGITOS. - O CÓDIGO ICAO PODE SER FORMADO POR LETRA E NÚMERO (SEM ESPAÇO E SEM HIFEN, UNDERLINE E ETC...).
    // GRUPO DE ZZZZ ELE BUSCA, POIS VAI HAVER VÁRIOS GRUPOS  GRAVADOS. VAI ABRIR UMA TELA PARA ESCOLHER QUAL ELE QUER.
    // NUNCA VAI COMEÇAR COM UM NÚMERO, POR ISTO O SEGUNDO TESTE...
    if (tempAerodromeCoord.length == 4 && /^[A-Z]/.test(tempAerodromeCoord) && /^[A-Z0-9\-]+$/.test(tempAerodromeCoord)) {
      this._cleanAerodromeInputInfo(tempAerodromeCoord, xTarget); // Limpa as informações ligadas a entrada dos dados.

      // Sem Recuperar os Dados Básicos, não tem como ir para frente. Os demais dados podem ser gerados durante o atendimento.
      Promise.all([
        await this._aerodromeBasicData(tempAerodromeCoord, xTarget)
      ]);
    } else if (tempAerodromeCoord.length == 3 && tempAerodromeCoord == "ZZZ") { // APAGANDO GRUPOS ZZZZ QUE FORAM CRIADOS POR COORDENADAS.           
      let tempControl: any;

      if (xTarget.indexOf("alt_") >= 0) { // Verifica se é aerodrome EXTRA.
        const tempIndexAlt = parseInt(xTarget.replaceAll("alt_", ""));

        if (tempIndexAlt > 2) { // Verifica se é um aeródromo Extra, index maior que 2.
          const tempIndex = parseInt(xTarget.replaceAll("alt_", "")) - 3;

          tempControl = this.formComponent.controls.aerodromes.controls.alt_extras.controls[tempIndex].getRawValue();
        } else {
          tempControl = this.formComponent.controls.aerodromes.controls[xTarget].getRawValue();
        }
      } else { // Departure, Destination
        tempControl = this.formComponent.controls.aerodromes.controls[xTarget].getRawValue();
      }

      if (tempControl.data.name.indexOf("Coord:") >= 0) {
        const tempValue = tempControl.data.name.replaceAll("Coord: ", "").replaceAll(")", "");

        if (this.arrayGoalAerodrome.includes(xTarget)) {
          // Por causa da Possibilidade de Entrar com Coordenada, é preciso Colocar o ZZZZ
          this.formAerodrome.controls[`${xTarget}`].setValue(tempValue, { emitEvent: false });

        } else { // AERODROMOS EXTRAS
          const tempIndexAerodrome = xTarget.replaceAll("alt_", "");

          // Por causa da Possibilidade de Entrar com Coordenada, é preciso Colocar o ZZZZ
          this.formAerodrome.controls.alt_extras.controls[parseInt(tempIndexAerodrome) - 3].setValue(tempValue, { emitEvent: false });
        }
      }

      this._cleanAerodromeInputInfo(xAerodromeCoord.trim().toUpperCase(), xTarget);
      this._clearAerodromeInput(xTarget);
    } else if (xAerodromeCoord.length > 4) {
      // PARA SER UMA COORDENADA, TEM QUE COMEÇAR COM "(" e terminar com ")";
      if (/^\((.*)\)$/.test(xAerodromeCoord.trim())) {
        xAerodromeCoord = xAerodromeCoord.replaceAll("(", "").replaceAll(")", "");

        if (xAerodromeCoord.indexOf(",") > 0 || xAerodromeCoord.indexOf(";") > 0 || xAerodromeCoord.indexOf("/") > 0) { // Não pode ser na primeira posição, por isto tem que ser somente maior que zero.
          const arraySeparator01 = xAerodromeCoord.split(",");
          const arraySeparator02 = xAerodromeCoord.split(";");
          const arraySeparator03 = xAerodromeCoord.split("/");

          if (arraySeparator01.length == 2) {
            tempLat = arraySeparator01[0].trim().replaceAll(",", ".").toLocaleUpperCase();
            tempLng = arraySeparator01[1].trim().replaceAll(",", ".").toLocaleUpperCase();
          } else if (arraySeparator02.length == 2) {
            tempLat = arraySeparator02[0].trim().replaceAll(",", ".").toLocaleUpperCase();
            tempLng = arraySeparator02[1].trim().replaceAll(",", ".").toLocaleUpperCase();
          } else if (arraySeparator03.length == 2) {
            tempLat = arraySeparator03[0].trim().replaceAll(",", ".").toLocaleUpperCase();
            tempLng = arraySeparator03[1].trim().replaceAll(",", ".").toLocaleUpperCase();
          }

          if (tempLat.indexOf("S") < 0 && tempLng.indexOf("N") < 0 && tempLng.indexOf(".") > 0) {
            // DECIMAL
            tempTypeCoord = "decimal";

            coordData.icao_code = "ZZZZ";
            coordData.name = `Coord: (${tempLat}, ${tempLng})`;
            coordData.coordinate_label = LocalMethodsHandlerClass.coordinateArrayDdToLabel([parseFloat(tempLat), parseFloat(tempLng)]);
            coordData.coordinate_dd = [parseFloat(tempLat), parseFloat(tempLng)];
            coordData.coordinate_gms = LocalMethodsHandlerClass.coordinateArrayDdToGms([parseFloat(tempLat), parseFloat(tempLng)]);
            coordData.coordinate_plan = LocalMethodsHandlerClass.coordinateArrayDdToPlan([parseFloat(tempLat), parseFloat(tempLng)]);
            coordData.coordinate_geo.location.coordinates = [parseFloat(tempLat), parseFloat(tempLng)];
            delete coordData.ciad;
            delete coordData.handling_data;

            returnDataCoord.data_info.push(coordData);

            await this._processBasicAerodrome(returnDataCoord, xTarget);
          } else if ((tempLat.indexOf("S") > 0 || tempLat.indexOf("N") > 0) && (tempLng.indexOf("E") > 0 || tempLng.indexOf("W") > 0) && tempLng.indexOf(".") > 0) {
            const tempCoordDecimal = LocalMethodsHandlerClass.coordinateArrayLabelToDd([tempLat, tempLng]); // Pode Enviar como string mesmo.

            tempTypeCoord = "label";

            coordData.icao_code = "ZZZZ"; // 0952.06S,06753.53W
            coordData.name = `Coord: (${tempCoordDecimal[0].toFixed(2)}, ${tempCoordDecimal[1].toFixed(2)})`;
            coordData.coordinate_label = [tempLat, tempLng];
            coordData.coordinate_dd = tempCoordDecimal;
            coordData.coordinate_gms = LocalMethodsHandlerClass.coordinateArrayDdToGms(tempCoordDecimal);
            coordData.coordinate_plan = LocalMethodsHandlerClass.coordinateArrayDdToPlan(tempCoordDecimal);
            coordData.coordinate_geo.location.coordinates = tempCoordDecimal;
            delete coordData.ciad;
            delete coordData.handling_data;

            returnDataCoord.data_info.push(coordData);

            await this._processBasicAerodrome(returnDataCoord, xTarget);
          } else if ((tempLat.indexOf("S") > 0 || tempLat.indexOf("N") > 0) && (tempLng.indexOf("E") > 0 || tempLng.indexOf("W") > 0) && tempLng.indexOf(".") <= 0) {
            const tempCoordDecimal = LocalMethodsHandlerClass.coordinateArrayGmsToDd([tempLat, tempLng]);

            tempTypeCoord = "gms";

            coordData.icao_code = "ZZZZ";
            coordData.name = `Coord: (${tempCoordDecimal[0].toFixed(2)}, ${tempCoordDecimal[1].toFixed(2)})`;
            coordData.coordinate_label = LocalMethodsHandlerClass.coordinateArrayDdToLabel(tempCoordDecimal);
            coordData.coordinate_dd = tempCoordDecimal;
            coordData.coordinate_gms = [tempLat, tempLng];
            coordData.coordinate_plan = LocalMethodsHandlerClass.coordinateArrayDdToPlan(tempCoordDecimal);
            coordData.coordinate_geo.location.coordinates = tempCoordDecimal;

            delete coordData.ciad;
            delete coordData.handling_data;

            returnDataCoord.data_info.push(coordData);

            await this._processBasicAerodrome(returnDataCoord, xTarget);
          }
        } else if (xAerodromeCoord.length == 11 && xAerodromeCoord.trim().indexOf(" ") < 0) {
          const arraySeparator04 = xAerodromeCoord.split("S");

          if (arraySeparator04.length == 2) {
            const tempCoordDecimal = LocalMethodsHandlerClass.coordinateArrayPlanToDd(xAerodromeCoord);

            tempTypeCoord = "plan"; // Coordenada do Tipo: PLAN ()

            coordData.icao_code = "ZZZZ";
            coordData.name = `Coord: (${tempCoordDecimal[0].toFixed(2)}, ${tempCoordDecimal[1].toFixed(2)})`;
            coordData.coordinate_label = LocalMethodsHandlerClass.coordinateArrayDdToLabel(tempCoordDecimal);
            coordData.coordinate_dd = tempCoordDecimal;
            coordData.coordinate_gms = LocalMethodsHandlerClass.coordinateArrayDdToGms(tempCoordDecimal);
            coordData.coordinate_plan = xAerodromeCoord;
            coordData.coordinate_geo.location.coordinates = tempCoordDecimal;

            delete coordData.ciad;
            delete coordData.handling_data;

            returnDataCoord.data_info.push(coordData);

            await this._processBasicAerodrome(returnDataCoord, xTarget);
          }
        } else {
          this._clearAerodromeInput(xTarget);
        };
      } else {
        this._clearAerodromeInput(xTarget);
      }
    } else {
      this._clearAerodromeInput(xTarget);
    }
  }

  /**
   * TODO: https://tsdoc.org/ 
   */
  private _cleanAerodromeInputInfo(xAerodromeIcao: string, xAerodromeTarget: string) {
    delete this.objAerodromesProgressBar[`${xAerodromeTarget}`];  // Apaga a Barra de Progressão.
    delete this.objAerodromesError[`${xAerodromeTarget}`];        // Apaga todos os Erros ligados ao Aeródromo.
    delete this.objAerodromeComplete[`${xAerodromeTarget}`];
  }

  /**
   * TODO: https://tsdoc.org/
   */
  private async _processOtherInformation(xAerodromeIcao: string, xTarget: string) {
    if (xAerodromeIcao != "ZZZZ") {
      //await this._aerodromeCompleteData(xAerodromeIcao.trim().toUpperCase(), xTarget);
      //await this._aerodromeObstaclesData(xAerodromeIcao.trim().toUpperCase(), xTarget);
      //await this._aerodromeNotamOnline(xAerodromeIcao.trim().toUpperCase(), xTarget);
      //await this._aerodromeMeteorologyOnline(xAerodromeIcao.trim().toUpperCase(), xTarget);
    }
  }

  /**
   * TODO: https://tsdoc.org/
   */
  private _getFormAerodromeIcaoCode(xAerodromeTarget: string): string {
    if (xAerodromeTarget.indexOf("alt_") >= 0) {
      const tempIndexAlt = parseInt(xAerodromeTarget.replaceAll("alt_", ""));

      if (tempIndexAlt > 2) { // Verifica se é um aeródromo Extra, index maior que 2.
        const tempIndex = parseInt(xAerodromeTarget.replaceAll("alt_", "")) - 3;

        return this.formComponent.controls.aerodromes.controls.alt_extras.controls[tempIndex].controls.data.controls.icao_code.value;
      } else {
        return this.formComponent.controls.aerodromes.controls[`${xAerodromeTarget}`].controls.data.controls.icao_code.value;
      }
    } else {
      return this.formComponent.controls.aerodromes.controls[xAerodromeTarget].controls.data.controls.icao_code.value;
    }
  }

  /**
   * TODO: https://tsdoc.org/
   */
  private async _aerodromeBasicData(xAerodromeIcao: string, xAerodromeTarget: string) {
    const tempAerodromeBasicData = xAerodromeIcao.trim().toUpperCase();

    try {
      let returnRequestAerodromeBasicData: any;

      if (tempAerodromeBasicData.trim().length == 4 && /^[A-Z]/.test(tempAerodromeBasicData) && /^[A-Z0-9\-]+$/.test(tempAerodromeBasicData)) {
        this.objAerodromesProgressBar[`${xAerodromeTarget}`] = "ok";  // Inicia a Barra Progressiva no Campo de entrada.

        delete this.objAerodromesError[`${xAerodromeTarget}`];        // Apaga todos os Erros ligados ao Aeródromo.
        this.objAerodromesError[`${xAerodromeTarget}`] = {};          // Inicia um novo Objeto de Erros ligados ao Aeródromo.

        // Faz a PRIMEIRA chamada dos dados BÁSICOS do Aeródromo do BANCO DE DADOS.
        Promise.all([
          returnRequestAerodromeBasicData = await this._getAerodromeBasicDataByAerodromeIcao(xAerodromeIcao, xAerodromeTarget)
        ]);

        // TRATAMENTO PARA PROCESSOS ASSÍNCRONO, VIA KAFKA.
        // VAI CHEGAR AO FINAL DO MÉTODO E EXECUTAR ESTE PROCESSO DEPOIS, POR ISTO TEM O PROCESSO DE RETORNO INTERNO.
        if (returnRequestAerodromeBasicData.status_code == HTTP_STATUS.ACCEPTED) {
          let tempCounter = 1;

          // VERIFICA SE O PROCESSO ASSÍNCRONO POSSUI UM ID DE PROCESSO, POIS É COM BASE NESTE PROCESSO QUE SERÁ RECUPERADO OS DADOS RECUPERADOS.
          if (returnRequestAerodromeBasicData.data_info && returnRequestAerodromeBasicData.data_info.process_id && returnRequestAerodromeBasicData.data_info.process_id.length == 36) {
            // COLOCA-SE UM TIMER ENTRE AS TENTATIVAS DE RECUPERAÇÃO DOS DADOS RECUPERADOS:
            const refreshId = setInterval(async () => {
              // COLOQUEI O TRY/CATCH PARA TRATAR EXCEÇÕES QUE PRECISAM INTERROMPER AS TENTATIVAS
              try {
                const tempIcaoSearch = tempAerodromeBasicData;

                let returnRequestKafkaService: any;

                // NO PROCESSO ASSÍNCRONO SERÁ RECUPERADO O REGISTRO DO PROCESSO KAFKA A FIM DE VER SE O PROCESSO TERMINOU.
                // TRÁS O REGISTRO DO SERVIÇO KAFKA QUE VAI SER ATUALIZADO DE FORMA ASSÍNCRONA.
                Promise.all([
                  returnRequestKafkaService = await this._getKafkaServiceDataByIdFromService(returnRequestAerodromeBasicData.data_info.process_id)
                ]);

                if (returnRequestKafkaService.status_code == HTTP_STATUS.OK) { // Resultado da Consulta no Banco do Kafka Service
                  if (returnRequestKafkaService.data_info.data[0].status_code == HTTP_STATUS.OK || returnRequestKafkaService.data_info.data[0].status_code == HTTP_STATUS.CREATED) {
                    returnRequestAerodromeBasicData = await this._getAerodromeBasicDataByAerodromeIcao(tempAerodromeBasicData, xAerodromeTarget);

                    await this._processBasicAerodrome(returnRequestAerodromeBasicData, xAerodromeTarget); // Tem que ter este processamento, pois é Assíncrono o Processo.
                    await this._processOtherInformation(xAerodromeIcao, xAerodromeTarget); // Procura os Demais Dados.
                  } else if (returnRequestKafkaService.data_info.data[0].status_code == HTTP_STATUS.BAD_REQUEST) {
                    this.objAerodromesError[`${xAerodromeTarget}`]['basicData'] = "ERRO DE REQUISIÇÃO (SN)";

                    this.messageService.add({ key: 'toast2', severity: 'error', summary: 'Dados Básicos de Aeródromo', detail: "Erro ao Recuperar Dados Básicos de Aeródromo" });
                  } else if (returnRequestKafkaService.data_info.data[0].status_code == HTTP_STATUS.NOT_FOUND) {
                    this.objAerodromesError[`${xAerodromeTarget}`]['basicData'] = "NÃO EXISTENTE NO AISWEB";

                    this.messageService.add({ key: 'toast2', severity: 'error', summary: 'Dados Básicos de Aeródromo', detail: `AERÓDROMO NÃO EXISTENTE NA BASE DA AISWEB ${xAerodromeIcao}` });
                  } else if (returnRequestKafkaService.data_info.data[0].status_code == HTTP_STATUS.INTERNAL_SERVER_ERROR) {
                    this.objAerodromesError[`${xAerodromeTarget}`]['basicData'] = "ERRO NO SINCRONISMO";

                    this.messageService.add({ key: 'toast2', severity: 'error', summary: 'Dados Básicos de Aeródromo', detail: `EXCEÇÃO NA RECUPERAÇÃO DOS DADOS BÁSICOS AERÓDROMO: ${xAerodromeIcao}` });
                  }
                } else if (returnRequestKafkaService.status_code == HTTP_STATUS.NOT_FOUND) {
                  this.objAerodromesError[`${xAerodromeTarget}`]['basicData'] = "ERRO SINCRONISMO (NF)";

                  this.messageService.add({ key: 'toast2', severity: 'error', summary: 'Dados Básicos de Aeródromo', detail: "Erro no Sincronismo: não encontrou processo." });
                } else if (returnRequestKafkaService.status_code == HTTP_STATUS.BAD_REQUEST) {
                  this.objAerodromesError[`${xAerodromeTarget}`]['basicData'] = "ERRO SINCRONISMO (BR)";

                  this.messageService.add({ key: 'toast2', severity: 'error', summary: 'Dados Básicos de Aeródromo', detail: "Erro no Sincronismo" });
                } else if (returnRequestKafkaService.status_code == HTTP_STATUS.REQUEST_TIMEOUT) {
                  this.objAerodromesError[`${xAerodromeTarget}`]['basicData'] = "ERRO SINCRONISMO (TO)";

                  this.messageService.add({ key: 'toast2', severity: 'error', summary: 'Dados Básicos de Aeródromo', detail: "Erro no Sincronismo" });
                } else {
                  this.objAerodromesError[`${xAerodromeTarget}`]['basicData'] = "ERRO SINCRONISMO (EX)";

                  this.messageService.add({ key: 'toast2', severity: 'error', summary: 'Dados Básicos de Aeródromo', detail: "Exceção no Sincronismo" });
                }

                //  - ATINGIU O MÁXIMO DE TENTATIVAS                - HOUVE UMA RESPOSTA DO PROCESSO E TUDO CERTO                          
                if (tempCounter >= settingConfig.NUMBER_ATTEMPTS || returnRequestKafkaService.data_info.data[0].status_code != HTTP_STATUS.ACCEPTED) {
                  delete this.objAerodromesProgressBar[`${xAerodromeTarget}`]; // Apagar a Barra Progressiva.

                  if (returnRequestKafkaService.data_info.data[0].status_code == HTTP_STATUS.ACCEPTED) {
                    if (tempIcaoSearch == this._getFormAerodromeIcaoCode(xAerodromeTarget)) {
                      this.objAerodromesError[`${xAerodromeTarget}`]['basicData'] = "ERRO SINCRONISMO (CC)";
                    }
                  }

                  clearInterval(refreshId);
                }

                tempCounter++;
              } catch (xError) {
                delete this.objAerodromesProgressBar[`${xAerodromeTarget}`]; // Apagar a Barra Progressiva. 

                this.messageService.add({ key: 'toast2', severity: 'error', summary: 'Dados Básicos de Aeródromo', detail: "Exceção no Sincronismo (CC)" });

                clearInterval(refreshId);
              }
            }, settingConfig.INTERVAL_ATTEMPTS);
          } else {
            this.objAerodromesError[`${xAerodromeTarget}`]['basicData'] = "ERRO SINCRONISMO (NI)";

            this.messageService.add({
              key: 'toast2',
              severity: 'error',
              summary: 'Dados Básicos de Aeródromo',
              detail: "Exceção no Sincronismo"
            });
          }
        }

        // --- ANALISE DO RETORNO DOS DADOS BÁSICO DO AERÓDROMO -- //
        if (returnRequestAerodromeBasicData.status_code == HTTP_STATUS.OK || returnRequestAerodromeBasicData.status_code == HTTP_STATUS.CREATED) {
          await this._processBasicAerodrome(returnRequestAerodromeBasicData, xAerodromeTarget);
          await this._processOtherInformation(xAerodromeIcao, xAerodromeTarget); // Procura os Demais Dados.

        } else if (returnRequestAerodromeBasicData.status_code == HTTP_STATUS.NOT_FOUND) {
          this.objAerodromesError[`${xAerodromeTarget}`]['basicData'] = "ERRO CONSULTA (NF)";

          this.messageService.add({ key: 'toast2', severity: 'error', summary: 'Dados Básicos de Aeródromo', detail: "Erro no Sincronismo: não encontrou processo." });
        } else if (returnRequestAerodromeBasicData.status_code == HTTP_STATUS.BAD_REQUEST) {
          this.objAerodromesError[`${xAerodromeTarget}`]['basicData'] = "ERRO CONSULTA (BR)";

          this.messageService.add({ key: 'toast2', severity: 'error', summary: 'Dados Básicos de Aeródromo', detail: "Erro ao Recuperar Dados Básicos de Aeródromo" });
        } else if (returnRequestAerodromeBasicData.status_code == HTTP_STATUS.INTERNAL_SERVER_ERROR) {
          this.objAerodromesError[`${xAerodromeTarget}`]['basicData'] = "ERRO CONSULTA (EX)";

          this.messageService.add({ key: 'toast2', severity: 'error', summary: 'Dados Básicos de Aeródromo', detail: "Exceção ao Recuperar Dados Básicos de Aeródromo" });
        }

        if (returnRequestAerodromeBasicData.status_code != HTTP_STATUS.ACCEPTED) {
          delete this.objAerodromesProgressBar[`${xAerodromeTarget}`]; // Apagar a Barra Progressiva.          
        }
      } else {
        //TODO: TRANSLATION
        //TODO: MESSENGER
        //TODO: ERROR - LOG
        //TODO: SEVERITY
        this.messageService.add({
          key: 'toast2',
          severity: 'error',
          summary: 'Dados do Aeródromo',
          detail: "O código ICAO do aeródromo para recuperar os dados básicos de aeródromo não é válido."
        });
      }
    } catch (xError: any) {
      console.log(">>>>>>>>>>>>>>>>>>", xError);

      delete this.objAerodromesProgressBar[`${xAerodromeTarget}`]; // Apagar a Barra Progressiva. 

      this.objAerodromesError[`${xAerodromeTarget}`] = {};

      if (xError.status_code == 0) {
        this.objAerodromesError[`${xAerodromeTarget}`]['basicData'] = "ERRO CONEXÃO CONSULTA";
      } else {
        this.objAerodromesError[`${xAerodromeTarget}`]['basicData'] = "ERRO CONSULTA"
      }

      //TODO: TRANSLATION
      //TODO: MESSENGER
      //TODO: ERROR - LOG
      //TODO: SEVERITY
      this.messageService.add({ key: 'toast2', severity: 'error', summary: 'Dados Básicos de Aeródromo', detail: "Exceção ao Recuperar os Dados Básicos de Aeródromo." });
    }
  }

  /**
   * TODO: https://tsdoc.org/
   */
  private async _aerodromeCompleteData(xAerodromeIcao: string, xAerodromeTarget: string) {
    const tempAerodromeCompleteData = xAerodromeIcao.trim().toUpperCase();

    delete this.objAerodromeComplete[`${xAerodromeTarget}`];

    try {
      let returnRequestAerodromeCompleteData: any;

      if (tempAerodromeCompleteData.length == 4 && /^[A-Z]/.test(tempAerodromeCompleteData) && /^[A-Z0-9\-]+$/.test(tempAerodromeCompleteData)) {
        // Faz a PRIMEIRA chamada dos dados BÁSICOS do Aeródromo do BANCO DE DADOS.
        Promise.all([
          returnRequestAerodromeCompleteData = await this._getAerodromeCompleteDataByAerodromeIcao(xAerodromeIcao)
        ]);

        // TRATAMENTO PARA PROCESSOS ASSÍNCRONO, VIA KAFKA.
        // VAI CHEGAR AO FINAL DO MÉTODO E EXECUTAR ESTE PROCESSO DEPOIS, POR ISTO TEM O PROCESSO DE RETORNO INTERNO.
        if (returnRequestAerodromeCompleteData.status_code == HTTP_STATUS.ACCEPTED) {
          let tempCounter = 1;

          if (returnRequestAerodromeCompleteData.data_info && returnRequestAerodromeCompleteData.data_info.process_id && returnRequestAerodromeCompleteData.data_info.process_id.length == 36) {
            const refreshId = setInterval(async () => {
              // COLOQUEI O TRY/CATCH PARA TRATAR EXCEÇÕES QUE PRECISAM INTERROMPER AS TENTATIVAS
              try {
                let returnRequestKafkaService: any;

                // TRÁS O REGISTRO DO SERVIÇO KAFKA QUE VAI SER ATUALIZADO DE FORMA ASSÍNCRONA.
                Promise.all([
                  returnRequestKafkaService = await this._getKafkaServiceDataByIdFromService(returnRequestAerodromeCompleteData.data_info.process_id)
                ]);

                if (returnRequestKafkaService.status_code == HTTP_STATUS.OK) { // Resultado da Consulta no Banco do Kafka Service
                  // --- ANALISE DOS DADOS DO KAFKA SERVICE -- //
                  if (returnRequestKafkaService.data_info.data[0].status_code == HTTP_STATUS.OK || returnRequestKafkaService.data_info.data[0].status_code == HTTP_STATUS.CREATED) {
                    this.messageService.add({
                      key: 'toast2',
                      severity: 'success',
                      summary: 'Dados Completos de Aeródromo',
                      detail: "Dado Completos do Aeródromo recuperados com sucesso."
                    });

                    this.objAerodromeComplete[`${xAerodromeTarget}`] = "OK";

                    //returnRequestAerodromeCompleteData = await this._getAerodromeCompleteDataByAerodromeIcao(xAerodromeIcao);
                    //await this._processCompleteAerodrome(returnRequestAerodromeCompleteData, xAerodromeTarget);
                  } else if (returnRequestKafkaService.data_info.data[0].status_code == HTTP_STATUS.BAD_REQUEST) {
                    this.objAerodromesError[`${xAerodromeTarget}`]['completeData'] = "ERRO DE REQUISIÇÃO (SN)";

                    //TODO: TRANSLATION
                    //TODO: MESSENGER
                    //TODO: ERROR - LOG
                    //TODO: SEVERITY
                    this.messageService.add({
                      key: 'toast2',
                      severity: 'error',
                      summary: 'Dados Completos de Aeródromo',
                      detail: "Exceção ao Recuperar os Dados Completos de Aeródromo (SN)."
                    });
                  } else if (returnRequestKafkaService.data_info.data[0].status_code == HTTP_STATUS.NOT_FOUND) {
                    this.objAerodromesError[`${xAerodromeTarget}`]['completeData'] = "NÃO EXISTENTE NO AISWEB";

                    //TODO: TRANSLATION
                    //TODO: MESSENGER
                    //TODO: ERROR - LOG
                    //TODO: SEVERITY
                    this.messageService.add({
                      key: 'toast2',
                      severity: 'error',
                      summary: 'Dados Completos de Aeródromo',
                      detail: "Exceção ao Recuperar os Dados Completos de Aeródromo (NE)."
                    });
                  } else if (returnRequestKafkaService.data_info.data[0].status_code == HTTP_STATUS.INTERNAL_SERVER_ERROR) {
                    this.objAerodromesError[`${xAerodromeTarget}`]['completeData'] = "ERRO NO SINCRONISMO";

                    //TODO: TRANSLATION
                    //TODO: MESSENGER
                    //TODO: ERROR - LOG
                    //TODO: SEVERITY
                    this.messageService.add({
                      key: 'toast2',
                      severity: 'error',
                      summary: 'Dados Completos de Aeródromo',
                      detail: "Exceção ao Recuperar os Dados Completos de Aeródromo (SC)."
                    });
                  }
                } else if (returnRequestKafkaService.status_code == HTTP_STATUS.NOT_FOUND) {
                  //this.objAerodromesError[`${xAerodromeTarget}`]['completeData'] = "ERRO SINCRONISMO (NF)";

                  //TODO: TRANSLATION
                  //TODO: MESSENGER
                  //TODO: ERROR - LOG
                  //TODO: SEVERITY
                  this.messageService.add({
                    key: 'toast2',
                    severity: 'error',
                    summary: 'Dados Completos de Aeródromo',
                    detail: "Exceção ao Recuperar os Dados Completos de Aeródromo (NF)."
                  });
                } else if (returnRequestKafkaService.status_code == HTTP_STATUS.BAD_REQUEST) {
                  //this.objAerodromesError[`${xAerodromeTarget}`]['completeData'] = "ERRO SINCRONISMO (BR)";

                  //TODO: TRANSLATION
                  //TODO: MESSENGER
                  //TODO: ERROR - LOG
                  //TODO: SEVERITY
                  this.messageService.add({
                    key: 'toast2',
                    severity: 'error',
                    summary: 'Dados Completos de Aeródromo',
                    detail: "Exceção ao Recuperar os Dados Completos de Aeródromo (BR)."
                  });
                } else if (returnRequestKafkaService.status_code == HTTP_STATUS.REQUEST_TIMEOUT) {
                  //this.objAerodromesError[`${xAerodromeTarget}`]['completeData'] = "ERRO SINCRONISMO (TO)";

                  //TODO: TRANSLATION
                  //TODO: MESSENGER
                  //TODO: ERROR - LOG
                  //TODO: SEVERITY
                  this.messageService.add({
                    key: 'toast2',
                    severity: 'error',
                    summary: 'Dados Completos de Aeródromo',
                    detail: "Exceção ao Recuperar os Dados Completos de Aeródromo (TO)."
                  });
                } else {
                  //this.objAerodromesError[`${xAerodromeTarget}`]['completeData'] = "ERRO SINCRONISMO (EX)";

                  //TODO: TRANSLATION
                  //TODO: MESSENGER
                  //TODO: ERROR - LOG
                  //TODO: SEVERITY
                  this.messageService.add({
                    key: 'toast2',
                    severity: 'error',
                    summary: 'Dados Completos de Aeródromo',
                    detail: "Exceção ao Recuperar os Dados Completos de Aeródromo (EX)."
                  });
                }

                //  - ATINGIU O MÁXIMO DE TENTATIVAS                - HOUVE UMA RESPOSTA DO PROCESSO E TUDO CERTO    
                if (tempCounter >= settingConfig.NUMBER_ATTEMPTS || returnRequestKafkaService.data_info.data[0].status_code != HTTP_STATUS.ACCEPTED) {
                  if (returnRequestKafkaService.data_info.data[0].status_code == HTTP_STATUS.ACCEPTED) {

                    if (this.objAerodromesError[`${xAerodromeTarget}`]) {
                      //this.objAerodromesError[`${xAerodromeTarget}`]['completeData'] = "ERRO SINCRONISMO (CC)";

                      //TODO: TRANSLATION
                      //TODO: MESSENGER
                      //TODO: ERROR - LOG
                      //TODO: SEVERITY
                      this.messageService.add({
                        key: 'toast2',
                        severity: 'error',
                        summary: 'Dados Completos de Aeródromo',
                        detail: "Exceção ao Recuperar os Dados Completos de Aeródromo (CC)."
                      });
                    }
                  }

                  clearInterval(refreshId);
                }

                tempCounter++;
              } catch (xException) {
                this.messageService.add({
                  key: 'toast2',
                  severity: 'info',
                  summary: 'Dados Completos de Aeródromo',
                  detail: "Exceção no Sincronismo (CC)"
                });

                clearInterval(refreshId);
              }
            }, settingConfig.INTERVAL_ATTEMPTS);
          } else {
            this.messageService.add({
              key: 'toast2',
              severity: 'info',
              summary: 'Dados Completos de Aeródromo',
              detail: "Exceção no Sincronismo (NI)"
            });
          }
        }

        // --- ANALISE DO RETORNO DOS DADOS BÁSICO DO AERÓDROMO -- //        
        if (returnRequestAerodromeCompleteData.status_code == HTTP_STATUS.OK || returnRequestAerodromeCompleteData.status_code == HTTP_STATUS.CREATED) {
          this.messageService.add({
            key: 'toast2',
            severity: 'success',
            summary: 'Dados Completos de Aeródromo',
            detail: " recuperados com sucesso."
          });

          this.objAerodromeComplete[`${xAerodromeTarget}`] = "OK";

          //await this._processCompleteAerodrome(returnRequestAerodromeCompleteData, xAerodromeTarget);
        } else if (returnRequestAerodromeCompleteData.status_code == HTTP_STATUS.NOT_FOUND) {
          //this.objAerodromesError[`${xAerodromeTarget}`]['completeData'] = "ERRO CONSULTA (NF)";

          //TODO: TRANSLATION
          //TODO: MESSENGER
          //TODO: ERROR - LOG
          //TODO: SEVERITY
          this.messageService.add({
            key: 'toast2',
            severity: 'error',
            summary: 'Dados Completos de Aeródromo',
            detail: "Exceção ao Recuperar os Dados Completos de Aeródromo (NF)."
          });
        } else if (returnRequestAerodromeCompleteData.status_code == HTTP_STATUS.BAD_REQUEST) {
          //this.objAerodromesError[`${xAerodromeTarget}`]['completeData'] = "ERRO CONSULTA (BR)";

          //TODO: TRANSLATION
          //TODO: MESSENGER
          //TODO: ERROR - LOG
          //TODO: SEVERITY
          this.messageService.add({
            key: 'toast2',
            severity: 'error',
            summary: 'Dados Completos de Aeródromo',
            detail: "Exceção ao Recuperar os Dados Completos de Aeródromo (BR)."
          });
        } else if (returnRequestAerodromeCompleteData.status_code == HTTP_STATUS.INTERNAL_SERVER_ERROR) {
          //this.objAerodromesError[`${xAerodromeTarget}`]['completeData'] = "EXCEÇÃO CONSULTA (EX)";

          //TODO: TRANSLATION
          //TODO: MESSENGER
          //TODO: ERROR - LOG
          //TODO: SEVERITY
          this.messageService.add({
            key: 'toast2',
            severity: 'error',
            summary: 'Dados Completos de Aeródromo',
            detail: "Exceção ao Recuperar os Dados Completos de Aeródromo (EX)."
          });
        }
      }
    } catch (xException: any) {
      console.log("ZZZZZZZZZZZZ", xException)

      if (xException.status_code == 0) {
        //this.objAerodromesError[`${xAerodromeTarget}`]['completeData'] = "ERRO CONEXÃO CONSULTA";

        //TODO: TRANSLATION
        //TODO: MESSENGER
        //TODO: ERROR - LOG
        //TODO: SEVERITY
        this.messageService.add({
          key: 'toast2',
          severity: 'error',
          summary: 'Dados Completos de Aeródromo',
          detail: "Exceção ao Recuperar os Dados Completos de Aeródromo (CX)."
        });
      } else {
        //this.objAerodromesError[`${xAerodromeTarget}`]['completeData'] = "ERRO CONSULTA"

        //TODO: TRANSLATION
        //TODO: MESSENGER
        //TODO: ERROR - LOG
        //TODO: SEVERITY
        this.messageService.add({
          key: 'toast2',
          severity: 'error',
          summary: 'Dados Completos de Aeródromo',
          detail: "Exceção ao Recuperar os Dados Completos de Aeródromo (CS)."
        });
      }
    }
  }

  /**
   * TODO: https://tsdoc.org/
   */
  private async _aerodromeObstaclesData(xAerodromeIcao: string, xAerodromeTarget: string) {
    try {
      if (xAerodromeIcao.trim().length == 4 && /^[A-Z]/.test(xAerodromeIcao.trim().toUpperCase()) && /^[A-Z0-9\-]+$/.test(xAerodromeIcao.toUpperCase())) {
        let returnRequestObstaclesData: any;

        // Faz a chamada dos dados do OBSTÁCULOS do Aeródromo do BANCO DE DADOS.
        Promise.all([
          returnRequestObstaclesData = await this._getAerodromeObstaclesDataByAerodromeIcao(xAerodromeIcao)
        ]);

        // TRATAMENTO PARA PROCESSOS ASSÍNCRONOS.
        if (returnRequestObstaclesData.status_code == HTTP_STATUS.ACCEPTED) {
          let tempCounter = 0;

          if (returnRequestObstaclesData.data_info && returnRequestObstaclesData.data_info.process_id && returnRequestObstaclesData.data_info.process_id.length == 36) {
            const refreshId = setInterval(async () => {
              // TRÁS O REGISTRO DO SERVIÇO KAFKA QUE VAI SER ATUALIZADO DE FORMA ASSÍNCRONA.
              const returnRequestKafkaService = await this._getKafkaServiceDataByIdFromService(returnRequestObstaclesData.data_info.process_id);

              // --- ANALISE DOS DADOS DO KAFKA SERVICE -- //
              if (returnRequestKafkaService.data_info.data[0].status == HTTP_STATUS.OK || returnRequestKafkaService.data_info.data[0].status == HTTP_STATUS.CREATED) {
                returnRequestObstaclesData = await this._getAerodromeObstaclesDataByAerodromeIcao(xAerodromeIcao);

                this._processObstaclesAerodrome(returnRequestObstaclesData, xAerodromeTarget);
              } else if (returnRequestKafkaService.data_info.data[0].status == HTTP_STATUS.BAD_REQUEST) {
                this.messageService.add({ key: 'toast2', severity: 'error', summary: 'Obstáculos de Aeródromo', detail: "Erro ao Recuperar Obstáculos do Aeródromo" });
              } else if (returnRequestKafkaService.data_info.data[0].status == HTTP_STATUS.NOT_FOUND) {
                this.messageService.add({ key: 'toast2', severity: 'error', summary: 'Obstáculos de Aeródromo', detail: `AERÓDROMO NÃO EXISTENTE NA BASE DA AISWEB ${xAerodromeIcao}` });
              } else if (returnRequestKafkaService.data_info.data[0].status == HTTP_STATUS.INTERNAL_SERVER_ERROR) {
                this.messageService.add({ key: 'toast2', severity: 'error', summary: 'Obstáculos de Aeródromo', detail: `EXCEÇÃO NA RECUPERAÇÃO DOS OBSTÁCULOS DO AERÓDROMO: ${xAerodromeIcao}` });
              }

              if (tempCounter > settingConfig.NUMBER_ATTEMPTS || returnRequestKafkaService.data_info.data[0].status != HTTP_STATUS.ACCEPTED) {
                clearInterval(refreshId);
              }

              tempCounter++;
            }, settingConfig.INTERVAL_ATTEMPTS);
          }
        }

        // --- ANALISE DO RETORNO DOS DADOS BÁSICO DO AERÓDROMO -- //
        if (returnRequestObstaclesData.status_code == HTTP_STATUS.OK || returnRequestObstaclesData.status_code == HTTP_STATUS.CREATED) {
          this._processObstaclesAerodrome(returnRequestObstaclesData, xAerodromeTarget);
        } else if (returnRequestObstaclesData.status_code == HTTP_STATUS.NOT_FOUND) {
          this.messageService.add({ key: 'toast2', severity: 'error', summary: 'Obstáculos de Aeródromo', detail: `AERÓDROMO NÃO EXISTENTE NA BASE DA AISWEB ${xAerodromeTarget}` });
        } else if (returnRequestObstaclesData.status_code == HTTP_STATUS.BAD_REQUEST) {
          this.messageService.add({ key: 'toast2', severity: 'error', summary: 'Obstáculos de Aeródromo', detail: "Erro ao Recuperar Obstáculos de Aeródromo" });
        } else if (returnRequestObstaclesData.status_code == HTTP_STATUS.INTERNAL_SERVER_ERROR) {
          this.messageService.add({ key: 'toast2', severity: 'error', summary: 'Obstáculos de Aeródromo', detail: "Exceção ao Recuperar Obstáculos de Aeródromo" });
        }
      }
    } catch (xError) {
      //TODO: TRANSLATION
      //TODO: MESSENGER
      //TODO: ERROR - LOG
      //TODO: SEVERITY
      this.messageService.add({ key: 'toast2', severity: 'error', summary: 'Dados do Obstáculos do Aeródromo', detail: "Exceção ao Recuperar os Dados do Obstáculos do Aeródromo." });
    }
  }

  /**
   * TODO: https://tsdoc.org/
   */
  private async _aerodromeNotamOnline(xAerodromeIcao: string, xAerodromeTarget: string) {
    try {
      if (xAerodromeIcao.trim().length == 4 && /^[A-Z]/.test(xAerodromeIcao.trim().toUpperCase()) && /^[A-Z0-9\-]+$/.test(xAerodromeIcao.toUpperCase())) {
        let returnRequestNotamOnline: any;

        // Faz a chamada dos dados do NOTAM do Aeródromo ONLINE.
        Promise.all([
          returnRequestNotamOnline = await this._getAerodromeNotamOnlineByAerodromeIcao(xAerodromeIcao)
        ]);

        // TRATAMENTO PARA PROCESSOS ASSÍNCRONOS.
        if (returnRequestNotamOnline.status_code == HTTP_STATUS.ACCEPTED) {
          let tempCounter = 0;

          if (returnRequestNotamOnline.data_info && returnRequestNotamOnline.data_info.process_id && returnRequestNotamOnline.data_info.process_id.length == 36) {
            const refreshId = setInterval(async () => {
              // TRÁS O REGISTRO DO SERVIÇO KAFKA QUE VAI SER ATUALIZADO DE FORMA ASSÍNCRONA.
              const returnRequestKafkaService = await this._getKafkaServiceDataByIdFromService(returnRequestNotamOnline.data_info.process_id);

              // --- ANALISE DOS DADOS DO KAFKA SERVICE -- //
              if (returnRequestKafkaService.data_info.data[0].status == HTTP_STATUS.OK || returnRequestKafkaService.data_info.data[0].status == HTTP_STATUS.CREATED) {
                returnRequestNotamOnline = await this._getAerodromeNotamOnlineByAerodromeIcao(xAerodromeIcao);

                this._processNotamAerodrome(returnRequestNotamOnline, xAerodromeTarget);
              } else if (returnRequestKafkaService.data_info.data[0].status == HTTP_STATUS.BAD_REQUEST) {
                this.messageService.add({ key: 'toast2', severity: 'error', summary: 'NOTAM do Aeródromo', detail: "Erro ao Recuperar NOTAM do Aeródromo" });
              } else if (returnRequestKafkaService.data_info.data[0].status == HTTP_STATUS.NOT_FOUND) {
                this.messageService.add({ key: 'toast2', severity: 'error', summary: 'NOTAM do Aeródromo', detail: `AERÓDROMO NÃO EXISTENTE NA BASE DA AISWEB ${xAerodromeIcao}` });
              } else if (returnRequestKafkaService.data_info.data[0].status == HTTP_STATUS.INTERNAL_SERVER_ERROR) {
                this.messageService.add({ key: 'toast2', severity: 'error', summary: 'NOTAM do Aeródromo', detail: `EXCEÇÃO NA RECUPERAÇÃO NOTAM DO AERÓDROMO: ${xAerodromeIcao}` });
              }

              if (tempCounter > settingConfig.NUMBER_ATTEMPTS || returnRequestKafkaService.data_info.data[0].status != HTTP_STATUS.ACCEPTED) {
                clearInterval(refreshId);
              }

              tempCounter++;
            }, settingConfig.INTERVAL_ATTEMPTS);
          }
        }

        // --- ANALISE DO RETORNO DOS DADOS BÁSICO DO AERÓDROMO -- //
        if (returnRequestNotamOnline.status_code == HTTP_STATUS.OK || returnRequestNotamOnline.status_code == HTTP_STATUS.CREATED) {
          this._processNotamAerodrome(returnRequestNotamOnline, xAerodromeTarget);
        } else if (returnRequestNotamOnline.status_code == HTTP_STATUS.NOT_FOUND) {
          this.messageService.add({ key: 'toast2', severity: 'error', summary: 'NOTAM do Aeródromo', detail: `AERÓDROMO NÃO EXISTENTE NA BASE DA AISWEB ${xAerodromeTarget}` });
        } else if (returnRequestNotamOnline.status_code == HTTP_STATUS.BAD_REQUEST) {
          this.messageService.add({ key: 'toast2', severity: 'error', summary: 'NOTAM do  Aeródromo', detail: "Erro ao Recuperar NOTAM do Aeródromo" });
        } else if (returnRequestNotamOnline.status_code == HTTP_STATUS.INTERNAL_SERVER_ERROR) {
          this.messageService.add({ key: 'toast2', severity: 'error', summary: 'NOTAM do  Aeródromo', detail: "Exceção ao Recuperar NOTAM do Aeródromo" });
        }
      }
    } catch (xError) {
      //TODO: TRANSLATION
      //TODO: MESSENGER
      //TODO: ERROR - LOG
      //TODO: SEVERITY
      this.messageService.add({ key: 'toast2', severity: 'error', summary: 'Dados do NOTAM de Aeródromo', detail: "Exceção ao Recuperar os Dados do NOTAM do Aeródromo." });
    }
  }

  /**
   * TODO: https://tsdoc.org/
   */
  private async _aerodromeMeteorologyOnline(xAerodromeIcao: string, xAerodromeTarget: string) {
    try {
      if (xAerodromeIcao.trim().length == 4 && /^[A-Z]/.test(xAerodromeIcao.trim().toUpperCase()) && /^[A-Z0-9\-]+$/.test(xAerodromeIcao.toUpperCase())) {
        let returnRequestMeteorologyOnline: any;

        // Faz a chamada dos dados do METEOROLOGIA do Aeródromo ONLINE.
        Promise.all([
          returnRequestMeteorologyOnline = await this._getAerodromeMeteorologyOnlineByAerodromeIcao(xAerodromeIcao)
        ]);

        // TRATAMENTO PARA PROCESSOS ASSÍNCRONOS.
        if (returnRequestMeteorologyOnline.status_code == HTTP_STATUS.ACCEPTED) {
          let tempCounter = 0;

          if (returnRequestMeteorologyOnline.data_info && returnRequestMeteorologyOnline.data_info.process_id && returnRequestMeteorologyOnline.data_info.process_id.length == 36) {
            const refreshId = setInterval(async () => {
              // TRÁS O REGISTRO DO SERVIÇO KAFKA QUE VAI SER ATUALIZADO DE FORMA ASSÍNCRONA.
              const returnRequestKafkaService = await this._getKafkaServiceDataByIdFromService(returnRequestMeteorologyOnline.data_info.process_id);

              // --- ANALISE DOS DADOS DO KAFKA SERVICE -- //
              if (returnRequestKafkaService.data_info.data[0].status == HTTP_STATUS.OK || returnRequestKafkaService.data_info.data[0].status == HTTP_STATUS.CREATED) {
                returnRequestMeteorologyOnline = await this._getAerodromeMeteorologyOnlineByAerodromeIcao(xAerodromeIcao)

                this._processMeteorologyAerodrome(returnRequestMeteorologyOnline, xAerodromeTarget);
              } else if (returnRequestKafkaService.data_info.data[0].status == HTTP_STATUS.BAD_REQUEST) {
                this.messageService.add({ key: 'toast2', severity: 'error', summary: 'Meteorologia do Aeródromo', detail: "Erro ao Recuperar Meteorologia do Aeródromo" });
              } else if (returnRequestKafkaService.data_info.data[0].status == HTTP_STATUS.NOT_FOUND) {
                this.messageService.add({ key: 'toast2', severity: 'error', summary: 'Meteorologia do Aeródromo', detail: `AERÓDROMO NÃO EXISTENTE NA BASE DA AISWEB ${xAerodromeIcao}` });
              } else if (returnRequestKafkaService.data_info.data[0].status == HTTP_STATUS.INTERNAL_SERVER_ERROR) {
                this.messageService.add({ key: 'toast2', severity: 'error', summary: 'Meteorologia do Aeródromo', detail: `EXCEÇÃO NA RECUPERAÇÃO DA METEOROLOGIA DO AERÓDROMO: ${xAerodromeIcao}` });
              }

              if (tempCounter > settingConfig.NUMBER_ATTEMPTS || returnRequestKafkaService.data_info.data[0].status != HTTP_STATUS.ACCEPTED) {
                clearInterval(refreshId);
              }

              tempCounter++;
            }, settingConfig.INTERVAL_ATTEMPTS);
          }
        }

        // --- ANALISE DO RETORNO DE METEOROLOGIA AERÓDROMO -- //
        if (returnRequestMeteorologyOnline.status_code == HTTP_STATUS.OK || returnRequestMeteorologyOnline.status_code == HTTP_STATUS.CREATED) {
          this._processMeteorologyAerodrome(returnRequestMeteorologyOnline, xAerodromeTarget);
        } else if (returnRequestMeteorologyOnline.status_code == HTTP_STATUS.NOT_FOUND) {
          this.messageService.add({ key: 'toast2', severity: 'error', summary: 'Meteorologia do Aeródromo', detail: `AERÓDROMO NÃO EXISTENTE NA BASE DA AISWEB ${xAerodromeTarget}` });
        } else if (returnRequestMeteorologyOnline.status_code == HTTP_STATUS.BAD_REQUEST) {
          this.messageService.add({ key: 'toast2', severity: 'error', summary: 'Meteorologia do  Aeródromo', detail: "Erro ao Recuperar Meteorologia do Aeródromo" });
        } else if (returnRequestMeteorologyOnline.status_code == HTTP_STATUS.INTERNAL_SERVER_ERROR) {
          this.messageService.add({ key: 'toast2', severity: 'error', summary: 'Meteorologia do  Aeródromo', detail: "Exceção ao Recuperar Meteorologia do Aeródromo" });
        }
      }
    } catch (xError) {
      //TODO: TRANSLATION
      //TODO: MESSENGER
      //TODO: ERROR - LOG
      //TODO: SEVERITY
      this.messageService.add({ key: 'toast2', severity: 'error', summary: 'Dados de Meteorologia de Aeródromo', detail: "Exceção ao Recuperar os Dados de Meteorologia do Aeródromo." });
    }
  }

  /**
   * TODO: https://tsdoc.org/
   */
  private async _getAerodromeBasicDataByAerodromeIcao(xAerodromeIcao: string, xAerodromeTarget: string, xForceReturn: string = null): Promise<IPatternResponseFromAPI> {
    return new Promise(async (resolve, reject) => {

      let returnRequest: IPatternResponseFromAPI;

      try {
        await this.flightPlanApisService.getAerodromeBasicDataByAerodromeIcao(xAerodromeIcao, xForceReturn).subscribe({
          next: (xResponseService: IPatternResponseFromAPI) => {
            returnRequest = xResponseService;

            if (xResponseService.status_code == HTTP_STATUS.ACCEPTED) {
              // TODO: RETIRAR, DEIXAR SOMENTE PARA TESTES....
              this.messageService.add({ key: 'toast2', severity: 'success', summary: 'Recuperar Dados Básicos de Aeródromo', detail: "01 - SUCESSO AO CONSULTAR O SERVIDOR - RECUPERAR DADOS BASICOS" });
            } else if (xResponseService.status_code == HTTP_STATUS.OK) {
              this._aerodromeCompleteData(xAerodromeIcao, xAerodromeTarget); // PARA ADIANTAR O SERVIÇO, EU JÁ DISPARO A BUSCA PELOS DADOS COMPLETOS DO AERODROMO.
              this.messageService.add({ key: 'toast2', severity: 'info', summary: 'Recuperar Dados Básicos de Aeródromo', detail: "01 - AGUARDANDO PROCESSO" });
            }

            resolve(returnRequest);
          },
          error: (xErrorService: any) => {
            returnRequest = xErrorService;

            if (xErrorService.status == 0) {
              this.messageService.add({ key: 'toast2', severity: 'error', summary: 'Recuperar Dados Básicos de Aeródromo', detail: "Erro ao fazer a conexão com o Servidor." });
            } else {
              this.messageService.add({ key: 'toast2', severity: 'error', summary: 'Recuperar Dados Básicos de Aeródromo', detail: "Erro ao Recuperar os Dados Básicos de Aeródromo" });
            }

            reject(returnRequest);
          }
        });
      } catch (xError) {
        returnRequest.status_code = HTTP_STATUS.INTERNAL_SERVER_ERROR;
        returnRequest.status = "EXCEPTION";

        //TODO: TRANSLATION
        //TODO: MESSENGER
        //TODO: ERROR - LOG
        //TODO: SEVERITY
        this.messageService.add({ key: 'toast2', severity: 'error', summary: 'Recuperar Dados Básicos de Aeródromo', detail: "Exceção ao Recuperar os Dados Básicos de Aeródromo" });

        reject(returnRequest);
      }
    });
  }

  /**
   * TODO: https://tsdoc.org/
   */
  private async _getAerodromeCompleteDataByAerodromeIcao(xAerodromeIcao: string, xForceReturn: string = null): Promise<IPatternResponseFromAPI> {
    return new Promise(async (resolve, reject) => {

      let returnRequest: IPatternResponseFromAPI;

      try {
        await this.flightPlanApisService.getAerodromeCompleteDataByAerodromeIcao(xAerodromeIcao, xForceReturn).subscribe({
          next: (xResponseService: IPatternResponseFromAPI) => {
            returnRequest = xResponseService;

            // TODO: RETIRAR, DEIXAR SOMENTE PARA TESTES....
            if (xResponseService.status_code == HTTP_STATUS.ACCEPTED) {
              this.messageService.add({ key: 'toast2', severity: 'success', summary: 'Recuperar Dados Completos de Aeródromo', detail: "SUCESSO AO RECUPERAR" });
            } else if (xResponseService.status_code == HTTP_STATUS.OK) {
              this.messageService.add({ key: 'toast2', severity: 'info', summary: 'Recuperar Dados Completos de Aeródromo', detail: "02 - AGUARDANDO PROCESSO" });
            }

            resolve(returnRequest);
          },
          error: (xErrorService: any) => {
            returnRequest = xErrorService;

            if (xErrorService.status == 0) {
              this.messageService.add({ key: 'toast2', severity: 'error', summary: 'Recuperar Dados Completos de Aeródromo', detail: "Erro ao fazer a conexão com o Servidor." });
            } else {
              this.messageService.add({ key: 'toast2', severity: 'error', summary: 'Recuperar Dados Completos de Aeródromo', detail: "Erro ao Recuperar os Dados Completos de Aeródromo" });
            }

            resolve(returnRequest);
          }
        });
      } catch (xError) {
        returnRequest.status_code = HTTP_STATUS.INTERNAL_SERVER_ERROR;
        returnRequest.status = "EXCEPTION";

        //TODO: TRANSLATION
        //TODO: MESSENGER
        //TODO: ERROR - LOG
        //TODO: SEVERITY
        this.messageService.add({ key: 'toast2', severity: 'error', summary: 'Recuperar Dados Completos de Aeródromo', detail: "Exceção ao Recuperar os Dados Completos de Aeródromo" });

        reject(returnRequest);
      }
    });
  }

  /**
   * TODO: https://tsdoc.org/
   */
  private async _getAerodromeObstaclesDataByAerodromeIcao(xAerodromeIcao: string, xForceReturn: string = null): Promise<IPatternResponseFromAPI> {
    return new Promise(async (resolve, reject) => {

      let returnRequest: IPatternResponseFromAPI;

      try {
        await this.flightPlanApisService.getAerodromeObstaclesDataByAerodromeIcao(xAerodromeIcao, xForceReturn).subscribe({
          next: (xResponseService: IPatternResponseFromAPI) => {
            returnRequest = xResponseService;

            // TODO: RETIRAR, DEIXAR SOMENTE PARA TESTES....
            if (xResponseService.status_code == HTTP_STATUS.ACCEPTED) {
              this.messageService.add({ key: 'toast2', severity: 'success', summary: 'Recuperar Obstáculos do Aeródromo', detail: "SUCESSO AO RECUPERAR" });
            } else if (xResponseService.status_code == HTTP_STATUS.OK) {
              this.messageService.add({ key: 'toast2', severity: 'info', summary: 'Recuperar Obstáculos do Aeródromo', detail: "03 - AGUARDANDO PROCESSO" });
            }

            resolve(returnRequest);
          },
          error: (xErrorService: any) => {
            returnRequest = xErrorService;

            if (xErrorService.status == 0) {
              this.messageService.add({ key: 'toast2', severity: 'error', summary: 'Recuperar Obstáculos do Aeródromo', detail: "Erro ao fazer a conexão com o Servidor." });
            } else {
              this.messageService.add({ key: 'toast2', severity: 'error', summary: 'Recuperar Obstáculos do Aeródromo', detail: "Erro ao Recuperar os Obstáculos do Aeródromo" });
            }

            resolve(returnRequest);
          }
        });
      } catch (xError) {
        returnRequest.status_code = HTTP_STATUS.INTERNAL_SERVER_ERROR;
        returnRequest.status = "EXCEPTION";

        //TODO: TRANSLATION
        //TODO: MESSENGER
        //TODO: ERROR - LOG
        //TODO: SEVERITY
        this.messageService.add({ key: 'toast2', severity: 'error', summary: 'Recuperar Obstáculos do Aeródromo', detail: "Exceção ao Recuperar os Obstáculos do Aeródromo" });

        reject(returnRequest);
      }
    });
  }

  /**
   * TODO: https://tsdoc.org/
   */
  private async _getAerodromeNotamOnlineByAerodromeIcao(xAerodromeIcao: string, xForceReturn: string = null): Promise<IPatternResponseFromAPI> {
    return new Promise(async (resolve, reject) => {

      let returnRequest: IPatternResponseFromAPI;

      try {
        await this.flightPlanApisService.getAerodromeNotamOnlineByAerodromeIcao(xAerodromeIcao, xForceReturn).subscribe({
          next: (xResponseService: IPatternResponseFromAPI) => {
            returnRequest = xResponseService;

            // TODO: RETIRAR, DEIXAR SOMENTE PARA TESTES....
            if (xResponseService.status_code == HTTP_STATUS.ACCEPTED) {
              this.messageService.add({ key: 'toast2', severity: 'success', summary: 'Recuperar NOTAM do Aeródromo', detail: "SUCESSO AO RECUPERAR" });
            } else if (xResponseService.status_code == HTTP_STATUS.OK) {
              this.messageService.add({ key: 'toast2', severity: 'info', summary: 'Recuperar NOTAM do Aeródromo', detail: "04 - AGUARDANDO PROCESSO" });
            }

            resolve(returnRequest);
          },
          error: (xErrorService: any) => {
            returnRequest = xErrorService;

            if (xErrorService.status == 0) {
              this.messageService.add({ key: 'toast2', severity: 'error', summary: 'Recuperar NOTAM do Aeródromo', detail: "Erro ao fazer a conexão com o Servidor." });
            } else {
              this.messageService.add({ key: 'toast2', severity: 'error', summary: 'Recuperar NOTAM do Aeródromo', detail: "Erro ao Recuperar NOTAM do Aeródromo" });
            }

            resolve(returnRequest);
          }
        });
      } catch (xError) {
        returnRequest.status_code = HTTP_STATUS.INTERNAL_SERVER_ERROR;
        returnRequest.status = "EXCEPTION";

        //TODO: TRANSLATION
        //TODO: MESSENGER
        //TODO: ERROR - LOG
        //TODO: SEVERITY
        this.messageService.add({ key: 'toast2', severity: 'error', summary: 'Recuperar NOTAM do Aeródromo', detail: "Exceção ao Recuperar NOTAM do Aeródromo" });

        reject(returnRequest);
      }
    });
  }

  /**
   * TODO: https://tsdoc.org/
   */
  private async _getAerodromeMeteorologyOnlineByAerodromeIcao(xAerodromeIcao: string, xForceReturn: string = null): Promise<IPatternResponseFromAPI> {
    return new Promise(async (resolve, reject) => {

      let returnRequest: IPatternResponseFromAPI;

      try {
        await this.flightPlanApisService.getAerodromeMeteorologyOnlineByAerodromeIcao(xAerodromeIcao).subscribe({
          next: (xResponseService: IPatternResponseFromAPI) => {
            returnRequest = xResponseService;

            // TODO: RETIRAR, DEIXAR SOMENTE PARA TESTES....
            if (xResponseService.status_code == HTTP_STATUS.ACCEPTED) {
              this.messageService.add({ key: 'toast2', severity: 'success', summary: 'Recuperar Meteorologia do Aeródromo', detail: "SUCESSO AO RECUPERAR" });
            } else if (xResponseService.status_code == HTTP_STATUS.OK) {
              this.messageService.add({ key: 'toast2', severity: 'info', summary: 'Recuperar Meteorologia do Aeródromo', detail: "05 - AGUARDANDO PROCESSO" });
            }

            resolve(returnRequest);
          },
          error: (xErrorService: any) => {
            returnRequest = xErrorService;

            if (xErrorService.status == 0) {
              this.messageService.add({ key: 'toast2', severity: 'error', summary: 'Recuperar Meteorologia do Aeródromo', detail: "Erro ao fazer a conexão com o Servidor." });
            } else {
              this.messageService.add({ key: 'toast2', severity: 'error', summary: 'Recuperar Meteorologia do Aeródromo', detail: "Erro ao Meteorologia do Aeródromo" });
            }

            resolve(returnRequest);
          }
        });
      } catch (xError) {
        returnRequest.status_code = HTTP_STATUS.INTERNAL_SERVER_ERROR;
        returnRequest.status = "EXCEPTION";

        //TODO: TRANSLATION
        //TODO: MESSENGER
        //TODO: ERROR - LOG
        //TODO: SEVERITY
        this.messageService.add({ key: 'toast2', severity: 'error', summary: 'Recuperar Meteorologia do Aeródromo', detail: "Exceção ao Meteorologia do Aeródromo" });

        reject(returnRequest);
      }
    });
  }

  /**
   * TODO: https://tsdoc.org/
   */
  private async _getKafkaServiceDataByIdFromService(xKafkaServiceId: string): Promise<IPatternResponseFromAPI> {
    return new Promise(async (resolve, reject) => {

      let returnRequest: IPatternResponseFromAPI;

      try {
        await this.flightPlanApisService.getKafkaServiceDataById(xKafkaServiceId).subscribe({
          next: (xResponseService: IPatternResponseFromAPI) => {
            returnRequest = xResponseService;

            // TODO: RETIRAR, DEIXAR SOMENTE PARA TESTES....
            if (xResponseService.status_code == HTTP_STATUS.ACCEPTED) {
              this.messageService.add({ key: 'toast2', severity: 'success', summary: 'Recuperar Kafka Service', detail: "SUCESSO AO RECUPERAR" });
            } else if (xResponseService.status_code == HTTP_STATUS.OK) {
              this.messageService.add({ key: 'toast2', severity: 'info', summary: 'Recuperar Kafka Service', detail: "06 - AGUARDANDO PROCESSO" });
            }

            resolve(returnRequest);
          },
          error: (xErrorService: any) => {
            returnRequest = xErrorService;

            if (xErrorService.status == 0) {
              this.messageService.add({ key: 'toast2', severity: 'error', summary: 'Recuperar Kafka Service', detail: "Erro ao fazer a conexão com o Servidor." });
            } else {
              this.messageService.add({ key: 'toast2', severity: 'error', summary: 'Recuperar Kafka Service', detail: "Erro ao Recuperar Kafka Service" });
            }

            resolve(returnRequest);
          }
        });
      } catch (xError) {
        returnRequest.status_code = HTTP_STATUS.INTERNAL_SERVER_ERROR;
        returnRequest.status = "EXCEPTION";

        //TODO: TRANSLATION
        //TODO: MESSENGER
        //TODO: ERROR - LOG
        //TODO: SEVERITY
        this.messageService.add({ key: 'toast2', severity: 'error', summary: 'Recuperar Kafka Service', detail: "Exceção ao Recuperar Kafka Service" });

        reject(returnRequest);
      }
    });
  }

  /**
   * TODO: https://tsdoc.org/
   */
  private async _processBasicAerodrome(xAerodromeBasicData: any, xAerodromeTarget: string) {
    const currentDateTime = new Date();

    delete this.objAerodromesProgressBar[`${xAerodromeTarget}`];  // Fecha a Barra Progressiva
    delete this.objAerodromesError[`${xAerodromeTarget}`];

    if (xAerodromeBasicData.data_info.length == 1) {
      const tempDataInfo = xAerodromeBasicData.data_info[0];

      // Retirar as Propriedades indesejadas, pois ela não está mapeada no Form.
      if (tempDataInfo.ciad) {
        delete tempDataInfo.ciad;
      }

      if (this.arrayGoalAerodrome.includes(xAerodromeTarget)) {
        // Por causa da Possibilidade de Entrar com Coordenada, é preciso Colocar o ZZZZ
        this.formAerodrome.controls[`${xAerodromeTarget}`].setValue(tempDataInfo.icao_code, { emitEvent: false });

        this.formComponent.controls.aerodromes.controls[`${xAerodromeTarget}`].controls.data.setValue(tempDataInfo);  // Carrega os dados no Form.
        this.formComponent.controls.aerodromes.controls[`${xAerodromeTarget}`].controls.timestamp.setValue(currentDateTime.getTime());

        if (tempDataInfo.icao_code != "ZZZZ") {
          this.formComponent.controls.aerodromes.controls[`${xAerodromeTarget}`].controls.font.setValue("AISWEB");
        } else {
          this.formComponent.controls.aerodromes.controls[`${xAerodromeTarget}`].controls.font.setValue("OTHER");
        }
      } else { // AERODROMOS EXTRAS
        const tempIndexAerodrome = xAerodromeTarget.replaceAll("alt_", "");

        // Por causa da Possibilidade de Entrar com Coordenada, é preciso Colocar o ZZZZ
        this.formAerodrome.controls.alt_extras.controls[parseInt(tempIndexAerodrome) - 3].setValue(tempDataInfo.icao_code, { emitEvent: false });

        this.formComponent.controls.aerodromes.controls.alt_extras.controls[parseInt(tempIndexAerodrome) - 3].controls.data.setValue(tempDataInfo);  // Carrega os dados no Form.
        this.formComponent.controls.aerodromes.controls.alt_extras.controls[parseInt(tempIndexAerodrome) - 3].controls.timestamp.setValue(currentDateTime.getTime());

        if (tempDataInfo.icao_code != "ZZZZ") {
          this.formComponent.controls.aerodromes.controls.alt_extras.controls[parseInt(tempIndexAerodrome) - 3].controls.font.setValue("AISWEB");
        } else {
          this.formComponent.controls.aerodromes.controls.alt_extras.controls[parseInt(tempIndexAerodrome) - 3].controls.font.setValue("OTHER");
        }
      }

      this.handlingAerodromeOnMap.emit(xAerodromeTarget); // VAI MOSTRAR O AERÓDROMO NO MAPA.

      this.handlingRiskFields();
      this.handlingMeteorologyFields();
    } else {

    }
  }

  /**
   * TODO: https://tsdoc.org/
   */
  private async _processCompleteAerodrome(xAerodromeCompleteData: any, xAerodromeTarget: string) {
    const currentDateTime = new Date();

    if (xAerodromeCompleteData.data_info.length == 1) {
      const tempDataInfo = xAerodromeCompleteData.data_info[0];

      if (this.arrayGoalAerodrome.includes(xAerodromeTarget)) {
        this.formComponent.controls.flight_plan_data.controls.aerodromes.controls[`${xAerodromeTarget}`].controls.data.setValue(tempDataInfo);  // Carrega os dados.
        this.formComponent.controls.flight_plan_data.controls.aerodromes.controls[`${xAerodromeTarget}`].controls.timestamp.setValue(currentDateTime.getTime());
        this.formComponent.controls.flight_plan_data.controls.aerodromes.controls[`${xAerodromeTarget}`].controls.font.setValue("AISWEB");
      } else { // AERODROMOS EXTRAS
        const tempIndexAerodrome = xAerodromeTarget.replaceAll("alt_", "");

        this.formComponent.controls.flight_plan_data.controls.aerodromes.controls.alt_extras.controls[parseInt(tempIndexAerodrome) - 3].controls.data.setValue(tempDataInfo);  // Carrega os dados.
        this.formComponent.controls.flight_plan_data.controls.aerodromes.controls.alt_extras.controls[parseInt(tempIndexAerodrome) - 3].controls.timestamp.setValue(currentDateTime.getTime());
        this.formComponent.controls.flight_plan_data.controls.aerodromes.controls.alt_extras.controls[parseInt(tempIndexAerodrome) - 3].controls.font.setValue("AISWEB");
      }
    } else {
      // TODO: RECUPERAR PARA VÁRIOS
    }
  }

  /**
   * TODO: https://tsdoc.org/
   */
  private async _processNotamAerodrome(xAerodromeBasicData: any, xAerodromeTarget: string) {
    let hasAerodromeBasicDada = false; // Só muda o valor se o Dado foi Carregado no formulário.

    delete this.objAerodromesProgressBar[`${xAerodromeTarget}`];  // Fecha a Barra Progressiva

    if (xAerodromeBasicData.data_info.length == 1) {
      const tempDataInfo = xAerodromeBasicData.data_info[0];

      // Retirar as Propriedades indesejadas, pois ela não está mapeada no Form.
      if (tempDataInfo.ciad) {
        delete tempDataInfo.ciad;
      }

      this.formComponent.controls.aerodromes.controls[`${xAerodromeTarget}`].setValue(tempDataInfo);  // Carrega os dados no Form.

      hasAerodromeBasicDada = true;
    } else {
      // TODO: RECUPERAR PARA VÁRIOS
    }
  }

  /**
   * TODO: https://tsdoc.org/
   */
  private async _processObstaclesAerodrome(xAerodromeBasicData: any, xAerodromeTarget: string) {
    let hasAerodromeBasicDada = false; // Só muda o valor se o Dado foi Carregado no formulário.

    delete this.objAerodromesProgressBar[`${xAerodromeTarget}`];  // Fecha a Barra Progressiva

    if (xAerodromeBasicData.data_info.length == 1) {
      const tempDataInfo = xAerodromeBasicData.data_info[0];

      // Retirar as Propriedades indesejadas, pois ela não está mapeada no Form.
      if (tempDataInfo.ciad) {
        delete tempDataInfo.ciad;
      }

      this.formComponent.controls.aerodromes.controls[`${xAerodromeTarget}`].setValue(tempDataInfo);  // Carrega os dados no Form.

      hasAerodromeBasicDada = true;
    } else {
      // TODO: RECUPERAR PARA VÁRIOS
    }
  }

  /**
   * TODO: https://tsdoc.org/
   */
  private async _processMeteorologyAerodrome(xAerodromeBasicData: any, xAerodromeTarget: string) {
    let hasAerodromeBasicDada = false; // Só muda o valor se o Dado foi Carregado no formulário.

    delete this.objAerodromesProgressBar[`${xAerodromeTarget}`];  // Fecha a Barra Progressiva

    if (xAerodromeBasicData.data_info.length == 1) {
      const tempDataInfo = xAerodromeBasicData.data_info[0];

      // Retirar as Propriedades indesejadas, pois ela não está mapeada no Form.
      if (tempDataInfo.ciad) {
        delete tempDataInfo.ciad;
      }

      this.formComponent.controls.aerodromes.controls[`${xAerodromeTarget}`].setValue(tempDataInfo);  // Carrega os dados no Form.

      hasAerodromeBasicDada = true;
    } else {
      // TODO: RECUPERAR PARA VÁRIOS
    }
  }

  /**
   * TODO: https://tsdoc.org/
   */
  private _initVariables(): void {
    this.optionsFlightPlanFlightRules = flightPlanFlightRulesOptions;
    this.settingConfig = settingConfig;

    this.objAerodromeIcones = ICON_AERODROME;

    this.objAerodromesProgressBar = {};
    this.objAerodromesError = {};
    this.objAerodromeComplete = {};

    this.arrayMainInputFieldAerodrome = [
      { target: "departure", label: 'AD Partida', control: "departure" },
      { target: "destination", label: 'AD Destino', control: "destination" },
      { target: "alt_1", label: 'AD Alt. 1', control: "alt_1" },
      { target: "alt_2", label: 'AD Alt. 2', control: "alt_2" },
    ];

    this.arrayGoalAerodrome = ["departure", "destination", "alt_1", "alt_2"];
  }
  //#endregion
}
