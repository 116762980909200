//#region "|--- IMPORT MODULES/PACKAGES ---|"
// ***** ANGULAR *****
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// ***** RXJS *****
import { Observable } from 'rxjs';

// ***** NASHVILLE *****
import { settingConfig } from '@nashville-config';
//#endregion

//#region "|--- IMPORT INTERFACES ---|"
import { IPatternResponseFromAPI } from "../../../interfaces/IPatternCrudResponseFromAPI";
//#endregion

@Injectable({
  providedIn: 'root'
})
export class AimDataApisService {
  //#region "|--- PROPERTIES---|"  
  urlAimService: any;
  //#endregion

  constructor(private http: HttpClient) {
    if (settingConfig.API_AIM_PORT == "80") {
      this.urlAimService = `${settingConfig.API_PROTOCOL}://${settingConfig.API_HOST}/${settingConfig.API_PATH}`;
    } else {
      this.urlAimService = `${settingConfig.API_PROTOCOL}://${settingConfig.API_HOST}:${settingConfig.API_AIM_PORT}/${settingConfig.API_PATH}`;
    }    
  }

  //#region "|--- AIM REQUEST ---|" 
  loginUser(xFlightRequest: any): Observable<IPatternResponseFromAPI> {
    return this.http.post<any>(`${this.urlAimService}/mod_fp/login/user`, xFlightRequest);
  }
  //#endregion
}
