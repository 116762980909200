//#region "|--- IMPORT MODULES/PACKAGES ---|"
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
//#endregion

@Injectable({
  providedIn: 'root'
})
export class ZipCodeApisService {
  constructor(private http: HttpClient) {}

   /**
   * TODO: https://tsdoc.org/
   */
  getBrasilCep(xZipCode: string): Observable<any> {
    return this.http.get<any>(`http://viacep.com.br/ws/${xZipCode}/json/`);
  }
}
