//#region "|--- IMPORT MODULES/PACKAGES ---|"
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';

import { settingConfig } from '@nashville-config';

import { PrimengComponentsModule } from '@nashville-primeng';

import { SearchLocationMapComponent } from 'src/app/features/flight-plan/components/search-location-map/search-location-map.component';
//#endregion

//#region "|--- ENUNS ---|"
import { BUTTON_ACTION } from '../../../../../../helpers/enum/ButtonActions';
import { IDropDownBasicOptions } from 'src/app/interfaces/IDropDownBasicOptions';
import { FormControl, FormGroup, UntypedFormControl } from '@angular/forms';
import { ICityMarkerOnTheMap } from 'src/app/interfaces/ICityMarkerOnTheMap';
//#endregion

@Component({
  selector:
    'nashville-dialog-search-aerodrome-coord',
  standalone: true,
  imports: [CommonModule, PrimengComponentsModule, SearchLocationMapComponent],
  templateUrl: './dialog-search-aerodrome-coord.component.html',
  styleUrl: './dialog-search-aerodrome-coord.component.scss',
})
export class DialogSearchAerodromeCoordComponent implements OnInit {
  //#region "|--- INPUTS ---|"
  @Input() showDialog!: boolean;
  //#endregion

  //#region "|--- OUTPUTS ---|"
  @Output() dialogButtonsAction = new EventEmitter<string>();
  //#endregion

  //#region "|--- PROPERTIES---|"
  settingConfig!: any;

  labelActionsButtons: any;

  formFilterSearchLocation!: any;

  showDialogSearchLocation!: boolean;

  optionsMapCities!: IDropDownBasicOptions[];
  optionsMapStates!: IDropDownBasicOptions[];
  optionsMapCountry!: IDropDownBasicOptions[];

  arrayCitiesToMarkMap: ICityMarkerOnTheMap[] = [];
  //#endregion

  /**
   * TODO: https://tsdoc.org/
   */
  ngOnInit(): void {
    this._initVariables();

    this._initFilterSearchLocationForm();
  }

  /**
   * TODO: https://tsdoc.org/
   */
  callbackButton(xValue: string) {
    this.dialogButtonsAction.emit(xValue);
  }


  //#region "|--- PRIVATE METHODS---|" 
  /**
   * TODO: https://tsdoc.org/
   */
  private _initVariables(): void {
    this.labelActionsButtons = BUTTON_ACTION;

    this.settingConfig = settingConfig;

    this.optionsMapCities = [];
    this.optionsMapStates = [];
    this.optionsMapCountry = [];

    this.arrayCitiesToMarkMap = [];
  }

  private _initFilterSearchLocationForm() {
    this.formFilterSearchLocation = new FormGroup({
      country_acronym: new FormControl<string>('BRA'),
      state_acronym: new UntypedFormControl(null),
      city_name: new UntypedFormControl(null),
      coordinate: new UntypedFormControl(null),
    });
  }

  //#endregion
}
