//#region "|--- INTERFACE ---|"
import { IDropDownTemplateIconOptions } from "src/app/interfaces/IDropDownTemplateIconOptions";
//#endregion

/**
 * TODO: https://tsdoc.org/
 */
export const typeAircraftOptions: IDropDownTemplateIconOptions[] = [
    { label: 'AVIÃO', value: 'aircraft', icon: 'fa-solid fa-plane' },
    { label: 'HELICÓPTERO', value: 'helicopter', icon: 'fa-solid fa-helicopter' }
]