//#region "|--- IMPORT ANGULAR ---|"
import { FormBuilder, FormArray, Validators } from '@angular/forms';
//#endregion

/**
 * TODO: https://tsdoc.org/
 */
export function initFormChecklist() {
    const objFormBuilder: FormBuilder = new FormBuilder();

    return objFormBuilder.group({
        checklist: objFormBuilder.array([])
    });
}
