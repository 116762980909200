import { FormBuilder, Validators } from '@angular/forms';

/**
 * TODO: https://tsdoc.org/
 */
export function initFormAerodrome() {
    const objFormBuilder = new FormBuilder();

    return objFormBuilder.group({
        departure: [
            null,
            [
                Validators.required,
            ]
        ],
        destination: [
            null,
            [
                Validators.required,
            ]
        ],
        alt_1: [
            null,
            [
                Validators.required,
            ]
        ],
        alt_2: [null],
        alt_extras: objFormBuilder.array([])
    });
}