import { Component, OnInit, Input, HostListener } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, ReactiveFormsModule } from '@angular/forms';

import { DropdownModule } from 'primeng/dropdown';
import { TooltipModule } from 'primeng/tooltip';

import { ErrorMessageControlsComponent } from '../common/error-message-controls/error-message-controls.component';
import { hasControlErrors } from '../common/controls-methods';

//$sm	Breakpoint of screens such as phones.					           576px
//$md	Breakpoint of screens such as tablets.					         768px
//$lg	Breakpoint of screens such as notebook monitors.		     992px
//$xl	Breakpoint of smaller screens such as desktop monitors.	1200px

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'ui-primeng-dropdown',
  standalone: true,
  imports: [
    ErrorMessageControlsComponent,
    ReactiveFormsModule,
    CommonModule,
    DropdownModule,
    TooltipModule
  ],
  templateUrl: './dropdown.component.html',
  styleUrl: './dropdown.component.scss',
})
export class DropdownComponent implements OnInit {
  //#region "|--- INPUTS ---|"
  @Input() control!: FormControl;
  @Input() itemsArray!: any[];
  @Input() itemLabel!: string;
  @Input() itemValue!: string;
  @Input() label!: string;
  @Input() idField!: string;
  @Input() placeHolder?: string;
  @Input() noLabelWidth?: string;
  @Input() isDisabled?: string;
  @Input() toolTip?: string;
  @Input() withFilter?: string;
  @Input() callbackClear!: any;
  @Input() callbackOnChange!: any;
  @Input() appendTo?: string;
  @Input() isEditable!: string;
  @Input() letterCase?: string;
  @Input() sizeInput?: string;
  //#endregion

  //#region "|--- PROPERTIES---|"
  innerWidth?: any;
  placeHolderContent?: string;
  tmpInputId?: string;

  showLabel = true;
  showLabelBold = false;
  showToolTipIcon = false;
  //#endregion

  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
    this.placeHolderContent = this.placeHolder;
    this.tmpInputId = `${this.idField}-inputtext`

    this._hasToShowLabelBold();
    this._getShowLabel();
    this._getIsDisabled();
    this._hasToShowToolTipIcon();
  }

  /**
   * (v1) - Executada toda vez que ocorre REDIMENSIONAMENTO da Tela.
   * https://www.w3schools.com/jsref/dom_obj_event.asp
   * @param event
   */
  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    this.innerWidth = window.innerWidth;

    this._getShowLabel();
  }

  /**
   * TODO: https://tsdoc.org/
   */
  getInputClass(): string {
    //let tmpReturn = "text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full";
    let tmpReturn = "w-full cls-dropdown-svg";

    // Faz a verificação da Caixa da Letra.

    if (this.letterCase != undefined && this.letterCase != "") {
      if (this.letterCase.toLowerCase() == "upper") {
        tmpReturn = `${tmpReturn} cls-text-uppercase`;
      } else if (this.letterCase.toLowerCase() == "lower") {
        tmpReturn = `${tmpReturn} cls-text-lowercase`;
      }
    }

    // Faz a Verificação do tamanho do Input.

    if (this.sizeInput != undefined && this.sizeInput != "") {
      if (this.sizeInput.toLowerCase() == "small") {
        tmpReturn = `${tmpReturn} p-dropdown-sm`;
      } else if (this.sizeInput.toLowerCase() == "large") {
        tmpReturn = `${tmpReturn} p-dropdown-lg`;
      } else {
        tmpReturn = `${tmpReturn}`;
      }
    }

    return tmpReturn
  }

  /**
   * TODO: https://tsdoc.org/
   */
  getFieldName() {
    return this.idField.replace("fid-", "");
  }

  /**
   * TODO: https://tsdoc.org/
   */
  getOptionLabel() {
    if (this.itemLabel != undefined && this.itemLabel != "") {
      return this.itemLabel;
    } else {
      return "label";
    }
  }

  /**
   * TODO: https://tsdoc.org/
   */
  getOptionValue() {
    if (this.itemValue != undefined && this.itemValue != "") {
      return this.itemValue;
    } else {
      return "value";
    }
  }

  /**
   * TODO: https://tsdoc.org/
   */
  getWithFilter() {
    if (this.withFilter != undefined) {
      if (this.withFilter == "true") {
        return true;
      } else {
        return false;
      }
    } else {
      return false; // Retorna o padrão texto
    }
  }

  /**
   * TODO: https://tsdoc.org/
   */
  showErrors() {
    return hasControlErrors(this.control, "input");
  }

  /**
   * TODO: https://tsdoc.org/
   */
  getShowLabelBold(): boolean {
    return this.showLabelBold;
  }

  /**
   * TODO: https://tsdoc.org/
   */
  getAppendTo() {
    if (this.appendTo != undefined) {
      return this.appendTo;
    } else {
      return ""
    }
  }

  /**
   * TODO: https://tsdoc.org/
   */
  getTooTipContent(): string {
    if (this.toolTip != undefined && this.toolTip != "") {
      return this.toolTip;
    } else {
      return "";
    }
  }

  /**
   * TODO: https://tsdoc.org/
   */
  getIsEditable() {
    if (this.isEditable != undefined && this.isEditable != "") {
      if (this.isEditable == "true") {
        return true;
      } else {
        return false;
      }
    } else {
      return false; // Retorna o padrão texto
    }
  }

  /**
   * TODO: https://tsdoc.org/
   */
  private _getShowLabel(): void {
    if (this.noLabelWidth != undefined && this.noLabelWidth != "") {
      if (this.innerWidth < Number(this.noLabelWidth)) {
        this.showLabel = false;
        this.showToolTipIcon = false;
        this.placeHolderContent = this.label;
      } else {
        this.showLabel = true;
        this._hasToShowToolTipIcon();

        if (this.placeHolder) {
          this.placeHolderContent = this.placeHolder;
        } else {
          this.placeHolderContent = "";
        }
      }
    }
  }

  /**
   * TODO: https://tsdoc.org/
   */
  _getIsDisabled(): void {
    if (this.isDisabled != undefined && this.isDisabled != "") {
      if (this.isDisabled == "true") {
        this.control.disable();
      } else {
        this.control.enable();
      }
    } else {
      this.control.enable();
    }
  }

  /**
   * TODO: https://tsdoc.org/
   */
  private _hasToShowLabelBold(): void {
    if (this.label != undefined) {
      if (this.label.indexOf("#BOLD#") > 0) {
        this.showLabelBold = true;
        this.label = this.label.replace("#BOLD#", "").trim();
      }
    }
  }

  /**
   * TODO: https://tsdoc.org/
   */
  _hasToShowToolTipIcon(): void {
    if (this.toolTip != undefined && this.toolTip != "") {
      this.showToolTipIcon = true;
    }
  }
}
