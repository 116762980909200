<section class="cls-component-data-panel">
    <div #panelContentFlightData class="cls-component-show-content-pdf">
        <p-panel [toggleable]="true" [collapsed]="this.collapsePanel" class="cls-component-show-content-pdf-panel">
            <ng-template pTemplate="icons">
                <button class="p-panel-header-icon p-link">
                    <span class="fa-solid fa-file-pdf"></span>
                </button>
            </ng-template>
            <!-- A COR DE FUNDO DO HEADER É DEFINA VIA PROGRAMAÇÃO -->
            <ng-template pTemplate="header">
                <div class="flex align-items-center gap-2">
                    <span class="cls-component-show-content-pdf-title">DADOS DO VOO</span>
                </div>
            </ng-template>
            <ng-template pTemplate="content">
                <p-accordion [activeIndex]="0">
                    <p-accordionTab>
                        <ng-template pTemplate="header">
                            <span class="flex align-items-center gap-2 w-full">
                                <span class="font-bold white-space-nowrap">
                                    REQUISIÇÃO DE VOO -
                                    {{this.iptSfdFormRequest.controls.flight_request_code.controls.normalized_text.value}}
                                </span>
                            </span>
                        </ng-template>
                        <ng-template pTemplate="content">
                            <div class="cls-component-show-content-pdf-pseudo-table">
                                <div class="grid">
                                    <div class="col-7 cls-component-show-content-pdf-pseudo-table-grid-col">
                                        <div class="cls-component-show-content-pdf-pseudo-table-row">
                                            <div class="cls-component-show-content-pdf-pseudo-table-row-title">
                                                AERONAVE:
                                            </div>
                                            <div class="cls-component-show-content-pdf-pseudo-table-row-value">
                                                {{this.iptSfdFormRequest.controls.aircraft_mark.value}}
                                            </div>
                                        </div>
                                        <div class="cls-component-show-content-pdf-pseudo-table-row">
                                            <div class="cls-component-show-content-pdf-pseudo-table-row-title">
                                                DATA/HORA EOBT:
                                            </div>
                                            <div class="cls-component-show-content-pdf-pseudo-table-row-value">
                                                {{this.iptSfdFormRequest.controls.date_time_eobt.controls.date_zulu.value}}
                                                {{this.iptSfdFormRequest.controls.date_time_eobt.controls.time_zulu.value}}
                                                (Z)
                                            </div>
                                        </div>
                                        <div class="cls-component-show-content-pdf-pseudo-table-row">
                                            <div class="cls-component-show-content-pdf-pseudo-table-row-title">
                                                EET:
                                            </div>
                                            <div class="cls-component-show-content-pdf-pseudo-table-row-value">
                                                {{this.iptSfdFormRequest.controls.eet.value}}
                                            </div>
                                        </div>

                                        <div class="cls-component-show-content-pdf-pseudo-table-row">
                                            <div class="cls-component-show-content-pdf-pseudo-table-row-title">
                                                DATA/HORA POUSO:
                                            </div>
                                            <div class="cls-component-show-content-pdf-pseudo-table-row-value">
                                                {{this.iptSfdFormRequest.controls.date_time_landing.controls.date_zulu.value}}
                                                {{this.iptSfdFormRequest.controls.date_time_landing.controls.time_zulu.value}}
                                                (Z)
                                            </div>
                                        </div>
                                        <div class="cls-component-show-content-pdf-pseudo-table-row">
                                            <div class="cls-component-show-content-pdf-pseudo-table-row-title">
                                                OPERADOR:
                                            </div>
                                            <div class="cls-component-show-content-pdf-pseudo-table-row-value">
                                                {{this.iptSfdFormRequest.controls.operator.value}}
                                            </div>
                                        </div>
                                        <div class="cls-component-show-content-pdf-pseudo-table-row">
                                            <div class="cls-component-show-content-pdf-pseudo-table-row-title">
                                                PILOTO EM COMANDO:
                                            </div>
                                            <div class="cls-component-show-content-pdf-pseudo-table-row-value">
                                                {{this.iptSfdFormRequest.controls.crew.controls.first_in_command.controls.normalized_text.value}}
                                            </div>
                                        </div>
                                        <div class="cls-component-show-content-pdf-pseudo-table-row">
                                            <div class="cls-component-show-content-pdf-pseudo-table-row-title">
                                                SEGUNDO EM COMANDO:
                                            </div>
                                            <div class="cls-component-show-content-pdf-pseudo-table-row-value">
                                                {{this.iptSfdFormRequest.controls.crew.controls.second_in_command.controls.normalized_text.value}}
                                            </div>
                                        </div>                                       
                                    </div>
                                    <div class="col-5 cls-component-show-content-pdf-pseudo-table-grid-col">
                                        <div class="cls-component-show-content-pdf-pseudo-table-img-aerodrome">
                                            <p-image src="{{this.serverUrl}}/mod_fp/flight/plan/data/route/image/{{this.iptSfdFormRequest.controls.flight_request_id.value}}?url={{this.flightPlanContentData.route.data[0].data[0].image}}"
                                                alt="Image" [preview]="true" />
                                        </div>
                                    </div>
                                </div>
                                <div class="cls-component-show-content-pdf-pseudo-table-row">
                                    <div class="cls-component-show-content-pdf-pseudo-table-row-title">
                                        REGRA DE VOO:
                                    </div>
                                    <div class="cls-component-show-content-pdf-pseudo-table-row-value">
                                        {{this.iptSfdFormRequest.controls.flight_rule.value}}
                                    </div>
                                </div>
                                <div class="cls-component-show-content-pdf-pseudo-table-row">
                                    <div class="cls-component-show-content-pdf-pseudo-table-row-title">
                                        NÍVEL DE VOO:
                                    </div>
                                    <div class="cls-component-show-content-pdf-pseudo-table-row-value">
                                        {{this.iptSfdFormRequest.controls.flight_level.value}}
                                    </div>
                                </div>
                                <div class="cls-component-show-content-pdf-pseudo-table-row">
                                    <div class="cls-component-show-content-pdf-pseudo-table-row-title">
                                        VEL. CRUZEIRO:
                                    </div>
                                    <div class="cls-component-show-content-pdf-pseudo-table-row-value">
                                        {{this.iptSfdFormRequest.controls.cruise_speed.value}}
                                    </div>
                                </div>
                                <div class="cls-component-show-content-pdf-pseudo-table-row">
                                    <div class="cls-component-show-content-pdf-pseudo-table-row-title">
                                        ROTA:
                                    </div>
                                    <div class="cls-component-show-content-pdf-pseudo-table-row-value">
                                        {{this.iptSfdFormRequest.controls.route.controls.normalized_text.value}}
                                    </div>
                                </div>
                                <div class="cls-component-show-content-pdf-pseudo-table-row">
                                    <div class="cls-component-show-content-pdf-pseudo-table-row-title">
                                        AD. DE PARTIDA:
                                    </div>
                                    <div class="cls-component-show-content-pdf-pseudo-table-row-value">
                                        {{this.iptSfdFormRequest.controls.aerodromes.controls.departure.controls.data.controls.icao_code.value}}
                                        -
                                        {{this.iptSfdFormRequest.controls.aerodromes.controls.departure.controls.data.controls.name.value}}
                                    </div>
                                </div>
                                <div class="cls-component-show-content-pdf-pseudo-table-row">
                                    <div class="cls-component-show-content-pdf-pseudo-table-row-title">
                                        AD. DE DESTINO:
                                    </div>
                                    <div class="cls-component-show-content-pdf-pseudo-table-row-value">
                                        {{this.iptSfdFormRequest.controls.aerodromes.controls.destination.controls.data.controls.icao_code.value}}
                                        -
                                        {{this.iptSfdFormRequest.controls.aerodromes.controls.destination.controls.data.controls.name.value}}
                                    </div>
                                </div>
                                <div class="cls-component-show-content-pdf-pseudo-table-row">
                                    <div class="cls-component-show-content-pdf-pseudo-table-row-title">
                                        AD. ALTERNATIVO 1:
                                    </div>
                                    <div class="cls-component-show-content-pdf-pseudo-table-row-value">
                                        {{this.iptSfdFormRequest.controls.aerodromes.controls.alt_1.controls.data.controls.icao_code.value}}
                                        -
                                        {{this.iptSfdFormRequest.controls.aerodromes.controls.alt_1.controls.data.controls.name.value}}
                                    </div>
                                </div>
                                <div class="cls-component-show-content-pdf-pseudo-table-row">
                                    <div class="cls-component-show-content-pdf-pseudo-table-row-title">
                                        AD. ALTERNATIVO 2:
                                    </div>
                                    <div class="cls-component-show-content-pdf-pseudo-table-row-value">
                                        {{this.iptSfdFormRequest.controls.aerodromes.controls.alt_2.controls.data.controls.icao_code.value}}
                                        -
                                        {{this.iptSfdFormRequest.controls.aerodromes.controls.alt_2.controls.data.controls.name.value}}
                                    </div>
                                </div>
                                <ng-container
                                    *ngFor="let itemExtra of this.iptSfdFormRequest.controls.aerodromes.controls.alt_extras.controls; let elementIndex=index">
                                    <div class="cls-component-show-content-pdf-pseudo-table-row">
                                        <div class="cls-component-show-content-pdf-pseudo-table-row-title">
                                            AD. ALTERNATIVO {{elementIndex + 3}}:
                                        </div>
                                        <div class="cls-component-show-content-pdf-pseudo-table-row-value">
                                            {{itemExtra.controls.data.controls.icao_code.value}}
                                            -
                                            {{itemExtra.controls.data.controls.name.value}}
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                        </ng-template>
                    </p-accordionTab>
                    <p-accordionTab>
                        <ng-template pTemplate="header">
                            <span class="flex align-items-center gap-2 w-full">
                                <span class="font-bold white-space-nowrap">
                                    INFORMAÇÕES ANEXAS - DADOS DE VOO
                                </span>
                                <p-badge value="{{this.flightPlanContentData.extra_data.data.length}}"
                                    class="ml-auto mr-2" />
                            </span>
                        </ng-template>
                        <ng-template pTemplate="content">
                            <nashville-extra-content 
                                [dataInfo]="this.dataInfoUploadImage"
                                [formRequest]="this.iptSfdFormRequest"
                                [arrayExtraData]="this.flightPlanContentData.extra_data.data"
                                (resultUploadImage)="callbackActionExtraFlightData($event)">
                            </nashville-extra-content>
                        </ng-template>
                    </p-accordionTab>
                </p-accordion>
            </ng-template>
            <ng-template pTemplate="footer">

            </ng-template>
        </p-panel>
    </div>
</section>