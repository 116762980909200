<p-dialog [modal]="true" [(visible)]="this.showDialog" [closable]="false" [style]="{ width: '80rem' }"
  class="cls-component-dialog-pattern">
  <ng-template pTemplate="header">
    <span class="cls-component-dialog-pattern-title">PROCURAR AERÓDROMO POR COORDENADA</span>
  </ng-template>
  <ng-template pTemplate="content">
    <div class="grid">
      <div class="field col-3">
        <p-fieldset class="cls-component-fieldset-filter">
          <ng-template pTemplate="header">
            <div class="flex align-items-center gap-2 px-2">
              <span class="font-bold">Localizar Aeródromo</span>
            </div>
          </ng-template>
          <div class="m-0">
            <div class="cls-component-form">
              <div class="formgrid grid">
                <div class="field col-12">
                  <ui-primeng-dropdown label="Pais" idField="fid-map-search-country-acronym" placeHolder=""
                    sizeInput="small" [control]="this.formFilterSearchLocation.controls.country_acronym"
                    [itemsArray]="this.optionsMapCountry" withFilter="false" noLabelWidth="1410">
                  </ui-primeng-dropdown>
                </div>
                <div class="field col-12">
                  <ui-primeng-dropdown label="Estado" idField="fid-map-search-state-acronym" placeHolder=""
                    sizeInput="small" [control]="this.formFilterSearchLocation.controls.state_acronym"
                    [itemsArray]="this.optionsMapStates" withFilter="true" noLabelWidth="1410">
                  </ui-primeng-dropdown>
                </div>
                <div class="field col-12">
                  <ui-primeng-dropdown label="Cidade" idField="fid-map-search-city-name" placeHolder=""
                    sizeInput="small" [control]="this.formFilterSearchLocation.controls.city_name"
                    [itemsArray]="this.optionsMapCities" withFilter="true" noLabelWidth="1410">
                  </ui-primeng-dropdown>
                </div>
                <div class="field col-12">
                  <ui-primeng-input-text label="Coordenadas" idField="fid-map-search-coordinate"
                    [control]="this.formFilterSearchLocation.controls.coordinate" sizeInput="small" letterCase="upper"
                    maxCharacters="5" isDisabled="false" noLabelWidth="1410">
                  </ui-primeng-input-text>
                </div>
              </div>
            </div>
          </div>
        </p-fieldset>
      </div>
      <div class="field col-9">
        <div class="cls-component-dialog-search-aerodrome-map-frame">
          <nashville-search-location-map [showMap]="this.showDialogSearchLocation"
            [citiesMarkers]="this.arrayCitiesToMarkMap">
          </nashville-search-location-map>
        </div>
      </div>
    </div>
  </ng-template>
  <ng-template pTemplate="footer">
    <section class="cls-component-toolbar-control-dialog">
      <p-toolbar class="cls-component-toolbar-control-dialog-pattern">
        <div class="p-toolbar-group-start"></div>
        <div class="p-toolbar-group-center"></div>
        <div class="p-toolbar-group-end">
          <div class="cls-page-box-left">
            <p-button label="SALVAR" icon="fa-solid fa-rotate" [raised]="true" severity="success"
              class="cls-component-button-pattern-default" (click)="callbackButton(this.labelActionsButtons.OK)">
            </p-button>
            <p-button label="CANCELAR" icon="fa-regular fa-file-pdf" [raised]="true" severity="danger"
              class="cls-component-button-pattern-default"
              (click)="callbackButton(this.labelActionsButtons.CLOSE_DIALOG)">
            </p-button>
          </div>
        </div>
      </p-toolbar>
    </section>
  </ng-template>
</p-dialog>