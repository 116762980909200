//#region "|--- IMPORT MODULES/PACKAGES ---|"
// ***** ANGULAR *****v
import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';

// ***** NASHVILLE *****
import { settingConfig } from '@nashville-config';
import { PrimengComponentsModule } from '@nashville-primeng-components';
import { LocalMethodsHandlerClass } from '@nashville-local-methods';

// ***** PRIMENG *****
import { Table } from 'primeng/table';
import { TableLazyLoadEvent } from 'primeng/table';
import { MessageService } from 'primeng/api';
import { InputTextModule } from 'primeng/inputtext';
//#endregion

//#region "|--- IMPORT COMPONENTS ---|"
import { PageTitleComponent } from '../../../../shared/components/page-title/page-title.component';
import { PageToastComponent } from '../../../../shared/components/page-toast/page-toast.component';
import { ToolbarTableAircraftDataListComponent } from './components/toolbar-table-aircraft-data-list/toolbar-table-aircraft-data-list.component';
//#endregion

//#region "|--- INTERFACE ---|"
import { IToastParameters } from '../../../../interfaces/IToastParameters';
import { IPatternResponseFromAPI } from '../../../../interfaces/IPatternCrudResponseFromAPI';
//#endregion

//#region "|--- SERVICE ---|"
import { AircraftDataApisService } from '../../services/aircraft-data-apis.service';
//#endregion

//#region "|--- ENUMS ---|"
import { BUTTON_ACTION } from '../../../../helpers/enum/ButtonActions';
//#endregion

//#region "|--- CONSTANTS ---|"
const TITLE_LIST = "AERONAVES CADASTRADAS";
//#endregion
@Component({
  selector: 'nashville-aircraft-data-list',
  standalone: true,
  imports: [
    CommonModule,
    PageTitleComponent,
    PageToastComponent,
    PrimengComponentsModule,
    ToolbarTableAircraftDataListComponent,
    InputTextModule,
    ReactiveFormsModule
  ],
  templateUrl: './aircraft-data-list.component.html',
  styleUrl: './aircraft-data-list.component.scss',
})
export class AircraftDataListComponent implements OnInit {
  @ViewChild('dt1') dt1: Table | undefined;

  //#region "|--- PROPERTIES---|"
  settingConfig!: any;
  serverUrl: any;

  formFilter!: any;

  tableAircraft: any[] = [];
  arrayAllAircraftData: any[] = [];

  totalRecords!: number;

  loading: boolean = false;

  objToastMessage: IToastParameters;

  currentPageTitle: string;

  localMethodsHandlerClass: any;
  //#endregion

  constructor(
    private aircraftDataApisService: AircraftDataApisService,
    private messageService: MessageService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this._initVariables();
    this._initFormFilter();

    Promise.all([
      this._loadAllAircraftData()
    ]);
  }

  //#region "|--- PUBLIC METHODS---|"
  /**
   * TODO: https://tsdoc.org/
   */
  applyFilterGlobal($event: any, stringVal: any) {
    console.log("+++++++++++++", this.dt1);
    return this.dt1.filterGlobal(($event.target as HTMLInputElement).value.replaceAll("-", ""), stringVal);
  }

  getValueFilter(event: Event): string {
    return (event.target as HTMLInputElement).value.replaceAll("-", "");
  }

  /**
   * TODO: https://tsdoc.org/
   */
  loadAircraft(event: TableLazyLoadEvent) {    
    setTimeout(() => {
      this.loading = true;

      const lazyEvent = JSON.stringify(event);
      const firstElement = event.first;
      const sizeRows = event.rows;
      const tmpTotal = this.arrayAllAircraftData.length;

      this.tableAircraft = [];

      for (let i = 0; i < tmpTotal; i++) {
        if (i >= firstElement && i < (sizeRows + firstElement)) {
          this.tableAircraft.push(this.arrayAllAircraftData[i]);
        }
      }

      this.loading = false;
    }, 500);
  }

  /**
   * TODO: https://tsdoc.org/
   */
  updateAircraftData(xAircraftDataId: any) {
    this.router.navigateByUrl(`/aircraft/form-ls/${xAircraftDataId}`);
  }
  //#endregion

  //#region "|--- PRIVATE METHODS---|"
  private _initFormFilter() {
    this.formFilter = new FormGroup({
      keyword_filter: new FormControl<string>('')
    });
  }


  /**
   * TODO: https://tsdoc.org/
   */
  private _initVariables(): void {
    this.settingConfig = settingConfig;
    this.currentPageTitle = TITLE_LIST;
    this.localMethodsHandlerClass = LocalMethodsHandlerClass;

    if (settingConfig.API_AIRCRAFT_DATA_PORT == "80") {
      this.serverUrl = `${settingConfig.API_PROTOCOL}://${settingConfig.API_HOST}/${settingConfig.API_PATH}`
    } else {
      this.serverUrl = `${settingConfig.API_PROTOCOL}://${settingConfig.API_HOST}:${settingConfig.API_AIRCRAFT_DATA_PORT}/${settingConfig.API_PATH}`
    }
  }

  /**
   * TODO: https://tsdoc.org/
   */
  private async _loadAllAircraftData() {
    await this.aircraftDataApisService.getAllAircraftData().subscribe({
      next: (xResponseService: IPatternResponseFromAPI) => {
        if (xResponseService.status_code == 200) {
          this.arrayAllAircraftData = xResponseService.data_info.data;
          this.totalRecords = this.arrayAllAircraftData.length;

          console.log("@@@@@@@@@@", this.arrayAllAircraftData)

          this._showToast({
            severity: 'success',
            summary: 'Listar Aeronaves Cadastradas',
            detail: "Lista de aeronaves cadastradas recuperada com sucesso.",
          });
        } else {
          if (this.settingConfig.SHOW_LOG_EXCEPTION) {
            console.log("Erro - Listar Aeronaves Cadastradas: ", xResponseService);
          }

          this._showToast({
            severity: 'error',
            summary: 'Listar Aeronaves Cadastradas',
            detail: "Erro - Listar aeronaves cadastradas.",
          });
        }
      },
      complete: () => {

      },
      error: (xErrorService: any) => {
        let tempMessage = "";
        let tempSeverity = "error";

        if (this.settingConfig.SHOW_LOG_EXCEPTION) {
          console.log("Exceção - Listar Aeronaves Cadastradas: ", xErrorService);
        }

        if (xErrorService.status == 0) {
          tempMessage = 'Exceção - Não foi possível conectar com o servidor da aplicação.'
        } else if (xErrorService.status == 404) {
          tempMessage = 'Exceção - Não foi possível conectar com o servidor da aplicação.'

          if (xErrorService.error) {
            if (xErrorService.error.data_info) {
              if (xErrorService.error.data_info.qtd == 0) {
                tempSeverity = "warn";
                tempMessage = "Exceção - Nenhuma aeronave registrada no sistema";
              }
            }
          }
        } else {
          tempMessage = 'Exceção - Recuperar dados da aeronaves.'
        }

        this._showToast({
          severity: tempSeverity,
          summary: 'Listar Aeronaves Cadastradas',
          detail: tempMessage,
        });
      }
    })
  }

  /**
   * TODO: https://tsdoc.org/
   */
  private async _showToast(xObjMessage: IToastParameters) {
    this.objToastMessage = null; // Tem que forçar a mudança para que o componente registre a alteração do valor da propriedade.
    this.objToastMessage = xObjMessage;
  }
  //#endregion

  //#region "|--- CALLBACK METHODS---|"
  /**
   * TODO: https://tsdoc.org/
   */
  callBackToolbarFormList(xAction: string) {
    if (xAction == BUTTON_ACTION.RELOAD) {
      this.arrayAllAircraftData = [];

      this._loadAllAircraftData();
    }
  }
  //#endregion
}
