//#region "|--- IMPORT MODULES/PACKAGES ---|"
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';

import { settingConfig } from '@nashville-config';

import { PrimengComponentsModule } from '@nashville-primeng';
//#endregion

//#region "|--- IMPORT COMPONENTS ---|"
import { AddressHandlerComponent } from '../../../../components/address_handler/address_handler.component';
import { ToolbarDialogOwnerOperatorComponent } from '../../components/toolbar-dialog-owner-operator/toolbar-dialog-owner-operator.component';
import { ContactHandlerComponent } from '../../../../components/contact_handler/contact_handler.component';
//#endregion

//#region "|--- INTERFACE ---|"
import { IDropDownTemplateCountryOptions } from 'src/app/interfaces/IDropDownTemplateCountryOptions';
//#endregion

//#region "|--- DROPDOWN OPTIONS ---|"
import { countryOptions } from '../../../../../../helpers/dropdown-static-options/countryOptions';
//#endregion

@Component({
  selector:
    'nashville-dialog-aircraft-data-owner-operator',
  standalone: true,
  imports: [
    CommonModule,
    PrimengComponentsModule,
    ToolbarDialogOwnerOperatorComponent,
    AddressHandlerComponent,
    ContactHandlerComponent
  ],
  templateUrl: './dialog-aircraft-data-owner-operator.component.html',
  styleUrl: './dialog-aircraft-data-owner-operator.component.scss',
})
export class DialogAircraftDataOwnerOperatorComponent implements OnInit {
  //#region "|--- INPUTS ---|"
  @Input() pageInnerWidth!: any;
  @Input() formComponent!: any;
  @Input() typeDialog!: string

  // É feito o set do @Input para que seja capturado qualquer modificação, em tempo real, 
  // do parâmetro que está sendo enviado pelo PARENT e, principalmente, que seja feito o 
  // processamento necessário com base no novo valor.
  @Input() set indexToShow(xValue: number) {
    if (xValue != undefined && xValue != null) {
      if(xValue < 0){ // Se for valor negativo, indica NOVO

      } else { // Se for valor zero para mais indica que deve recuperar o elemento do array formComponent.controls.
        this.indexArrayToLoad = xValue;
      }        
    }
  };

  // É feito o set do @Input para que seja capturado qualquer modificação, em tempo real, 
  // do parâmetro que está sendo enviado pelo PARENT e, principalmente, que seja feito o 
  // processamento necessário com base no novo valor.
  @Input() set showDialog(xValue: boolean) {
    if (xValue != undefined && xValue != null) {
      if (xValue == true) {
        this._setValues();
      }
      this.showDialogOwnerOperator = xValue;
    }
  };
  //#endregion

  //#region "|--- OUTPUT ---|"
  @Output() dialogAction = new EventEmitter<string>();
  //#endregion

  //#region "|--- PROPERTIES ---|"
  showDialogOwnerOperator: boolean;
  settingConfig!: any;
  innerWidth!: any;
  indexArrayToLoad!: number;

  optionsCountryRegistration: IDropDownTemplateCountryOptions[];

  labelButtonType!: string;
  //#endregion

  ngOnInit(): void {
    this._initVariables();
  }

  //#region "|--- PRIVATE METHODS ---|" 
  /**
   * TODO: https://tsdoc.org/
   */
  private _initVariables(): void {
    this.settingConfig = settingConfig;
    this.showDialogOwnerOperator = false;
    this.innerWidth = this.pageInnerWidth;
    this.optionsCountryRegistration = countryOptions;
  }

  private _setValues(): void {
    if (this.typeDialog == "owner") {      
      this.labelButtonType = "Proprietário";
    } else if (this.typeDialog == "operator") {
      this.labelButtonType = "Operador";
    } else {
      this.labelButtonType = "";
    }

    if(this.formComponent.controls.type_owner_operator.value != "both"){
      this.formComponent.controls.type_owner_operator.setValue(this.typeDialog);
    }
  }
  //#endregion

  //#region "|--- CALLBACK ---|" 
  returnCallBackDialogButton(xAction: string) {
    this.dialogAction.emit(xAction);
  }

  callbackSetArrayAddress(xArray: any) {    
    if(xArray != undefined && xArray != null){
      //this.formComponent.controls.address.value = xArray;
      this.formComponent.controls.address.patchValue(xArray);
    }    
  }

  callbackSetArrayContact(xArray: any) {
    if(xArray != undefined && xArray != null){      
      //this.formComponent.controls.contacts.value = xArray;
      this.formComponent.controls.contacts.patchValue(xArray);
    }  
  }
  //#endregion
}

  