import { Component, OnInit, HostListener, ElementRef, Directive } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PrimengComponentsModule } from '../../../../../layouts/primeng-components/primeng-components.module';

import { MenuItem, MessageService } from 'primeng/api';

import { SCREEN_SIZES } from 'src/app/helpers/enum/ScreenSizes';

//$sm	Breakpoint of screens such as phones.					           576px
//$md	Breakpoint of screens such as tablets.					         768px
//$lg	Breakpoint of screens such as notebook monitors.		     992px
//$xl	Breakpoint of smaller screens such as desktop monitors.	1200px

/**
 * https://angular.io/api/core/HostListener
 */
@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'common-components-top-header-01',
  standalone: true,
  imports: [
    PrimengComponentsModule,
    CommonModule
  ],
  templateUrl: './top-header-01.component.html',
  styleUrl: './top-header-01.component.scss',
})
export class TopHeader01Component implements OnInit {
  //#region "|--- PROPERTIES---|"
  innerWidth?: any;
  items: MenuItem[] = [];

  strUserName!: string;
  strProfile!: string;
  //#endregion

  constructor(private messageService: MessageService) { }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnInit() {
    this.strUserName = "MARIA JOSE";
    this.strProfile = "piloto";

    this.items = [
      {
        label: 'Perfil',
        icon: 'fa-solid fa-circle-user',
        routerLink: ['/']
      },
      {
        label: 'Setup',
        icon: 'pi pi-cog',
        routerLink: ['/']
      },
      { separator: true },
      {
        label: 'Trocar Senha',
        icon: 'fa-solid fa-lock',
        routerLink: ['/']
      },
      { separator: true },
      {
        label: 'Trocar Conta',
        icon: 'fa-solid fa-right-left',
        routerLink: ['/']
      },
      { separator: true },
      {
        label: 'Sair',
        icon: 'fa-solid fa-arrow-right-from-bracket',
        routerLink: ["/"]
      }
    ];

    // Adicionar o nome e o Profile do Usuário dentro do Menu, quando a tela for menor que 

    /*this.waitForElementToExist('#id-user-menu ul').then(element => {
      if (innerWidth > SCREEN_SIZES.SM) {
        document.querySelector('#id-user-menu ul')?.prepend("<li>GASPAR</li>")
      }
      console.log('The element exists', element);
    });
    */

    this.waitForElementToExist('#id-user-menu ul');
  }

  /**
   * (v1) - Executada toda vez que ocorre REDIMENSIONAMENTO da Tela.
   * https://www.w3schools.com/jsref/dom_obj_event.asp
   * @param event
   */
  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    this.innerWidth = window.innerWidth;
  }

  /**
   * (v1) - Executada toda vez que for clicado em um botão.
   * @param event
   */
  @HostListener('click', ['$event'])
  public clicked(e: MouseEvent): void {
    this.waitForElementToExist('#id-user-menu ul');
  }

  save(severity: string) {
    this.messageService.add({ severity: severity, summary: 'Success', detail: 'Data Saved' });
  }

  update() {
    this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Data Updated' });
  }

  delete() {
    this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Data Deleted' });
  }

  hasUserImage() {
    return true;
  }

  /**
  * TODO: https://tsdoc.org/
  * FUNÇÃO QUE FICA MONITORANDO SE EXISTE OU NÃO O ELEMENTO DESEJADO DENTRO DO DOM
  * https://bobbyhadz.com/blog/javascript-wait-for-element-to-exist
  * https://pt.stackoverflow.com/questions/226014/como-utilizar-da-meneira-correta-o-mutationobserver
  * https://www.bairesdev.com/blog/angular-services/
  * 
  */
  waitForElementToExist(selector: string) {
    const elementButtonMenu = document.getElementById('id-user-menu');

    const observer = new MutationObserver((mutations) => {
      if (document.querySelector(selector)) {
        if (innerWidth < SCREEN_SIZES.SM) {

          if (!document.querySelector('#id-user-menu ul li[user-name-profile]')) {
            const elementLi = document.createElement("li");
            const elementDivName = document.createElement("li");
            const elementDivProfile = document.createElement("li");

            elementDivName.append(document.createTextNode("GASPAR JR"));
            elementDivName.classList.add("cls-component-user-tools-menu-item-user-name")
            elementDivProfile.append(document.createTextNode("piloto"));
            elementDivProfile.classList.add("cls-component-user-tools-menu-item-user-profile")
            elementLi.append(elementDivName);
            elementLi.append(elementDivProfile);
            elementLi.setAttribute("user-name-profile", "");
            elementLi.classList.add("cls-component-user-tools-menu-item-user")

            document.querySelector('#id-user-menu ul')?.prepend(elementLi);
          }

          // Para evitar que entre em um looping infinito, pois a modificação vai ser feita no DOM que está sendo monitorado... 
          // A modificação vai disparar o monitoramento que vai alterar e vai disparar o monitoramento e assim vai, ou seja vai cair em um processo RECURSIVO.
          // Por isto é preciso sempre ativar quando alguém apertar o botão para mostrar o menu.
          observer.disconnect();
        }
      }
    });

    if (elementButtonMenu) {
      // Restringir o monitoramento a mudança do DOM do SplitButton 
      observer.observe(elementButtonMenu, {
        subtree: true,
        childList: true,
        //attributes: true,
        //characterData: true,
        //attributeOldValue: true,
        //characterDataOldValue: true
      });
    }
  }




  /**
   * TODO: https://tsdoc.org/
   * FUNÇÃO QUE FICA MONITORANDO SE EXISTE OU NÃO O ELEMENTO DESEJADO DENTRO DO DOM
   * https://bobbyhadz.com/blog/javascript-wait-for-element-to-exist
   */
  /*waitForElementToExist(selector: string) {    
    return new Promise(resolve => {
      if (document.querySelector(selector)) {
        return resolve(document.querySelector(selector));
      }

      const observer = new MutationObserver(() => {
        if (document.querySelector(selector)) {
          resolve(document.querySelector(selector));
          observer.disconnect();
        }
      });

      observer.observe(document.body, {
        subtree: true,
        childList: true,
      });
    });
  }*/
}
