/**
 * TODO: https://tsdoc.org/
 */
export const BUTTON_ACTION = {    
    NEW: "new_action",
    NEW_CLONE: "new_clone_action",
    NEW_RETURN: "new_return_action",
    RELOAD: "reload_action",
    SEARCH: "search_action",
    SAVE: "save_action",
    UPDATE: "update_action",
    RENEW: "renew_action",
    DELETE: "delete_action",
    START: "start_action",
    OK: "ok_action",
    CREATE_PDF: "create_pdf",
    CANCEL: "cancel_action",
    SHOW_OWNER: "open_dialog_owner",
    SHOW_OPERATOR: "open_dialog_operator",    
    SHOW_DIALOG: "open_dialog",
    SHOW_MESSAGING: "open_messaging",
    SHOW_CHECK_LIST: "open_check_list",
    CLOSE_DIALOG: "close_dialog",
}
