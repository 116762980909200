<article id="id-horizontal-basic-02" class="cls-hrt-frame">
    <header class="cls-hrt-frame-header">
        <div class="cls-hrt-frame-header-top">
            <common-components-top-header-01 *ngIf="isTopHeader01()"></common-components-top-header-01>
            <common-components-top-header-02 *ngIf="isTopHeader02()"></common-components-top-header-02>
        </div>
        <div class="cls-hrt-frame-header-menu">
            <common-components-top-horizontal-menu-01 *ngIf="isTopHorizontalMenu01()"></common-components-top-horizontal-menu-01>
            <common-components-top-horizontal-menu-02 *ngIf="isTopHorizontalMenu02()"></common-components-top-horizontal-menu-02>
        </div>
    </header>
    <section class="cls-hrt-frame-main">
        <h1>horizontal-basic-02</h1>
        <router-outlet></router-outlet>
    </section>
    <footer class="cls-hrt-frame-footer">

    </footer>
</article>