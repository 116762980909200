<section id="id-toolbar-document-pdf-form" class="cls-component-toolbar-control-form">
  <p-toolbar class="cls-component-toolbar-control-form-pattern">
    <div class="p-toolbar-group-start">
      <div class="ml-4">
        <div class="cls-component-show-content-pdf-date-generation"
          [ngClass]="{'cls-component-show-content-pdf-date-generation-ok': this.checkDateTime(this.currentFlightPlanContentData.date_time_create_upload) === true, 'cls-component-show-content-pdf-date-generation-not-ok': this.checkDateTime(this.currentFlightPlanContentData.date_time_create_upload) === false }">
          <div>
            Dados Gerados em:
          </div>
          <div>
            {{this.setVersionDateFormat(this.currentFlightPlanContentData.date_time_create_upload)}}
          </div>
        </div>
      </div>
    </div>
    <div class="p-toolbar-group-center"></div>
    <div class="p-toolbar-group-end">
      <div class="cls-page-box-right">
        <p-button label="ATUALIZAR DADOS DA DOCUMENTAÇÃO" icon="fa-solid fa-rotate" [raised]="true" severity="warning"
          (click)="callbackButton(this.labelActionsButtons.RENEW)" class="cls-component-button-pattern-default">
        </p-button>
      </div>
    </div>
  </p-toolbar>
</section>