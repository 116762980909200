<div #countdownClock class="cls-component-countdown">
    <div class="cls-component-countdown-wrap">
        <div class="cls-component-countdown-label">
            <div class="cls-component-countdown-label-days">DIAS</div>
            <div class="cls-component-countdown-label-content">TEMPO ATÉ EOBT</div>
        </div>
        <div class="cls-component-countdown-clock">            
            <div class="bloc-time days">
                <!--<span class="count-title">D</span>-->
                <div class="figure days days-1">
                    <span class="top">0</span>
                    <span class="top-back">
                        <span>0</span>
                    </span>
                    <span class="bottom">0</span>
                    <span class="bottom-back">
                        <span>0</span>
                    </span>
                </div>

                <div class="figure days days-2">
                    <span class="top">0</span>
                    <span class="top-back">
                        <span>0</span>
                    </span>
                    <span class="bottom">0</span>
                    <span class="bottom-back">
                        <span>0</span>
                    </span>
                </div>
            </div>
            <div class="cls-component-countdown-separator">
                -
            </div>            
            <div class="bloc-time hours">
                <!--<span class="count-title">H</span>-->

                <div class="figure hours hours-1">
                    <span class="top">0</span>
                    <span class="top-back">
                        <span>0</span>
                    </span>
                    <span class="bottom">0</span>
                    <span class="bottom-back">
                        <span>0</span>
                    </span>
                </div>

                <div class="figure hours hours-2">
                    <span class="top">0</span>
                    <span class="top-back">
                        <span>0</span>
                    </span>
                    <span class="bottom">0</span>
                    <span class="bottom-back">
                        <span>0</span>
                    </span>
                </div>
            </div>
            <div class="cls-component-countdown-separator">
                :
            </div>
            <div class="bloc-time minutes">
                <!--<span class="count-title">M</span>-->

                <div class="figure min min-1">
                    <span class="top">0</span>
                    <span class="top-back">
                        <span>0</span>
                    </span>
                    <span class="bottom">0</span>
                    <span class="bottom-back">
                        <span>0</span>
                    </span>
                </div>

                <div class="figure min min-2">
                    <span class="top">0</span>
                    <span class="top-back">
                        <span>0</span>
                    </span>
                    <span class="bottom">0</span>
                    <span class="bottom-back">
                        <span>0</span>
                    </span>
                </div>
            </div>
            <div class="cls-component-countdown-separator">
                :
            </div>
            <div class="bloc-time sec">
                <!--<span class="count-title">S</span>-->

                <div class="figure sec sec-1">
                    <span class="top">0</span>
                    <span class="top-back">
                        <span>0</span>
                    </span>
                    <span class="bottom">0</span>
                    <span class="bottom-back">
                        <span>0</span>
                    </span>
                </div>

                <div class="figure sec sec-2">
                    <span class="top">0</span>
                    <span class="top-back">
                        <span>0</span>
                    </span>
                    <span class="bottom">0</span>
                    <span class="bottom-back">
                        <span>0</span>
                    </span>
                </div>
            </div>
        </div>
    </div>
</div>



<!--
    https://medium.com/geekculture/23-javascript-countdown-timer-for-website-273efc2f5618
    https://github.com/pqina/flip/tree/master
-->