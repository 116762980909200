<div class="cls-ui-primeng-field">
    <div class="field" [ngClass]="{'cls-field-wrong': this.showErrors() }">
        <ng-container *ngIf="this.showLabel">
            <label class="cls-field-label"
                [ngClass]="{'cls-label-bold': this.getShowLabelBold(), 'cls-field-wrong': this.showErrors() }"
                for="{{ this.idField }}">
                {{ this.label }}
                <ng-container *ngIf="this.showToolTipIcon">
                    <i [pTooltip]="tooltipContent" [showDelay]="500" [hideDelay]="300"
                        class="fa-solid fa-comment-dots ml-1"></i>
                </ng-container>
            </label>
            <ng-template #tooltipContent>
                <div class="flex align-items-center">
                    <img src="https://primefaces.org/cdn/primeng/images/primeng.svg" height="20" class="mr-2" />
                    <span> {{ this.getTooTipContent() }} </span>
                </div>
            </ng-template>
        </ng-container>
        <span [class]="this.getIconSide()">
            <ng-container *ngIf="this.showIcon()">
                <i [class]="this.getIconName()" [ngClass]="{'cls-field-wrong': this.showErrors() }"></i>
            </ng-container>
            <!-- É preciso fazer duas versões diferentes do componente por causa do "oninput" que transforma a letra digitada 
                em Maiúsculas, antes de atribuir ao controle. Este evento é difícil de tratar, pois inicia antes da finalização do
                componente, ou seja, não enxerga as vaiáveis e nem métodos...
                Quando o processo era feito usando o "Keyup" eram disparados 1 evento, de alteração de valor de controle do form,
                na hora da atribuição inicial e uma Segundo quando era feita a transformação para Maiúscula. Isto é um erro e 
                gera um grande problema na hora de tratar eventos, como por exemplo: capturar um valor para criar uma caixa de 
                sugestão de valores. -->
            <ng-container *ngIf="this.letterCase === 'upper'">
                <textarea 
                    [formControl]="this.control" 
                    [ngClass]="{ 'is-danger': this.showErrors() }"
                    [class]="this.getInputClass()" 
                    [maxlength]="this.getMaxCharacters()"
                    autocomplete="off" 
                    oninput="this.value = this.value ? this.value.toUpperCase() : null"
                    placeholder="{{this.placeHolderContent}}" 
                    rows="{{ this.getRowsSize() }}"
                    id="{{ this.idField }}" 
                    name="{{ this.getFieldName() }}"
                    pInputTextarea>
                </textarea>
            </ng-container>
            <ng-container *ngIf="this.letterCase === 'lower'">
                <textarea 
                    [formControl]="this.control" 
                    [ngClass]="{ 'is-danger': this.showErrors() }"
                    [class]="this.getInputClass()" 
                    [maxlength]="this.getMaxCharacters()"
                    autocomplete="off" 
                    placeholder="{{this.placeHolderContent}}" 
                    id="{{ this.idField }}"
                    name="{{ this.getFieldName() }}"
                    rows="{{ this.getRowsSize() }}"
                    pInputTextarea>
                </textarea>
            </ng-container>
        </span>
    </div>
    <ui-primeng-error-message-controls class="cls-pmg-field-error-message" [control]="this.control" typeControl="input">
    </ui-primeng-error-message-controls>   
</div>