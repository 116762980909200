/**
 * TODO: https://tsdoc.org/
 */
export const NAME_TYPE_DOCUMENT = {
    "complete_executive": "Completo Executiva",
    "complete_company": "Completo Companhia",
    "card_aerodrome": "Card de Aeródromos",
    "card_top_risk": "Card Top Risk",
    "card_frequency": "Carde de Frequência",
    "card_meteorology": "Card Meteorologia",
    "card_notam": "Card NOTAM"
}
