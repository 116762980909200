<section id="id-toolbar-tabpanel-aero-registration" class="cls-component-toolbar-control-form">
    <p-toolbar class="cls-component-toolbar-control-form-pattern">
        <div class="p-toolbar-group-start"></div>
        <div class="p-toolbar-group-center"></div>
        <div class="p-toolbar-group-end">
            <ng-container *ngIf="this.formComponent.controls.aircraft_data_id.value === null">
                <p-button label="SALVAR REGISTRO" icon="fa-regular fa-floppy-disk" [raised]="true"
                    (click)="callbackPageButton(this.labelActionsButtons.SAVE)" severity="secondary"
                    class="cls-component-button-pattern-default">
                </p-button>
            </ng-container>
            <ng-container *ngIf="this.formComponent.controls.aircraft_data_id.value !== null">
                <p-button label="SALVAR ALTERAÇÃO" icon="fa-regular fa-floppy-disk" [raised]="true" severity="secondary"
                    (click)="callbackPageButton(this.labelActionsButtons.UPDATE)"
                    class="cls-component-button-pattern-default">
                </p-button>
                <p-button label="NOVO CADASTRO" icon="fa-regular fa-plus" [raised]="true"
                    (click)="callbackPageButton(this.labelActionsButtons.NEW)" severity="secondary"
                    class="cls-component-button-pattern-default">
                </p-button>
            </ng-container>
            <p-button label="FECHAR" icon="fa-solid fa-right-from-bracket"
                (click)="callbackPageButton(this.labelActionsButtons.CANCEL)" [raised]="true" severity="secondary"
                class="cls-component-button-pattern-default">
            </p-button>

            <!--<div class="cls-page-box-right">
                <p-button label="Legenda" icon="fa-solid fa-info" [text]="true" severity="secondary"
                    class="cls-component-toolbar-page-control-button-legend"></p-button>
            </div>-->
        </div>
    </p-toolbar>
</section>