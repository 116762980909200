<section class="cls-component-data-panel">
    <p-panel header="Arquivo PDF" class="cls-component-panel-formgrid">
        <nashville-toolbar-panel-generate-pdf (generatePdfButtonAction)="this.callbackFromToolbarButton($event)"></nashville-toolbar-panel-generate-pdf>
        <div class="cls-component-generate-pdf">
            <div class="cls-component-generate-pdf-check">
                <ui-primeng-checkbox idField="fid-only-most-recent"
                    [control]="this.formDocumentGeneration.controls.only_most_recent"
                    label="Somente o mais recente, por tipo"></ui-primeng-checkbox>
            </div>
            <p-table [value]="this.formComponent.controls.documents_files.value" class="p-datatable-sm"
                scrollHeight="15rem" scrollWidth="20rem">
                <ng-template pTemplate="header"> 
                    <tr>
                        <th>Documentos PDF ({{this.formComponent.controls.documents_files.value.length}})</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-documentPdf  let-rowIndex="rowIndex">
                    <ng-container
                        *ngIf="documentPdf.document_type !== undefined">
                        <ng-container
                            *ngIf="this.showDocumentValueInTable(rowIndex, documentPdf.document_type)">
                            <tr>
                                <td>
                                    <div class="cls-component-generate-pdf-row">
                                        <div class="cls-component-generate-pdf-row-type">
                                            {{this.objNameTypeDocument[documentPdf.document_type]}}
                                        </div>
                                        <div class="cls-component-generate-pdf-row-name">
                                            <div>{{documentPdf.name}}</div>
                                        </div>
                                        <div class="cls-component-generate-pdf-row-frame">
                                            <div class="cls-component-generate-pdf-row-date"
                                                [ngClass]="{'cls-component-generate-pdf-row-date-ok': this.checkDateTime(this.documentPdf.timestamp) === true, 'cls-component-generate-pdf-row-date-not-ok': this.checkDateTime(this.documentPdf.timestamp) === false }">
                                                {{this.setVersionDateFormat(this.documentPdf.timestamp)}}
                                            </div>
                                            <div class="cls-component-generate-pdf-row-buttons">
                                                <p-button icon="fa-solid fa-eye" [rounded]="true" severity="help"
                                                    (click)="viewPdfFromServer(documentPdf.name)" />
                                                <p-button icon="fa-solid fa-download" [rounded]="true" severity="info"
                                                    (click)="downloadPdfFromServer(documentPdf.name)" />
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </ng-container>
                    </ng-container>
                </ng-template>
            </p-table>
        </div>
    </p-panel>
</section>