//#region "|--- IMPORT MODULES/PACKAGES ---|"
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';

import { settingConfig } from '@nashville-config';

import { PrimengComponentsModule } from '@nashville-primeng';
//#endregion

//#region "|--- INTERFACE ---|"
import { IToastParameters } from 'src/app/interfaces/IToastParameters';
//#endregion

@Component({
  selector: 'nashville-panel-image-color-avatar',
  standalone: true,
  imports: [
    CommonModule,
    PrimengComponentsModule
  ],
  templateUrl: './panel-image-color-avatar.component.html',
  styleUrl: './panel-image-color-avatar.component.scss',
})
export class PanelImageColorAvatarComponent implements OnInit {
  //#region "|--- INPUTS ---|"
  @Input() formComponent!: any;
  @Input() pageInnerWidth!: any;
  //#endregion

  //#region "|--- OUTPUTS ---|"
  @Output() showToastMessage = new EventEmitter<IToastParameters>();
  //#endregion

  //#region "|--- PROPERTIES---|"
  settingConfig!: any;
  serverUrl: any;
  //#endregion

  ngOnInit(): void {
    this._initVariables();
  }

  //#region "|--- PUBLIC METHODS---|" 
  /**
   * TODO: https://tsdoc.org/
   */
  deleteAvatarImage() {
    this.formComponent.controls.avatar_image.setValue("no_image_available.jpg");

    this.showToastMessage.emit({
      severity: 'info',
      summary: 'Image Aeronave',
      detail: 'A imagem da aeronave foi apagada.'
    });
  }

  /**
   * TODO: https://tsdoc.org/
   */
  onSuccessAircraftImageUpload(xEvent: any) {
    if (xEvent.body && xEvent.body.data_info) {
      this.formComponent.controls.avatar_image.setValue(xEvent.body.data_info.path);

      this.showToastMessage.emit({
        severity: 'success',
        summary: 'Upload Image Aeronave',
        detail: 'A imagem da aeronave foi enviada para o servidor com sucesso.'
      });
    } else {
      this.showToastMessage.emit({
        severity: 'warn',
        summary: 'TESTEEEEEEEEEEEEEE',
        detail: 'A imagem da aeronave foi enviada para o servidor com sucesso.'
      });
    }
  }

  /**
   * TODO: https://tsdoc.org/
   */
  onErrorAircraftImageUpload(xEvent: any) {
    if (xEvent.error) {
      if (JSON.stringify(xEvent).indexOf('404') >= 0) {
        this.showToastMessage.emit({
          severity: 'error',
          summary: 'Upload Image Aeronave',
          detail: 'a URL de upload da imagem não foi encontrado.'
        });
      } else if (JSON.stringify(xEvent).indexOf('Unknown Error') >= 0) {
        this.showToastMessage.emit({
          severity: 'error',
          summary: 'Upload Image Aeronave',
          detail: `Houve um erro de Upload da Imagem. \n\n\t DETALHES: (${xEvent.message})`
        });
      }
    } else {
      this.showToastMessage.emit({
        severity: 'error',
        summary: 'Upload Image Aeronave',
        detail: `Houve um erro inesperado. \n\n\t DETALHES: (${xEvent.message})`
      });
    }
  }

  /**
   * TODO: https://tsdoc.org/
   */
  onErrorAircraftImage(event: any) {
    console.log("CCCCCCCCCCCCCCCCC", event);
  }
  //#endregion

  //#region "|--- PRIVATE METHODS ---|"  
  /**
   * TODO: https://tsdoc.org/
   */
  private _initVariables(): void {
    if (settingConfig.API_AIRCRAFT_DATA_PORT == "80") {
      this.serverUrl = `${settingConfig.API_PROTOCOL}://${settingConfig.API_HOST}/${settingConfig.API_PATH}`
    } else {
      this.serverUrl = `${settingConfig.API_PROTOCOL}://${settingConfig.API_HOST}:${settingConfig.API_AIRCRAFT_DATA_PORT}/${settingConfig.API_PATH}`
    }
  }
  //#endregion
}
