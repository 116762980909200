<section id="id-dialog-route-search" class="cls-component-dialog-control">
  <p-dialog header="RECUPERAR ROTA" [modal]="true" [(visible)]="this.showDialog" [closable]="false"
    [style]="{ width: '25rem' }" class="cls-component-dialog-pattern">
    <ng-template pTemplate="header">
      <span class="cls-component-dialog-pattern-title">SALVAR ROTA</span>
    </ng-template>
    <ng-template pTemplate="content"> </ng-template>
    <ng-template pTemplate="footer">
      <section class="cls-component-toolbar-control-dialog">
        <p-toolbar class="cls-component-toolbar-control-dialog-pattern">
          <div class="p-toolbar-group-start"></div>
          <div class="p-toolbar-group-center"></div>
          <div class="p-toolbar-group-end">
            <div class="cls-page-box-left">
              <p-button label="SALVAR" icon="fa-solid fa-rotate" [raised]="true" severity="success"
                class="cls-component-button-pattern-default">
              </p-button>
              <p-button label="CANCELAR" icon="fa-regular fa-file-pdf" [raised]="true" severity="danger"
                class="cls-component-button-pattern-default" (click)="callbackButton(this.labelActionsButtons.CLOSE_DIALOG)">
              </p-button>
            </div>
          </div>
        </p-toolbar>
      </section>
    </ng-template>
  </p-dialog>
</section>