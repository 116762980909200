<section id="id-toolbar-flight-request-form" class="cls-component-toolbar-control-form">
  <p-toolbar class="cls-component-toolbar-control-form-pattern">
    <div class="p-toolbar-group-start">
      <p-button icon="fa-regular fa-message" severity="secondary" [raised]="true" class="cls-component-button-pattern-default ml-3"
        (click)="callbackButton(this.labelActionsButtons.SHOW_MESSAGING)" />
      <p-button icon="fa-solid fa-list-check" severity="secondary" [raised]="true" class="cls-component-button-pattern-default"
        (click)="callbackButton(this.labelActionsButtons.SHOW_CHECK_LIST)" />
    </div>
    <div class="p-toolbar-group-center"></div>
    <div class="p-toolbar-group-end">
      <ng-container *ngIf="this.formComponent.controls.flight_request_code.controls.normalized_text.value === null">
        <p-button label="SALVAR SOLICITAÇÃO" icon="fa-regular fa-floppy-disk" [raised]="true"
          (click)="callbackButton(this.labelActionsButtons.SAVE)" severity="secondary"
          class="cls-component-button-pattern-default">
        </p-button>
      </ng-container>
      <ng-container *ngIf="this.formComponent.controls.flight_request_code.controls.normalized_text.value !== null">
        <ng-container
          *ngIf="this.formComponent.controls.fk_flight_plan_data_id.value === null || this.formComponent.controls.fk_flight_plan_data_id.value === ''">
          <p-button label="INICIAR ATENDIMENTO" icon="fa-regular fa-circle-play" [raised]="true"
            (click)="callbackButton(this.labelActionsButtons.START)" severity="success"
            class="cls-component-button-pattern-default">
          </p-button>
        </ng-container>

        <ng-container *ngIf="this.formComponent.controls.fk_flight_plan_data_id.value !== null">
          <p-button label="SALVAR MODIFICAÇÕES" icon="fa-solid fa-arrows-spin" [raised]="true"
            (click)="callbackButton(this.labelActionsButtons.UPDATE)" severity="secondary"
            class="cls-component-button-pattern-default">
          </p-button>
        </ng-container>

        <!-- VOLTAR -->
        <!--<p-splitButton label="NOVA SOLICITAÇÃO" icon="fa-solid fa-plus" [model]="this.itemsSubMenuNew"
          styleClass="p-button-raised p-button-info" class="cls-component-button-pattern-default-split">
        </p-splitButton>-->
      </ng-container>
      <p-button label="FECHAR" icon="fa-solid fa-right-from-bracket" [raised]="true" severity="secondary"
        class="cls-component-button-pattern-default" [routerLink]="'/flight-request/list'">
      </p-button>
      <!--<div class="cls-page-box-right">
                <p-button label="Legenda" icon="fa-solid fa-info" [text]="true" severity="secondary"
                    class="cls-component-toolbar-form-control-button-legend"></p-button>
            </div>-->
    </div>
  </p-toolbar>
</section>