//#region "|--- IMPORT MODULES/PACKAGES ---|"
import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';

import { settingConfig } from '@nashville-config';

import { MessageService } from 'primeng/api';

import { PrimengComponentsModule } from '@nashville-primeng';
import { FileUpload, FileUploadErrorEvent, FileUploadEvent, UploadEvent } from 'primeng/fileupload';
//#endregion

//#region "|--- IMPORT COMPONENTS ---|"
import { AddressHandlerComponent } from 'src/app/features/aircraft-data/components/address_handler/address_handler.component';
import { ContactHandlerComponent } from 'src/app/features/aircraft-data/components/contact_handler/contact_handler.component';
//#endregion

//#region "|--- ENUMS ---|"
import { BUTTON_ACTION } from '../../../../../../helpers/enum/ButtonActions';
//#endregion

//#region "|--- DROPDOWN OPTIONS ---|"
import { typeAircraftOptions } from '../../../../../../helpers/dropdown-static-options/typeAircraftOptions';
import { flightRulesOptions } from '../../../../../../helpers/dropdown-static-options/flightRulesOptions';
//#endregion

import { AircraftDataApisService } from '../../../../services/aircraft-data-apis.service';


@Component({
  selector: 'nashville-tab-view-aero-registration',
  standalone: true,
  imports: [
    CommonModule,
    PrimengComponentsModule,
    AddressHandlerComponent,
    ContactHandlerComponent
  ],
  templateUrl: './tab-view-aero-registration.component.html',
  styleUrl: './tab-view-aero-registration.component.scss',
})
export class TabViewAeroRegistrationComponent implements OnInit {
  //#region "|--- INPUTS ---|"
  @Input() pageInnerWidth!: any;
  @Input() formComponent!: any;
  //#endregion

  //#region "|--- OUTPUTS ---|"
  @Output() tableButtonAction = new EventEmitter<string>();
  //#endregion

  //#region "|--- PROPERTIES---|"
  settingConfig!: any;

  optionsTypeAircraft: any;
  optionsFlightRules: any;

  serverUrl: any;

  labelActionsButtons: any;

  arrayOwnersOperators: any;
  //#endregion

  constructor(
    private aircraftDataApisService: AircraftDataApisService,
    private messageService: MessageService,    
  ) { }

  ngOnInit(): void {
    this._initVariables();

    this.serverUrl = `${settingConfig.API_PROTOCOL}://${settingConfig.API_HOST}:${settingConfig.API_AIRCRAFT_DATA_PORT}/${settingConfig.API_PATH}`
  }


  //#region "|--- PUBLIC METHODS---|" 
  /**
   * TODO: https://tsdoc.org/
   */
  callbackShowDialog(xValue: string) {
    this.tableButtonAction.emit(xValue);
  }

  //#region "|--- PUBLIC METHODS---|" 
  /**
   * TODO: https://tsdoc.org/
   */
  onSuccessAircraftImageUpload(xEvent: any) {
    this.formComponent.controls.avatar_image.setValue(xEvent.body.data_info.path);

    this.messageService.add({ key: 'toast2', severity: 'success', summary: 'Upload Image Aeronave', detail: 'A Imagem da Aeronave foi enviada para o Servidor com Sucesso.' });
  }

  /**
   * TODO: https://tsdoc.org/
   */
  onErrorAircraftImageUpload(xEvent: any) {
    if (xEvent.error) {
      if (JSON.stringify(xEvent).indexOf('404') >= 0) {
        //TODO: TRANSLATION
        //TODO: MESSENGER
        this.messageService.add({ key: 'toast2', severity: 'error', summary: 'Imagem Aeronave', detail: 'O Endereço de Upload da Imagem não foi encontrado.' });
      } else if (JSON.stringify(xEvent).indexOf('Unknown Error') >= 0) {
        //TODO: TRANSLATION
        //TODO: MESSENGER
        this.messageService.add({ key: 'toast2', severity: 'error', summary: 'Imagem Aeronave', detail: `Houve um erro de Upload da Imagem. \n\n\t DETALHES: (${xEvent.message})` });
      }
    } else {
      //TODO: TRANSLATION
      //TODO: MESSENGER
      this.messageService.add({ key: 'toast2', severity: 'error', summary: 'Imagem Aeronave', detail: `Houve um erro inesperado. \n\n\t DETALHES: (${xEvent.message})` });
    }
  }

  /**
   * TODO: https://tsdoc.org/
   */
  onErrorAircraftImage(event: any) {
    console.log("CCCCCCCCCCCCCCCCC", event);
  }


  //#endregion

  //#region "|--- PRIVATE METHODS---|" 
  /**
   * TODO: https://tsdoc.org/
   */
  private _initVariables(): void {
    this.labelActionsButtons = BUTTON_ACTION;

    this.settingConfig = settingConfig;

    this.optionsTypeAircraft = typeAircraftOptions;
    this.optionsFlightRules = flightRulesOptions;
  }
  //#endregion
}
