//#region "|--- IMPORT MODULES/PACKAGES ---|"
// ***** ANGULAR *****
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';

// ***** NPM *****
import { MenuItem } from 'primeng/api';

// ***** NASHVILLE *****
import { PrimengComponentsModule } from '@nashville-primeng';
import { LocalMethodsHandlerClass } from '@nashville-local-methods';
//#endregion

//#region "|--- IMPORT ENUMS ---|"
import { BUTTON_ACTION } from '../../../../../../helpers/enum/ButtonActions';
//#endregion

@Component({
  selector: 'nashville-toolbar-panel-content-pdf',
  standalone: true,
  imports: [
    CommonModule,
    PrimengComponentsModule
  ],
  templateUrl: './toolbar-panel-content-pdf.component.html',
  styleUrl: './toolbar-panel-content-pdf.component.scss',
})
export class ToolbarPanelContentPdfComponent implements OnInit {
  //#region "|--- INPUTS ---|"
  @Input() pageInnerWidth!: any;

  // É feito o set do @Input para que seja capturado qualquer modificação, em tempo real, 
  // do parâmetro que está sendo enviado pelo PARENT e, principalmente, que seja feito o 
  // processamento necessário com base no novo valor.
  @Input() set flightPlanContent(xValue: any) {
    console.log("###############------", xValue)
    
    this.currentFlightPlanContentData = xValue;
  };
  //#endregion

  //#region "|--- OUTPUTS ---|"
  @Output() callbackFromToolbarButton = new EventEmitter<any>();
  //#endregion

  //#region "|--- PROPERTIES---|"
  itemsSubMenuNew!: MenuItem[];

  currentFlightPlanContentData: any;

  labelActionsButtons: any;
  //#endregion

  /**
   * TODO: https://tsdoc.org/
   */
  ngOnInit(): void {
    this._initVariables();
  }

  //#region "|--- PUBLIC METHODS ---|"
  /**
   * type METHOD.
   * @version 1.0.0 (GASPAR - 29/06/2024).
  .
   * 
   * @description
   * - Faz a comparação entre um TimeStamp que foi passado e a Hora Atual, a fim de saber qual é a maior.
   * - Neste caso o TimeStamp çe acrescido de 30 minutos
   * 
   * @param xTimeStamp 
   * - TimeStamp que se deseja comparar com a hora atual
   * @returns 
   */
  checkDateTime(xTimeStamp: any) {
    // Este método é chamado direto, por isto tem que somar 30 minutos ao tempo do PDF.
    const pdfDateTime30More = new Date(xTimeStamp);
    const currentDateTime = new Date();

    pdfDateTime30More.setMinutes(pdfDateTime30More.getMinutes() + 30);

    if (currentDateTime < pdfDateTime30More) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * type METHOD.
   * @version 1.0.0 (GASPAR - 27/06/2024).
  .
   * 
   * @description
   * - Faz a Conversão do Formato de Timestamp, para "dd/mm/aaaa - hh:mm"
   * - Como esta parte é feita no html, é preciso criar este método intermediário, já a classe LocalMethodsHandlerClass não é reconhecida.
   * @param xDate 
   * @returns 
   */
  setVersionDateFormat(xDate: number) {
    return `${LocalMethodsHandlerClass.processTimeStampToDateTimeFormat(new Date(xDate))}`;
  }
  //#endregion


  //#region "|--- CALLBACK METHODS---|"
  /**
   * TODO: https://tsdoc.org/
   */
  callbackButton(xValue: any) {
    this.callbackFromToolbarButton.emit(xValue);
  }
  //#endregion

  //#region "|--- PRIVATE METHODS---|" 
  /**
   * TODO: https://tsdoc.org/
   */
  private _initVariables() {
    this.labelActionsButtons = BUTTON_ACTION;
  }
  //#endregion
}
