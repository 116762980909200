import { Component, OnInit, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

import * as L from 'leaflet';

//#region "|--- INTERFACES ---|"
import { IAerodromeMarkerOnTheMap } from 'src/app/interfaces/IAerodromeMarkerOnTheMap';
import { ICityMarkerOnTheMap } from 'src/app/interfaces/ICityMarkerOnTheMap';
import { ICoordinateMarkerOnTheMap } from 'src/app/interfaces/ICoordinateMarkerOnTheMap';
//#endregion

//#region "|--- CONSTANTS ---|
const INITIAL_LAT = -14.654012;
const INITIAL_LNG = -56.162814;
const INITIAL_ZOOM = 2;
//#endregion

@Component({
  selector: 'nashville-search-location-map',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './search-location-map.component.html',
  styleUrl: './search-location-map.component.scss',
})
export class SearchLocationMapComponent implements OnInit {
  //#region "|--- PRIVATE VARIABLE USED TO HELP "INPUT PROPERTY SETTER" ---|"
  private _showMap!: boolean;

  private _citiesMarkers!: ICityMarkerOnTheMap[];
  private _coordinatesMarkers!: ICoordinateMarkerOnTheMap[];
  private _aerodromesMarkers!: IAerodromeMarkerOnTheMap[];
  //#endregion

  //#region "|--- INPUTS ---|"
  // É feito o set do @Input para que seja capturado qualquer modificação, em tempo real, 
  // do parâmetro que está sendo enviado pelo PARENT e, principalmente, que seja feito o 
  // processamento necessário com base no novo valor.
  @Input() set showMap(xValue: boolean) {
    const tmpContext = this; // Foi criado para guardar o contexto geral em uma variável de escopo, pois na hora de executar o "_initMap()" mus-ase de contexto e n$ao se encontra este método.

    tmpContext._showMap = xValue;

    // Aqui vai ser feito a Inicialização do MAP.
    if (tmpContext._showMap == true) {
      if (!tmpContext.map_search_location) {
        setTimeout(function () {
          tmpContext._initMap(); // 
          tmpContext._initIconsToMarkers();
        }, 500); // Adjust the value (in ms)
      }
    }
  };

  // É feito o set do @Input para que seja capturado qualquer modificação, em tempo real, 
  // do parâmetro que está sendo enviado pelo PARENT e, principalmente, que seja feito o 
  // processamento necessário com base no novo valor.
  @Input() set aerodromesMarkers(xValue: IAerodromeMarkerOnTheMap[]) {
    this._aerodromesMarkers = xValue;

    if (xValue) {
      if (this._showMap == true) { // Fazer somente quando o mapa está sendo mostrado.
        this._settingAerodromesMarkers();
      }
    }
  };

  // É feito o set do @Input para que seja capturado qualquer modificação, em tempo real, 
  // do parâmetro que está sendo enviado pelo PARENT e, principalmente, que seja feito o 
  // processamento necessário com base no novo valor.
  @Input() set citiesMarkers(xValue: ICityMarkerOnTheMap[]) {
    this._citiesMarkers = xValue;

    if (xValue) {
      if (this._showMap == true) { // Fazer somente quando o mapa está sendo mostrado.
        this._settingCitiesMarkers();
      }
    }
  };

  // É feito o set do @Input para que seja capturado qualquer modificação, em tempo real, 
  // do parâmetro que está sendo enviado pelo PARENT e, principalmente, que seja feito o 
  // processamento necessário com base no novo valor.
  @Input() set coordinatesMarkers(xValue: ICoordinateMarkerOnTheMap[]) {
    this._coordinatesMarkers = xValue;

    if (xValue) {
      if (this._showMap == true) { // Fazer somente quando o mapa está sendo mostrado.
        this._settingCoordinatesMarkers();
      }
    }
  };
  //#endregion

  //#region "|--- PROPERTIES---|"
  private map_search_location!: L.Map;

  objAerodromesMarkersOnMap: any = {};   // Armazena todos os Marcadores referentes aos AERÓDROMOS que foram plotados no MAPA.
  objCitiesMarkersOnMap: any = {};       // Armazena todos os Marcadores referentes a CIDADES que foram plotados no MAPA.
  objCoordinatesMarkerOnMap: any = {};   // Armazena todos os Marcadores referentes as COORDENADAS que foram plotados no MAPA.

  objAerodromesIconsToMarker: any = {};   // Lista dos Ícones para os Marcadores dos Aeródromos.
  objCityIconsToMarker: any = {};         // Lista dos Ícones para os Marcadores das Cidades.
  objCoordinatesIconsToMarker: any = {};   // Lista dos Ícones para os Marcadores das Coordenadas.
  //#endregion

  ngOnInit(): void {
    this._initVariables();
  }
  //#region "|--- PUBLIC METHODS ---|"

  //#endregion

  //#region "|--- PRIVATE METHODS ---|"
  /**
   * TODO: https://tsdoc.org/
   */
  private _initMap(): void {
    
    this.map_search_location = L.map('map-search-location', {
      center: [INITIAL_LAT, INITIAL_LNG],
      zoom: INITIAL_ZOOM
    });

    const tiles = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 18,
      minZoom: 3,
      attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
    });

    tiles.addTo(this.map_search_location);
  }

  //#region "|--- PRIVATE METHODS ---|"
  /**
   * TODO: https://tsdoc.org/
   */
  private _settingAerodromesMarkers() {
    // Se tiver MARCADORES para Plotar, deve-se apagar todos os marcadores anteriores...
    // Se não tiver nenhum MARCADOR para Plotar, deve-se apagar todos os marcadores anteriores...
    this._clearAllAerodromesMarkerOnMap();

    if (this._aerodromesMarkers) {
      this._aerodromesMarkers.forEach((xElementAerodrome: IAerodromeMarkerOnTheMap) => {
        // Para apagar depois os marcadores, é preciso armazenar em um array cada um qeu foi criado.
        this.objAerodromesMarkersOnMap[`${xElementAerodrome.id_marker}`] = L.marker([xElementAerodrome.coordinates_dd![0], xElementAerodrome.coordinates_dd![1]], { icon: this.objAerodromesIconsToMarker["blackIcon"] }).addTo(this.map_search_location);
      });
    }

    this._centerMapByMarkers();
  }

  /**
   * TODO: https://tsdoc.org/
   */
  private _settingCitiesMarkers() {
    // Se tiver MARCADORES para Plotar, deve-se apagar todos os marcadores anteriores...
    // Se não tiver nenhum MARCADOR para Plotar, deve-se apagar todos os marcadores anteriores...
    this._clearAllCitiesMarkerOnMap();

    if (this._citiesMarkers) {
      this._citiesMarkers.forEach((xElementCity: ICityMarkerOnTheMap) => {
        // Para apagar depois os marcadores, é preciso armazenar em um array cada um qeu foi criado.
        this.objCitiesMarkersOnMap[`${xElementCity.id_marker}`] = L.marker([xElementCity.coordinates_dd![0], xElementCity.coordinates_dd![1]], { icon: this.objCityIconsToMarker["blackIcon"] }).addTo(this.map_search_location);
      });
    }

    this._centerMapByMarkers();
  }

  /**
   * TODO: https://tsdoc.org/
   */
  private _settingCoordinatesMarkers() {
    // Se tiver MARCADORES para Plotar, deve-se apagar todos os marcadores anteriores...
    // Se não tiver nenhum MARCADOR para Plotar, deve-se apagar todos os marcadores anteriores...
    this._clearAllCoordinatesMarkerOnMap();

    if (this._coordinatesMarkers) {
      this._coordinatesMarkers.forEach((xElementCoordinate: ICoordinateMarkerOnTheMap) => {
        // Para apagar depois os marcadores, é preciso armazenar em um array cada um qeu foi criado.
        this.objCoordinatesMarkerOnMap[`${xElementCoordinate.id_marker}`] = L.marker([xElementCoordinate.coordinates_dd![0], xElementCoordinate.coordinates_dd![1]], { icon: this.objCoordinatesIconsToMarker["blackIcon"] }).addTo(this.map_search_location);
      });
    }

    this._centerMapByMarkers();
  }

  /**
   * TODO: https://tsdoc.org/
   */
  private _initIconsToMarkers() {
    //#region "|--- AERODROMES ---|"
    //https://www.iconpacks.net/free-icon/airport-location-4359.html
    this.objAerodromesIconsToMarker["blueIcon"] = L.icon({
      iconUrl: '../../../../../assets/imgs/l_icon/airport-location-blue.png',
      iconSize: [40, 40], // size of the icon
      iconAnchor: [20, 40], // point of the icon which will correspond to marker's location
      popupAnchor: [-3, -76] // point from which the popup should open relative to the iconAnchor
    });

    this.objAerodromesIconsToMarker["greenIcon"] = L.icon({
      iconUrl: '../../../../../assets/imgs/l_icon/airport-location-green.png',
      iconSize: [40, 40], // size of the icon
      iconAnchor: [20, 40], // point of the icon which will correspond to marker's location
      popupAnchor: [-3, -76] // point from which the popup should open relative to the iconAnchor
    });

    this.objAerodromesIconsToMarker["redIcon"] = L.icon({
      iconUrl: '../../../../../assets/imgs/l_icon/airport-location-red.png',
      iconSize: [40, 40], // size of the icon
      iconAnchor: [20, 40], // point of the icon which will correspond to marker's location
      popupAnchor: [-3, -76] // point from which the popup should open relative to the iconAnchor
    });

    this.objAerodromesIconsToMarker["blackIcon"] = L.icon({
      iconUrl: '../../../../../assets/imgs/l_icon/airport-location-black.png',
      iconSize: [40, 40], // size of the icon
      iconAnchor: [20, 40], // point of the icon which will correspond to marker's location
      popupAnchor: [-3, -76] // point from which the popup should open relative to the iconAnchor
    });

    this.objAerodromesIconsToMarker["roxoIcon"] = L.icon({
      iconUrl: '../../../../../assets/imgs/l_icon/airport-location-roxo.png',
      iconSize: [40, 40], // size of the icon
      iconAnchor: [20, 40], // point of the icon which will correspond to marker's location
      popupAnchor: [-3, -76] // point from which the popup should open relative to the iconAnchor
    });

    this.objAerodromesIconsToMarker["orangeIcon"] = L.icon({
      iconUrl: '../../../../../assets/imgs/l_icon/airport-location-orange.png',
      iconSize: [40, 40], // size of the icon
      iconAnchor: [20, 40], // point of the icon which will correspond to marker's location
      popupAnchor: [-3, -76] // point from which the popup should open relative to the iconAnchor
    });

    this.objAerodromesIconsToMarker["yellowIcon"] = L.icon({
      iconUrl: '../../../../../assets/imgs/l_icon/airport-location-yellow.png',
      iconSize: [40, 40], // size of the icon
      iconAnchor: [20, 40], // point of the icon which will correspond to marker's location
      popupAnchor: [-3, -76] // point from which the popup should open relative to the iconAnchor
    });

    this.objAerodromesIconsToMarker["marronIcon"] = L.icon({
      iconUrl: '../../../../../assets/imgs/l_icon/airport-location-marron.png',
      iconSize: [40, 40], // size of the icon
      iconAnchor: [20, 40], // point of the icon which will correspond to marker's location
      popupAnchor: [-3, -76] // point from which the popup should open relative to the iconAnchor
    });
    //#endregion

    //#region "|--- CITIES ---|"
    //https://www.iconpacks.net/free-icon/location-blue-pin-sign-and-yellow-circle-15632.html
    this.objCityIconsToMarker["blueIcon"] = L.icon({
      iconUrl: '../../../../../assets/imgs/l_icon/city-location-blue.png',
      iconSize: [40, 40], // size of the icon
      iconAnchor: [20, 40], // point of the icon which will correspond to marker's location
      popupAnchor: [-3, -76] // point from which the popup should open relative to the iconAnchor
    });

    this.objCityIconsToMarker["greenIcon"] = L.icon({
      iconUrl: '../../../../../assets/imgs/l_icon/city-location-green.png',
      iconSize: [40, 40], // size of the icon
      iconAnchor: [20, 40], // point of the icon which will correspond to marker's location
      popupAnchor: [-3, -76] // point from which the popup should open relative to the iconAnchor
    });

    this.objCityIconsToMarker["redIcon"] = L.icon({
      iconUrl: '../../../../../assets/imgs/l_icon/city-location-red.png',
      iconSize: [40, 40], // size of the icon
      iconAnchor: [20, 40], // point of the icon which will correspond to marker's location
      popupAnchor: [-3, -76] // point from which the popup should open relative to the iconAnchor
    });

    this.objCityIconsToMarker["blackIcon"] = L.icon({
      iconUrl: '../../../../../assets/imgs/l_icon/city-location-black.png',
      iconSize: [40, 40], // size of the icon
      iconAnchor: [20, 40], // point of the icon which will correspond to marker's location
      popupAnchor: [-3, -76] // point from which the popup should open relative to the iconAnchor
    });

    this.objCityIconsToMarker["roxoIcon"] = L.icon({
      iconUrl: '../../../../../assets/imgs/l_icon/city-location-roxo.png',
      iconSize: [40, 40], // size of the icon
      iconAnchor: [20, 40], // point of the icon which will correspond to marker's location
      popupAnchor: [-3, -76] // point from which the popup should open relative to the iconAnchor
    });

    this.objCityIconsToMarker["orangeIcon"] = L.icon({
      iconUrl: '../../../../../assets/imgs/l_icon/city-location-orange.png',
      iconSize: [40, 40], // size of the icon
      iconAnchor: [20, 40], // point of the icon which will correspond to marker's location
      popupAnchor: [-3, -76] // point from which the popup should open relative to the iconAnchor
    });
    //#endregion

    //#region "|--- COORDINATES ---|"
    //https://www.iconpacks.net/free-icon/location-3408.html
    this.objCoordinatesIconsToMarker["blueIcon"] = L.icon({
      iconUrl: '../../../../../assets/imgs/l_icon/airport-location-blue.png',
      iconSize: [40, 40], // size of the icon
      iconAnchor: [20, 40], // point of the icon which will correspond to marker's location
      popupAnchor: [-3, -76] // point from which the popup should open relative to the iconAnchor
    });

    this.objCoordinatesIconsToMarker["greenIcon"] = L.icon({
      iconUrl: '../../../../../assets/imgs/l_icon/coordinate-location-green.png',
      iconSize: [40, 40], // size of the icon
      iconAnchor: [20, 40], // point of the icon which will correspond to marker's location
      popupAnchor: [-3, -76] // point from which the popup should open relative to the iconAnchor
    });

    this.objCoordinatesIconsToMarker["redIcon"] = L.icon({
      iconUrl: '../../../../../assets/imgs/l_icon/coordinate-location-red.png',
      iconSize: [40, 40], // size of the icon
      iconAnchor: [20, 40], // point of the icon which will correspond to marker's location
      popupAnchor: [-3, -76] // point from which the popup should open relative to the iconAnchor
    });

    this.objCoordinatesIconsToMarker["blackIcon"] = L.icon({
      iconUrl: '../../../../../assets/imgs/l_icon/coordinate-location-black.png',
      iconSize: [40, 40], // size of the icon
      iconAnchor: [20, 40], // point of the icon which will correspond to marker's location
      popupAnchor: [-3, -76] // point from which the popup should open relative to the iconAnchor
    });

    this.objCoordinatesIconsToMarker["roxoIcon"] = L.icon({
      iconUrl: '../../../../../assets/imgs/l_icon/coordinate-location-roxo.png',
      iconSize: [40, 40], // size of the icon
      iconAnchor: [20, 40], // point of the icon which will correspond to marker's location
      popupAnchor: [-3, -76] // point from which the popup should open relative to the iconAnchor
    });

    this.objCoordinatesIconsToMarker["orangeIcon"] = L.icon({
      iconUrl: '../../../../../assets/imgs/l_icon/coordinate-location-orange.png',
      iconSize: [40, 40], // size of the icon
      iconAnchor: [20, 40], // point of the icon which will correspond to marker's location
      popupAnchor: [-3, -76] // point from which the popup should open relative to the iconAnchor
    });
    //#endregion
  }

  /**
   * TODO: https://tsdoc.org/
   */
  private _clearAllAerodromesMarkerOnMap() {
    // IMPORTANTE: Não precisa criar um método para apagar uma posição especifica, pois toda vez que o 
    // array com o dados a serem plotados forem enviados para o Mapa, eles já vem com o resultado final.                
    if (this.objAerodromesMarkersOnMap) {
      for (var keyObject in this.objAerodromesMarkersOnMap) {
        {
          if (Object.prototype.hasOwnProperty.call(this.objAerodromesMarkersOnMap, keyObject)) {
            this.map_search_location.removeLayer(this.objAerodromesMarkersOnMap[keyObject]);
          }
        }
      }
    }

    this.objAerodromesMarkersOnMap = {}; // TEm que reiniciar o Objeto, agora que todos os MArcadores foram removidos...
  }

  /**
   * TODO: https://tsdoc.org/
   */
  private _clearAllCitiesMarkerOnMap() {
    // IMPORTANTE: Não precisa criar um método para apagar uma posição especifica, pois toda vez que o 
    // array com o dados a serem plotados forem enviados para o Mapa, eles já vem com o resultado final.                
    if (this.objCitiesMarkersOnMap) {
      for (var keyObject in this.objCitiesMarkersOnMap) {
        {
          if (Object.prototype.hasOwnProperty.call(this.objCitiesMarkersOnMap, keyObject)) {
            this.map_search_location.removeLayer(this.objCitiesMarkersOnMap[keyObject]);
          }
        }
      }
    }

    this.objCitiesMarkersOnMap = {}; // TEm que reiniciar o Objeto, agora que todos os MArcadores foram removidos...
  }

  /**
   * TODO: https://tsdoc.org/
   */
  private _clearAllCoordinatesMarkerOnMap() {
    // IMPORTANTE: Não precisa criar um método para apagar uma posição especifica, pois toda vez que o 
    // array com o dados a serem plotados forem enviados para o Mapa, eles já vem com o resultado final.                
    if (this.objCoordinatesMarkerOnMap) {
      for (var keyObject in this.objCoordinatesMarkerOnMap) {
        {
          if (Object.prototype.hasOwnProperty.call(this.objCoordinatesMarkerOnMap, keyObject)) {
            this.map_search_location.removeLayer(this.objCoordinatesMarkerOnMap[keyObject]);
          }
        }
      }
    }
    this.objCoordinatesMarkerOnMap = {}; // TEm que reiniciar o Objeto, agora que todos os MArcadores foram removidos...
  }

  /**
   * TODO: https://tsdoc.org/
   */
  private _centerMapByMarkers() {
    // SEMPRE QUE FOR CHAMADA, DEVE-SE INICIALIZAR O ARRAY DE PONTOS PARA CALCULAR AS BORDAS DA CENTRALIZAÇÃO.
    const arrayLocationMarkers: L.Marker[] = [];

    if (this._aerodromesMarkers) {
      this._aerodromesMarkers.forEach((xElementAerodrome: IAerodromeMarkerOnTheMap) => {
        arrayLocationMarkers.push(L.marker([xElementAerodrome.coordinates_dd![0], xElementAerodrome.coordinates_dd![1]]));
      });
    }

    if (this._citiesMarkers) {
      this._citiesMarkers.forEach((xElementCity: ICityMarkerOnTheMap) => {
        arrayLocationMarkers.push(L.marker([xElementCity.coordinates_dd![0], xElementCity.coordinates_dd![1]]));
      });
    }

    if (this._coordinatesMarkers) {
      this._coordinatesMarkers.forEach((xElementCoordinate: ICoordinateMarkerOnTheMap) => {
        arrayLocationMarkers.push(L.marker([xElementCoordinate.coordinates_dd![0], xElementCoordinate.coordinates_dd![1]]));
      });
    }

    // CALCULO DAS BORDAS DO LIMITE QUE SERá MOSTRADO NO MAPA.
    const bounds = L.latLngBounds(arrayLocationMarkers.map(arrayLocationMarkers => arrayLocationMarkers.getLatLng()));

    if (this.map_search_location) { // VERIFICAR SE O MAPA FOI INICIALIZADO.
      if (arrayLocationMarkers) {
        if (arrayLocationMarkers.length < 1) { // Nenhum ponto, deve-se centralizar na forma inicial
          this.map_search_location.setView(new L.LatLng(INITIAL_LAT, INITIAL_LNG), INITIAL_ZOOM); // Posição da Inicialização...
        } else if (arrayLocationMarkers.length > 1) { // Quando tiver mais de 1 ponto, deve-se usar a opção de mostrar o mapa com base nas bordas...
          this.map_search_location.fitBounds(bounds);
        } else if (arrayLocationMarkers.length == 1) {
          if (this._aerodromesMarkers) {
            if (Object.keys(this._aerodromesMarkers).length == 1) {
              // Quando for uma coordenada só, basta recentrar o mapa
              this.map_search_location.setView(new L.LatLng(this._aerodromesMarkers[0].coordinates_dd![0], this._aerodromesMarkers[0].coordinates_dd![1]), 8);
            }
          }

          if (this._citiesMarkers) {
            if (Object.keys(this._citiesMarkers).length == 1) {
              let zoomToShow;

              if (this._citiesMarkers[0].marker_as_capital == true) {
                zoomToShow = 7;
              } else {
                zoomToShow = 10;
              }

              // Quando for uma coordenada só, basta recentrar o mapa
              this.map_search_location.setView(new L.LatLng(this._citiesMarkers[0].coordinates_dd![0], this._citiesMarkers[0].coordinates_dd![1]), zoomToShow);
            }
          }

          if (this._coordinatesMarkers) {
            if (Object.keys(this._coordinatesMarkers).length == 1) {
              // Quando for uma coordenada só, basta recentrar o mapa
              this.map_search_location.setView(new L.LatLng(this._coordinatesMarkers[0].coordinates_dd![0], this._coordinatesMarkers[0].coordinates_dd![1]), 8);
            }
          }
        }
      }
    }
  }

  /**
   * TODO: https://tsdoc.org/
   */
  private _initVariables(): void {

  }
  //#endregion
}
