<article id="id-login-form" class="cls-page-frame">
    <!-- TOAST-->
    <nashville-page-toast [objToastMessage]="this.objToastMessage"></nashville-page-toast>
    <div class="cls-shared-login-form">
        <div class="cls-shared-login-form-frame">
            <div class="cls-shared-login-form-info">
                <img src="assets/imgs/pexels-camcasey-1686990.jpg" alt="">
            </div>
            <div class="cls-shared-login-form-data">
                <div class="cls-shared-login-form-version">
                    Versão: 1.0.0
                </div>
                <div class="cls-shared-login-form-data-advice">
                    PAINEL DO CLIENTE
                </div>
                <div class="cls-shared-login-form-data-id">
                    <ui-primeng-input-text label="ID Usuário #BOLD#" idField="fid-aircraft-mark"
                        [control]="this.formLogin.controls.id" sizeInput="small" letterCase="upper" maxCharacters="150"
                        isDisabled="false" noLabelWidth="{{this.innerWidth}}">
                    </ui-primeng-input-text>
                </div>
                <div class="cls-shared-login-form-data-password">
                    <div class="cls-shared-login-form-data-id">
                        <ui-primeng-input-text label="Senha #BOLD#" idField="fid-aircraft-mark"
                            [control]="this.formLogin.controls.id" sizeInput="small" letterCase="upper"
                            maxCharacters="150" isDisabled="false" typeInput="password"
                            noLabelWidth="{{this.innerWidth}}">
                        </ui-primeng-input-text>
                    </div>
                </div>
                <div class="cls-shared-login-form-data-extra">
                    <div class="cls-shared-login-form-data-keep-connected">
                        <ui-primeng-checkbox idField="fid-only-most-recent"
                            [control]="this.formLogin.controls.keep_connected" label="Manter Conectado">
                        </ui-primeng-checkbox>
                    </div>
                    <div class="cls-shared-login-form-data-link-forgot">
                        <a routerLink="../request/password">
                            ESQUECEU A SENHA?
                        </a>
                    </div>
                </div>
                <div class="cls-shared-login-form-data-buttons">
                    <p-button label="ENTRAR" icon="fa-solid fa-key" size="small" severity="secondary"
                        class="cls-component-button-pattern-default" (click)="loginUser()" />
                </div>
            </div>
        </div>
    </div>
    <p-dialog [modal]="true" [closable]="false" [(visible)]="this.showDialogError"
        [style]="{ width: '35rem', height: '20rem' }" class="cls-shared-login-form-dialog">
        <ng-template pTemplate="header">
            <div class="cls-shared-login-form-dialog-title">
                <i class="fa-solid fa-triangle-exclamation"></i> ERRO AO FAZER O LOGIN
            </div>
        </ng-template>
        <ng-template pTemplate="content">
            <div class="cls-shared-login-form-dialog-message">
                <div [innerHtml]="this.errorMessage"></div>
            </div>
        </ng-template>
        <ng-template pTemplate="footer">
            <div class="cls-shared-login-form-dialog-button">
                <p-button label="FECHAR" icon="fa-solid fa-right-from-bracket" (click)="closeDialog()" size="small" severity="secondary"
                    class="cls-component-button-pattern-default" />
            </div>
        </ng-template>
    </p-dialog>
</article>