import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ElementRef } from '@angular/core';

import gsap from "gsap";

// get other plugins:
import Flip from "gsap/Flip";

@Component({
  selector: 'nashville-countdown-clock-flip',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './countdown-clock-flip.component.html',
  styleUrl: './countdown-clock-flip.component.scss',
})
export class CountdownClockFlipComponent implements OnInit {
  //#region "|--- VIEW CHILD ---|"
  @ViewChild('countdownClock')
  set elementCountdownClock(xElement: any) {   
    this.$elementCountdown = xElement;

    this._initCountdownClock();
  };
  //#endregion

  //#region "|--- INPUTS ---|"
  @Input() set targetDateTime(xValue: any) {
    if(xValue){
      this.countDownDate = new Date(xValue).getTime(); 
    }    
  };

  //#endregion

  //#region "|--- PROPERTIES ---|"
  countDownDate: number;
  $elementCountdown: any = null;
  $domElement: any;
  //#endregion

  constructor(private _elementRef: ElementRef<HTMLElement>) { }

  ngOnInit(): void {
  }

  private _initCountdownClock() {      
    this.$domElement = {
      days: this.$elementCountdown.nativeElement.querySelectorAll('.bloc-time.days .figure'),
      hours: this.$elementCountdown.nativeElement.querySelectorAll('.bloc-time.hours .figure'),
      minutes: this.$elementCountdown.nativeElement.querySelectorAll('.bloc-time.minutes .figure'),
      seconds: this.$elementCountdown.nativeElement.querySelectorAll('.bloc-time.sec .figure')
    }

    this.startClock();
  }

  /**
   * TODO: https://tsdoc.org/
   */
  startClock() {
    let $day_1 = this.$domElement.days[0];
    let $day_2 = this.$domElement.days[1];
    let $hour_1 = this.$domElement.hours[0];
    let $hour_2 = this.$domElement.hours[1];
    let $min_1 = this.$domElement.minutes[0];
    let $min_2 = this.$domElement.minutes[1];
    let $sec_1 = this.$domElement.seconds[0];
    let $sec_2 = this.$domElement.seconds[1];

    setInterval(() => {
      // Get today's date and time
      const now = new Date().getTime();

      // Find the distance between now and the count down date
      const deltaTime = this.countDownDate - now;

      let daysNormalized = "00";
      let hoursNormalized = "00";
      let minutesNormalized = "00";
      let secondsNormalized = "00";

      if (deltaTime > 0) {
        // Time calculations for days, hours, minutes and seconds
        const days = Math.floor(deltaTime / (1000 * 60 * 60 * 24));
        const hours = Math.floor((deltaTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((deltaTime % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((deltaTime % (1000 * 60)) / 1000);

        daysNormalized = days < 10 ? `0${days}` : `${days}`;
        hoursNormalized = hours < 10 ? `0${hours}` : `${hours}`;
        minutesNormalized = minutes < 10 ? `0${minutes}` : `${minutes}`;
        secondsNormalized = seconds < 10 ? `0${seconds}` : `${seconds}`;
      }
      
            // ANIMATE DAY
            this._checkValueChange(daysNormalized, $day_1, $day_2);
      
            // ANIMATE HOUR
            this._checkValueChange(hoursNormalized, $hour_1, $hour_2);
      
            // ANIMATE MINUTE
            this._checkValueChange(minutesNormalized, $min_1, $min_2);
      
            // ANIMATE SECOND
            this._checkValueChange(secondsNormalized, $sec_1, $sec_2);
    }, 1000);
  }

  /**
   * TODO: https://tsdoc.org/
   */
  private _checkValueChange(value: string, $el_1: any, $el_2: any) {
    let val_1 = value.charAt(0);
    let val_2 = value.charAt(1);
    let fig_1_value = $el_1.querySelector('.top').innerText;
    let fig_2_value = $el_2.querySelector('.top').innerText;

    // Animate only if the figure has changed
    if (fig_1_value !== val_1) this._animateFigure($el_1, val_1);
    if (fig_2_value !== val_2) this._animateFigure($el_2, val_2)
  }

  /**
   * TODO: https://tsdoc.org/
   */
  private _animateFigure($el: any, value: string) {
    const $top = $el.querySelector('.top');
    const $bottom = $el.querySelector('.bottom');
    const $back_top = $el.querySelector('.top-back');
    const $back_bottom = $el.querySelector('.bottom-back');

    // Before we begin, change the back value
    $back_top.querySelector('span').innerText = value;

    // Also change the back bottom value
    $back_bottom.querySelector('span').innerText = value;

    // Then animate
    gsap.to($top, 0.8, {
      rotationX: '-180deg',
      transformPerspective: 300,
      ease: "power1.out",
      onComplete: function () {

        $top.innerText = value;

        $bottom.innerText = value;

        gsap.set($top, { rotationX: 0 });
      }
    });

    gsap.to($back_top, 0.8, {
      rotationX: 0,
      transformPerspective: 300,
      ease: "power1.out",
      clearProps: 'all'
    });
  }
}
