<section id="id-toolbar-tabpanel-aero-registration" class="cls-component-toolbar-control-form">
    <p-toolbar class="cls-component-toolbar-control-form-pattern">
        <div class="p-toolbar-group-start"></div>
        <div class="p-toolbar-group-center"></div>
        <div class="p-toolbar-group-end">
            <p-button label="NOVA AERONAVE" icon="fa-solid fa-plus" [raised]="true" severity="secondary"
                class="cls-component-button-pattern-default" [routerLink]="'/aircraft/form'">
            </p-button>
            <p-button label="ATUALIZAR LISTA" icon="fa-solid fa-rotate" [raised]="true" severity="secondary"
                (click)="callbackPageButton(this.labelActionsButtons.RELOAD)"
                class="cls-component-button-pattern-default">
            </p-button>
        </div>
    </p-toolbar>
</section>