import { FormBuilder, Validators } from '@angular/forms';

/**
 * TODO: https://tsdoc.org/
 */
export function initFormLogin() {
    const objFormBuilder = new FormBuilder();

    return objFormBuilder.group({
        id: [
            "osvaldo.gaspar.jr@gmail.com",
            [
                Validators.required,
            ]
        ],
        password: [
            "123456",
            [
                Validators.required,
            ]
        ],
        keep_connected: [
            true,
            [
                Validators.required,
            ]
        ]
    });
}