//#region "|--- IMPORT MODULES/PACKAGES ---|"
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { settingConfig } from '@nashville-config';

import { IPatternResponseFromAPI } from "../../../interfaces/IPatternCrudResponseFromAPI";
//#endregion

@Injectable({
  providedIn: 'root'
})
export class AircraftDataApisService {
  urlAircraftDataService: any;

  constructor(private http: HttpClient) {
    //#region "|--- PROPERTIES---|"  
    if (settingConfig.API_AIRCRAFT_DATA_PORT == "80") {
      this.urlAircraftDataService = `${settingConfig.API_PROTOCOL}://${settingConfig.API_HOST}/${settingConfig.API_PATH}`;
    } else {
      this.urlAircraftDataService = `${settingConfig.API_PROTOCOL}://${settingConfig.API_HOST}:${settingConfig.API_AIRCRAFT_DATA_PORT}/${settingConfig.API_PATH}`;
    }
    //#endregion
  }

  //#region "|--- AERO REGISTRATION ---|" 
  /**
   * TODO: https://tsdoc.org/
   */
  saveAircraftData(xForm: any): Observable<IPatternResponseFromAPI> {
    return this.http.post<any>(`${this.urlAircraftDataService}/mod_ac/aircraft/data`, xForm);
  }

  updateAircraftData(xAircraftData: any) {
    return this.http.put<any>(`${this.urlAircraftDataService}/mod_ac/aircraft/data/${xAircraftData.aircraft_data_id}`, xAircraftData);
  }

  /**
   * TODO: https://tsdoc.org/
   */
  getAllAircraftData(): Observable<IPatternResponseFromAPI> {
    return this.http.get<any>(`${this.urlAircraftDataService}/mod_ac/aircraft/data`);
  }

  /**
   * TODO: https://tsdoc.org/
   */
  getAircraftDataById(xId: any): Observable<IPatternResponseFromAPI> {
    return this.http.get<any>(`${this.urlAircraftDataService}/mod_ac/aircraft/data/${xId}`);
  }

  /**
   * TODO: https://tsdoc.org/
   */
  getBraRab(xAircraftMark: any): Observable<IPatternResponseFromAPI> {
    return this.http.get<any>(`${this.urlAircraftDataService}/mod_ac/aircraft/bra/rab/${xAircraftMark}`);
  }















  /**
   * TODO: https://tsdoc.org/
   */


  /**
   * TODO: https://tsdoc.org/
   */
  getAircraftImage(imagePath: string): Observable<any> {
    console.log("-------------", `${settingConfig.API_PROTOCOL}://${settingConfig.API_HOST}:${settingConfig.API_AIRCRAFT_DATA_PORT}/${settingConfig.API_PATH}/aircraft/data/image?url=${imagePath}`)
    return this.http.get<any>(`${settingConfig.API_PROTOCOL}://${settingConfig.API_HOST}:${settingConfig.API_AIRCRAFT_DATA_PORT}/${settingConfig.API_PATH}/aircraft/data/image?url=${imagePath}`);
  }
  //#endregion
}
