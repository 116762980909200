<section class="cls-component-data-panel">
    <div #panelContentAerodromes class="cls-component-show-content-pdf">
        <p-panel [toggleable]="true" [collapsed]="this.collapsePanel" class="cls-component-show-content-pdf-panel">
            <ng-template pTemplate="icons">
                <button class="p-panel-header-icon p-link" (click)="createAerodromeCard()">
                    <i class="fa-solid fa-file-pdf"></i>
                </button>
            </ng-template>
            <!-- A COR DE FUNDO DO HEADER É DEFINA VIA PROGRAMAÇÃO -->
            <ng-template pTemplate="header">
                <div class="flex align-items-center gap-2">
                    <span class="cls-component-show-content-pdf-title">AERÓDROMOS</span>
                </div>
            </ng-template>
            <ng-template pTemplate="content">
                <p-tabView>
                    <ng-container
                        *ngFor="let itemAerodrome of this.iptSadFormRequest.controls.aerodromes_list.value; let elementIndex=index">
                        <p-tabPanel>
                            <ng-template pTemplate="header">
                                <div class="flex align-items-center gap-2">
                                    <p-avatar
                                        image="../../../../../../../assets/imgs/l_icon/{{this.settingConfig.LAYOUT.icons[this.getIconName(itemAerodrome.aerodrome_goal)]}}.png"
                                        shape="circle" />
                                    <span class="font-bold white-space-nowrap m-0">
                                        {{itemAerodrome.icao_code}}
                                    </span>
                                </div>
                            </ng-template>
                            <p-accordion [activeIndex]="0">
                                <p-accordionTab>
                                    <ng-template pTemplate="header">
                                        <span class="flex align-items-center gap-2 w-full">
                                            <span class="font-bold white-space-nowrap">
                                                ROTAER - {{itemAerodrome.icao_code}}
                                            </span>
                                        </span>
                                    </ng-template>
                                    <ng-template pTemplate="content">
                                        <div class="cls-component-show-content-pdf-pseudo-table">
                                            <div class="grid">
                                                <div class="col-7 cls-component-show-content-pdf-pseudo-table-grid-col">
                                                    <div class="cls-component-show-content-pdf-pseudo-table-row">
                                                        <div
                                                            class="cls-component-show-content-pdf-pseudo-table-row-title">
                                                            CÓDIGO ICAO:
                                                        </div>
                                                        <div
                                                            class="cls-component-show-content-pdf-pseudo-table-row-value">
                                                            {{this.getAerodromeData(itemAerodrome.aerodrome_goal, "icao_code")}}
                                                        </div>
                                                    </div>
                                                    <div class="cls-component-show-content-pdf-pseudo-table-row">
                                                        <div
                                                            class="cls-component-show-content-pdf-pseudo-table-row-title">
                                                            NOME:
                                                        </div>
                                                        <div
                                                            class="cls-component-show-content-pdf-pseudo-table-row-value">
                                                            {{this.getAerodromeData(itemAerodrome.aerodrome_goal, "name")}}
                                                        </div>
                                                    </div>
                                                    <div class="cls-component-show-content-pdf-pseudo-table-row">
                                                        <div
                                                            class="cls-component-show-content-pdf-pseudo-table-row-title">
                                                            LOCALIZAÇÃO:
                                                        </div>
                                                        <div
                                                            class="cls-component-show-content-pdf-pseudo-table-row-value">
                                                            {{this.getAerodromeData(itemAerodrome.aerodrome_goal, "location")}}
                                                        </div>
                                                    </div>
                                                    <div class="cls-component-show-content-pdf-pseudo-table-row">
                                                        <div
                                                            class="cls-component-show-content-pdf-pseudo-table-row-title">
                                                            OBJETIVO:
                                                        </div>
                                                        <div
                                                            class="cls-component-show-content-pdf-pseudo-table-row-value">
                                                            {{this.getAerodromeData(itemAerodrome.aerodrome_goal, "goal")}}
                                                        </div>
                                                    </div>
                                                    <div class="cls-component-show-content-pdf-pseudo-table-row">
                                                        <div
                                                            class="cls-component-show-content-pdf-pseudo-table-row-title">
                                                            COORDENADAS:
                                                        </div>
                                                        <div
                                                            class="cls-component-show-content-pdf-pseudo-table-row-value">
                                                            <div
                                                                innerHtml="{{this.getAerodromeData(itemAerodrome.aerodrome_goal, 'coordinate')}}">
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="cls-component-show-content-pdf-pseudo-table-row">
                                                        <div
                                                            class="cls-component-show-content-pdf-pseudo-table-row-title">
                                                            ALTITUDE:
                                                        </div>
                                                        <div
                                                            class="cls-component-show-content-pdf-pseudo-table-row-value">
                                                            {{this.getAerodromeData(itemAerodrome.aerodrome_goal, "altitude")}}
                                                        </div>
                                                    </div>
                                                    <div class="cls-component-show-content-pdf-pseudo-table-row">
                                                        <div
                                                            class="cls-component-show-content-pdf-pseudo-table-row-title">
                                                            FIR/JURISD.:
                                                        </div>
                                                        <div
                                                            class="cls-component-show-content-pdf-pseudo-table-row-value">
                                                            {{this.getAerodromeData(itemAerodrome.aerodrome_goal, "fir")}}
                                                            ({{this.getAerodromeData(itemAerodrome.aerodrome_goal, "authority")}})
                                                        </div>
                                                    </div>
                                                    <div class="cls-component-show-content-pdf-pseudo-table-row">
                                                        <div
                                                            class="cls-component-show-content-pdf-pseudo-table-row-title">
                                                            SOL:
                                                        </div>
                                                        <div
                                                            class="cls-component-show-content-pdf-pseudo-table-row-value">
                                                            <div class="cls-component-show-content-pdf-pseudo-table-sun">
                                                                <div  class="cls-component-show-content-pdf-pseudo-table-sun-sunrise">
                                                                    <img src="../../../../../../../assets/imgs/sunrise.png" alt="">
                                                                    <div class="ml-2">
                                                                        <div innerHTML="{{getSunrise(itemAerodrome.aerodrome_basic_data_id) }}"></div>
                                                                                                                                               
                                                                    </div>
                                                                </div>
                                                                <div  class="cls-component-show-content-pdf-pseudo-table-sun-sunset">
                                                                    <img src="../../../../../../../assets/imgs/sunset.png" alt="">
                                                                    <div class="ml-2">
                                                                        <div innerHTML="{{getSunset(itemAerodrome.aerodrome_basic_data_id)}}"></div>                                                                        
                                                                    </div>
                                                                </div>
                                                            </div>                                                            
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-5 cls-component-show-content-pdf-pseudo-table-grid-col">
                                                    <div
                                                        class="cls-component-show-content-pdf-pseudo-table-img-aerodrome">
                                                        <p-image
                                                            src="{{this.serverUrl}}/mod_fp/aux/aerodrome/basic/image?url={{itemAerodrome.icao_code}}-{{itemAerodrome.aerodrome_basic_data_id}}.png"
                                                            alt="Image" [preview]="true" />
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- DETAILS -->
                                            <div class="grid cls-component-show-content-pdf-pseudo-table-grid">
                                                <div
                                                    class="col-12 cls-component-show-content-pdf-pseudo-table-grid-col">
                                                    <div class="cls-component-show-content-pdf-pseudo-table-row-box">
                                                        <div
                                                            class="cls-component-show-content-pdf-pseudo-table-row-box-title">
                                                            DETALHES:
                                                        </div>
                                                        <div
                                                            class="cls-component-show-content-pdf-pseudo-table-row-box-value">
                                                            <div>
                                                                <i class="fa-solid fa-circle-info"></i>
                                                                {{this.getAerodromeData(itemAerodrome.aerodrome_goal, "details_raw")}}
                                                            </div>
                                                            <div
                                                                class="cls-component-show-content-pdf-pseudo-table-row-box-value-item">
                                                                <div
                                                                    class="cls-component-show-content-pdf-pseudo-table-row-title">
                                                                    TIPO:
                                                                </div>
                                                                <div
                                                                    class="cls-component-show-content-pdf-pseudo-table-row-value">
                                                                    {{this.getAerodromeData(itemAerodrome.aerodrome_goal, "aerodrome_type")}}
                                                                </div>
                                                            </div>
                                                            <div
                                                                class="cls-component-show-content-pdf-pseudo-table-row-box-value-item">
                                                                <div
                                                                    class="cls-component-show-content-pdf-pseudo-table-row-title">
                                                                    CATEGORIA:
                                                                </div>
                                                                <div
                                                                    class="cls-component-show-content-pdf-pseudo-table-row-value">
                                                                    {{this.getAerodromeData(itemAerodrome.aerodrome_goal, "category")}}
                                                                </div>
                                                            </div>
                                                            <div
                                                                class="cls-component-show-content-pdf-pseudo-table-row-box-value-item">
                                                                <div
                                                                    class="cls-component-show-content-pdf-pseudo-table-row-title">
                                                                    UTILIZAÇÃO:
                                                                </div>
                                                                <div
                                                                    class="cls-component-show-content-pdf-pseudo-table-row-value">
                                                                    {{this.getAerodromeData(itemAerodrome.aerodrome_goal, "utilization_type")}}
                                                                </div>
                                                            </div>
                                                            <div
                                                                class="cls-component-show-content-pdf-pseudo-table-row-box-value-item">
                                                                <div
                                                                    class="cls-component-show-content-pdf-pseudo-table-row-title">
                                                                    ADMINISTRADOR:
                                                                </div>
                                                                <div
                                                                    class="cls-component-show-content-pdf-pseudo-table-row-value">
                                                                    {{this.getAerodromeData(itemAerodrome.aerodrome_goal, "operator")}}
                                                                </div>
                                                            </div>
                                                            <div
                                                                class="cls-component-show-content-pdf-pseudo-table-row-box-value-item">
                                                                <div
                                                                    class="cls-component-show-content-pdf-pseudo-table-row-title">
                                                                    DIST/DIR CIDADE:
                                                                </div>
                                                                <div
                                                                    class="cls-component-show-content-pdf-pseudo-table-row-value">
                                                                    {{this.getAerodromeData(itemAerodrome.aerodrome_goal, "downtown")}}
                                                                </div>
                                                            </div>
                                                            <div
                                                                class="cls-component-show-content-pdf-pseudo-table-row-box-value-item">
                                                                <div
                                                                    class="cls-component-show-content-pdf-pseudo-table-row-title">
                                                                    FUSO:
                                                                </div>
                                                                <div
                                                                    class="cls-component-show-content-pdf-pseudo-table-row-value">
                                                                    {{this.getAerodromeData(itemAerodrome.aerodrome_goal, "utc")}}
                                                                </div>
                                                            </div>
                                                            <div
                                                                class="cls-component-show-content-pdf-pseudo-table-row-box-value-item">
                                                                <div
                                                                    class="cls-component-show-content-pdf-pseudo-table-row-title">
                                                                    TIPO OPERAÇÃO:
                                                                </div>
                                                                <div
                                                                    class="cls-component-show-content-pdf-pseudo-table-row-value">
                                                                    {{this.getAerodromeData(itemAerodrome.aerodrome_goal, "operation_type")}}
                                                                </div>
                                                            </div>
                                                            <div
                                                                class="cls-component-show-content-pdf-pseudo-table-row-box-value-item">
                                                                <div
                                                                    class="cls-component-show-content-pdf-pseudo-table-row-title">
                                                                    LUZES AERÓDROMO:
                                                                </div>
                                                                <div
                                                                    class="cls-component-show-content-pdf-pseudo-table-row-value">
                                                                    <div
                                                                        innerHTML="{{this.getAerodromeData(itemAerodrome.aerodrome_goal, 'lights')}}">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- RUNWAY -->
                                            <div class="grid cls-component-show-content-pdf-pseudo-table-grid">
                                                <div
                                                    class="col-12 cls-component-show-content-pdf-pseudo-table-grid-col">
                                                    <div class="cls-component-show-content-pdf-pseudo-table-row-box">
                                                        <div
                                                            class="cls-component-show-content-pdf-pseudo-table-row-box-title">
                                                            RUNWAY
                                                            ({{this.getAerodromeData(itemAerodrome.aerodrome_goal, "runway_length")}}):
                                                        </div>
                                                        <div
                                                            class="cls-component-show-content-pdf-pseudo-table-row-box-value">
                                                            <ng-container
                                                                *ngFor="let itemRunway of this.getAerodromeData(itemAerodrome.aerodrome_goal, 'runway'); let elementIndex=index">

                                                                <ng-container *ngIf="elementIndex > 0">
                                                                    <br>
                                                                </ng-container>
                                                                <div>
                                                                    <i class="fa-solid fa-road-bridge"></i>
                                                                    {{itemRunway.runway_raw}}
                                                                </div>
                                                                <div
                                                                    class="cls-component-show-content-pdf-pseudo-table-row-box-value-item">
                                                                    <div
                                                                        class="cls-component-show-content-pdf-pseudo-table-row-title">
                                                                        TIPO:
                                                                    </div>
                                                                    <div
                                                                        class="cls-component-show-content-pdf-pseudo-table-row-value">
                                                                        {{this.getRunwayType(itemRunway.runway_type)}}
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    class="cls-component-show-content-pdf-pseudo-table-row-box-value-item">
                                                                    <div
                                                                        class="cls-component-show-content-pdf-pseudo-table-row-title">
                                                                        CABECEIRAS
                                                                        ({{itemRunway.thresholds_data.count}}):
                                                                    </div>
                                                                    <div
                                                                        class="cls-component-show-content-pdf-pseudo-table-row-value">
                                                                        <ng-container
                                                                            *ngFor="let itemThreshold of itemRunway.thresholds_data.data; let elementIndexThreshold=index">
                                                                            <div>
                                                                                <i class="fa-solid fa-road"></i>
                                                                                {{itemThreshold.threshold}}
                                                                            </div>
                                                                            <ng-container
                                                                                *ngIf="itemThreshold.lights_data.count > 0">
                                                                                <ul>
                                                                                    <ng-container
                                                                                        *ngFor="let itemThresholdLight of itemThreshold.lights_data.data">
                                                                                        <li>{{itemThresholdLight.light_code}}
                                                                                            -
                                                                                            {{itemThresholdLight.description}}
                                                                                        </li>
                                                                                    </ng-container>
                                                                                </ul>
                                                                            </ng-container>
                                                                        </ng-container>
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    class="cls-component-show-content-pdf-pseudo-table-row-box-value-item">
                                                                    <div
                                                                        class="cls-component-show-content-pdf-pseudo-table-row-title">
                                                                        COMPRIMENTO:
                                                                    </div>
                                                                    <div
                                                                        class="cls-component-show-content-pdf-pseudo-table-row-value">
                                                                        {{itemRunway.length.surface_length}} metros
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    class="cls-component-show-content-pdf-pseudo-table-row-box-value-item">
                                                                    <div
                                                                        class="cls-component-show-content-pdf-pseudo-table-row-title">
                                                                        LARGURA:
                                                                    </div>
                                                                    <div
                                                                        class="cls-component-show-content-pdf-pseudo-table-row-value">
                                                                        {{itemRunway.width.surface_width}} metros
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    class="cls-component-show-content-pdf-pseudo-table-row-box-value-item">
                                                                    <div
                                                                        class="cls-component-show-content-pdf-pseudo-table-row-title">
                                                                        PAVIMENTO:
                                                                    </div>
                                                                    <div
                                                                        class="cls-component-show-content-pdf-pseudo-table-row-value">
                                                                        {{itemRunway.surface_pavement.surface_pavement_type}}
                                                                        - {{itemRunway.surface_pavement.legend}}
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    class="cls-component-show-content-pdf-pseudo-table-row-box-value-item">
                                                                    <div
                                                                        class="cls-component-show-content-pdf-pseudo-table-row-title">
                                                                        RESISTÊNCIA:
                                                                    </div>
                                                                    <div
                                                                        class="cls-component-show-content-pdf-pseudo-table-row-value">
                                                                        {{itemRunway.surface_resistance.surface_resistance_type}}
                                                                        <div
                                                                            innerHtml="{{this.getResistanceLegend(itemRunway.surface_resistance)}}">
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    class="cls-component-show-content-pdf-pseudo-table-row-box-value-item">
                                                                    <div
                                                                        class="cls-component-show-content-pdf-pseudo-table-row-title">
                                                                        LUZES DE PISTA:
                                                                    </div>
                                                                    <div
                                                                        class="cls-component-show-content-pdf-pseudo-table-row-value">
                                                                        <div
                                                                            innerHtml="{{this.getRunwayLight(itemRunway.lights_data.data)}}">
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </ng-container>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-template>
                                </p-accordionTab>
                                <p-accordionTab>
                                    <ng-template pTemplate="header">
                                        <span class="flex align-items-center gap-2 w-full">
                                            <span class="font-bold white-space-nowrap">
                                                INFOTEMP - {{itemAerodrome.icao_code}}
                                            </span>
                                            <p-badge value="0" class="ml-auto mr-2" />
                                        </span>
                                    </ng-template>
                                    <ng-template pTemplate="content">
                                    </ng-template>
                                </p-accordionTab>
                                <p-accordionTab>
                                    <ng-template pTemplate="header">
                                        <span class="flex align-items-center gap-2 w-full">
                                            <span class="font-bold white-space-nowrap">
                                                SUPLEMENTO - {{itemAerodrome.icao_code}}
                                            </span>
                                            <p-badge value="0" class="ml-auto mr-2" />
                                        </span>
                                    </ng-template>
                                    <ng-template pTemplate="content">
                                    </ng-template>
                                </p-accordionTab>
                                <p-accordionTab>
                                    <ng-template pTemplate="header">
                                        <span class="flex align-items-center gap-2 w-full">
                                            <span class="font-bold white-space-nowrap">
                                                NOTAM - {{itemAerodrome.icao_code}}
                                            </span>
                                            <p-badge value="0" class="ml-auto mr-2" />
                                        </span>
                                    </ng-template>
                                    <ng-template pTemplate="content">
                                    </ng-template>
                                </p-accordionTab>
                                <p-accordionTab>
                                    <ng-template pTemplate="header">
                                        <span class="flex align-items-center gap-2 w-full">
                                            <span class="font-bold white-space-nowrap">
                                                INFORMAÇÕES ANEXAS - {{itemAerodrome.icao_code}}
                                            </span>
                                            <p-badge
                                                value="{{this.flightPlanContentData.aerodromes.extra_data.data.length}}"
                                                class="ml-auto mr-2" />
                                        </span>
                                    </ng-template>
                                    <ng-template pTemplate="content">
                                        <nashville-extra-content [dataInfo]="this.dataInfoUploadImage"
                                            [formRequest]="this.iptSadFormRequest"
                                            [arrayExtraData]="this.flightPlanContentData.aerodromes.extra_data.data"
                                            (resultUploadImage)="callbackActionExtraAerodromesData($event)">
                                        </nashville-extra-content>
                                    </ng-template>
                                </p-accordionTab>
                            </p-accordion>
                        </p-tabPanel>
                    </ng-container>
                </p-tabView>
            </ng-template>
            <ng-template pTemplate="footer">

            </ng-template>
        </p-panel>
    </div>
</section>