import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MenubarModule } from 'primeng/menubar';
import { MenuItem } from 'primeng/api';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'common-components-top-horizontal-menu-01',
  standalone: true,
  imports: [
    MenubarModule,
    CommonModule
  ],
  templateUrl: './top-horizontal-menu-01.component.html',
  styleUrl: './top-horizontal-menu-01.component.scss',
})
export class TopHorizontalMenu01Component implements OnInit {
  items: MenuItem[] | undefined;

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnInit() {
    this.items = [
      {
        label: 'Home',
        icon: 'fa-solid fa-book',
      },
      {
        label: 'Cadastros',
        icon: 'fa-solid fa-layer-group',
        items: [
          {
            label: 'Aeronaves',
            icon: 'fa-solid fa-plane',
            items: [
              {
                label: 'Nova Aeronave',
                icon: 'fa-solid fa-file-circle-plus',
                routerLink: ["/aircraft/form"]
              },
              {
                label: 'Listar Aeronaves',
                icon: 'fa-solid fa-table-list',
                routerLink: ["/aircraft/list"]
              },
              {
                separator: true
              },
              {
                label: 'Dashboard',
                icon: 'fa-solid fa-gauge',
                routerLink: ["/flight-plan/list"]
              }

            ]
          },
          {
            label: 'Tripulação',
            icon: 'fa-solid fa-user-tie',
            items: [
              {
                label: 'Novo Tripulante',
                icon: 'fa-solid fa-file-circle-plus',
                routerLink: ["/aircraft/form"]
              },
              {
                label: 'Listar Tripulantes',
                icon: 'fa-solid fa-table-list',
                routerLink: ["/aircraft/list"]
              },
              {
                separator: true
              },
              {
                label: 'Dashboard',
                icon: 'fa-solid fa-gauge',
                routerLink: ["/flight-plan/list"]
              }

            ]
          },
          {
            label: 'Passageiros',
            icon: 'fa-solid fa-users',
            items: [
              {
                label: 'Novo Passageiro',
                icon: 'fa-solid fa-file-circle-plus',
                routerLink: ["/aircraft/form"]
              },
              {
                label: 'Listar Passageiros',
                icon: 'fa-solid fa-table-list',
                routerLink: ["/aircraft/list"]
              },
              {
                separator: true
              },
              {
                label: 'Dashboard',
                icon: 'fa-solid fa-gauge',
                routerLink: ["/flight-plan/list"]
              }

            ]
          }
        ]
      },


      ,
      /*{
        label: 'Logbook',
        icon: 'fa-solid fa-book',
        items: [
          {
            label: 'Listar',
            icon: 'pi pi-fw pi-align-left',
            routerLink: ["/logbook/list"]
          },
          {
            label: 'Right',
            icon: 'pi pi-fw pi-align-right'
            ,
            routerLink: ["/logbook/form"]
          }
        ]
      },*/
      {
        label: 'CCO',
        icon: 'fa-solid fa-fan',
        items: [
          {
            label: 'Nova Req. de Voo',
            icon: 'fa-solid fa-file-circle-plus',
            routerLink: ["/flight-request/form"]
          },
          {
            label: 'Listar Req. de Voo',
            icon: 'fa-solid fa-rectangle-list',
            routerLink: ["/flight-request/list"]
          },
          {
            separator: true
          },
          /*  {
             label: 'Check List',
             icon: 'fa-solid fa-list-check',
             routerLink: ["/flight-request/checklist"]
           },
          {
             separator: true
           },         
           {
             label: 'Dashboard',
             icon: 'fa-solid fa-gauge',
             routerLink: ["/flight-plan/list"]
           },
           {
             label: 'Flight Board',
             icon: 'fa-solid fa-chalkboard-user',
             routerLink: ["/flight-plan/list"]
           },
           {
             label: 'Time Painel',
             icon: 'fa-solid fa-business-time',
             routerLink: ["/flight-plan/list"]
           },*/
        ]
      },
      {
        label: 'ADMIN',
        icon: 'fa-solid fa-sliders',
        items: [
          {
            label: 'Novo Usuário',
            icon: 'fa-solid fa-file-circle-plus',
            routerLink: ["/flight-request/form"]
          },
          {
            label: 'Listar Usuários',
            icon: 'fa-solid fa-rectangle-list',
            routerLink: ["/flight-request/list"]
          },
          {
            separator: true
          },
          /*  {
             label: 'Check List',
             icon: 'fa-solid fa-list-check',
             routerLink: ["/flight-request/checklist"]
           },
          {
             separator: true
           },         
           {
             label: 'Dashboard',
             icon: 'fa-solid fa-gauge',
             routerLink: ["/flight-plan/list"]
           },
           {
             label: 'Flight Board',
             icon: 'fa-solid fa-chalkboard-user',
             routerLink: ["/flight-plan/list"]
           },
           {
             label: 'Time Painel',
             icon: 'fa-solid fa-business-time',
             routerLink: ["/flight-plan/list"]
           },*/
        ]
      },
      /*{
        label: 'SUPER',
        icon: 'fa-solid fa-user-tie',
        items: [
          {
            label: 'Nova Conta',
            icon: 'fa-solid fa-file-circle-plus',
            routerLink: ["/flight-request/form"]
          },
          {
            label: 'Listar Contas',
            icon: 'fa-solid fa-rectangle-list',
            routerLink: ["/flight-request/list"]
          },
          {
            separator: true
          },
           {
            label: 'Novo Usuário',
            icon: 'fa-solid fa-file-circle-plus',
            routerLink: ["/flight-request/checklist"]
          },         
          {
            label: 'Listar Usuários',
            icon: 'fa-solid fa-rectangle-list',
            routerLink: ["/flight-plan/list"]
          }                   
        ]
      },*/
      /*{
        label: 'SOCKET IO',
        icon: 'fa-solid fa-file-circle-plus',
        routerLink: ["/socketio"]
      },*/
    ]
  }
}

