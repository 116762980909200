<section id="id-bottom-utc-datetime" class="cls-component-bottom-utc-datetime">
    <div class="cls-component-bottom-utc-datetime-label">
        Data-Hora UTC:
        <span class="cls-component-bottom-utc-datetime-alert" [ngClass]="{'cls-hidden' : this.isServerDateTime === true}"><i class="fa-solid fa-triangle-exclamation"></i></span>
        <span class="cls-component-bottom-utc-datetime-info" [ngClass]="{'cls-hidden' : this.isServerDateTime === false}"><i class="fa-solid fa-circle-info"></i></span>
    </div>
    <div class="cls-component-bottom-utc-datetime-clock">
        <ng-container *ngIf="this.showWatch"> 
            <div class="cls-component-bottom-utc-datetime-watch">
                {{this.dayDateUTC}}/{{this.monthDateUTC}}/{{this.yearDateUTC}} 
            </div>
            <div class="mr-2 ml-2">
                -
            </div>
            <div class="cls-component-bottom-utc-datetime-date">
                {{this.hourDateUTC}}:{{this.minuteDateUTC}}:{{this.secondsDateUTC}}
            </div>
        </ng-container>        
    </div>
</section>