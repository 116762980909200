import { Component, OnInit, Input, HostListener } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TooltipModule } from 'primeng/tooltip';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'ui-primeng-label',
  standalone: true,
  imports: [
    CommonModule,
    TooltipModule
  ],
  templateUrl: './label.component.html',
  styleUrl: './label.component.scss',
})
export class LabelComponent implements OnInit {
  //#region "|--- INPUTS ---|"
  @Input() label!: string;
  @Input() idField!: string;
  @Input() noLabelWidth?: string;
  @Input() toolTip?: string;
  //#endregion

  //#region "|--- PROPERTIES---|"
  innerWidth?: any;

  showLabel = true;
  showLabelBold = false;
  showToolTipIcon = false;
  //#endregion

  ngOnInit(): void {
    this.innerWidth = window.innerWidth;

    this._hasToShowLabelBold();
    this._getShowLabel();
    this._hasToShowToolTipIcon();
  }

  /**
   * (v1) - Executada toda vez que ocorre REDIMENSIONAMENTO da Tela.
   *
   * @param event
   */
  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    this.innerWidth = window.innerWidth;

    this._getShowLabel();
  }

  /**
   * TODO: https://tsdoc.org/
   */
  getTooTipContent(): string {
    if (this.toolTip != undefined && this.toolTip != "") {
      return this.toolTip;
    } else {
      return "";
    }
  }

  /**
   * TODO: https://tsdoc.org/
   */
  getShowLabelBold(): boolean {
    return this.showLabelBold;
  }

  /**
   * TODO: https://tsdoc.org/
   */
  private _getShowLabel(): void {
    if (this.noLabelWidth != undefined && this.noLabelWidth != "") {
      if (this.innerWidth < Number(this.noLabelWidth)) {
        this.showLabel = false;
        this.showToolTipIcon = false;
      } else {
        this.showLabel = true;
        this._hasToShowToolTipIcon();
      }
    }
  }

  /**
   * TODO: https://tsdoc.org/
   */
  private _hasToShowLabelBold(): void {
    if (this.label != undefined) {
      if (this.label.indexOf("#BOLD#") > 0) {
        this.showLabelBold = true;
        this.label = this.label.replace("#BOLD#", "").trim();
      }
    }
  }

  /**
   * TODO: https://tsdoc.org/
   */
  _hasToShowToolTipIcon(): void {
    if (this.toolTip != undefined && this.toolTip != "") {
      this.showToolTipIcon = true;
    }
  }
}
