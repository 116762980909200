//#region "|--- IMPORT MODULES/PACKAGES ---|"
// ***** ANGULAR *****
import { Component, OnInit, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

// ***** NPM *****
import clipboard from 'clipboardy';
import { MessageService } from 'primeng/api';

// ***** NASHVILLE *****
import { PrimengComponentsModule } from '@nashville-primeng';
import { LocalMethodsHandlerClass } from '@nashville-local-methods';
//#endregion

//#region "|--- IMPORT SERVICES ---|"
import { FlightPlanApisService } from '../../../../services/flight-plan-data-apis.service';
//#endregion

//#region "|---  IMPORT INTERFACES ---|"
import { IDropDownBasicOptions } from '../../../../../../interfaces/IDropDownBasicOptions';
//#endregion

@Component({
  selector: 'nashville-quick-access-data',
  standalone: true,
  imports: [
    CommonModule,
    PrimengComponentsModule
  ],
  templateUrl: './quick-access-data.component.html',
  styleUrl: './quick-access-data.component.scss',
})
export class QuickAccessDataComponent implements OnInit {
  //#region "|--- INPUTS ---|"
  @Input() pageInnerWidth!: any;
  @Input() formComponent!: any;
  @Input() arrayChecklist!: any;

  // É feito o set do @Input para que seja capturado qualquer modificação, em tempo real, 
  // do parâmetro que está sendo enviado pelo PARENT e, principalmente, que seja feito o 
  // processamento necessário com base no novo valor.
  @Input() set flightRequestAction(xValue: any) {
    if (xValue) {
      this.refreshFlightIntentionClipboard();
    }
  };
  //#endregion

  //#region "|--- PROPERTIES ---|"  
  optionsChecklist!: IDropDownBasicOptions[];  //OK

  metarTafMessageHtml: string | null;
  metarTafMessageWhatsApp: string;

  flightIntentionMessageHtml: string | null;
  flightIntentionMessageWhatsApp: string;

  executionTimeMetarTaf: string | null;
  executionTimeFlightIntention: string | null;
  //#endregion

  constructor(
    private flightPlanApisService: FlightPlanApisService,
    private messageService: MessageService,
  ) { }

  ngOnInit(): void {
    this._initVariables();
  }

  //#region "|--- PRIVATE METHODS ---|"  
  /**
   * TODO: https://tsdoc.org/
   */
  private _initVariables(): void {
    this.optionsChecklist = this.arrayChecklist;

    const currentDateTime = new Date();

    this.metarTafMessageHtml = null;
    this.executionTimeMetarTaf = null;
    this.flightIntentionMessageHtml = null;
    this.executionTimeFlightIntention = null;
  }
  //#endregion

  async refreshMetarTafToClipboard() {
    const currentDateTime = new Date();
    let retornoMetarTaf: any;

    await this.flightPlanApisService.getMetarTafAisweb(this.formComponent.controls.aerodromes_list.value).subscribe({
      next: async (xResponseServiceOnline: any) => {
        this.executionTimeMetarTaf = `${currentDateTime.getUTCDate().toString().padStart(2, "0")}/${(currentDateTime.getUTCMonth() + 1).toString().padStart(2, "0")}/${currentDateTime.getUTCFullYear()} - ${currentDateTime.getUTCHours().toString().padStart(2, "0")}:${currentDateTime.getUTCMinutes().toString().padStart(2, "0")} (Z)`

        if (xResponseServiceOnline.status_code == 200) {
          this.metarTafMessageHtml = `☁️ *CONSULTA METAR E TAF REALIZADA: ${this.executionTimeMetarTaf} ☁️<br>`;
          this.metarTafMessageWhatsApp = `☁️ *CONSULTA METAR E TAF REALIZADA: ${this.executionTimeMetarTaf} ☁️\n`;

          // O Retorno do Metar TAF está na ordem correta de Apresentação, basta mostrar
          for (let itemMetarTaf in xResponseServiceOnline.data_info) {
            let tmpMetar: string = '';
            let tmpTaf: string = '';

            this.metarTafMessageHtml = `${this.metarTafMessageHtml} <br>  📍 *${itemMetarTaf.toUpperCase()}*`;
            this.metarTafMessageWhatsApp = `${this.metarTafMessageWhatsApp} \n  📍 *${itemMetarTaf.toUpperCase()}*`;

            if (xResponseServiceOnline.data_info[itemMetarTaf]['metar']) {
              tmpMetar = xResponseServiceOnline.data_info[itemMetarTaf]['metar'];

              if (tmpMetar.indexOf('METAR') >= 0) {
                tmpMetar = tmpMetar.replace(`METAR`, `*METAR*`);
              }

              if (tmpMetar.indexOf('SPECI') >= 0) {
                tmpMetar = tmpMetar.replace(`SPECI`, `*SPECI*`);
              }
            }

            if (xResponseServiceOnline.data_info[itemMetarTaf]['taf']) {
              tmpTaf = xResponseServiceOnline.data_info[itemMetarTaf]['taf'];

              if (tmpTaf.indexOf('TAF') >= 0) {
                tmpTaf = tmpTaf.replace(`TAF`, `*TAF*`);
              }
            }

            if (tmpMetar != '') {
              this.metarTafMessageHtml = `${this.metarTafMessageHtml} <br> ${tmpMetar}`;
              this.metarTafMessageWhatsApp = `${this.metarTafMessageWhatsApp} \n ${tmpMetar}`;
            }

            if (tmpTaf != '') {
              this.metarTafMessageHtml = `${this.metarTafMessageHtml} <br> ${tmpTaf} `;
              this.metarTafMessageWhatsApp = `${this.metarTafMessageWhatsApp} \n ${tmpTaf} `;
            }
          }
        }
      },
      complete: () => {

      },
      error: (xError: any) => {

      }
    })
  }

  copyMetarTafToClipboard() {
    clipboard.write(this.metarTafMessageWhatsApp).then((xReturn) => {
      this.messageService.add({
        severity: 'success',
        summary: `Copiar METAR/TAF`,
        detail: `Mensagem copiada com Sucesso.`,
      });
    });
  }

  /**
   * TODO: https://tsdoc.org/
   */
  async refreshFlightIntentionClipboard() {
    const currentDateTime = new Date();

    this.executionTimeFlightIntention = `${currentDateTime.getUTCDate().toString().padStart(2, "0")}/${(currentDateTime.getUTCMonth() + 1).toString().padStart(2, "0")}/${currentDateTime.getUTCFullYear()} - ${currentDateTime.getUTCHours().toString().padStart(2, "0")}:${currentDateTime.getUTCMinutes().toString().padStart(2, "0")} (Z)`

    const tempRequestData = this.formComponent.getRawValue();

    const tmpDate = new Date(tempRequestData.date_time_eobt.date_time_zulu);
    const tmpDateEobt = `${tmpDate.getUTCDate().toString().padStart(2, '0')}/${(tmpDate.getUTCMonth() + 1).toString().padStart(2, '0')}/${tmpDate.getUTCFullYear()}`;

    const tmpDepartureIcao = tempRequestData.aerodromes.departure.data.icao_code;
    const tmpDepartureName = tempRequestData.aerodromes.departure.data.name;
    const tmpDestinationIcao = tempRequestData.aerodromes.destination.data.icao_code;
    const tmpDestinationName = tempRequestData.aerodromes.destination.data.name;
    const tmpAircraftMark = tempRequestData.aircraft_mark;
    const tmpPilotId = tempRequestData.crew.first_in_command.pilot_id;
    const tmpTimeEobt = tempRequestData.date_time_eobt.time_zulu;
    //const tmpFlightRule = this.formComponent.controls.flight_rule.value;
    //const tmpFlightLevel = this.formComponent.controls.flight_level.value;
    
    this.flightIntentionMessageHtml = `✅ *Solicitação de Voo recebida!* <br>`;
    this.flightIntentionMessageWhatsApp = `✅ *Solicitação de Voo recebida!* \n`

    this.flightIntentionMessageHtml = `${this.flightIntentionMessageHtml} *${LocalMethodsHandlerClass.aircraftMarkPutMask(tmpAircraftMark)}* CANAC: ${tmpPilotId} <br><br>`;
    this.flightIntentionMessageWhatsApp = `${this.flightIntentionMessageWhatsApp} *${LocalMethodsHandlerClass.aircraftMarkPutMask(tmpAircraftMark)}* CANAC: ${tmpPilotId} \n\n`

    this.flightIntentionMessageHtml = `${this.flightIntentionMessageHtml} *${tmpDateEobt} ${tmpTimeEobt} (Z) <br>`;
    this.flightIntentionMessageWhatsApp = `${this.flightIntentionMessageWhatsApp} *${tmpDateEobt} ${tmpTimeEobt} (Z) \n`
        
    this.flightIntentionMessageHtml = `${this.flightIntentionMessageHtml} *${tmpDepartureIcao} (${tmpDepartureName}) <br>`;
    this.flightIntentionMessageWhatsApp = `${this.flightIntentionMessageWhatsApp} *${tmpDepartureIcao} (${tmpDepartureName}) \n`
    
    this.flightIntentionMessageHtml = `${this.flightIntentionMessageHtml} *${tmpDestinationIcao} (${tmpDestinationName}) <br>`;
    this.flightIntentionMessageWhatsApp = `${this.flightIntentionMessageWhatsApp} *${tmpDestinationIcao} (${tmpDestinationName}) \n`
    /* 


    intentionMessage = `${intentionMessage} \n *${tmpDeparture} - ${tmpDestination}* ${tmpFlightRule} ${tmpFlightLevel}`;

    //#region "|--- REFAZER POIS ELE É CHAMADO EM 2 LUGARES DA MESMA MANERIA ---|"
    const dateTimeSearch = new Date();
    let retornoMetarTaf;

    Promise.all([(retornoMetarTaf = await this._getMetarTafToMessage())]);

    intentionMessage = ` ${intentionMessage} \n\n ☁️ *CONSULTA METAR E TAF REALIZADA: ${dateTimeSearch.getUTCDate().toString().padStart(2, '0')}/${(dateTimeSearch.getUTCMonth() + 1).toString().padStart(2, '0')}/${dateTimeSearch.getUTCFullYear()} ${dateTimeSearch.getUTCHours().toString().padStart(2, '0')}:${dateTimeSearch.getUTCMinutes().toString().padStart(2, '0')}z*: ☁️\n`;

    if (retornoMetarTaf) {
      // O Retorno do Metar TAF está na ordem correta de Apresentação, basta mostrar
      for (let itemMetarTaf in retornoMetarTaf) {
        let tmpMetar: string = '';
        let tmpTaf: string = '';

        intentionMessage = `${intentionMessage} \n  📍 *${itemMetarTaf.toUpperCase()}*`;

        if (retornoMetarTaf[itemMetarTaf]['metar']) {
          tmpMetar = retornoMetarTaf[itemMetarTaf]['metar'];

          if (tmpMetar.indexOf('METAR') >= 0) {
            tmpMetar = tmpMetar.replace(`METAR`, `*METAR*`);
          }

          if (tmpMetar.indexOf('SPECI') >= 0) {
            tmpMetar = tmpMetar.replace(`SPECI`, `*SPECI*`);
          }
        }

        if (retornoMetarTaf[itemMetarTaf]['taf']) {
          tmpTaf = retornoMetarTaf[itemMetarTaf]['taf'];

          if (tmpTaf.indexOf('TAF') >= 0) {
            tmpTaf = tmpTaf.replace(`TAF`, `*TAF*`);
          }
        }

        if (tmpMetar != '') {
          intentionMessage = `${intentionMessage} \n ${tmpMetar}`;
        }

        if (tmpTaf != '') {
          intentionMessage = `${intentionMessage} \n ${tmpTaf} `;
        }
      }
    }
    //#endregion

    clipboard.write(intentionMessage).then((xReturn) => {
      this.messageService.add({
        severity: 'success',
        summary: `Copiar Mensagem de Intenção de Voo`,
        detail: `Mensagem copiado com Sucesso.`,
      });

      this.isIntentionMessageCopied = true;

      setTimeout(() => {
        this.isIntentionMessageCopied = false;
      }, 5000);
    });*/
  }

  copyFlightIntentionToClipboard() {
    clipboard.write(this.flightIntentionMessageWhatsApp).then((xReturn) => {
      this.messageService.add({
        severity: 'success',
        summary: `Copiar Intenção de Voo`,
        detail: `Mensagem copiada com Sucesso.`,
      });
    });
  }
}
