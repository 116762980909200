<section class="cls-component-tab-panel">
    <div class="cls-form">
        <div class="grid">
            <div class="col-12">
                <div class="grid">
                    <div class="col-10">
                        <p-panel header="Informações da Aeronave" class="cls-component-panel-formgrid">
                            <div class="cls-component-form">
                                <!-- FORM DATA -->
                                <div class="formgrid grid">
                                    <!-- TYPE AIRCRAFT -->
                                    <div class="field col-3">
                                        <ui-primeng-dropdown label="Tipo Aeronave #BOLD#" idField="fid-type-aircraft"
                                            [control]="this.formComponent.controls.type_aircraft"
                                            [itemsArray]="this.optionsTypeAircraft" withFilter="false" placeHolder="..."
                                            sizeInput="small" noLabelWidth="{{this.pageInnerWidth}}">
                                        </ui-primeng-dropdown>
                                    </div>
                                    <!-- MARK -->
                                    <div class=" field col-3">
                                        <ui-primeng-input-text label="Registro Aeronave #BOLD#" idField="fid-mark"
                                            [control]="this.formComponent.controls.mark" sizeInput="small"
                                            letterCase="upper" maxCharacters="7" noLabelWidth="{{this.pageInnerWidth}}">
                                        </ui-primeng-input-text>
                                    </div>
                                    <!-- ICAO CODE -->
                                    <div class="field col-3">
                                        <ui-primeng-input-text label="ICAO Code #BOLD#" idField="fid-icao-code"
                                            [control]="this.formComponent.controls.registration_data.controls.icao_code"
                                            sizeInput="small" letterCase="upper" maxCharacters="15"
                                            noLabelWidth="{{this.pageInnerWidth}}">
                                        </ui-primeng-input-text>
                                    </div>
                                    <!-- YEAR MANUFACTURED -->
                                    <div class="field col-3">
                                        <ui-primeng-input-text label="Ano Fabricação #BOLD#"
                                            idField="fid-year-manufactured"
                                            [control]="this.formComponent.controls.registration_data.controls.year_manufactured"
                                            sizeInput="small" maxCharacters="4" noLabelWidth="{{this.pageInnerWidth}}">
                                        </ui-primeng-input-text>
                                    </div>
                                </div>
                                <div class=" formgrid grid">
                                    <!-- MANUFACTURER -->
                                    <div class="field col-3">
                                        <ui-primeng-input-text label="Fabricante #BOLD#" idField="fid-manufacturer"
                                            [control]="this.formComponent.controls.registration_data.controls.manufacturer"
                                            sizeInput="small" letterCase="upper" noLabelWidth="{{this.pageInnerWidth}}">
                                        </ui-primeng-input-text>
                                    </div>
                                    <!-- MODEL -->
                                    <div class="field col-3">
                                        <ui-primeng-input-text label="Modelo #BOLD#" idField="fid-model"
                                            [control]="this.formComponent.controls.registration_data.controls.model"
                                            sizeInput="small" letterCase="upper" noLabelWidth="{{this.pageInnerWidth}}">
                                        </ui-primeng-input-text>
                                    </div>
                                    <!-- SERIAL NUMBER -->
                                    <div class="field col-3">
                                        <ui-primeng-input-text label="Número Serial #BOLD#" idField="fid-serial-number"
                                            [control]="this.formComponent.controls.registration_data.controls.serial_number"
                                            sizeInput="small" letterCase="upper" noLabelWidth="{{this.pageInnerWidth}}">
                                        </ui-primeng-input-text>
                                    </div>
                                    <!-- FLIGHT RULES -->
                                    <div class="field col-3">
                                        <ui-primeng-dropdown label="R. de Voo #BOLD#" idField="fid-flight-rules"
                                            [control]="this.formComponent.controls.registration_data.controls.flight_rules"
                                            [itemsArray]="this.optionsFlightRules" withFilter="false" sizeInput="small"
                                            placeHolder="..." noLabelWidth="{{this.pageInnerWidth}}">
                                        </ui-primeng-dropdown>
                                    </div>
                                </div>
                                <div class="formgrid grid">
                                    <!-- FIN NUMBER -->
                                    <div class="field col-3">
                                        <ui-primeng-input-text label="Número FIN" idField="fid-fin-number"
                                            [control]="this.formComponent.controls.registration_data.controls.fin_number"
                                            sizeInput="small" letterCase="upper" noLabelWidth="{{this.pageInnerWidth}}">
                                        </ui-primeng-input-text>
                                    </div>
                                    <!-- AIRCRAFT NAME -->
                                    <div class="field col-3">
                                        <ui-primeng-input-text label="Nome Aeronave" idField="fid-aircraft-name"
                                            [control]="this.formComponent.controls.registration_data.controls.aircraft_name"
                                            sizeInput="small" letterCase="upper" noLabelWidth="{{this.pageInnerWidth}}">
                                        </ui-primeng-input-text>
                                    </div>
                                    <!-- OBSERVATION -->
                                    <div class="field col-6">
                                        <ui-primeng-input-text label="Observação" idField="fid-obs-registration-data"
                                            [control]="this.formComponent.controls.registration_data.controls.obs_registration_data"
                                            sizeInput="small" letterCase="upper" noLabelWidth="{{this.pageInnerWidth}}">
                                        </ui-primeng-input-text>
                                    </div>
                                </div>
                            </div>
                        </p-panel>
                        <div class="grid">
                            <div class="col-6 pl-0">
                                <!-- OWNER -->
                                <p-panel header="Proprietários" class="cls-component-panel-formgrid">
                                    <p-table [value]="this.formComponent.controls.owner_operator_data.value"
                                        [tableStyle]="{ 'max-height': '20rem' }" [paginator]="true" [rows]="5"
                                        [showCurrentPageReport]="true" [scrollable]="true" rowExpandMode="multiple"
                                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                                        responsiveLayout="stack" class="cls-component-table-list"
                                        styleClass="p-datatable-striped">
                                        <ng-template pTemplate="caption">
                                            <div
                                                class="flex align-items-center justify-content-between cls-component-table-list-caption">
                                                <span><i class="fa-solid fa-hands-holding-circle"></i> LISTA DE
                                                    PROPRIETÁRIOS</span>
                                                <p-button icon="fa-solid fa-plus"
                                                    (click)="this.callbackShowDialog(this.labelActionsButtons.SHOW_OWNER)"
                                                    size="small" severity="secondary"
                                                    class="cls-component-table-list-caption-button"></p-button>
                                            </div>
                                        </ng-template>
                                        <ng-template pTemplate="header">
                                            <tr>
                                                <th style="width:24%; text-align: center">CPF/CNPJ</th>
                                                <th style="width:58%">Name</th>
                                                <th style="width:18%; text-align: center"></th>
                                            </tr>
                                        </ng-template>
                                        <ng-template pTemplate="body" let-owner>
                                            <tr>
                                                <td style="text-align: center">00.000.000/0001-91</td>
                                                <td>{{ owner.name }}</td>
                                                <td style="text-align: center" class="cls-component-table-list-item-actions">
                                                    <p-button icon="fa-regular fa-handshake" [rounded]="true" [text]="true"
                                                        severity="help" [outlined]="true"></p-button>
                                                    <p-button icon="fa-solid fa-pen" [rounded]="true" [text]="true"
                                                        severity="info"></p-button>
                                                    <p-button icon="fa-solid fa-xmark" [rounded]="true" [text]="true"
                                                        severity="danger"></p-button>
                                                </td>
                                            </tr>
                                        </ng-template>
                                    </p-table>
                                </p-panel>
                            </div>
                            <div class="col-6 pr-0">
                                <p-panel header="Operadores" class="cls-component-panel-formgrid">
                                    <p-table [value]="this.formComponent.controls.owner_operator_data.value"
                                        [tableStyle]="{ 'max-height': '20rem' }" [paginator]="true" [rows]="5"
                                        [showCurrentPageReport]="true" [scrollable]="true" rowExpandMode="multiple"
                                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                                        responsiveLayout="stack" class="cls-component-table-list"
                                        styleClass="p-datatable-striped">
                                        <ng-template pTemplate="caption">
                                            <div
                                                class="flex align-items-center justify-content-between cls-component-table-list-caption">
                                                <span><i class="fa-regular fa-handshake"></i> LISTA DE
                                                    OPERADORES</span>
                                                <p-button icon="fa-solid fa-plus"
                                                    (click)="this.callbackShowDialog(this.labelActionsButtons.SHOW_OPERATOR)"
                                                    size="small" severity="secondary"
                                                    class="cls-component-table-list-caption-button">
                                                </p-button>
                                            </div>
                                        </ng-template>
                                        <ng-template pTemplate="header">
                                            <tr>
                                                <th>Code</th>
                                                <th>Name</th>
                                                <th>Category</th>
                                                <th>Quantity</th>
                                            </tr>
                                        </ng-template>
                                        <ng-template pTemplate="body" let-product>
                                            <tr>
                                                <td>{{ product.code }}</td>
                                                <td>{{ product.name }}</td>
                                                <td>{{ product.category }}</td>
                                                <td>{{ product.quantity }}</td>
                                            </tr>
                                        </ng-template>
                                    </p-table>
                                </p-panel>
                            </div>
                        </div>
                    </div>
                    <div class="col-2">
                        <p-panel header="Image Aeronave" class="cls-component-panel-formgrid">
                            <div class="cls-component-aircraft-avatar">
                                <div class="cls-component-aircraft-avatar-image">
                                    <ng-container *ngIf="this.formComponent.controls.avatar_image.value === null">
                                        <nashville-upload-file 
                                            (onSuccess)="onSuccessAircraftImageUpload($event)"
                                            (onError)="onErrorAircraftImageUpload($event)"
                                            (onImageError)="onErrorAircraftImage($event)" 
                                            [isMultiFiles]="false"
                                            fileName="image-upload" urlUpload="{{this.serverUrl}}/aircraft/data/image">
                                        </nashville-upload-file>
                                    </ng-container>
                                    <ng-container *ngIf="this.formComponent.controls.avatar_image.value !== null">
                                        <div class="field">
                                            <ui-primeng-label label="Imagem Aeronave"></ui-primeng-label>
                                            <div class="cls-component-aircraft-avatar-image-frame">
                                                <img src="{{this.serverUrl}}/aircraft/data/image?url={{this.formComponent.controls.avatar_image.value}}"
                                                    alt="">
                                            </div>
                                        </div>
                                    </ng-container>
                                </div>
                                <div class="cls-component-aircraft-avatar-color">
                                    <ui-primeng-color-picker label="Cor no Sistema" idField="fid-avatar-color"
                                        [control]="this.formComponent.controls.avatar_color">
                                    </ui-primeng-color-picker>
                                </div>
                            </div>
                        </p-panel>
                        <p-panel header="Mensagens Aeronave" class="cls-component-panel-formgrid">
                        </p-panel>
                    </div>
                </div>
            </div>           
        </div>
    </div>
</section>