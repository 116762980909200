//#region "|--- IMPORT MODULES/PACKAGES ---|"
// ***** ANGULAR *****
import { FormBuilder, Validators } from '@angular/forms';
//#endregion

/**
 * @status OK
 * @type FORM
 * @version 1.0.0 (GASPAR - 11/06/2024)
 * - Versão inicial.
 * 
 * @description 
 * - Instância o REACTIVE FORM ANGULAR que trata a Requisição de Voo.
 * 
 * @returns 
 * - Retorna um objeto contendo a estrutura do formulário de Requisição de Voo.
 */
export function initFormRequest() {
    const objFormBuilder = new FormBuilder();

    return objFormBuilder.group({
        flight_request_id: [
            null
        ],
        fk_flight_plan_data_id: [
            null
        ],
        flight_plan_official_confirmation: [
            null
        ],
        flight_request_code: objFormBuilder.group({
            normalized_text: [
                null
            ],
            date_string_eobt: [
                null
            ],
            sequential: [
                null
            ]
        }),
        aircraft_mark: [
            null,
            [
                Validators.required,
                Validators.minLength(3),
                Validators.maxLength(7),
                Validators.pattern('^[a-zA-Z0-9]+$')
            ]
        ],
        aerodromes_list: [
            null
        ],
        aerodromes: objFormBuilder.group({
            departure: objFormBuilder.group({
                data: objFormBuilder.group({
                    aerodrome_basic_data_id: [null],
                    fk_aerodrome_complete_data_id: [null],
                    iata_code: [null],
                    icao_code: [null],
                    name: [null],
                    aerodrome_type: [null],
                    coordinate_label: [null],
                    coordinate_dd: [null],
                    coordinate_gms: [null],
                    coordinate_plan: [null],
                    coordinate_geo: {
                        location: {
                            type: "Point",
                            coordinates: [null]
                        }
                    },
                    place: {
                        country_code: [null],
                        state: [null],
                        city: [null]
                    }
                }),
                font: [null],
                timestamp: [null]
            }),
            destination: objFormBuilder.group({
                data: objFormBuilder.group({
                    aerodrome_basic_data_id: [null],
                    fk_aerodrome_complete_data_id: [null],
                    iata_code: [null],
                    icao_code: [null],
                    name: [null],
                    aerodrome_type: [null],
                    coordinate_label: [null],
                    coordinate_dd: [null],
                    coordinate_gms: [null],
                    coordinate_plan: [null],
                    coordinate_geo: {
                        location: {
                            type: "Point",
                            coordinates: [null]
                        }
                    },
                    place: {
                        country_code: [null],
                        state: [null],
                        city: [null]
                    }
                }),
                font: [null],
                timestamp: [null]
            }),
            alt_1: objFormBuilder.group({
                data: objFormBuilder.group({
                    aerodrome_basic_data_id: [null],
                    fk_aerodrome_complete_data_id: [null],
                    iata_code: [null],
                    icao_code: [null],
                    name: [null],
                    aerodrome_type: [null],
                    coordinate_label: [null],
                    coordinate_dd: [null],
                    coordinate_gms: [null],
                    coordinate_plan: [null],
                    coordinate_geo: {
                        location: {
                            type: "Point",
                            coordinates: [null]
                        }
                    },
                    place: {
                        country_code: [null],
                        state: [null],
                        city: [null]
                    }
                }),
                font: [null],
                timestamp: [null]
            }),
            alt_2: objFormBuilder.group({
                data: objFormBuilder.group({
                    aerodrome_basic_data_id: [null],
                    fk_aerodrome_complete_data_id: [null],
                    iata_code: [null],
                    icao_code: [null],
                    name: [null],
                    aerodrome_type: [null],
                    coordinate_label: [null],
                    coordinate_dd: [null],
                    coordinate_gms: [null],
                    coordinate_plan: [null],
                    coordinate_geo: {
                        location: {
                            type: "Point",
                            coordinates: [null]
                        }
                    },
                    place: {
                        country_code: [null],
                        state: [null],
                        city: [null]
                    }
                }),
                timestamp: [null],
                font: [null]
            }),
            alt_extras: objFormBuilder.array([])
        }),
        map: objFormBuilder.group({
            marker: [[]],
            draw: [[]]
        }),
        crew: objFormBuilder.group({
            first_in_command: objFormBuilder.group({
                normalized_text: [
                    null,
                    [
                        Validators.required,
                        Validators.maxLength(150),
                        Validators.pattern('^[0-9]+ \- [a-zA-Z0-9 ]*$')
                    ]
                ],
                pilot_id: [
                    null,
                    [
                        Validators.maxLength(10),
                    ]
                ],
                full_name: [
                    null,
                    [
                        Validators.maxLength(100),
                    ]
                ]
            }),
            second_in_command: objFormBuilder.group({
                normalized_text: [
                    null,
                    [
                        Validators.minLength(10),
                        Validators.maxLength(110),
                        Validators.pattern('^[0-9]+ \- [a-zA-Z0-9 ]*$')
                    ]
                ],
                pilot_id: [
                    null,
                    [
                        Validators.maxLength(10),
                    ]
                ],
                full_name: [
                    null,
                    [
                        Validators.maxLength(100),
                    ]
                ]
            })
        }),
        operator: [
            null,
            [
                Validators.required,
                Validators.minLength(2),
                Validators.maxLength(150)
            ]
        ],
        date_time_eobt: objFormBuilder.group({
            date_zulu: [
                null,
                [
                    Validators.required,
                    Validators.minLength(10),
                    Validators.pattern('^(3[01]|[12][0-9]|0[1-9])/(1[0-2]|0[1-9])/[0-9]{4}$')
                ]
            ],
            time_zulu: [
                null,
                [
                    Validators.required,
                    Validators.pattern('^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$')
                ]
            ],
            date_time_zulu: [
                null,
                [
                    Validators.required
                ]
            ]
        }),
        date_time_landing: objFormBuilder.group({
            date_zulu: [
                null,
                [
                    Validators.required,
                    Validators.minLength(10),
                    Validators.pattern('^(3[01]|[12][0-9]|0[1-9])/(1[0-2]|0[1-9])/[0-9]{4}$')
                ]
            ],
            time_zulu: [
                null,
                [
                    Validators.required,
                    Validators.pattern('^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$')
                ]
            ],
            date_time_zulu: [
                null,
                [
                    Validators.required
                ]
            ]
        }),
        flight_level: [
            null,
            [
                Validators.required,
                Validators.minLength(3),
                Validators.maxLength(4),
                Validators.pattern('^[AaFf]\\d{3}|VFR|vfr$')
            ]
        ],
        cruise_speed: [
            null,
            [
                Validators.required,
                Validators.minLength(4),
                Validators.maxLength(5),
                Validators.pattern('^[KkNn]\\d{4}|[Mm]\\d{3}$')
            ]
        ],
        eet: [
            null,
            [
                Validators.required,
                Validators.pattern('^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$')
            ]
        ],
        flight_rule: [
            null,
            [
                Validators.required,
                Validators.minLength(1),
                Validators.pattern('^[iIvVyYzZ]$')
            ]
        ],
        route: objFormBuilder.group({
            normalized_text: [
                "DCT"
            ],
            parts: objFormBuilder.array(["DCT"])
        }),
        documents_files: objFormBuilder.array([]),
        filters: objFormBuilder.group({
            obstacles: objFormBuilder.group({
                aerodromes_icao: [null],
                coordinates: [null],
                sort_result_obstacles: ["radius"],
                obstacle_distance_nm: [3],
                obstacle_min_height_m: [0],
                obstacle_min_altitude_ft: [0],
                itens: objFormBuilder.group({
                    all: [true],
                    ag_equip: [true],
                    antenna: [true],
                    bridge: [true],
                    building: [true],
                    control_tower: [true],
                    crane: [true],
                    dome: [true],
                    electrical_system: [true],
                    elevator: [true],
                    fence: [true],
                    fuel_system: [true],
                    gate: [true],
                    grain_elevator: [true],
                    industrial_system: [true],
                    lighthouse: [true],
                    monument: [true],
                    natural_gas_system: [true],
                    natural_highpoint: [true],
                    navaid: [true],
                    other: [true],
                    pole: [true],
                    power_plant: [true],
                    rig: [true],
                    sign: [true],
                    spire: [true],
                    stack: [true],
                    tank: [true],
                    tethered_balloon: [true],
                    tower: [true],
                    tramway: [true],
                    transmission_line: [true],
                    tree: [true],
                    urban: [true],
                    vegetation: [true],
                    wall: [true],
                    wastewater_system: [true],
                    water_tower: [true],
                    windmill: [true],
                    windmill_farm: [true]
                }),
                notam: objFormBuilder.group({

                }),
                meteorology: objFormBuilder.group({
                    metar: [true],
                    taf: [true],
                    sigmet: [true],
                    wind: [true]
                })
            }),
            meteorology: objFormBuilder.group({
                aerodromes_icao: [null],
                fir_icao: [null],
                cities: [null],
                coordinates: [null],
                initial_date_zulu: [null],
                initial_time_zulu: [null],
                end_date_zulu: [null],
                end_time_zulu: [null],
                flight_level: [50],
                meteoro_messages: objFormBuilder.group({
                    metar: [true],
                    taf: [true],
                    sigmet: [true]
                }),
                images: objFormBuilder.group({
                    sigwx: [true],
                    wind: [true],
                    satellite_ir: [false],
                    satellite_realcada: [true],
                    satellite_vis: [false]
                })
            }),
            notam: [null],
        }),
        current_status: objFormBuilder.group({
            request_flight: [null],
            document: [null],
            official: [null]
        }),
        request_historic: [null]
    });
}