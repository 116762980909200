<article id="id-flight-plan-request-form" class="cls-page-frame">
    <!-- TOAST-->
    <nashville-page-toast [objToastMessage]="this.objToastMessage"></nashville-page-toast>
    <!-- TOAST -->
    <p-toast key="toast2" [baseZIndex]="1000" position="bottom-right" class="cls-ui-primeng-toast">
    </p-toast>
    <!-- TITLE -->
    <nashville-page-title pageTitle="{{this.currentPageTitle}}"></nashville-page-title>
    <div class="cls-page-frame-content">
        <p-tabView class="cls-component-page-tabview">
            <!-- PANEL REQUISIÇÃO -->
            <p-tabPanel>
                <ng-template pTemplate="header">
                    <i class="fa-solid fa-paper-plane"></i>
                    <span class="cls-component-page-tabview-tabpainel-legend">Requisição</span>
                </ng-template>
                <ng-template pTemplate="content">
                    <div class="cls-component-page-tabview-content-frame">
                        <!-- #BEGIN# - PAGE TOOLBAR  -->
                        <nashville-toolbar-form-flight-request [formComponent]="this.formRequest"
                            [pageInnerWidth]="this.innerWidth"
                            (toolbarButtonAction)="callbackToolbarRequestButtons($event)">
                        </nashville-toolbar-form-flight-request>
                        <!-- $END$ - PAGE TOOLBAR  -->
                        <div class="grid">
                            <div class="lg:col-8">
                                <!-- CHECKLIST -->
                                <nashville-process-monitoring-data [arrayChecklist]="this.optionsCheckList">
                                </nashville-process-monitoring-data>
                                <!-- DADOS AERONAVE -->
                                <nashville-panel-aircraft-data [arrayAircraft]="this.optionsAircraft"
                                    [arrayPilots]="this.optionsPilots" [arrayOperators]="this.optionsOperators"
                                    [formComponent]="this.formRequest" [pageInnerWidth]="this.innerWidth">
                                </nashville-panel-aircraft-data>
                                <!-- DADOS VOO -->
                                <nashville-flight-data [formComponent]="this.formRequest"
                                    (outputFormFindAerodromeByCoord)="returnCallbackSearchAerodromeByCoord($event)"
                                    (outputFormFindAerodromeByIcao)="returnCallbackSearchAerodromeByIcao($event)"
                                    (outputFormRoute)="returnCallBackRouteButtons($event)"
                                    (outputFormTempAerodrome)="returnCallBackFormTempAerodrome($event)"
                                    (handlingAerodromeOnMap)="returnCallBackAerodromeOnMap($event)">
                                </nashville-flight-data>
                                <!-- DADOS TOP RISK -->
                                <nashville-panel-top-risk-data [formComponent]="this.formRequest"
                                    [formTopRisk]="this.formTopRisk">
                                </nashville-panel-top-risk-data>
                                <!-- DADOS METEOROLOGIA -->
                                <nashville-panel-meteorology-data [formComponent]="this.formRequest">
                                </nashville-panel-meteorology-data>
                                <!-- DADOS VOO -->
                                <nashville-panel-notam-data [formComponent]="this.formRequest">
                                </nashville-panel-notam-data>
                            </div>
                            <div class="lg:col-4">
                                <p-panel header="Mapa de Navegação" class="cls-component-panel-formgrid">
                                    <p-toolbar class="cls-component-toolbar-control-form-pattern">
                                        <div class="p-toolbar-group-start"></div>
                                        <div class="p-toolbar-group-center"></div>
                                        <span class="p-input-icon-left">
                                            <p-button icon="fa-solid fa-rotate-right" severity="secondary" class="mr-2"
                                                class="cls-component-button-pattern-default" (click)="refreshNavigationMap()" />
                                            <p-button icon="fa-solid fa-expand" severity="secondary" class="mr-2"
                                                class="cls-component-button-pattern-default" (click)="expandNavigationMap()" />
                                            <p-button icon="fa-solid fa-bullseye" severity="secondary"
                                                class="cls-component-button-pattern-default" (click)="centerNavigationMap()" />
                                        </span>
                                    </p-toolbar>
                                    <nashville-navigation-map [objectToDraw]="this.objectToDrawOnMaps">
                                    </nashville-navigation-map>
                                </p-panel>
                                <div class="mt-2"></div>
                                <nashville-quick-access-data [formComponent]="this.formRequest"
                                    [pageInnerWidth]="this.innerWidth" [flightRequestAction]="this.currentAction">
                                </nashville-quick-access-data>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </p-tabPanel>
            <ng-container *ngIf="this.formRequest.controls.fk_flight_plan_data_id.value !== null">
                <!-- PANEL DOCUMENTAÇÃO -->
                <p-tabPanel>
                    <ng-template pTemplate="header">
                        <i class="fa-solid fa-book"></i>
                        <span class="cls-component-page-tabview-tabpainel-legend">Documentação</span>
                    </ng-template>
                    <ng-template pTemplate="content">
                        <div class="cls-component-page-tabview-content-frame">
                            <div class="grid">
                                <div class="col-3">
                                    <nashville-panel-generate-pdf [formComponent]="this.formRequest"
                                        (generatePdfButtonAction)="this.callbackGeneratePdfButtons($event)">
                                    </nashville-panel-generate-pdf>
                                </div>
                                <div class="col-9">
                                    <nashville-panel-content-pdf [iptPcpFormRequest]="this.formRequest"
                                        [iptPcpFlightPlanContent]="this.currentFlightPlanData"
                                        (ottPcpActionPdfButtons)="this.callbackContentPdfButtons($event)"
                                        (ottPcpActionExtraFlightData)="this.callbackActionExtraFlightData($event)">
                                    </nashville-panel-content-pdf>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </p-tabPanel>
            </ng-container>
        </p-tabView>
    </div>
    <section class="cls-component-fixed-footer-page">
        <div class="cls-content-box">
            <div class="cls-component-fixed-footer-page-frame">
                <div class="cls-component-fixed-footer-page-frame-start"></div>
                <div class="cls-component-fixed-footer-page-frame-center"></div>
                <div class="cls-component-fixed-footer-page-frame-end">
                    <nashville-bottom-utc-datetime></nashville-bottom-utc-datetime>
                </div>
            </div>
        </div>
    </section>
    <section>
        <p-sidebar [(visible)]="this.showSidebarMessages" animate="true" position="left" class="cls-component-sidebar-frame">
            <ng-template pTemplate="header">
                <div>MENSAGENS</div>
            </ng-template>
            <ng-template pTemplate="content">

            </ng-template>
        </p-sidebar>
        <p-sidebar [(visible)]="this.showSidebarCheckList" position="right" class="cls-component-sidebar-frame">
            <ng-template pTemplate="header">
                <div>CHECK LIST</div>
            </ng-template>
            <ng-template pTemplate="content">

            </ng-template>
        </p-sidebar>
    </section>






    <section>
        <p-dialog class="cls-component-dialog-search-aerodrome">
            <ng-template pTemplate="content">
                <div class="grid">
                    <div class="field col-12">
                        <p-fieldset class="cls-component-fieldset-filter">
                            <ng-template pTemplate="header">
                                <span class="font-bold">Procurar Aeródromo / Heliporto / Heliponto / ZZZZ</span>
                            </ng-template>
                            <div class="">
                                <div class="cls-component-form">
                                    <div class="formgrid grid">
                                        <!--<div class="field col-6 md:col-6 md:col-6 lg:col-6 xl:col-2">
                                            <ui-primeng-auto-complement-input-text-button label="CÓD. ICAO"
                                                idField="fid-aerodrome-filter-table-icao-code"
                                                [control]="this.formFilterSearchRecordedAerodrome.controls.icao_code"
                                                sizeInput="small" letterCase="upper" [maxCharacters]="4"
                                                [arraySuggestions]="this.filteredByIcaoCode" noLabelWidth="1410">
                                            </ui-primeng-auto-complement-input-text-button>
                                        </div>-->
                                        <div class="field col-6 md:col-6 md:col-6 lg:col-6 xl:col-2">
                                            <ui-primeng-input-text label="Nome" idField="fid-aircraft-mark"
                                                [control]="this.formFilterSearchRecordedAerodrome.controls.name"
                                                sizeInput="small" letterCase="upper" maxCharacters="25"
                                                isDisabled="false" noLabelWidth="1410">
                                            </ui-primeng-input-text>
                                        </div>
                                        <div class="field col-6 md:col-6 md:col-6 lg:col-6 xl:col-2">
                                            <ui-primeng-input-text label="Cidade" idField="fid-aircraft-mark"
                                                [control]="this.formFilterSearchRecordedAerodrome.controls.city"
                                                sizeInput="small" letterCase="upper" maxCharacters="25"
                                                isDisabled="false" noLabelWidth="1410">
                                            </ui-primeng-input-text>
                                        </div>
                                        <!-- <div class="field col-4">
                                            <ui-primeng-input-text label="Tipo" idField="fid-aircraft-mark"
                                                [control]="this.formFilterSearchRecordedAerodrome.controls.aerodrome_type"
                                                sizeInput="small" letterCase="upper" maxCharacters="5"
                                                isDisabled="false" noLabelWidth="1410">
                                            </ui-primeng-input-text>
                                        </div>
                                        
                                        <div class="field col-4">
                                            <ui-primeng-input-text label="País" idField="fid-aircraft-mark"
                                                [control]="this.formFilterTableSearchAerodrome.controls.country"
                                                sizeInput="small" letterCase="upper" maxCharacters="25"
                                                isDisabled="false" noLabelWidth="1410">
                                            </ui-primeng-input-text>
                                        </div>
                                        <div class="field col-4">
                                            <ui-primeng-input-text label="Estado" idField="fid-aircraft-mark"
                                                [control]="this.formFilterTableSearchAerodrome.controls.state"
                                                sizeInput="small" letterCase="upper" maxCharacters="15"
                                                isDisabled="false" noLabelWidth="1410">
                                            </ui-primeng-input-text>
                                        </div>
                                       -->
                                    </div>
                                </div>
                            </div>
                        </p-fieldset>
                        <div class="cls-component-dialog-search-aerodrome-table">
                            <!--<p-table [value]="this.arrayAerodromeSearch" responsiveLayout="stack"
                                styleClass="p-datatable-gridlines p-datatable-striped" scrollHeight="23.5rem"
                                [tableStyle]="{'height': '23.5rem'}">
                                 <ng-template pTemplate="header">
                                    <tr>
                                        <th style="width:3%;"></th>
                                        <th pSortableColumn="icao_code" class="text-center" style="width:10%;">ICAO
                                            CODE
                                            <p-sortIcon field="ica_code"></p-sortIcon>
                                        </th>
                                        <th class="text-center" style="width:7%;">TIPO</th>
                                        <th pSortableColumn="name" style="width:30%;">NOME <p-sortIcon field="name">
                                            </p-sortIcon>
                                        </th>
                                        <th class="text-center">PAIS</th>
                                        <th pSortableColumn="place.state" class="text-center">ESTADO
                                            <p-sortIcon field="place.state"></p-sortIcon>
                                        </th>
                                        <th pSortableColumn="place.city" style="width:25%;">CIDADE
                                            <p-sortIcon field="place.city"></p-sortIcon>
                                        </th>
                                        <th class="text-center">COORDENADAS</th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-aerodromes>
                                    <tr>
                                        <td class="text-center">
                                            <div class="cls-component-dialog-search-aerodrome-table-action-button">
                                                <p-button [rounded]="true" severity="secondary" [outlined]="true"
                                                    (click)="selectAerodrome(aerodromes.aerodrome_basic_data_id)">
                                                    <img src="{{this.getAerodromeIcon(null)}}" alt="">
                                                </p-button>
                                            </div>
                                        </td>
                                        <td class="text-center">{{ aerodromes.icao_code }}</td>
                                        <td class="text-center">{{ aerodromes.aerodrome_type }}</td>
                                        <td>{{ aerodromes.name }}</td>
                                        <td class="text-center">{{ aerodromes.place.country_code }}</td>
                                        <td class="text-center">{{ aerodromes.place.state }}</td>
                                        <td>{{ aerodromes.place.city }}</td>
                                        <td class="text-center">
                                            <div>
                                                {{ aerodromes.coordinate_plan }}
                                            </div>
                                            <div style="font-size: 0.6rem;">
                                                ({{ aerodromes.coordinate_dd[0].toFixed(5) }},
                                                {{ aerodromes.coordinate_dd[1].toFixed(5) }})
                                            </div>
                                        </td>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="summary">
                                    <div class="flex align-items-center justify-content-between">
                                        Total de Aeródromos recuperados:
                                        {{this.arrayAerodromeSearch ? this.arrayAerodromeSearch.length : 0 }}.
                                    </div>
                                </ng-template>
                            </p-table>-->
                        </div>
                    </div>
                </div>
            </ng-template>
            <ng-template pTemplate="footer">
                <p-button icon="fa-solid fa-xmark" label="Cancelar" pAutoFocus [autofocus]="true" severity="secondary"
                    (click)="closeSearchRecordedAerodrome()"></p-button>
            </ng-template>
        </p-dialog>
        <p-dialog [showHeader]="false" [(visible)]="this.showDialogSearchLocation" [modal]="true"
            [style]="{ width: '80vw' }" class="cls-component-dialog-search-aerodrome">
            <ng-template pTemplate="content">
                <div class="grid">
                    <div class="field col-12">
                        <p-fieldset class="cls-component-fieldset-filter">
                            <ng-template pTemplate="header">
                                <div class="flex align-items-center gap-2 px-2">
                                    <span class="font-bold">Localizar Aeródromo</span>
                                </div>
                            </ng-template>
                            <div class="m-0">
                                <div class="cls-component-form">
                                    <div class="formgrid grid">
                                        <div class="field col-6">
                                            <ui-primeng-dropdown label="Pais" idField="fid-map-search-country-acronym"
                                                placeHolder="" sizeInput="small"
                                                [control]="this.formFilterSearchLocation.controls.country_acronym"
                                                [itemsArray]="this.optionsMapCountry" withFilter="false"
                                                noLabelWidth="1410">
                                            </ui-primeng-dropdown>
                                        </div>
                                        <div class="field col-6">
                                            <ui-primeng-dropdown label="Estado" idField="fid-map-search-state-acronym"
                                                placeHolder="" sizeInput="small"
                                                [control]="this.formFilterSearchLocation.controls.state_acronym"
                                                [itemsArray]="this.optionsMapStates" withFilter="true"
                                                noLabelWidth="1410">
                                            </ui-primeng-dropdown>
                                        </div>
                                        <div class="field col-6">
                                            <ui-primeng-dropdown label="Cidade" idField="fid-map-search-city-name"
                                                placeHolder="" sizeInput="small"
                                                [control]="this.formFilterSearchLocation.controls.city_name"
                                                [itemsArray]="this.optionsMapCities" withFilter="true"
                                                noLabelWidth="1410">
                                            </ui-primeng-dropdown>
                                        </div>
                                        <div class="field col-6">
                                            <ui-primeng-input-text label="Coordenadas"
                                                idField="fid-map-search-coordinate"
                                                [control]="this.formFilterSearchLocation.controls.coordinate"
                                                sizeInput="small" letterCase="upper" maxCharacters="5"
                                                isDisabled="false" noLabelWidth="1410">
                                            </ui-primeng-input-text>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </p-fieldset>
                        <div class="cls-component-dialog-search-aerodrome-map-frame">
                            <nashville-search-location-map [showMap]="this.showDialogSearchLocation"
                                [citiesMarkers]="this.arrayCitiesToMarkMap">
                            </nashville-search-location-map>
                        </div>
                    </div>
                </div>
            </ng-template>
            <ng-template pTemplate="footer">
                <p-button icon="fa-solid fa-xmark" label="Cancelar" pAutoFocus [autofocus]="true" severity="secondary"
                    (click)="closeSearchLocation()"></p-button>
            </ng-template>
        </p-dialog>
    </section>
    <p-scrollTop></p-scrollTop>
    <!--DIALOGS BOXES-->
    <nashville-dialog-many-aerodrome-basic-data
        (dialogButtonsAction)="returnCallbackDialogManyAerodromeBasicData($event)"
        [showDialog]="this.showDialogManyAerodromeBasicData">
    </nashville-dialog-many-aerodrome-basic-data>

    <nashville-dialog-search-aerodrome-coord 
        (dialogButtonsAction)="returnCallbackDialogSearchAerodromeByCoord($event)"
        [showDialog]="this.showDialogSearchAerodromeCoord">
    </nashville-dialog-search-aerodrome-coord>

    <nashville-dialog-search-aerodrome-icao 
        (dialogButtonsAction)="returnCallbackDialogSearchAerodromeByIcao($event)"
        [showDialog]="this.showDialogSearchAerodromeIcao">
    </nashville-dialog-search-aerodrome-icao>

    <nashville-dialog-route-save 
        (dialogButtonsAction)="returnCallbackDialogRouteSave($event)"
        [showDialog]="this.showDialogRouteSave">
    </nashville-dialog-route-save>

    <nashville-dialog-route-list 
        (dialogButtonsAction)="returnCallbackDialogRouteList($event)"
        [showDialog]="this.showDialogRouteList">
    </nashville-dialog-route-list>

    <nashville-dialog-waiting-process 
        (dialogButtonsAction)="returnCallbackDialogWaitingProcess($event)"
        [showDialog]="this.showDialogWaitingProcess">
    </nashville-dialog-waiting-process>


    <p-dialog [modal]="true" [(visible)]="this.showDialogWaitingSaveFlightRequest"
        [style]="{ width: '40vw', height: '40vh' }" class="cls-component-dialog-waiting-process">
        <ng-template pTemplate="header"></ng-template>
        <ng-template pTemplate="content">
            <div class="cls-component-dialog-waiting-process">
                <div class="cls-component-dialog-waiting-process-image">
                    <img src="assets/imgs/waiting_process_04.gif" alt="">
                </div>
                <div class="cls-component-dialog-waiting-process-label">
                    OS DADOS DA REQUISIÇÃO DE VOO ESTÃO SENDO SALVOS.
                    AGUARDE UM MOMENTO, POR FAVOR...
                </div>
            </div>
        </ng-template>
    </p-dialog>

    <p-dialog [modal]="true" [(visible)]="this.showDialogWaitingUpdateFlightRequest"
        [style]="{ width: '40vw', height: '40vh' }" class="cls-component-dialog-waiting-process">
        <ng-template pTemplate="header"></ng-template>
        <ng-template pTemplate="content">
            <div class="cls-component-dialog-waiting-process">
                <div class="cls-component-dialog-waiting-process-image">
                    <img src="assets/imgs/waiting_process_04.gif" alt="">
                </div>
                <div class="cls-component-dialog-waiting-process-label">
                    OS DADOS DA REQUISIÇÃO DE VOO ESTÃO SENDO SALVOS.
                </div>
            </div>
        </ng-template>
    </p-dialog>

    <p-dialog [modal]="true" [(visible)]="this.showDialogWaitingUpdatingFlightPlanData"
        [style]="{ width: '40vw', height: '40vh' }" class="cls-component-dialog-waiting-process">
        <ng-template pTemplate="header"></ng-template>
        <ng-template pTemplate="content">
            <div>
                <div class="cls-component-dialog-waiting-process-image">
                    <img src="assets/imgs/waiting_process_04.gif" alt="">
                </div>
                <div class="cls-component-dialog-waiting-process-label">
                    OS DADOS DO PLANO DE VOO ESTÃO SENDO ATUALIZADOS. <br>
                    AGUARDE UM MOMENTO, POR FAVOR...
                </div>
            </div>
        </ng-template>
    </p-dialog>

    <p-dialog [modal]="true" [(visible)]="this.showDialogWaitingLoadingData" [style]="{ width: '40vw', height: '40vh' }"
        class="cls-component-dialog-waiting-process">
        <ng-template pTemplate="header"></ng-template>
        <ng-template pTemplate="content">
            <div>
                <div class="cls-component-dialog-waiting-process-image">
                    <img src="assets/imgs/waiting_process_04.gif" alt="">
                </div>
                <div class="cls-component-dialog-waiting-process-label">
                    OS DADOS DA REQUISIÇÃO DE VOO ESTÃO RECUPERADAS. <br>
                    AGUARDE UM MOMENTO, POR FAVOR...
                </div>
            </div>
        </ng-template>
    </p-dialog>

    <p-dialog [modal]="true" [(visible)]="this.showDialogWaitingGettingData" [style]="{ width: '40vw', height: '40vh' }"
        class="cls-component-dialog-waiting-process">
        <ng-template pTemplate="header"></ng-template>
        <ng-template pTemplate="content">
            <div class="cls-component-dialog-waiting-process">
                <div class="cls-component-dialog-waiting-process-image">
                    <img src="assets/imgs/waiting_process_04.gif" alt="">
                </div>
                <div class="cls-component-dialog-waiting-process-label">
                    OS DADOS DO PLANO DE VOO ESTÃO SENDO GERADOS.<br>
                    AGUARDE UM MOMENTO, POR FAVOR...
                </div>
            </div>
        </ng-template>
    </p-dialog>

    <p-dialog [modal]="true" [(visible)]="this.showDialogWaitingPdfGenerating"
        [style]="{ width: '40vw', height: '40vh' }" class="cls-component-dialog-waiting-process">
        <ng-template pTemplate="header"></ng-template>
        <ng-template pTemplate="content">
            <div class="cls-component-dialog-waiting-process">
                <div class="cls-component-dialog-waiting-process-image">
                    <img src="assets/imgs/waiting_process_04.gif" alt="">
                </div>
                <div class="cls-component-dialog-waiting-process-label">
                    O ARQUIVO PDF DO DOCUMENTO ESTÁ SENDO GERADO. <br>
                    AGUARDE UM MOMENTO, POR FAVOR...
                </div>
            </div>
        </ng-template>
    </p-dialog>
</article>