//#region "|--- IMPORT INTERFACES ---|"
import { IDropDownBasicOptions } from '../../interfaces/IDropDownBasicOptions';
//#endregion

/**
 * TODO: https://tsdoc.org/
 */
export const braStatesOptions:IDropDownBasicOptions[] =  [
    { label: 'ACRE', value: 'AC' },
    { label: 'ALAGOAS', value: 'AL' },
    { label: 'AMAPÁ', value: 'AP' },
    { label: 'AMAZONAS', value: 'AM' },
    { label: 'BAHIA', value: 'BA' },
    { label: 'CEARÁ', value: 'CE' },
    { label: 'DISTRITO FEDERAL', value: 'DF' },
    { label: 'ESPÍRITO SANTO', value: 'ES' },
    { label: 'GOIÁS', value: 'GO' },
    { label: 'MARANHÃO', value: 'MA' },
    { label: 'MATO GROSSO', value: 'MT' },
    { label: 'MATO GROSSO DO SUL', value: 'MS' },
    { label: 'MINAS GERAIS', value: 'MG' },
    { label: 'PARÁ', value: 'PA' },
    { label: 'PARAÍBA', value: 'PB' },    
    { label: 'PARANÁ', value: 'PR' },
    { label: 'PERNAMBUCO', value: 'PE' },
    { label: 'PIAUÍ', value: 'PI' },
    { label: 'RIO DE JANEIRO', value: 'RJ' },
    { label: 'RIO GRANDE DO NORTE', value: 'RN' },
    { label: 'RIO GRANDE DO SUL', value: 'RS' },
    { label: 'RONDÔNIA', value: 'RO' },
    { label: 'RORAIMA', value: 'RR' },
    { label: 'SANTA CATARINA', value: 'SC' },
    { label: 'SÃO PAULO', value: 'SP' },
    { label: 'SERGIPE', value: 'SE' },
    { label: 'TOCANTINS', value: 'TO' }
]