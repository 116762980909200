import { Component, OnInit, Input, HostListener } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, ReactiveFormsModule } from '@angular/forms';

import { InputTextareaModule } from 'primeng/inputtextarea';
import { KeyFilterModule } from 'primeng/keyfilter';
import { TooltipModule } from 'primeng/tooltip';

import { ErrorMessageControlsComponent } from '../common/error-message-controls/error-message-controls.component';
import { hasControlErrors } from '../common/controls-methods';

//$sm	Breakpoint of screens such as phones.					           576px
//$md	Breakpoint of screens such as tablets.					         768px
//$lg	Breakpoint of screens such as notebook monitors.		     992px
//$xl	Breakpoint of smaller screens such as desktop monitors.	1200px

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'ui-primeng-text-area',
  standalone: true,
  imports: [
    ErrorMessageControlsComponent,
    KeyFilterModule,
    ReactiveFormsModule,
    CommonModule,
    InputTextareaModule,
    TooltipModule
  ],
  templateUrl: './text-area.component.html',
  styleUrl: './text-area.component.scss',
})
export class TextAreaComponent implements OnInit {
  //#region "|--- INPUTS ---|"
  @Input() control!: FormControl;
  @Input() label!: string;
  @Input() idField!: string;
  @Input() sizeInput?: string;
  @Input() rowsSize?: number;
  @Input() leftIcon?: string;
  @Input() rightIcon?: string;
  @Input() placeHolder?: string;
  @Input() noLabelWidth?: string;
  @Input() letterCase?: string;
  @Input() maxCharacters?: string;
  @Input() dataType?: string;
  @Input() isDisabled?: string;
  @Input() toolTip?: string;
  @Input() eventBlur?: any;
  @Input() eventKeyPress?: any;
  //#endregion

  //#region "|--- PROPERTIES ---|"
  innerWidth?: any;
  placeHolderContent?: string;

  hasIcon = false;
  showLabel = true;
  showLabelBold = false;
  showToolTipIcon = false;
  //#endregion

  ngOnInit(): void {
    if (this.letterCase != undefined && this.letterCase != "" && this.letterCase != null) {
      if (this.letterCase.toLocaleLowerCase() == "upper") {

      } else {
        this.letterCase = "lower";
      }
    } else {
      this.letterCase = "lower";
    }

    this.innerWidth = window.innerWidth;
    this.placeHolderContent = this.placeHolder;

    this._hasToShowLabelBold();
    this._getShowLabel();
    this._getIsDisabled();
    this._hasToShowToolTipIcon();
  }

  /**
   * (v1) - Executada toda vez que ocorre REDIMENSIONAMENTO da Tela.
   * https://www.w3schools.com/jsref/dom_obj_event.asp
   * @param event
   */
  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    this.innerWidth = window.innerWidth;

    this._getShowLabel();
  }

  getRowsSize(): number {
    if (this.rowsSize) {
      if (this.rowsSize > 0) {
        return this.rowsSize;
      } else {
        return 2;
      }
    } else {
      return 2;
    }
  }

  /**
   * TODO: https://tsdoc.org/
   */
  getFieldName() {
    return this.idField.replace("fid-", "");
  }

  /**
   * TODO: https://tsdoc.org/
   */
  getInputDataType(): any {
    if (this.dataType != undefined && this.dataType != "") {
      return this.dataType;
    } else {
      return "alphanum";
    }
  }

  /**
   * TODO: https://tsdoc.org/
   */
  getMaxCharacters(): string {
    if (this.maxCharacters) {
      return this.maxCharacters; // Retorna o que foi enviado durante a criação do componente
    } else {
      return ""; // Retorna o padrão texto
    }
  }

  /**
   * TODO: https://tsdoc.org/
   */
  getIconSide(): string {
    if (this.leftIcon != undefined && this.leftIcon != "") {
      this.hasIcon = true;

      return "p-input-icon-left w-full";
    } else if (this.rightIcon != undefined && this.rightIcon != "") {
      this.hasIcon = true;

      return "p-input-icon-right w-full";
    } else {
      return "cls-no-class";
    }
  }

  /**
   * TODO: https://tsdoc.org/
   */
  showIcon(): boolean {
    return this.hasIcon;
  }

  /**
   * TODO: https://tsdoc.org/
   */
  getIconName(): string {
    if (this.leftIcon != undefined && this.leftIcon != "") {
      return this.leftIcon;
    } else if (this.rightIcon != undefined && this.rightIcon != "") {
      return this.rightIcon;
    } else {
      return "";
    }
  }

  /**
   * TODO: https://tsdoc.org/
   */
  getShowLabelBold(): boolean {
    return this.showLabelBold;
  }

  /**
   * TODO: https://tsdoc.org/
   */
  getInputClass(): string {
    //let tmpReturn = "text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full";
    let tmpReturn = "w-full";

    // Faz a verificação da Caixa da Letra.

    if (this.letterCase != undefined && this.letterCase != "") {
      if (this.letterCase.toLowerCase() == "upper") {
        tmpReturn = `${tmpReturn} cls-text-uppercase`;
      } else if (this.letterCase.toLowerCase() == "lower") {
        tmpReturn = `${tmpReturn} cls-text-lowercase`;
      }
    }

    // Faz a Verificação do tamanho do Input.

    if (this.sizeInput != undefined && this.sizeInput != "") {
      if (this.sizeInput.toLowerCase() == "small") {
        tmpReturn = `${tmpReturn} p-inputtext-sm`;
      } else if (this.sizeInput.toLowerCase() == "large") {
        tmpReturn = `${tmpReturn} p-inputtext-lg`;
      } else {
        tmpReturn = `${tmpReturn}`;
      }
    }

    return tmpReturn
  }

  /**
   * TODO: https://tsdoc.org/
   */
  showErrors() {
    return hasControlErrors(this.control, "input");
  }

  /**
   * TODO: https://tsdoc.org/
   */
  getTooTipContent(): string {
    if (this.toolTip != undefined && this.toolTip != "") {
      return this.toolTip;
    } else {
      return "";
    }
  }

  /**
   * TODO: https://tsdoc.org/
   */
  private _getIsDisabled(): void {
    if (this.isDisabled != undefined && this.isDisabled != "") {
      if (this.isDisabled == "true") {
        this.control.disable();
      } else {
        this.control.enable();
      }
    } else {
      this.control.enable();
    }
  }

  /**
   * TODO: https://tsdoc.org/
   */
  private _hasToShowLabelBold(): void {
    if (this.label != undefined) {
      if (this.label.indexOf("#BOLD#") > 0) {
        this.showLabelBold = true;
        this.label = this.label.replace("#BOLD#", "").trim();
      }
    }
  }

  /**
   * TODO: https://tsdoc.org/
   */
  private _getShowLabel(): void {
    if (this.noLabelWidth != undefined && this.noLabelWidth != "") {
      if (this.innerWidth < Number(this.noLabelWidth)) {
        this.showLabel = false;
        this.showToolTipIcon = false;
        this.placeHolderContent = this.label;
      } else {
        this.showLabel = true;
        this._hasToShowToolTipIcon();
        this.placeHolderContent = "";
      }
    }
  }

  /**
   * TODO: https://tsdoc.org/
   */
  _hasToShowToolTipIcon(): void {
    if (this.toolTip != undefined && this.toolTip != "") {
      this.showToolTipIcon = true;
    }
  }
}
