<article id="id-horizontal-basic-01" class="cls-hrt-frame">
    <header class="cls-hrt-frame-header">
        <div class="cls-hrt-frame-header-top">
            <div class="cls-content-box ">
                <common-components-top-header-01 *ngIf="isTopHeader01()"></common-components-top-header-01>
                <common-components-top-header-02 *ngIf="isTopHeader02()"></common-components-top-header-02>
            </div>
        </div>
        <div class="cls-hrt-frame-header-menu">
            <div class="cls-content-box">
                <common-components-top-horizontal-menu-01 *ngIf="isTopHorizontalMenu01()">
                </common-components-top-horizontal-menu-01>
                <common-components-top-horizontal-menu-02 *ngIf="isTopHorizontalMenu02()">
                </common-components-top-horizontal-menu-02>
            </div>
        </div>
    </header>
    <section class="cls-hrt-frame-main">
        <div class="cls-content-box">
            <div class="cls-hrt-frame-main-content">
                <router-outlet></router-outlet>                
            </div>            
        </div>
    </section>
    <footer class="cls-hrt-frame-footer">

    </footer>
</article>