//#region "|--- INTERFACE ---|"
import { IDropDownBasicOptions } from '../../interfaces/IDropDownBasicOptions';
//#endregion

/**
 * TODO: https://tsdoc.org/
 */
export const flightPlanFlightRulesOptions:IDropDownBasicOptions[] =  [
    { label: '(I) IFR', value: 'I' },
    { label: '(V) VFR', value: 'V' },
    { label: '(Y) Inicia IFR', value: 'Y' },
    { label: '(Z) Inicia VFR', value: 'Z' }
]