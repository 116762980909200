<section class="cls-component-data-panel">
    <p-panel header="Aeronave no Sistema" class="cls-component-panel-formgrid">
        <div class="cls-component-aircraft-avatar">
            <div class="cls-component-aircraft-avatar-image">
                <ng-container
                    *ngIf="this.formComponent.controls.avatar_image.value === null || this.formComponent.controls.avatar_image.value.indexOf('no_image_available.jpg') >= 0">
                    <nashville-upload-file (onSuccess)="onSuccessAircraftImageUpload($event)"
                        (onError)="onErrorAircraftImageUpload($event)" (onImageError)="onErrorAircraftImage($event)"
                        [isMultiFiles]="false" buttonLabel="Buscar Imagem" fileName="image-upload"
                        urlUpload="{{this.serverUrl}}/mod_ac/aircraft/avatar/image">
                    </nashville-upload-file>
                </ng-container>
                <ng-container
                    *ngIf="this.formComponent.controls.avatar_image.value !== null && this.formComponent.controls.avatar_image.value.indexOf('no_image_available.jpg') < 0">
                    <div class="cls-component-aircraft-avatar-image-frame">
                        <img src="{{this.serverUrl}}/mod_ac/aircraft/avatar/image?url={{this.formComponent.controls.avatar_image.getRawValue()}}"
                            alt="">
                        <div class="cls-component-aircraft-avatar-image-frame-frame-button-delete">
                            <p-button label="APAGAR IMAGEM" icon="fa-solid fa-trash-can"
                                class="cls-component-aircraft-avatar-image-button-delete" (click)="deleteAvatarImage()"
                                size="small" severity="danger" />
                        </div>
                    </div>
                </ng-container>
            </div>
            <div class="cls-component-aircraft-avatar-color">
                <ui-primeng-color-picker label="Cor no Sistema" idField="fid-avatar-color"
                    [control]="this.formComponent.controls.avatar_color">
                </ui-primeng-color-picker>
            </div>
        </div>
    </p-panel>
</section>