//#region "|--- IMPORT MODULES/PACKAGES ---|"
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PrimengComponentsModule } from '@nashville-primeng-components';
//#endregion

//#region "|--- ENUMS ---|"
import { BUTTON_ACTION } from '../../../../../../helpers/enum/ButtonActions';
//#endregion

@Component({
  selector:
    'nashville-toolbar-table-flight-request-list',
  standalone: true,
  imports: [
    CommonModule,
    PrimengComponentsModule
  ],
  templateUrl: './toolbar-table-flight-request-list.component.html',
  styleUrl: './toolbar-table-flight-request-list.component.scss',
})
export class ToolbarTableFlightRequestListComponent implements OnInit {
  //#region "|--- INPUTS ---|"
  @Input() pageInnerWidth!: any;
  @Input() formComponent!: any;
  //#endregion

  //#region "|--- OUTPUTS ---|"
  @Output() toolbarAction = new EventEmitter<string>();
  //#endregion

  //#region "|--- PROPERTIES---|"
  labelActionsButtons: any;
  //#endregion

  /**
   * TODO: https://tsdoc.org/
   */
  ngOnInit(): void {
    this._initVariables();
  }

  //#region "|--- PRIVATE METHODS---|" 
  /**
   * TODO: https://tsdoc.org/
   */
  private _initVariables() {
    this.labelActionsButtons = BUTTON_ACTION;
  }
  //#endregion

  //#region "|--- PUBLIC METHODS---|" 
  /**
   * TODO: https://tsdoc.org/
   */
  callbackPageButton(xValue: string) {
    this.toolbarAction.emit(xValue);
  }
  //#endregion
}
