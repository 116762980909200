// $$$$$$$$$$ REVISED $$$$$$$$$$

//#region "|--- IMPORT MODULES/PACKAGES ---|"
// ***** ANGULAR *****
import { Component, EventEmitter, Input, OnInit, Output, AfterViewChecked, ViewChild, HostListener } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ElementRef } from '@angular/core';

// ***** NASHVILLE *****
import { settingConfig } from '@nashville-config';
import { PrimengComponentsModule } from '@nashville-primeng';
//#endregion

//#region "|--- IMPORT COMPONENTS ---|"
import { ExtraContentComponent } from '../../../../components/extra-content/extra-content.component';
//#endregion

@Component({
  selector: 'nashville-show-top-risk-data-pdf',
  standalone: true,
  imports: [
    CommonModule,
    PrimengComponentsModule,
    ExtraContentComponent
  ],
  templateUrl: './show-top-risk-data-pdf.component.html',
  styleUrl: './show-top-risk-data-pdf.component.scss',
})
export class ShowTopRiskDataPdfComponent implements OnInit, AfterViewChecked {
  //#region "|--- VIEW CHILD ---|"
  @ViewChild('panelContentTopRisk', { static: false }) divPanel: ElementRef;
  //#endregion

  //#region "|--- INPUTS ---|"
  // (IPT) Input
  // (STD) ShowTopRiskDataPdf
  @Input() iptStdFormRequest!: any;

  // É feito o set do @Input para que seja capturado qualquer modificação, em tempo real, 
  // do parâmetro que está sendo enviado pelo PARENT e, principalmente, que seja feito o 
  // processamento necessário com base no novo valor.
  @Input() set iptStdFlightPlanContent(xValue: any) {
    if (xValue && xValue != null) {
      this.flightPlanContentData = xValue;
      this.topRiskContentData = xValue.top_risk;     
    }
  };
  @Input() set iptStdCollapsePanel(xValue: any) {
    this.collapsePanel = xValue;
  };
  //#endregion

  //#region "|--- OUTPUTS ---|"
  // (OTT) Output
  // (STD) ShowTopRiskDataPdf
  @Output() ottStdActionExtraTopRiskData = new EventEmitter<string>();
  //#endregion

  //#region "|--- PROPERTIES---|" 
  // ***** PRIVATE *****
  _innerWidth!: any;

  // ***** CONFIG *****  
  collapsePanel: boolean;
  settingConfig!: any;

  // ***** OTHERS *****
  dataInfoUploadImage!: any;
  flightPlanContentData: any;
  topRiskContentData: any;
  serverUrl: any;
  //#endregion

  ngOnInit(): void {
    this._initVariables();
  }

  ngAfterViewChecked() {
    // PARA MEXER NO LAYOUT, TEM QUE ESPERAR TODOS O COMPONENTES HTML SEREM RENDERIZADOS.
    this._setLayout();
  }

  //#region "|--- HOST LISTENER ---|"
  /**
   * @type HOST LISTENER
   * @version 1.0.0 (GASPAR - 11/06/2024)
   * - Versão inicial.
   * 
   * @description 
   * - Executada toda vez que ocorre REDIMENSIONAMENTO da Tela.
   * - https://www.w3schools.com/jsref/dom_obj_event.asp
   * 
   * @param event 
   */
  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    this._innerWidth = window.innerWidth;
  }
  //#endregion

  //#region "|--- CALLBACK METHODS---|"
  /**
   * @type CALLBACK
   * @version 1.0.0 (GASPAR - 13/06/2024)
   * - Versão inicial.
   * 
   * @description 
   * - Faz o tratamento dos eventos relacionados aos botões do Toolbar.
   * 
   * @param xValue 
   */
  callbackActionExtraTopRiskData(xAction: any) {
    this.ottStdActionExtraTopRiskData.emit(xAction);    
  }
  //#endregion

  //#region "|--- PRIVATE METHODS---|"
  private _initVariables() {
    if (settingConfig.API_FLIGHT_PLAN_PORT == "80") {
      this.serverUrl = `${settingConfig.API_PROTOCOL}://${settingConfig.API_HOST}/${settingConfig.API_PATH}`
    } else {
      this.serverUrl = `${settingConfig.API_PROTOCOL}://${settingConfig.API_HOST}:${settingConfig.API_FLIGHT_PLAN_PORT}/${settingConfig.API_PATH}`
    }

    this.settingConfig = settingConfig;

    this.dataInfoUploadImage = {
      flight_request_id: this.iptStdFormRequest.controls.flight_request_id.value,
      type_file: "data_risk_extra"
    }
  }

  private _setLayout() {
    this.divPanel.nativeElement.querySelector('.p-panel .p-panel-header').style.backgroundColor = settingConfig.LAYOUT.background_color.top_risk;
    this.divPanel.nativeElement.querySelector('.p-panel .p-panel-header').style.color = "#FFF";
    this.divPanel.nativeElement.querySelector('.p-panel .p-panel-header .p-panel-header-icon').setAttribute('style', 'color: #FFF !important');
    this.divPanel.nativeElement.querySelector('.p-panel .p-panel-header .p-icon-wrapper').setAttribute('style', 'color: #FFF !important');
  }
  //#endregion
}
