//#region "|--- IMPORT MODULES/PACKAGES ---|"
// ***** ANGULAR *****
import { Component, OnInit, HostListener } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';

// ***** NPM *****
import HTTP_STATUS from 'http-status-codes';
import { MessageService } from 'primeng/api';
import { ConfirmationService } from 'primeng/api';

// ***** NASHVILLE *****
import { settingConfig } from '@nashville-config';
import { LocalMethodsHandlerClass } from '@nashville-local-methods';
import { PrimengComponentsModule } from '@nashville-primeng-components';
//#endregion

//#region "|--- IMPORT COMPONENTS ---|"
import { ToolbarTabpanelAeroRegistrationComponent } from './components/toolbar-tabpanel-aero-registration/toolbar-tabpanel-aero-registration.component';
import { AircraftDataDocumentComponent } from './components/aircraft-data-document/aircraft-data-document.component';
import { TabViewAeroRegistrationComponent } from './components/tab-view-aero-registration/tab-view-aero-registration.component';
import { AircraftDataSpecificationComponent } from './components/aircraft-data-specification/aircraft-data-specification.component';
import { AircraftDataEquipmentComponent } from './components/aircraft-data-equipment/aircraft-data-equipment.component';
import { AircraftDataWeightAndBalanceComponent } from './components/aircraft-data-weight-and-balance/aircraft-data-weight-and-balance.component';
import { AircraftDataPerformanceComponent } from './components/aircraft-data-performance/aircraft-data-performance.component';
import { DialogAircraftDataOwnerOperatorComponent } from './components/dialog-aircraft-data-owner-operator/dialog-aircraft-data-owner-operator.component';
import { PageTitleComponent } from 'src/app/shared/components/page-title/page-title.component';
import { PageToastComponent } from 'src/app/shared/components/page-toast/page-toast.component';
import { PanelAeroRegistrationComponent } from './components/panel-aero-registration/panel-aero-registration.component';
import { PanelImageColorAvatarComponent } from './components/panel-image-color-avatar/panel-image-color-avatar.component';
//#endregion

//#region "|--- FORM ---|"
import { initAircraftDataForm } from '../../form-init/aircraft-data-form';
import { initAircraftOwnersOperatorsForm } from '../../form-init/owner-operator-form';
//#endregion

//#region "|--- INTERFACE ---|"
import { IPatternResponseFromAPI } from '../../../../interfaces/IPatternCrudResponseFromAPI';
import { IDropDownBasicOptions } from 'src/app/interfaces/IDropDownBasicOptions';
import { IToastParameters } from 'src/app/interfaces/IToastParameters';
//#endregion

//#region "|--- SERVICE ---|"
import { AircraftDataApisService } from '../../services/aircraft-data-apis.service';
//#endregion

//#region "|--- ENUMS ---|"
import { BUTTON_ACTION } from '../../../../helpers/enum/ButtonActions';
//#endregion

//#region "|--- CONSTANTS ---|"
const TITLE_NEW = "CADASTRAR AERONAVE";
const TITLE_UPDATE = "EDITAR DADOS DA AERONAVE";
//#endregion

@Component({
  selector: 'nashville-aircraft-data-form',
  standalone: true,
  imports: [
    CommonModule,
    PrimengComponentsModule,
    AircraftDataDocumentComponent,
    AircraftDataEquipmentComponent,
    AircraftDataPerformanceComponent,
    AircraftDataSpecificationComponent,
    AircraftDataWeightAndBalanceComponent,
    DialogAircraftDataOwnerOperatorComponent,
    PageTitleComponent,
    PageToastComponent,
    TabViewAeroRegistrationComponent,
    ToolbarTabpanelAeroRegistrationComponent,
    PanelAeroRegistrationComponent,
    PanelImageColorAvatarComponent
  ],
  templateUrl: './aircraft-data-form.component.html',
  styleUrl: './aircraft-data-form.component.scss',
})
export class AircraftDataFormComponent implements OnInit {
  //#region "|--- PROPERTIES---|"
  currentPageTitle: string;
  currentAircraftId: any;
  innerWidth?: any;
  settingConfig!: any;
  objToastMessage: IToastParameters;

  showDialogOwnerOperator!: boolean;
  showDialogWaitingAircraftData!: boolean;

  typeDialogOwnersOperators!: string;
  indexArrayOwnersOperatorsToLoad!: number;

  formAircraftData!: any;
  formOwnerOperator!: any

  localMethodsHandlerClass: any;

  isEditModeAircraft!: boolean;


  optionsTypeAircraft!: IDropDownBasicOptions[];
  optionsFlightRules!: IDropDownBasicOptions[];

  arrayOwnersOperators!: any[];
  //#endregion

  constructor(
    private aircraftDataApisService: AircraftDataApisService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private routerActive: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit(): void {
    this._initVariables();
    this._initAircraftDataForm();
    this._initOwnerOperatorForm();

    setTimeout(() => {
      this._initEventsComponentForm();
    }, 10);

    // TEM QUE SER O ULTIMO ELEMENTO DA INICIALIZAÇÃO, POIS TEM QUE CARREGAR OS COMBOS PRIMEIROS.
    this._checkEditMode();
  }

  //#region "|--- PAGE EVENTS ---|"
  /**
   * (v1) - Executada toda vez que ocorre REDIMENSIONAMENTO da Tela.
   * https://www.w3schools.com/jsref/dom_obj_event.asp
   * @param event
   */
  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    this.innerWidth = window.innerWidth;
  }
  //#endregion

  //#region "|--- CALL BACKS METHODS ---|"
  /**
   * TODO: https://tsdoc.org/
   */
  callbackShowDialogOwnerOperator(xDataAction: any) {
    if (xDataAction.action == BUTTON_ACTION.SHOW_DIALOG) {
      this.showDialogOwnerOperator = true;
      this.typeDialogOwnersOperators = xDataAction.type;
      this.indexArrayOwnersOperatorsToLoad = xDataAction.index;

      if (this.indexArrayOwnersOperatorsToLoad < 0) { // Se for valor negativo, indica NOVO

      } else { // Se for valor zero para mais indica que deve recuperar o elemento do array formComponent.controls.               
        this.formOwnerOperator.patchValue(this.formAircraftData.controls.owner_operator_data.value[this.indexArrayOwnersOperatorsToLoad]);
      }
    } else if (xDataAction.action == BUTTON_ACTION.DELETE) {
      this._deleteOwnerOperator(xDataAction.index, xDataAction.type);
    }
  }

  /**
   * TODO: https://tsdoc.org/
   */
  callBackDialogOwnerOperatorAction(xAction: string) {
    if (xAction == BUTTON_ACTION.SAVE) {
      this._saveOwnerOperator();
    } if (xAction == BUTTON_ACTION.UPDATE) {
      this._updateOwnerOperator();
    } else if (xAction == BUTTON_ACTION.CANCEL) {
      this.showDialogOwnerOperator = false;
      this.typeDialogOwnersOperators = "";

      this.formOwnerOperator.controls.owner_operator_id.setValue(null);
      this.formOwnerOperator.controls.type_owner_operator.setValue(null);
      this.formOwnerOperator.controls.avatar_image.setValue(null);
      this.formOwnerOperator.controls.avatar_color.setValue(null);
      this.formOwnerOperator.controls.is_company.setValue(true);
      this.formOwnerOperator.controls.country.setValue("BRA");
      this.formOwnerOperator.controls.ni.setValue(null);
      this.formOwnerOperator.controls.name.setValue(null);
      this.formOwnerOperator.controls.responsible.controls.name.setValue(null);
      this.formOwnerOperator.controls.responsible.controls.contact.controls.ddi.setValue(null);
      this.formOwnerOperator.controls.responsible.controls.contact.controls.ddd.setValue(null);
      this.formOwnerOperator.controls.responsible.controls.contact.controls.number.setValue(null);
      this.formOwnerOperator.controls.responsible.controls.contact.controls.normalized_text.setValue(null);
      this.formOwnerOperator.controls.address.setValue([]);
      this.formOwnerOperator.controls.contacts.setValue([]);
      this.formOwnerOperator.controls.obs_owner_operator.setValue(null);
    }
  }

  /**
   * TODO: https://tsdoc.org/
   */
  callBackToolbarAeroRegistration(xAction: string) {
    if (xAction == BUTTON_ACTION.NEW) {
      this._newRegister();
    } else if (xAction == BUTTON_ACTION.SAVE) {
      this._saveAeroRegistration();
    } else if (xAction == BUTTON_ACTION.UPDATE) {
      this._updateAeroRequest();
    } else if (xAction == BUTTON_ACTION.CANCEL) {
      this.router.navigateByUrl(`/aircraft/list`);
    }
  }

  /**
   * TODO: https://tsdoc.org/
   */
  callBackShowToast(xObjMessage: IToastParameters) {
    this._showToast(xObjMessage);
  }
  //#endregion

  //#region "|--- PRIVATE METHODS ---|"
  private _initEventsComponentForm() {

    this.formAircraftData.get('aero_registration.mark').valueChanges.subscribe(async (xValue: any) => {

      if (xValue && xValue != null && xValue.length > 0) {
        const tempMarkNoMask = xValue.replaceAll("-", "");

        if (tempMarkNoMask.length == 5 && this.currentAircraftId == null) {
          this.showDialogWaitingAircraftData = true;

          await this.aircraftDataApisService.getBraRab(tempMarkNoMask).subscribe({
            next: (xResponseService: any) => {
              if (xResponseService.status_code == HTTP_STATUS.OK) {
                if (xResponseService.data_info) {
                  if (xResponseService.data_info.qtd == 1) {
                    const tempData = xResponseService.data_info.data[0];
                    const tempOwnerOperador: any = [];

                    let tempOwnerName: any = [];
                    let tempOwnerNi: any = [];

                    this.formAircraftData.controls.aero_registration.controls.model.setValue(tempData.model);
                    this.formAircraftData.controls.aero_registration.controls.serial_number.setValue(tempData.serial_number);
                    this.formAircraftData.controls.aero_registration.controls.manufacturer.setValue(tempData.manufacturer);
                    this.formAircraftData.controls.aero_registration.controls.icao_code.setValue(tempData.icao_code);
                    this.formAircraftData.controls.aero_registration.controls.year_manufactured.setValue(tempData.year_manufactured);
                    this.formAircraftData.controls.aero_registration.controls.type_aircraft.setValue(tempData.type_aircraft);
                    this.formAircraftData.controls.aero_registration.controls.flight_rules.setValue(tempData.flight_rules);

                    // OWNER
                    tempData.owner.forEach((xElementOwner: any) => {
                      tempOwnerName.push(xElementOwner.name.trim());
                      tempOwnerNi.push(xElementOwner.ni.trim());

                      tempOwnerOperador.push(
                        {
                          owner_operator_id: LocalMethodsHandlerClass.generateUuid(),
                          type_owner_operator: "owner",
                          avatar_image: "",
                          avatar_color: "",
                          is_company: xElementOwner.is_company,
                          country: "",
                          ni: xElementOwner.ni,
                          name: xElementOwner.name,
                          responsible: {
                            name: "",
                            contact: {
                              ddi: "+55",
                              ddd: "",
                              number: "",
                              normalized_text: ""
                            }
                          },
                          address: [],
                          contacts: [],
                          obs_owner_operator: ""
                        }
                      )
                    });

                    tempData.operator.forEach((xElementOperator: any) => {
                      if (tempOwnerName.includes(xElementOperator.name.trim()) && tempOwnerNi.includes(xElementOperator.ni.trim())) {
                        tempOwnerOperador.forEach((xElementOwnerOperator: any, xIndex: number) => {
                          if (xElementOwnerOperator.name == xElementOperator.name) {
                            tempOwnerOperador[xIndex].type_owner_operator = "both";
                          }
                        });
                      } else {
                        tempOwnerOperador.push(
                          {
                            owner_operator_id: LocalMethodsHandlerClass.generateUuid(),
                            type_owner_operator: "operator",
                            avatar_image: "",
                            avatar_color: "",
                            is_company: xElementOperator.is_company,
                            country: "",
                            ni: xElementOperator.ni,
                            name: xElementOperator.name,
                            responsible: {
                              name: "",
                              contact: {
                                ddi: "",
                                ddd: "",
                                number: "",
                                normalized_text: ""
                              }
                            },
                            address: [],
                            contacts: [],
                            obs_owner_operator: ""
                          }
                        )
                      }
                    })

                    this.formAircraftData.controls.owner_operator_data.value = tempOwnerOperador;
                  }
                }
              }

              setTimeout(() => {
                this.showDialogWaitingAircraftData = false;

                /*this._showToast({
                  severity: 'success',
                  summary: 'Salvar Requisição de Voo',
                  detail: `A Requisição de Voo foi criada com sucesso.`
                });*/
              }, 3000)
            },
            complete: () => { },
            error: (xErrorService: any) => {
              this.showDialogWaitingAircraftData = false;
            }
          })
        }
      }
    });
  }

  /**
   * TODO: https://tsdoc.org/
   */
  private _initVariables(): void {
    this.innerWidth = window.innerWidth;
    this.settingConfig = settingConfig;

    this.localMethodsHandlerClass = LocalMethodsHandlerClass;
  }

  /**
   * TODO: https://tsdoc.org/
   */
  private async _checkEditMode() {
    // Verifica se na URL, mapeada em rotas, possui um parâmetro que neste caso: ID da Aeronave.
    await this.routerActive.params.subscribe(async xParams => {
      if (xParams["id"]) {
        const tmpCurrentID = xParams['id'];
        this.currentPageTitle = TITLE_UPDATE;

        await this.aircraftDataApisService.getAircraftDataById(tmpCurrentID).subscribe({
          next: (xResponseApiService: IPatternResponseFromAPI) => {
            if (xResponseApiService.status_code == HTTP_STATUS.OK) {
              if (xResponseApiService.data_info.qtd == 1) {
                const tempData = xResponseApiService.data_info.data[0];

                this.currentAircraftId = tempData.aircraft_data_id;

                this.formAircraftData.controls.aero_registration.controls.country_registration.setValue(tempData.aero_registration.country_registration);
                this.formAircraftData.controls.aero_registration.controls.type_aircraft.setValue(tempData.aero_registration.type_aircraft);
                this.formAircraftData.controls.aero_registration.controls.mark.patchValue(tempData.aero_registration.mark, { emitEvent: false, onlySelf: true });
                this.formAircraftData.controls.aero_registration.controls.manufacturer.setValue(tempData.aero_registration.manufacturer);
                this.formAircraftData.controls.aero_registration.controls.model.setValue(tempData.aero_registration.model);
                this.formAircraftData.controls.aero_registration.controls.icao_code.setValue(tempData.aero_registration.icao_code);
                this.formAircraftData.controls.aero_registration.controls.year_manufactured.setValue(tempData.aero_registration.year_manufactured);
                this.formAircraftData.controls.aero_registration.controls.serial_number.setValue(tempData.aero_registration.serial_number);
                this.formAircraftData.controls.aero_registration.controls.flight_rules.setValue(tempData.aero_registration.flight_rules);
                this.formAircraftData.controls.aero_registration.controls.fin_number.setValue(tempData.aero_registration.fin_number);
                this.formAircraftData.controls.aero_registration.controls.aircraft_name.setValue(tempData.aero_registration.aircraft_name);
                this.formAircraftData.controls.aero_registration.controls.obs_registration_data.setValue(tempData.aero_registration.obs_registration_data);

                this.formAircraftData.controls.aircraft_data_id.setValue(tempData.aircraft_data_id);
                this.formAircraftData.controls.avatar_color.setValue(tempData.avatar_color);
                this.formAircraftData.controls.avatar_image.setValue(tempData.avatar_image);

                this.formAircraftData.controls.owner_operator_data.value = tempData.owner_operator_data;
              }
            }
          },
          complete: () => { },
          error: (xErrorApiService: any) => {
            if (this.settingConfig.SHOW_LOG_EXCEPTION) {
              console.log("Exceção - Recuperar Registro Aeronáutico: ", xErrorApiService);
            }

            this._showToast({
              severity: 'error',
              summary: 'Registro Aeronáutico',
              detail: 'Exceção - Recuperar Registro Aeronáutico.',
            });
          }
        });
      } else {
        this.currentAircraftId = null;
        this.formAircraftData.controls.aircraft_data_id.setValue(null);
        this.currentPageTitle = TITLE_NEW;
      }
    })
  }

  /**
   * TODO: https://tsdoc.org/
   * STATUS: OK
   */
  private async _newRegister() {
    await this.routerActive.params.subscribe(xParams => {
      if (xParams["id"]) {
        this.router.navigateByUrl(`/aircraft/form`);
      } else {
        this._initAircraftDataForm();
        this.formAircraftData.controls.avatar_color.setValue(LocalMethodsHandlerClass.generateHexColor())

        setTimeout(() => {
          this._initEventsComponentForm();
        }, 10);
      }
    })
  }

  /**
   * TODO: https://tsdoc.org/
   * STATUS: OK
   */
  private async _saveAeroRegistration() {
    if (this.formAircraftData.invalid) {
      // Recupera todos os Erros do Form, com base na Validação durante a criação do FORM.
      const errosFormRequest = LocalMethodsHandlerClass.getFormFieldsErrors(this.formAircraftData);
      const fieldsErros: any = [];

      let tmpData = '- campos não identificados.';

      // NOMEANDO OS CAMPOS
      errosFormRequest.forEach((xItem: any) => {
        fieldsErros.push(this._getFieldName(xItem.controlName));
      });

      // FORMATANDO OS NOMES DOS CAMPOS A SEREM MOSTRADOS.
      if (Array.isArray(fieldsErros)) {
        tmpData = fieldsErros.join('\n - ');
      }

      this._showToast({
        severity: 'error',
        summary: 'Registro Aeronáutico',
        detail: `O(s) campo(s) não foram validados: \n\n- ${tmpData}`
      });
    } else {
      // Tem que usar".getRawValue()", pois como tem controle desabilitado pode ser que os dados não sejam capturados.
      await this.aircraftDataApisService.saveAircraftData(this.formAircraftData.getRawValue()).subscribe({
        next: (xResponseService: IPatternResponseFromAPI) => {
          if (xResponseService.status_code == HTTP_STATUS.CREATED) {
            // Coloca o ID da Aeronave.
            this.formAircraftData["controls"]["aircraft_data_id"].setValue(xResponseService.data_info[0].id, { emitEvent: false });
            this.isEditModeAircraft = true;

            this._showToast({
              severity: 'success',
              summary: 'Registro Aeronáutico',
              detail: `O Registro Aeronáutico da Aeronave ${this.localMethodsHandlerClass.aircraftMarkPutMask(this.formAircraftData.controls.aero_registration.controls.mark.value)} foi realizado com sucesso.`
            });
          }
        },
        complete: () => { },
        error: (xErrorService: any) => {
          // TRATAMENTO DE ERRO RELACIONADO A CAMPO NAO VALIDADO DO LADO DO SERVIDOR.
          if (xErrorService.status == HTTP_STATUS.BAD_REQUEST) {
            let tempMessage = "Erro ao inserir registro aeronáutico.";
            let tempDetailOwnerOperator = ""; // Esta mensagem vai existir quando houver erro de Proprietário e Operador, ela engloba o erro do formulário do Registro Aeronáutico + Erro do Proprietário e Operador.
            let tempDetailAircraftData = "";  // Esta mensagem só vai existir se não houver problema do Proprietário e Operador, pois vai ser mensagem pura de erro do formulário do Registro Aeronáutico.
            let indexOwnerOperator = -1;

            if (xErrorService.error) { // UMA PEQUENA VERIFICAÇÃO PARA SABER SE ESTA RELACIONADO COM VALIDAÇÃO DE CAMPO.
              tempMessage = `${xErrorService.error.message}`;

              // SE FOR ARRAY, É VALIDAÇÃO DE CAMPO
              if (Array.isArray(xErrorService.error.data_info)) {
                xErrorService.error.data_info.forEach((xItemDataInfo: any) => { // VAI CORRER CADA ERRO DO ARRAY.
                  const tempOwnerOperatorErros: any = {
                    message: "",
                    parameter: "",
                    type: ""
                  };

                  // PRIMEIRA COISA É VERIFICA SE OS CAMPOS SÁO PERTENCENTES AO MESMO PARENT: owner_operator_data
                  // NO CASO DE ERROS RELACIONADO AO owner_operator_data, ELE VEM INDICANDO O INDEX DO ELEMENTO DO ARRAY QUE ESTA COM PROBLEMA.
                  // EU VOU SEMPRE O PRIMEIRO ELEMENTO DO ARRAY QUE APARECER, OS DEMAIS V4AO SER TRATADOS NAS PRÓXIMAS TENTATIVAS DE SALVA
                  if (xItemDataInfo.parameter.indexOf("owner_operator_data") >= 0) {
                    const tempArrayControls = xItemDataInfo.parameter.split(".");

                    // RECUPERAR QUAL É O INDEX DO ARRAY QUE VAI SER TRATADO.
                    if (indexOwnerOperator == -1) {
                      tempArrayControls.forEach((xItemControl: any) => {
                        if (isNaN(xItemControl) == false) {
                          indexOwnerOperator = parseInt(xItemControl);
                        }
                      });
                    }

                    // VAI RECUPERAR APENAS AS MENSAGENS DO ITEM DO ARRAY, CUJO INDEX FOI DETERMINADO, QUE ESTÃO ERRADOS
                    // SE TIVER OUTRO ITEM DO ARRAY ERRADO, VAI SER PrECISO SALVAR E TRATAR NA PROXIMA
                    if (xItemDataInfo.parameter.indexOf(`${indexOwnerOperator}`) >= 0) {
                      // É PRECISO AJUSTAR O NOME DOS PARÂMETROS ERRADOS TIRANDO O NOME DO PARENT. ISTO GARANTE QUE VAI SER TRATADO CORRETAMENTE NO FORM DESEJADO.
                      tempOwnerOperatorErros.message = xItemDataInfo.message,
                        tempOwnerOperatorErros.parameter = xItemDataInfo.parameter.replaceAll(`owner_operator_data.${indexOwnerOperator}.`, ""),
                        tempOwnerOperatorErros.type = xItemDataInfo.type

                      if (tempDetailOwnerOperator == "") {
                        tempDetailOwnerOperator = `* ${xItemDataInfo.message}`
                      } else {
                        tempDetailOwnerOperator = `${tempDetailOwnerOperator} \n * ${xItemDataInfo.message}`
                      }
                    }

                    // ENVIA O FORM DO DIALOG COM OS PARÂMETROS QUE FORAM ALTERADOS.
                    this.formOwnerOperator.setValue(this.formAircraftData.controls.owner_operator_data.value[indexOwnerOperator]);

                    // TEMPO PARA MOSTRAR O TOAST, ANTES DE ABRIR A JANELA.
                    setTimeout(() => {
                      this.showDialogOwnerOperator = true;
                      this.typeDialogOwnersOperators = this.formOwnerOperator.controls.type_owner_operator.value || "owner"; // POR DEFAULT, EU COLOCO SEMPRE COMO PROPRIETÁRIO.
                      this._setFieldErrorValidation(this.formOwnerOperator, tempOwnerOperatorErros);
                    }, 2000)
                  } else {
                    this._setFieldErrorValidation(this.formAircraftData, xItemDataInfo);

                    if (tempDetailAircraftData == "") {
                      tempDetailAircraftData = `* ${xItemDataInfo.message}`
                    } else {
                      tempDetailAircraftData = `${tempDetailAircraftData} \n * ${xItemDataInfo.message}`
                    }
                  }
                })
              }
            }

            this._showToast({
              severity: 'error',
              summary: 'Registro Aeronáutico',
              detail: `${tempMessage} \n\n ${tempDetailAircraftData} \n ${tempDetailOwnerOperator}`
            });
          } else {
            let tempMessage = "Houve um erro ou instabilidade no sistema.";

            if (xErrorService.error) {
              tempMessage = xErrorService.error.message
            }

            this._showToast({
              severity: 'error',
              summary: 'Registro Aeronáutico',
              detail: tempMessage
            });
          }
        }
      });
    }
  }

  private async _updateAeroRequest() {
    if (this.formAircraftData.invalid) {
      // Recupera todos os Erros do Form, com base na Validação durante a criação do FORM.
      const errosFormRequest = LocalMethodsHandlerClass.getFormFieldsErrors(this.formAircraftData);
      const fieldsErros: any = [];

      let tmpData = '- campos não identificados.';

      // NOMEANDO OS CAMPOS
      errosFormRequest.forEach((xItem: any) => {
        fieldsErros.push(this._getFieldName(xItem.controlName));
      });

      // FORMATANDO OS NOMES DOS CAMPOS A SEREM MOSTRADOS.
      if (Array.isArray(fieldsErros)) {
        tmpData = fieldsErros.join('\n - ');
      }

      this._showToast({
        severity: 'error',
        summary: 'Registro Aeronáutico',
        detail: `O(s) campo(s) não foram validados: \n\n- ${tmpData}`
      });
    } else {
      // Tem que usar".getRawValue()", pois como tem controle desabilitado pode ser que os dados não sejam capturados.
      await this.aircraftDataApisService.updateAircraftData(this.formAircraftData.getRawValue()).subscribe({
        next: (xResponseService: IPatternResponseFromAPI) => {
          if (xResponseService.status_code == HTTP_STATUS.OK) {
            this.isEditModeAircraft = true;

            this._showToast({
              severity: 'success',
              summary: 'Registro Aeronáutico',
              detail: `O Registro Aeronáutico da Aeronave ${this.localMethodsHandlerClass.aircraftMarkPutMask(this.formAircraftData.controls.aero_registration.controls.mark.value)} foi atualizado com sucesso.`
            });
          }
        },
        complete: () => { },
        error: (xErrorService: any) => {
          // TRATAMENTO DE ERRO RELACIONADO A CAMPO NAO VALIDADO DO LADO DO SERVIDOR.
          if (xErrorService.status == HTTP_STATUS.BAD_REQUEST) {
            let tempMessage = "Erro ao atualizar registro aeronáutico.";
            let tempDetailOwnerOperator = ""; // Esta mensagem vai existir quando houver erro de Proprietário e Operador, ela engloba o erro do formulário do Registro Aeronáutico + Erro do Proprietário e Operador.
            let tempDetailAircraftData = "";  // Esta mensagem só vai existir se não houver problema do Proprietário e Operador, pois vai ser mensagem pura de erro do formulário do Registro Aeronáutico.
            let indexOwnerOperator = -1;

            if (xErrorService.error) { // UMA PEQUENA VERIFICAÇÃO PARA SABER SE ESTA RELACIONADO COM VALIDAÇÃO DE CAMPO.
              tempMessage = `${xErrorService.error.message}`;

              // SE FOR ARRAY, É VALIDAÇÃO DE CAMPO
              if (Array.isArray(xErrorService.error.data_info)) {
                xErrorService.error.data_info.forEach((xItemDataInfo: any) => { // VAI CORRER CADA ERRO DO ARRAY.
                  const tempOwnerOperatorErros: any = {
                    message: "",
                    parameter: "",
                    type: ""
                  };

                  // PRIMEIRA COISA É VERIFICA SE OS CAMPOS SÁO PERTENCENTES AO MESMO PARENT: owner_operator_data
                  // NO CASO DE ERROS RELACIONADO AO owner_operator_data, ELE VEM INDICANDO O INDEX DO ELEMENTO DO ARRAY QUE ESTA COM PROBLEMA.
                  // EU VOU SEMPRE O PRIMEIRO ELEMENTO DO ARRAY QUE APARECER, OS DEMAIS V4AO SER TRATADOS NAS PRÓXIMAS TENTATIVAS DE SALVA
                  if (xItemDataInfo.parameter.indexOf("owner_operator_data") >= 0) {
                    const tempArrayControls = xItemDataInfo.parameter.split(".");

                    // RECUPERAR QUAL É O INDEX DO ARRAY QUE VAI SER TRATADO.
                    if (indexOwnerOperator == -1) {
                      tempArrayControls.forEach((xItemControl: any) => {
                        if (isNaN(xItemControl) == false) {
                          indexOwnerOperator = parseInt(xItemControl);
                        }
                      });
                    }

                    // VAI RECUPERAR APENAS AS MENSAGENS DO ITEM DO ARRAY, CUJO INDEX FOI DETERMINADO, QUE ESTÃO ERRADOS
                    // SE TIVER OUTRO ITEM DO ARRAY ERRADO, VAI SER PrECISO SALVAR E TRATAR NA PROXIMA
                    if (xItemDataInfo.parameter.indexOf(`${indexOwnerOperator}`) >= 0) {
                      // É PRECISO AJUSTAR O NOME DOS PARÂMETROS ERRADOS TIRANDO O NOME DO PARENT. ISTO GARANTE QUE VAI SER TRATADO CORRETAMENTE NO FORM DESEJADO.
                      tempOwnerOperatorErros.message = xItemDataInfo.message,
                        tempOwnerOperatorErros.parameter = xItemDataInfo.parameter.replaceAll(`owner_operator_data.${indexOwnerOperator}.`, ""),
                        tempOwnerOperatorErros.type = xItemDataInfo.type

                      if (tempDetailOwnerOperator == "") {
                        tempDetailOwnerOperator = `* ${xItemDataInfo.message}`
                      } else {
                        tempDetailOwnerOperator = `${tempDetailOwnerOperator} \n * ${xItemDataInfo.message}`
                      }
                    }

                    // ENVIA O FORM DO DIALOG COM OS PARÂMETROS QUE FORAM ALTERADOS.
                    this.formOwnerOperator.setValue(this.formAircraftData.controls.owner_operator_data.value[indexOwnerOperator]);

                    // TEMPO PARA MOSTRAR O TOAST, ANTES DE ABRIR A JANELA.
                    setTimeout(() => {
                      this.showDialogOwnerOperator = true;
                      this.typeDialogOwnersOperators = this.formOwnerOperator.controls.type_owner_operator.value || "owner"; // POR DEFAULT, EU COLOCO SEMPRE COMO PROPRIETÁRIO.
                      this._setFieldErrorValidation(this.formOwnerOperator, tempOwnerOperatorErros);
                    }, 2000)
                  } else {
                    this._setFieldErrorValidation(this.formAircraftData, xItemDataInfo);

                    if (tempDetailAircraftData == "") {
                      tempDetailAircraftData = `* ${xItemDataInfo.message}`
                    } else {
                      tempDetailAircraftData = `${tempDetailAircraftData} \n * ${xItemDataInfo.message}`
                    }
                  }
                })
              }
            }

            this._showToast({
              severity: 'error',
              summary: 'Registro Aeronáutico',
              detail: `${tempMessage} \n\n ${tempDetailAircraftData} \n ${tempDetailOwnerOperator}`
            });
          } else {
            let tempMessage = "Houve um erro ou instabilidade no sistema.";

            if (xErrorService.error) {
              tempMessage = xErrorService.error.message
            }

            this._showToast({
              severity: 'error',
              summary: 'Registro Aeronáutico',
              detail: tempMessage
            });
          }
        }
      });
    }
  }


  /**
   * TODO: https://tsdoc.org/
   */
  private _setFieldErrorValidation(xForm: any, xData: any) {
    const arrayControls = xData.parameter.split(".");
    const errorType: any = {};

    let tempControl = xForm;

    errorType[`${xData.type}`] = true;

    arrayControls.forEach((xItemControl: any) => {
      if (isNaN(xItemControl) == true) { // Verifica se o conteúdo é NÃO NUMÉRICO.
        tempControl = tempControl.controls[`${xItemControl}`];
      }
    });

    // POR CAUSA DE DIALOG É PRECISO COLOCAR UM DELAY ATÉ FORMAR O COMPONENTE.
    setTimeout(() => {
      tempControl.setErrors(errorType);
      tempControl.markAsTouched();
      tempControl.markAsDirty();
    }, 1000)
  }

  /**
   * TODO: https://tsdoc.org/
   * STATUS: OK
   */
  private async _saveOwnerOperator() {
    const tempTypeOwnerOperator = this.formOwnerOperator.controls.type_owner_operator.getRawValue();

    let labelTypeOwnerOperator = "Proprietário/Operador";

    if (tempTypeOwnerOperator) {
      if (tempTypeOwnerOperator == "owner") {
        labelTypeOwnerOperator = "Proprietário";
      } else if (tempTypeOwnerOperator == "operator") {
        labelTypeOwnerOperator = "Operador";
      }
    }

    console.log("-*-*-*-*@@@@@@@@@@@@@@@@@@", this.formOwnerOperator.getRawValue())

    if (this.formOwnerOperator.invalid) {
      // Recupera todos os Erros do Form, com base na Validação durante a criação do FORM.
      const errosFormRequest = LocalMethodsHandlerClass.getFormFieldsErrors(this.formOwnerOperator);
      const fieldsErros: any = [];

      let tmpData = '- campos não identificados.';

      // NOMEANDO OS CAMPOS
      errosFormRequest.forEach((xItem: any) => {
        fieldsErros.push(this._getFieldName(xItem.controlName));
      });

      // FORMATANDO OS NOMES DOS CAMPOS A SEREM MOSTRADOS.
      if (Array.isArray(fieldsErros)) {
        tmpData = fieldsErros.join('\n - ');
      }

      this._showToast({
        severity: 'error',
        summary: `Dados ${labelTypeOwnerOperator}`,
        detail: `O(s) campo(s) não foram validados: \n\n- ${tmpData}`
      });

      // ATUALIZAR AUTOMATICAMENTE OS DADOS DA AERONAVE, QUANDO EXISTIR O DADO NO BANCO
      if (this.formAircraftData.controls.aircraft_data_id.value != null) {
        this._updateAeroRequest();
      }
    } else {
      const tempOwnerOperatorId = this.formOwnerOperator.controls.owner_operator_id.getRawValue();

      if (tempOwnerOperatorId == null) { // NOVO REGISTRO
        this.formOwnerOperator.controls.owner_operator_id.setValue(LocalMethodsHandlerClass.generateUuid(), { emitEvent: false })
        this.formAircraftData.controls.owner_operator_data.value.push(this.formOwnerOperator.getRawValue());

        //this.formOwnerOperator.reset();
        this.formOwnerOperator.controls.owner_operator_id.setValue(null);
        this.formOwnerOperator.controls.is_company.setValue(true);

        // É IMPORTANTE ATRIBUIR O VALOR DE OWNER E OPERATOR A FORMUlÁRIO DA AERONAVE.
        console.log("~77777777", this.formOwnerOperator)
        console.log("~44444444", this.formAircraftData.controls.owner_operator_data)
        //this.formAircraftData.controls.owner_operator_data.value = 

        this._showToast({
          severity: 'success',
          summary: `Dados ${labelTypeOwnerOperator}`,
          detail: `Os dados foram salvos com sucesso.`
        });
      } else { // ATUALIZAR REGISTRO
        let indexToChange: number;

        this.formAircraftData.controls.owner_operator_data.value.forEach((xItemArray: any, xCounter: number) => {
          if (xItemArray.owner_operator_id == tempOwnerOperatorId) {
            indexToChange = xCounter;
          }
        });

        this.formAircraftData.controls.owner_operator_data.value[indexToChange] = this.formOwnerOperator.getRawValue();

        this._showToast({
          severity: 'success',
          summary: `Dados ${labelTypeOwnerOperator}`,
          detail: `Os dados foram atualizados com sucesso.`
        });

        // ATUALIZAR AUTOMATICAMENTE OS DADOS DA AERONAVE, QUANDO EXISTIR O DADO NO BANCO
        if (this.formAircraftData.controls.aircraft_data_id.value != null) {
          this._updateAeroRequest();
        }
      }

      this.showDialogOwnerOperator = false;
      this.typeDialogOwnersOperators = ""
    }
  }

  private async _updateOwnerOperator() {
    const tempTypeOwnerOperator = this.formOwnerOperator.controls.type_owner_operator.getRawValue();

    let labelTypeOwnerOperator = "Proprietário/Operador";

    if (tempTypeOwnerOperator) {
      if (tempTypeOwnerOperator == "owner") {
        labelTypeOwnerOperator = "Proprietário";
      } else if (tempTypeOwnerOperator == "operator") {
        labelTypeOwnerOperator = "Operador";
      }
    }

    console.log("$&$&$&$&$@@@@@@@@@@@@@@@@@@", this.formOwnerOperator.getRawValue())

    if (this.formOwnerOperator.invalid) {
      // Recupera todos os Erros do Form, com base na Validação durante a criação do FORM.
      const errosFormRequest = LocalMethodsHandlerClass.getFormFieldsErrors(this.formOwnerOperator);
      const fieldsErros: any = [];

      let tmpData = '- campos não identificados.';

      // NOMEANDO OS CAMPOS
      errosFormRequest.forEach((xItem: any) => {
        fieldsErros.push(this._getFieldName(xItem.controlName));
      });

      // FORMATANDO OS NOMES DOS CAMPOS A SEREM MOSTRADOS.
      if (Array.isArray(fieldsErros)) {
        tmpData = fieldsErros.join('\n - ');
      }

      this._showToast({
        severity: 'error',
        summary: `Dados ${labelTypeOwnerOperator}`,
        detail: `O(s) campo(s) não foram validados: \n\n- ${tmpData}`
      });
    } else {
      const tempOwnerOperatorId = this.formOwnerOperator.controls.owner_operator_id.getRawValue();

      if (tempOwnerOperatorId == null) { // NOVO REGISTRO
        this.formOwnerOperator.controls.owner_operator_id.setValue(LocalMethodsHandlerClass.generateUuid(), { emitEvent: false })
        this.formAircraftData.controls.owner_operator_data.value.push(this.formOwnerOperator.getRawValue());

        //this.formOwnerOperator.reset();
        this.formOwnerOperator.controls.owner_operator_id.setValue(null);
        this.formOwnerOperator.controls.is_company.setValue(true);

        // É IMPORTANTE ATRIBUIR O VALOR DE OWNER E OPERATOR A FORMUlÁRIO DA AERONAVE.
        console.log("~77777777", this.formOwnerOperator)
        console.log("~44444444", this.formAircraftData.controls.owner_operator_data)
        //this.formAircraftData.controls.owner_operator_data.value = 

        this._showToast({
          severity: 'success',
          summary: `Dados ${labelTypeOwnerOperator}`,
          detail: `Os dados foram salvos com sucesso.`
        });

        // ATUALIZAR AUTOMATICAMENTE OS DADOS DA AERONAVE, QUANDO EXISTIR O DADO NO BANCO
        if (this.formAircraftData.controls.aircraft_data_id.value != null) {
          this._updateAeroRequest();
        }
      } else { // ATUALIZAR REGISTRO
        let indexToChange: number;

        this.formAircraftData.controls.owner_operator_data.value.forEach((xItemArray: any, xCounter: number) => {
          if (xItemArray.owner_operator_id == tempOwnerOperatorId) {
            indexToChange = xCounter;
          }
        });

        this.formAircraftData.controls.owner_operator_data.value[indexToChange] = this.formOwnerOperator.getRawValue();

        this._showToast({
          severity: 'success',
          summary: `Dados ${labelTypeOwnerOperator}`,
          detail: `Os dados foram atualizados com sucesso.`
        });

        // ATUALIZAR AUTOMATICAMENTE OS DADOS DA AERONAVE, QUANDO EXISTIR O DADO NO BANCO
        if (this.formAircraftData.controls.aircraft_data_id.value != null) {
          this._updateAeroRequest();
        }
      }

      this.showDialogOwnerOperator = false;
      this.typeDialogOwnersOperators = ""
    }
  }

  /**
   * TODO: https://tsdoc.org/
   * STATUS: OK
   */
  private async _deleteOwnerOperator(xIndexToDelete: number, xTypeOwnerOperator: any) {
    const tempItemToDelete = this.formAircraftData.controls.owner_operator_data.value[xIndexToDelete];

    let labelTypeOwnerOperator = "Proprietário/Operador";
    let labelOppositeTypeOwnerOperator = "Proprietário/Operador";
    let typeOppositeTypeOwnerOperator = "";

    if (xTypeOwnerOperator) {
      if (xTypeOwnerOperator == "owner") {
        labelTypeOwnerOperator = "Proprietário";
        labelOppositeTypeOwnerOperator = "Operador";
        typeOppositeTypeOwnerOperator = "operator";
      } else if (xTypeOwnerOperator == "operator") {
        labelTypeOwnerOperator = "Operador";
        labelOppositeTypeOwnerOperator = "Proprietário";
        typeOppositeTypeOwnerOperator = "owner";
      }
    }

    if (tempItemToDelete.type_owner_operator == "both") {
      // É PRECISO CONFIRMAR SE REALMENTE O USUÁRIO DESEJA APAGAR OS DADOS.
      this.confirmationService.confirm({
        target: event.target as EventTarget,
        message: `Você deseja apagar os dados do ${labelTypeOwnerOperator}: ${tempItemToDelete.ni} - ${tempItemToDelete.name}? <br><br> <strong>OBS: Este registro continua existindo como ${labelOppositeTypeOwnerOperator}.</strong>`,
        header: 'Confirmação para apagar dados.',
        icon: 'pi pi-exclamation-triangle',
        acceptIcon: "none",
        rejectIcon: "none",
        acceptLabel: "CONFIRMAR",
        rejectLabel: "CANCELAR",
        acceptButtonStyleClass: "cls-component-button-pattern-default ml-2",
        rejectButtonStyleClass: "cls-component-button-pattern-default",
        accept: () => {
          // Quando for do tipo BOTH, não pode apagar o item do array, mas apenas definir qual tipo vai ser. As Tabelas serão atualizadas automaticamente.
          this.formAircraftData.controls.owner_operator_data.value[xIndexToDelete].type_owner_operator = typeOppositeTypeOwnerOperator;

          this._showToast({
            severity: 'success',
            summary: `Dados ${labelTypeOwnerOperator}`,
            detail: `Os dados foram apagados com sucesso.`
          });

          // ATUALIZAR AUTOMATICAMENTE OS DADOS DA AERONAVE, QUANDO EXISTIR O DADO NO BANCO
          if (this.formAircraftData.controls.aircraft_data_id.value != null) {
            this._updateAeroRequest();
          }
        },
        reject: () => {
          this._showToast({
            severity: 'info',
            summary: `Dados ${labelTypeOwnerOperator}`,
            detail: `Os dados não foram apagados.`
          });
        }
      });
    } else {
      // É PRECISO CONFIRMAR SE REALMENTE O USUÁRIO DESEJA APAGAR OS DADOS.
      this.confirmationService.confirm({
        target: event.target as EventTarget,
        message: `Você deseja apagar os dados do ${labelTypeOwnerOperator}: ${tempItemToDelete.ni} - ${tempItemToDelete.name}?`,
        header: 'Confirmação para apagar dados.',
        icon: 'pi pi-exclamation-triangle',
        acceptIcon: "none",
        rejectIcon: "none",
        acceptLabel: "CONFIRMAR",
        rejectLabel: "CANCELAR",
        acceptButtonStyleClass: "cls-component-button-pattern-default ml-2",
        rejectButtonStyleClass: "cls-component-button-pattern-default",
        accept: () => {
          this.formAircraftData.controls.owner_operator_data.value.splice(xIndexToDelete, 1);

          this._showToast({
            severity: 'success',
            summary: `Dados ${labelTypeOwnerOperator}`,
            detail: `Os dados foram apagados com sucesso.`
          });

          // ATUALIZAR AUTOMATICAMENTE OS DADOS DA AERONAVE, QUANDO EXISTIR O DADO NO BANCO
          if (this.formAircraftData.controls.aircraft_data_id.value != null) {
            this._updateAeroRequest();
          }
        },
        reject: () => {
          this._showToast({
            severity: 'info',
            summary: `Dados ${labelTypeOwnerOperator}`,
            detail: `Os dados não foram apagados.`
          });
        }
      });
    }
  }

  /**
   * TODO: https://tsdoc.org/
   */
  private async _showToast(xObjMessage: IToastParameters) {
    this.objToastMessage = null; // Tem que forçar a mudança para que o componente registre a alteração do valor da propriedade.
    this.objToastMessage = xObjMessage;
  }

  /**
   * TODO: https://tsdoc.org/
   */
  private _getFieldName(xFiledId: string) {
    switch (xFiledId) {
      case 'aero_registration.country_registration':
        return 'País de Registro';
      case 'aero_registration.type_aircraft':
        return 'Tipo Aeronave';
      case 'aero_registration.mark':
        return 'Registro Aeronave';
      case 'aero_registration.icao_code':
        return 'ICAO Code';
      case 'aero_registration.year_manufactured':
        return 'Ano Fabricação';
      case 'aero_registration.manufacturer':
        return 'Fabricante';
      case 'aero_registration.model':
        return 'Modelo';
      case 'aero_registration.serial_number':
        return 'Número Serial';
      case 'aero_registration.flight_rules':
        return 'Regra de Voo';
      case 'ni':
        return 'CNPJ/CPF';
      case 'name':
        return 'Nome';
      case 'address.country':
        return 'País';
      case 'address.zip_code':
        return 'CEP';
      case 'address.street_name':
        return 'Logradouro';
      case 'address.city':
        return 'Cidade';
      case 'address.state':
        return 'Estado';
      case 'contacts.mobile':
        return 'Celular/WhatsApp';
      case 'contacts.email':
        return 'E-mail';
      default:
        return xFiledId;
    }
  }
  //#endregion

















  //#region "|--- PUBLIC METHODS---|" 


  /**
   * TODO: https://tsdoc.org/
   */
  returnCallBackTableButton(xAction: string) {
    if (xAction == BUTTON_ACTION.SHOW_OWNER) {
      this.showDialogOwnerOperator = true;
      this.typeDialogOwnersOperators = "owner";
    } else if (xAction == BUTTON_ACTION.SHOW_OPERATOR) {
      this.showDialogOwnerOperator = true;
      this.typeDialogOwnersOperators = "operator";
    } else if (xAction == BUTTON_ACTION.CANCEL) {
      this.showDialogOwnerOperator = false;
      this.typeDialogOwnersOperators = "";
    }
  }


  //#endregion


  //#region "|--- PRIVATE METHODS---|" 


  /**
   * TODO: https://tsdoc.org/
   */



  /**
   * TODO: https://tsdoc.org/
   */
  private _initAircraftDataForm(): void {
    this.formAircraftData = initAircraftDataForm();
  }

  /**
   * TODO: https://tsdoc.org/
   */
  private _initOwnerOperatorForm(): void {
    this.formOwnerOperator = initAircraftOwnersOperatorsForm();
  }
  //#endregion





  /**
   * TODO: https://tsdoc.org/
   */
  async submitForGetAllAircraftData() {
    await this.aircraftDataApisService.getAllAircraftData().subscribe({
      next: (xResponseService) => {
        console.log("HHHHHHHHHH", xResponseService);
      },
      complete: () => {

      },
      error: (xErrorService) => {
        console.log("XXXXXXXXXX", xErrorService);
        this.messageService.add({ severity: 'error', summary: 'Dados Aeronave', detail: "ERROR" });
      }
    });
  }
}
