<section class="cls-component-data-panel">
    <p-panel header="Top Risk" [toggleable]="true" [collapsed]="true" class="cls-component-panel-formgrid">
        <p-fieldset legend="GERAL" class="cls-component-fieldset-formgrid">
            <div class="cls-component-form">
                <div class="formgrid grid">
                    <div class="field col-4">
                        <ui-primeng-dropdown-template-color label="RISCO DO VOO" idField="fid-flight-risk-degree-choice"
                            [itemsArray]="this.optionsRiskDegree" placeHolder="..."
                            [control]="this.formTopRisk.controls.general.controls.flight_risk_degree.controls.choice"
                            sizeInput="small" letterCase="upper" noLabelWidth="{{this.settingConfig.WIDTH_NO_LABEL}}">
                        </ui-primeng-dropdown-template-color>
                    </div>
                    <div class="field col-4">
                        <ui-primeng-dropdown label="ATENÇÃO AO VOO" idField="fid-flight-risk-attention-choice"
                            placeHolder="" sizeInput="small"
                            [control]="this.formTopRisk.controls.general.controls.flight_risk_attention.controls.choice"
                            placeHolder="..." [itemsArray]="this.optionsRiskAttention" withFilter="false">
                        </ui-primeng-dropdown>
                    </div>
                    <div class="field col-4">
                        <ui-primeng-dropdown label="CONDIÇÃO DE VOO" idField="fid-flight-condition-choice"
                            placeHolder="" sizeInput="small"
                            [control]="this.formTopRisk.controls.general.controls.weather_category.controls.choice"
                            placeHolder="..." [itemsArray]="this.optionsWeatherCategories" withFilter="false">
                        </ui-primeng-dropdown>
                    </div>
                </div>
                <div class="formgrid grid">
                    <div class="field col-12">
                        <ui-primeng-text-area label="OBSERVAÇÃO RISCO DE VOO" idField="fid-risk-observation.data"
                            [rowsSize]="3"
                            [control]="this.formTopRisk.controls.general.controls.risk_observation.controls.data"
                            sizeInput="small" letterCase="upper" isDisabled="false">
                        </ui-primeng-text-area>
                    </div>
                </div>
            </div>
        </p-fieldset>
        <p-fieldset legend="PILOTO(S)" class="cls-component-fieldset-formgrid">
            <p-panel header="Qualificações para o Voo" class="cls-component-panel-formgrid-sub-menu">
                <div class="cls-component-form">
                    <div class="formgrid grid">
                        <div class="field col-4">
                            <div class="cls-component-compose-input-top-risk">
                                <ui-primeng-label label="Certificados para o voo"></ui-primeng-label>
                                <div class="cls-component-compose-input-top-risk-frame">
                                    <ui-primeng-dropdown label="" idField="fid-crew-certification-choice"
                                        sizeInput="small"
                                        [control]="this.formTopRisk.controls.crew.controls.certification.controls.choice"
                                        placeHolder="..." [itemsArray]="this.optionsRiskYesNotApplicable"
                                        withFilter="false" noLabelWidth="{{this.settingConfig.WIDTH_NO_LABEL}}"
                                        class="cls-component-compose-input-top-risk-choice">
                                    </ui-primeng-dropdown>
                                    <ui-primeng-input-text label="" idField="fid-crew-certification-data"
                                        [control]="this.formTopRisk.controls.crew.controls.certification.controls.data"
                                        sizeInput="small" letterCase="upper" maxCharacters="250" isDisabled="false"
                                        noLabelWidth="{{this.settingConfig.WIDTH_NO_LABEL}}"
                                        class="cls-component-compose-input-top-risk-data">
                                    </ui-primeng-input-text>
                                </div>
                            </div>
                        </div>
                        <div class="field col-4">
                            <div class="cls-component-compose-input-top-risk">
                                <ui-primeng-label label="Experiência para o voo"></ui-primeng-label>
                                <div class="cls-component-compose-input-top-risk-frame">
                                    <ui-primeng-dropdown label="" idField="fid-crew-experience-choice" sizeInput="small"
                                        [control]="this.formTopRisk.controls.crew.controls.experience.controls.choice"
                                        placeHolder="..." [itemsArray]="this.optionsRiskYesNotApplicable"
                                        withFilter="false" noLabelWidth="{{this.settingConfig.WIDTH_NO_LABEL}}"
                                        class="cls-component-compose-input-top-risk-choice">
                                    </ui-primeng-dropdown>
                                    <ui-primeng-input-text label="" idField="fid-crew-experience-data"
                                        [control]="this.formTopRisk.controls.crew.controls.experience.controls.data"
                                        sizeInput="small" letterCase="upper" maxCharacters="250" isDisabled="false"
                                        noLabelWidth="{{this.settingConfig.WIDTH_NO_LABEL}}"
                                        class="cls-component-compose-input-top-risk-data">
                                    </ui-primeng-input-text> 
                                </div>
                            </div>
                        </div>
                        <div class="field col-4">
                            <div class="cls-component-compose-input-top-risk">
                                <ui-primeng-label label="Treinamento necessários"></ui-primeng-label>
                                <div class="cls-component-compose-input-top-risk-frame">
                                    <ui-primeng-dropdown label="" idField="fid-crew-training-choice" sizeInput="small"
                                        [control]="this.formTopRisk.controls.crew.controls.training.controls.choice"
                                        placeHolder="..." [itemsArray]="this.optionsRiskYesNotApplicable"
                                        withFilter="false" noLabelWidth="{{this.settingConfig.WIDTH_NO_LABEL}}"
                                        class="cls-component-compose-input-top-risk-choice">
                                    </ui-primeng-dropdown>
                                    <ui-primeng-input-text label="" idField="fid-crew-training-data"
                                        [control]="this.formTopRisk.controls.crew.controls.training.controls.data"
                                        sizeInput="small" letterCase="upper" maxCharacters="250" isDisabled="false"
                                        noLabelWidth="{{this.settingConfig.WIDTH_NO_LABEL}}"
                                        class="cls-component-compose-input-top-risk-data">
                                    </ui-primeng-input-text>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="cls-component-form">
                    <div class="formgrid grid">
                        <div class="field col-4">
                            <div class="cls-component-compose-input-top-risk">
                                <ui-primeng-label label="Documentações em dia"></ui-primeng-label>
                                <div class="cls-component-compose-input-top-risk-frame">
                                    <ui-primeng-dropdown label="" idField="fid-crew-documentation-choice"
                                        sizeInput="small"
                                        [control]="this.formTopRisk.controls.crew.controls.documentation.controls.choice"
                                        placeHolder="..." [itemsArray]="this.optionsRiskYesNotApplicable"
                                        withFilter="false" noLabelWidth="{{this.settingConfig.WIDTH_NO_LABEL}}"
                                        class="cls-component-compose-input-top-risk-choice">
                                    </ui-primeng-dropdown>
                                    <ui-primeng-input-text label="" idField="fid-crew-documentation-data"
                                        [control]="this.formTopRisk.controls.crew.controls.documentation.controls.data"
                                        sizeInput="small" letterCase="upper" maxCharacters="250" isDisabled="false"
                                        noLabelWidth="{{this.settingConfig.WIDTH_NO_LABEL}}"
                                        class="cls-component-compose-input-top-risk-data">
                                    </ui-primeng-input-text>
                                </div>
                            </div>
                        </div>
                        <div class="field col-4">
                            <div class="cls-component-compose-input-top-risk">
                                <ui-primeng-label label="Adaptado a aeronave"></ui-primeng-label>
                                <div class="cls-component-compose-input-top-risk-frame">
                                    <ui-primeng-dropdown label="" idField="fid-crew-adapted-choice" sizeInput="small"
                                        [control]="this.formTopRisk.controls.crew.controls.adapted_aircraft.controls.choice"
                                        placeHolder="..." [itemsArray]="this.optionsRiskYesNotApplicable"
                                        withFilter="false" noLabelWidth="{{this.settingConfig.WIDTH_NO_LABEL}}"
                                        class="cls-component-compose-input-top-risk-choice">
                                    </ui-primeng-dropdown>
                                    <ui-primeng-input-text label="" idField="fid-crew-adapted-data"
                                        [control]="this.formTopRisk.controls.crew.controls.adapted_aircraft.controls.data"
                                        sizeInput="small" letterCase="upper" maxCharacters="250" isDisabled="false"
                                        noLabelWidth="{{this.settingConfig.WIDTH_NO_LABEL}}"
                                        class="cls-component-compose-input-top-risk-data">
                                    </ui-primeng-input-text>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </p-panel>
            <p-panel header="Aero Medical" class="cls-component-panel-formgrid-sub-menu mt-2">
                <div class="cls-component-form">
                    <div class="formgrid grid">
                        <div class="field col-4">
                            <div class="cls-component-compose-input-top-risk">
                                <ui-primeng-label label="Apresenta algum enfermidade"></ui-primeng-label>
                                <div class="cls-component-compose-input-top-risk-frame">
                                    <ui-primeng-dropdown label="" idField="fid-crew-illness-choice" sizeInput="small"
                                        [control]="this.formTopRisk.controls.crew.controls.illness.controls.choice"
                                        placeHolder="..." [itemsArray]="this.optionsRiskYesNotApplicable"
                                        withFilter="false" noLabelWidth="{{this.settingConfig.WIDTH_NO_LABEL}}"
                                        class="cls-component-compose-input-top-risk-choice">
                                    </ui-primeng-dropdown>
                                    <ui-primeng-input-text label="" idField="fid-crew-illness-data"
                                        [control]="this.formTopRisk.controls.crew.controls.illness.controls.data"
                                        sizeInput="small" letterCase="upper" maxCharacters="250" isDisabled="false"
                                        noLabelWidth="{{this.settingConfig.WIDTH_NO_LABEL}}"
                                        class="cls-component-compose-input-top-risk-data">
                                    </ui-primeng-input-text>
                                </div>
                            </div>
                        </div>
                        <div class="field col-4">
                            <div class="cls-component-compose-input-top-risk">
                                <ui-primeng-label label="Uso de medicamentos"></ui-primeng-label>
                                <div class="cls-component-compose-input-top-risk-frame">
                                    <ui-primeng-dropdown label="" idField="fid-crew-medication-choice" sizeInput="small"
                                        [control]="this.formTopRisk.controls.crew.controls.medication.controls.choice"
                                        placeHolder="..." [itemsArray]="this.optionsRiskYesNotApplicable"
                                        withFilter="false" noLabelWidth="{{this.settingConfig.WIDTH_NO_LABEL}}"
                                        class="cls-component-compose-input-top-risk-choice">
                                    </ui-primeng-dropdown>
                                    <ui-primeng-input-text label="" idField="fid-crew-medication-data"
                                        [control]="this.formTopRisk.controls.crew.controls.medication.controls.data"
                                        sizeInput="small" letterCase="upper" maxCharacters="250" isDisabled="false"
                                        noLabelWidth="{{this.settingConfig.WIDTH_NO_LABEL}}"
                                        class="cls-component-compose-input-top-risk-data">
                                    </ui-primeng-input-text>
                                </div>
                            </div>
                        </div>
                        <div class="field col-4">
                            <div class="cls-component-compose-input-top-risk">
                                <ui-primeng-label label="Estresse/fadiga"></ui-primeng-label>
                                <div class="cls-component-compose-input-top-risk-frame">
                                    <ui-primeng-dropdown label="" idField="fid-crew-stress_fadigue-choice"
                                        sizeInput="small"
                                        [control]="this.formTopRisk.controls.crew.controls.stress_fadigue.controls.choice"
                                        placeHolder="..." [itemsArray]="this.optionsRiskYesNotApplicable"
                                        withFilter="false" noLabelWidth="{{this.settingConfig.WIDTH_NO_LABEL}}"
                                        class="cls-component-compose-input-top-risk-choice">
                                    </ui-primeng-dropdown>
                                    <ui-primeng-input-text label="" idField="fid-crew-stress_fadigue-data"
                                        [control]="this.formTopRisk.controls.crew.controls.stress_fadigue.controls.data"
                                        sizeInput="small" letterCase="upper" maxCharacters="250" isDisabled="false"
                                        noLabelWidth="{{this.settingConfig.WIDTH_NO_LABEL}}"
                                        class="cls-component-compose-input-top-risk-data">
                                    </ui-primeng-input-text>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </p-panel>
        </p-fieldset>
        <p-fieldset legend="AERONAVE" class="cls-component-fieldset-formgrid">
            <p-panel header="Performance" class="cls-component-panel-formgrid-sub-menu">
                <div class="cls-component-form">
                    <div class="formgrid grid">
                        <div class="field col-4">
                            <div class="cls-component-compose-input-top-risk">
                                <ui-primeng-label label="Cálculo de Combustível"></ui-primeng-label>
                                <div class="cls-component-compose-input-top-risk-frame">
                                    <ui-primeng-dropdown label="" idField="fid-aircraft-fuel-range-choice" sizeInput="small"
                                        [control]="this.formTopRisk.controls.aircraft.controls.fuel_range.controls.choice"
                                        placeHolder="..." [itemsArray]="this.optionsRiskYesNotApplicable"
                                        withFilter="false" noLabelWidth="{{this.settingConfig.WIDTH_NO_LABEL}}"
                                        class="cls-component-compose-input-top-risk-choice">
                                    </ui-primeng-dropdown>
                                    <ui-primeng-input-text label="" idField="fid-aircraft-fuel-range-data"
                                        [control]="this.formTopRisk.controls.aircraft.controls.fuel_range.controls.data"
                                        sizeInput="small" letterCase="upper" maxCharacters="250" isDisabled="false"
                                        noLabelWidth="{{this.settingConfig.WIDTH_NO_LABEL}}"
                                        class="cls-component-compose-input-top-risk-data">
                                    </ui-primeng-input-text>
                                </div>
                            </div>
                        </div>
                        <div class="field col-4">
                            <div class="cls-component-compose-input-top-risk">
                                <ui-primeng-label label="Cálculo Decol./Pouso"></ui-primeng-label>
                                <div class="cls-component-compose-input-top-risk-frame">
                                    <ui-primeng-dropdown label="" idField="fid-aircraft-takeoff-landing-performance-choice" sizeInput="small"
                                        [control]="this.formTopRisk.controls.aircraft.controls.takeoff_landing_performance.controls.choice"
                                        placeHolder="..." [itemsArray]="this.optionsRiskYesNotApplicable"
                                        withFilter="false" noLabelWidth="{{this.settingConfig.WIDTH_NO_LABEL}}"
                                        class="cls-component-compose-input-top-risk-choice">
                                    </ui-primeng-dropdown>
                                    <ui-primeng-input-text label="" idField="fid-aircraft-takeoff-landing-performance-data"
                                        [control]="this.formTopRisk.controls.aircraft.controls.takeoff_landing_performance.controls.data"
                                        sizeInput="small" letterCase="upper" maxCharacters="250" isDisabled="false"
                                        noLabelWidth="{{this.settingConfig.WIDTH_NO_LABEL}}"
                                        class="cls-component-compose-input-top-risk-data">
                                    </ui-primeng-input-text>
                                </div>
                            </div>
                        </div>
                        <div class="field col-4">
                            <div class="cls-component-compose-input-top-risk">
                                <ui-primeng-label label="Cálculo Cruzeiro"></ui-primeng-label>
                                <div class="cls-component-compose-input-top-risk-frame">
                                    <ui-primeng-dropdown label="" idField="fid-aircraft-cruise-performance-choice" sizeInput="small"
                                        [control]="this.formTopRisk.controls.aircraft.controls.cruise_performance.controls.choice"
                                        placeHolder="..." [itemsArray]="this.optionsRiskYesNotApplicable"
                                        withFilter="false" noLabelWidth="{{this.settingConfig.WIDTH_NO_LABEL}}"
                                        class="cls-component-compose-input-top-risk-choice">
                                    </ui-primeng-dropdown>
                                    <ui-primeng-input-text label="" idField="fid-aircraft-cruise-performance-data"
                                        [control]="this.formTopRisk.controls.aircraft.controls.cruise_performance.controls.data"
                                        sizeInput="small" letterCase="upper" maxCharacters="250" isDisabled="false"
                                        noLabelWidth="{{this.settingConfig.WIDTH_NO_LABEL}}"
                                        class="cls-component-compose-input-top-risk-data">
                                    </ui-primeng-input-text>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="formgrid grid">
                        <div class="field col-4">
                            <div class="cls-component-compose-input-top-risk">
                                <ui-primeng-label label="Peso e Balanceamento"></ui-primeng-label>
                                <div class="cls-component-compose-input-top-risk-frame">
                                    <ui-primeng-dropdown label="" idField="fid-aircraft-weight-balance-choice" sizeInput="small"
                                        [control]="this.formTopRisk.controls.aircraft.controls.weight_balance.controls.choice"
                                        placeHolder="..." [itemsArray]="this.optionsRiskYesNotApplicable"
                                        withFilter="false" noLabelWidth="{{this.settingConfig.WIDTH_NO_LABEL}}"
                                        class="cls-component-compose-input-top-risk-choice">
                                    </ui-primeng-dropdown>
                                    <ui-primeng-input-text label="" idField="fid-aircraft-weight-balance-data"
                                        [control]="this.formTopRisk.controls.aircraft.controls.weight_balance.controls.data"
                                        sizeInput="small" letterCase="upper" maxCharacters="250" isDisabled="false"
                                        noLabelWidth="{{this.settingConfig.WIDTH_NO_LABEL}}"
                                        class="cls-component-compose-input-top-risk-data">
                                    </ui-primeng-input-text>
                                </div>
                            </div>
                        </div>
                        <div class="field col-4">
                            <div class="cls-component-compose-input-top-risk">
                                <ui-primeng-label label="Carga Perigosa"></ui-primeng-label>
                                <div class="cls-component-compose-input-top-risk-frame">
                                    <ui-primeng-dropdown label="" idField="fid-aircraft-danger-paylod-choice" sizeInput="small"
                                        [control]="this.formTopRisk.controls.aircraft.controls.danger_payload.controls.choice"
                                        placeHolder="..." [itemsArray]="this.optionsRiskYesNotApplicable"
                                        withFilter="false" noLabelWidth="{{this.settingConfig.WIDTH_NO_LABEL}}"
                                        class="cls-component-compose-input-top-risk-choice">
                                    </ui-primeng-dropdown>
                                    <ui-primeng-input-text label="" idField="fid-aircraft-danger-paylod-data"
                                        [control]="this.formTopRisk.controls.aircraft.controls.danger_payload.controls.data"
                                        sizeInput="small" letterCase="upper" maxCharacters="250" isDisabled="false"
                                        noLabelWidth="{{this.settingConfig.WIDTH_NO_LABEL}}"
                                        class="cls-component-compose-input-top-risk-data">
                                    </ui-primeng-input-text>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </p-panel>
            <p-panel header="Equipamento" class="cls-component-panel-formgrid-sub-menu mt-2">
                <div class="cls-component-form">
                    <div class="formgrid grid">
                        <div class="field col-4">
                            <div class="cls-component-compose-input-top-risk">
                                <ui-primeng-label label="Equipamento requeridos para o voo"></ui-primeng-label>
                                <div class="cls-component-compose-input-top-risk-frame">
                                    <ui-primeng-dropdown label="" idField="fid-aircraft-flight-equipment-choice" sizeInput="small"
                                        [control]="this.formTopRisk.controls.aircraft.controls.flight_equipment.controls.choice"
                                        placeHolder="..." [itemsArray]="this.optionsRiskYesNotApplicable"
                                        withFilter="false" noLabelWidth="{{this.settingConfig.WIDTH_NO_LABEL}}"
                                        class="cls-component-compose-input-top-risk-choice">
                                    </ui-primeng-dropdown>
                                    <ui-primeng-input-text label="" idField="fid-aircraft-flight-equipment-data"
                                        [control]="this.formTopRisk.controls.aircraft.controls.flight_equipment.controls.data"
                                        sizeInput="small" letterCase="upper" maxCharacters="250" isDisabled="false"
                                        noLabelWidth="{{this.settingConfig.WIDTH_NO_LABEL}}"
                                        class="cls-component-compose-input-top-risk-data">
                                    </ui-primeng-input-text>
                                </div>
                            </div>
                        </div>
                        <div class="field col-4">
                            <div class="cls-component-compose-input-top-risk">
                                <ui-primeng-label label="Equipamento Inoperante"></ui-primeng-label>
                                <div class="cls-component-compose-input-top-risk-frame">
                                    <ui-primeng-dropdown label="" idField="fid-aircraft-flight-inoperante-equipment-choice" sizeInput="small"
                                        [control]="this.formTopRisk.controls.aircraft.controls.inoperante_equipment.controls.choice"
                                        placeHolder="..." [itemsArray]="this.optionsRiskYesNotApplicable"
                                        withFilter="false" noLabelWidth="{{this.settingConfig.WIDTH_NO_LABEL}}"
                                        class="cls-component-compose-input-top-risk-choice">
                                    </ui-primeng-dropdown>
                                    <ui-primeng-input-text label="" idField="fid-aircraft-flight-inoperante-equipment-data"
                                        [control]="this.formTopRisk.controls.aircraft.controls.inoperante_equipment.controls.data"
                                        sizeInput="small" letterCase="upper" maxCharacters="250" isDisabled="false"
                                        noLabelWidth="{{this.settingConfig.WIDTH_NO_LABEL}}"
                                        class="cls-component-compose-input-top-risk-data">
                                    </ui-primeng-input-text>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </p-panel>
        </p-fieldset>
        <p-fieldset legend="AMBIENTE" class="cls-component-fieldset-formgrid">
            <p-panel header="Meteorologia" class="cls-component-panel-formgrid-sub-menu">
                <div class="cls-component-form">
                    <div class="formgrid grid">
                        <div class="field col-4">
                            <div class="cls-component-compose-input-top-risk">
                                <ui-primeng-label label="Tempestade"></ui-primeng-label>
                                <div class="cls-component-compose-input-top-risk-frame">
                                    <ui-primeng-dropdown label="" idField="fid-environment-thunder-storm-choice" sizeInput="small"
                                        [control]="this.formTopRisk.controls.environment.controls.thunder_storm.controls.choice"
                                        placeHolder="..." [itemsArray]="this.optionsRiskYesNotApplicable"
                                        withFilter="false" noLabelWidth="{{this.settingConfig.WIDTH_NO_LABEL}}"
                                        class="cls-component-compose-input-top-risk-choice">
                                    </ui-primeng-dropdown>
                                    <ui-primeng-input-text label="" idField="fid-environment-thunder-storm-data"
                                        [control]="this.formTopRisk.controls.environment.controls.thunder_storm.controls.data"
                                        sizeInput="small" letterCase="upper" maxCharacters="250" isDisabled="false"
                                        noLabelWidth="{{this.settingConfig.WIDTH_NO_LABEL}}"
                                        class="cls-component-compose-input-top-risk-data">
                                    </ui-primeng-input-text>
                                </div>
                            </div>
                        </div>
                        <div class="field col-4">
                            <div class="cls-component-compose-input-top-risk">
                                <ui-primeng-label label="Gelo"></ui-primeng-label>
                                <div class="cls-component-compose-input-top-risk-frame">
                                    <ui-primeng-dropdown label="" idField="fid-environment-icing-choice" sizeInput="small"
                                        [control]="this.formTopRisk.controls.environment.controls.icing.controls.choice"
                                        placeHolder="..." [itemsArray]="this.optionsRiskYesNotApplicable"
                                        withFilter="false" noLabelWidth="{{this.settingConfig.WIDTH_NO_LABEL}}"
                                        class="cls-component-compose-input-top-risk-choice">
                                    </ui-primeng-dropdown>
                                    <ui-primeng-input-text label="" idField="fid-environment-icing-date"
                                        [control]="this.formTopRisk.controls.environment.controls.icing.controls.data"
                                        sizeInput="small" letterCase="upper" maxCharacters="250" isDisabled="false"
                                        noLabelWidth="{{this.settingConfig.WIDTH_NO_LABEL}}"
                                        class="cls-component-compose-input-top-risk-data">
                                    </ui-primeng-input-text>
                                </div>
                            </div>
                        </div>
                        <div class="field col-4">
                            <div class="cls-component-compose-input-top-risk">
                                <ui-primeng-label label="Teto Baixo e Baixa Visibilidade"></ui-primeng-label>
                                <div class="cls-component-compose-input-top-risk-frame">
                                    <ui-primeng-dropdown label="" idField="fid-environment-low-ceiling-visibility-choice" sizeInput="small"
                                        [control]="this.formTopRisk.controls.environment.controls.low_ceiling_visibility.controls.choice"
                                        placeHolder="..." [itemsArray]="this.optionsRiskYesNotApplicable"
                                        withFilter="false" noLabelWidth="{{this.settingConfig.WIDTH_NO_LABEL}}"
                                        class="cls-component-compose-input-top-risk-choice">
                                    </ui-primeng-dropdown>
                                    <ui-primeng-input-text label="" idField="fid-environment-low-ceiling-visibility-data"
                                        [control]="this.formTopRisk.controls.environment.controls.low_ceiling_visibility.controls.data"
                                        sizeInput="small" letterCase="upper" maxCharacters="250" isDisabled="false"
                                        noLabelWidth="{{this.settingConfig.WIDTH_NO_LABEL}}"
                                        class="cls-component-compose-input-top-risk-data">
                                    </ui-primeng-input-text>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="formgrid grid">
                        <div class="field col-4">
                            <div class="cls-component-compose-input-top-risk">
                                <ui-primeng-label label="Turbulência"></ui-primeng-label>
                                <div class="cls-component-compose-input-top-risk-frame">
                                    <ui-primeng-dropdown label="" idField="fid-environment-turbulence-choice" sizeInput="small"
                                        [control]="this.formTopRisk.controls.environment.controls.turbulence.controls.choice"
                                        placeHolder="..." [itemsArray]="this.optionsRiskYesNotApplicable"
                                        withFilter="false" noLabelWidth="{{this.settingConfig.WIDTH_NO_LABEL}}"
                                        class="cls-component-compose-input-top-risk-choice">
                                    </ui-primeng-dropdown>
                                    <ui-primeng-input-text label="" idField="fid-environment-turbulence-data"
                                        [control]="this.formTopRisk.controls.environment.controls.turbulence.controls.data"
                                        sizeInput="small" letterCase="upper" maxCharacters="250" isDisabled="false"
                                        noLabelWidth="{{this.settingConfig.WIDTH_NO_LABEL}}"
                                        class="cls-component-compose-input-top-risk-data">
                                    </ui-primeng-input-text>
                                </div>
                            </div>
                        </div>
                        <div class="field col-4">
                            <div class="cls-component-compose-input-top-risk">
                                <ui-primeng-label label="Ventos"></ui-primeng-label>
                                <div class="cls-component-compose-input-top-risk-frame">
                                    <ui-primeng-dropdown label="" idField="fid-environment-winds-choice" sizeInput="small"
                                        [control]="this.formTopRisk.controls.environment.controls.winds.controls.choice"
                                        placeHolder="..." [itemsArray]="this.optionsRiskYesNotApplicable"
                                        withFilter="false" noLabelWidth="{{this.settingConfig.WIDTH_NO_LABEL}}"
                                        class="cls-component-compose-input-top-risk-choice">
                                    </ui-primeng-dropdown>
                                    <ui-primeng-input-text label="" idField="fid-environment-winds-data"
                                        [control]="this.formTopRisk.controls.environment.controls.winds.controls.data"
                                        sizeInput="small" letterCase="upper" maxCharacters="250" isDisabled="false"
                                        noLabelWidth="{{this.settingConfig.WIDTH_NO_LABEL}}"
                                        class="cls-component-compose-input-top-risk-data">
                                    </ui-primeng-input-text>
                                </div>
                            </div>
                        </div>
                        <div class="field col-4">
                            <div class="cls-component-compose-input-top-risk">
                                <ui-primeng-label label="Wind Shear"></ui-primeng-label>
                                <div class="cls-component-compose-input-top-risk-frame">
                                    <ui-primeng-dropdown label="" idField="fid-environment-wind-shear-chocie" sizeInput="small"
                                        [control]="this.formTopRisk.controls.environment.controls.wind_shear.controls.choice"
                                        placeHolder="..." [itemsArray]="this.optionsRiskYesNotApplicable"
                                        withFilter="false" noLabelWidth="{{this.settingConfig.WIDTH_NO_LABEL}}"
                                        class="cls-component-compose-input-top-risk-choice">
                                    </ui-primeng-dropdown>
                                    <ui-primeng-input-text label="" idField="fid-environment-wind-shear-data"
                                        [control]="this.formTopRisk.controls.environment.controls.wind_shear.controls.data"
                                        sizeInput="small" letterCase="upper" maxCharacters="250" isDisabled="false"
                                        noLabelWidth="{{this.settingConfig.WIDTH_NO_LABEL}}"
                                        class="cls-component-compose-input-top-risk-data">
                                    </ui-primeng-input-text>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="formgrid grid">
                        <div class="field col-4">
                            <div class="cls-component-compose-input-top-risk">
                                <ui-primeng-label label="Alta Densidade do Ar"></ui-primeng-label>
                                <div class="cls-component-compose-input-top-risk-frame">
                                    <ui-primeng-dropdown label="" idField="fid-environment-hight-density-altitude-choice" sizeInput="small"
                                        [control]="this.formTopRisk.controls.environment.controls.hight_density_altitude.controls.choice"
                                        placeHolder="..." [itemsArray]="this.optionsRiskYesNotApplicable"
                                        withFilter="false" noLabelWidth="{{this.settingConfig.WIDTH_NO_LABEL}}"
                                        class="cls-component-compose-input-top-risk-choice">
                                    </ui-primeng-dropdown>
                                    <ui-primeng-input-text label="" idField="fid-environment-hight-density-altitude-data"
                                        [control]="this.formTopRisk.controls.environment.controls.hight_density_altitude.controls.data"
                                        sizeInput="small" letterCase="upper" maxCharacters="250" isDisabled="false"
                                        noLabelWidth="{{this.settingConfig.WIDTH_NO_LABEL}}"
                                        class="cls-component-compose-input-top-risk-data">
                                    </ui-primeng-input-text>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </p-panel>
            <p-panel header="Terreno" class="cls-component-panel-formgrid-sub-menu mt-2">
                <div class="cls-component-form">
                    <div class="formgrid grid">
                        <div class="field col-4">
                            <div class="cls-component-compose-input-top-risk">
                                <ui-primeng-label label="Obstáculos"></ui-primeng-label>
                                <div class="cls-component-compose-input-top-risk-frame">
                                    <ui-primeng-dropdown label="" idField="fid-environment-obstacles-choice" sizeInput="small"
                                        [control]="this.formTopRisk.controls.environment.controls.obstacles.controls.choice"
                                        placeHolder="..." [itemsArray]="this.optionsRiskYesNotApplicable"
                                        withFilter="false" noLabelWidth="{{this.settingConfig.WIDTH_NO_LABEL}}"
                                        class="cls-component-compose-input-top-risk-choice">
                                    </ui-primeng-dropdown>
                                    <ui-primeng-input-text label="" idField="fid-environment-obstacles-data"
                                        [control]="this.formTopRisk.controls.environment.controls.obstacles.controls.data"
                                        sizeInput="small" letterCase="upper" maxCharacters="250" isDisabled="false"
                                        noLabelWidth="{{this.settingConfig.WIDTH_NO_LABEL}}"
                                        class="cls-component-compose-input-top-risk-data">
                                    </ui-primeng-input-text>
                                </div>
                            </div>
                        </div>
                        <div class="field col-4">
                            <div class="cls-component-compose-input-top-risk">
                                <ui-primeng-label label="Hot Spot"></ui-primeng-label>
                                <div class="cls-component-compose-input-top-risk-frame">
                                    <ui-primeng-dropdown label="" idField="fid-environment-aerodrome-hot-spot-choice" sizeInput="small"
                                        [control]="this.formTopRisk.controls.environment.controls.aerodrome_hot_spot.controls.choice"
                                        placeHolder="..." [itemsArray]="this.optionsRiskYesNotApplicable"
                                        withFilter="false" noLabelWidth="{{this.settingConfig.WIDTH_NO_LABEL}}"
                                        class="cls-component-compose-input-top-risk-choice">
                                    </ui-primeng-dropdown>
                                    <ui-primeng-input-text label="" idField="fid-environment-aerodrome-hot-spot-data"
                                        [control]="this.formTopRisk.controls.environment.controls.aerodrome_hot_spot.controls.data"
                                        sizeInput="small" letterCase="upper" maxCharacters="250" isDisabled="false"
                                        noLabelWidth="{{this.settingConfig.WIDTH_NO_LABEL}}"
                                        class="cls-component-compose-input-top-risk-data">
                                    </ui-primeng-input-text>
                                </div>
                            </div>
                        </div>
                        <div class="field col-4">
                            <div class="cls-component-compose-input-top-risk">
                                <ui-primeng-label label="Voo Sobre Mar"></ui-primeng-label>
                                <div class="cls-component-compose-input-top-risk-frame">
                                    <ui-primeng-dropdown label="" idField="fid-environment-over-water-choice" sizeInput="small"
                                        [control]="this.formTopRisk.controls.environment.controls.over_water.controls.choice"
                                        placeHolder="..." [itemsArray]="this.optionsRiskYesNotApplicable"
                                        withFilter="false" noLabelWidth="{{this.settingConfig.WIDTH_NO_LABEL}}"
                                        class="cls-component-compose-input-top-risk-choice">
                                    </ui-primeng-dropdown>
                                    <ui-primeng-input-text label="" idField="fid-environment-over-water-data"
                                        [control]="this.formTopRisk.controls.environment.controls.over_water.controls.data"
                                        sizeInput="small" letterCase="upper" maxCharacters="250" isDisabled="false"
                                        noLabelWidth="{{this.settingConfig.WIDTH_NO_LABEL}}"
                                        class="cls-component-compose-input-top-risk-data">
                                    </ui-primeng-input-text>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </p-panel>
            <p-panel header="Aeródromo e Ambiente" class="cls-component-panel-formgrid-sub-menu mt-2">
                <div class="cls-component-form">
                    <div class="formgrid grid">
                        <div class="field col-4">
                            <div class="cls-component-compose-input-top-risk">
                                <ui-primeng-label label="AD Interferência Ilícita"></ui-primeng-label>
                                <div class="cls-component-compose-input-top-risk-frame">
                                    <ui-primeng-dropdown label="" idField="fid-environment-avsec-chocie" sizeInput="small"
                                        [control]="this.formTopRisk.controls.environment.controls.avsec.controls.choice"
                                        placeHolder="..." [itemsArray]="this.optionsRiskYesNotApplicable"
                                        withFilter="false" noLabelWidth="{{this.settingConfig.WIDTH_NO_LABEL}}"
                                        class="cls-component-compose-input-top-risk-choice">
                                    </ui-primeng-dropdown>
                                    <ui-primeng-input-text label="" idField="fid-environment-avsec-data"
                                        [control]="this.formTopRisk.controls.environment.controls.avsec.controls.data"
                                        sizeInput="small" letterCase="upper" maxCharacters="250" isDisabled="false"
                                        noLabelWidth="{{this.settingConfig.WIDTH_NO_LABEL}}"
                                        class="cls-component-compose-input-top-risk-data">
                                    </ui-primeng-input-text>
                                </div>
                            </div>
                        </div>
                        <div class="field col-4">
                            <div class="cls-component-compose-input-top-risk">
                                <ui-primeng-label label="Obstáculos Pouso/Decolagem"></ui-primeng-label>
                                <div class="cls-component-compose-input-top-risk-frame">
                                    <ui-primeng-dropdown label="" idField="fid-environment-ctol-choice" sizeInput="small"
                                        [control]="this.formTopRisk.controls.environment.controls.ctol.controls.choice"
                                        placeHolder="..." [itemsArray]="this.optionsRiskYesNotApplicable"
                                        withFilter="false" noLabelWidth="{{this.settingConfig.WIDTH_NO_LABEL}}"
                                        class="cls-component-compose-input-top-risk-choice">
                                    </ui-primeng-dropdown>
                                    <ui-primeng-input-text label="" idField="fid-environment-ctol-data"
                                        [control]="this.formTopRisk.controls.environment.controls.ctol.controls.data"
                                        sizeInput="small" letterCase="upper" maxCharacters="250" isDisabled="false"
                                        noLabelWidth="{{this.settingConfig.WIDTH_NO_LABEL}}"
                                        class="cls-component-compose-input-top-risk-data">
                                    </ui-primeng-input-text>
                                </div>
                            </div>
                        </div>
                        <div class="field col-4">
                            <div class="cls-component-compose-input-top-risk">
                                <ui-primeng-label label="Fauna Terrestre"></ui-primeng-label>
                                <div class="cls-component-compose-input-top-risk-frame">
                                    <ui-primeng-dropdown label="" idField="fid-environment-wild-choice" sizeInput="small"
                                        [control]="this.formTopRisk.controls.environment.controls.wild.controls.choice"
                                        placeHolder="..." [itemsArray]="this.optionsRiskYesNotApplicable"
                                        withFilter="false" noLabelWidth="{{this.settingConfig.WIDTH_NO_LABEL}}"
                                        class="cls-component-compose-input-top-risk-choice">
                                    </ui-primeng-dropdown>
                                    <ui-primeng-input-text label="" idField="fid-environment-wild-data"
                                        [control]="this.formTopRisk.controls.environment.controls.wild.controls.data"
                                        sizeInput="small" letterCase="upper" maxCharacters="250" isDisabled="false"
                                        noLabelWidth="{{this.settingConfig.WIDTH_NO_LABEL}}"
                                        class="cls-component-compose-input-top-risk-data">
                                    </ui-primeng-input-text>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="formgrid grid">
                        <div class="field col-4">
                            <div class="cls-component-compose-input-top-risk">
                                <ui-primeng-label label="Aves"></ui-primeng-label>
                                <div class="cls-component-compose-input-top-risk-frame">
                                    <ui-primeng-dropdown label="" idField="fid-environment-bird-choice" sizeInput="small"
                                        [control]="this.formTopRisk.controls.environment.controls.bird.controls.choice"
                                        placeHolder="..." [itemsArray]="this.optionsRiskYesNotApplicable"
                                        withFilter="false" noLabelWidth="{{this.settingConfig.WIDTH_NO_LABEL}}"
                                        class="cls-component-compose-input-top-risk-choice">
                                    </ui-primeng-dropdown>
                                    <ui-primeng-input-text label="" idField="fid-environment-bird-data"
                                        [control]="this.formTopRisk.controls.environment.controls.bird.controls.data"
                                        sizeInput="small" letterCase="upper" maxCharacters="250" isDisabled="false"
                                        noLabelWidth="{{this.settingConfig.WIDTH_NO_LABEL}}"
                                        class="cls-component-compose-input-top-risk-data">
                                    </ui-primeng-input-text>
                                </div>
                            </div>
                        </div>
                        <div class="field col-4">
                            <div class="cls-component-compose-input-top-risk">
                                <ui-primeng-label label="Incursão de Pista"></ui-primeng-label>
                                <div class="cls-component-compose-input-top-risk-frame">
                                    <ui-primeng-dropdown label="" idField="fid-environment-ri-choice" sizeInput="small"
                                        [control]="this.formTopRisk.controls.environment.controls.ri.controls.choice"
                                        placeHolder="..." [itemsArray]="this.optionsRiskYesNotApplicable"
                                        withFilter="false" noLabelWidth="{{this.settingConfig.WIDTH_NO_LABEL}}"
                                        class="cls-component-compose-input-top-risk-choice">
                                    </ui-primeng-dropdown>
                                    <ui-primeng-input-text label="" idField="fid-environment-ri-data"
                                        [control]="this.formTopRisk.controls.environment.controls.ri.controls.data"
                                        sizeInput="small" letterCase="upper" maxCharacters="250" isDisabled="false"
                                        noLabelWidth="{{this.settingConfig.WIDTH_NO_LABEL}}"
                                        class="cls-component-compose-input-top-risk-data">
                                    </ui-primeng-input-text>
                                </div>
                            </div>
                        </div>
                        <div class="field col-4">
                            <div class="cls-component-compose-input-top-risk">
                                <ui-primeng-label label="Perda de Controle"></ui-primeng-label>
                                <div class="cls-component-compose-input-top-risk-frame">
                                    <ui-primeng-dropdown label="" idField="fid-environment-log-g-choice" sizeInput="small"
                                        [control]="this.formTopRisk.controls.environment.controls.log_g.controls.choice"
                                        placeHolder="..." [itemsArray]="this.optionsRiskYesNotApplicable"
                                        withFilter="false" noLabelWidth="{{this.settingConfig.WIDTH_NO_LABEL}}"
                                        class="cls-component-compose-input-top-risk-choice">
                                    </ui-primeng-dropdown>
                                    <ui-primeng-input-text label="" idField="fid-environment-log-g-data"
                                        [control]="this.formTopRisk.controls.environment.controls.log_g.controls.data"
                                        sizeInput="small" letterCase="upper" maxCharacters="250" isDisabled="false"
                                        noLabelWidth="{{this.settingConfig.WIDTH_NO_LABEL}}"
                                        class="cls-component-compose-input-top-risk-data">
                                    </ui-primeng-input-text>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="formgrid grid">
                        <div class="field col-4">
                            <div class="cls-component-compose-input-top-risk">
                                <ui-primeng-label label="Pista deteriorada"></ui-primeng-label>
                                <div class="cls-component-compose-input-top-risk-frame">
                                    <ui-primeng-dropdown label="" idField="fid-environment-runway-deteriorated-choice" sizeInput="small"
                                        [control]="this.formTopRisk.controls.environment.controls.runway_deteriorated.controls.choice"
                                        placeHolder="..." [itemsArray]="this.optionsRiskYesNotApplicable"
                                        withFilter="false" noLabelWidth="{{this.settingConfig.WIDTH_NO_LABEL}}"
                                        class="cls-component-compose-input-top-risk-choice">
                                    </ui-primeng-dropdown>
                                    <ui-primeng-input-text label="" idField="fid-environment-runway-deteriorated-data"
                                        [control]="this.formTopRisk.controls.environment.controls.runway_deteriorated.controls.data"
                                        sizeInput="small" letterCase="upper" maxCharacters="250" isDisabled="false"
                                        noLabelWidth="{{this.settingConfig.WIDTH_NO_LABEL}}"
                                        class="cls-component-compose-input-top-risk-data">
                                    </ui-primeng-input-text>
                                </div>
                            </div>
                        </div>
                        <div class="field col-4">
                            <div class="cls-component-compose-input-top-risk">
                                <ui-primeng-label label="Contaminação de pista"></ui-primeng-label>
                                <div class="cls-component-compose-input-top-risk-frame">
                                    <ui-primeng-dropdown label="" idField="fid-environment-runway-contamination-choice" sizeInput="small"
                                        [control]="this.formTopRisk.controls.environment.controls.runway_contamination.controls.choice"
                                        placeHolder="..." [itemsArray]="this.optionsRiskYesNotApplicable"
                                        withFilter="false" noLabelWidth="{{this.settingConfig.WIDTH_NO_LABEL}}"
                                        class="cls-component-compose-input-top-risk-choice">
                                    </ui-primeng-dropdown>
                                    <ui-primeng-input-text label="" idField="fid-environment--runway-contamination-data"
                                        [control]="this.formTopRisk.controls.environment.controls.runway_contamination.controls.data"
                                        sizeInput="small" letterCase="upper" maxCharacters="250" isDisabled="false"
                                        noLabelWidth="{{this.settingConfig.WIDTH_NO_LABEL}}"
                                        class="cls-component-compose-input-top-risk-data">
                                    </ui-primeng-input-text>
                                </div>
                            </div>
                        </div>
                        <div class="field col-4">
                            <div class="cls-component-compose-input-top-risk">
                                <ui-primeng-label label="Heliporto restrições"></ui-primeng-label>
                                <div class="cls-component-compose-input-top-risk-frame">
                                    <ui-primeng-dropdown label="" idField="fid-environment-heliport-restriction-choice" sizeInput="small"
                                        [control]="this.formTopRisk.controls.environment.controls.heliport_restriction.controls.choice"
                                        placeHolder="..." [itemsArray]="this.optionsRiskYesNotApplicable"
                                        withFilter="false" noLabelWidth="{{this.settingConfig.WIDTH_NO_LABEL}}"
                                        class="cls-component-compose-input-top-risk-choice">
                                    </ui-primeng-dropdown>
                                    <ui-primeng-input-text label="" idField="fid-environment-heliport-restriction-data"
                                        [control]="this.formTopRisk.controls.environment.controls.heliport_restriction.controls.data"
                                        sizeInput="small" letterCase="upper" maxCharacters="250" isDisabled="false"
                                        noLabelWidth="{{this.settingConfig.WIDTH_NO_LABEL}}"
                                        class="cls-component-compose-input-top-risk-data">
                                    </ui-primeng-input-text>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="formgrid grid">
                        <div class="field col-4">
                            <div class="cls-component-compose-input-top-risk">
                                <ui-primeng-label label="Auxilio Inoperante"></ui-primeng-label>
                                <div class="cls-component-compose-input-top-risk-frame">
                                    <ui-primeng-dropdown label="" idField="fid-environment-approach-aids-choice" sizeInput="small"
                                        [control]="this.formTopRisk.controls.environment.controls.approach_aids.controls.choice"
                                        placeHolder="..." [itemsArray]="this.optionsRiskYesNotApplicable"
                                        withFilter="false" noLabelWidth="{{this.settingConfig.WIDTH_NO_LABEL}}"
                                        class="cls-component-compose-input-top-risk-choice">
                                    </ui-primeng-dropdown>
                                    <ui-primeng-input-text label="" idField="fid-environment-approach-aids-data"
                                        [control]="this.formTopRisk.controls.environment.controls.approach_aids.controls.data"
                                        sizeInput="small" letterCase="upper" maxCharacters="250" isDisabled="false"
                                        noLabelWidth="{{this.settingConfig.WIDTH_NO_LABEL}}"
                                        class="cls-component-compose-input-top-risk-data">
                                    </ui-primeng-input-text>
                                </div>
                            </div>
                        </div>
                        <div class="field col-4">
                            <div class="cls-component-compose-input-top-risk">
                                <ui-primeng-label label="(FOD) Objeto Estranho"></ui-primeng-label>
                                <div class="cls-component-compose-input-top-risk-frame">
                                    <ui-primeng-dropdown label="" idField="fid-environment-fod-choice" sizeInput="small"
                                        [control]="this.formTopRisk.controls.environment.controls.fod.controls.choice"
                                        placeHolder="..." [itemsArray]="this.optionsRiskYesNotApplicable"
                                        withFilter="false" noLabelWidth="{{this.settingConfig.WIDTH_NO_LABEL}}"
                                        class="cls-component-compose-input-top-risk-choice">
                                    </ui-primeng-dropdown>
                                    <ui-primeng-input-text label="" idField="fid-environment-fod-data"
                                        [control]="this.formTopRisk.controls.environment.controls.fod.controls.data"
                                        sizeInput="small" letterCase="upper" maxCharacters="250" isDisabled="false"
                                        noLabelWidth="{{this.settingConfig.WIDTH_NO_LABEL}}"
                                        class="cls-component-compose-input-top-risk-data">
                                    </ui-primeng-input-text>
                                </div>
                            </div>
                        </div>                        
                    </div>
                </div>
            </p-panel>
            <p-panel header="Espaço Aéreo" class="cls-component-panel-formgrid-sub-menu mt-2">
                <div class="cls-component-form"> 
                    <div class="formgrid grid">
                        <div class="field col-4">
                            <div class="cls-component-compose-input-top-risk">
                                <ui-primeng-label label="Area Restritas/Proibidas"></ui-primeng-label>
                                <div class="cls-component-compose-input-top-risk-frame">
                                    <ui-primeng-dropdown label="" idField="fid-environment-prohibited-restricted-area-choice" sizeInput="small"
                                        [control]="this.formTopRisk.controls.environment.controls.prohibited_restricted_area.controls.choice"
                                        placeHolder="..." [itemsArray]="this.optionsRiskYesNotApplicable"
                                        withFilter="false" noLabelWidth="{{this.settingConfig.WIDTH_NO_LABEL}}"
                                        class="cls-component-compose-input-top-risk-choice">
                                    </ui-primeng-dropdown>
                                    <ui-primeng-input-text label="" idField="fid-environment-prohibited-restricted-area-data"
                                        [control]="this.formTopRisk.controls.environment.controls.prohibited_restricted_area.controls.data"
                                        sizeInput="small" letterCase="upper" maxCharacters="250" isDisabled="false"
                                        noLabelWidth="{{this.settingConfig.WIDTH_NO_LABEL}}"
                                        class="cls-component-compose-input-top-risk-data">
                                    </ui-primeng-input-text>
                                </div>
                            </div>
                        </div>
                        <div class="field col-4">
                            <div class="cls-component-compose-input-top-risk">
                                <ui-primeng-label label="Serviço Indisponível"></ui-primeng-label>
                                <div class="cls-component-compose-input-top-risk-frame">
                                    <ui-primeng-dropdown label="" idField="fid-environment-services-unavailability-choice" sizeInput="small"
                                        [control]="this.formTopRisk.controls.environment.controls.services_unavailability.controls.choice"
                                        placeHolder="..." [itemsArray]="this.optionsRiskYesNotApplicable"
                                        withFilter="false" noLabelWidth="{{this.settingConfig.WIDTH_NO_LABEL}}"
                                        class="cls-component-compose-input-top-risk-choice">
                                    </ui-primeng-dropdown>
                                    <ui-primeng-input-text label="" idField="fid-environment-services-unavailability-data"
                                        [control]="this.formTopRisk.controls.environment.controls.services_unavailability.controls.data"
                                        sizeInput="small" letterCase="upper" maxCharacters="250" isDisabled="false"
                                        noLabelWidth="{{this.settingConfig.WIDTH_NO_LABEL}}"
                                        class="cls-component-compose-input-top-risk-data">
                                    </ui-primeng-input-text>
                                </div>
                            </div>
                        </div>
                        <div class="field col-4">
                            <div class="cls-component-compose-input-top-risk">
                                <ui-primeng-label label="Tráfego Aéreo Congestionado"></ui-primeng-label>
                                <div class="cls-component-compose-input-top-risk-frame">
                                    <ui-primeng-dropdown label="" idField="fid-environment-air-traffic-density-choice" sizeInput="small"
                                        [control]="this.formTopRisk.controls.environment.controls.air_traffic_density.controls.choice"
                                        placeHolder="..." [itemsArray]="this.optionsRiskYesNotApplicable"
                                        withFilter="false" noLabelWidth="{{this.settingConfig.WIDTH_NO_LABEL}}"
                                        class="cls-component-compose-input-top-risk-choice">
                                    </ui-primeng-dropdown>
                                    <ui-primeng-input-text label="" idField="fid-environment-air-traffic-density-data"
                                        [control]="this.formTopRisk.controls.environment.controls.air_traffic_density.controls.data"
                                        sizeInput="small" letterCase="upper" maxCharacters="250" isDisabled="false"
                                        noLabelWidth="{{this.settingConfig.WIDTH_NO_LABEL}}"
                                        class="cls-component-compose-input-top-risk-data">
                                    </ui-primeng-input-text>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="formgrid grid">
                        <div class="field col-4">
                            <div class="cls-component-compose-input-top-risk">
                                <ui-primeng-label label="Balão Junino"></ui-primeng-label>
                                <div class="cls-component-compose-input-top-risk-frame">
                                    <ui-primeng-dropdown label="" idField="fid-environment-balloons-area-choice" sizeInput="small"
                                        [control]="this.formTopRisk.controls.environment.controls.balloons.controls.choice"
                                        placeHolder="..." [itemsArray]="this.optionsRiskYesNotApplicable"
                                        withFilter="false" noLabelWidth="{{this.settingConfig.WIDTH_NO_LABEL}}"
                                        class="cls-component-compose-input-top-risk-choice">
                                    </ui-primeng-dropdown>
                                    <ui-primeng-input-text label="" idField="fid-environment-balloons-area-data"
                                        [control]="this.formTopRisk.controls.environment.controls.balloons.controls.data"
                                        sizeInput="small" letterCase="upper" maxCharacters="250" isDisabled="false"
                                        noLabelWidth="{{this.settingConfig.WIDTH_NO_LABEL}}"
                                        class="cls-component-compose-input-top-risk-data">
                                    </ui-primeng-input-text>
                                </div>
                            </div>
                        </div>                                               
                    </div>
                </div>
            </p-panel>
            <p-panel header="Voo Noturno" class="cls-component-panel-formgrid-sub-menu mt-2">
                <div class="cls-component-form">
                    <div class="formgrid grid">
                        <div class="field col-4">
                            <div class="cls-component-compose-input-top-risk">
                                <ui-primeng-label label="VFR Noturno"></ui-primeng-label>
                                <div class="cls-component-compose-input-top-risk-frame">
                                    <ui-primeng-dropdown label="" idField="fid-night-vfr-operations-choice" sizeInput="small"
                                        [control]="this.formTopRisk.controls.environment.controls.night_vfr_operation.controls.choice"
                                        placeHolder="..." [itemsArray]="this.optionsRiskYesNotApplicable"
                                        withFilter="false" noLabelWidth="{{this.settingConfig.WIDTH_NO_LABEL}}"
                                        class="cls-component-compose-input-top-risk-choice">
                                    </ui-primeng-dropdown>
                                    <ui-primeng-input-text label="" idField="fid-night-vfr-operations-data"
                                        [control]="this.formTopRisk.controls.environment.controls.night_vfr_operation.controls.data"
                                        sizeInput="small" letterCase="upper" maxCharacters="250" isDisabled="false"
                                        noLabelWidth="{{this.settingConfig.WIDTH_NO_LABEL}}"
                                        class="cls-component-compose-input-top-risk-data">
                                    </ui-primeng-input-text>
                                </div>
                            </div>
                        </div>
                        <div class="field col-4">
                            <div class="cls-component-compose-input-top-risk">
                                <ui-primeng-label label="Operação Monomotor Noturno"></ui-primeng-label>
                                <div class="cls-component-compose-input-top-risk-frame">
                                    <ui-primeng-dropdown label="" idField="fid-night-single-engine-choice" sizeInput="small"
                                        [control]="this.formTopRisk.controls.environment.controls.night_single_engine.controls.choice"
                                        placeHolder="..." [itemsArray]="this.optionsRiskYesNotApplicable"
                                        withFilter="false" noLabelWidth="{{this.settingConfig.WIDTH_NO_LABEL}}"
                                        class="cls-component-compose-input-top-risk-choice">
                                    </ui-primeng-dropdown>
                                    <ui-primeng-input-text label="" idField="fid-night-single-engine--data"
                                        [control]="this.formTopRisk.controls.environment.controls.night_single_engine.controls.data"
                                        sizeInput="small" letterCase="upper" maxCharacters="250" isDisabled="false"
                                        noLabelWidth="{{this.settingConfig.WIDTH_NO_LABEL}}"
                                        class="cls-component-compose-input-top-risk-data">
                                    </ui-primeng-input-text>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </p-panel>
        </p-fieldset>
        <p-fieldset legend="PRESSÕES EXTERNAS" class="cls-component-fieldset-formgrid">
            <p-panel header="Condições" class="cls-component-panel-formgrid-sub-menu">
                <div class="cls-component-form">
                    <div class="formgrid grid">
                        <div class="field col-4">
                            <div class="cls-component-compose-input-top-risk">
                                <ui-primeng-label label="Tempo Limitado"></ui-primeng-label>
                                <div class="cls-component-compose-input-top-risk-frame">
                                    <ui-primeng-dropdown label="" idField="fid-external-pressures-limited-time-choice" sizeInput="small"
                                        [control]="this.formTopRisk.controls.external_pressures.controls.limited_time.controls.choice"
                                        placeHolder="..." [itemsArray]="this.optionsRiskYesNotApplicable"
                                        withFilter="false" noLabelWidth="{{this.settingConfig.WIDTH_NO_LABEL}}"
                                        class="cls-component-compose-input-top-risk-choice">
                                    </ui-primeng-dropdown>
                                    <ui-primeng-input-text label="" idField="fid-external-pressures-limited-time-data"
                                        [control]="this.formTopRisk.controls.external_pressures.controls.limited_time.controls.data"
                                        sizeInput="small" letterCase="upper" maxCharacters="250" isDisabled="false"
                                        noLabelWidth="{{this.settingConfig.WIDTH_NO_LABEL}}"
                                        class="cls-component-compose-input-top-risk-data">
                                    </ui-primeng-input-text>
                                </div>
                            </div>
                        </div>
                        <div class="field col-4">
                            <div class="cls-component-compose-input-top-risk">
                                <ui-primeng-label label="Voo urgente"></ui-primeng-label>
                                <div class="cls-component-compose-input-top-risk-frame">
                                    <ui-primeng-dropdown label="" idField="fid-external-pressures-urgent-choice" sizeInput="small"
                                        [control]="this.formTopRisk.controls.external_pressures.controls.urgent.controls.choice"
                                        placeHolder="..." [itemsArray]="this.optionsRiskYesNotApplicable"
                                        withFilter="false" noLabelWidth="{{this.settingConfig.WIDTH_NO_LABEL}}"
                                        class="cls-component-compose-input-top-risk-choice">
                                    </ui-primeng-dropdown>
                                    <ui-primeng-input-text label="" idField="fid-external-pressures-urgent-data"
                                        [control]="this.formTopRisk.controls.external_pressures.controls.urgent.controls.data"
                                        sizeInput="small" letterCase="upper" maxCharacters="250" isDisabled="false"
                                        noLabelWidth="{{this.settingConfig.WIDTH_NO_LABEL}}"
                                        class="cls-component-compose-input-top-risk-data">
                                    </ui-primeng-input-text>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </p-panel>
        </p-fieldset>
        <p-fieldset legend="FILTRO OBSTÁCULOS" class="cls-component-fieldset-formgrid">
            <div class="cls-component-form">
                <div class="formgrid grid">
                    <div class="field col-12">
                        <ui-primeng-dropdown label="Ordenação do Resultado #BOLD#" idField="fid-sort_result"
                            placeHolder="" sizeInput="small"
                            [control]="this.formComponent.controls.filters.controls.obstacles.controls.sort_result_obstacles"
                            placeHolder="..." [itemsArray]="this.optionsObstaclesSortResult" withFilter="false"
                            noLabelWidth="{{this.settingConfig.WIDTH_NO_LABEL}}">
                        </ui-primeng-dropdown>
                    </div>
                </div>
            </div>
            <div class="cls-component-form">
                <div class="formgrid grid">
                    <div class="field col-4">
                        <ui-primeng-slider label="Raio de Busca #BOLD#"
                            [control]="this.formComponent.controls.filters.controls.obstacles.controls.obstacle_distance_nm"
                            idField="fid-filter-obstacles-distance-nm" maxValue="15" minValue="1" stepValue="0.5"
                            posLabelValue=" NM" noLabelWidth="{{this.settingConfig.WIDTH_NO_LABEL}}">
                        </ui-primeng-slider>
                    </div>
                    <div class="field col-4">
                        <ui-primeng-slider label="ALTURA Minima do Obstáculo #BOLD#"
                            [control]="this.formComponent.controls.filters.controls.obstacles.controls.obstacle_min_height_m"
                            idField="fid-filter-obstacles-height-m" maxValue="150" minValue="0" stepValue="1"
                            labelValue="" posLabelValue=" m" noLabelWidth="{{this.settingConfig.WIDTH_NO_LABEL}}">
                        </ui-primeng-slider>
                    </div>
                    <div class="field col-4">
                        <ui-primeng-slider label="ALTITUDE Minima do Obstáculo #BOLD#"
                            [control]="this.formComponent.controls.filters.controls.obstacles.controls.obstacle_min_altitude_ft"
                            idField="fid-filter-obstacles-altitude-ft" maxValue="150" minValue="0" stepValue="1"
                            labelValue="" posLabelValue=" ft" noLabelWidth="{{this.settingConfig.WIDTH_NO_LABEL}}">
                        </ui-primeng-slider>
                    </div>
                </div>
                <div class="formgrid grid">
                    <div class="field col-6">
                        <div class="cls-component-chip-risk-meteoro">
                            <ui-primeng-chips
                                [control]="this.formComponent.controls.filters.controls.obstacles.controls.aerodromes_icao"
                                label="Aeródromos ICAO" idField="fid-filter-obstacles-aerodromes-icao"
                                fieldLabel="label" noLabelWidth=this.settingConfig.WIDTH_NO_LABEL gDefaultControl>
                            </ui-primeng-chips>
                        </div>
                    </div>
                    <div class="field col-6">
                        <div class="cls-component-chip-risk-meteoro">
                            <ui-primeng-chips
                                [control]="this.formComponent.controls.filters.controls.obstacles.controls.coordinates"
                                label="Coordenadas (decimal)" idField="fid-filter-obstacles-coordinates"
                                noLabelWidth="{{this.settingConfig.WIDTH_NO_LABEL}}" gDefaultControl>
                            </ui-primeng-chips>
                        </div>
                    </div>
                </div>
                <div class="formgrid grid">
                    <div class="field col-12">
                        <ui-primeng-label label="OBSTÁCULOS #BOLD#"></ui-primeng-label>
                        <div class="formgrid grid">
                            <div class="field col-4">
                                <ui-primeng-checkbox
                                    [control]="this.formComponent.controls.filters.controls.obstacles.controls.itens.controls.all"
                                    label="TODOS" initialValue="true" idField="fid-filter-obstacles-itens-all">
                                </ui-primeng-checkbox>
                            </div>
                        </div>
                        <div class="formgrid grid">
                            <div class="field col-4">
                                <ui-primeng-checkbox
                                    [control]="this.formComponent.controls.filters.controls.obstacles.controls.itens.controls.ag_equip"
                                    label="Equipamento AG" initialValue="true"
                                    idField="fid-filter-obstacles-itens-ag_equip"></ui-primeng-checkbox>
                                <ui-primeng-checkbox
                                    [control]="this.formComponent.controls.filters.controls.obstacles.controls.itens.controls.antenna"
                                    label="Antena" initialValue="true" idField="fid-filter-obstacles-itens-antenna">
                                </ui-primeng-checkbox>
                                <ui-primeng-checkbox
                                    [control]="this.formComponent.controls.filters.controls.obstacles.controls.itens.controls.bridge"
                                    label="Ponte" initialValue="true" idField="fid-filter-obstacles-itens-bridge">
                                </ui-primeng-checkbox>
                                <ui-primeng-checkbox
                                    [control]="this.formComponent.controls.filters.controls.obstacles.controls.itens.controls.building"
                                    label="Construção" initialValue="true"
                                    idField="fid-filter-obstacles-itens-building"></ui-primeng-checkbox>
                                <ui-primeng-checkbox
                                    [control]="this.formComponent.controls.filters.controls.obstacles.controls.itens.controls.control_tower"
                                    label="Torre de Controle" initialValue="true" idField="fid-filter-obstacles-itens-">
                                </ui-primeng-checkbox>
                                <ui-primeng-checkbox
                                    [control]="this.formComponent.controls.filters.controls.obstacles.controls.itens.controls.crane"
                                    label="Guindaste" initialValue="true"
                                    idField="fid-filter-obstacles-itens-control_tower"></ui-primeng-checkbox>
                                <ui-primeng-checkbox
                                    [control]="this.formComponent.controls.filters.controls.obstacles.controls.itens.controls.dome"
                                    label="Cúpula" initialValue="true" idField="fid-filter-obstacles-itens-dome">
                                </ui-primeng-checkbox>
                                <ui-primeng-checkbox
                                    [control]="this.formComponent.controls.filters.controls.obstacles.controls.itens.controls.electrical_system"
                                    label="Sistema Elétrico" initialValue="true"
                                    idField="fid-filter-obstacles-itens-electrical_system"></ui-primeng-checkbox>
                                <ui-primeng-checkbox
                                    [control]="this.formComponent.controls.filters.controls.obstacles.controls.itens.controls.elevator"
                                    label="Elevador" initialValue="true" idField="fid-filter-obstacles-itens-elevator">
                                </ui-primeng-checkbox>
                                <ui-primeng-checkbox
                                    [control]="this.formComponent.controls.filters.controls.obstacles.controls.itens.controls.fence"
                                    label="Cerca" initialValue="true" idField="fid-filter-obstacles-itens-fence">
                                </ui-primeng-checkbox>
                                <ui-primeng-checkbox
                                    [control]="this.formComponent.controls.filters.controls.obstacles.controls.itens.controls.fuel_system"
                                    label="Sistema de Combustível" initialValue="true"
                                    idField="fid-filter-obstacles-itens-fuel_system"></ui-primeng-checkbox>
                                <ui-primeng-checkbox
                                    [control]="this.formComponent.controls.filters.controls.obstacles.controls.itens.controls.gate"
                                    label="Portão" initialValue="true" idField="fid-filter-obstacles-itens-gate">
                                </ui-primeng-checkbox>
                                <ui-primeng-checkbox
                                    [control]="this.formComponent.controls.filters.controls.obstacles.controls.itens.controls.grain_elevator"
                                    label="Elevador de Grãos" initialValue="true"
                                    idField="fid-filter-obstacles-itens-grain_elevator"></ui-primeng-checkbox>
                            </div>
                            <div class="field col-4">
                                <ui-primeng-checkbox
                                    [control]="this.formComponent.controls.filters.controls.obstacles.controls.itens.controls.industrial_system"
                                    label="Sistema Industrial" initialValue="true"
                                    idField="fid-filter-obstacles-itens-industrial_system"></ui-primeng-checkbox>
                                <ui-primeng-checkbox
                                    [control]="this.formComponent.controls.filters.controls.obstacles.controls.itens.controls.lighthouse"
                                    label="Farol" initialValue="true" idField="fid-filter-obstacles-itens-lighthouse">
                                </ui-primeng-checkbox>
                                <ui-primeng-checkbox
                                    [control]="this.formComponent.controls.filters.controls.obstacles.controls.itens.controls.monument"
                                    label="Monumento" initialValue="true" idField="fid-filter-obstacles-itens-monument">
                                </ui-primeng-checkbox>
                                <ui-primeng-checkbox
                                    [control]="this.formComponent.controls.filters.controls.obstacles.controls.itens.controls.natural_gas_system"
                                    label="Sistema Gás Natural" initialValue="true"
                                    idField="fid-filter-obstacles-itens-natural_gas_system"></ui-primeng-checkbox>
                                <ui-primeng-checkbox
                                    [control]="this.formComponent.controls.filters.controls.obstacles.controls.itens.controls.natural_highpoint"
                                    label="Relevo Alto" initialValue="true"
                                    idField="fid-filter-obstacles-itens-natural_highpoint"></ui-primeng-checkbox>
                                <ui-primeng-checkbox
                                    [control]="this.formComponent.controls.filters.controls.obstacles.controls.itens.controls.navaid"
                                    label="Auxilio Navegação" initialValue="true"
                                    idField="fid-filter-obstacles-itens-navaid"></ui-primeng-checkbox>
                                <ui-primeng-checkbox
                                    [control]="this.formComponent.controls.filters.controls.obstacles.controls.itens.controls.other"
                                    label="Outros" initialValue="true" idField="fid-filter-obstacles-itens-other">
                                </ui-primeng-checkbox>
                                <ui-primeng-checkbox
                                    [control]="this.formComponent.controls.filters.controls.obstacles.controls.itens.controls.pole"
                                    label="Polo" initialValue="true" idField="fid-filter-obstacles-itens-pole">
                                </ui-primeng-checkbox>
                                <ui-primeng-checkbox
                                    [control]="this.formComponent.controls.filters.controls.obstacles.controls.itens.controls.power_plant"
                                    label="Usina Elétrica" initialValue="true"
                                    idField="fid-filter-obstacles-itens-power_plant"></ui-primeng-checkbox>
                                <ui-primeng-checkbox
                                    [control]="this.formComponent.controls.filters.controls.obstacles.controls.itens.controls.rig"
                                    label="Equipamentos" initialValue="true" idField="fid-filter-obstacles-itens-rig">
                                </ui-primeng-checkbox>
                                <ui-primeng-checkbox
                                    [control]="this.formComponent.controls.filters.controls.obstacles.controls.itens.controls.sign"
                                    label="Sinal" initialValue="true" idField="fid-filter-obstacles-itens-sign">
                                </ui-primeng-checkbox>
                                <ui-primeng-checkbox
                                    [control]="this.formComponent.controls.filters.controls.obstacles.controls.itens.controls.spire"
                                    label="Torre de Construção" initialValue="true"
                                    idField="fid-filter-obstacles-itens-spire"></ui-primeng-checkbox>
                                <ui-primeng-checkbox
                                    [control]="this.formComponent.controls.filters.controls.obstacles.controls.itens.controls.stack"
                                    label="Monte" initialValue="true" idField="fid-filter-obstacles-itens-stack">
                                </ui-primeng-checkbox>

                            </div>
                            <div class="field col-4">
                                <ui-primeng-checkbox
                                    [control]="this.formComponent.controls.filters.controls.obstacles.controls.itens.controls.tank"
                                    label="Tanque" initialValue="true" idField="fid-filter-obstacles-itens-tank">
                                </ui-primeng-checkbox>
                                <ui-primeng-checkbox
                                    [control]="this.formComponent.controls.filters.controls.obstacles.controls.itens.controls.tethered_balloon"
                                    label="Balão Amarrado" initialValue="true"
                                    idField="fid-filter-obstacles-itens-tethered_balloon"></ui-primeng-checkbox>
                                <ui-primeng-checkbox
                                    [control]="this.formComponent.controls.filters.controls.obstacles.controls.itens.controls.tower"
                                    label="Torre" initialValue="true" idField="fid-filter-obstacles-itens-tower">
                                </ui-primeng-checkbox>
                                <ui-primeng-checkbox
                                    [control]="this.formComponent.controls.filters.controls.obstacles.controls.itens.controls.tramway"
                                    label="Bonde" initialValue="true" idField="fid-filter-obstacles-itens-tramway">
                                </ui-primeng-checkbox>
                                <ui-primeng-checkbox
                                    [control]="this.formComponent.controls.filters.controls.obstacles.controls.itens.controls.transmission_line"
                                    label="Linha Transmissão" initialValue="true"
                                    idField="fid-filter-obstacles-itens-transmission_line"></ui-primeng-checkbox>
                                <ui-primeng-checkbox
                                    [control]="this.formComponent.controls.filters.controls.obstacles.controls.itens.controls.tree"
                                    label="Árvore" initialValue="true" idField="fid-filter-obstacles-itens-tree">
                                </ui-primeng-checkbox>
                                <ui-primeng-checkbox
                                    [control]="this.formComponent.controls.filters.controls.obstacles.controls.itens.controls.urban"
                                    label="Urbanização" initialValue="true" idField="fid-filter-obstacles-itens-urban">
                                </ui-primeng-checkbox>
                                <ui-primeng-checkbox
                                    [control]="this.formComponent.controls.filters.controls.obstacles.controls.itens.controls.vegetation"
                                    label="Vegetação" initialValue="true"
                                    idField="fid-filter-obstacles-itens-vegetation"></ui-primeng-checkbox>
                                <ui-primeng-checkbox
                                    [control]="this.formComponent.controls.filters.controls.obstacles.controls.itens.controls.wall"
                                    label="Muro" initialValue="true" idField="fid-filter-obstacles-itens-wall">
                                </ui-primeng-checkbox>
                                <ui-primeng-checkbox
                                    [control]="this.formComponent.controls.filters.controls.obstacles.controls.itens.controls.wastewater_system"
                                    label="Sistema de Água" initialValue="true"
                                    idField="fid-filter-obstacles-itens-wastewater_system"></ui-primeng-checkbox>
                                <ui-primeng-checkbox
                                    [control]="this.formComponent.controls.filters.controls.obstacles.controls.itens.controls.water_tower"
                                    label="Caixa d' Água" initialValue="true"
                                    idField="fid-filter-obstacles-itens-water_tower"></ui-primeng-checkbox>
                                <ui-primeng-checkbox
                                    [control]="this.formComponent.controls.filters.controls.obstacles.controls.itens.controls.windmill"
                                    label="Moinho de Vento" initialValue="true"
                                    idField="fid-filter-obstacles-itens-windmill"></ui-primeng-checkbox>
                                <ui-primeng-checkbox
                                    [control]="this.formComponent.controls.filters.controls.obstacles.controls.itens.controls.windmill_farm"
                                    label="Fazenda de Moinho" initialValue="true"
                                    idField="fid-filter-obstacles-itens-windmill_farm"></ui-primeng-checkbox>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </p-fieldset>
    </p-panel>
</section>