<section id="id-dialog-owner-operator" class="cls-component-dialog-control">
    <p-dialog [(visible)]="this.showDialogOwnerOperator" [modal]="true" [showHeader]="true" [closable]="false"
        [style]="{height:'80vh', width: '75vw'}" class="cls-component-dialog-modal">
        <ng-template pTemplate="header">
            <h2 class="cls-component-dialog-modal-title">Cadastrar Novo {{this.labelButtonType}}</h2>
        </ng-template>
        <ng-template pTemplate="content">
            <p-panel header="Identificação" class="cls-component-panel-formgrid">
                <div class="cls-component-form">
                    <div class="formgrid grid">
                        <div class="col-12 md:col-6 lg:col-1">
                            <ui-primeng-input-switch idField="fid-aircraft-owner-operator-is-company"
                                [control]="this.formComponent.controls.is_company" label="&nbsp;"
                                iconLeft="fa-solid fa-user" iconRight="fa-solid fa-industry"
                                noLabelWidth="{{this.pageInnerWidth}}">
                            </ui-primeng-input-switch>

                        </div>
                        <div class="col-12 md:col-6 lg:col-3">
                            <ui-primeng-dropdown-template-flag label="País de Origem #BOLD#"
                                idField="fid-aircraft-owner-operator-country" [control]="this.formComponent.controls.country"
                                [itemsArray]="this.optionsCountryRegistration" withFilter="false" placeHolder="..."
                                sizeInput="small" noLabelWidth="{{this.pageInnerWidth}}">
                            </ui-primeng-dropdown-template-flag>
                        </div>
                        <ng-container *ngIf="this.formComponent.controls.is_company.value === true">
                            <div class="col-12 md:col-12 lg:col-2">
                                <ui-primeng-input-text idField="fid-aircraft-owner-operator-ni"
                                    [control]="this.formComponent.controls.ni" letterCase="upper" sizeInput="small"
                                    maxCharacters="25" label="CNPJ #BOLD#" noLabelWidth="{{this.pageInnerWidth}}">
                                </ui-primeng-input-text>
                            </div>
                            <div class="col-12 md:col-12 lg:col-6">
                                <ui-primeng-input-text idField="fid-aircraft-owner-operator-name"
                                    [control]="this.formComponent.controls.name" letterCase="upper" sizeInput="small"
                                    maxCharacters="150" label="Nome #BOLD#" noLabelWidth="{{this.pageInnerWidth}}">
                                </ui-primeng-input-text>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="this.formComponent.controls.is_company.value === false">
                            <div class="col-12 md:col-12 lg:col-2">
                                <ui-primeng-input-text idField="fid-aircraft-owner-operator-ni"
                                    [control]="this.formComponent.controls.ni" letterCase="upper" sizeInput="small"
                                    maxCharacters="25" label="CPF #BOLD#" noLabelWidth="{{this.pageInnerWidth}}">
                                </ui-primeng-input-text>
                            </div>
                            <div class="col-12 md:col-12 lg:col-6">
                                <ui-primeng-input-text idField="fid-aircraft-owner-operator-name"
                                    [control]="this.formComponent.controls.name" letterCase="upper" sizeInput="small"
                                    maxCharacters="150" label="Nome #BOLD#" noLabelWidth="{{this.pageInnerWidth}}">
                                </ui-primeng-input-text>
                            </div>
                        </ng-container>
                    </div>
                    <div class="formgrid grid">
                        <ng-container *ngIf="this.formComponent.controls.is_company.value === true">
                            <div class="col-12 md:col-4 lg:col-4">
                                <ui-primeng-input-text idField="fid-aircraft-owner-operator-responsible-name"
                                    [control]="this.formComponent.controls.responsible.controls.name"
                                    maxCharacters="150" sizeInput="small" label="Nome Responsável" letterCase="upper"
                                    noLabelWidth="{{this.pageInnerWidth}}">
                                </ui-primeng-input-text>
                            </div>
                            <div class="col-12 md:col-4 lg:col-4">
                                <div class="cls-component-phone-input">
                                    <div class="cls-component-phone-input-label">
                                        <ui-primeng-label label="Celular/WhatsApp Responsável"></ui-primeng-label>
                                    </div>
                                    <div class="cls-component-phone-input-phone">
                                        <ui-primeng-dropdown-template-ddi label="" idField="fid-responsible-phone-ddi"
                                            placeHolder="" sizeInput="small"
                                            [control]="this.formComponent.controls.responsible.controls.contact.controls.ddi"
                                            placeHolder="..." [itemsArray]="null" withFilter="false"
                                            noLabelWidth="{{this.settingConfig.WIDTH_NO_LABEL}}"
                                            class="cls-component-phone-input-phone-ddi">
                                        </ui-primeng-dropdown-template-ddi>
                                        <ui-primeng-input-text idField="fid-responsible-phone-ddd"
                                            [control]="this.formComponent.controls.responsible.controls.contact.controls.ddd"
                                            maxCharacters="5" sizeInput="small" label=""
                                            noLabelWidth="{{this.pageInnerWidth}}"
                                            class="cls-component-phone-input-phone-ddd">
                                        </ui-primeng-input-text>
                                        <ui-primeng-input-text idField="fid-responsible-phone-number"
                                            [control]="this.formComponent.controls.responsible.controls.contact.controls.number"
                                            maxCharacters="20" sizeInput="small" label=""
                                            noLabelWidth="{{this.pageInnerWidth}}"
                                            class="cls-component-phone-input-phone-number">
                                        </ui-primeng-input-text>
                                    </div>
                                </div>

                            </div>
                            <div class="col-12 md:col-8 lg:col-4">
                                <ui-primeng-input-text idField="fid-aircraft-owner-operator-observation"
                                    [control]="this.formComponent.controls.obs_owner_operator" numRows="1"
                                    maxCharacters="500" sizeInput="small" letterCase="upper"
                                    label="Observação Identificação" noLabelWidth="{{this.pageInnerWidth}}">
                                </ui-primeng-input-text>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="this.formComponent.controls.is_company.value === false">
                            <div class="col-12 md:col-12 lg:col-12">
                                <ui-primeng-input-text idField="fid-aircraft-owner-operator-observation"
                                    [control]="this.formComponent.controls.obs_owner_operator" numRows="1"
                                    maxCharacters="500" sizeInput="small" label="Observação Identificação"
                                    noLabelWidth="{{this.pageInnerWidth}}">
                                </ui-primeng-input-text>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </p-panel>
            <p-panel header="Endereço" class="cls-component-panel-formgrid">
                <div class="cls-component-form">
                    <div class="formgrid grid">
                        <div class="col-12 md:col-12 lg:col-12">
                            <nashville-address-handler 
                                [arrayAddressData]="this.formComponent.controls.address.value"
                                (callBackAddressAction)="this.callbackSetArrayAddress($event)">                                
                            </nashville-address-handler>
                        </div>
                    </div>
                </div>
            </p-panel>
            <p-panel header="Contatos" class="cls-component-panel-formgrid">
                <div class="cls-component-form">
                    <div class="formgrid grid">
                        <div class="col-12 md:col-12 lg:col-12">
                            <nashville-contact-handler 
                                [arrayContactData]="this.formComponent.controls.contacts.value"
                                (callBackContactAction)="this.callbackSetArrayContact($event)">
                            </nashville-contact-handler>
                        </div>
                    </div>
                </div>
            </p-panel>
        </ng-template>
        <ng-template pTemplate="footer">
            <nashville-toolbar-dialog-owner-operator 
                (dialogButtonAction)="returnCallBackDialogButton($event)"
                [formComponent]="this.formComponent" 
                [pageInnerWidth]="this.innerWidth"
                [registerType]="this.labelButtonType">
            </nashville-toolbar-dialog-owner-operator>
        </ng-template>
    </p-dialog>
</section>