<div class="cls-ui-primeng-field">
    <div class="field" [ngClass]="{'cls-field-wrong': this.showErrors() }">
        <ng-container *ngIf="this.showLabel">
            <label class="cls-field-label"
                [ngClass]="{'cls-label-bold': this.getShowLabelBold(), 'cls-field-wrong': this.showErrors() }"
                for="{{ this.idField }}">
                {{ this.label }}
                <ng-container *ngIf="this.showToolTipIcon">
                    <i [pTooltip]="tooltipContent" [showDelay]="500" [hideDelay]="300"
                        class="fa-solid fa-comment-dots ml-1"></i>
                </ng-container>
            </label>
            <ng-template #tooltipContent>
                <div class="flex align-items-center">
                    <img src="https://primefaces.org/cdn/primeng/images/primeng.svg" height="20" class="mr-2" />
                    <span> {{ this.getTooTipContent() }} </span>
                </div>
            </ng-template>
        </ng-container>      
            <p-calendar 
                [showIcon]="this.getShowIcon()"            
                [iconDisplay]="'input'"
                [formControl]="this.control"
                [showClear]=true
                [minDate]="this.valMinData" 
                [maxDate]="this.valMaxDate"
                (onClear)="callbackClearItem()"
                (onSelect)="callbackSelectedItem($event)"
                readonlyInput=true
                showButtonBar=true
                placeholder={{this.getPlaceHolder()}}
                dateFormat={{this.getFormatDate()}}                        
                id="{{ this.idField }}"   
                name="{{ this.getFieldName() }}"
                styleClass="w-full"
                pInputText> 
            </p-calendar>               
    </div>
    <ui-primeng-error-message-controls class="cls-pmg-field-error-message" [control]="this.control" typeControl="input">
    </ui-primeng-error-message-controls>
</div>