//#region "|--- INTERFACE ---|"
import { IDropDownBasicOptions } from '../../interfaces/IDropDownBasicOptions';
//#endregion

/**
 * TODO: https://tsdoc.org/
 */
export const riskYesNotApplicableOptions: IDropDownBasicOptions[] = [
    { label: 'SIM', value: 'yes' },
    { label: 'NÃO', value: 'no' }
]