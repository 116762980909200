<section id="id-map-navigation" class="cls-component-extra-content-flight-data">
    <div class="grid">
        <div class="col-6">
            <div class="cls-component-extra-content-flight-data-input-frame">
                <p-toolbar class="cls-component-toolbar-control-form-pattern">
                    <div class="p-toolbar-group-start">
                    </div>
                    <div class="p-toolbar-group-center"></div>
                    <div class="p-toolbar-group-end">
                        <div class="cls-page-box-right">
                            <p-button label="NOVO" icon="fa-regular fa-floppy-disk" [raised]="true"
                                severity="secondary" (click)="newExtraData()"
                                class="cls-component-button-pattern-default">
                            </p-button>
                        </div>
                        <ng-container *ngIf="this.indexElementToEdit === null">
                            <div class="cls-page-box-right">
                                <p-button label="SALVAR NOVO" icon="fa-regular fa-floppy-disk" [raised]="true"
                                    severity="secondary" (click)="saveNewExtraData()"
                                    class="cls-component-button-pattern-default">
                                </p-button>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="this.indexElementToEdit !== null">
                            <div class="cls-page-box-right">
                                <p-button label="SALVAR EDIÇÃO" icon="fa-regular fa-floppy-disk" [raised]="true"
                                    severity="secondary" (click)="saveEditExtraData()"
                                    class="cls-component-button-pattern-default">
                                </p-button>
                            </div>
                        </ng-container>
                    </div>
                </p-toolbar>
                <div class="formgrid grid">
                    <div class="col-12 cls-component-extra-content-flight-data-field">
                        <ui-primeng-checkbox idField="fid-only-most-recent"
                            [control]="this.formExtraMessageForm.controls.new_page" label="Inserir em Nova Página">
                        </ui-primeng-checkbox>
                    </div>
                    <div class="col-12 cls-component-extra-content-flight-data-field">
                        <ui-primeng-input-text label="Título Tópico #BOLD#" idField="fid-topic-title"
                            [control]="this.formExtraMessageForm.controls.topic_title" sizeInput="small"
                            letterCase="upper">
                        </ui-primeng-input-text>
                    </div>
                    <div class="col-12">
                        <ng-container *ngIf="this.formExtraMessageForm.controls.image_url.value === null">
                            <nashville-upload-file (onSuccess)="onSuccessAircraftImageUpload($event)"
                                (onError)="onErrorAircraftImageUpload($event)"
                                (onImageError)="onErrorAircraftImage($event)" [isMultiFiles]="false"
                                [fieldData]="this.dataInfo" buttonLabel="Buscar Imagem" fileName="image-upload"
                                urlUpload="{{this.serverUrl}}/mod_fp/flight/plan/extra/data/image">
                            </nashville-upload-file>
                        </ng-container>
                        <ng-container *ngIf="this.formExtraMessageForm.controls.image_url.value !== null">
                            <div class="cls-component-aircraft-avatar-image-frame">
                                <img src="{{this.serverUrl}}/mod_fp/flight/plan/extra/data/image/{{this.formRequest.controls.flight_request_id.getRawValue()}}?url={{this.formExtraMessageForm.controls.image_url.getRawValue()}}"
                                    alt="">
                                <div class="cls-component-aircraft-avatar-image-frame-frame-button-delete">
                                    <p-button label="APAGAR IMAGEM" icon="fa-solid fa-trash-can"
                                        class="cls-component-aircraft-avatar-image-button-delete" size="small"
                                        severity="danger" (click)="removeImage()" />
                                </div>
                            </div>
                        </ng-container>

                    </div>
                    <div class="col-12 cls-component-extra-content-flight-data-field">
                        <ui-primeng-text-area label="Texto" idField="fid-text-content" [rowsSize]="3"
                            [control]="this.formExtraMessageForm.controls.text_content" sizeInput="small"
                            letterCase="upper" isDisabled="false">
                        </ui-primeng-text-area>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-6">
            <p-table [value]="this.arrayExtraData" [reorderableColumns]="true"
                class="p-datatable-sm cls-component-extra-content-flight-data-table" scrollHeight="32rem"
                (onRowReorder)="onRowReorder($event)">
                <ng-template pTemplate="header">
                    <tr>
                        <th>Conteúdos Anexos</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-extraData let-rowIndex="rowIndex">
                    <tr [pReorderableRow]="rowIndex">
                        <td>
                            <div class="cls-component-extra-content-flight-data-row">
                                <div class="cls-component-extra-content-flight-data-row-content">
                                    <div class="cls-component-extra-content-flight-data-row-title">
                                        <span class="fa-solid fa-arrows-up-down" pReorderableRowHandle></span> {{
                                            extraData.topic_title
                                        }}
                                    </div>
                                    <div class="cls-component-extra-content-flight-data-row-others">
                                        <div class="cls-component-extra-content-flight-data-row-image">
                                            <ng-container
                                                *ngIf="extraData.image_url !== null && extraData.image_url !==''">
                                                <p-image
                                                    src="{{this.serverUrl}}/mod_fp/flight/plan/extra/data/image/{{this.formRequest.controls.flight_request_id.getRawValue()}}?url={{extraData.image_url}}"
                                                    alt="Image" width="100" [preview]="true" />
                                            </ng-container>
                                        </div>
                                        <div class="cls-component-extra-content-flight-data-row-text-content">
                                            {{
                                                extraData.text_content
                                            }}
                                        </div>
                                    </div>
                                </div>
                                <ng-container *ngIf="extraData.new_page">
                                    <div class="cls-component-extra-content-flight-data-row-new-page">
                                        Conteúdo em Nova Página.
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="extraData.new_page === false">
                                    <div class="cls-component-extra-content-flight-data-row-new-page === false">
                                        Conteúdo na Mesma Página Anterior.
                                    </div>
                                </ng-container>
                                <div class="cls-component-extra-content-flight-data-row-buttons">
                                    <p-button icon="fa-solid fa-pencil" [rounded]="true" severity="info"
                                        (click)="editExtraData(rowIndex)" />
                                    <p-button icon="fa-solid fa-trash-can" [rounded]="true" severity="danger"
                                        (click)="deleteExtraData(rowIndex)" />
                                </div>
                            </div>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
    <p-confirmDialog />
</section>