//#region "|--- IMPORT MODULES/PACKAGES ---|"
// ***** ANGULAR *****
import { Component, OnInit, Input, Output, EventEmitter, HostListener } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, FormGroup, UntypedFormControl } from '@angular/forms';

// ***** NASHVILLE *****
import { settingConfig } from '@nashville-config';
import { PrimengComponentsModule } from '@nashville-primeng';
//#endregion

//#region "|--- IMPORT INTERFACES ---|"
import { IDropDownTemplateIconOptions } from 'src/app/interfaces/IDropDownTemplateIconOptions';
import { IDropDownTemplateCountryOptions } from 'src/app/interfaces/IDropDownTemplateCountryOptions';
//#endregion

//#region "|--- OPTIONS ---|"
import { countryOptions } from 'src/app/helpers/dropdown-static-options/countryOptions';
//#endregion

//#region "|--- IMPORT SERVICES ---|"
import { ConfirmationService } from 'primeng/api';
//#endregion

@Component({
  selector: 'nashville-contact-handler',
  standalone: true,
  imports: [
    CommonModule,
    PrimengComponentsModule
  ],
  templateUrl: './contact_handler.component.html',
  styleUrl: './contact_handler.component.scss',
})
export class ContactHandlerComponent implements OnInit {
  //#region "|--- INPUTS ---|"
  @Input() pageInnerWidth!: any;
  @Input() arrayContactData!: any;
  //#endregion

  //#region "|--- OUTPUTS ---|"
  @Output() callBackContactAction = new EventEmitter<any>();
  //#endregion

  //#region "|--- PROPERTIES---|"
  // ***** CONFIG *****
  innerWidth?: any;
  settingConfig!: any;

  // ***** FORMS *****
  formContact!: any;

  // ***** OPTIONS DROPDOWN *****
  optionsContactType!: IDropDownTemplateIconOptions[];
  optionsCountryType!: IDropDownTemplateCountryOptions[];

  // ***** SHOW DIALOG *****
  showDialogFormContact?: boolean;

  // ***** OTHER *****
  currentIndexUpdate: string | null;
  //#endregion

  constructor(
    private confirmationService: ConfirmationService,
  ) { }

  ngOnInit(): void {
    this._initVariables();

    this._initAddressTypeOptions();
    this._initFormContact();

    setTimeout(() => {
      this._initEventContactForm();
    }, 10);
  }

  //#region "|--- HOST LISTENER ---|"
  /**
   * @type HOST LISTENER
   * @version 1.0.0 (GASPAR - 11/06/2024)
   * - Versão inicial.
   * 
   * @description 
   * - Executada toda vez que ocorre REDIMENSIONAMENTO da Tela.
   * - https://www.w3schools.com/jsref/dom_obj_event.asp
   * 
   * @param event 
   */
  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    this.innerWidth = window.innerWidth;
  }
  //#endregion

  //#region "|--- INIT FORM METHODS ---|"
  /**
     * @status OK
     * @type METHOD
     * @version 1.0.0 (GASPAR - 11/06/2024)
     * - Versão inicial.
     * 
     * @description 
     * - Inicializa o FORM de Endereço.
     */
  private _initFormContact() {
    this.formContact = new FormGroup({
      type: new UntypedFormControl(null),
      phone: new FormGroup({
        ddi: new FormControl<string>('+55'),
        ddd: new UntypedFormControl(null),
        number: new UntypedFormControl(null),
        normalized_text: new UntypedFormControl(null),
        is_whatsapp: new FormControl<boolean>(false),
        is_telegram: new FormControl<boolean>(false),
      }),
      facebook: new UntypedFormControl(null),
      instagram: new UntypedFormControl(null),
      email: new UntypedFormControl(null),
      obs_contact: new UntypedFormControl(null)
    });
  }
  //#endregion

  //#region "|--- PRIVATE METHODS---|"
  private _initVariables(): void {
    this.showDialogFormContact = false;
    this.settingConfig = settingConfig;
    this.optionsCountryType = countryOptions;
    this.currentIndexUpdate = null;
  }

  private _initEventContactForm() {
    this.formContact.get('type').valueChanges.subscribe((xValue: any) => {
      this._clearContact(false);
    });
  }

  private _initAddressTypeOptions() {
    this.optionsContactType = [
      {
        label: "Celular",
        value: "mobile",
        icon: "fa-solid fa-mobile-screen-button"
      },
      {
        label: "Telefone Fixo",
        value: "phone",
        icon: "fa-solid fa-phone"
      },
      {
        label: "E-mail",
        value: "email",
        icon: "fa-regular fa-envelope"
      },
      {
        label: "Facebook",
        value: "facebook",
        icon: "fa-brands fa-facebook"
      },
      {
        label: "Instagram",
        value: "instagram",
        icon: "fa-brands fa-instagram"
      }
    ];
  }

  private _clearContact(xClearType: boolean = true) {
    if (xClearType) {
      this.formContact.controls.type.setValue(null);
    }

    this.formContact.controls.phone.controls.ddi.setValue('+55');
    this.formContact.controls.phone.controls.ddd.setValue(null);
    this.formContact.controls.phone.controls.number.setValue(null);
    this.formContact.controls.phone.controls.normalized_text.setValue(null);
    this.formContact.controls.phone.controls.is_whatsapp.setValue(false);
    this.formContact.controls.phone.controls.is_telegram.setValue(false);
    this.formContact.controls.facebook.setValue(null);
    this.formContact.controls.instagram.setValue(null);
    this.formContact.controls.email.setValue(null);
    this.formContact.controls.obs_contact.setValue(null);
  }
  //#endregion

  //#region "|--- PUBLIC METHODS ---|"
  callbackShowDialog() {
    this.showDialogFormContact = true;
  }

  saveDataContact() {
    this.arrayContactData.push(this.formContact.getRawValue());
    this.callBackContactAction.emit(this.arrayContactData);
    this.showDialogFormContact = false;
    this._clearContact();
  }

  saveEditContact() {
    this.callBackContactAction.emit(this.arrayContactData);
  }

  editContact(xIndexToUpdate: number) {

  }

  closeDialogContact() {
    this.showDialogFormContact = false;
    this._clearContact();
  }

  deleteContact(xIndexToDelete: number) {
    this.confirmationService.confirm({
      target: event.target as EventTarget,
      message: `Você deseja apagar endereço.`,
      header: 'Confirmação para apagar dados.',
      icon: 'pi pi-exclamation-triangle',
      acceptIcon: "none",
      rejectIcon: "none",
      acceptLabel: "CONFIRMAR",
      rejectLabel: "CANCELAR",
      acceptButtonStyleClass: "cls-component-button-pattern-default ml-2",
      rejectButtonStyleClass: "cls-component-button-pattern-default",
      accept: () => {
        // Quando for do tipo BOTH, não pode apagar o item do array, mas apenas definir qual tipo vai ser. As Tabelas serão atualizadas automaticamente.
        this.arrayContactData.splice(xIndexToDelete, 1);
        this.callBackContactAction.emit(this.arrayContactData);
      },
      reject: () => {

      }
    });
  }

  getTypeName(xType: string) {
    if (xType == "mobile") {
      return "<i class='fa-solid fa-mobile-screen-button'></i> CELULAR";
    } else if (xType == "phone") {
      return "<i class='fa-solid fa-phone'></i> TELEFONE";
    } else if (xType == "email") {
      return "<i class='fa-regular fa-envelope'></i> E-MAIL";
    } else if (xType == "facebook") {
      return "<i class='fa-brands fa-facebook'></i> FACEBOOK";
    } else if (xType == "instagram") {
      return "<i class='fa-brands fa-instagram'></i> INSTAGRAM";
    } else {
      return "";
    }
  }
  //#endregion
}
