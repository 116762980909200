// $$$$$$$$$$ REVISED $$$$$$$$$$

//#region "|--- IMPORT MODULES/PACKAGES ---|"
// ***** ANGULAR *****
import { Component, OnInit, Input, Output, EventEmitter, HostListener } from '@angular/core';
import { CommonModule } from '@angular/common';

// ***** NPM *****
import { PrimengComponentsModule } from '@nashville-primeng';
//#endregion

//#region "|--- IMPORT COMPONENTS ---|"
import { ShowAerodromeDataPdfComponent } from '../show-aerodrome-data-pdf/show-aerodrome-data-pdf.component';
import { ShowFlightDataPdfComponent } from '../show-flight-data-pdf/show-flight-data-pdf.component';
import { ShowMeteorologyDataPdfComponent } from '../show-meteorology-data-pdf/show-meteorology-data-pdf.component';
import { ShowTopRiskDataPdfComponent } from '../show-top-risk-data-pdf/show-top-risk-data-pdf.component';
import { ToolbarPanelContentPdfComponent } from '../toolbar-panel-content-pdf/toolbar-panel-content-pdf.component';
//#endregion

@Component({
  selector:
    'nashville-panel-content-pdf',
  standalone: true,
  imports: [
    CommonModule,
    ShowAerodromeDataPdfComponent,
    ShowFlightDataPdfComponent,
    ShowMeteorologyDataPdfComponent,
    ShowTopRiskDataPdfComponent,
    PrimengComponentsModule,
    ToolbarPanelContentPdfComponent
  ],
  templateUrl: './panel-content-pdf.component.html',
  styleUrl: './panel-content-pdf.component.scss',
})
export class PanelContentPdfComponent implements OnInit {
  //#region "|--- INPUTS ---|"
  // (IPT) Input
  // (PCP) Panel Content Pdf
  @Input() iptPcpFormRequest!: any;

  // É feito o set do @Input para que seja capturado qualquer modificação, em tempo real, 
  // do parâmetro que está sendo enviado pelo PARENT e, principalmente, que seja feito o 
  // processamento necessário com base no novo valor.
  @Input() set iptPcpFlightPlanContent(xValue: any) {
    this.flightPlanContentData = xValue;
  };
  //#endregion

  //#region "|--- OUTPUTS ---|"
  // (OTT) Output
  // (PCP) Panel Content Pdf
  @Output() ottPcpActionPdfButtons = new EventEmitter<any>();
  @Output() ottPcpActionExtraFlightData = new EventEmitter<any>();
  //#endregion

  //#region "|--- PROPERTIES---|" 
  // ***** PRIVATE *****
  _innerWidth!: any;

  // ***** CONFIG *****  
  collapseAllPanels: boolean;

  // ***** OTHERS *****
  flightPlanContentData: any;
  //#endregion

  ngOnInit(): void {
    this._initVariables();
  }

  //#region "|--- HOST LISTENER ---|"
  /**
   * @type HOST LISTENER
   * @version 1.0.0 (GASPAR - 11/06/2024)
   * - Versão inicial.
   * 
   * @description 
   * - Executada toda vez que ocorre REDIMENSIONAMENTO da Tela.
   * - https://www.w3schools.com/jsref/dom_obj_event.asp
   * 
   * @param event 
   */
  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    this._innerWidth = window.innerWidth;
  }
  //#endregion

  //#region "|--- CALLBACK METHODS---|"
  /**
   * @type CALLBACK
   * @version 1.0.0 (GASPAR - 13/06/2024)
   * - Versão inicial.
   * 
   * @description 
   * - Faz o tratamento dos eventos relacionados aos botões do Toolbar.
   * 
   * @param xValue 
   */
  callbackToolbarPanelButton(xValue: any) {    
    this.ottPcpActionPdfButtons.emit(xValue);
  }

  /**
   * @type CALLBACK
   * @version 1.0.0 (GASPAR - 13/06/2024)
   * - Versão inicial.
   * 
   * @description 
   * - 
   * 
   * @param xValue 
   */
  callbackExtraFlightData(xValue: any) {
    this.ottPcpActionExtraFlightData.emit(xValue);
  }
  //#endregion

  //#region "|--- PRIVATE METHODS---|"
  /**
   * @status OK
   * @type METHOD
   * @version 1.0.0 (GASPAR - 13/06/2024)
   * - Versão inicial.
   * 
   * @description 
   * - Inicializa as variáveis do processo.
   */
  private _initVariables(): void {
    this._innerWidth = window.innerWidth;
    this.collapseAllPanels = true;
  }
  //#endregion

  //#region "|--- PUBLIC METHODS---|"
  /**
   * @status OK
   * @type METHOD
   * @version 1.0.0 (GASPAR - 13/06/2024)
   * - Versão inicial.
   * 
   * @description 
   * - Expand ou colapsa todos os painéis que mostram os dados que compõem a documentação do Plano de Voo.
   */
  collapseExpandAllPanels(xAction: string) {
    if (xAction == "collapse") {
      this.collapseAllPanels = true;
    } else {
      this.collapseAllPanels = false;
    }
  }
  //#endregion
}