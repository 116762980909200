import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { LOCALE_ID } from '@angular/core';

// Configurações Regionais
import { APP_BASE_HREF,registerLocaleData } from '@angular/common';
import localeptBR from '@angular/common/locales/br';
import localeptExtra from '@angular/common/locales/extra/br';


import { UserMenuService } from './services/user-menu/user-menu.service'; 
import { UserConfigService } from './services/user-config/user-config.service';

registerLocaleData(localeptBR, localeptExtra);

@Component({
  standalone: true,
  imports: [
    CommonModule,
    RouterModule
  ],
  selector: 'nashville-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  providers: [
    {
      provide: LOCALE_ID, useValue: 'pt-BR'
    },
    UserMenuService,
    UserConfigService
  ] // ao registrar um provedor no nível do componente, você obtém uma nova instância do serviço com cada nova instância desse componente
})
export class AppComponent implements OnInit{
  title = 'nashville-ftd-web-client-portal-angular';

  constructor(
    private userMenuService: UserMenuService,
    private userConfigService: UserConfigService
    ) {}

  ngOnInit(): void {
    
  }

  
}
