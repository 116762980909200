<article id="id-aircraft-data-form" class="cls-page-frame">
    <!-- TOAST-->
    <nashville-page-toast [objToastMessage]="this.objToastMessage"></nashville-page-toast>
    <!-- TITLE -->
    <nashville-page-title pageTitle="{{this.currentPageTitle}}"></nashville-page-title>
    <!-- CONTENT -->
    <div class="cls-page-frame-content">
        <div class="grid">
            <div class="lg:col-12">
                <p-tabView class="cls-component-page-tabview">
                    <p-tabPanel>
                        <ng-template pTemplate="header">
                            <i class="fa-regular fa-file-lines"></i>
                            <span class="cls-component-page-tabview-tabpainel-legend">Registro</span>
                        </ng-template>
                        <ng-template pTemplate="content">
                            <div class="cls-component-page-tabview-content-frame">
                                <!-- PAGE TOOLBAR -->
                                <nashville-toolbar-tabpanel-aero-registration [formComponent]="this.formAircraftData"
                                    [pageInnerWidth]="this.innerWidth"
                                    (toolbarAction)="callBackToolbarAeroRegistration($event)">
                                </nashville-toolbar-tabpanel-aero-registration>
                                <div class="grid cls-remove-margin-grid">
                                    <div class="col-9">
                                        <nashville-panel-aero-registration
                                            (showToastMessage)="callBackShowToast($event)"
                                            (buttonsOwnerOperatorAction)="callbackShowDialogOwnerOperator($event)"
                                            [pageInnerWidth]="this.innerWidth" [formComponent]="this.formAircraftData">
                                        </nashville-panel-aero-registration>
                                    </div>
                                    <div class="col-3">
                                        <nashville-panel-image-color-avatar
                                            (showToastMessage)="callBackShowToast($event)"
                                            [pageInnerWidth]="this.innerWidth" [formComponent]="this.formAircraftData">
                                        </nashville-panel-image-color-avatar>
                                    </div>
                                </div>

                                <!-- <div class="cls-page-frame-adjust-margin-grid">
                                     AERONAUTICAL REGISTRATION 
                                    <nashville-tab-view-aero-registration
                                        [formComponent]="this.formAircraftData"
                                        [pageInnerWidth]="this.innerWidth"
                                        (tableButtonAction)="returnCallBackTableButton($event)">
                                    </nashville-tab-view-aero-registration>
                                </div>-->
                            </div>
                        </ng-template>
                    </p-tabPanel>
                    <ng-container *ngIf="this.formAircraftData.controls.aircraft_data_id.value === 'teste'">
                        <p-tabPanel>
                            <ng-template pTemplate="header">
                                <i class="fa-solid fa-book"></i>
                                <span class="cls-component-page-tabview-tabpainel-legend">Documentação</span>
                            </ng-template>
                            <ng-template pTemplate="content">
                                <nashville-aircraft-data-document [pageInnerWidth]="this.innerWidth"
                                    [formComponent]="this.formAircraftData">
                                </nashville-aircraft-data-document>
                            </ng-template>
                        </p-tabPanel>
                        <p-tabPanel>
                            <ng-template pTemplate="header">
                                <i class="fa-solid fa-tags"></i>
                                <span class="cls-component-page-tabview-tabpainel-legend">Especificações</span>
                            </ng-template>
                            <ng-template pTemplate="content">
                                <nashville-aircraft-data-specification [pageInnerWidth]="this.innerWidth"
                                    [formComponent]="this.formAircraftData">
                                </nashville-aircraft-data-specification>
                            </ng-template>
                        </p-tabPanel>
                        <p-tabPanel>
                            <ng-template pTemplate="header">
                                <i class="fa-solid fa-satellite-dish"></i>
                                <span class="cls-component-page-tabview-tabpainel-legend">Equipamentos</span>
                            </ng-template>
                            <ng-template pTemplate="content">
                                <nashville-aircraft-data-equipment [pageInnerWidth]="this.innerWidth"
                                    [formComponent]="this.formAircraftData">
                                </nashville-aircraft-data-equipment>
                            </ng-template>
                        </p-tabPanel>
                        <p-tabPanel>
                            <ng-template pTemplate="header">
                                <i class="fa-solid fa-dumbbell"></i>
                                <span class="cls-component-page-tabview-tabpainel-legend">Peso & Balanceamento</span>
                            </ng-template>
                            <ng-template pTemplate="content">
                                <nashville-aircraft-data-weight-and-balance [pageInnerWidth]="this.innerWidth"
                                    [formComponent]="this.formAircraftData">
                                </nashville-aircraft-data-weight-and-balance>
                            </ng-template>
                        </p-tabPanel>
                        <p-tabPanel>
                            <ng-template pTemplate="header">
                                <i class="fa-solid fa-jet-fighter"></i>
                                <span class="cls-component-page-tabview-tabpainel-legend">Performance</span>
                            </ng-template>
                            <ng-template pTemplate="content">
                                <nashville-aircraft-data-performance [pageInnerWidth]="this.innerWidth"
                                    [formComponent]="this.formAircraftData">
                                </nashville-aircraft-data-performance>
                            </ng-template>
                        </p-tabPanel>
                    </ng-container>
                </p-tabView>
            </div>
        </div>
    </div>
    <nashville-dialog-aircraft-data-owner-operator 
        (dialogAction)="callBackDialogOwnerOperatorAction($event)"
        [pageInnerWidth]="this.innerWidth" 
        [formComponent]="this.formOwnerOperator"
        [typeDialog]="this.typeDialogOwnersOperators" 
        [showDialog]="this.showDialogOwnerOperator"
        [indexToShow]="this.indexArrayOwnersOperatorsToLoad">
    </nashville-dialog-aircraft-data-owner-operator>
    <!-- CONFIRM DIALOG -->
    <p-confirmDialog />
    <section>
        <p-dialog [modal]="true" [(visible)]="this.showDialogWaitingAircraftData"
            [style]="{ width: '45vw', height: '45vh' }" class="cls-component-dialog-waiting-process">
            <ng-template pTemplate="header"></ng-template>
            <ng-template pTemplate="content">
                <div class="cls-component-dialog-waiting-process">
                    <div class="cls-component-dialog-waiting-process-image">
                        <img src="assets/imgs/waiting_process_04.gif" alt="">
                    </div>
                    <div class="cls-component-dialog-waiting-process-label">
                        O SISTEMA ESTA CONSULTANDO OS DADOS DA AERONAVE JUNTO A AUTORIDADE LOCAL. <br>
                        AGUARDE UM MOMENTO, POR FAVOR...
                    </div>
                </div>
            </ng-template>
        </p-dialog>
    </section>
</article>

<!--<div class="cls-form">
        <p-toolbar>
            <div class="p-toolbar-group-start">
            </div>
            <div class="p-toolbar-group-center">
            </div>
            <div class="p-toolbar-group-end">
                <button pButton icon="pi pi-search" (click)="this.submitForGetAllAircraftData()" class="mr-2"></button>
                <button pButton icon="pi pi-plus" (click)="this.submitForAircraftData()" class="mr-2"></button>
            </div>
        </p-toolbar>
        <div class="grid">
            <div class="col-6" style="background-color: aqua;">
                <!-- INFORMAÇÕES AERONAVE --
                <p-panel header="Informações da Aeronave" class="cls-panel-formgrid">
                    <div class="grid">
                        <div class="col-9">
                            <div class="formgrid grid">
                                <!-- TYPE AIRCRAFT --
                                <div class="col-4">
                                    <ui-primeng-dropdown label="Tipo Aeronave #BOLD#" idField="fid-type-aircraft"
                                        placeHolder="TESTANDO 123" sizeInput="small"
                                        [control]="this.formAircraftData.controls.type_aircraft"
                                        [itemsArray]="this.optionsTypeAircraft" toolTip="Testando Tooltip"
                                        withFilter="false"></ui-primeng-dropdown>
                                </div>
                                <!-- MARK --
                                <div class="col-4">
                                    <ui-primeng-input-text label="Registro #BOLD#" idField="fid-aircraft-mark"
                                        [control]="this.formAircraftData.controls.mark" sizeInput="small"
                                        letterCase="upper" maxCharacters="7" isDisabled="false"
                                        toolTip="Testando Tooltip" noLabelWidth="576"></ui-primeng-input-text>
                                </div>
                                <!-- ICAO CODE --
                                <div class="col-4">
                                    <ui-primeng-input-text label="ICAO Code #BOLD#" idField="fid-icao-coder"
                                        [control]="this.formAircraftData.controls.registration_data.controls.icao_code"
                                        sizeInput="small" letterCase="upper" placeHolder="testando 123"
                                        isDisabled="false" noLabelWidth="576"></ui-primeng-input-text>
                                </div>
                            </div>
                            <div class="formgrid grid">
                                <!-- MANUFACTURER --
                                <div class="col-4">
                                    <ui-primeng-input-text label="Fabricante #BOLD#" idField="fid-manufacturer"
                                        [control]="this.formAircraftData.controls.registration_data.controls.manufacturer"
                                        sizeInput="small" letterCase="upper" placeHolder="testando 123"
                                        isDisabled="false" noLabelWidth="576"></ui-primeng-input-text>
                                </div>
                                <!-- MODEL --
                                <div class="col-4">

                                    <ui-primeng-input-text label="Modelo #BOLD#" idField="fid-model"
                                        [control]="this.formAircraftData.controls.registration_data.controls.model"
                                        sizeInput="small" letterCase="upper" placeHolder="testando 123"
                                        isDisabled="false" noLabelWidth="576"></ui-primeng-input-text>
                                </div>
                                <!-- SERIAL NUMBER --
                                <div class="col-4">
                                    <ui-primeng-input-text label="Número Serial #BOLD#" idField="fid-serial-number"
                                        [control]="this.formAircraftData.controls.registration_data.controls.serial_number"
                                        sizeInput="small" letterCase="upper" placeHolder="testando 123"
                                        isDisabled="false" noLabelWidth="576"></ui-primeng-input-text>
                                </div>
                            </div>
                            <div class="formgrid grid">
                                <!-- YEAR MANUFACTURED --
                                <div class="col-3">
                                    <ui-primeng-input-text label="Ano Fabricação #BOLD#" idField="fid-year-manufactured"
                                        [control]="this.formAircraftData.controls.registration_data.controls.year_manufactured"
                                        sizeInput="small" letterCase="upper" placeHolder="testando 123"
                                        isDisabled="false" noLabelWidth="576"></ui-primeng-input-text>
                                </div>
                                <!-- FLIGHT RULES --
                                <div class="col-3">
                                    <ui-primeng-dropdown label="Regras de Voo #BOLD#" idField="fid-flight-rules"
                                        placeHolder="TESTANDO 123" sizeInput="small"
                                        [control]="this.formAircraftData.controls.registration_data.controls.flight_rules"
                                        [itemsArray]="this.optionsFlightRules" toolTip="Testando Tooltip"
                                        withFilter="false"></ui-primeng-dropdown>
                                </div>                                
                                <!-- FIN NUMBER --
                                <div class="col-3">
                                    <ui-primeng-input-text label="Número FIN" idField="fid-fin-number"
                                        [control]="this.formAircraftData.controls.registration_data.controls.fin_number"
                                        sizeInput="small" letterCase="upper" placeHolder="testando 123"
                                        isDisabled="false" noLabelWidth="576"></ui-primeng-input-text>
                                </div>
                                <!-- AIRCRAFT NAME --
                                <div class="col-3">
                                    <ui-primeng-input-text label="Nome Aeronave" idField="fid-aircraft-name"
                                        [control]="this.formAircraftData.controls.registration_data.controls.aircraft_name"
                                        sizeInput="small" letterCase="upper" placeHolder="testando 123"
                                        isDisabled="false" noLabelWidth="576"></ui-primeng-input-text>
                                </div>
                            </div>
                            <div class="formgrid grid">
                                <!-- OBSERVATION --
                                <div class="col-12">
                                    <ui-primeng-input-text label="Observação" idField="fid-obs-aircraft-data"
                                        [control]="this.formAircraftData.controls.registration_data.controls.obs_aircraft_data"
                                        sizeInput="small" letterCase="upper" placeHolder="testando 123"
                                        isDisabled="false" noLabelWidth="576"></ui-primeng-input-text>
                                </div>
                            </div>
                        </div>
                        <div class="col-3">
                            <div class="cls-avatar-image">
                                <div class="field">
                                    <ui-primeng-label label="Imagem Aeronave"></ui-primeng-label>
                                    <div class="cls-avatar-image-frame">
                                        <img src="assets/imgs/no_image_available.jpg" alt="">
                                    </div>
                                </div>                                                                
                            </div>
                            <div class="cls-avatar-color">
                                <ui-primeng-color-picker
                                    label="Cor no Sistema"
                                    idField="fid-avatar-color"
                                    [control]="this.formAircraftData.controls.avatar_color"
                                ></ui-primeng-color-picker>
                            </div> 
                        </div>
                    </div>
                </p-panel>                
                <p-panel header="Performance Aeronave" class="cls-panel-formgrid">
                    <div class="formgrid grid">
                        <div class="field col-2">
                            <label for="firstname2">Regra de Voo</label>
                            <input id="firstname2" type="text"
                                class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
                        </div>
                        <div class="field col-2">
                            <label for="firstname2">Performance</label>
                            <input id="firstname2" type="text"
                                class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
                        </div>
                        <div class="field col-2">
                            <label for="firstname2">Cat. Esteira Turbulência</label>
                            <input id="firstname2" type="text"
                                class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
                        </div>
                        <div class="field col-2">
                            <label for="firstname2">Velocidade Cruzeiro</label>
                            <input id="firstname2" type="text"
                                class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
                        </div>
                        <div class="field col-2">
                            <label for="firstname2">Nível de Cruzeiro</label>
                            <input id="firstname2" type="text"
                                class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
                        </div>
                        <div class="field col-2">
                            <label for="firstname2">Teto de Serviço</label>
                            <input id="firstname2" type="text"
                                class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
                        </div>
                        <div class="field col-2">
                            <label for="firstname2">Minimo Pista</label>
                            <input id="firstname2" type="text"
                                class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
                        </div>
                        <div class="field col-2">
                            <label for="firstname2">Máxima distância</label>
                            <input id="firstname2" type="text"
                                class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
                        </div>
                        <div class="field col-2">
                            <label for="firstname2">Tripulantes</label>
                            <input id="firstname2" type="text"
                                class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
                        </div>
                    </div>
                </p-panel>
                <p-panel header="Pesos Aeronave" class="cls-panel-formgrid">
                    <div class="formgrid grid">
                        <div class="field col-2">
                            <label for="firstname2">Máximo Passageiros</label>
                            <input id="firstname2" type="text"
                                class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
                        </div>
                        <div class="field col-2">
                            <label for="firstname2">Passageiro Adulto Peso</label>
                            <input id="firstname2" type="text"
                                class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
                        </div>
                        <div class="field col-2">
                            <label for="firstname2">Passageiro Criança Peso</label>
                            <input id="firstname2" type="text"
                                class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
                        </div>
                        <div class="field col-2">
                            <label for="firstname2">Bagagem Peso</label>
                            <input id="firstname2" type="text"
                                class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
                        </div>
                        <div class="field col-2">
                            <label for="firstname2">Empty Weight</label>
                            <input id="firstname2" type="text"
                                class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
                        </div>
                        <div class="field col-2">
                            <label for="firstname2">Max Zero Fuel Weight</label>
                            <input id="firstname2" type="text"
                                class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
                        </div>
                        <div class="field col-2">
                            <label for="firstname2">Max Takeoff Weight</label>
                            <input id="firstname2" type="text"
                                class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
                        </div>
                        <div class="field col-2">
                            <label for="firstname2">Max Landing Weight</label>
                            <input id="firstname2" type="text"
                                class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
                        </div>
                        <div class="field col-2">
                            <label for="firstname2">Max Fuel Capacity</label>
                            <input id="firstname2" type="text"
                                class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
                        </div>
                        <div class="field col-2">
                            <label for="firstname2">Max Cargo Weight</label>
                            <input id="firstname2" type="text"
                                class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
                        </div>
                    </div>
                </p-panel>
                <p-panel header="Equipamentos" class="cls-panel-formgrid">

                </p-panel>
            </div>
            <div class="col-6" style="background-color: bisque">
                <p-panel header="Proprietários" class="cls-panel-formgrid">
                    <section>
                        <p-table [value]="arrayOwnersOperators" [tableStyle]="{ 'min-width': '50rem' }">
                            <ng-template pTemplate="caption">
                                <div class="flex align-items-center justify-content-between">
                                    PROPRIETÁRIOS
                                    <p-button icon="pi pi-plus" (click)="this.showDialogOwners()"></p-button>
                                </div>
                            </ng-template>
                            <ng-template pTemplate="header">
                                <tr>
                                    <th>Code</th>
                                    <th>Name</th>
                                    <th>Category</th>
                                    <th>Quantity</th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-product>
                                <tr>
                                    <td>{{ product.code }}</td>
                                    <td>{{ product.name }}</td>
                                    <td>{{ product.category }}</td>
                                    <td>{{ product.quantity }}</td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </section>
                </p-panel>
                <p-panel header="Operadores" class="cls-panel-formgrid">
                    <section>
                        <p-table [value]="arrayOwnersOperators" [tableStyle]="{ 'min-width': '50rem' }">
                            <ng-template pTemplate="caption">
                                <div class="flex align-items-center justify-content-between">
                                    OPERADORES
                                    <p-button icon="pi pi-plus" (click)="this.showDialogOperators()"></p-button>
                                </div>
                            </ng-template>
                            <ng-template pTemplate="header">
                                <tr>
                                    <th>Code</th>
                                    <th>Name</th>
                                    <th>Category</th>
                                    <th>Quantity</th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-product>
                                <tr>
                                    <td>{{ product.code }}</td>
                                    <td>{{ product.name }}</td>
                                    <td>{{ product.category }}</td>
                                    <td>{{ product.quantity }}</td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </section>
                </p-panel>
                DIREITA
                {{ this.formAircraftData.value | json }}
            </div>
        </div>
    </div>
    <section>
        <p-dialog header="Header" [modal]="true" [(visible)]="this.showDialogOwnerOperator" [showHeader]="false" [style]="{height: '82vh', width: '75vw'}">
            <ng-template pTemplate="content">
                <p-panel header="Proprietários" class="cls-panel-formgrid">
                    <div class="formgrid grid">
                    </div>
                </p-panel>
            </ng-template>
        </p-dialog>
    </section>-->