//#region "|--- IMPORT MODULES/PACKAGES ---|"
// ***** ANGULAR *****
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, FormGroup, UntypedFormControl } from '@angular/forms';

// ***** NPM *****
import HTTP_STATUS from 'http-status-codes';

// ***** NASHVILLE *****
import { settingConfig } from '@nashville-config';
import { PrimengComponentsModule } from '@nashville-primeng';
//#endregion

//#region "|--- IMPORT INTERFACES ---|"
import { IToastParameters } from '../../../../interfaces/IToastParameters';
//#endregion

//#region "|--- IMPORT SERVICES ---|"
import { ConfirmationService } from 'primeng/api';
//#endregion

//#region "|--- INTERFACES ---|"
interface IExtraDate {
    new_page: boolean,
    topic_title: string,
    text_content: string,
    image_url: string  
}
//#endregion

@Component({
  selector: 'nashville-extra-content',
  standalone: true,
  imports: [
    CommonModule,
    PrimengComponentsModule
  ],
  templateUrl: './extra-content.component.html',
  styleUrl: './extra-content.component.scss',
})
export class ExtraContentComponent implements OnInit {
  //#region "|--- INPUTS ---|" 
  @Input() formRequest!: any;
  @Input() arrayExtraData!: any;
  @Input() topicExtraData!: any;
  @Input() dataInfo!: any;
  //#endregion

  //#region "|--- OUTPUTS ---|"
  @Output() resultUploadImage = new EventEmitter<any>();
  //#endregion

  //#region "|--- PROPERTIES---|"
  innerWidth?: any;
  settingConfig!: any;
  serverUrl: any;

  formExtraMessageForm: any;

  indexElementToEdit: any;

  objToastMessage: IToastParameters;
  //#endregion

  constructor(
    private confirmationService: ConfirmationService,
  ) { }

  ngOnInit(): void {
    this._initVariables();
    this._initExtraMessageForm();
  }

  private _initVariables() {
    this.indexElementToEdit = null;

    if (settingConfig.API_FLIGHT_PLAN_PORT == "80") {
      this.serverUrl = `${settingConfig.API_PROTOCOL}://${settingConfig.API_HOST}/${settingConfig.API_PATH}`
    } else {
      this.serverUrl = `${settingConfig.API_PROTOCOL}://${settingConfig.API_HOST}:${settingConfig.API_FLIGHT_PLAN_PORT}/${settingConfig.API_PATH}`
    }
  }


  /**
   * TODO: https://tsdoc.org/
   */
  onSuccessAircraftImageUpload(xEvent: any) {
    if (xEvent.status == HTTP_STATUS.OK) {
      if (xEvent.body && xEvent.body.data_info) {
        this.formExtraMessageForm.controls.image_url.setValue(xEvent.body.data_info.path);

        this.resultUploadImage.emit({
          code_status: HTTP_STATUS.OK,
          action: "upload_image",
          message: "Success Upload Image"
        })
      } else {
        this.formExtraMessageForm.controls.image_url.setValue(null);

        this.resultUploadImage.emit({
          code_status: HTTP_STATUS.BAD_REQUEST,
          action: "upload_image",
          message: ""
        })
      }
    } else {
      this.formExtraMessageForm.controls.image_url.setValue(null);

      this.resultUploadImage.emit({
        code_status: HTTP_STATUS.INTERNAL_SERVER_ERROR,
        action: "upload_image",
        message: ""
      })
    }
  }

  /**
   * TODO: https://tsdoc.org/
   */
  onErrorAircraftImageUpload(xEvent: any) {
    if (xEvent.error) {
      if (JSON.stringify(xEvent).indexOf('404') >= 0) {
        this.formExtraMessageForm.controls.image_url.setValue(null);

        this.resultUploadImage.emit({
          code_status: HTTP_STATUS.NOT_FOUND,
          action: "upload_image",
          message: "A URL de upload da imagem não foi encontrado."
        })
      } else if (JSON.stringify(xEvent).indexOf('Unknown Error') >= 0) {
        this.formExtraMessageForm.controls.image_url.setValue(null);

        this.resultUploadImage.emit({
          code_status: HTTP_STATUS.BAD_REQUEST,
          action: "upload_image",
          message: "Houve um erro de Upload da Imagem. \n\n\t DETALHES: (${xEvent.message})"
        })
      }
    } else {
      this.formExtraMessageForm.controls.image_url.setValue(null);

      this.resultUploadImage.emit({
        code_status: HTTP_STATUS.INTERNAL_SERVER_ERROR,
        action: "upload_image",
        message: `Houve um erro inesperado. \n\n\t DETALHES: (${xEvent.message})`
      })
    }
  }

  /**
   * TODO: https://tsdoc.org/
   */
  onErrorAircraftImage(event: any) {
    console.log("CCCCCCCCCCCCCCCCC", event);
  }

  private _initExtraMessageForm() {
    this.formExtraMessageForm! = new FormGroup({
      new_page: new FormControl<boolean>(true),
      topic_title: new FormControl<string>(''),
      text_content: new FormControl<string>(''),
      image_url: new UntypedFormControl(null)
    });
  }

  deleteExtraData(xElementIndex: number) {
    this.confirmationService.confirm({
      target: event.target as EventTarget,
      message: `Você deseja apagar os dados?`, //${labelTypeOwnerOperator}: ${tempItemToDelete.ni} - ${tempItemToDelete.name}? <br><br> <strong>OBS: Este registro continua existindo como ${labelOppositeTypeOwnerOperator}.</strong>`,
      header: 'Confirmação para apagar dados.',
      icon: 'pi pi-exclamation-triangle',
      acceptIcon: "none",
      rejectIcon: "none",
      acceptLabel: "CONFIRMAR",
      rejectLabel: "CANCELAR",
      acceptButtonStyleClass: "cls-component-button-pattern-default ml-2",
      rejectButtonStyleClass: "cls-component-button-pattern-default",
      accept: () => {
        // Quando for do tipo BOTH, não pode apagar o item do array, mas apenas definir qual tipo vai ser. As Tabelas serão atualizadas automaticamente.
        this.arrayExtraData.splice(xElementIndex, 1);

        this.resultUploadImage.emit({
          code_status: HTTP_STATUS.OK,
          action: "delete_extra_data",
          message: `Success to delete extra date.`
        })
      },
      reject: () => {
        this.resultUploadImage.emit({
          code_status: HTTP_STATUS.BAD_REQUEST,
          action: "delete_extra_data",
          message: `Error delete extra date`
        })
      }
    });
  }

  onRowReorder(xEvent: any){
    this.resultUploadImage.emit({
      code_status: HTTP_STATUS.OK,
      action: "reorder_extra_data",
      message: `Success to reorder.`
    })
  }

  editExtraData(xElementIndex: number) {
    this.indexElementToEdit = xElementIndex;

    this.formExtraMessageForm.controls.new_page.setValue(this.arrayExtraData[xElementIndex].new_page);
    this.formExtraMessageForm.controls.topic_title.setValue(this.arrayExtraData[xElementIndex].topic_title);
    this.formExtraMessageForm.controls.text_content.setValue(this.arrayExtraData[xElementIndex].text_content);
    this.formExtraMessageForm.controls.image_url.setValue(this.arrayExtraData[xElementIndex].image_url);
  }

  newExtraData() {
    this.formExtraMessageForm.controls.new_page.setValue(true);
    this.formExtraMessageForm.controls.topic_title.setValue(null);
    this.formExtraMessageForm.controls.text_content.setValue(null);
    this.formExtraMessageForm.controls.image_url.setValue(null);

    this.indexElementToEdit = null;
  }

  saveNewExtraData() {
    this.arrayExtraData.push(this.formExtraMessageForm.getRawValue());

    this.formExtraMessageForm.controls.new_page.setValue(true);
    this.formExtraMessageForm.controls.topic_title.setValue(null);
    this.formExtraMessageForm.controls.text_content.setValue(null);
    this.formExtraMessageForm.controls.image_url.setValue(null);

    this.indexElementToEdit = null;

    this.resultUploadImage.emit({
      code_status: HTTP_STATUS.CREATED,
      action: "save_extra_data",
      message: `Success to save extra date.`
    })
  }

  removeImage(){
    console.log("ENTROUUUUUUUUUUUUUUUU")
    this.formExtraMessageForm.controls.image_url.setValue(null);
  }

  saveEditExtraData() {
    this.arrayExtraData[this.indexElementToEdit] = this.formExtraMessageForm.getRawValue();

    this.formExtraMessageForm.controls.new_page.setValue(true);
    this.formExtraMessageForm.controls.topic_title.setValue(null);
    this.formExtraMessageForm.controls.text_content.setValue(null);
    this.formExtraMessageForm.controls.image_url.setValue(null);

    this.indexElementToEdit = null;

    this.resultUploadImage.emit({
      code_status: HTTP_STATUS.CREATED,
      action: "save_extra_data",
      message: `Success to save extra date.`
    })
  }




  /**
   * @type METHOD.
   * @version 1.0.0 (GASPAR - 27/06/2024).
  .
   * 
   * @description 
   * - Método que mostra a mensagem no Toast.
   * 
   * @param xObjMessage IToastParameters
   * - Objeto contendo os dados necessários para mostrar os dados em um Toast. 
   */
  private async _showToast(xObjMessage: IToastParameters) {
    this.objToastMessage = null; // Tem que forçar a mudança para que o componente registre a alteração do valor da propriedade.
    this.objToastMessage = xObjMessage;
  }
  //#endregion
}
