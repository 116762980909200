//#region "|--- IMPORT MODULES/PACKAGES ---|"
import { FormArray, FormGroup, ValidationErrors } from '@angular/forms'

import { v4 as uuidv4 } from 'uuid';
import { NIL as NIL_UUID } from 'uuid';

import * as geolib from 'geolib';
//#endregion

//#region "|--- INTERFACE ---|"
import { IAerodromeBasicData } from 'src/app/interfaces/IAerodromeBasicData';
//#endregion

/**
 * TODO: https://tsdoc.org/
 */
export class LocalMethodsHandlerClass {

    /**
     * TODO: https://tsdoc.org/
     */
    static generateHexColor(): string {
        const tmpLetters = "0123456789ABCDEF";
        let tmpColor = '#';

        for (let i = 0; i < 6; i++) {
            tmpColor += tmpLetters[(Math.floor(Math.random() * 16))];
        }

        return tmpColor;
    }

    /**
     * TODO: https://tsdoc.org/
     */
    static processTimeStampToDateTimeFormat(xDateTimeStamp: Date, isZulu: boolean = true): string {
        let tempCurrentDate: any;
        let tempCurrentMonth: any;
        let tempCurrentYear: any;
        let tempCurrentHour: any;
        let tempCurrentMinute: any;

        if (isZulu) {
            tempCurrentDate = `${xDateTimeStamp.getUTCDate()}`.padStart(2, '0');
            tempCurrentMonth = `${(xDateTimeStamp.getUTCMonth() + 1)}`.padStart(2, '0');
            tempCurrentYear = `${xDateTimeStamp.getUTCFullYear()}`;
            tempCurrentHour = `${xDateTimeStamp.getUTCHours()}`.padStart(2, '0');
            tempCurrentMinute = `${xDateTimeStamp.getUTCMinutes()}`.padStart(2, '0');
        } else {
            tempCurrentDate = `${xDateTimeStamp.getDate()}`.padStart(2, '0');
            tempCurrentMonth = `${(xDateTimeStamp.getMonth() + 1)}`.padStart(2, '0');
            tempCurrentYear = `${xDateTimeStamp.getFullYear()}`;
            tempCurrentHour = `${xDateTimeStamp.getHours()}`.padStart(2, '0');
            tempCurrentMinute = `${xDateTimeStamp.getMinutes()}`.padStart(2, '0');
        }

        return `${tempCurrentDate}/${tempCurrentMonth}/${tempCurrentYear} - ${tempCurrentHour}:${tempCurrentMinute} ${isZulu ? "(Z)" : ""}`;
    }

    /**
     * TODO: https://tsdoc.org/
     */
    static initAerodromeBasicData(): IAerodromeBasicData {
        return {
            aerodrome_basic_data_id: "",
            fk_aerodrome_complete_data_id: "",
            aerodrome_type: "",
            ciad: "",
            icao_code: "",
            iata_code: "",
            name: "",
            coordinate_label: [],
            coordinate_dd: [],
            coordinate_gms: [],
            coordinate_plan: "",
            coordinate_geo: {
                location: {
                    type: "Point",
                    coordinates: []
                }
            },
            place: {
                country_code: '',
                state: '',
                city: ''
            },
            handling_data: {
                source: "",
                font: ""
            }
        }
    }

    /**
     * TODO: https://tsdoc.org/
     */
    static coordinateArrayDdToLabel(xArrayCoordinateDd: any[]): string[] {
        let tempLatNumber = 0;
        let tempLngNumber = 0;

        if (typeof xArrayCoordinateDd[0] == "number") {
            tempLatNumber = xArrayCoordinateDd[0];
        } else {
            tempLatNumber = parseFloat(xArrayCoordinateDd[0]);
        }

        if (typeof xArrayCoordinateDd[1] == "number") {
            tempLngNumber = xArrayCoordinateDd[1];
        } else {
            tempLngNumber = parseFloat(xArrayCoordinateDd[1]);
        }

        let returnCoordinatePlan = "";
        let tmpCoordinateLat = geolib.decimalToSexagesimal(tempLatNumber);
        let tmpCoordinateLng = geolib.decimalToSexagesimal(tempLngNumber);
        let tmpCoordinateLatGraus = "";
        let tmpCoordinateLatMinuto = "";
        let tmpCoordinateLatSegundos = "";
        let tmpCoordinateLngGraus = "";
        let tmpCoordinateLngMinuto = "";
        let tmpCoordinateLngSegundos = "";

        tmpCoordinateLat = tmpCoordinateLat.replaceAll("°", "");  // Retirar o Simbolo de grau (°)
        tmpCoordinateLat = tmpCoordinateLat.replaceAll("'", "");  // Retirar o Simbolo de minutos (')
        tmpCoordinateLat = tmpCoordinateLat.replaceAll('"', "");  // Retirar o Simbolo de segundos (")
        tmpCoordinateLat = tmpCoordinateLat.replaceAll(".0", ""); // Retirar as Casas Decimais (")

        tmpCoordinateLng = tmpCoordinateLng.replaceAll("°", "");  // Retirar o Simbolo de grau (°)
        tmpCoordinateLng = tmpCoordinateLng.replaceAll("'", "");  // Retirar o Simbolo de minutos (')
        tmpCoordinateLng = tmpCoordinateLng.replaceAll('"', "");  // Retirar o Simbolo de segundos (")
        tmpCoordinateLng = tmpCoordinateLng.replaceAll(".0", ""); // Retirar as Casas Decimais (")

        const tmpArrayLat = tmpCoordinateLat.split(" ");
        const tmpArrayLng = tmpCoordinateLng.split(" ");

        // Garantir que terá somente os Graus e os Minutos para a Latitude.
        if (tmpArrayLat.length == 1) {
            tmpArrayLat[1] = "00"; // Só tem os Graus, é preciso acrescentar os Minutos da Latitude.
        } else if (tmpArrayLat.length == 2) {
            tmpArrayLat[2] = "00"; // Só tem os Graus e Minutos, é preciso acrescentar os Segundos da Latitude.
        }

        if (tmpArrayLng.length == 1) {
            tmpArrayLng[1] = "00"; // Só tem os Graus, é preciso acrescentar os Minutos da Longitude.
        } else if (tmpArrayLng.length == 2) {
            tmpArrayLng[2] = "00"; // Só tem os Graus e Minutos, é preciso acrescentar os Segundos da Longitude.
        }

        // Ajustar os GRAUS da Latitude para ter 2 Dígitos.
        if (parseInt(tmpArrayLat[0]) < 10) { // O Graus da Latitude devem ser compostos por 2 caracteres.
            tmpCoordinateLatGraus = `0${parseInt(tmpArrayLat[0])}`;
        } else {
            tmpCoordinateLatGraus = `${parseInt(tmpArrayLat[0])}`;
        }

        // Ajustar os MINUTOS da Latitude para ter 2 Dígitos.
        if (parseInt(tmpArrayLat[1]) < 10) {
            tmpCoordinateLatMinuto = `0${parseInt(tmpArrayLat[1])}`;
        } else {
            tmpCoordinateLatMinuto = `${parseInt(tmpArrayLat[1])}`;
        }

        // Ajustar os SEGUNDOS da Latitude para ter 2 Dígitos.
        if (parseInt(tmpArrayLat[2]) < 10) {
            tmpCoordinateLatSegundos = `0${parseInt(tmpArrayLat[2])}`;
        } else {
            tmpCoordinateLatSegundos = `${parseInt(tmpArrayLat[2])}`;
        }

        // Ajustar os GRAUS da Longitude para ter 3 Dígitos.
        if (parseInt(tmpArrayLng[0]) < 10) { // O Graus de Longitude devem ser compostos por 3 caracteres.
            tmpCoordinateLngGraus = `00${parseInt(tmpArrayLng[0])}`;
        } else if (parseInt(tmpArrayLng[0]) < 100) { // O Graus de Longitude devem ser compostos por 3 caracteres.
            tmpCoordinateLngGraus = `0${parseInt(tmpArrayLng[0])}`;
        } else {
            tmpCoordinateLngGraus = `${parseInt(tmpArrayLng[0])}`;
        }

        // Ajustar os MINUTOS da Longitude para ter 2 Dígitos.
        if (parseInt(tmpArrayLng[1]) < 10) {
            tmpCoordinateLngMinuto = `0${parseInt(tmpArrayLng[1])}`;
        } else {
            tmpCoordinateLngMinuto = `${parseInt(tmpArrayLng[1])}`;
        }

        // Ajustar os SEGUNDOS da Longitude para ter 2 Dígitos.
        if (parseInt(tmpArrayLng[2]) < 10) {
            tmpCoordinateLngSegundos = `0${parseInt(tmpArrayLng[2])}`;
        } else {
            tmpCoordinateLngSegundos = `${parseInt(tmpArrayLng[2])}`;
        }

        if (tempLatNumber < 0) {
            returnCoordinatePlan = `${tmpCoordinateLatGraus}${tmpCoordinateLatMinuto}.${tmpCoordinateLatSegundos}S`;
        } else {
            returnCoordinatePlan = `${tmpCoordinateLatGraus}${tmpCoordinateLatMinuto}.${tmpCoordinateLatSegundos}N`;
        }

        if (tempLngNumber < 0) {
            returnCoordinatePlan = `${returnCoordinatePlan}/${tmpCoordinateLngGraus}${tmpCoordinateLngMinuto}.${tmpCoordinateLngSegundos}W`;
        } else {
            returnCoordinatePlan = `${returnCoordinatePlan}/${tmpCoordinateLngGraus}${tmpCoordinateLngMinuto}.${tmpCoordinateLngSegundos}E`;
        }

        return returnCoordinatePlan.split("/");
    }

    /**
     * TODO: https://tsdoc.org/
     */
    static coordinateArrayDdToGms(xArrayCoordinateDd: any[]): any {
        let tempLatNumber = 0;
        let tempLngNumber = 0;

        if (typeof xArrayCoordinateDd[0] == "number") {
            tempLatNumber = xArrayCoordinateDd[0];
        } else {
            tempLatNumber = parseFloat(xArrayCoordinateDd[0]);
        }

        if (typeof xArrayCoordinateDd[1] == "number") {
            tempLngNumber = xArrayCoordinateDd[1];
        } else {
            tempLngNumber = parseFloat(xArrayCoordinateDd[1]);
        }

        let returnCoordinateLat = "";
        let returnCoordinateLng = "";

        let tmpCoordinateLat = geolib.decimalToSexagesimal(tempLatNumber);
        let tmpCoordinateLng = geolib.decimalToSexagesimal(tempLngNumber);

        tmpCoordinateLat = tmpCoordinateLat.replaceAll("°", "");  // Retirar o Simbolo de grau (°)
        tmpCoordinateLat = tmpCoordinateLat.replaceAll("'", "");  // Retirar o Simbolo de minutos (')
        tmpCoordinateLat = tmpCoordinateLat.replaceAll('"', "");  // Retirar o Simbolo de segundos (")
        tmpCoordinateLat = tmpCoordinateLat.replaceAll(".0", ""); // Retirar as Casas Decimais (")

        tmpCoordinateLng = tmpCoordinateLng.replaceAll("°", "");  // Retirar o Simbolo de grau (°)
        tmpCoordinateLng = tmpCoordinateLng.replaceAll("'", "");  // Retirar o Simbolo de minutos (')
        tmpCoordinateLng = tmpCoordinateLng.replaceAll('"', "");  // Retirar o Simbolo de segundos (")
        tmpCoordinateLng = tmpCoordinateLng.replaceAll(".0", ""); // Retirar as Casas Decimais (")

        let tmpCoordinateGrausLat = "";
        let tmpCoordinateMinutoLat = "";
        let tmpCoordinateSecondsLat = "";
        let tmpCoordinateGrausLng = "";
        let tmpCoordinateMinutoLng = "";
        let tmpCoordinateSecondsLng = "";

        // -- LATITUDE -- //
        const tmpArrayLat = tmpCoordinateLat.split(" ");

        if (parseInt(tmpArrayLat[0]) < 10) { // O Graus da Latitude devem ser compostos por 2 caracteres.
            tmpCoordinateGrausLat = `0${parseInt(tmpArrayLat[0])}`;
        } else {
            tmpCoordinateGrausLat = `${parseInt(tmpArrayLat[0])}`;
        }

        if (parseInt(tmpArrayLat[1]) < 10) { // O Graus da Latitude devem ser compostos por 2 caracteres.
            tmpCoordinateMinutoLat = `0${parseInt(tmpArrayLat[1])}`;
        } else {
            tmpCoordinateMinutoLat = `${parseInt(tmpArrayLat[1])}`;
        }

        if (parseInt(tmpArrayLat[2]) < 10) { // O Graus da Latitude devem ser compostos por 2 caracteres.
            tmpCoordinateSecondsLat = `0${parseInt(tmpArrayLat[2])}`;
        } else {
            tmpCoordinateSecondsLat = `${parseInt(tmpArrayLat[2])}`;
        }

        if (tempLatNumber < 0) {
            returnCoordinateLat = `${tmpCoordinateGrausLat} ${tmpCoordinateMinutoLat} ${tmpCoordinateSecondsLat}S`;
        } else {
            returnCoordinateLat = `${tmpCoordinateGrausLat} ${tmpCoordinateMinutoLat} ${tmpCoordinateSecondsLat}N`;
        }

        // -- LONGITUDE -- //

        const tmpArrayLng = tmpCoordinateLng.split(" ");

        if (parseInt(tmpArrayLng[0]) < 10) { // O Graus de Longitude devem ser compostos por 3 caracteres.
            tmpCoordinateGrausLng = `00${parseInt(tmpArrayLng[0])}`;
        } else if (parseInt(tmpArrayLng[0]) < 100) { // O Graus de Longitude devem ser compostos por 3 caracteres.
            tmpCoordinateGrausLng = `0${parseInt(tmpArrayLng[0])}`;
        } else {
            tmpCoordinateGrausLng = `${parseInt(tmpArrayLng[0])}`;
        }

        if (parseInt(tmpArrayLng[1]) < 10) { // O Graus da Latitude devem ser compostos por 2 caracteres.
            tmpCoordinateMinutoLng = `0${parseInt(tmpArrayLng[1])}`;
        } else {
            tmpCoordinateMinutoLng = `${parseInt(tmpArrayLng[1])}`;
        }

        if (parseInt(tmpArrayLng[2]) < 10) { // O Graus da Latitude devem ser compostos por 2 caracteres.
            tmpCoordinateSecondsLng = `0${parseInt(tmpArrayLng[2])}`;
        } else {
            tmpCoordinateSecondsLng = `${parseInt(tmpArrayLng[2])}`;
        }

        if (tempLngNumber < 0) {
            returnCoordinateLng = `${tmpCoordinateGrausLng} ${tmpCoordinateMinutoLng} ${tmpCoordinateSecondsLng}W`;
        } else {
            returnCoordinateLng = `${tmpCoordinateGrausLng} ${tmpCoordinateMinutoLng} ${tmpCoordinateSecondsLng}E`;
        }

        return [returnCoordinateLat, returnCoordinateLng];
    }

    /**
     * TODO: https://tsdoc.org/
     */
    static coordinateArrayDdToPlan(xArrayCoordinateDd: any[]): string {
        let tempLatNumber = 0;
        let tempLngNumber = 0;

        if (typeof xArrayCoordinateDd[0] == "number") {
            tempLatNumber = xArrayCoordinateDd[0];
        } else {
            tempLatNumber = parseFloat(xArrayCoordinateDd[0]);
        }

        if (typeof xArrayCoordinateDd[1] == "number") {
            tempLngNumber = xArrayCoordinateDd[1];
        } else {
            tempLngNumber = parseFloat(xArrayCoordinateDd[1]);
        }

        let returnCoordinatePlan = "";
        let tmpCoordinateLat = geolib.decimalToSexagesimal(tempLatNumber);
        let tmpCoordinateLng = geolib.decimalToSexagesimal(tempLngNumber);
        let tmpCoordinateLatGraus = "";
        let tmpCoordinateLatMinuto = "";
        let tmpCoordinateLngGraus = "";
        let tmpCoordinateLngMinuto = "";

        tmpCoordinateLat = tmpCoordinateLat.replaceAll("°", "");  // Retirar o Simbolo de grau (°)
        tmpCoordinateLat = tmpCoordinateLat.replaceAll("'", "");  // Retirar o Simbolo de minutos (')
        tmpCoordinateLat = tmpCoordinateLat.replaceAll('"', "");  // Retirar o Simbolo de segundos (")
        tmpCoordinateLat = tmpCoordinateLat.replaceAll(".0", ""); // Retirar as Casas Decimais (")

        tmpCoordinateLng = tmpCoordinateLng.replaceAll("°", "");  // Retirar o Simbolo de grau (°)
        tmpCoordinateLng = tmpCoordinateLng.replaceAll("'", "");  // Retirar o Simbolo de minutos (')
        tmpCoordinateLng = tmpCoordinateLng.replaceAll('"', "");  // Retirar o Simbolo de segundos (")
        tmpCoordinateLng = tmpCoordinateLng.replaceAll(".0", ""); // Retirar as Casas Decimais (")

        const tmpArrayLat = tmpCoordinateLat.split(" ");
        const tmpArrayLng = tmpCoordinateLng.split(" ");

        // Garantir que terá somente os Graus e os Minutos para a Latitude.
        if (tmpArrayLat.length == 1) {
            tmpArrayLat[1] = "00"; // Só tem os Graus, é preciso acrescentar os Minutos da Latitude.
        } else if (tmpArrayLat.length == 3) {
            tmpArrayLat.pop(); // É preciso retirar os Segundos, ultimo elemento.
        }

        // Garantir que terá somente os Graus e os Minutos para a Longitude.
        if (tmpArrayLng.length == 1) {
            tmpArrayLng[1] = "00"; // Só tem os Graus, é preciso acrescentar os Minutos da Latitude.
        } else if (tmpArrayLng.length == 3) {
            tmpArrayLng.pop(); // É preciso retirar os Segundos, ultimo elemento.
        }

        // Ajustar os GRAUS da Latitude para ter 2 Dígitos.
        if (parseInt(tmpArrayLat[0]) < 10) { // O Graus da Latitude devem ser compostos por 2 caracteres.
            tmpCoordinateLatGraus = `0${parseInt(tmpArrayLat[0])}`;
        } else {
            tmpCoordinateLatGraus = `${parseInt(tmpArrayLat[0])}`;
        }

        // Ajustar os MINUTOS da Latitude para ter 2 Dígitos.
        if (parseInt(tmpArrayLat[1]) < 10) {
            tmpCoordinateLatMinuto = `0${parseInt(tmpArrayLat[1])}`;
        } else {
            tmpCoordinateLatMinuto = `${parseInt(tmpArrayLat[1])}`;
        }

        // Ajustar os GRAUS da Longitude para ter 3 Dígitos.
        if (parseInt(tmpArrayLng[0]) < 10) { // O Graus de Longitude devem ser compostos por 3 caracteres.
            tmpCoordinateLngGraus = `00${parseInt(tmpArrayLng[0])}`;
        } else if (parseInt(tmpArrayLng[0]) < 100) { // O Graus de Longitude devem ser compostos por 3 caracteres.
            tmpCoordinateLngGraus = `0${parseInt(tmpArrayLng[0])}`;
        } else {
            tmpCoordinateLngGraus = `${parseInt(tmpArrayLng[0])}`;
        }

        // Ajustar os MINUTOS da Longitude para ter 2 Dígitos.
        if (parseInt(tmpArrayLng[1]) < 10) {
            tmpCoordinateLngMinuto = `0${parseInt(tmpArrayLng[1])}`;
        } else {
            tmpCoordinateLngMinuto = `${parseInt(tmpArrayLng[1])}`;
        }

        if (tempLatNumber < 0) {
            returnCoordinatePlan = `${tmpCoordinateLatGraus}${tmpCoordinateLatMinuto}S`;
        } else {
            returnCoordinatePlan = `${tmpCoordinateLatGraus}${tmpCoordinateLatMinuto}N`;
        }

        if (tempLngNumber < 0) {
            returnCoordinatePlan = `${returnCoordinatePlan}${tmpCoordinateLngGraus}${tmpCoordinateLngMinuto}W`;
        } else {
            returnCoordinatePlan = `${returnCoordinatePlan}${tmpCoordinateLngGraus}${tmpCoordinateLngMinuto}E`;
        }

        return returnCoordinatePlan;
    }

    /**
     * TODO: https://tsdoc.org/
     */
    static coordinateArrayLabelToDd(xArrayCoordinateLabel: string[]): number[] {
        let tmpStrLatCoordLabel = "";
        let tmpStrLngCoordLabel = "";

        tmpStrLatCoordLabel = xArrayCoordinateLabel[0].replaceAll(".", " ").toUpperCase();
        tmpStrLngCoordLabel = xArrayCoordinateLabel[1].replaceAll(".", " ").toUpperCase();

        tmpStrLatCoordLabel = `${tmpStrLatCoordLabel.substring(0, 2)} ${tmpStrLatCoordLabel.substring(2)}`;
        tmpStrLngCoordLabel = `${tmpStrLngCoordLabel.substring(0, 3)} ${tmpStrLngCoordLabel.substring(3)}`;

        // LATITUDE----------------------------
        // Descobrir a Letra que indica o Hemisfério, pois ela vai ser retirada da string, uma vez que pode estar no começo.
        const tmpLat: string = (tmpStrLatCoordLabel.indexOf("N") >= 0) ? "N" : "S";

        let arraySplittedLat: string[];

        tmpStrLatCoordLabel = tmpStrLatCoordLabel.replaceAll("S", "");
        tmpStrLatCoordLabel = tmpStrLatCoordLabel.replaceAll("N", "");

        tmpStrLatCoordLabel = tmpStrLatCoordLabel.trim();

        arraySplittedLat = tmpStrLatCoordLabel.split(" ");

        // É preciso reconstruir o valor de entrada, pois pode ser que venha com a letra do hemisfério na frente e sem os indicativo de graus, minuto e segundo....
        tmpStrLatCoordLabel = `${arraySplittedLat[0]}° ${arraySplittedLat[1]}' ${arraySplittedLat[2]}" ${tmpLat}`;

        // LONGITUDE----------------------------
        const tmpLng: string = (tmpStrLngCoordLabel.indexOf("W") >= 0) ? "W" : "E";

        let arraySplittedLng: string[];
        let tempFirstPosition: string;

        tmpStrLngCoordLabel = tmpStrLngCoordLabel.replaceAll("W", "");
        tmpStrLngCoordLabel = tmpStrLngCoordLabel.replaceAll("E", "");

        tmpStrLngCoordLabel = tmpStrLngCoordLabel.trim();

        arraySplittedLng = tmpStrLngCoordLabel.split(" ");
        tempFirstPosition = arraySplittedLng[0];

        if (tempFirstPosition.length == 1) {
            tempFirstPosition = `00${parseInt(tempFirstPosition)}`;
        } else if (tempFirstPosition.length == 2) {
            tempFirstPosition = `0${parseInt(tempFirstPosition)}`;
        } else {
            tempFirstPosition = `${parseInt(tempFirstPosition)}`;
        }

        // É preciso reconstruir o valor de entrada, pois pode ser que venha com a letra do hemisfério na frente e sem os indicativo de graus, minuto e segundo....
        tmpStrLngCoordLabel = `${tempFirstPosition}° ${arraySplittedLng[1]}' ${arraySplittedLng[2]}" ${tmpLng}`;

        return [geolib.sexagesimalToDecimal(tmpStrLatCoordLabel), geolib.sexagesimalToDecimal(tmpStrLngCoordLabel)];
    }

    /**
     * TODO: https://tsdoc.org/
     */
    static coordinateArrayGmsToDd(xArrayCoordinateGms: string[]): number[] {
        let tmpStrLatCoordinate: string = xArrayCoordinateGms[0];
        let tmpStrLngCoordinate: string = xArrayCoordinateGms[1];

        tmpStrLatCoordinate = tmpStrLatCoordinate.replaceAll("°", "");  // Retirar o Simbolo de grau (°)
        tmpStrLatCoordinate = tmpStrLatCoordinate.replaceAll("'", "");  // Retirar o Simbolo de minutos (')
        tmpStrLatCoordinate = tmpStrLatCoordinate.replaceAll('"', "");  // Retirar o Simbolo de segundos (")
        tmpStrLatCoordinate = tmpStrLatCoordinate.replaceAll(".0", ""); // Retirar as Casas Decimais (")

        tmpStrLngCoordinate = tmpStrLngCoordinate.replaceAll("°", "");  // Retirar o Simbolo de grau (°)
        tmpStrLngCoordinate = tmpStrLngCoordinate.replaceAll("'", "");  // Retirar o Simbolo de minutos (')
        tmpStrLngCoordinate = tmpStrLngCoordinate.replaceAll('"', "");  // Retirar o Simbolo de segundos (")
        tmpStrLngCoordinate = tmpStrLngCoordinate.replaceAll(".0", ""); // Retirar as Casas Decimais (")

        // LATITUDE----------------------------
        // Descobrir a Letra que indica o Hemisfério, pois ela vai ser retirada da string, uma vez que pode estar no começo.
        const tmpLat: string = (tmpStrLatCoordinate.indexOf("N") >= 0) ? "N" : "S";

        let arraySplittedLat: string[];

        tmpStrLatCoordinate = tmpStrLatCoordinate.replaceAll("S", "");
        tmpStrLatCoordinate = tmpStrLatCoordinate.replaceAll("N", "");

        tmpStrLatCoordinate = tmpStrLatCoordinate.trim();

        arraySplittedLat = tmpStrLatCoordinate.split(" ");

        // É preciso reconstruir o valor de entrada, pois pode ser que venha com a letra do hemisfério na frente e sem os indicativo de graus, minuto e segundo....
        tmpStrLatCoordinate = `${arraySplittedLat[0]}° ${arraySplittedLat[1]}' ${arraySplittedLat[2]}" ${tmpLat}`;

        // LONGITUDE----------------------------
        const tmpLng: string = (tmpStrLngCoordinate.indexOf("W") >= 0) ? "W" : "E";

        let arraySplittedLng: string[];
        let tempFirstPosition: string;

        tmpStrLngCoordinate = tmpStrLngCoordinate.replaceAll("W", "");
        tmpStrLngCoordinate = tmpStrLngCoordinate.replaceAll("E", "");

        tmpStrLngCoordinate = tmpStrLngCoordinate.trim();

        arraySplittedLng = tmpStrLngCoordinate.split(" ");
        tempFirstPosition = arraySplittedLng[0];

        if (tempFirstPosition.length == 1) {
            tempFirstPosition = `00${parseInt(tempFirstPosition)}`;
        } else if (tempFirstPosition.length == 2) {
            tempFirstPosition = `0${parseInt(tempFirstPosition)}`;
        } else {
            tempFirstPosition = `${parseInt(tempFirstPosition)}`;
        }

        // É preciso reconstruir o valor de entrada, pois pode ser que venha com a letra do hemisfério na frente e sem os indicativo de graus, minuto e segundo....
        tmpStrLngCoordinate = `${tempFirstPosition}° ${arraySplittedLng[1]}' ${arraySplittedLng[2]}" ${tmpLng}`;

        return [geolib.sexagesimalToDecimal(tmpStrLatCoordinate), geolib.sexagesimalToDecimal(tmpStrLngCoordinate)];
    }

    /**
     * TODO: https://tsdoc.org/
     */
    static coordinateArrayPlanToDd(xCoordinateLabel: string): number[] {
        xCoordinateLabel = xCoordinateLabel.trim(); // PAra evitar erro, melhor limpar.

        let tmpStrLatCoordLabel = `${xCoordinateLabel.substring(0, 2)} ${xCoordinateLabel.substring(2, 4)} 00 ${xCoordinateLabel.substring(4, 5)}`;
        let tmpStrLngCoordLabel = `${xCoordinateLabel.substring(5, 8)} ${xCoordinateLabel.substring(8, 10)} 00 ${xCoordinateLabel.substring(10)}`;

        // LATITUDE----------------------------
        // Descobrir a Letra que indica o Hemisfério, pois ela vai ser retirada da string, uma vez que pode estar no começo.
        const tmpLat: string = (tmpStrLatCoordLabel.indexOf("N") >= 0) ? "N" : "S";

        let arraySplittedLat: string[];

        tmpStrLatCoordLabel = tmpStrLatCoordLabel.replaceAll("S", "");
        tmpStrLatCoordLabel = tmpStrLatCoordLabel.replaceAll("N", "");

        tmpStrLatCoordLabel = tmpStrLatCoordLabel.trim();

        arraySplittedLat = tmpStrLatCoordLabel.split(" ");

        // É preciso reconstruir o valor de entrada, pois pode ser que venha com a letra do hemisfério na frente e sem os indicativo de graus, minuto e segundo....
        tmpStrLatCoordLabel = `${arraySplittedLat[0]}° ${arraySplittedLat[1]}' ${arraySplittedLat[2]}" ${tmpLat}`;

        // LONGITUDE----------------------------
        const tmpLng: string = (tmpStrLngCoordLabel.indexOf("W") >= 0) ? "W" : "E";

        let arraySplittedLng: string[];
        let tempFirstPosition: string;

        tmpStrLngCoordLabel = tmpStrLngCoordLabel.replaceAll("W", "");
        tmpStrLngCoordLabel = tmpStrLngCoordLabel.replaceAll("E", "");

        tmpStrLngCoordLabel = tmpStrLngCoordLabel.trim();

        arraySplittedLng = tmpStrLngCoordLabel.split(" ");
        tempFirstPosition = arraySplittedLng[0];

        if (tempFirstPosition.length == 1) {
            tempFirstPosition = `00${parseInt(tempFirstPosition)}`;
        } else if (tempFirstPosition.length == 2) {
            tempFirstPosition = `0${parseInt(tempFirstPosition)}`;
        } else {
            tempFirstPosition = `${parseInt(tempFirstPosition)}`;
        }

        // É preciso reconstruir o valor de entrada, pois pode ser que venha com a letra do hemisfério na frente e sem os indicativo de graus, minuto e segundo....
        tmpStrLngCoordLabel = `${tempFirstPosition}° ${arraySplittedLng[1]}' ${arraySplittedLng[2]}" ${tmpLng}`;

        return [geolib.sexagesimalToDecimal(tmpStrLatCoordLabel), geolib.sexagesimalToDecimal(tmpStrLngCoordLabel)];
    }

    /**
     * TODO: https://tsdoc.org/
     */
    static generateUuid(): string {
        // Retorna uma String com 36 posições = '1b9d6bcd-bbfd-4b2d-9b5d-ab8dfbbd4bed'.
        return uuidv4();
    }

    /**
     * TODO: https://tsdoc.org/
     */
    static generateUuidNil(): string {
        // Retorna uma String com 36 posições = '00000000-0000-0000-0000-000000000000'.
        return NIL_UUID;
    }

    /**
     * TODO: https://tsdoc.org/
     */
    static getFormFieldsErrors(xForm: FormGroup | FormArray, xParentField: string = "") {
        let errorsForm: any = [];
        let tempFieldName = "";

        Object.keys(xForm.controls).forEach(field => {
            const control = xForm.get(field);

            tempFieldName = `${xParentField}${field}`

            if (control instanceof FormGroup || control instanceof FormArray) {
                errorsForm = errorsForm.concat(this.getFormFieldsErrors(control, `${tempFieldName}.`)); //FAZ RECURSIVIDADES, CASO SEJA UM ARRAY DE CONTROLES.

                return;
            } else {
                // FORCAR OS CAMPOS A MOSTRAREM OS ERROS: TOUCHED & DIRTY:
                control.markAsTouched();
                control.markAsDirty();
            }

            const controlErrors: ValidationErrors = control.errors;

            if (controlErrors !== null) {
                Object.keys(controlErrors).forEach(keyError => {
                    errorsForm.push({
                        controlName: tempFieldName,
                        errorName: keyError,
                        errorValue: controlErrors[keyError]
                    });
                });
            }
        });

        // Remove os Itens Duplicados.
        errorsForm = errorsForm.filter((xValue: any, xIndex: any, xSelf: any) => xSelf.findIndex((t: any) => {
            return t.controlName === xValue.controlName;
        }) === xIndex);

        return errorsForm;
    }

    /**
     * TODO: https://tsdoc.org/
     */
    static aircraftMarkPutMask(xTempMark: string) {
        const tempTwoLetters = xTempMark.substring(0, 2).toUpperCase();
        const arrayBrasilInitial = ["PT", "PR", "PP", "PS", "PU", "PH"];

        if (arrayBrasilInitial.includes(tempTwoLetters)) {
            return `${xTempMark.substring(0, 2).toUpperCase()}-${xTempMark.substring(2, 5).toUpperCase()}`
        } else {
            return xTempMark;
        }
    }
}