<section class="cls-component-contact-handler">
    <article>
        <div class="cls-component-table-frame-01">
            <p-table [value]="this.arrayContactData" [scrollable]="true" scrollHeight="100px"
                [showCurrentPageReport]="true" rowExpandMode="multiple" responsiveLayout="stack"
                class="cls-component-table-form-list" styleClass="p-datatable-striped">
                <ng-template pTemplate="caption">
                    <div class="flex align-items-center justify-content-between cls-component-table-list-caption">
                        <span>
                            <i class="fa-solid fa-address-book"></i> LISTA DE CONTATOS
                        </span>
                        <p-button icon="fa-solid fa-plus" (click)="this.callbackShowDialog()" size="small"
                            severity="secondary" class="cls-component-table-list-caption-button"></p-button>
                    </div>
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <th style="width:10%">TIPO</th>
                        <th style="width:50%">CONTATO</th>
                        <th style="width:25%">OBSERVATION</th>
                        <th style="width:15%;"></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-contact let-rowIndex="rowIndex">
                    <tr>
                        <td>
                            <div innerHtml="{{ this.getTypeName(contact.type) }}"></div>
                        </td>
                        <ng-container *ngIf="contact.type === 'mobile' || contact.type === 'phone'">
                            <td>
                                {{contact.phone.ddi}} ({{contact.phone.ddd}}) {{ contact.phone.number }}
                                <ng-container *ngIf="contact.phone.is_whatsapp">
                                    <span class="ml-3">
                                        <i class="fa-brands fa-whatsapp"></i> - WHATSAPP
                                    </span>
                                </ng-container>
                                <ng-container *ngIf="contact.phone.is_telegram">
                                    <span class="ml-3">
                                        <i class="fa-brands fa-telegram"></i> - TELEGRAM
                                    </span>
                                </ng-container>
                            </td>
                        </ng-container>
                        <ng-container *ngIf="contact.type === 'facebook'">
                            <td>{{ contact.facebook }}</td>
                        </ng-container>
                        <ng-container *ngIf="contact.type === 'instagram'">
                            <td>{{ contact.instagram }}</td>
                        </ng-container>
                        <ng-container *ngIf="contact.type === 'email'">
                            <td>{{ contact.email }}</td>
                        </ng-container>
                        <td>{{ contact.obs_contact }}</td>
                        <td style="text-align:right" class="cls-component-table-form-list-item-actions">
                            <p-button icon="fa-solid fa-pen" [rounded]="true" [text]="true" severity="info"
                                (click)="this.editContact(rowIndex)">
                            </p-button>
                            <p-button icon="fa-solid fa-trash-can" [rounded]="true" [text]="true" severity="danger"
                                (click)="this.deleteContact(rowIndex)">
                            </p-button>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </article>
    <article>
        <p-dialog header="Edit Profile" [modal]="true" [closable]="false" [(visible)]="this.showDialogFormContact"
            [style]="{ width: '40vw', height: '40vh' }">
            <ng-template pTemplate="header">
                <h2 class="cls-component-dialog-modal-title">Dados do Contato</h2>
            </ng-template>
            <ng-template pTemplate="content">
                <div class="cls-component-form">
                    <div class="formgrid grid">
                        <div class="col-12 md:col-12 lg:col-12">
                            <ui-primeng-dropdown-template-icon label="Tipo de Contato #BOLD#" idField="fid-contact-type"
                                [control]="this.formContact.controls.type" [itemsArray]="this.optionsContactType"
                                withFilter="false" placeHolder="..." sizeInput="small"
                                noLabelWidth="{{this.pageInnerWidth}}">
                            </ui-primeng-dropdown-template-icon>
                        </div>
                    </div>
                    <ng-container
                        *ngIf="this.formContact.controls.type.value === 'mobile' || this.formContact.controls.type.value === 'phone'">
                        <div class="formgrid grid">
                            <div class="col-12 md:col-12 lg:col-3">
                                <ui-primeng-dropdown-template-ddi label="Código País #BOLD#" idField="fid-phone-ddi"
                                    placeHolder="" sizeInput="small"
                                    [control]="this.formContact.controls.phone.controls.ddi" placeHolder="..."
                                    [itemsArray]="null" withFilter="false"
                                    noLabelWidth="{{this.settingConfig.WIDTH_NO_LABEL}}">
                                </ui-primeng-dropdown-template-ddi>
                            </div>
                            <div class="col-12 md:col-12 lg:col-3">
                                <ui-primeng-input-text label="Código de Área #BOLD#" idField="fid-phone-ddd"
                                    [control]="this.formContact.controls.phone.controls.ddd" isUpperCase="true"
                                    sizeInput="small" maxCharacters="5" noLabelWidth="{{this.pageInnerWidth}}">
                                </ui-primeng-input-text>
                            </div>
                            <div class="col-12 md:col-12 lg:col-6">
                                <ui-primeng-input-text label="Número #BOLD#" idField="fid-phone-number"
                                    [control]="this.formContact.controls.phone.controls.number" isUpperCase="true"
                                    sizeInput="small" maxCharacters="15" noLabelWidth="{{this.pageInnerWidth}}">
                                </ui-primeng-input-text>
                            </div>
                        </div>
                        <div class="formgrid grid">
                            <div class="col-12 md:col-12 lg:col-3">
                                <ui-primeng-checkbox idField="fid-is-whatsapp"
                                    [control]="this.formContact.controls.phone.controls.is_whatsapp" label="WhatsApp">
                                </ui-primeng-checkbox>
                            </div>
                            <div class="col-12 md:col-12 lg:col-9">
                                <ui-primeng-checkbox idField="fid-is-telegram"
                                    [control]="this.formContact.controls.phone.controls.is_telegram" label="Telegram">
                                </ui-primeng-checkbox>
                            </div>
                        </div>
                        <div class="formgrid grid">
                            <div class="col-12 md:col-12 lg:col-12">
                                <ui-primeng-text-area label="Observação" idField="fid-obs-contact" [rowsSize]="3"
                                    [control]="this.formContact.controls.obs_contact" sizeInput="small"
                                    isDisabled="false">
                                </ui-primeng-text-area>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="this.formContact.controls.type.value === 'email'">
                        <div class="col-12 md:col-12 lg:col-12">
                            <ui-primeng-input-text label="E-mail #BOLD#" idField="fid-contact-email"
                                [control]="this.formContact.controls.email" isUpperCase="true" sizeInput="small"
                                maxCharacters="150" noLabelWidth="{{this.pageInnerWidth}}">
                            </ui-primeng-input-text>
                        </div>
                        <div class="formgrid grid">
                            <div class="col-12 md:col-12 lg:col-12">
                                <ui-primeng-text-area label="Observação" idField="fid-obs-contact" [rowsSize]="3"
                                    [control]="this.formContact.controls.obs_contact" sizeInput="small"
                                    isDisabled="false">
                                </ui-primeng-text-area>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="this.formContact.controls.type.value === 'facebook'">
                        <div class="col-12 md:col-12 lg:col-12">
                            <ui-primeng-input-text label="Facebook #BOLD#" idField="fid-contact-facebook"
                                [control]="this.formContact.controls.facebook" isUpperCase="true" sizeInput="small"
                                maxCharacters="250" noLabelWidth="{{this.pageInnerWidth}}">
                            </ui-primeng-input-text>
                        </div>
                        <div class="formgrid grid">
                            <div class="col-12 md:col-12 lg:col-12">
                                <ui-primeng-text-area label="Observação" idField="fid-obs-contact" [rowsSize]="3"
                                    [control]="this.formContact.controls.obs_contact" sizeInput="small"
                                    isDisabled="false">
                                </ui-primeng-text-area>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="this.formContact.controls.type.value === 'instagram'">
                        <div class="col-12 md:col-12 lg:col-12">
                            <ui-primeng-input-text label="Instagram #BOLD#" idField="fid-contact-instagram"
                                [control]="this.formContact.controls.instagram" isUpperCase="true" sizeInput="small"
                                maxCharacters="250" noLabelWidth="{{this.pageInnerWidth}}">
                            </ui-primeng-input-text>
                        </div>
                        <div class="formgrid grid">
                            <div class="col-12 md:col-12 lg:col-12">
                                <ui-primeng-text-area label="Observação" idField="fid-obs-contact" [rowsSize]="3"
                                    [control]="this.formContact.controls.obs_contact" sizeInput="small"
                                    isDisabled="false">
                                </ui-primeng-text-area>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </ng-template>
            <ng-template pTemplate="footer">
                <p-toolbar class="cls-component-toolbar-control-dialog-pattern">
                    <div class="p-toolbar-group-start"></div>
                    <div class="p-toolbar-group-center"></div>
                    <div class="p-toolbar-group-end">
                        <div class="cls-page-box-left">
                            <ng-container *ngIf="this.currentIndexUpdate === null">
                                <p-button label="SALVAR" icon="fa-regular fa-floppy-disk" [raised]="true"
                                    (click)="this.saveDataContact()" severity="secondary"
                                    class="cls-component-button-pattern-default">
                                </p-button>
                            </ng-container>
                            <ng-container *ngIf="this.currentIndexUpdate !== null">
                                <p-button label="ATUALIZAR" icon="fa-solid fa-rotate" [raised]="true"
                                    severity="secondary" (click)="this.saveEditContact()"
                                    class="cls-component-button-pattern-default">
                                </p-button>
                            </ng-container>
                            <p-button label="FECHAR" icon="fa-solid fa-arrow-right-from-bracket"
                                (click)="this.closeDialogContact()" [raised]="true" severity="secondary"
                                class="cls-component-button-pattern-default">
                            </p-button>
                        </div>
                    </div>
                </p-toolbar>
            </ng-template>
        </p-dialog>
    </article>
</section>