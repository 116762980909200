<section id="id-top-header-01" class="cls-top-hdr">
    <div class="cls-top-hdr-frame">
        <div class="cls-top-hdr-frame-item01">
            <div class="cls-component-logo-system">
                <!--<img src="assets/imgs/no_logo.png" alt="">-->
            </div>            
        </div>
        <div class="cls-top-hdr-frame-item02">
            <div class="cls-component-version-info">
                <div class="cls-component-version-info-page">
                    <div class="cls-component-version-info-page-label">AMBIENTE DE DESENVOLVIMENTO</div>
                    <div class="cls-component-version-info-page-link">
                        <a routerLink="version/dev">0.7.0 (19/07/2024)</a>
                    </div> 
                </div>                                
            </div>
        </div>  
        <div class="cls-top-hdr-frame-item03">
            <section class="cls-component-buttons-control">
                <!--<p-button icon="fa-solid fa-solid fa-magnifying-glass" [rounded]="true" severity="info" [raised]="true" class="cls-component-buttons-control-search"></p-button>
                <p-button icon="fa-solid fa-table-cells-large" [rounded]="true" severity="info" [raised]="true"></p-button>
                <p-button icon="fi fi-br" [rounded]="true" severity="info" [raised]="true"></p-button>
                <p-button icon="fa-regular fa-bell" [rounded]="true" severity="info" [raised]="true"></p-button>               
                <p-button icon="fa-regular fa-moon" [rounded]="true" severity="info" [raised]="true"></p-button>-->
            </section>
            <section user-name-insideMenu class="cls-component-user-tools">         
               <p-splitButton id="id-user-menu" [model]="items">
                     <ng-template pTemplate="content">
                        <div class="cls-component-user-tools-avatar">
                         <ng-container *ngIf="hasUserImage() === true">
                                <p-avatar styleClass="cls-component-user-tools-avatar-img"
                                    image="https://primefaces.org/cdn/primeng/images/demo/avatar/amyelsner.png">
                                </p-avatar>
                            </ng-container>
                            <ng-container *ngIf="hasUserImage() === false">
                                <p-avatar styleClass="cls-component-user-tools-avatar-icon" icon="fa-regular fa-circle-user">
                                </p-avatar>
                            </ng-container>
                        </div>
                        <div class="cls-component-user-tools-ident">
                            <div class="cls-component-user-tools-ident-name">{{ this.strUserName }}</div>
                            <div class="cls-component-user-tools-ident-profile">{{ this.strProfile }}</div>
                        </div>
                    </ng-template>
                </p-splitButton>
            </section>
        </div>
    </div>
</section>