import { Component, OnInit, Input, HostListener } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, ReactiveFormsModule } from '@angular/forms';

import { InputSwitchModule } from 'primeng/inputswitch';

import { hasControlErrors } from '../common/controls-methods';

//$sm	Breakpoint of screens such as phones.					           576px
//$md	Breakpoint of screens such as tablets.					         768px
//$lg	Breakpoint of screens such as notebook monitors.		     992px
//$xl	Breakpoint of smaller screens such as desktop monitors.	1200px

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'ui-primeng-input-switch',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    InputSwitchModule
  ],
  templateUrl: './input-switch.component.html',
  styleUrl: './input-switch.component.scss',
})
export class InputSwitchComponent implements OnInit {
  //#region "|--- INPUTS ---|"
  @Input() control!: FormControl;
  @Input() label!: string;
  @Input() itemLeft!: string;
  @Input() itemRight!: string;
  @Input() iconLeft!: string;
  @Input() iconRight!: string;
  @Input() name!: string;
  @Input() idField!: string;
  @Input() noLabelWidth?: string;

  @Input() value!: string;
  //#endregion

  //#region "|--- PROPERTIES---|"
  innerWidth?: any;

  showLabel = true;
  showLabelBold = false;
  //#endregion

  ngOnInit(): void {
    this.innerWidth = window.innerWidth;

    this._hasToShowLabelBold();
    this._getShowLabel();
  }

  /**
   * METHOD: Executada toda vez que ocorre uma alteração na Tela.
   *
   * @param event
   */
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.innerWidth = window.innerWidth;

    this._getShowLabel();
  }

  /**
   * TODO: https://tsdoc.org/
   */
  showLeftIcon(): boolean {
    if (this.iconLeft != undefined && this.iconLeft != null) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * TODO: https://tsdoc.org/
   */
  showRightIcon(): boolean {
    if (this.iconRight != undefined && this.iconRight != null) {
      return true;
    } else {
      return false;
    }
  }

  getLeftIconName() {
    if (this.iconLeft != undefined && this.iconLeft != "") {
      return this.iconLeft;
    } else {
      return ""
    }
  }

  getRightIconName() {
    if (this.iconRight != undefined && this.iconRight != "") {
      return this.iconRight;
    } else {
      return ""
    }
  }

  showErrors() {
    return hasControlErrors(this.control, "input");
  }

  /**
  * @description
  *
  * @returns
  */
  getShowLabelBold() {
    return this.showLabelBold;
  }

  /**
   * @description Verifica se é para retirar o Label e substituir pelo PlaceHold.
   * OBS: Isto deve ocorrer quando for passado o tamanho da tela que esta transformação deve ocorrer.
   */
  _getShowLabel() {
    if (this.noLabelWidth && this.noLabelWidth != "") {
      if (this.innerWidth < Number(this.noLabelWidth)) {
        this.showLabel = false;
      } else {
        this.showLabel = true;
      }
    }
  }

  /**
   * @description
   */
  _hasToShowLabelBold() {
    if (this.label.indexOf("#BOLD#") > 0) {
      this.showLabelBold = true;
      this.label = this.label.replace("#BOLD#", "").trim();
    }
  }
}
