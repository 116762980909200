//#region "|--- IMPORT MODULES/PACKAGES ---|"
import { Component, OnInit, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PrimengComponentsModule } from '@nashville-primeng';
//#endregion

@Component({
  selector: 'nashville-aircraft-data-equipment',
  standalone: true,
  imports: [
    CommonModule,
    PrimengComponentsModule
  ],
  templateUrl: './aircraft-data-equipment.component.html',
  styleUrl: './aircraft-data-equipment.component.scss',
})
export class AircraftDataEquipmentComponent implements OnInit {
  //#region "|--- INPUTS ---|"
  @Input() pageInnerWidth!: any;
  @Input() formComponent!: any;
  //#endregion

  ngOnInit(): void {

  }
}
