//#region "|--- IMPORT MODULES/PACKAGES ---|"
import { Component, OnInit, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PrimengComponentsModule } from '@nashville-primeng';
//#endregion

@Component({
  selector:
    'nashville-aircraft-data-weight-and-balance',
  standalone: true,
  imports: [
    CommonModule,
    PrimengComponentsModule
  ],
  templateUrl: './aircraft-data-weight-and-balance.component.html',
  styleUrl: './aircraft-data-weight-and-balance.component.scss',
})
export class AircraftDataWeightAndBalanceComponent implements OnInit {
  //#region "|--- INPUTS ---|"
  @Input() pageInnerWidth!: any;
  @Input() formComponent!: any;
  //#endregion

  ngOnInit(): void {

  }
}
