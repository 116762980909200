//#region "|--- IMPORT MODULES/PACKAGES ---|"
// ***** ANGULAR *****
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';

// ***** NASHVILLE *****
import { PrimengComponentsModule } from '@nashville-primeng';
//#endregion

//#region "|--- ENUMS ---|"
import { BUTTON_ACTION } from '../../../../../../helpers/enum/ButtonActions';
//#endregion

@Component({
  selector:
    'nashville-toolbar-dialog-owner-operator',
  standalone: true,
  imports: [
    CommonModule,
    PrimengComponentsModule
  ],
  templateUrl: './toolbar-dialog-owner-operator.component.html',
  styleUrl: './toolbar-dialog-owner-operator.component.scss',
})
export class ToolbarDialogOwnerOperatorComponent implements OnInit {
  //#region "|--- INPUTS ---|"
  @Input() formComponent!: any;
  @Input() pageInnerWidth!: any;

  // É feito o set do @Input para que seja capturado qualquer modificação, em tempo real, 
  // do parâmetro que está sendo enviado pelo PARENT e, principalmente, que seja feito o 
  // processamento necessário com base no novo valor.
  @Input() set registerType(xValue: string) {
    if (xValue) {
      this.labelButtonType = xValue.toUpperCase();
    }
  };
  //#endregion

  //#region "|--- OUTPUTS ---|"
  @Output() dialogButtonAction = new EventEmitter<string>();
  //#endregion

  //#region "|--- PROPERTIES---|"
  labelActionsButtons: any;
  labelButtonType!: string;
  //#endregion

  /**
   * TODO: https://tsdoc.org/
   */
  ngOnInit(): void {
    this._initVariables();
  }

  //#region "|--- PUBLIC METHODS---|" 
  /**
   * TODO: https://tsdoc.org/
   */
  callbackDialogButton(xValue: string) {
    this.dialogButtonAction.emit(xValue);
  }
  //#endregion


  //#region "|--- PRIVATE METHODS---|" 
  /**
   * TODO: https://tsdoc.org/
   */
  private _initVariables() {
    this.labelActionsButtons = BUTTON_ACTION;
  }
  //#endregion
}
