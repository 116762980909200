//#region "|--- IMPORT MODULES/PACKAGES ---|"
import { Component, OnInit, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

// ***** NASHVILLE *****
import { settingConfig } from '@nashville-config';

import { PrimengComponentsModule } from '@nashville-primeng';
//#endregion

//#region "|--- IMPORT SERVICE ---|"
import { FlightPlanApisService } from '../../../../services/flight-plan-data-apis.service';
//#endregion

//#region "|--- INTERFACE ---|"
import { IDropDownTemplateIconOptions } from '../../../../../../interfaces/IDropDownTemplateIconOptions';
import { IDropDownBasicOptions } from '../../../../../../interfaces/IDropDownBasicOptions';
import { IToastParameters } from '../../../../../../interfaces/IToastParameters';
import { IPatternResponseFromAPI } from '../../../../../../interfaces/IPatternCrudResponseFromAPI';
import { LocalMethodsHandlerClass } from '@nashville-local-methods';
//#endregion

@Component({
  selector: 'nashville-panel-aircraft-data',
  standalone: true,
  imports: [
    CommonModule,
    PrimengComponentsModule
  ],
  templateUrl: './panel-aircraft-data.component.html',
  styleUrl: './panel-aircraft-data.component.scss',
})
export class PanelAircraftDataComponent implements OnInit {
  //#region "|--- INPUTS ---|"
  @Input() formComponent!: any;
  @Input() pageInnerWidth!: any;
  @Input() arrayAircraft!: any;
  @Input() arrayPilots!: any;
  @Input() arrayOperators!: any;
  //#endregion

  //#region "|--- PROPERTIES ---|"  
  settingConfig!: any;

  allAircraftData!: any;

  optionsAircraft!: IDropDownTemplateIconOptions[];  //OK
  optionsPilots!: IDropDownBasicOptions[]  //OK
  optionsOperators!: IDropDownBasicOptions[];  //OK
  //#endregion

  constructor(
    private flightPlanApisService: FlightPlanApisService
  ) { }

  ngOnInit(): void {
    this._initVariables();

    this._loadAllAircraft();

    setTimeout(() => {
      this._initEventsComponentForm();
    }, 10);
  }

  //#region "|--- PRIVATE METHODS ---|"  
  /**
   * TODO: https://tsdoc.org/
   */
  private _initVariables(): void {
    this.optionsAircraft = this.arrayAircraft;
    this.optionsPilots = this.arrayPilots;
    this.optionsOperators = this.arrayOperators;

    this.settingConfig = settingConfig;
  }

  private _initEventsComponentForm() {
    this.formComponent.get('aircraft_mark').valueChanges.subscribe((xValue: any) => {
      if (xValue && xValue != null && xValue.length > 0) {
        this.optionsOperators = [];

        this.allAircraftData.forEach((xElement: any) => {          
          if (xElement.aero_registration.mark == xValue) {
            xElement.owner_operator_data.forEach((xOperator: any) => {
              if (xOperator.type_owner_operator == "both" || xOperator.type_owner_operator == "operator") {
                this.optionsOperators.push({
                  label: xOperator.name,
                  value: xOperator.name
                });
              }              
            })
          }
        });

        if(this.optionsOperators.length == 1){
          this.formComponent.controls.operator.setValue(this.optionsOperators[0].value);
        }
      } else {
        this.optionsOperators = [];
      }
    });    
  }

  /**
   * TODO: https://tsdoc.org/
   */
  private async _loadAllAircraft() {
    await this.flightPlanApisService.getAllAircraftRequest().subscribe({
      next: (xResponseService: IPatternResponseFromAPI) => {

        if (xResponseService.status_code == 200) {
          this.allAircraftData = xResponseService.data_info.data;

          this.allAircraftData.forEach((xElement: any) => {
            this.optionsAircraft.push({
              label: LocalMethodsHandlerClass.aircraftMarkPutMask(xElement.aero_registration.mark),
              value: xElement.aero_registration.mark,
              icon: (xElement.aero_registration.type_aircraft) == "aircraft" ? "fa-solid fa-plane" : "fa-solid fa-helicopter"
            });
          });

          //

          //this.arrayAllFlightRequests = xResponseService.data_info.data;

          /*this._showToast({
            severity: 'success',
            summary: 'Listar Requisições de Planos de Voo',
            detail: 'Lista de requisições de planos de voo recuperada com sucesso.',
          });*/
        } else {
          /*if (this.settingConfig.SHOW_LOG_EXCEPTION) {
            console.log("Erro - Listar Requisições de Planos de Voo: ", xResponseService);
          }

          this._showToast({
            severity: 'error',
            summary: 'Listar Requisições de Planos de Voo',
            detail: 'Erro - Recuperar requisições de planos de voo.',
          });*/
        }
      },
      complete: () => {

      },
      error: (xErrorService: any) => {
        let tempMessage = "";
        let tempSeverity = "error";

        if (this.settingConfig.SHOW_LOG_EXCEPTION) {
          console.log("Exceção - Listar Requisições de Planos de Voo: ", xErrorService);
        }

        if (xErrorService.status == 0) {
          tempMessage = 'Exceção - Não foi possível conectar com o servidor da aplicação.';
        } else if (xErrorService.status == 404) {
          tempMessage = 'Exceção - Não foi possível conectar com o servidor da aplicação.'

          if (xErrorService.error) {
            if (xErrorService.error.data_info) {
              if (xErrorService.error.data_info.qtd == 0) {
                tempSeverity = "warn";
                tempMessage = "Exceção - Nenhuma requisição de plano de voo registrado no sistema";
              }
            }
          }
        } else {
          tempMessage = 'Exceção - Recuperar requisições de planos de voo.';
        }

        /*this._showToast({
          severity: tempSeverity,
          summary: 'Listar Requisições de Planos de Voo',
          detail: tempMessage,
        });*/
      }
    });
  }

  //#endregion
}
