import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { IUserConfigs } from 'src/app/interfaces/IUserConfigs';

@Injectable({
  providedIn: 'root'
})
export class UserConfigService {

  constructor() { }

  getUserConfig(): Observable<IUserConfigs> {
    const userConfig: IUserConfigs = {
      "top_header": "top-header-01",
      "top_horizontal_menu": "top-horizontal-menu-01",
      "layout_frame": "horizontal-basic-01",
      "color_schema": "light",
      "theme": "fly-solutions"
    }

    return of(userConfig);
  }
}
