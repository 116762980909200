import { Component, OnInit, Input, HostListener } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';

import { ColorPickerModule } from 'primeng/colorpicker';
import { TooltipModule } from 'primeng/tooltip';

import { ErrorMessageControlsComponent } from '../common/error-message-controls/error-message-controls.component';
import { hasControlErrors } from '../common/controls-methods';

//$sm	Breakpoint of screens such as phones.					           576px
//$md	Breakpoint of screens such as tablets.					         768px
//$lg	Breakpoint of screens such as notebook monitors.		     992px
//$xl	Breakpoint of smaller screens such as desktop monitors.	1200px

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'ui-primeng-color-picker',
  standalone: true,
  imports: [
    ErrorMessageControlsComponent,
    ColorPickerModule,
    CommonModule,
    ReactiveFormsModule,
    TooltipModule
  ],
  templateUrl: './color-picker.component.html',
  styleUrl: './color-picker.component.scss',
})
export class ColorPickerComponent implements OnInit {
  //#region "|--- INPUTS ---|"

  @Input() control!: FormControl;

  @Input() label!: string;
  @Input() idField!: string;
  @Input() sizeInput?: string;
  @Input() placeHolder?: string;
  @Input() noLabelWidth?: string;
  @Input() isDisabled?: string;
  @Input() toolTip?: string;
  //#endregion

  //#region "|--- PROPERTIES---|"
  innerWidth?: any;
  placeHolderContent?: string;
  inputTextControl!: FormControl;

  showLabel = true;
  showLabelBold = false;
  showToolTipIcon = false;
  //#endregion

  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
    this.placeHolderContent = this.placeHolder;

    this.inputTextControl = new FormControl<string>(this.control.value),

    this._hasToShowLabelBold();
    this._getShowLabel();
    this._getIsDisabled();
    this._hasToShowToolTipIcon();
    this._initEvents();
  }

  /**
   * (v1) - Executada toda vez que ocorre REDIMENSIONAMENTO da Tela.
   *
   * @param event
   */
  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    this.innerWidth = window.innerWidth;

    this._getShowLabel();
  }

  /**
   * TODO: https://tsdoc.org/
   */
  getShowLabelBold(): boolean {
    return this.showLabelBold;
  }

  /**
   * TODO: https://tsdoc.org/
   */
  getTooTipContent(): string {
    if (this.toolTip != undefined && this.toolTip != "") {
      return this.toolTip;
    } else {
      return "";
    }
  }

  /**
   * TODO: https://tsdoc.org/
   */
  getFieldName() {
    return this.idField.replace("fid-", "");
  }

  /**
   * TODO: https://tsdoc.org/
   */
  showErrors() {
    return hasControlErrors(this.control, "input");
  }

  _initEvents() {
    this.control.valueChanges.subscribe((xValue: any) => {
      this.inputTextControl.setValue(xValue.toUpperCase(), { emitEvent: false })
    });

    this.inputTextControl.valueChanges.subscribe((xValue: any) => {
      if (xValue.length == 7) {
        this.control.setValue(xValue.toUpperCase(), { emitEvent: false });
      }
    });
  }

  /**
   * TODO: https://tsdoc.org/
   */
  _getIsDisabled(): void {
    if (this.isDisabled != undefined && this.isDisabled != "") {
      if (this.isDisabled == "true") {
        this.control.disable();
      } else {
        this.control.enable();
      }
    } else {
      this.control.enable();
    }
  }

  /**
   * TODO: https://tsdoc.org/
   */
  private _hasToShowLabelBold(): void {
    if (this.label != undefined) {
      if (this.label.indexOf("#BOLD#") > 0) {
        this.showLabelBold = true;
        this.label = this.label.replace("#BOLD#", "").trim();
      }
    }
  }

  /**
   * TODO: https://tsdoc.org/
   */
  private _getShowLabel(): void {
    if (this.noLabelWidth != undefined && this.noLabelWidth != "") {
      if (this.innerWidth < Number(this.noLabelWidth)) {
        this.showLabel = false;
        this.showToolTipIcon = false;
        this.placeHolderContent = this.label;
      } else {
        this.showLabel = true;
        this._hasToShowToolTipIcon();
        this.placeHolderContent = "";
      }
    }
  }

  /**
   * TODO: https://tsdoc.org/
   */
  _hasToShowToolTipIcon(): void {
    if (this.toolTip != undefined && this.toolTip != "") {
      this.showToolTipIcon = true;
    }
  }
}
