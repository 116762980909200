import { FormBuilder, Validators } from '@angular/forms';

/**
 * TODO: https://tsdoc.org/
 */
export function initFormRiskList() {
    const objFormBuilder = new FormBuilder();

    // SEGURANÇA CONTRA ATOS DE INTERFERÊNCIA ILÍCITA (AVSEC)
    // COLISÃO COM OBSTÁCULOS NA DECOLAGEM OU POUSO (CTOL)
    // COLISÃO COM FAUNA NO SOLO (WILD)
    // DANOS CAUSADOS POR OBJETOS ESTRANHOS (FOD)
    // INCURSÃO EM PISTA (RI)
    // PERDA DE CONTROLE DE AERONAVE NO SOLO (LOC-G)
    return objFormBuilder.group({
        general: objFormBuilder.group({
            flight_risk_degree: objFormBuilder.group({
                choice: [],
                data: []
            }),
            flight_risk_attention: objFormBuilder.group({
                choice: [],
                data: []
            }),
            weather_category: objFormBuilder.group({
                choice: [],
                data: []
            }),
            risk_observation: objFormBuilder.group({
                type: [],
                data: []
            })
        }),
        crew: objFormBuilder.group({
            certification: objFormBuilder.group({
                choice: [],
                data: []
            }),
            experience: objFormBuilder.group({
                choice: [],
                data: []
            }),
            training: objFormBuilder.group({
                choice: [],
                data: []
            }),
            documentation: objFormBuilder.group({
                choice: [],
                data: []
            }),
            adapted_aircraft: objFormBuilder.group({
                choice: [],
                data: []
            }),
            illness: objFormBuilder.group({
                choice: [],
                data: []
            }),
            medication: objFormBuilder.group({
                choice: [],
                data: []
            }),
            stress_fadigue: objFormBuilder.group({
                choice: [],
                data: []
            })
        }),
        aircraft: objFormBuilder.group({
            fuel_range: objFormBuilder.group({
                choice: [],
                data: []
            }),
            takeoff_landing_performance: objFormBuilder.group({
                choice: [],
                data: []
            }),
            cruise_performance: objFormBuilder.group({
                choice: [],
                data: []
            }),
            weight_balance: objFormBuilder.group({
                choice: [],
                data: []
            }),
            danger_payload: objFormBuilder.group({
                choice: [],
                data: []
            }),
            flight_equipment: objFormBuilder.group({
                choice: [],
                data: []
            }),
            inoperante_equipment: objFormBuilder.group({
                choice: [],
                data: []
            })
        }),
        environment: objFormBuilder.group({
            thunder_storm: objFormBuilder.group({
                choice: [],
                data: []
            }),
            icing: objFormBuilder.group({
                choice: [],
                data: []
            }),
            low_ceiling_visibility: objFormBuilder.group({
                choice: [],
                data: []
            }),
            turbulence: objFormBuilder.group({
                choice: [],
                data: []
            }),
            winds: objFormBuilder.group({
                choice: [],
                data: []
            }),
            wind_shear: objFormBuilder.group({
                choice: [],
                data: []
            }),
            hight_density_altitude: objFormBuilder.group({
                choice: [],
                data: []
            }),
            obstacles: objFormBuilder.group({
                choice: [],
                data: []
            }),
            aerodrome_hot_spot: objFormBuilder.group({
                choice: [],
                data: []
            }),
            over_water: objFormBuilder.group({
                choice: [],
                data: []
            }),
            avsec: objFormBuilder.group({
                choice: [],
                data: []
            }),
            ctol: objFormBuilder.group({
                choice: [],
                data: []
            }),
            wild: objFormBuilder.group({
                choice: [],
                data: []
            }),
            bird: objFormBuilder.group({
                choice: [],
                data: []
            }),
            ri: objFormBuilder.group({
                choice: [],
                data: []
            }),
            log_g: objFormBuilder.group({
                choice: [],
                data: []
            }),
            runway_deteriorated: objFormBuilder.group({
                choice: [],
                data: []
            }),
            runway_contamination: objFormBuilder.group({
                choice: [],
                data: []
            }),
            heliport_restriction: objFormBuilder.group({
                choice: [],
                data: []
            }),
            approach_aids: objFormBuilder.group({
                choice: [],
                data: []
            }),
            fod: objFormBuilder.group({
                choice: [],
                data: []
            }),
            prohibited_restricted_area: objFormBuilder.group({
                choice: [],
                data: []
            }),
            services_unavailability: objFormBuilder.group({
                choice: [],
                data: []
            }),
            air_traffic_density: objFormBuilder.group({
                choice: [],
                data: []
            }),
            balloons: objFormBuilder.group({
                choice: [],
                data: []
            }),
            night_vfr_operation: objFormBuilder.group({
                choice: [],
                data: []
            }),
            night_single_engine: objFormBuilder.group({
                choice: [],
                data: []
            })
        }),
        external_pressures: objFormBuilder.group({
            limited_time: objFormBuilder.group({
                choice: [],
                data: []
            }),
            urgent: objFormBuilder.group({
                choice: [],
                data: []
            })
        })
    });
}