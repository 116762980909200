import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrimengComponentsModule } from '@nashville-primeng-components';

@Component({
  selector: 'nashville-version-dev',
  standalone: true,
  imports: [
    CommonModule,
    PrimengComponentsModule
  ],
  templateUrl: './version-dev.component.html',
  styleUrl: './version-dev.component.scss',
})
export class VersionDevComponent {}
