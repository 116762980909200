// 1) https://uploadcare.com/blog/how-to-upload-files-in-angular/
// 2) https://blog.angular-university.io/angular-file-upload/


// https://github.com/progtarek/angular-drag-n-drop-directive/blob/master/src/app/app.component.ts
// https://github.com/progtarek/angular-drag-n-drop-directive/blob/master/src/app/app.component.html
// https://blog.angular-university.io/angular-file-upload/

// https://uploadcare.com/blog/how-to-upload-files-in-angular/

//#region "|--- IMPORT MODULES/PACKAGES ---|"
// ***** ANGULAR *****
import { Component, OnInit, Input, HostListener, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { HttpClient, HttpEventType } from '@angular/common/http';

// ***** NPM *****
import HTTP_STATUS from 'http-status-codes';
//#endregion


//$sm	Breakpoint of screens such as phones.					           576px
//$md	Breakpoint of screens such as tablets.					         768px
//$lg	Breakpoint of screens such as notebook monitors.		     992px
//$xl	Breakpoint of smaller screens such as desktop monitors.	1200px

@Component({
  selector: 'nashville-upload-file',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './upload-file.component.html',
  styleUrl: './upload-file.component.scss',
})
export class UploadFileComponent implements OnInit {
  @ViewChild("fileDropRef", { static: false }) fileDropEl: ElementRef;

  //#region "|--- INPUTS ---|"
  @Input() control!: FormControl;
  @Input() urlUpload!: string;
  @Input() idField!: string;
  @Input() fileName!: string;
  @Input() fieldData!: any;
  @Input() isMultiFiles!: boolean;
  @Input() acceptExtension!: string;

  @Input() fileSize!: string;
  @Input() buttonLabel!: string;
  //#endregion

  //#region "|--- OUTPUT ---|"
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onSuccess: EventEmitter<any> = new EventEmitter();
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onUpload: EventEmitter<any> = new EventEmitter();
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onError: EventEmitter<any> = new EventEmitter();  // Callback to invoke if file upload fails.
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onImageError: EventEmitter<any> = new EventEmitter(); // This event is triggered if an error occurs while loading an image file.
  //#endregion

  //#region "|--- PROPERTIES ---|"
  innerWidth?: any;
  fileOver: boolean;
  files: any[] = [];
  fileSizeLabel: string;

  buttonLabelGetFile!: string

  _fileSize: number;

  status: "initial" | "uploading" | "success" | "fail" = "initial"; // Variable to store file status

  file: File | null = null; // Variable to store file
  //#endregion

  constructor(
    private http: HttpClient
  ) { }

  ngOnInit(): void {
    this._initVariables();
    this._getFileSize();
    this._getButtonLabel();
  }

  // PASTE EVENT 
  @HostListener('window:paste', ['$event']) onPaste(xEvent: any) {
    const items = (xEvent.clipboardData || window.Clipboard || xEvent.originalEvent.clipboardData).items;

    let tempBlobImage = null;

    for (const item of items) {
      if (item.type.indexOf('image') === 0) {
        tempBlobImage = item.getAsFile();
      }
    }

    console.log("tttttttttttttt", tempBlobImage)

    if (tempBlobImage !== null) {
      const reader = new FileReader();
      const formData = new FormData();

      this.status = "initial";

      formData.append("image-upload", tempBlobImage);

      // Envia dados de Controle como Field dentro do formulário...
      if (this.fieldData && this.fieldData != null) {
        formData.append('field_data', JSON.stringify(this.fieldData));
      }

      const upload$ = this.http.post(`${this.urlUpload}`, formData, {
        reportProgress: true,
        observe: 'events'
      });

      upload$.subscribe({
        next: (xUploadReturn: any) => {
          if (xUploadReturn.type === HttpEventType.Response) {
            if (xUploadReturn.status == HTTP_STATUS.OK) {
              console.log("2222222222222222");
              this.onSuccess.emit(xUploadReturn);  // Retorna a mensagem de Sucesso, vinda do Servidor, para ser tratada corretamente.
            } else {
              this.onError.emit(xUploadReturn);  // Retorna a mensagem de Erro, vinda do Servidor, para ser tratada corretamente.
            }
          } else if (xUploadReturn.type === HttpEventType.UploadProgress) {
            const percentDone = Math.round(100 * xUploadReturn.loaded / xUploadReturn.total);
            console.log('Progress ' + percentDone + '%');
          }
        },
        error: (xError: any) => {
          this.onError.emit(xError); // Erro relacionados ao UPLOAD, como Serviço fora do ar.
        },
        complete: () => {
          this.onUpload.emit();
        }
      });


      /*reader.onload = (evt: any) => {
        const tmpFormImage = {
          flight_plan_folder_path: this.formFlightPlanRequest.controls.flight_plan_folder_path.value,
          image_context: xForm,
          image: evt.target.result,
          originalname: tempBlobImage.name,
          size: tempBlobImage.size
        }

        if (tempBlobImage.size < 2000000) {
          // Envia a imagem para ser salva no Servidor
          this.flightPlanModuleService.uploadPasteImageFlightPlan(tmpFormImage).subscribe({
            next: (xResponseService: any) => {
              if (xResponseService.status == "success") {
                const tmpMessage = JSON.parse(xResponseService.messages);   // Transforma de JSON para OBJECT.
                const tmpData = JSON.parse(xResponseService.data);          // Pega o ID da Solicitação de Plano de Voo que foi gravado.

                const tmpURL = `${tmpData[0].url_image}`;
                const tmpFileName = `${tmpData[0].image_name}`;

                this.setFormImage(tmpURL, tmpFileName, xForm); // Salva a URL da Imagem no Formulário para enviar ao servidor.
                this._getFlightPlanImageFromServerTo(tmpURL, "ImageChosen", xForm); // Recupera a Imagem do Servidor.

                this.messageService.add({
                  severity: 'success',
                  summary: `Carregar a Imagem do(a) ${this._getLabelItemImage(xForm)}.`,
                  detail: `${tmpMessage.join(" ")}`
                });

                this._updateFlightPlanRequest(this.formFlightPlanRequest.value, this.currentFlightPlanRequestID);
              } else {
                const tmpMessage = JSON.parse(xResponseService.messages); // Transforma de JSON para OBJECT.

                this.messageService.add({
                  severity: 'error',
                  summary: `Carregar a Imagem do(a) ${this._getLabelItemImage(xForm)}.`,
                  detail: `(ID: ${xResponseService.error.erro_id}) - ${tmpMessage.join(" ")}`
                });
              }
            },
            complete: () => {
              this.showWaitingDialogPreview = false;
            },
            error: (xErrorService: any) => {
              const tmpMessage = JSON.parse(xErrorService.error.messages); // Transforma de JSON para OBJECT.

              this.messageService.add({
                severity: 'error',
                summary: `Carregar a Imagem do(a) ${this._getLabelItemImage(xForm)}.`,
                detail: `(ID: ${xErrorService.error.erro_id}) - ${tmpMessage.join(" ")}`
              });
            }
          });
        } else {
          this.messageService.add({
            severity: 'error',
            summary: `Carregar a Imagem do(a) ${this._getLabelItemImage(xForm)}.`,
            detail: `O Tamanho máximo da Imagem é de 2MB e esta imagem tem ${this.bytesToSize(tempBlobImage.size)}`
          });
        }
      */}/*

      reader.readAsDataURL(tempBlobImage);
      */
  }

  // DRAGOVER LISTENER
  /**
   * TODO: https://tsdoc.org/
   */
  @HostListener('dragover', ['$event']) onDragOver(evt: any) {
    evt.preventDefault();
    evt.stopPropagation();

    this.fileOver = true;
  }

  // DRAGOVER LEAVE
  /**
   * TODO: https://tsdoc.org/
   */
  @HostListener('dragleave', ['$event']) onDragLeave(evt: any) {
    console.log("AAAAAAAAAAAAAAAAAA++++++++++++")

    evt.preventDefault();
    evt.stopPropagation();
  }

  // DRAGOVER DROP
  @HostListener('drop', ['$event']) onDrop(evt: any) {
    console.log("BBBBBBBBBBBBBBBB++++++++++++")
    evt.preventDefault();
    evt.stopPropagation();

    this.fileOver = false;
    const files = evt.dataTransfer.files;

    if (files.length > 0) {
      const formData = new FormData();

      this.file = files[0];
      formData.append(`${this.fileName}`, this.file, this.file.name);

      const upload$ = this.http.post(`${this.urlUpload}`, formData, {
        reportProgress: true,
        observe: 'events'
      });

      upload$.subscribe({
        next: (xResponse: any) => {
          if (xResponse.status == HTTP_STATUS.OK) {
            if (xResponse.body) {
              this.onSuccess.emit(xResponse);
              this.status = "success";
            }
          }
        },
        error: (xError: any) => {
          if (xError.status != HTTP_STATUS.OK) {
            this.onError.emit(xError);
            this.status = "fail";
          }
        }
      });
    }
  }



  /**
   * TODO: https://tsdoc.org/
   */
  _getFileSize() {
    if (this.fileSize) {
      this.fileSizeLabel = this.formatBytes(this.fileSize);
      return this.fileSize;
    } else {
      this.fileSizeLabel = this.formatBytes(2100000); // Default é 2MG.
      return 2100000;
    }
  }

  /**
  * TODO: https://tsdoc.org/
  */
  _getButtonLabel() {
    if (this.buttonLabel) {
      this.buttonLabelGetFile = this.buttonLabel;
    } else {
      this.buttonLabelGetFile = "BUSCAR ARQUIVO";
    }
  }


  /**
   * TODO: https://tsdoc.org/
   */
  getButtonLabel() {

  }

  /**
   * TODO: https://tsdoc.org/
   */
  fileBrowserHandler(event: Event) {
    const files = (<HTMLInputElement>event.target).files;
    const tempFile: any[] = [];

    for (let i = 0; i < files.length; i++) {
      if (files[i]) {
        const formData = new FormData();

        this.status = "initial";

        if (this.isMultiFiles) { // Verifica se é Multi-Arquivos.
          [...this.files].forEach((file) => {
            formData.append("file", file, file.name);
          });
        } else { // Apenas 1 Arquivo para Upload.
          this.file = files[i];
          formData.append(`${this.fileName}`, this.file, this.file.name);
        }

        // Envia dados de Controle como Field dentro do formulário...
        if (this.fieldData && this.fieldData != null) {
          formData.append('field_data', JSON.stringify(this.fieldData));
        }

        const upload$ = this.http.post(`${this.urlUpload}`, formData, {
          reportProgress: true,
          observe: 'events'
        });

        upload$.subscribe({
          next: (xUploadReturn: any) => {
            if (xUploadReturn.type === HttpEventType.Response) {
              if (xUploadReturn.status == HTTP_STATUS.OK) {
                console.log("2222222222222222");
                this.onSuccess.emit(xUploadReturn);  // Retorna a mensagem de Sucesso, vinda do Servidor, para ser tratada corretamente.
              } else {
                this.onError.emit(xUploadReturn);  // Retorna a mensagem de Erro, vinda do Servidor, para ser tratada corretamente.
              }
            } else if (xUploadReturn.type === HttpEventType.UploadProgress) {
              const percentDone = Math.round(100 * xUploadReturn.loaded / xUploadReturn.total);
              console.log('Progress ' + percentDone + '%');
            }
          },
          error: (xError: any) => {
            this.onError.emit(xError); // Erro relacionados ao UPLOAD, como Serviço fora do ar.
          },
          complete: () => {
            this.onUpload.emit();
          }
        });
      }

      //tempFile.push(files[i]);
    }
  }

  /**
   * TODO: https://tsdoc.org/
   */
  deleteFile(index: number) {
    if (this.files[index].progress < 100) {
      console.log("Upload in progress.");
      return;
    }
    this.files.splice(index, 1);
  }

  /**
   * TODO: https://tsdoc.org/
   */
  uploadFilesSimulator(index: number) {
    setTimeout(() => {


      /*if (index === this.files.length) {
        return;
      } else {
        const progressInterval = setInterval(() => {
          if (this.files[index].progress === 100) {
            clearInterval(progressInterval);
            this.uploadFilesSimulator(index + 1);
          } else {
            this.files[index].progress += 5;
          }
        }, 200);
      }*/
    }, 1000);
  }

  /**
   * TODO: https://tsdoc.org/
   */
  formatBytes(bytes: any, decimals = 2) {
    if (bytes === 0) {
      return "0 Bytes";
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  /**
   * TODO: https://tsdoc.org/
   */
  private _initVariables(): void {
    this.fileOver = false;

    if (!this.isMultiFiles) {
      this.isMultiFiles = false;
    }

    if (!this.acceptExtension) {
      this.acceptExtension = "";
    }
  }
}
