<div class="cls-ui-primeng-field">
    <div class="field" [ngClass]="{'cls-field-wrong': this.showErrors() }">
        <ng-container *ngIf="this.showLabel"> 
            <label class="cls-field-label" [ngClass]="{'cls-label-bold': this.getShowLabelBold(), 'cls-field-wrong': this.showErrors() }" for="{{ this.idField }}">
                {{ this.label }} 
                <ng-container *ngIf="this.showToolTipIcon">
                    <i [pTooltip]="tooltipContent" [showDelay]="500" [hideDelay]="300" class="fa-solid fa-comment-dots ml-1"></i>
                </ng-container> 
            </label>
            <ng-template #tooltipContent>
                <div class="flex align-items-center">
                    <img src="https://primefaces.org/cdn/primeng/images/primeng.svg" height="20" class="mr-2" />
                    <span> {{ this.getTooTipContent() }} </span>
                </div>
            </ng-template>
        </ng-container>
    </div>
    <p-autoComplete 
        [formControl]="this.control"
        [ngClass]="{ 'is-danger': this.showErrors() }"
        [styleClass]="this.getInputClass()"
        [class]="this.getInputClass()"
        [inputStyleClass]="this.getInputClass()"
        [panelStyleClass]="this.getInputClass()"
        [type]="this.getInputType()"        
        [suggestions]="this.arraySuggestions" 
        [minlength]="2"
        [delay]="300"
        [maxlength]="this.getMaxCharacters()"
        (completeMethod)="filterContent($event)" 
        placeholder="{{this.placeHolderContent}}"
        oninput="this.value = this.value ? this.value.toUpperCase() : null"
        id="{{ this.idField }}">
    </p-autoComplete>
</div>