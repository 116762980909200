import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HorizontalBasic01Component } from '../../../../layouts/frames-layout/spa-frames/wareframes/horizontal-basic-01/horizontal-basic-01.component';
import { HorizontalBasic02Component } from '../../../../layouts/frames-layout/spa-frames/wareframes/horizontal-basic-02/horizontal-basic-02.component';
import { VerticalBasic01Component } from '../../../../layouts/frames-layout/spa-frames/wareframes/vertical-basic-01/vertical-basic-01.component';
import { VerticalBasic02Component } from '../../../../layouts/frames-layout/spa-frames/wareframes/vertical-basic-02/vertical-basic-02.component';

import { UserMenuService } from 'src/app/services/user-menu/user-menu.service';
import { UserConfigService } from 'src/app/services/user-config/user-config.service';

import { IUserConfigs } from 'src/app/interfaces/IUserConfigs';

@Component({
  selector: 'nashville-spa-page',
  standalone: true,
  imports: [
    CommonModule,
    HorizontalBasic01Component,
    HorizontalBasic02Component,
    VerticalBasic01Component,
    VerticalBasic02Component
  ],
  templateUrl: './spa-page.component.html',
  styleUrl: './spa-page.component.scss',
  providers: [
    UserMenuService,
    UserConfigService
  ]
})
export class SpaPageComponent implements OnInit {
  topHeader!: string | null;
  topHorizontalMenu!: string | null;
  layoutFrame!: string | null;
  colorSchema!: string | null;
  theme!: string | null;

  constructor(
    private userMenuService: UserMenuService,
    private userConfigService: UserConfigService
  ) { }

  ngOnInit(): void {
    this.settingUserConfig();    
  }

  settingUserConfig() {
    this.userConfigService.getUserConfig().subscribe((xUserConfig: IUserConfigs) => {
      this.topHeader = xUserConfig.top_header ? xUserConfig.top_header : "";
      this.topHorizontalMenu = xUserConfig.top_horizontal_menu ? xUserConfig.top_horizontal_menu : "";
      this.layoutFrame = xUserConfig.layout_frame ? xUserConfig.layout_frame : "";
      this.colorSchema = xUserConfig.color_schema ? xUserConfig.color_schema : "";
      this.theme = xUserConfig.theme ? xUserConfig.theme : "";

      document.body.setAttribute('data-top_header', this.topHeader);
      document.body.setAttribute('data-top_horizontal_menu', this.topHorizontalMenu);
      document.body.setAttribute('data-layout_frame', this.layoutFrame);
      document.body.setAttribute('data-color_schema', this.colorSchema);
      document.body.setAttribute('data-theme', this.theme);
    });
  }

  isHorizontalBasic01LayoutRequested(): boolean {
    if(this.layoutFrame == "horizontal-basic-01"){
      return true;
    }else {
      return false;
    }    
  }

  isHorizontalBasic02LayoutRequested(): boolean {
    if(this.layoutFrame == "horizontal-basic-02"){
      return true;
    }else {
      return false;
    }    
  }

  isVerticalBasic01LayoutRequested(): boolean {
    if(this.layoutFrame == "vertical-basic-01"){
      return true;
    }else {
      return false;
    }    
  }

  isVerticalBasic02LayoutRequested(): boolean {
    if(this.layoutFrame == "vertical-basic-02"){
      return true;
    }else {
      return false;
    }    
  }
}
