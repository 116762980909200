<section class="cls-component-data-panel">
    <p-panel header="Filtro Meteorologia" class="cls-component-panel-formgrid">
        <div class="cls-component-form">
            <div class="formgrid grid">
                <div class="field col-3">
                    <ui-primeng-calendar label="Data Inicial (Z) #BOLD#" idField="fid-meteorology-initial-data-zulu"
                        [control]="this.formComponent.controls.filters.controls.meteorology.controls.initial_date_zulu"
                        class="cls-component-calendar-request" maskInput="d0/M0/0000" showMask="true" sizeInput="small"
                        isDisabled="false" noLabelWidth="{{this.settingConfig.WIDTH_NO_LABEL}}" leftIcon="true">
                    </ui-primeng-calendar>
                </div>
                <div class="field col-3">
                    <ui-primeng-input-text label="Hora Inicial (Z) #BOLD#" idField="fid-meteorology-initial-time-zulu"
                        [control]="this.formComponent.controls.filters.controls.meteorology.controls.initial_time_zulu"
                        placeHolder="00:00" maskInput="Hh:m0" sizeInput="small" letterCase="upper" maxCharacters="5"
                        isDisabled="false" noLabelWidth="{{this.settingConfig.WIDTH_NO_LABEL}}">
                    </ui-primeng-input-text>
                </div>
                <div class="field col-3">
                    <ui-primeng-calendar label="Data Inicial (Z)" idField="fid-meteorology-end-data-zulu"
                        [control]="this.formComponent.controls.filters.controls.meteorology.controls.end_date_zulu"
                        class="cls-component-calendar-request" maskInput="d0/M0/0000" showMask="true" sizeInput="small"
                        isDisabled="false" noLabelWidth="{{this.settingConfig.WIDTH_NO_LABEL}}" leftIcon="true">
                    </ui-primeng-calendar>
                </div>
                <div class="field col-3">
                    <ui-primeng-input-text label="Data Final (Z)" idField="fid-meteorology-end-time-zulu"
                        [control]="this.formComponent.controls.filters.controls.meteorology.controls.end_time_zulu"
                        placeHolder="00:00" maskInput="Hh:m0" sizeInput="small" letterCase="upper" maxCharacters="5"
                        isDisabled="false" noLabelWidth="{{this.settingConfig.WIDTH_NO_LABEL}}">
                    </ui-primeng-input-text>
                </div>
            </div>
            <div class="formgrid grid">
                <div class="field col-12">
                    <ui-primeng-slider label="Nível de Voo #BOLD#"
                        [control]="this.formComponent.controls.filters.controls.meteorology.controls.flight_level"
                        idField="fid-meteorology-flight-level" maxValue="635" minValue="10" stepValue="5"
                        labelValue="FL: " noLabelWidth="{{this.settingConfig.WIDTH_NO_LABEL}}">
                    </ui-primeng-slider>
                </div>
            </div>
            <div class="formgrid grid">
                <div class="field col-6">
                    <div class="cls-component-chip-risk-meteoro">
                        <ui-primeng-chips
                            [control]="this.formComponent.controls.filters.controls.meteorology.controls.aerodromes_icao"
                            label="Aerodromes ICAO" idField="fid-meteorology-aerodromes-icao"
                            noLabelWidth=this.settingConfig.WIDTH_NO_LABEL gDefaultControl>
                        </ui-primeng-chips>
                    </div>

                </div>
                <div class="field col-6">
                    <div class="cls-component-chip-risk-meteoro">
                        <ui-primeng-chips
                            [control]="this.formComponent.controls.filters.controls.meteorology.controls.fir_icao"
                            label="FIR ICAO" idField="fid-meteorology-aerodromes-fir-icao"
                            noLabelWidth="{{this.settingConfig.WIDTH_NO_LABEL}}" gDefaultControl>
                        </ui-primeng-chips>
                    </div>
                </div>
            </div>
            <div class="formgrid grid">
                <div class="field col-6">
                    <div class="cls-component-chip-risk-meteoro">
                        <ui-primeng-chips
                            [control]="this.formComponent.controls.filters.controls.meteorology.controls.cities"
                            label="Cidades" idField="fid-meteorology-cities"
                            noLabelWidth="{{this.settingConfig.WIDTH_NO_LABEL}}" gDefaultControl>
                        </ui-primeng-chips>
                    </div>
                </div>
                <div class="field col-6">
                    <div class="cls-component-chip-risk-meteoro">
                        <ui-primeng-chips
                            [control]="this.formComponent.controls.filters.controls.meteorology.controls.coordinates"
                            label="Coordenadas (decimal)" idField="fid-meteorology-coordinates"
                            noLabelWidth="{{this.settingConfig.WIDTH_NO_LABEL}}" gDefaultControl>
                        </ui-primeng-chips>
                    </div>
                </div>
            </div>
            <div class="formgrid grid">
                <div class="field col-6">
                    <ui-primeng-label label="Mensagens Meteorológicas"></ui-primeng-label>
                    <ui-primeng-checkbox
                        [control]="this.formComponent.controls.filters.controls.meteorology.controls.meteoro_messages.controls.metar"
                        label="METAR" initialValue="true" idField="fid-meteorology-messages-metar">
                    </ui-primeng-checkbox>
                    <ui-primeng-checkbox
                        [control]="this.formComponent.controls.filters.controls.meteorology.controls.meteoro_messages.controls.taf"
                        label="TAF" initialValue="true" idField="fid-meteorology-messages-taf"></ui-primeng-checkbox>
                    <ui-primeng-checkbox
                        [control]="this.formComponent.controls.filters.controls.meteorology.controls.meteoro_messages.controls.sigmet"
                        label="SIGMET" initialValue="true" idField="fid-meteorology-messages-sigmet">
                    </ui-primeng-checkbox>
                </div>
                <div class="field col-6">
                    <ui-primeng-label label="Imagens"></ui-primeng-label>
                    <ui-primeng-checkbox
                        [control]="this.formComponent.controls.filters.controls.meteorology.controls.images.controls.sigwx"
                        label="SIGWX" idField="fid-meteorology-images-sigwx"></ui-primeng-checkbox>
                    <ui-primeng-checkbox
                        [control]="this.formComponent.controls.filters.controls.meteorology.controls.images.controls.wind"
                        label="VENTO" idField="fid-meteorology-images-wind"></ui-primeng-checkbox>
                    <ui-primeng-checkbox
                        [control]="this.formComponent.controls.filters.controls.meteorology.controls.images.controls.satellite_ir"
                        label="Satélite IR" idField="fid-meteorology-images-satellite-ir"></ui-primeng-checkbox>
                    <ui-primeng-checkbox
                        [control]="this.formComponent.controls.filters.controls.meteorology.controls.images.controls.satellite_realcada"
                        label="Satélite REALÇADA" idField="fid-meteorology-images-satellite-realcada">
                    </ui-primeng-checkbox>
                    <ui-primeng-checkbox
                        [control]="this.formComponent.controls.filters.controls.meteorology.controls.images.controls.satellite_vis"
                        label="Satélite VIS" idField="fid-meteorology-images-satellite-vis"></ui-primeng-checkbox>
                </div>
            </div>
        </div>
    </p-panel>
</section>