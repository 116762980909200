import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'ui-primeng-auto-complement-dropdown',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './auto-complement-dropdown.component.html',
  styleUrl: './auto-complement-dropdown.component.scss',
})
export class AutoComplementDropdownComponent {}
