import { Component, OnInit, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl } from '@angular/forms';

import { hasControlErrors } from '../controls-methods';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'ui-primeng-error-message-controls',
  standalone: true,
  imports: [
    CommonModule
  ],
  templateUrl: './error-message-controls.component.html',
  styleUrl: './error-message-controls.component.scss',
})
export class ErrorMessageControlsComponent implements OnInit {
  //#region "|--- INPUTS ---|"
  @Input() control!: FormControl;
  @Input() typeControl!: string;
  @Input() hasError!: string;
  //#endregion

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnInit(): void { }

  showErrors() {
    return hasControlErrors(this.control, this.typeControl);
  }
}
